import { CircularProgress, Modal } from "@mui/material";
import { Box } from "@mui/system";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "transparent",
  border: "none",
  p: 4,
};
export default function SuspenseLoading() {
  return (
    <Modal open={true}>
      <Box disabled sx={style}>
        <CircularProgress
          size="100px"
          sx={{ color: (theme) => theme.palette.primary.main }}
        />
      </Box>
    </Modal>
  );
}
