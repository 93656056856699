import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import useTablePagination from "../../../Hooks/TablePaginationHook";
import DownloadSelect from "../../../common/DownloadSelect";
import { dFlex, flexBetweenCenter } from "../../../common/commonStyles";
import {
  StyledTableCell,
  StyledTableRow,
  TableRowSkeletonComponent,
} from "../../../const/Table";
import { CustomDateTextField, FormTextField } from "../../../const/TextField";
import { getFormattedDate } from "../../../const/constFunctions";
import {
  ArrowDropDownIconSvg,
  ArrowDropUpIconSvg,
  FilterIcon,
} from "../../../images/icons";

export default function StudentsEarnedCertificatesTable() {
  const [certificateListApi] = useDataFetchHook(
    process.env.REACT_APP_SA_DASHBOARD_LIST + "/studentCertified"
  );
  const [selectedDate, setSelectedDate] = useState("");
  const [selectOption, setSelectOption] = useState("default");
  const [selectedSortUp, setSelectedSortUp] = useState("");
  const [selectedSortDown, setSelectedSortDown] = useState("");
  const handleChange = (e) => {
    setSearchText("");
    setSelectOption(e.target.value);
  };
  const navigate = useNavigate();

  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();
  const handleFilter = () => {
    let filteredData = certificateListApi?.res?.data;
    console.log(selectOption);
    if (selectOption !== "default") {
      filteredData = filteredData.filter(
        (item) => item.subscriberDetails.type === selectOption
      );
    }

    if (selectedDate) {
      const filterDate = new Date(selectedDate);
      filteredData = filteredData.filter(
        (item) =>
          new Date(item.registrationDate).toDateString() ===
          filterDate.toDateString()
      );
    }

    setSearchData(filteredData);
  };

  const clearSearch = () => {
    setSearchText("");
  };

  const handleClearFilter = () => {
    setSelectOption("default");
    setSelectedDate("");
    setSelectedSortDown("");
    setSelectedSortUp("");
    setSearchData(certificateListApi?.res?.data ?? []);
  };

  const [searchData, setSearchData] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const handleSearch = () => {
    try {
      // console.log("searhing");
      let arr = [...certificateListApi?.res?.data];
      arr = arr?.filter((e) => {
        return e?.studentName
          ?.toLowerCase()
          ?.includes(searchText.toLowerCase());
      });
      handleChangePage(null, 0);
      setSearchData(arr);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!searchText) {
      setSearchData(certificateListApi?.res?.data);
      return;
    }
    handleSearch();
  }, [searchText]);

  useEffect(() => {
    if (!certificateListApi?.res) {
      return;
    }

    setSearchData(certificateListApi?.res?.data);
    return () => {};
  }, [certificateListApi?.res]);

  const sortArr = (para) => {
    setSelectedSortUp(para);
    setSelectedSortDown("");

    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "studentName":
          fir = a.studentName.toLowerCase();
          sec = b.studentName.toLowerCase();
          break;
        case "name":
          fir = a?.subscriberDetails?.name?.toLowerCase();
          sec = b?.subscriberDetails?.name?.toLowerCase();
          break;
        case "registrationDate":
          fir = new Date(a.registrationDate);
          sec = new Date(b.registrationDate);
          break;
        case "certificateIssuedOn":
          fir = new Date(a.certificateIssuedOn);
          sec = new Date(b.certificateIssuedOn);
          break;

        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir < sec) {
        return -1;
      }
      if (fir > sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  const sortArrDec = (para) => {
    setSelectedSortUp("");
    setSelectedSortDown(para);

    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "studentName":
          fir = a.studentName.toLowerCase();
          sec = b.studentName.toLowerCase();
          break;
        case "name":
          fir = a?.subscriberDetails?.name?.toLowerCase();
          sec = b?.subscriberDetails?.name?.toLowerCase();
          break;
        case "registrationDate":
          fir = new Date(a.registrationDate);
          sec = new Date(b.registrationDate);
          break;
        case "certificateIssuedOn":
          fir = new Date(a.certificateIssuedOn);
          sec = new Date(b.certificateIssuedOn);
          break;

        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir > sec) {
        return -1;
      }
      if (fir < sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  const downloadCsv = () => {
    let data = searchData;
    data = data.map((e) => {
      let formattedData = {
        "Student Name": e?.studentName,
        "Subscriber Name": e?.subscriberDetails?.name ?? "NA",
        "Subscriber Type": e?.subscriberDetails?.type ?? "NA",
        "Registration Date": getFormattedDate(e?.registrationDate),
        "Certification Date": getFormattedDate(e?.certificateIssuedOn),
      };
      return formattedData;
    });

    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Student_Earned_Certificate_Data_${new Date().toString()}.csv`;
    link.click();
  };

  return (
    <Box sx={{ mt: 4 }}>
      {/* Subscription Amount filters */}
      <Box sx={{ ...flexBetweenCenter, mb: 3 }}>
        <Box
          sx={{
            ...dFlex,
            gap: 2,
            boxSizing: "border-box",
          }}
        >
          <FormTextField
            placeholder="search"
            // sx={{ width: "400px" }}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            disabled={certificateListApi?.loading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    fontSize="small"
                    sx={{
                      color: "#BFBFBF",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          {/* <FormTextField autoComplete="off" /> */}
          <FormControl sx={{ width: 180 }}>
            <Select
              sx={{
                background: "#FFF",
                color: "#809fb8",
                height: "38px",
                borderRadius: "4px",
              }}
              name="selectOption"
              onChange={handleChange}
              value={selectOption}
            >
              <MenuItem value="default" disabled>
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  Select Type
                </Typography>
              </MenuItem>
              {/* File type options */}
              <MenuItem value="Parent">
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  Parent
                </Typography>
              </MenuItem>
              <MenuItem value="School">
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  School / Institution
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
          <CustomDateTextField
            value={selectedDate}
            onChange={(e) => {
              setSearchText("");
              setSelectedDate(e.target.value);
            }}
            autoComplete="off"
            type="date"
          />
          <Box>
            <Button onClick={handleFilter} variant="primary-medium">
              {" "}
              <FilterIcon /> Filter
            </Button>
            <Button
              onClick={() => {
                handleClearFilter();
                clearSearch();
              }}
              sx={{
                textTransform: "none",
                color: (theme) => theme.palette.text.main,
              }}
            >
              {" "}
              x Clear
            </Button>
          </Box>
        </Box>
        <DownloadSelect downloadCsv={downloadCsv} />
      </Box>

      {/* Table */}
      <Box>
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "13px",
            pt: 1,
            px: "20px",
            boxSizing: "border-box",
            maxHeight: certificateListApi?.loading ? "400px" : "1200px",
            transition: "all 1s ease",
          }}
        >
          <Table sx={{ minWidth: 650 }}>
            {/* Table header */}
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Box sx={{ ...dFlex, gap: 1 }}>
                    <Typography
                      variant="medium"
                      sx={{ color: (theme) => theme.palette.text.dark }}
                    >
                      Student Name
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArr("studentName")}
                      >
                        <ArrowDropUpIconSvg
                          focus={selectedSortUp == "studentName"}
                        />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArrDec("studentName")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown == "studentName"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ ...dFlex, gap: 1 }}>
                    <Typography
                      variant="medium"
                      sx={{ color: (theme) => theme.palette.text.dark }}
                    >
                      Subscriber
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArr("name")}
                      >
                        <ArrowDropUpIconSvg focus={selectedSortUp == "name"} />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArrDec("name")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown == "name"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Subscriber Type
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ ...dFlex, gap: 1 }}>
                    <Typography
                      variant="medium"
                      sx={{ color: (theme) => theme.palette.text.dark }}
                    >
                      Registration Date
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArr("registrationDate")}
                      >
                        <ArrowDropUpIconSvg
                          focus={selectedSortUp == "registrationDate"}
                        />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArrDec("registrationDate")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown == "registrationDate"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ ...dFlex, gap: 1 }}>
                    <Typography
                      variant="medium"
                      sx={{ color: (theme) => theme.palette.text.dark }}
                    >
                      Certification Issued On
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArr("certificateIssuedOn")}
                      >
                        <ArrowDropUpIconSvg
                          focus={selectedSortUp == "certificateIssuedOn"}
                        />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArrDec("certificateIssuedOn")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown == "certificateIssuedOn"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledTableCell>
              </TableRow>
            </TableHead>

            {/* Table body */}
            {certificateListApi?.loading ? (
              <TableRowSkeletonComponent column={5} row={4} />
            ) : (
              <TableBody>
                {searchData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow
                      onClick={() => {
                        navigate(`${row?.id}`, {
                          state: {
                            studentInfo: row,
                          },
                        });
                      }}
                    >
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row.studentName}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row?.subscriberDetails?.name ?? "NA"}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row?.subscriberDetails?.type ?? "NA"}{" "}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {getFormattedDate(row?.registrationDate)}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {getFormattedDate(row?.certificateIssuedOn)}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {/* Table pagination */}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            rowsPerPageOptions={[]}
            count={searchData?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </Box>
  );
}
