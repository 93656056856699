import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { DELETE } from "../../../Apis/constVariables";
import { DeleteIcon } from "../../../images/icons";

export default function DeleteBatchModal({ data }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleArchive = async () => {
    try {
      const url =
        process.env.REACT_APP_SCHOOL_BATCH_DELETE + `/${data?.batchName}`;
      setLoading(true);
      const config = createAxiosConfig({
        endPoint: url,
        method: DELETE,
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      navigate(-1);
      toast(apiRes?.data?.message);
      handleClose();
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Button onClick={handleOpen} variant="primary">
        <DeleteIcon /> Delete Batch
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "13px",
            backgroundColor: (theme) => theme.palette.background.main,
          },
        }}
      >
        <Box sx={{ width: "515px", pb: "20px" }}>
          <DialogContent sx={{ mb: 2 }}>
            <DialogContentText id="alert-dialog-description">
              <Typography
                variant="regular"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Are you sure you want to delete the batch Batch 1?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={loading}
              onClick={handleArchive}
              variant="primary"
            >
              Delete
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
