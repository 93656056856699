import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import useTablePagination from "../../../Hooks/TablePaginationHook";
import DownloadSelect from "../../../common/DownloadSelect";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import {
  dFlex,
  flexBetweenCenter,
  flexCenter,
  textEllipses,
} from "../../../common/commonStyles";
import { StyledTableCell, StyledTableRow } from "../../../const/Table";
import { FormTextField } from "../../../const/TextField";
import { getFormattedDate } from "../../../const/constFunctions";
import {
  ArrowDropDownIconSvg,
  ArrowDropUpIconSvg,
} from "../../../images/icons";

const batches = [
  { label: "Green", value: 1 },
  { label: "Blue", value: 1 },
  { label: "Red", value: 1 },
  { label: "Violet", value: 1 },
  { label: "Gray", value: 1 },
  { label: "Blue", value: 1 },
  { label: "Yellow", value: 1 },
];

export default function DownloadData() {
  const [apiData] = useDataFetchHook(
    process.env.REACT_APP_SCHOOL_GET_REPORT_DATA
  );
  const [moduleName, setModuleName] = useState([]);
  const [batchName, setBatchName] = useState([]);
  const [selectOption, setSelectOption] = useState("default");
  const [selectedSortUp, setSelectedSortUp] = useState("");
  const [selectedSortDown, setSelectedSortDown] = useState("");

  const sortArr = (para) => {
    setSelectedSortUp(para);
    setSelectedSortDown("");

    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "name":
          fir = a.name.toLowerCase();
          sec = b.name.toLowerCase();
          break;
        case "certificateIssued":
          fir = a.certificate?.certificateIssued;
          sec = b.certificate?.certificateIssued;
          break;
        case "certificateIssuedDate":
          fir = new Date(a?.certificate?.certificateIssuedDate);
          sec = new Date(b?.certificate?.certificateIssuedDate);
          break;
        case "rank":
          fir = parseInt(a.rank);
          sec = parseInt(b.rank);
          break;
        case "tokens":
          fir = parseInt(a.tokens);
          sec = parseInt(b.tokens);
          break;
        case "badgeCount":
          fir = parseInt(a.badgeCount);
          sec = parseInt(b.badgeCount);
          break;
        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir < sec) {
        return -1;
      }
      if (fir > sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  const sortArrDec = (para) => {
    setSelectedSortUp("");
    setSelectedSortDown(para);

    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "name":
          fir = a.name.toLowerCase();
          sec = b.name.toLowerCase();
          break;
        case "certificateIssued":
          fir = a.certificate?.certificateIssued;
          sec = b.certificate?.certificateIssued;
          break;
        case "certificateIssuedDate":
          fir = new Date(a?.certificate?.certificateIssuedDate);
          sec = new Date(b?.certificate?.certificateIssuedDate);
          break;
        case "rank":
          fir = parseInt(a.rank);
          sec = parseInt(b.rank);
          break;
        case "tokens":
          fir = parseInt(a.tokens);
          sec = parseInt(b.tokens);
          break;
        case "badgeCount":
          fir = parseInt(a.badgeCount);
          sec = parseInt(b.badgeCount);
          break;
        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir > sec) {
        return -1;
      }
      if (fir < sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  const handleBatchChange = (event) => {
    const {
      target: { value },
    } = event;
    setBatchName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setModuleName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();

  const handleFilter = () => {
    let filteredData = apiData?.res?.data ?? [];

    if (selectOption !== "default") {
      filteredData = filteredData.filter(
        (item) => item.amount === selectOption
      );
    }

    setSearchData(filteredData);
  };

  const clearSearch = () => {
    setSearchText("");
  };
  const handleClearFilter = () => {
    setSelectOption("default");
    setSelectedSortDown("");
    setSelectedSortUp("");
    setSearchText("");
    setSearchData(apiData?.res?.data ?? []);
  };

  const [searchData, setSearchData] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const handleSearch = () => {
    try {
      // console.log("searhing");
      let arr = [...apiData?.res?.data];
      arr = arr?.filter((e) => {
        return e?.name?.toLowerCase()?.includes(searchText.toLowerCase());
      });
      handleChangePage(null, 0);
      setSearchData(arr);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!searchText) {
      setSearchData(apiData?.res?.data);
      return;
    }
    handleSearch();
  }, [searchText]);

  useEffect(() => {
    if (!apiData?.res) {
      return;
    }

    setSearchData(apiData?.res?.data);
    return () => {};
  }, [apiData?.res]);

  const downloadCsv = () => {
    let data = searchData;
    data = data.map((e) => {
      let formattedData = {
        Name: e.name,
        Email: e.email?.toLowerCase(),
        Phone: e.phone,
        Rank: e.rank,
        "Modules Completed": `${
          e.modulesCompleted?.map((e) => e.name)?.join("\n") ?? ""
        }`,
        "Current Module Name": e.currModule?.name ?? e.currModule?.id,
        Percentage: e.percentage,
        Tokens: e.tokens,
        "Batch Name": e.batchName,
        "Badges Count": e.badgeCount,
        "Certificate Issued": e.certificate?.certificateIssued ? "Yes" : "No",
        "Certificate Issued On": e?.certificate?.certificateIssuedDate
          ? getFormattedDate(e?.certificate?.certificateIssuedDate)
          : "NA",
        "Certificate URL": e.certificate?.certificateUrl,
        "ACE Risk Profiling Score": e.riskProfiling?.ace,
        "SDQ PRE Risk Profiling Score": e.riskProfiling?.sdqPre,
        "SDQ POST Risk Profiling Score": e.riskProfiling?.sdqPost,
      };
      return formattedData;
    });

    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Student_Data_${new Date().toString()}.csv`;
    link.click();
  };

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Download Data"]} />
      <Box sx={{ ...flexBetweenCenter, mb: 3 }}>
        <Box
          sx={{
            ...dFlex,
            gap: 2,
            alignItems: "center",
            boxSizing: "border-box",
          }}
        >
          <FormTextField
            placeholder="search"
            // sx={{ width: "400px" }}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            disabled={apiData?.loading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    fontSize="small"
                    sx={{
                      color: "#BFBFBF",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          {/* <FormControl>
            <Select
              sx={{
                background: "#FFF",
                color: "#809fb8",
                height: "39px",
                minWidth: "155px",
                maxWidth: "220px",
                borderRadius: "7px",
                // border: "1px solid #358B8A",
              }}
              multiple
              value={batchName}
              onChange={handleBatchChange}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem value="default" disabled>
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  Select Batch
                </Typography>
              </MenuItem>
              {batches?.map((e, index) => (
                <MenuItem value={e.label}>
                  <Checkbox checked={batchName.indexOf(e.label) > -1} />
                  <Typography
                    variant="medium2"
                    sx={{
                      fontWeight: 600,
                      color: (theme) => theme.palette.text.light,
                    }}
                  >
                    {e.label}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <Select
              sx={{
                background: "#FFF",
                color: "#809fb8",
                height: "39px",
                minWidth: "155px",
                maxWidth: "220px",
                borderRadius: "7px",
                // border: "1px solid #358B8A",
              }}
              multiple
              value={moduleName}
              onChange={handleChange}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem value="default" disabled>
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  Select Module
                </Typography>
              </MenuItem>
              <MenuItem value="Module1">
                <Checkbox checked={moduleName.indexOf("Module1") > -1} />
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  Module 1
                </Typography>
              </MenuItem>
              <MenuItem value="Module2">
                <Checkbox checked={moduleName.indexOf("Module2") > -1} />
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  Module 2
                </Typography>
              </MenuItem>
              <MenuItem value="Module3">
                <Checkbox checked={moduleName.indexOf("Module3") > -1} />
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  Module 3
                </Typography>
              </MenuItem>
            </Select>
          </FormControl> */}
          <Box>
            {/* <Button variant="primary-medium">
              <FilterIcon /> Filter
            </Button> */}
            <Button
              onClick={handleClearFilter}
              sx={{
                textTransform: "none",
                color: (theme) => theme.palette.text.main,
              }}
            >
              {" "}
              x Clear
            </Button>
          </Box>
        </Box>
        <DownloadSelect downloadCsv={downloadCsv} />
      </Box>
      {apiData?.loading && (
        <Box sx={flexCenter}>
          <CircularProgress />
        </Box>
      )}
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: "13px",
          my: 3,
          pt: 1,
          px: "20px",
          boxSizing: "border-box",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Box sx={{ ...dFlex, gap: 1 }}>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Name
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArr("name")}
                    >
                      <ArrowDropUpIconSvg focus={selectedSortUp == "name"} />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArrDec("name")}
                    >
                      <ArrowDropDownIconSvg
                        focus={selectedSortDown == "name"}
                      />
                    </Box>
                  </Box>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  variant="medium"
                  sx={{ color: (theme) => theme.palette.text.dark }}
                >
                  Batch
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  variant="medium"
                  sx={{ color: (theme) => theme.palette.text.dark }}
                >
                  Module
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ ...dFlex, gap: 1 }}>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Ranking
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArr("rank")}
                    >
                      <ArrowDropUpIconSvg focus={selectedSortUp == "rank"} />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArrDec("rank")}
                    >
                      <ArrowDropDownIconSvg
                        focus={selectedSortDown == "rank"}
                      />
                    </Box>
                  </Box>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ ...dFlex, gap: 1 }}>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Tokens
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArr("tokens")}
                    >
                      <ArrowDropUpIconSvg focus={selectedSortUp == "tokens"} />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArrDec("tokens")}
                    >
                      <ArrowDropDownIconSvg
                        focus={selectedSortDown == "tokens"}
                      />
                    </Box>
                  </Box>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ ...dFlex, gap: 1 }}>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Badges
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArr("badgeCount")}
                    >
                      <ArrowDropUpIconSvg
                        focus={selectedSortUp == "badgeCount"}
                      />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArrDec("badgeCount")}
                    >
                      <ArrowDropDownIconSvg
                        focus={selectedSortDown == "badgeCount"}
                      />
                    </Box>
                  </Box>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ ...dFlex, gap: 1 }}>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Certification
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArr("certificateIssued")}
                    >
                      <ArrowDropUpIconSvg
                        focus={selectedSortUp == "certificateIssued"}
                      />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArrDec("certificateIssued")}
                    >
                      <ArrowDropDownIconSvg
                        focus={selectedSortDown == "certificateIssued"}
                      />
                    </Box>
                  </Box>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ ...dFlex, gap: 1 }}>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Issued Date
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArr("certificateIssuedDate")}
                    >
                      <ArrowDropUpIconSvg
                        focus={selectedSortUp == "certificateIssuedDate"}
                      />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArrDec("certificateIssuedDate")}
                    >
                      <ArrowDropDownIconSvg
                        focus={selectedSortDown == "certificateIssuedDate"}
                      />
                    </Box>
                  </Box>
                </Box>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchData
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <StyledTableRow
                  sx={{
                    cursor: "default!important",
                  }}
                >
                  <StyledTableCell
                    sx={{
                      display: "block",
                      width: "200px",
                      ...textEllipses,
                    }}
                  >
                    <Typography
                      variant="regular"
                      sx={{ color: (theme) => theme.palette.text.main }}
                    >
                      {row.name}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      variant="regular"
                      sx={{ color: (theme) => theme.palette.text.main }}
                    >
                      {row.batchName}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      variant="regular"
                      sx={{ color: (theme) => theme.palette.text.main }}
                    >
                      {row.currModule?.name
                        ? row?.currModule?.name
                        : row?.currModule?.id}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      variant="regular"
                      sx={{ color: (theme) => theme.palette.text.main }}
                    >
                      {row.rank}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      variant="regular"
                      sx={{ color: (theme) => theme.palette.text.main }}
                    >
                      {row.tokens}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      variant="regular"
                      sx={{ color: (theme) => theme.palette.text.main }}
                    >
                      {row.badgeCount}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      variant="regular"
                      sx={{ color: (theme) => theme.palette.text.main }}
                    >
                      {row?.certificate?.certificateIssued ? "Yes" : "No"}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      variant="regular"
                      sx={{ color: (theme) => theme.palette.text.main }}
                    >
                      {row?.certificate?.certificateIssuedDate
                        ? getFormattedDate(
                            row?.certificate?.certificateIssuedDate
                          )
                        : "NA"}
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <TablePagination
          rowsPerPageOptions={[]}
          count={searchData?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
}
