import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  flexCenter,
  itemsCenter,
  justifyCenter,
} from "../../../common/commonStyles";
import { AlertIconUrl } from "../../../const/imageUrl";

export default function AlertStudentModal({ setLimitError }) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/school/settings");
  };

  return (
    <>
      <Typography
        variant="medium2"
        sx={{ color: (theme) => theme.palette.text.light, cursor: "pointer" }}
      >
        Archive
      </Typography>
      <Dialog
        open={true}
        onClose={() => {
          setLimitError((p) => !p);
        }}
        PaperProps={{
          sx: {
            borderRadius: "13px",
            backgroundColor: (theme) => theme.palette.background.main,
          },
        }}
      >
        <Box sx={{ width: "515px", pb: "20px" }}>
          <DialogContent sx={{ mb: 2 }}>
            <DialogContentText id="alert-dialog-description">
              <Box
                sx={{ ...flexCenter, flexDirection: "column", gap: 2, mb: 3 }}
              >
                <img
                  src={AlertIconUrl}
                  style={{ height: "85px", width: "85px" }}
                />
                <Typography
                  color="error"
                  sx={{ fontWeight: 600, fontSize: "18px" }}
                >
                  Alert
                </Typography>
              </Box>

              <Box sx={{ ...justifyCenter }}>
                <Typography
                  variant="regular"
                  sx={{
                    color: (theme) => theme.palette.text.main,
                    textAlign: "center",
                  }}
                >
                  No seats available for new registration. Subscribe for more
                  students in settings page before registering a student
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setLimitError((p) => !p);
              }}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleNavigate} variant="primary">
              Go to Settings
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
