import {
  Avatar,
  Box,
  CircularProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FacilitatorBatchInfoTab from "../../../Components/Admin/Dashboard/FacilitatorBatchInfoTab";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { flexCenter, itemsCenter } from "../../../common/commonStyles";
import { TabPanel } from "./SchoolSubscribers";
export default function SchoolFacilitatorInfoData() {
  const [schoolData, setSchoolData] = useState({ name: "" });
  const [facilitatorInfo, setFacilitatorInfo] = useState({ name: "" });
  const [value, setValue] = useState(0);
  const params = useParams();
  const [apiData] = useDataFetchHook(
    process.env.REACT_APP_SCHOOL_FACILITATOR +
      `/batchlist?id=${params?.facilitatorId}`
  );
  const location = useLocation();
  const navigate = useNavigate();
  // Event handler for tab change
  const handleChange = (event, newValue) => {
    // const queryParam = new URLSearchParams(location?.search);
    // queryParam.set("tab", newValue);
    // navigate({ search: queryParam.toString() });
    setValue(newValue);
  };

  useEffect(() => {
    // Set school data from the location state
    const state = location?.state ?? {};
    setSchoolData(state?.schoolInfo);
    setFacilitatorInfo(state?.facilitatorInfo);

    const queryParam = new URLSearchParams(location?.search);
    setValue(Number(queryParam.get("tab") ?? 1));
    // Cleanup function to reset schoolData when component unmounts
    return () => {
      setSchoolData({ instructorName: "" });
    };
  }, [location.state, location?.search]);
  return (
    <Box>
      <NavBreadCrumbs
        pageTags={[
          "Dashboard",
          "School Subscribers",
          schoolData?.name,
          "Facilitator Information",
          facilitatorInfo?.instructorName,
        ]}
      />
      <Box sx={{ ...itemsCenter, gap: "2%" }}>
        <Avatar
          src={facilitatorInfo?.image}
          alt="profile image"
          sx={{ width: "106px", height: "106px" }}
        >
          {facilitatorInfo?.instructorName?.[0]}
        </Avatar>

        <Box sx={{ flex: 1 }}>
          <Stack spacing={2}>
            <Box sx={{ ...itemsCenter, gap: 1 }}>
              <Typography
                variant="medium"
                sx={{
                  fontSize: "16px",
                  width: "70px",
                  color: (theme) => theme.palette.text.dark,
                }}
              >
                Name:
              </Typography>
              <Typography
                variant="regular"
                sx={{
                  width: "60%",
                  color: (theme) => theme.palette.text.main,
                }}
              >
                {facilitatorInfo?.instructorName}
              </Typography>
            </Box>
            <Box sx={{ ...itemsCenter, gap: 1 }}>
              <Typography
                variant="medium"
                sx={{
                  fontSize: "16px",
                  width: "70px",
                  color: (theme) => theme.palette.text.dark,
                }}
              >
                Email:
              </Typography>
              <Typography
                variant="regular"
                sx={{
                  width: "60%",
                  color: (theme) => theme.palette.text.main,
                }}
              >
                {facilitatorInfo?.email}{" "}
              </Typography>
            </Box>
            <Box sx={{ ...itemsCenter, gap: 1 }}>
              <Typography
                variant="medium"
                sx={{
                  fontSize: "16px",
                  width: "70px",
                  color: (theme) => theme.palette.text.dark,
                }}
              >
                Phone:
              </Typography>
              <Typography
                variant="regular"
                sx={{
                  width: "60%",
                  color: (theme) => theme.palette.text.main,
                }}
              >
                {facilitatorInfo?.phone}{" "}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>

      <Box>
        {/* Tabs for current and previous subscribers */}
        {apiData?.loading && (
          <Box sx={flexCenter}>
            <CircularProgress />
          </Box>
        )}
        {apiData?.res?.batch?.length > 0 && (
          <Box
            sx={{
              // width: "100%",
              mt: 5,
              borderBottom: `1px solid #BFBFBF`,
            }}
          >
            <Tabs value={value} onChange={handleChange}>
              {apiData?.res?.batch?.map((e, i) => (
                <Tab
                  sx={{
                    textTransform: "none",
                  }}
                  label={
                    <Typography variant={value === 1 ? "bold2" : "medium"}>
                      {e?.batchName}
                    </Typography>
                  }
                  value={i + 1}
                />
              ))}
            </Tabs>
          </Box>
        )}

        {/* Tab panels */}
        {apiData?.res?.batch?.map((e, i) => (
          <TabPanel value={value} index={i + 1}>
            <FacilitatorBatchInfoTab data={e} schoolData={schoolData} />
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
}
