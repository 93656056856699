import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { DELETE } from "../../../Apis/constVariables";
import { flexCenter } from "../../../common/commonStyles";
import { AlertIconUrl } from "../../../const/imageUrl";

export default function DeleteStudentModal({ unArchive, fetchAgain, data }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const url = process.env.REACT_APP_SCHOOL_STUDENT + `/${data?.id}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: DELETE,
        data: {
          ...data,
        },
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      fetchAgain();
      toast(apiRes?.data?.message);
      handleClose();
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Typography
        variant="medium2"
        onClick={handleOpen}
        sx={{ color: (theme) => theme.palette.text.light, cursor: "pointer" }}
      >
        Delete
      </Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "13px",
            backgroundColor: (theme) => theme.palette.background.main,
          },
        }}
      >
        <Box sx={{ width: "515px", pb: 2 }}>
          <DialogContent sx={{ mb: 0 }}>
            <DialogContentText id="alert-dialog-description">
              <Box
                sx={{ ...flexCenter, flexDirection: "column", gap: 2, mb: 1 }}
              >
                <img
                  src={AlertIconUrl}
                  style={{ height: "40px", width: "40px" }}
                />
                <Typography
                  color="error"
                  sx={{ fontWeight: 600, fontSize: "18px" }}
                >
                  Alert
                </Typography>
              </Box>{" "}
              <Typography
                variant="regular"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Are you sure you want to delete student{" "}
                <b>{data?.studentName}</b> ?
              </Typography>
              <br />
              <Typography
                variant="regular"
                sx={{
                  color: (theme) => theme.palette.content.quizQuestion,
                }}
              >
                Note: All the data related to this student will be permanently
                erased.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button disabled={loading} onClick={handleDelete} variant="primary">
              Delete{" "}
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
