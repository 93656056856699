import { Box, Button, IconButton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { PATCH, POST } from "../../../Apis/constVariables";
import { itemsCenter } from "../../../common/commonStyles";
import { AddIconBig, RemoveIconBig } from "../../../images/icons";
import { validateBasicQuestionForm } from "../CreateQuestion";
import "../styles/createQuiz.css";
export const scrambleArr = (arr) => {
  //console.log(arr[0]);
  let res = Array.from(arr);
  return res.sort(() => 0.5 - Math.random());
};

const initLeft = ["", ""];
const initRight = [
  {
    text: "",
    match: 0,
  },
  {
    text: "",
    match: 1,
  },
];
const Matchthepair = ({
  editAble,
  questionFormData,
  fetchQuizQuestionsAgain,
  toggleQuestionForm,
}) => {
  const [loading, setLoading] = useState(false);
  const [canEditMatchPair, setCanEditMatchPair] = useState(true);
  const [leftOptions, setLeftOptions] = useState(initLeft);
  const [rightOptions, setRightOptions] = useState(initRight);

  const handleMatchPair = (e, order, side) => {
    //console.log("onChange");
    if (e?.target?.value?.length > 35) {
      toast.error("Max 35 characters allowed");
      return;
    }
    if (!canEditMatchPair) return;
    if (side == "left") {
      setLeftOptions((prev) => {
        let item = e.target.value;

        let res = Array.from(prev);
        res[order - 1] = item;
        return res;
      });
    } else {
      setRightOptions((prev) => {
        let item = {
          text: e.target.value,
          match: order,
        };
        let res = Array.from(prev);
        res[order - 1] = item;
        return res;
      });
    }
  };

  const handleMatchUpScramble = () => {
    setCanEditMatchPair(false);
    setRightOptions((prev) => {
      let res = scrambleArr(prev);
      return res;
    });
  };

  const handleErrorInit = () => {
    const temp = {
      leftOptions: new Array(leftOptions?.length).fill(""),
      rightOptions: new Array(rightOptions?.length).fill({ text: "" }),
    };
    setCheckErrorInForm(temp);
  };

  const [checkErrorInForm, setCheckErrorInForm] = useState({
    leftOptions: new Array(leftOptions?.length).fill(""),
    rightOptions: new Array(rightOptions?.length).fill({ text: "" }),
  });

  const [formCheck, setFormCheck] = useState("");
  function submit(e) {
    e.preventDefault();
  }

  const handleAdd = () => {
    setLeftOptions((prevLeftOptions) => [...prevLeftOptions, ""]);
    setRightOptions((prevRightOptions) => [
      ...prevRightOptions,
      { text: "", match: prevRightOptions.length + 1 },
    ]);
    handleErrorInit();
  };

  const handleRemove = (id) => {
    setLeftOptions((prevOptions) =>
      prevOptions.filter((option, index) => index !== id)
    );
    setRightOptions((prevOptions) =>
      prevOptions.filter((option, index) => index !== id)
    );
    handleErrorInit();
  };

  const addData = async () => {
    try {
      const formValidator = validateBasicQuestionForm(questionFormData);
      if (formValidator?.error) {
        toast.error(formValidator.message);
        return;
      }
      let nsn = questionFormData.questionName.replaceAll(" ", "");

      let dat = /\//i.test(nsn);
      if (!dat) {
      } else {
        toast.error("You can not use any (/) characters");
        return;
      }
      let err = false;
      leftOptions?.forEach((e) => {
        if (!e?.trim()) {
          err = true;
        }
      });
      rightOptions?.forEach((e) => {
        if (!e?.text?.trim()) {
          err = true;
        }
      });
      if (err) {
        toast.error("Fields cannot be empty");
        return;
      }

      setLoading(true);
      const url = process.env.REACT_APP_QUESTION;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...questionFormData,
          data: { options: leftOptions, correctOptions: rightOptions },
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchQuizQuestionsAgain();
      toggleQuestionForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
  const updateData = async () => {
    try {
      const formValidator = validateBasicQuestionForm(questionFormData);
      if (formValidator?.error) {
        toast.error(formValidator.message);
        return;
      }
      let nsn = questionFormData.questionName.replaceAll(" ", "");

      let dat = /\//i.test(nsn);
      if (!dat) {
      } else {
        toast.error("You can not use any (/) characters");
        return;
      }
      let err = false;
      leftOptions?.forEach((e) => {
        if (!e?.trim()) {
          err = true;
        }
      });
      rightOptions?.forEach((e) => {
        if (!e?.text?.trim()) {
          err = true;
        }
      });
      if (err) {
        toast.error("Fields cannot be empty");
        return;
      }
      setLoading(true);
      const url =
        process.env.REACT_APP_QUESTION + `/${questionFormData?.questionId}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: {
          ...questionFormData,
          data: { options: leftOptions, correctOptions: rightOptions },
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchQuizQuestionsAgain();
      toggleQuestionForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (!questionFormData?.data) {
      return;
    }
    let left = questionFormData?.data?.options;
    setLeftOptions(left);
    let right = questionFormData?.data?.correctOptions?.sort(
      (a, b) => a.match - b.match
    );
    setRightOptions(right);

    return () => {
      setLeftOptions(initLeft);
      setRightOptions(initRight);
    };
  }, [questionFormData?.data]);

  return (
    <section className="quizForm-container">
      <form onSubmit={submit}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            style={{
              marginLeft: "1em",
            }}
          >
            {leftOptions.map((item, index) => (
              <>
                <p className="quizForm-matchUp-label">Option {index + 1}: </p>
                <input
                  disabled={canEditMatchPair ? "" : "disabled"}
                  className="quizForm-matchUp-inputBar"
                  value={item}
                  style={{
                    border: `${
                      checkErrorInForm?.leftOptions[index] == "error"
                        ? "2px solid red"
                        : "1px solid #d7d7d7"
                    }`,
                  }}
                  placeholder={"Left option " + (index + 1)}
                  onChange={(e) => {
                    handleMatchPair(e, index + 1, "left");
                  }}
                />
                {checkErrorInForm?.leftOptions[index] == "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter Option
                  </span>
                )}

                <br />
              </>
            ))}
          </Box>

          <Box>
            {rightOptions.map((item, index) => (
              <>
                <input
                  disabled={canEditMatchPair ? "" : "disabled"}
                  className="quizForm-matchUp-inputBar"
                  value={item.text}
                  style={{
                    border: `${
                      checkErrorInForm?.rightOptions[index]?.text == "error"
                        ? "2px solid red"
                        : "1px solid #d7d7d7"
                    }`,
                  }}
                  placeholder={"Answer " + (index + 1)}
                  onChange={(e) => {
                    handleMatchPair(e, index + 1, "right");
                  }}
                />

                {checkErrorInForm?.rightOptions[index]?.text == "error" && (
                  <span
                    style={{
                      marginLeft: "20px",
                      color: "red",
                      fontSize: "15px",
                    }}
                  >
                    Enter Option
                  </span>
                )}
                {index > rightOptions.length - 2 &&
                  index < 4 &&
                  canEditMatchPair && (
                    <IconButton onClick={handleAdd}>
                      <AddIconBig />
                    </IconButton>
                  )}
                {index > 1 && (
                  <IconButton onClick={() => handleRemove(index)}>
                    <RemoveIconBig />
                  </IconButton>
                )}
                <br />
              </>
            ))}
          </Box>
        </Box>
        <Button
          variant="primary-small"
          style={{
            marginLeft: "1em",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleMatchUpScramble();
          }}
        >
          {/* onClick={handleMatchUpScramble} */}
          Shuffle
        </Button>
      </form>
      {formCheck && <Box> Fill all the Required fields.</Box>}
      <Box sx={{ ...itemsCenter, justifyContent: "flex-end" }}>
        <Button
          sx={{
            textTransform: "none",
          }}
          onClick={toggleQuestionForm}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          variant="primary-small"
          onClick={editAble ? updateData : addData}
        >
          Save
        </Button>
      </Box>
    </section>
  );
};

export default Matchthepair;
