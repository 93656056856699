import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { itemsCenter } from "../../../../common/commonStyles";
import { EditIconColor } from "../../../../images/icons";
import DeleteModuleModal from "./DeleteModuleModal";
export default function ModuleCard({
  data,
  index,
  fetchAgain,
  provided,
  snapshot,
}) {
  const navigate = useNavigate();
  return (
    <Box
      // ref={provided.innerRef}
      // snapshot={snapshot}
      // key={data?.moduleId}
      // {...provided.draggableProps}
      // {...provided.dragHandleProps}
      sx={{
        my: 2,
        padding: "14px 24px",
        border: "1px solid #BFBFBF",
        backgroundColor: (theme) => theme.palette.background.secondary,
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.12)",
        ...itemsCenter,
        borderRadius: "5px",
      }}
    >
      <Box
        sx={{
          ...itemsCenter,
          gap: 2,
        }}
      >
        {/* <SvgVector fill={"#358b8a"} /> */}
        <Box
          sx={{ ...itemsCenter, gap: 0.5, cursor: "pointer" }}
          onClick={() => {
            navigate("builder", {
              state: { courseData: data, see: true },
            });
          }}
        >
          <Typography
            variant="semibold"
            sx={{
              color: (theme) => theme.palette.text.main,
            }}
          >
            Module {index + 1}:
          </Typography>
          <Typography
            variant="medium"
            sx={{
              color: (theme) => theme.palette.text.light,
            }}
          >
            {data?.moduleName}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ borderLeft: "1px solid #BFBFBF", pl: 2, ml: "auto" }}>
        <IconButton
          sx={{ py: 0 }}
          onClick={() => {
            navigate("builder", {
              state: { courseData: data },
            });
          }}
        >
          <EditIconColor />
        </IconButton>
        <DeleteModuleModal data={data} fetchAgain={fetchAgain} />
      </Box>
    </Box>
  );
}
