import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../../Apis/axiosPayloads";
import { POST } from "../../../../Apis/constVariables";
import { flexCenter } from "../../../../common/commonStyles";
import { PaymentSuccessIcon } from "../../../../images/icons";

export default function PaymentSuccess({ data }) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    // localStorage.clear();
    setOpen(false);
  };

  useEffect(() => {
    if (!data) return;
    try {
      let parsedData = JSON.parse(JSON.parse(data.data));
      let amount = data?.total;
      let student = parsedData?.numberOfStudent;
      let paymentMode = parsedData.paymentMode;
      let formattedData = {
        amount,
        student,
        WiPayResponse: data,
        paymentMode,
      };

      const url = process.env.REACT_APP_SUBSCRIBE_MORE;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: formattedData,
      });
      axios
        .request(config)
        .then((apiRes) => {
          if (apiRes.data?.refreshPage) {
            window.location.href = window.location.pathname;
          }
        })
        .catch((e) => console.log(e));
      toast("Student limit increased");
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "13px",
            backgroundColor: (theme) => theme.palette.background.main,
          },
        }}
      >
        <Box sx={{ width: "400px", pb: "20px", textAlign: "center" }}>
          {/* <form onSubmit={handleDeleteAdmission} autoComplete="off"> */}
          <DialogContent sx={{ mb: 0 }}>
            <DialogContentText id="alert-dialog-description">
              <Box sx={{ ...flexCenter, flexDirection: "column", mb: 1 }}>
                <PaymentSuccessIcon />
                <Typography
                  variant="regular"
                  sx={{
                    color: "#2B8E2F",
                    fontWeight: "700",
                  }}
                >
                  Success
                </Typography>
              </Box>
              <Typography
                variant="regular"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                We are delighted to inform you that we received your payment.
              </Typography>
              <br />
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Close
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
