import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { flexCenter } from "../../../common/commonStyles";
import { noGroups } from "../../../const/imageUrl";
import GroupCard from "../../Facilitator/CommunityProject/GroupCard";

export default function ParentGroupComponent({ apiData }) {
  // const arr = new Array(10).fill(1);

  return (
    <Box
      sx={{
        width: "50%",
      }}
    >
      <Typography
        variant="semibold"
        sx={{ color: (theme) => theme.palette.primary.main }}
      >
        Groups
      </Typography>
      <Box
        sx={{
          mt: 1,
          p: 2,
          borderRadius: "13px",
          height: "65vh",
          backgroundColor: (theme) => theme.palette.background.secondary,
          overflow: "auto",
        }}
      >
        {apiData.loading && (
          <Box sx={flexCenter}>
            <CircularProgress />
          </Box>
        )}
        {apiData?.res?.data?.length < 1 && (
          <Box
            sx={{
              mt: 3,
              ...flexCenter,
              flexDirection: "column",
              py: 10,
              gap: 2,
            }}
          >
            <img src={noGroups} alt="no request" />
            <Typography
              variant="bold"
              sx={{
                color: (theme) => theme.palette.text.light,
              }}
            >
              No Groups
            </Typography>
          </Box>
        )}
        {apiData?.res?.data?.map((e, i) => (
          <GroupCard data={e} key={i} />
        ))}
      </Box>
    </Box>
  );
}
