import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ModuleStatus from "../../../Components/Admin/Dashboard/ModuleStatus";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import {
  dFlex,
  flexBetweenCenter,
  flexCenter,
  itemsCenter,
} from "../../../common/commonStyles";
import { formatNumberWithOrdinal } from "../../../const/constFunctions";
import { UserContext } from "../../../context/UserContext";
import { LeaderboardIcon, MessageIcon } from "../../../images/icons";

export default function StudentDetails() {
  const { userDetails } = useContext(UserContext);
  const [userInfo, setUserInfo] = useState({ studentName: "" });
  const param = useParams();
  const [apiCall] = useDataFetchHook(
    process.env.REACT_APP_GET_STUDENT_MODULE_PROGRESS + `${param?.studentId}`
  );
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const state = location?.state ?? {};
    setUserInfo(state?.studentInfo ?? { studentName: "" });
  }, [location.state]);

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Student Progress", userInfo?.studentName]} />
      <Box sx={{ ...dFlex, minHeight: "60vh", gap: "5%" }}>
        <Box
          sx={{
            ...itemsCenter,
            flex: "25%",
            minWidth: "220px",
            maxWidth: "300px",
            borderRadius: "16px",
            background: "#FFF",
            py: 4,
            flexDirection: "column",
          }}
        >
          <Avatar
            src={userInfo?.image}
            sx={{ width: "190px", height: "190px", fontSize: "46px" }}
          >
            {userInfo?.studentName?.[0]}
          </Avatar>
          <Stack spacing={2} sx={{ mt: "30px", width: "80%" }}>
            <Box sx={{ ...itemsCenter }}>
              <Typography
                variant="semibold"
                sx={{
                  color: (theme) => theme.palette.text.main,
                  width: "70px",
                }}
              >
                Name:
              </Typography>
              <Typography
                variant="semibold"
                sx={{ ml: 1.5, color: (theme) => theme.palette.primary.main }}
              >
                {userInfo?.studentName ?? ""}
              </Typography>
            </Box>
            <Box sx={{ ...itemsCenter }}>
              <Typography
                variant="semibold"
                sx={{
                  color: (theme) => theme.palette.text.main,
                  width: "70px",
                }}
              >
                Batch:
              </Typography>
              <Typography
                variant="semibold"
                sx={{ ml: 1.5, color: (theme) => theme.palette.primary.main }}
              >
                {userInfo?.batchName}
              </Typography>
            </Box>
            {/* <Box sx={{ ...itemsCenter }}>
              <Typography
                variant="semibold"
                sx={{
                  color: (theme) => theme.palette.text.main,
                  width: "70px",
                }}
              >
                Group:
              </Typography>
              <Typography
                variant="semibold"
                sx={{ ml: 1.5, color: (theme) => theme.palette.primary.main }}
              >
                Group 1
              </Typography>
            </Box> */}
            <Box sx={{ ...itemsCenter }}>
              <Typography
                variant="semibold"
                sx={{
                  color: (theme) => theme.palette.text.main,
                  width: "70px",
                }}
              >
                Tokens:
              </Typography>
              <Typography
                variant="semibold"
                sx={{ ml: 1.5, color: (theme) => theme.palette.primary.main }}
              >
                {userInfo?.totalTokens ?? 0}
              </Typography>
            </Box>
            {/* <Box sx={{ ...itemsCenter }}>
              <Typography
                variant="semibold"
                sx={{
                  color: (theme) => theme.palette.text.main,
                  width: "70px",
                }}
              >
                Progress:
              </Typography>
              <Typography
                variant="semibold"
                sx={{ ml: 1.5, color: (theme) => theme.palette.primary.main }}
              >
                Good
              </Typography>
            </Box> */}
            <Box sx={{ ...flexBetweenCenter, mt: "24px!important" }}>
              <Box
                sx={{
                  width: "fit-content",
                  position: "relative",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate("risk-profiling", {
                      state: {
                        studentInfo: userInfo,
                      },
                    });
                  }}
                  sx={{ textTransform: "none" }}
                >
                  Risk Profiling
                </Button>
                {(!userInfo?.ACE || !userInfo?.SDQPre) && (
                  <FiberManualRecordIcon
                    sx={{
                      color: "red",
                      position: "absolute",
                      top: -8,
                      right: -8,
                      fontSize: "20px",
                    }}
                  />
                )}
              </Box>
              <Box>
                <IconButton
                  disabled={
                    userDetails?.isArchived || userDetails.subscriptionExpired
                  }
                  onClick={() => {
                    navigate("/facilitator/community-channel");
                  }}
                >
                  <MessageIcon />
                </IconButton>
              </Box>
            </Box>
          </Stack>
        </Box>
        <Box sx={{ flex: "65%", minWidth: "600px" }}>
          <Box sx={{ mb: 15 }}>
            {apiCall?.loading && (
              <Box sx={{ ...flexCenter }}>
                <CircularProgress />
              </Box>
            )}
            {apiCall?.res?.sequence?.map((e, index) => (
              <ModuleStatus
                key={index}
                index={index + 1}
                userInfo={userInfo}
                data={e}
                status={e.completed ? "completed" : "ongoing"}
                show={true}
              />
            ))}
          </Box>
          <Box
            sx={{
              ...dFlex,
              pl: 1,
              gap: 10,
              // justifyContent: "space-evenly",
            }}
          >
            <Box>
              <Typography
                variant="bold"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Leaderboard Ranking this week
              </Typography>
              <Box sx={{ mt: 2, ...itemsCenter, gap: 4 }}>
                <LeaderboardIcon />
                <Typography
                  variant="semibold"
                  sx={{
                    fontSize: "35px",
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  {formatNumberWithOrdinal(userInfo?.batchRank ?? 0)}
                </Typography>
              </Box>
            </Box>
            {/* <Box>
              <Typography
                variant="bold"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Leaderboard Ranking untill now
              </Typography>
              <Box sx={{ mt: 2, ...itemsCenter, gap: 4 }}>
                <LeaderboardIcon />
                <Typography
                  variant="semibold"
                  sx={{
                    fontSize: "35px",
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  89th
                </Typography>
              </Box>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
