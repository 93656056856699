import { AccordionDetails, Typography } from "@mui/material";
import React from "react";
import useDataFetchHook from "../../Hooks/DataFetchHook";
import { AccordionTransition } from "../../const/constFunctions";
import AudioPlayerModal from "./ItemModals/AudioPlayerModal";
import PdfRenderModal from "./ItemModals/PdfRenderModal";
import VideoPlayerModal from "./ItemModals/VideoPlayerModal";
import WebModal from "./ItemModals/WebModal";
import { Accordion, AccordionSummary } from "./ModuleTest";
import QuizElement from "./QuizElement";

export default function TopicElement({ data, index, unPublished }) {
  const [itemApiCall] = useDataFetchHook(
    unPublished
      ? process.env.REACT_APP_ALL_ITEM_TOPIC + `?topicId=${data?.topicId}`
      : process.env.REACT_APP_ALL_PUBLISHED_ITEM + `?topicId=${data?.topicId}`
  );
  const [quizApiCall] = useDataFetchHook(
    unPublished
      ? process.env.REACT_APP_TOPIC_QUIZ + `?topicId=${data.topicId}`
      : process.env.REACT_APP_ALL_PUBLISHED_QUIZ + `?topicId=${data?.topicId}`
  );

  return (
    <Accordion
      TransitionProps={AccordionTransition}
      sx={{ p: 0, mb: 1, mt: 2 }}
    >
      <AccordionSummary
        sx={{
          maxHeight: "50px",
          // border: "none",
          // backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        <Typography
          variant="bold2"
          sx={{ color: (theme) => theme.palette.text.main }}
        >
          Topic {index + 1}: {data?.topicName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: "#FFF",
        }}
      >
        <Typography sx={{ color: (theme) => theme.palette.text.main }}>
          {data?.topicDescription}
        </Typography>{" "}
        {itemApiCall?.res?.items?.map((e, i) => {
          if (e.itemType == "pdf") {
            return <PdfRenderModal key={i} data={e} />;
          }
          if (e.itemType == "video") {
            return <VideoPlayerModal key={i} data={e} />;
          }
          if (e.itemType == "html") {
            return <WebModal key={i} data={e} />;
          }
          if (e.itemType == "audio") {
            return <AudioPlayerModal key={i} data={e} />;
          }
        })}
        {quizApiCall?.res?.quiz?.map((e) => (
          <QuizElement key={e?.quizId} data={e} unPublished={unPublished} />
        ))}{" "}
      </AccordionDetails>
    </Accordion>
  );
}
