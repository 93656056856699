import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import useTablePagination from "../../../Hooks/TablePaginationHook";
import DownloadSelect from "../../../common/DownloadSelect";
import ProgressBar from "../../../common/ProgressBar";
import { dFlex, flexSpaceBetween } from "../../../common/commonStyles";
import {
  StyledTableCell,
  StyledTableRow,
  TableRowSkeletonComponent,
} from "../../../const/Table";
import { FormTextField } from "../../../const/TextField";
import {
  ArrowDropDownIconSvg,
  ArrowDropUpIconSvg,
} from "../../../images/icons";
export default function FacilitatorBatchInfoTab({ data, schoolData }) {
  const url = `${process.env.REACT_APP_SCHOOL_GET_STUDENT_BATCH}${data?.batchName}&offset=0&limit=50`;
  const [searchText, setSearchText] = useState(null);
  const [searchData, setSearchData] = useState([]);
  const [studentApiCall] = useDataFetchHook(url);

  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();
  const [selectedSortUp, setSelectedSortUp] = useState("");
  const [selectedSortDown, setSelectedSortDown] = useState("");

  const navigate = useNavigate();
  const params = useParams();

  const handleSearch = () => {
    try {
      // console.log("searhing");
      let arr = [...studentApiCall?.res?.Student];
      arr = arr?.filter((e) => {
        return e?.studentName
          ?.toLowerCase()
          ?.includes(searchText.toLowerCase());
      });
      handleChangePage(null, 0);
      setSearchData(arr);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!searchText) {
      setSearchData(studentApiCall?.res?.Student);
      return;
    }
    handleSearch();
  }, [searchText]);
  useEffect(() => {
    if (!studentApiCall?.res) {
      return;
    }
    setSearchData(studentApiCall?.res?.Student);
  }, [studentApiCall?.res]);

  const handleClear = () => {
    setSelectedSortDown("");
    setSelectedSortUp("");
    setSearchText("");
    setSearchData(studentApiCall?.res?.Student);
  };

  const sortArr = (para) => {
    setSelectedSortUp(para);
    setSelectedSortDown("");

    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "studentName":
          fir = a.studentName.toLowerCase();
          sec = b.studentName.toLowerCase();
          break;
        case "totalTokens":
          fir = parseInt(a.totalTokens);
          sec = parseInt(b.totalTokens);
          break;
        case "batchRank":
          fir = parseInt(a.batchRank);
          sec = parseInt(b.batchRank);
          break;
        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir < sec) {
        return -1;
      }
      if (fir > sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  const sortArrDec = (para) => {
    setSelectedSortUp("");
    setSelectedSortDown(para);

    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "studentName":
          fir = a.studentName.toLowerCase();
          sec = b.studentName.toLowerCase();
          break;
        case "totalTokens":
          fir = parseInt(a.totalTokens);
          sec = parseInt(b.totalTokens);
          break;
        case "batchRank":
          fir = parseInt(a.batchRank);
          sec = parseInt(b.batchRank);
          break;
        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir > sec) {
        return -1;
      }
      if (fir < sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  const downloadCsv = () => {
    let downloadData = searchData;
    downloadData = downloadData.map((e) => {
      let formattedData = {
        "Student Name": e?.studentName,
        "Tokens Earned": e?.totalTokens,
        "Batch Rank": e?.batchRank,
        "Current Module Name": e?.moduleData?.moduleName ?? "NA",
        "Completion Percentage": `${e.percentage ?? 0}%`,
      };
      return formattedData;
    });

    const csv = Papa.unparse(downloadData);
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${schoolData?.name}_${
      data?.batchName
    }_Student_Data_${new Date().toString()}.csv`;
    link.click();
  };

  return (
    <>
      <Box sx={{ ...flexSpaceBetween, alignItems: "center" }}>
        {/* Search input field */}
        <Box>
          <FormTextField
            placeholder="search"
            sx={{ width: 300 }}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            disabled={studentApiCall?.loading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    fontSize="small"
                    sx={{
                      color: "#BFBFBF",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Button
            onClick={handleClear}
            sx={{
              ml: 2,
              textTransform: "none",
              color: (theme) => theme.palette.text.main,
            }}
          >
            {" "}
            x Clear
          </Button>
        </Box>
        {/* File type select */}
        <DownloadSelect downloadCsv={downloadCsv} />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography
          variant="regular"
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
        >
          Total Students: {studentApiCall?.res?.Student?.length ?? 0}
        </Typography>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: "13px",
          my: 2,
          pt: 1,
          px: "20px",
          boxSizing: "border-box",
          maxHeight: studentApiCall?.loading ? "400px" : "1200px",
          transition: "all 1s ease",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Box sx={{ ...dFlex, gap: 1 }}>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Name
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArr("studentName")}
                    >
                      <ArrowDropUpIconSvg
                        focus={selectedSortUp == "studentName"}
                      />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArrDec("studentName")}
                    >
                      <ArrowDropDownIconSvg
                        focus={selectedSortDown == "studentName"}
                      />
                    </Box>
                  </Box>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ ...dFlex, gap: 1 }}>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Tokens
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArr("totalTokens")}
                    >
                      <ArrowDropUpIconSvg
                        focus={selectedSortUp == "totalTokens"}
                      />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArrDec("totalTokens")}
                    >
                      <ArrowDropDownIconSvg
                        focus={selectedSortDown == "totalTokens"}
                      />
                    </Box>
                  </Box>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ ...dFlex, gap: 1 }}>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Ranking
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArr("batchRank")}
                    >
                      <ArrowDropUpIconSvg
                        focus={selectedSortUp == "batchRank"}
                      />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArrDec("batchRank")}
                    >
                      <ArrowDropDownIconSvg
                        focus={selectedSortDown == "batchRank"}
                      />
                    </Box>
                  </Box>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  variant="medium"
                  sx={{ color: (theme) => theme.palette.text.dark }}
                >
                  Module
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  variant="medium"
                  sx={{ color: (theme) => theme.palette.text.dark }}
                >
                  Status
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {studentApiCall?.loading ? (
            <TableRowSkeletonComponent column={5} row={4} />
          ) : (
            <TableBody>
              {searchData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow
                    onClick={() => {
                      navigate(
                        `/admin/dashboard/school-subscribers/${params?.schoolId}/student-information/${row?.id}`,
                        {
                          state: {
                            schoolInfo: schoolData,
                            studentInfo: row,
                          },
                        }
                      );
                    }}
                  >
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row.studentName}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row.totalTokens ?? 0}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row.batchRank ?? 0}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row?.certificateIssued
                          ? "All Modules Completed"
                          : row?.moduleData?.moduleName ?? "Yet to start"}{" "}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        <ProgressBar
                          height={"20px"}
                          progress={row.percentage}
                          fontSize={"12px"}
                        />
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <TablePagination
          rowsPerPageOptions={[]}
          count={searchData?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
}
