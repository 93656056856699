import { Avatar, Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ParentStudentInfoTable from "../../../Components/Admin/Dashboard/ParentStudentInfoTable";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { itemsCenter } from "../../../common/commonStyles";

export default function ParentInfoData() {
  const [parentData, setParentData] = useState({ name: "" });

  const params = useParams();
  const [parentInfo] = useDataFetchHook(
    process.env.REACT_APP_SA_DASHBOARD_LIST +
      `/subscriber/${params.parentId}?type=parent`
  );

  const location = useLocation();

  useEffect(() => {
    // Set school data from the location state
    if (!parentInfo.res) {
      return;
    }
    setParentData(parentInfo?.res?.data);

    return () => {
      setParentData({ name: "" });
    };
  }, [parentInfo?.res]);
  return (
    <Box>
      <NavBreadCrumbs
        pageTags={["Dashboard", "Parent Subscribers", parentData?.name]}
      />
      <Box
        sx={{
          ...itemsCenter,
          gap: "4%",
          mb: 5,
        }}
      >
        <Avatar
          src={parentData?.image}
          alt="profile image"
          sx={{ width: "106px", height: "106px" }}
        >
          {parentData?.name?.[0]}
        </Avatar>

        <Box sx={{ flex: 1 }}>
          <Stack spacing={2}>
            <Box sx={{ ...itemsCenter, gap: "20px" }}>
              <Typography
                variant="medium"
                sx={{
                  fontSize: "16px",
                  width: "70px",
                  color: (theme) => theme.palette.text.dark,
                }}
              >
                Name:
              </Typography>
              <Typography
                variant="regular"
                sx={{
                  width: "60%",
                  color: (theme) => theme.palette.text.main,
                }}
              >
                {parentData?.name}
              </Typography>
            </Box>
            <Box sx={{ ...itemsCenter, gap: "20px" }}>
              <Typography
                variant="medium"
                sx={{
                  fontSize: "16px",
                  width: "70px",
                  color: (theme) => theme.palette.text.dark,
                }}
              >
                Email:
              </Typography>
              <Typography
                variant="regular"
                sx={{
                  width: "60%",
                  color: (theme) => theme.palette.text.main,
                }}
              >
                {parentData?.email ?? "NA"}
              </Typography>
            </Box>
            <Box sx={{ ...itemsCenter, gap: "20px" }}>
              <Typography
                variant="medium"
                sx={{
                  fontSize: "16px",
                  width: "70px",
                  color: (theme) => theme.palette.text.dark,
                }}
              >
                Phone:
              </Typography>
              <Typography
                variant="regular"
                sx={{
                  width: "60%",
                  color: (theme) => theme.palette.text.main,
                }}
              >
                {parentData?.phone ?? "NA"}
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box>
        <ParentStudentInfoTable parentData={parentData} />
      </Box>
    </Box>
  );
}
