import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import ProgressBar from "../../../common/ProgressBar";
import { flexBetweenWithItemsCenter } from "../../../common/commonStyles";
import {
  StyledTableCell,
  StyledTableRow,
  TableRowSkeletonComponent,
} from "../../../const/Table";
import { AdminInfoIcon, RightArrow } from "../../../images/icons";

export default function SchoolSubscriberData() {
  const navigate = useNavigate();
  const [schoolData, setSchoolData] = useState({ name: "" });
  const location = useLocation();
  const params = useParams();

  const [studentListApi] = useDataFetchHook(
    process.env.REACT_APP_SA_DASHBOARD_LIST +
      `/subscriber/student?type=school&limit=${4}&schoolId=${params?.schoolId}`
  );
  const [facilitatorListApi] = useDataFetchHook(
    process.env.REACT_APP_SA_DASHBOARD_LIST +
      `/subscriber/facilitators?limit=${4}&schoolId=${params?.schoolId}`
  );

  useEffect(() => {
    const { data } = location?.state ?? { name: "" };
    setSchoolData(data);

    return () => {
      setSchoolData({ name: "" });
    };
  }, [location.state]);

  return (
    <Box>
      <NavBreadCrumbs
        pageTags={["Dashboard", "School Subscribers", schoolData?.name]}
      />

      <Box>
        <Box sx={flexBetweenWithItemsCenter}>
          <Typography
            variant="bold"
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          >
            Student Information
          </Typography>
          <Box>
            <Button
              onClick={() => {
                navigate("admin", {
                  state: {
                    data: schoolData,
                  },
                });
              }}
              variant="primary"
            >
              <AdminInfoIcon />
              Admin Info
            </Button>{" "}
            {/* <DeleteSchoolModal /> */}
          </Box>
        </Box>

        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "13px",
            my: 3,
            pt: 1,
            px: "10px",
            boxSizing: "border-box",
            maxHeight: studentListApi?.loading ? "200px" : "600px",
            transition: "all 1s ease",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Name
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Tokens
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Ranking
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Module
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Status
                  </Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {studentListApi?.loading ? (
              <TableRowSkeletonComponent column={5} row={2} />
            ) : (
              <TableBody>
                {studentListApi?.res?.data?.slice(0, 4).map((row, index) => (
                  <StyledTableRow
                    onClick={() => {
                      navigate(`student-information/${row?.id}`, {
                        state: {
                          schoolInfo: schoolData,
                          studentInfo: row,
                        },
                      });
                    }}
                  >
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row.studentName}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row.totalTokens ?? 0}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row.rank ?? 0}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row?.certificateIssued
                          ? "All Modules Completed"
                          : row?.moduleData?.moduleName ?? "Yet to start"}{" "}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        <ProgressBar
                          height={"20px"}
                          progress={row?.percentage ?? 0}
                          fontSize="12px"
                        />
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
          onClick={() =>
            navigate("student-information", {
              state: {
                school: schoolData,
              },
            })
          }
        >
          <Typography
            variant="medium"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            See More <RightArrow />
          </Typography>
        </Box>
      </Box>

      <Box sx={{ mt: 5 }}>
        <Typography
          variant="bold"
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
        >
          Facilitator Information
        </Typography>
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "13px",
            my: 3,
            pt: 1,
            px: "10px",
            boxSizing: "border-box",
            maxHeight: facilitatorListApi?.loading ? "200px" : "600px",
            transition: "all 1s ease",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Name
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Phone
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    E-mail
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Batches
                  </Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {facilitatorListApi?.loading ? (
              <TableRowSkeletonComponent column={4} row={2} />
            ) : (
              <TableBody>
                {facilitatorListApi?.res?.data
                  ?.slice(0, 4)
                  .map((row, index) => (
                    <StyledTableRow
                      sx={{
                        cursor: "pointer",
                        //   "&:hover": {
                        //     boxShadow: (theme) => `0px 0px 1px 1px lightgray inset`,
                        //   },
                      }}
                      onClick={() => {
                        navigate(`facilitator-information/${row?.id}`, {
                          state: {
                            schoolInfo: schoolData,
                            facilitatorInfo: row,
                          },
                        });
                      }}
                    >
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row?.instructorName ?? "NA"}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row?.phone ?? "NA"}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ textTransform: "lowercase!important" }}
                      >
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row?.email ?? "NA"}
                        </Typography>
                      </StyledTableCell>

                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row.batchCount ?? 0}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
          onClick={() =>
            navigate("facilitator-information", {
              state: {
                school: schoolData,
              },
            })
          }
        >
          <Typography
            variant="medium"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            See More <RightArrow />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
