import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../Apis/axiosPayloads";
import { POST } from "../../Apis/constVariables";
import { flexCenter } from "../../common/commonStyles";
import { PaymentSuccessIcon } from "../../images/icons";

export default function PaymentSuccess({ data }) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    // localStorage.clear();
    setOpen(false);
  };

  useEffect(() => {
    if (!data) return;
    try {
      let email = JSON.parse(JSON.parse(data.data))?.email;
      console.log({ email });
      const url2 = process.env.REACT_APP_REGISTER_STATUS + "/success";
      const config2 = createAxiosConfig({
        endPoint: url2,
        method: POST,
        data: {
          email: email,
          paymentStatus: "success",
          data,
        },
      });
      axios
        .request(config2)
        .then((e) => {
          // localStorage.clear()
          console.log("Registered Successfully");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong, Please Contact Administrator");
        });
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong, Please Contact Administrator");
    }
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "13px",
            backgroundColor: (theme) => theme.palette.background.main,
          },
        }}
      >
        <Box sx={{ width: "400px", pb: "20px", textAlign: "center" }}>
          {/* <form onSubmit={handleDeleteAdmission} autoComplete="off"> */}
          <DialogContent sx={{ mb: 0 }}>
            <DialogContentText id="alert-dialog-description">
              <Box sx={{ ...flexCenter, flexDirection: "column", mb: 1 }}>
                <PaymentSuccessIcon />
                <Typography
                  variant="regular"
                  sx={{
                    color: "#2B8E2F",
                    fontWeight: "700",
                  }}
                >
                  Success
                </Typography>
              </Box>
              <Typography
                variant="regular"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                We are delighted to inform you that we received your payment.
              </Typography>
              <br />
              <Typography
                variant="regular"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Please login to continue.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Close
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
