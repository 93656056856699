import {
  AccordionDetails,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import React from "react";
import useDataFetchHook from "../../Hooks/DataFetchHook";
import { flexCenter } from "../../common/commonStyles";
import { AccordionTransition } from "../../const/constFunctions";
import BrawtaElement from "./BrawtaElement";
import AudioPlayerModal from "./ItemModals/AudioPlayerModal";
import PdfRenderModal from "./ItemModals/PdfRenderModal";
import ScromModal from "./ItemModals/ScromModal";
import VideoPlayerModal from "./ItemModals/VideoPlayerModal";
import WebModal from "./ItemModals/WebModal";
import { Accordion, AccordionSummary } from "./ModuleTest";
import QuizElement from "./QuizElement";
import TopicElement from "./TopicElement";

export default function LessonElement({ data, index, unPublished }) {
  const [apiCall] = useDataFetchHook(
    unPublished
      ? process.env.REACT_APP_ALL_TOPIC + `?lessonId=${data?.lessonId}`
      : process.env.REACT_APP_ALL_PUBLISHED_TOPIC + `${data?.lessonId}`
  );
  const [itemApiCall] = useDataFetchHook(
    unPublished
      ? process.env.REACT_APP_ALL_ITEM + `?lessonId=${data?.lessonId}`
      : process.env.REACT_APP_ALL_PUBLISHED_ITEM + `?lessonId=${data?.lessonId}`
  );
  const [quizApiCall] = useDataFetchHook(
    unPublished
      ? process.env.REACT_APP_LESSON_QUIZ + `?lessonId=${data?.lessonId}`
      : process.env.REACT_APP_ALL_PUBLISHED_QUIZ + `?lessonId=${data?.lessonId}`
  );
  const [brawtaApiCall] = useDataFetchHook(
    unPublished
      ? process.env.REACT_APP_LESSON_BRAWTA + `?lessonId=${data?.lessonId}`
      : process.env.REACT_APP_ALL_PUBLISHED_BRAWTA + `${data?.lessonId}`
  );
  return (
    <Accordion TransitionProps={AccordionTransition} sx={{ p: 0, my: 2 }}>
      <AccordionSummary
        sx={{
          maxHeight: "55px",
          // border: "none",
          // backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        <Typography
          variant="bold2"
          sx={{ color: (theme) => theme.palette.text.main }}
        >
          Lesson {index + 1} : {data?.lessonName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: "16px 40px" }}>
        <Typography sx={{ color: (theme) => theme.palette.text.main }}>
          {data?.lessonDescription}
        </Typography>

        {(apiCall?.loading || itemApiCall?.loading || quizApiCall?.loading) && (
          <Box sx={{ ...flexCenter }}>
            <CircularProgress />
          </Box>
        )}

        {itemApiCall?.res?.items?.map((e, i) => {
          if (e.itemType == "pdf") {
            return <PdfRenderModal key={i} data={e} />;
          } else if (e.itemType == "video") {
            return <VideoPlayerModal key={i} data={e} />;
          } else if (e.itemType == "html") {
            return <WebModal key={i} data={e} />;
          } else if (e.itemType == "scrom") {
            return <ScromModal key={i} data={e} />;
          } else if (e.itemType == "audio") {
            return <AudioPlayerModal key={i} data={e} />;
          }
        })}
        {apiCall?.res?.topics?.map((e, i) => (
          <TopicElement
            key={e?.topicId}
            data={e}
            index={i}
            unPublished={unPublished}
          />
        ))}
        {quizApiCall?.res?.quiz?.map((e) => (
          <QuizElement key={e?.quizId} data={e} unPublished={unPublished} />
        ))}
        {brawtaApiCall?.res?.brawta?.map((e) => (
          <BrawtaElement key={e?.brawtaId} data={e} unPublished={unPublished} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
