import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {
  Avatar,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ModuleStatus from "../../../Components/Admin/Dashboard/ModuleStatus";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import { dFlex, flexCenter, itemsCenter } from "../../../common/commonStyles";
import { formatNumberWithOrdinal } from "../../../const/constFunctions";
import { LeaderboardIcon } from "../../../images/icons";
export default function StudentInfoDetails() {
  const [userInfo, setUserInfo] = useState({ studentName: "" });

  const param = useParams();
  const [apiCall] = useDataFetchHook(
    process.env.REACT_APP_GET_STUDENT_MODULE_PROGRESS + `${param?.studentId}`
  );
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const state = location?.state ?? {};
    console.log({ state });
    setUserInfo(state?.studentInfo ?? { studentName: "" });
  }, [location.state]);

  return (
    <Box>
      <Box sx={{ mb: 5 }}>
        <Typography
          variant="medium"
          sx={{
            ...itemsCenter,
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
            color: (theme) => theme.palette.text.main,
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <NavigateBeforeIcon />
          {userInfo?.studentName}
        </Typography>
      </Box>
      <Box sx={{ ...dFlex, minHeight: "40vh", gap: "5%" }}>
        <Box
          sx={{
            ...itemsCenter,
            flex: "25%",
            minWidth: "220px",
            maxWidth: "300px",
            borderRadius: "16px",
            background: "#FFF",
            pt: 4,
            pb: 8,
            flexDirection: "column",
            height: "fit-content",
          }}
        >
          <Avatar
            src={userInfo?.image}
            sx={{ width: "190px", height: "190px" }}
          >
            P
          </Avatar>
          <Stack spacing={2} sx={{ mt: "30px", width: "80%" }}>
            <Box sx={{ ...itemsCenter }}>
              <Typography
                variant="semibold"
                sx={{
                  color: (theme) => theme.palette.text.main,
                  width: "70px",
                }}
              >
                Name:
              </Typography>
              <Typography
                variant="semibold"
                sx={{ ml: 1.5, color: (theme) => theme.palette.primary.main }}
              >
                {userInfo?.studentName ?? ""}
              </Typography>
            </Box>
            <Box sx={{ ...itemsCenter }}>
              <Typography
                variant="semibold"
                sx={{
                  color: (theme) => theme.palette.text.main,
                  width: "70px",
                }}
              >
                Batch:
              </Typography>
              <Typography
                variant="semibold"
                sx={{ ml: 1.5, color: (theme) => theme.palette.primary.main }}
              >
                {userInfo?.batchName}
              </Typography>
            </Box>
            <Box sx={{ ...itemsCenter }}>
              <Typography
                variant="semibold"
                sx={{
                  color: (theme) => theme.palette.text.main,
                  width: "70px",
                }}
              >
                Tokens:
              </Typography>
              <Typography
                variant="semibold"
                sx={{ ml: 1.5, color: (theme) => theme.palette.primary.main }}
              >
                {userInfo?.totalTokens ?? 0}{" "}
              </Typography>
            </Box>
            {/* <Box sx={{ ...itemsCenter }}>
              <Typography
                variant="semibold"
                sx={{
                  color: (theme) => theme.palette.text.main,
                  width: "70px",
                }}
              >
                Progress:
              </Typography>
              <Typography
                variant="semibold"
                sx={{ ml: 1.5, color: (theme) => theme.palette.primary.main }}
              >
                Good
              </Typography>
            </Box> */}
          </Stack>
        </Box>
        <Box sx={{ flex: "65%", minWidth: "600px" }}>
          <Box sx={{ mb: 5 }}>
            {apiCall?.loading && (
              <Box sx={{ ...flexCenter }}>
                <CircularProgress />
              </Box>
            )}

            {apiCall?.res?.sequence?.map((e, index) => (
              <ModuleStatus
                key={index}
                index={index + 1}
                userInfo={userInfo}
                data={e}
                status={e.completed ? "completed" : "ongoing"}
              />
            ))}
          </Box>
          <Box
            sx={{
              ...dFlex,
              pl: 1,
              gap: 10,
              // justifyContent: "space-evenly",
            }}
          >
            <Box>
              <Typography
                variant="bold"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Leaderboard Ranking this week
              </Typography>
              <Box sx={{ mt: 2, ...itemsCenter, gap: 4 }}>
                <LeaderboardIcon />
                <Typography
                  variant="semibold"
                  sx={{
                    fontSize: "35px",
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  {formatNumberWithOrdinal(
                    userInfo?.schoolRank ?? userInfo?.globalRank ?? 0
                  )}{" "}
                </Typography>
              </Box>
            </Box>
            {/* <Box>
              <Typography
                variant="bold"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Leaderboard Ranking untill now
              </Typography>
              <Box sx={{ mt: 2, ...itemsCenter, gap: 4 }}>
                <LeaderboardIcon />
                <Typography
                  variant="semibold"
                  sx={{
                    fontSize: "35px",
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  {formatNumberWithOrdinal(userInfo?.schoolRank ?? 0)}{" "}
                </Typography>
              </Box>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
