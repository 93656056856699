import { Box } from "@mui/system";
import React from "react";
import { dFlex } from "../../../common/commonStyles";
import { Typography } from "@mui/material";
import AddFAQModal from "./AddFAQModal";
import { flexBetweenWithItemsCenter } from "../../../common/commonStyles";
import { textEllipses } from "../../../common/commonStyles";
import FAQDeleteModal from "./FAQDeleteModal";
export default function FAQQuestionComponent({ data, fetchAgain }) {
  return (
    <Box
      sx={{
        ...flexBetweenWithItemsCenter,
        maxWidth: "79vw",
        my: 2,
      }}
    >
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.secondary,
          p: "16px 10px",
          width: "95%",
          ...dFlex,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.12)",
        }}
      >
        <Typography
          variant="medium"
          sx={{
            ...textEllipses,
            width: "98%",
            color: (theme) => theme.palette.text.main,
          }}
        >
          {data?.question}
        </Typography>
        <AddFAQModal edit={true} data={data} fetchAgain={fetchAgain} />
      </Box>
      <FAQDeleteModal data={data} fetchAgain={fetchAgain} />
    </Box>
  );
}
