import { Box } from "@mui/material";
import React from "react";
import IntroMessage from "../../Components/Admin/Content/IntroMessage/IntroMessage";
import NavBreadCrumbs from "../../common/NavBreadCrumbs";

export default function IntroMessagePage() {
  return (
    <Box>
      <NavBreadCrumbs pageTags={["Intro Message"]} />
      <IntroMessage />
    </Box>
  );
}
