import { Box, Typography } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import { dFlex } from "../../../common/commonStyles";

export default function UserManualCard({ data, index }) {
  // const [loading, setLoading] = useState(false);
  const handleDownload = async () => {
    try {
      if (!data.file) return;
      const response = await fetch(data?.file);
      if (!response.ok) {
        throw new Error("Something went wrong while downloading");
      }

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      downloadLink.download = `Slide-${index + 1}`;
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      toast.error(error);
      console.error("Error while downloading:", error);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: (theme) => theme.palette.background.secondary,
        width: "98%",
        p: 3,
        my: 5,
        boxSizing: "border-box",
        maxHeight: "60vh",
        ...dFlex,
        flexDirection: { xs: "column", md: "row" },
        gap: 3,
        overflow: "scroll",
      }}
    >
      <Box sx={{ width: { xs: "100%", md: "50%" }, height: "350px" }}>
        <embed
          style={{ height: "100%", width: "100%", borderRadius: "6px" }}
          src={data?.file}
        />
      </Box>
      <Box>
        <Box sx={{ overflow: "auto", maxHeight: "85%", p: 1 }}>
          <Typography sx={{ color: (theme) => theme.palette.text.main }}>
            {data?.message}{" "}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          right: { xs: 0, md: 10 },
          bottom: { xs: 0, md: 10 },
          // top: { xs: 10, md: "95%" },
        }}
      >
        <Typography
          variant="semibold"
          onClick={handleDownload}
          sx={{
            color: (theme) => theme.palette.primary.main,
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          {" "}
          Download
        </Typography>
      </Box>
    </Box>
  );
}
