import React from "react";

export default function TOS() {
  return (
    <embed
      style={{ width: "100%", height: "100vh" }}
      src="https://firebasestorage.googleapis.com/v0/b/hypnotic-trees-396813.appspot.com/o/tnc%2FUNUH%20Combined%20Terms%20Of%20Use%20%26%20Privacy%20Policy.pdf?alt=media&token=4b3bd547-301f-4235-b883-139dc0ac9272"
    />
  );
}
