import {
  Box,
  Button,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { TabPanel } from "../../Admin/Dashboard/SchoolSubscribers";

import axios from "axios";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import AceQuestionAnswerTab from "../../../Components/Facilitator/ManageStudent/RiskProflingAnswer/AceQuestionAnswerTab";
import SDQQuestionAnswerTab from "../../../Components/Facilitator/StudentProgress/SDQQuestionAnswerTab";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import DownloadSelect from "../../../common/DownloadSelect";
import { flexCenter, itemsCenter } from "../../../common/commonStyles";
import { RegisterIcon } from "../../../images/icons";

export default function FacilitatorRiskProfiling() {
  const [value, setValue] = useState(1);

  // Event handler for tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [userInfo, setUserInfo] = useState({ name: "" });
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const state = location?.state ?? {};
    setUserInfo(state?.studentInfo ?? { name: "" });
  }, [location.state]);

  const params = useParams();
  const aceURL =
    process.env.REACT_APP_SUPERADMIN_RISK_PROFILING_QUESTION + "?type=ACE";
  const sdqPreURL =
    process.env.REACT_APP_SUPERADMIN_RISK_PROFILING_QUESTION + "?type=SDQPre";
  const sdqPOSTURL =
    process.env.REACT_APP_SUPERADMIN_RISK_PROFILING_QUESTION + "?type=SDQPost";
  const getRiskProfilingAnswer =
    process.env.REACT_APP_FACILITATOR_RISK_PROFILING_QUESTIONS + "/getrecord?";
  const [aceData, fetchAceData] = useDataFetchHook(aceURL);
  const [sdqPreData, fetchSDQPreData] = useDataFetchHook(sdqPreURL);
  const [sdqPostData, fetchSDQPostData] = useDataFetchHook(sdqPOSTURL);
  const [aceDataAnswer] = useDataFetchHook(
    getRiskProfilingAnswer + `type=ACE&id=${params?.studentId}`
  );
  const [sdqPreDataAnswer] = useDataFetchHook(
    getRiskProfilingAnswer + `type=SDQPre&id=${params?.studentId}`
  );
  const [sdqPostDataAnswer] = useDataFetchHook(
    getRiskProfilingAnswer + `type=SDQPost&id=${params?.studentId}`
  );

  const [aceAnswer, setAceAnswer] = useState({});
  const [sdqPreAnswer, setSdqPreAnswer] = useState({});
  const [sdqPostAnswer, setSdqPostAnswer] = useState({});

  const handleSave = async () => {
    try {
      let studentId = params?.studentId;
      if (!studentId) {
        toast.error("Invalid Attempt");
        return;
      }
      const url =
        process.env.REACT_APP_FACILITATOR_RISK_PROFILING_QUESTIONS +
        `/${studentId}`;
      const aceConfig = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          record: aceAnswer,
          type: "ACE",
        },
      });
      const sdqPreConfig = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          record: sdqPreAnswer,
          type: "SDQPre",
        },
      });
      const sdqPostConfig = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          record: sdqPostAnswer,
          type: "SDQPost",
        },
      });

      let aceRes = axios.request(aceConfig);
      let sdqPreRes = axios.request(sdqPreConfig);
      let sdqPostRes = axios.request(sdqPostConfig);
      aceRes = await aceRes;
      sdqPostRes = await sdqPostRes;
      sdqPreRes = await sdqPreRes;
      toast(sdqPreRes?.data?.message);
      navigate("/facilitator/student-progress", {
        replace: true,
      });
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    const state = location?.state ?? {};
    setUserInfo(state?.studentInfo ?? { name: "" });
  }, [location.state]);

  useEffect(() => {
    setAceAnswer(aceDataAnswer?.res?.record);
    setSdqPreAnswer(sdqPreDataAnswer?.res?.record);
    setSdqPostAnswer(sdqPostDataAnswer?.res?.record);
  }, [aceDataAnswer, sdqPreDataAnswer, sdqPostDataAnswer]);

  return (
    <Box>
      <NavBreadCrumbs
        pageTags={["Student Progress", userInfo?.studentName, "Risk Profiling"]}
      />
      <Box sx={{ ...itemsCenter, justifyContent: "flex-end", gap: 2 }}>
        <DownloadSelect
          downloadPdf={() => {
            window.open(
              `/risk-profiling/${params?.studentId}`,
              "",
              "toolbars=no,resizable=0,width=900,height=700,left=200,top=200"
            );
          }}
        />
        <Button onClick={handleSave} variant="primary">
          <RegisterIcon />
          Save
        </Button>
      </Box>
      <Box>
        <Box
          sx={{
            width: "100%",
            mt: 3,
            // position: "sticky",
            // top: 65,
            // zIndex: 5,
            // backgroundColor: "#eff7f6",
            borderBottom: `1px solid #BFBFBF`,
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            allowScrollButtonsMobile
            scrollButtons="auto"
          >
            <Tab
              sx={{
                textTransform: "none",
              }}
              label={
                <Typography variant={value == 1 ? "bold2" : "medium"}>
                  ACE Questions
                </Typography>
              }
              value={1}
            />
            <Tab
              sx={{
                textTransform: "none",
              }}
              label={
                <Typography variant={value == 2 ? "bold2" : "medium"}>
                  SDQ Pre Test
                </Typography>
              }
              value={2}
              disabled
            />

            <Tab
              sx={{
                textTransform: "none",
              }}
              label={
                <Typography variant={value == 3 ? "bold2" : "medium"}>
                  SDQ Post Test
                </Typography>
              }
              value={3}
              disabled
            />
          </Tabs>
        </Box>
        {(aceData.loading ||
          sdqPreData.loading ||
          sdqPostData.loading ||
          aceDataAnswer.loading ||
          sdqPreDataAnswer.loading ||
          sdqPostDataAnswer.loading) && (
          <Box sx={{ ...flexCenter }}>
            <CircularProgress />
          </Box>
        )}
        {/* Tab panels */}
        <TabPanel value={value} index={1}>
          <AceQuestionAnswerTab
            data={aceData?.res?.question ?? []}
            answers={aceAnswer}
            setAnswers={setAceAnswer}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SDQQuestionAnswerTab
            data={sdqPreData?.res?.question ?? []}
            answers={sdqPreAnswer}
            setAnswers={setSdqPreAnswer}
            type={"Pre"}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <SDQQuestionAnswerTab
            data={sdqPostData?.res?.question ?? []}
            answers={sdqPostAnswer}
            setAnswers={setSdqPostAnswer}
            type={"Post"}
          />
        </TabPanel>
      </Box>
    </Box>
  );
}
