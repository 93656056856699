import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArchivedStudentTab from "../../../Components/School/ManageBatch/ArchivedStudentTab";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { flexCenter } from "../../../common/commonStyles";
import { BatchEmpty } from "../../../const/imageUrl";
import { TabPanel } from "../../Admin/Dashboard/SchoolSubscribers";

export default function ArchivedStudentDetails() {
  const location = useLocation();
  const [tabs, setTabs] = useState([]);
  const url =
    process.env.REACT_APP_SCHOOL_FACILITATOR +
    `/batchlist?filterCompleted=true`;

  const [apiData] = useDataFetchHook(url);

  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  // Event handler for tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!apiData?.res) {
      return;
    }
    setTabs(apiData?.res?.batch);
    return () => {
      setTabs([]);
    };
  }, [apiData?.res]);

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Manage Students", "Archived"]} />

      <Box
        sx={{
          width: "100%",
          mt: 3,
          borderBottom: `1px solid #BFBFBF`,
        }}
      >
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          {tabs?.map((e, i) => (
            <Tab
              sx={{
                textTransform: "none",
              }}
              label={
                <Typography variant={value == i ? "bold2" : "medium"}>
                  {e.batchName}
                </Typography>
              }
              value={i}
            />
          ))}
        </Tabs>
      </Box>
      {apiData?.res?.batch?.length < 1 && (
        <Box
          sx={{
            mt: 3,
            backgroundColor: (theme) => theme.palette.background.secondary,
            ...flexCenter,
            flexDirection: "column",
            py: 8,
            gap: 5,
            borderRadius: "12px",
          }}
        >
          <img src={BatchEmpty} style={{ height: "220px", width: "600px" }} />
          <Typography
            variant="bold"
            sx={{ color: (theme) => theme.palette.text.light }}
          >
            No Batches to show
          </Typography>
        </Box>
      )}
      {tabs?.map((e, i) => (
        <TabPanel value={value} index={i}>
          <ArchivedStudentTab data={e} key={i} />
        </TabPanel>
      ))}
    </Box>
  );
}
