import { Box, MenuItem, Select, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  dFlex,
  flexSpaceBetween,
  itemsCenter,
} from "../../../../common/commonStyles";

export default function ModuleDropDownCard({ value, index }) {
  // const [options, setOptions] = useState([
  //   { label: "Lorem ipsum", count: "1000000" },
  //   { label: "Lorem ipsum dolor", count: "526365" },
  //   { label: "Lorem ipsum dolor, sit", count: "798741" },
  // ]);
  const tempArr = [];
  value.data.options.map((_, idx) => {
    tempArr.push({
      label: _.case,
      correct: _.options[_.answer],
      count: value?.options[idx],
    });
  });
  const [options, setOptions] = useState(tempArr);
  return (
    <Box
      sx={{
        my: 3,
        px: 3,
        borderRadius: "15px",
      }}
    >
      <Box sx={{ mb: 1, ...flexSpaceBetween, gap: 8 }}>
        <Typography
          variant="medium"
          sx={{
            flex: "80%",
            color: (theme) => theme.palette.text.main,
          }}
        >
          {index + 1}. {value.question}
        </Typography>
        <Typography
          variant="regular"
          sx={{
            flex: "10%",
            color: (theme) => theme.palette.text.main,
          }}
        >
          Answers Count
        </Typography>
      </Box>

      <Box sx={{ ...dFlex, flexDirection: "column", mt: 2 }}>
        {options?.map((e, i) => (
          <Box
            sx={{
              ...itemsCenter,
              gap: 2,
              my: 1,
              borderRadius: "6px",
              // backgroundColor: (theme) => theme.palette.background.secondary,
            }}
          >
            <Box
              sx={{
                p: "9px 20px",
                flex: "80%",
                color: (theme) => theme.palette.text.main,
                ...dFlex,
                flexDirection: "column",
                // boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.12)",
              }}
            >
              {String.fromCharCode(97 + i)}. {e.label}
              <Select
                sx={{
                  width: "50%",
                  height: "30px",
                  backgroundColor: (theme) =>
                    theme.palette.background.secondary,
                }}
                value={e.correct}
                disabled
              >
                <MenuItem value={e.correct}>{e.correct}</MenuItem>
              </Select>
            </Box>
            <Typography
              variant="medium"
              sx={{
                flex: "10%",
                pl: 1,
                color: (theme) => theme.palette.text.main,
              }}
            >
              {e?.count ? e.count : "0"}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
