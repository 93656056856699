import { Avatar, Box, Button, Popover, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import {
  dFlex,
  flexBetweenWithItemsCenter,
  itemsCenter,
} from "../../../common/commonStyles";
import { CustomDateTextField, FormTextField } from "../../../const/TextField";
import { CreateBatchIcon } from "../../../images/icons";

const initData = {
  date: "",
  startTime: "",
  endTime: "",
  subject: "",
  note: "",
  facilitators: [],
};

export default function AddMeeting() {
  const url =
    process.env.REACT_APP_SCHOOL_CREATE_MEETING + "?offset=0&limit=100";
  const [apiCall, fetchAgain] = useDataFetchHook(url);
  useEffect(() => {
    if (!apiCall?.res?.Instructor) return;
    let formatedData = apiCall?.res?.Instructor?.map((e) => {
      return { label: e.instructorName, value: e.id, email: e.email };
    });
    setFacilitators(formatedData);
  }, [apiCall?.res?.Instructor]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [meetingData, setMeetingData] = useState(initData);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const navigate = useNavigate();
  const handleChange = (e) => {
    let { name, value } = e.target;
    setMeetingData((p) => {
      return {
        ...p,
        [name]: value,
      };
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [selectedFacilitators, setSelectedFacilitators] = useState([]);

  const [facilitators, setFacilitators] = useState([]);

  const handleSelectFacilitator = (data) => {
    setSelectedFacilitators((prev) => [...prev, data]);
  };
  const handleRemoveSelectFacilitator = (index) => {
    setSelectedFacilitators((prev) => prev.filter((_, i) => i !== index));
  };

  // const [selectedPeriod, setSelectedPeriod] = useState("AM");
  // const [selectedPeriod2, setSelectedPeriod2] = useState("AM");

  // const handlePeriodChange = (event) => {
  //   setSelectedPeriod(event.target.value);
  // };

  // const handlePeriodChange2 = (event) => {
  //   setSelectedPeriod2(event.target.value);
  // };

  const handleCreate = async () => {
    try {
      if (loading) return;
      if (!startTime || !endTime) {
        toast.error("Meeting Duration is required");
        return;
      }
      let date = new Date();
      let tempDate = new Date(meetingData?.date);
      let tempDate1 = new Date(meetingData?.date);
      const [hours, minutes] = startTime.split(":").map(Number);
      const [hours1, minutes1] = endTime.split(":").map(Number);
      tempDate.setHours(hours);
      tempDate.setMinutes(minutes);
      tempDate1.setHours(hours1);
      tempDate1.setMinutes(minutes1);
      if (tempDate < date) {
        toast.error("Date Cannot be in past");
        return;
      }
      if (tempDate1 < tempDate) {
        toast.error("Invalid meeting time");
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_SCHOOL_CREATE_MEETING;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...meetingData,
          startTime,
          endTime,
          facilitators: selectedFacilitators?.map((e) => e.value),
        },
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      setLoading(false);
      navigate(-1);
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Meetings", "Schedule New"]} />

      <Box sx={{ ...dFlex, justifyContent: "space-between" }}>
        <Box sx={{ ...dFlex, flexDirection: "column", mb: 2 }}>
          {selectedFacilitators?.map((e, i) => (
            <Box sx={{ ...itemsCenter, gap: 1, my: 1 }}>
              <Avatar sx={{ height: "30px", width: "30px" }} />
              <Typography sx={{ color: (theme) => theme.palette.text.main }}>
                {e.label}
              </Typography>
              <Typography sx={{ color: (theme) => theme.palette.text.light }}>
                {e.email}
              </Typography>
              <Typography
                onClick={() => handleRemoveSelectFacilitator(i)}
                sx={{
                  color: (theme) => theme.palette.text.main,
                  cursor: "pointer",
                }}
              >
                x
              </Typography>
            </Box>
          ))}

          <Button
            variant="outlined"
            onClick={handleClick}
            sx={{
              textTransform: "none",
              borderRadius: "30px",
              width: "200px",
              fontWeight: 500,
              fontSize: "16px",
            }}
          >
            + Add Facilitator
          </Button>
          <Popover
            open={anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              // top: 0,
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box
              sx={{
                // ...dFlex,
                py: 1,
                // mx: 2,
                // px: 4,
                borderRadius: "10px",
                maxHeight: "140px",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {facilitators
                ?.filter((e) => !selectedFacilitators.includes(e))
                ?.map((e) => (
                  <Box
                    onClick={() => handleSelectFacilitator(e)}
                    sx={{
                      cursor: "pointer",
                      px: 4,
                      py: 1,
                      borderRadius: "10px",
                      "&:hover": {
                        backgroundColor: "#DEDEDE",
                      },
                    }}
                  >
                    {e.label}
                  </Box>
                ))}
            </Box>
          </Popover>
        </Box>
        <Box>
          <Button onClick={handleCreate} variant="primary">
            <CreateBatchIcon />
            Schedule
          </Button>
        </Box>
      </Box>

      <Box sx={{ ...flexBetweenWithItemsCenter, mt: 3 }}>
        <Box sx={{ ...dFlex, flexDirection: "column" }}>
          <Typography sx={{ color: (theme) => theme.palette.text.main }}>
            Date*
          </Typography>
          <CustomDateTextField
            name="date"
            value={meetingData?.date}
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ ...dFlex, gap: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ color: (theme) => theme.palette.text.main }}>
              Start Time*
            </Typography>
            <Box sx={{ display: "flex" }}>
              {/* <TimePicker
                sx={{
                  background: "#FFF",
                  height: "38px",
                  width: "140px",
                  borderRadius: "4px",
                  "& .MuiInputBase-root": {
                    height: "38px",
                    width: "140px",
                    fontSize: "14px",
                  },
                }}
                value={startTime}
                onChange={(e) => {
                  setStartTime(e);
                }}
              /> */}
              <CustomDateTextField
                placeholder="HH:MM"
                type="time"
                sx={{ width: "110px" }}
                value={startTime}
                onChange={(e) => {
                  setStartTime(e.target.value);
                }}
              />
              {/* <RadioGroup
                row
                aria-label="period"
                name="period"
                value={selectedPeriod}
                onChange={handlePeriodChange}
              >
                <FormControlLabel
                  value="AM"
                  control={<Radio sx={{ display: "none" }} />}
                  label="AM"
                  sx={radioButtonStyle(selectedPeriod === "AM")}
                />
                <FormControlLabel
                  value="PM"
                  control={<Radio sx={{ display: "none" }} />}
                  label="PM"
                  sx={radioButtonStyle(selectedPeriod === "PM")}
                />
              </RadioGroup> */}
            </Box>
          </Box>{" "}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ color: (theme) => theme.palette.text.main }}>
              End Time*
            </Typography>
            <Box sx={{ display: "flex" }}>
              {/* <FormTextField
                placeholder="HH:MM"
                sx={{ width: "110px" }}
                autoComplete="off"
                value={endTime}
                onChange={(e) => handleTimeInput(e, setEndTime)}
              /> */}
              <CustomDateTextField
                placeholder="HH:MM"
                type="time"
                sx={{ width: "110px" }}
                value={endTime}
                onChange={(e) => {
                  setEndTime(e.target.value);
                }}
              />
              {/* <RadioGroup
                row
                aria-label="period"
                name="period"
                value={selectedPeriod2}
                onChange={handlePeriodChange2}
              >
                <FormControlLabel
                  value="AM"
                  control={<Radio sx={{ display: "none" }} />}
                  label="AM"
                  sx={radioButtonStyle(selectedPeriod2 === "AM")}
                />
                <FormControlLabel
                  value="PM"
                  control={<Radio sx={{ display: "none" }} />}
                  label="PM"
                  sx={radioButtonStyle(selectedPeriod2 === "PM")}
                />
              </RadioGroup> */}
            </Box>
          </Box>{" "}
        </Box>
      </Box>
      <Box sx={{ mt: 5 }}>
        <FormTextField
          fullWidth
          placeholder="Meeting Subject"
          name="subject"
          value={meetingData?.subject}
          onChange={handleChange}
        />
      </Box>
      <Box sx={{ mt: 3 }}>
        <FormTextField
          placeholder="Note...(Optional)"
          multiline
          rows={8}
          fullWidth
          name="note"
          value={meetingData?.note}
          onChange={handleChange}
        />
      </Box>
    </Box>
  );
}

const radioButtonStyle = (isSelected) => ({
  margin: 0,
  px: 1,
  border: "1px solid #BFBFBF",
  color: isSelected ? "#358B8A" : "#BFBFBF",
  fontSize: "16px",
  bgcolor: isSelected ? "#C4DDDD" : "#F8F8F8",
});
