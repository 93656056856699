import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import { dFlex, flexCenter } from "../../../common/commonStyles";
import { reactSelectTheme } from "../../../const/constFunctions";
import { RegisterIcon } from "../../../images/icons";

export const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
export default function AssignBatchModal({
  currentBatchCount,
  normal,
  facilitatorDetails,
  fetchAgain,
  handleELClose,
}) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState(null);
  const [value, setValue] = useState(null);
  const [apiData] = useDataFetchHook(url);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    const url =
      process.env.REACT_APP_SCHOOL_BATCH_FACILITATOR_LIST +
      `/${facilitatorDetails?.id}`;

    setUrl(url);
    setOpen(true);
  };
  const handleChange = (selectedOption) => {
    setValue(selectedOption);
  };
  const handleClose = () => {
    setValue(null);
    setOpen(false);
  };

  const handleAdd = async () => {
    try {
      if (currentBatchCount) {
        if (currentBatchCount >= 10) {
          toast.error("Maximum batch limit exceeded");
          return;
        }
      }
      if (!value) {
        toast.error("Please select a batch");
        return;
      }
      if (!facilitatorDetails?.id) {
        toast.error("Something is went wrong");
        handleClose();
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_SCHOOL_ADD_FACILITATOR_TO_BATCH;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          batchName: value?.value,
          instructorId: facilitatorDetails?.id,
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchAgain();
      setLoading(false);
      handleClose();
      handleELClose();
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (!apiData?.res?.batch) {
      return;
    }
    let data = [...apiData.res.batch];
    data = data.map((e) => {
      return {
        label: e?.batchName,
        value: e?.batchName,
      };
    });
    setOptions(data);
    return () => {};
  }, [apiData?.res?.batch]);

  return (
    <>
      {normal ? (
        <Typography
          onClick={handleOpen}
          sx={{
            color: (theme) => theme.palette.text.light,
            cursor: "pointer",
          }}
        >
          Assign a batch
        </Typography>
      ) : (
        <Button onClick={handleOpen} variant="primary" sx={{ mr: 3 }}>
          <RegisterIcon /> Assign Batch
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "13px",
            backgroundColor: (theme) => theme.palette.background.main,
          },
        }}
      >
        <Box sx={{ width: "515px", pb: "20px" }}>
          <DialogContent sx={{ mb: 2, height: "30vh" }}>
            <DialogContentText id="alert-dialog-description">
              <Box
                sx={{
                  ...dFlex,
                  flexDirection: "column",
                  mb: 2,
                }}
              >
                <Typography
                  variant="medium"
                  sx={{
                    color: (theme) => theme.palette.text.dark,
                  }}
                >
                  Assign a batch
                </Typography>
              </Box>

              <Box>
                <Select
                  theme={reactSelectTheme}
                  value={value}
                  onChange={handleChange}
                  options={options}
                  isClearable={true}
                  hideSelectedOptions={true}
                  maxMenuHeight={150}
                  placeholder="Type Batch Name"
                />
              </Box>
              {apiData?.loading && (
                <Box sx={{ ...flexCenter }}>
                  <CircularProgress />
                </Box>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={apiData?.loading || loading}
              onClick={handleAdd}
              variant="primary"
            >
              Assign
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
