import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import Select from "react-select";

import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import countryList from "react-select-country-list";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../Apis/axiosPayloads";
import { POST } from "../../Apis/constVariables";
import PaymentFailureSponsor from "../../Components/Payment/PaymentFailureSponsor";
import PaymentSuccessSponsor from "../../Components/Payment/PaymentSuccessSponsor";
import OfflineRegistrationRequestModal from "../../Components/Registration/OfflineRegistrationRequestModal";
import { flexCenter, itemsCenter } from "../../common/commonStyles";
import { FormTextField } from "../../const/TextField";
import { reactSelectTheme } from "../../const/constFunctions";
import { RegistrationIllustration } from "../../const/imageUrl";
import { LoadingBarContext } from "../../context/TopLodingBarContext";
import { startPayment } from "../../functions/paymentGateway";
import { currencyType, defaultVal, paymentOptions } from "./RegisterModal";

export default function Sponsor() {
  const { ref } = useContext(LoadingBarContext);
  const [paymentRedirect, setPaymentRedirect] = useState({});
  const [numberOfStudent, setNumberOfStudent] = useState(null);
  const [paymentMode, setPaymentMode] = useState({
    label: "Online",
    value: "online",
  });
  const [currency, setCurrency] = useState({
    label: "JMD",
    value: "jmd",
  });
  const [calculatedPrice, setCalculatedPrice] = useState(defaultVal);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setFocus,
    clearErrors,
  } = useForm();
  const location = useLocation();
  const [offlineRegistration, setOfflineRegistration] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [country, setCountry] = useState({ label: "Jamaica", value: "JM" });
  const [show, setShow] = useState(false);
  const [showExistingModal, setShowExistingModal] = useState(false);
  const options = useMemo(() => countryList().getData(), []);
  const [emailError, setEmailError] = useState(false);

  const onSubmit = async (data) => {
    try {
      if (!numberOfStudent || calculatedPrice <= 0) {
        toast.error("Please enter a number first");
        return;
      }
      setSubmit(true);
      // localStorage.setItem("registrationData", JSON.stringify(data));
      let formattedData = {
        schoolName: data?.schoolName,
        email: data?.email,
        sponsorEmail: data.sponsorEmail,
        sponsorName: data?.sponsorName,
        isSponsor: true,
        country: country,
        numberOfStudent: numberOfStudent,
        paymentMode: paymentMode.value,
        amount: calculatedPrice?.[currency.value],
        currency: currency.label,
        alreadyExists: emailError,
        type: "School",
        reference: localStorage.getItem("ref"),
      };
      // console.log({ formattedData });
      ref.current.continuousStart();
      const url = process.env.REACT_APP_SPONSOR_REGISTER;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: { ...formattedData, paymentStatus: "pending" },
      });
      setSubmit(true);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      if (paymentMode.value == "online") {
        await startPayment(
          formattedData.sponsorEmail,
          "",
          {
            email: formattedData.email,
            numberOfStudent: formattedData.numberOfStudent,
            paymentMode: paymentMode.value,
          },
          calculatedPrice?.[currency.value],
          "/sponsor",
          currency.label,
          data?.sponsorName
        );
      } else {
        setOfflineRegistration(true);
      }
      ref.current.complete();
    } catch (err) {
      ref.current.complete();
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
    setSubmit(false);
  };

  useEffect(() => {
    let queryParams = new URLSearchParams(location.search);
    let reference = queryParams.get("ref") ?? window.location.origin + "/login";
    let formattedData = {
      email: queryParams.get("email"),
      schoolName: queryParams.get("schoolName"),
    };
    let numberOfStudent = queryParams.get("students");
    if (!numberOfStudent) {
      const searchParams = new URLSearchParams(window.location.search);
      let paymentDetails = {};
      searchParams.forEach((val, key) => {
        paymentDetails[key] = val;
      });
      setPaymentRedirect(paymentDetails);
      return;
    }
    reset(formattedData);
    localStorage.setItem("ref", reference);
    setNumberOfStudent(numberOfStudent);
    handleGetPrice(numberOfStudent);
    handleCheckEmail({ target: { value: formattedData.email } });
  }, [location?.search]);
  // useEffect(() => {
  //   let registrationData = new URLSearchParams(location.search);
  //   registrationData.set("email", "prathams@krishworks.com");
  //   registrationData.set("schoolName", "pratham school");
  //   registrationData.set("students", 5);
  //   let encodedRegistrationData = registrationData.toString();

  //   navigate({ search: encodedRegistrationData });
  // }, []);

  const handleCheckEmail = async (event) => {
    try {
      ref.current.continuousStart();
      setShow(false);
      setEmailError(true);
      const url2 = process.env.REACT_APP_EMAIL_STATUS;
      if (!event.target.value) {
        return;
      }
      const config2 = createAxiosConfig({
        endPoint: url2,
        method: POST,
        data: {
          email: event.target.value,
        },
      });
      await axios
        .request(config2)
        .then(() => {
          // localStorage.clear()
          setEmailError(false);
        })
        .catch((err) => {
          console.log({ data: err.response.data });
          setShowExistingModal(true);
          // setError("email", { message: err.response.data.message });
          // event.target.focus();
        })
        .finally(() => {
          setShow(true);
          ref.current.complete();
        });
    } catch (e) {
      console.log({ e });
    }
  };

  const handleGetPrice = async (numberOfStudent) => {
    try {
      if (!numberOfStudent) {
        setCalculatedPrice(defaultVal);
        setNumberOfStudent(null);
        toast.error("Please enter a number");
        return;
      }
      const url = process.env.REACT_APP_GET_CALCULATED_PRICE;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          type: "School",
          student: numberOfStudent,
        },
      });
      setSubmit(true);
      const apiRes = await axios.request(config);
      setCalculatedPrice(apiRes?.data?.Price);
      toast("Price Calculated");
    } catch (err) {
      setNumberOfStudent(null);
      setCalculatedPrice(defaultVal);
      toast.error(err?.response?.data?.message);
    }
    setSubmit(false);
  };
  // console.log({ paymentRedirect });
  return (
    <Grid
      container
      style={{
        height: "100vh",
        overflow: "auto",
        backgroundColor: (theme) => theme.palette.background.main,
      }}
    >
      <Grid item xs={12} sm={6} sx={{ display: { xs: "none", sm: "block" } }}>
        <Box
          sx={{
            height: "100%",
            textAlign: "center",
            boxSizing: "border-box",
            p: "40px 60px",
            background: (theme) => theme.palette.primary.light,
          }}
        >
          <Typography
            sx={{
              fontSize: "30px",
              color: "white",
            }}
          >
            Welcome to UNUH!
            <Typography
              sx={{
                fontSize: "18px",
                color: "white",
                mb: 12,
              }}
            >
              A fun, mobile learning programme designed to empower young people
              to master the inner game of life for greater happiness and
              success. Fill in the required information to complete your
              registration.{" "}
            </Typography>
          </Typography>
          <img
            // width="100%"
            height="426px"
            style={{
              objectFit: "contain",
            }}
            src={RegistrationIllustration}
            alt="register_illustration"
          />
        </Box>
      </Grid>
      {/* Right Section */}
      <Grid item xs={12} sm={6}>
        <Typography
          variant="semibold"
          onClick={() => {
            window.location.href =
              "https://unuh.limitlesstransformationja.com/";
          }}
          sx={{
            position: "absolute",
            top: 30,
            right: 40,
            color: (theme) => theme.palette.primary.main,
            mb: 3,
            textDecoration: "underline",
            cursor: "pointer",
            ...itemsCenter,
            gap: 0.1,
          }}
        >
          Go to Home Page <ArrowRightAltIcon />
        </Typography>
        <Box
          sx={{
            display: "flex",
            pt: "44px",
            backgroundColor: (theme) => theme.palette.background.main,
            // alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Box sx={{ width: "80%" }}>
            <Box sx={{ mb: 3 }}>
              <Typography
                variant="bold"
                sx={{
                  fontSize: "24px",
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                Sponsor
              </Typography>
            </Box>

            <form onSubmit={(e) => e.preventDefault()}>
              <Box sx={{ mt: 7, mb: 3 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    // flexDirection: "column",
                    alignItems: "center",
                    flexWrap: "wrap",
                    my: 3,
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      // my: 3,
                      gap: 3,
                    }}
                  >
                    {" "}
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        variant="bold2"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        Sponsor Name*
                      </Typography>
                      <FormTextField
                        fullWidth
                        {...register("sponsorName", {
                          required: "This field is required.",
                        })}
                        error={Boolean(errors.sponsorName)}
                        // helperText={errors.sponsorName?.message}
                      />
                      {Boolean(errors?.sponsorName) && (
                        <FormHelperText error={true}>
                          <Typography variant="semibold2">
                            {errors?.sponsorName?.message}
                          </Typography>
                        </FormHelperText>
                      )}
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        variant="bold2"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        Sponsor Email*
                      </Typography>
                      <FormTextField
                        type="email"
                        inputProps={{
                          style: {
                            textTransform: "lowercase",
                          },
                        }}
                        fullWidth
                        {...register("sponsorEmail", {
                          required: "Email is required.",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid Email Address",
                          },
                          onChange: () => {
                            clearErrors("sponsorEmail");
                          },
                        })}
                        error={Boolean(errors.sponsorEmail)}
                        // helperText={errors.email?.message}
                      />
                      {Boolean(errors?.sponsorEmail) && (
                        <FormHelperText error={true}>
                          <Typography variant="semibold2">
                            {errors?.sponsorEmail?.message}
                          </Typography>
                        </FormHelperText>
                      )}
                    </Box>
                  </Box>

                  <Typography
                    variant="semibold2"
                    sx={{ color: (theme) => theme.palette.text.main }}
                  >
                    Note: Sponsor details are strictly confidential and are not
                    shared with anyone except UNUH Admin.
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      // my: 3,
                      gap: 3,
                      mb: show ? 1 : 0,
                      transition: "all 200ms ease",
                    }}
                  >
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        variant="bold2"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        School / Institute Name*
                      </Typography>
                      <FormTextField
                        fullWidth
                        {...register("schoolName", {
                          required: "This field is required.",
                        })}
                        error={Boolean(errors.schoolName)}
                        // helperText={errors.schoolName?.message}
                      />
                      {Boolean(errors?.schoolName) && (
                        <FormHelperText error={true}>
                          <Typography variant="semibold2">
                            {errors?.schoolName?.message}
                          </Typography>
                        </FormHelperText>
                      )}
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        variant="bold2"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        School Admin Email*
                      </Typography>
                      <FormTextField
                        type="email"
                        inputProps={{
                          style: {
                            textTransform: "lowercase",
                          },
                        }}
                        fullWidth
                        {...register("email", {
                          required: "Email is required.",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid Email Address",
                          },
                          onChange: () => {
                            setShow(false);
                            clearErrors("email");
                          },
                          onBlur: (e) => {
                            handleCheckEmail(e);
                          },
                        })}
                        error={Boolean(errors.email)}
                        helperText={
                          show && (
                            <Typography
                              sx={{
                                position: "absolute",
                                left: 0,
                                color: "red",
                                whiteSpace: "nowrap",
                              }}
                              variant="semibold2"
                            >
                              {emailError
                                ? "Email already exist, license will be upgraded"
                                : "No school with this email found."}
                            </Typography>
                          )
                        }
                      />
                      {Boolean(errors?.email) && (
                        <FormHelperText error={true}>
                          <Typography variant="semibold2">
                            {errors?.email?.message}
                          </Typography>
                        </FormHelperText>
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: 3,
                      width: "100%",
                    }}
                  >
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        variant="bold2"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        Country*
                      </Typography>
                      <FormControl fullWidth>
                        <Select
                          options={options}
                          theme={reactSelectTheme}
                          value={country}
                          onChange={(e) => setCountry(e)}
                        />{" "}
                      </FormControl>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        variant="bold2"
                        sx={{
                          color: (theme) => theme.palette.text.main,
                        }}
                      >
                        Number of students
                      </Typography>
                      <FormTextField
                        type="number"
                        inputProps={{ min: 0 }}
                        fullWidth
                        value={numberOfStudent}
                        onBlur={(e) => {
                          if (e.target.value < 0) {
                            setNumberOfStudent(Math.abs(e.target.value));
                          }
                          handleGetPrice(Math.abs(e.target.value));
                        }}
                        onChange={(e) => setNumberOfStudent(e.target.value)}
                        placeholder="Enter number of students"
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      gap: 3,
                      width: "100%",
                    }}
                  >
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        variant="bold2"
                        sx={{
                          color: (theme) => theme.palette.text.main,
                          display: "block",
                        }}
                      >
                        Currency
                      </Typography>
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          isSearchable={false}
                          onChange={(e) => setCurrency(e)}
                          value={currency}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: "100%",
                            }),
                          }}
                          options={currencyType}
                          theme={reactSelectTheme}
                        />
                      </FormControl>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Typography
                        variant="bold2"
                        sx={{
                          color: (theme) => theme.palette.text.main,
                          display: "block",
                        }}
                      >
                        Payment Mode:
                      </Typography>
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          isSearchable={false}
                          onChange={(e) => setPaymentMode(e)}
                          value={paymentMode}
                          options={paymentOptions}
                          theme={reactSelectTheme}
                        />
                      </FormControl>
                    </Box>
                  </Box>

                  <Box sx={{ flex: 1 }}>
                    {submit ? (
                      <Box sx={{ ...flexCenter }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Typography
                        sx={{
                          color: (theme) => theme.palette.text.main,
                          // mb: 1,
                          // ...itemsCenter,
                          // gap: 2,
                        }}
                      >
                        Total{" "}
                        <Typography variant="bold">
                          ${calculatedPrice?.[currency.value]}
                        </Typography>
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ ...itemsCenter, flexDirection: "column" }}>
                <Button
                  onClick={handleSubmit(onSubmit)}
                  disabled={submit || !show}
                  variant="primary"
                >
                  Sponsor
                </Button>
              </Box>
            </form>
          </Box>
          <OfflineRegistrationRequestModal
            open={offlineRegistration}
            setOpen={setOfflineRegistration}
          />

          {paymentRedirect.status == "success" && (
            <PaymentSuccessSponsor data={paymentRedirect} />
          )}
          {paymentRedirect.status == "failed" && (
            <PaymentFailureSponsor data={paymentRedirect} />
          )}
          <ExitingUserModal
            open={showExistingModal}
            emailError={emailError}
            numberOfStudent={numberOfStudent}
            // changeEmail={() => {
            //   setShowExistingModal(false);
            //   setFocus("email");
            // }}
            handleClose={() => {
              setShowExistingModal(false);
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

const ExitingUserModal = ({
  emailError,
  open,
  handleClose,
  numberOfStudent,
  changeEmail,
}) => {
  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: "13px",
          backgroundColor: (theme) => theme.palette.background.main,
        },
      }}
    >
      <Box sx={{ width: "400px", pb: "20px", textAlign: "center" }}>
        {/* <form onSubmit={handleDeleteAdmission} autoComplete="off"> */}
        <DialogContent sx={{ mb: 0 }}>
          <DialogContentText id="alert-dialog-description">
            <Box sx={{ ...flexCenter, flexDirection: "column", mb: 1 }}>
              {emailError ? (
                <Typography
                  variant="regular"
                  sx={{
                    // color: "#2B8E2F",
                    fontWeight: "700",
                  }}
                >
                  School with email already exists, {numberOfStudent} students
                  will be added to the current license
                </Typography>
              ) : (
                <Typography
                  variant="regular"
                  sx={{
                    color: "#2B8E2F",
                    fontWeight: "700",
                  }}
                ></Typography>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={handleClose}
            sx={{
              fontWeight: 500,
              textTransform: "none",
            }}
          >
            Close
          </Button>
          {/* <Button
            onClick={changeEmail}
            variant="primary"
            sx={{
              fontWeight: 500,
              textTransform: "none",
            }}
          >
            Change
          </Button> */}
        </DialogActions>
        {/* </form> */}
      </Box>
    </Dialog>
  );
};
