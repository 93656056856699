import { Box, Button, IconButton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { PATCH, POST } from "../../../Apis/constVariables";
import { itemsCenter } from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { AddIconBig, RemoveIconBig } from "../../../images/icons";
import { validateBasicQuestionForm } from "../CreateQuestion";
import "../styles/createQuiz.css";
const CompleteSentence = ({
  editAble,
  questionFormData,
  fetchQuizQuestionsAgain,
  toggleQuestionForm,
}) => {
  const [loading, setLoading] = useState(false);
  const [completeSentenceQuestions, setCompleteSentenceQuestions] = useState([
    "",
    "",
  ]);

  const handleAdd = () => {
    setCompleteSentenceQuestions([...completeSentenceQuestions, ""]);
  };
  const handleRemove = (id) => {
    let abc = [...completeSentenceQuestions];
    abc = abc.filter((e, i) => {
      return i !== id;
    });
    setCompleteSentenceQuestions(abc);
  };

  function submit(e) {
    e.preventDefault();
  }

  const addData = async () => {
    try {
      const formValidator = validateBasicQuestionForm(questionFormData);
      if (formValidator?.error) {
        toast.error(formValidator.message);
        return;
      }
      let nsn = questionFormData.questionName.replaceAll(" ", "");

      let dat = /\//i.test(nsn);
      if (!dat) {
      } else {
        toast.error("You can not use any (/) characters");
        return;
      }
      let err = false;
      completeSentenceQuestions?.forEach((e) => {
        if (!e?.trim()) {
          err = true;
        }
      });
      if (err) {
        toast.error("Fields cannot be empty");
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_QUESTION;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...questionFormData,
          data: { options: completeSentenceQuestions },
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchQuizQuestionsAgain();
      toggleQuestionForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const updateData = async () => {
    try {
      const formValidator = validateBasicQuestionForm(questionFormData);
      if (formValidator?.error) {
        toast.error(formValidator.message);
        return;
      }
      let nsn = questionFormData.questionName.replaceAll(" ", "");
      let dat = /\//i.test(nsn);
      if (!dat) {
      } else {
        toast.error("You can not use any (/) characters");
        return;
      }
      let err = false;
      completeSentenceQuestions?.forEach((e) => {
        if (!e?.trim()) {
          err = true;
        }
      });
      if (err) {
        toast.error("Fields cannot be empty");
        return;
      }
      setLoading(true);
      const url =
        process.env.REACT_APP_QUESTION + `/${questionFormData?.questionId}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: {
          ...questionFormData,
          data: { options: completeSentenceQuestions },
        },
      });
      console.log({ config });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchQuizQuestionsAgain();
      toggleQuestionForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (!questionFormData?.data) {
      return;
    }
    setCompleteSentenceQuestions(questionFormData?.data?.options);

    return () => {
      setCompleteSentenceQuestions(["", ""]);
    };
  }, [questionFormData?.data]);

  return (
    <section className="quizForm-container">
      <form onSubmit={submit}>
        {completeSentenceQuestions?.map((e, i) => (
          <Box className="quizForm-mainForm-infoRow" sx={{ ...itemsCenter }}>
            <>
              <label className="quizForm-mainForm-label">
                Option {i + 1} :
              </label>
              <FormTextField
                className="quizForm-mainForm-inputBar"
                autoFocus={i > 1}
                value={e}
                placeholder="Enter Option"
                onChange={(event) => {
                  const { value } = event.target;
                  setCompleteSentenceQuestions((prevQuestions) =>
                    prevQuestions.map((question, index) =>
                      index === i ? value : question
                    )
                  );
                }}
              />
            </>
            {i > completeSentenceQuestions.length - 2 && i < 4 && (
              <IconButton onClick={handleAdd}>
                <AddIconBig />
              </IconButton>
            )}
            {i > 1 && (
              <IconButton onClick={() => handleRemove(i)}>
                <RemoveIconBig />
              </IconButton>
            )}
          </Box>
        ))}
      </form>
      <Box className="quizForm-mainForm-infoRow">
        <Button
          sx={{
            textTransform: "none",
          }}
          style={{ marginLeft: "auto" }}
          onClick={toggleQuestionForm}
        >
          Cancel
        </Button>
        <Button
          variant="primary-small"
          disabled={loading}
          onClick={editAble ? updateData : addData}
        >
          Save
        </Button>
      </Box>
    </section>
  );
};

export default CompleteSentence;
