import { Box } from "@mui/material";
import React from "react";
import OfflineRequestTable from "../../../Components/Admin/Dashboard/OfflineRequestTable";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";

// Function to render individual tab panels

export default function OfflineRegistrationRequest() {
  return (
    <Box>
      {/* Navigation breadcrumbs */}
      <NavBreadCrumbs pageTags={["Dashboard", "Offline  Requests"]} />

      <OfflineRequestTable />
    </Box>
  );
}
