import ReplyIcon from "@mui/icons-material/Reply";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  InputAdornment,
  TablePagination,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { PATCH } from "../../../Apis/constVariables";
import CompletedStudentCard from "../../../Components/School/ManageBatch/CompletedStudentCard";
import DeleteBatchModal from "../../../Components/School/ManageBatch/DeleteBatchModal";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import useTablePagination from "../../../Hooks/TablePaginationHook";
// import DownloadSelect from "../../../common/DownloadSelect";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import {
  dFlex,
  flexBetweenWithItemsCenter,
  flexCenter,
  itemsCenter,
} from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { BatchEmpty } from "../../../const/imageUrl";
import { RegisterIcon } from "../../../images/icons";

export default function CompletedBatchDetails() {
  const location = useLocation();
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [studentUrl, setStudentUrl] = useState(null);
  const [studentApiCall] = useDataFetchHook(studentUrl);
  const [insUrl, setInsUrl] = useState(null);
  const [insApiCall] = useDataFetchHook(insUrl);
  const navigate = useNavigate();
  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();
  const [batchDetails, setBatchDetails] = useState({
    batchName: "",
  });
  useEffect(() => {
    const { batchDetail } = location?.state ?? { batchName: "" };
    setStudentUrl(
      `${process.env.REACT_APP_SCHOOL_GET_STUDENT_BATCH}${batchDetail.batchName}&offset=0&limit=50`
    );
    setInsUrl(
      `${process.env.REACT_APP_SCHOOL_GET_FACILITATOR_IN_BATCH}${batchDetail.batchName}&offset=0&limit=50`
    );
    setBatchDetails(batchDetail);
  }, [location.state]);

  const handleMoveToCurrent = async () => {
    try {
      const url = process.env.REACT_APP_SCHOOL_CREATE_BATCH;
      setLoading(true);
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: { batchName: batchDetails?.batchName.trim(), complete: false },
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      navigate(-1);
      toast(apiRes?.data?.message);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
    setLoading(false);
  };

  const filterData = () => {
    console.log("searhing");
    let arr = [...studentApiCall?.res?.Student];
    arr = arr?.filter((e) => {
      return e?.studentName?.toLowerCase()?.includes(searchText.toLowerCase());
    });
    handleChangePage(null, 0);
    setSearchData(arr);
    setLoading(false);
  };
  useEffect(() => {
    if (!searchText) {
      setSearchData([]);
      setLoading(false);
      return;
    }
    filterData();
  }, [searchText]);
  return (
    <Box>
      <NavBreadCrumbs pageTags={["Manage Batch", batchDetails?.batchName]} />

      <Box sx={{ ...flexBetweenWithItemsCenter, height: "40px" }}>
        <Box>
          <FormTextField
            placeholder="search"
            onChange={(e) => {
              setLoading(true);
              setSearchText(e.target.value);
            }}
            value={searchText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    fontSize="small"
                    sx={{
                      color: "#BFBFBF",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ ...dFlex, gap: 3 }}>
          {/* <DownloadSelect /> */}

          <Button
            variant="primary"
            disabled={loading}
            onClick={handleMoveToCurrent}
            sx={{ mr: 0 }}
          >
            <ReplyIcon /> Move to Current Batch
          </Button>
          <DeleteBatchModal data={batchDetails} />
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Box>
          <Typography
            sx={{ color: (theme) => theme.palette.text.main, ...dFlex, gap: 2 }}
          >
            Facilitator Name : -{" "}
            <Box>
              {insApiCall?.res?.instructor?.map((e, i) => (
                <Typography
                  sx={{
                    color: (theme) => theme.palette.text.main,
                    ...itemsCenter,
                    gap: 1,
                  }}
                >
                  {e?.instructorName}{" "}
                </Typography>
              ))}{" "}
            </Box>
          </Typography>
        </Box>
        <Box sx={{ ...flexBetweenWithItemsCenter }}>
          <Typography sx={{ color: (theme) => theme.palette.text.main }}>
            Number of Students : {studentApiCall?.res?.Student?.length}
          </Typography>
          {/* <Button
            variant="outlined"
            onClick={() => {
              navigate("archived", {
                state: {
                  batchDetail: batchDetails,
                },
              });
            }}
            sx={{ textTransform: "none", gap: 1, borderRadius: "7px" }}
          >
            <ArchiveIcon />
            Archived
          </Button> */}
        </Box>
      </Box>
      <Box>
        {false && (
          <Box
            sx={{
              mt: 3,
              backgroundColor: (theme) => theme.palette.background.secondary,
              ...flexCenter,
              flexDirection: "column",
              py: 8,
              gap: 5,
              borderRadius: "12px",
            }}
          >
            <img
              alt="no batch"
              src={BatchEmpty}
              style={{ height: "220px", width: "600px" }}
            />
            <Typography
              variant="bold"
              sx={{ color: (theme) => theme.palette.text.light }}
            >
              You have not registered student
            </Typography>
            <Button
              variant="primary"
              onClick={() =>
                navigate("add-student", {
                  state: {
                    batchDetail: batchDetails,
                  },
                })
              }
            >
              <RegisterIcon /> Add Student
            </Button>
          </Box>
        )}
      </Box>
      {searchText && (
        <Box>
          {searchData
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((e) => (
              <CompletedStudentCard data={e} batchDetails={batchDetails} />
            ))}
        </Box>
      )}
      {!searchText && (
        <Box>
          {studentApiCall?.res?.Student?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          ).map((e) => (
            <CompletedStudentCard data={e} batchDetails={batchDetails} />
          ))}
        </Box>
      )}
      {studentApiCall?.res?.Student?.length >= 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            rowsPerPageOptions={[]}
            count={
              searchText
                ? searchData.length
                : studentApiCall?.res?.Student?.length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
}
