import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import {
  dFlex,
  itemsCenter,
  justifyCenter,
  textEllipses,
} from "../../../../common/commonStyles";
import { PlayIcon } from "../../../../images/icons";
import FileDeleteModal from "./FileDeleteModal";
export default function FileCard({ details, setFileSelected, fetchAgain }) {
  return (
    <Box
      sx={{
        width: "max-content",
        // height: "173px",
        ...dFlex,
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box
        sx={{
          width: "173px",
          height: "173px",
          objectFit: "fill",
          backgroundImage: `url(${details?.coverImage})`,
          position: "relative",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          ...itemsCenter,
          ...justifyCenter,
        }}
      >
        <IconButton
          onClick={() => {
            setFileSelected(details);
          }}
          sx={{
            position: "absolute",
            borderRadius: "50%",
            height: "60px",
            width: "60px",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            },
          }}
        >
          <PlayIcon />
        </IconButton>
        <FileDeleteModal details={details} fetchAgain={fetchAgain} />
      </Box>
      <Typography
        variant="medium"
        sx={{
          color: (theme) => theme.palette.text.main,
          pl: 1,
          maxWidth: "150px",
          ...textEllipses,
        }}
      >
        {details?.title}
      </Typography>
    </Box>
  );
}
