import { Box, Typography } from "@mui/material";
import React from "react";
import { dFlex, flexSpaceBetween } from "../../../../common/commonStyles";

export default function ModuleDragAndDropCard({ data, index }) {
  console.log({ data });
  return (
    <Box
      sx={{
        my: 3,
        px: 3,
        borderRadius: "15px",
      }}
    >
      <Box sx={{ mb: 1, ...flexSpaceBetween, gap: 8 }}>
        <Typography
          variant="medium"
          sx={{
            flex: "80%",
            color: (theme) => theme.palette.text.main,
          }}
        >
          {index + 1}.{data?.question}
        </Typography>
      </Box>

      <Box sx={{ ...dFlex }}>
        <Box
          sx={{ flex: "80%", flexDirection: "column", mt: 2, p: "9px 20px" }}
        >
          <Box
            sx={{
              border: "1px dashed #BFBFBF",
              width: "389px",
              height: "141px",
              borderRadius: "8px",
              backgroundColor: (theme) => theme.palette.background.secondary,
              ...dFlex,
              boxSizing: "border-box",

              p: 2,
              gap: 2,
              flexWrap: "wrap",
            }}
          ></Box>
          <Box sx={{ ...dFlex, gap: 2, mt: 2 }}>
            <Box
              sx={{
                border: "1px dashed #BFBFBF",
                // height: "240px",
                width: "180px",
                backgroundColor: (theme) => theme.palette.background.secondary,
                ...dFlex,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                py: 0.5,
                px: 0.5,
              }}
            >
              <Box>
                {data?.stdAnswer?.item1Answers?.map((e) => (
                  <img
                    src={e}
                    style={{ marginRight: "10px" }}
                    height={"44px"}
                    width={"44px"}
                  />
                ))}
              </Box>
              <img src={data?.data?.item1} height={"140px"} width={"120px"} />
            </Box>
            <Box
              sx={{
                border: "1px dashed #BFBFBF",
                // height: "240px",
                width: "180px",
                backgroundColor: (theme) => theme.palette.background.secondary,
                ...dFlex,
                alignItems: "flex-end",
                justifyContent: "center",
                flexWrap: "wrap",
                py: 0.5,
              }}
            >
              <Box>
                {data?.stdAnswer?.item2Answers?.map((e) => (
                  <img
                    src={e}
                    style={{ marginRight: "10px" }}
                    height={"44px"}
                    width={"44px"}
                  />
                ))}
              </Box>
              <img src={data?.data?.item2} height={"140px"} width={"120px"} />
            </Box>
          </Box>{" "}
        </Box>
      </Box>
    </Box>
  );
}
