import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Client } from "@twilio/conversations";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import FacilitatorMessageBox from "../../../Components/Facilitator/CommunityChannel/FacilitatorMessageBox";
import ChannelStudentCard from "../../../Components/Parent/CommunityChannel/ChannelStudentCard";
import SuspendStudentModal from "../../../Components/Parent/CommunityChannel/SuspendStudentModal";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import {
  dFlex,
  flexBetweenCenter,
  flexCenter,
  itemsCenter,
  textEllipses,
} from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { reactSelectTheme } from "../../../const/constFunctions";
import { UserContext } from "../../../context/UserContext";
import { getToken } from "../../Parent/CommunityChannel/ParentCommunityChannel";

export default function FacilitatorCommunityChannel() {
  const [chatDetails, setChatDetails] = useState(null);
  const clientRef = useRef(null);
  const [chatUserFcmToken, setChatUserFcmToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(false);
  const [batches, setBatches] = useState([]);

  const url =
    process.env.REACT_APP_SCHOOL_FACILITATOR +
    `/batchlist?filterCompleted=true`;

  const [apiData] = useDataFetchHook(url);
  const lsConvo =
    process.env.REACT_APP_LIST_CONVERSATION + `?filterCompleted=true`;

  const [lsConvoData, fetchAgain] = useDataFetchHook(lsConvo);
  // console.log({ res: lsConvoData?.res });
  const [lsConversations, setLsConversations] = useState([]);
  const [lastMessageLoading, setLastMessageLoading] = useState(false);
  useEffect(() => {
    if (!apiData?.res) {
      return;
    }
    setBatches(apiData?.res?.batch);
    return () => {
      setBatches([]);
    };
  }, [apiData?.res]);

  const joinChannel = async (channelId, type) => {
    // let channelId = "uniqueName";
    try {
      if (chatDetails?.uniqueName == channelId) {
        return;
      }
      setChatUserFcmToken(null);
      setChatDetails(null);
      setLoading(true);
      const conversation = await clientRef.current.getConversationByUniqueName(
        channelId
      );
      let att = await conversation.getAttributes();
      if (type == "p2p") {
        const url = process.env.REACT_APP_GET_FCM_TOKEN + `?id=${channelId}`;
        const config = createAxiosConfig({
          endPoint: url,
        });
        // console.log({ config });
        const response = await axios.request(config);
        const data = response?.data;
        console.log({ data });
        setChatUserFcmToken(data?.token);
      }
      // const conversation = await clientRef.current.getConversationByUniqueName(
      //   channelId
      // );
      console.log("conversation already found");
      // console.log(conversation.status);
      // await conversation.delete();
      setChatDetails(conversation);
    } catch (error) {
      if (error.body.code == 50432) {
        const url = `${process.env.REACT_APP_JOIN_CONVERSATION}?id=${channelId}`;
        try {
          const config = createAxiosConfig({
            endPoint: url,
          });
          const response = await axios.request(config);
          console.log({ response });
          await joinChannel(channelId);
        } catch (error) {
          console.log({ apiError: error });
          toast.error("Please refresh the page");
          toast.error("Error While Connecting");
        }
      } else if (error.body.code == 50350) {
        const url = process.env.REACT_APP_CREATE_CONVERSATION;
        try {
          const config = createAxiosConfig({
            endPoint: url,
            method: POST,
            data: {
              name: channelId,
              id: channelId,
              type: type,
            },
          });
          const response = await axios.request(config);
          console.log({ response });
          await joinChannel(channelId);
        } catch (error) {
          console.log({ apiError: error });
          toast.error(error?.response?.data?.message);
          toast.error("Error occurred while joining");
          toast.error("Please refresh the page");
        }
      } else console.log({ error });
    }
    setLoading(false);
  };

  const initFunction = async () => {
    // console.log("calling access token");
    const tokenRes = await getToken(url);

    // console.log({ res: tokenRes });
    const token = tokenRes?.accessToken;
    const client = new Client(token);
    clientRef.current = client;
    try {
      client.on("tokenAboutToExpire", async () => {
        const tokenRes = await getToken(url);
        const token = tokenRes?.accessToken;
        client.updateToken(token);
      });
      client.on("tokenExpired", async () => {
        const tokenRes = await getToken(url);
        const token = tokenRes?.accessToken;
        client.updateToken(token);
      });
      setLoading(false);
      // // console.log({ client });
      // const conversation = await client.getConversationByUniqueName(
      //   "uniqueName"
      // );
      // console.log("conversation already found");
      // // console.log(conversation.status);
      // // await conversation.delete();
      // setChatDetails(conversation);
      // const client = await Channel.length;
      // client.on("tokenAboutToExpire", async () => {});
    } catch (error) {
      // if (error.body.code == 50350) {
      //   const conversation = await client.createConversation({
      //     friendlyName: "uniqueName",
      //     uniqueName: "uniqueName",
      //   });
      //   // console.log({ conversation });

      //   setChatDetails(conversation);
      //   setLoading(false);
      // }
      toast.error("Error While Connecting");
      toast.error("Please refresh the page");
      console.log({ error });
    }
    return client;
  };

  useEffect(() => {
    initFunction();
    // return () => res();

    let intervalId = setInterval(() => {
      fetchAgain();
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);
  const setLastMessage = async (channelIds) => {
    let data = [];
    console.time("setLastMessage");
    try {
      // dispatchlsConvo({ type: request });
      setLastMessageLoading(true);
      // for (let i = 0; i < channelIds.length; i++) {
      //   const channelId = channelIds[i]?.uniqueName;
      //   const conversation = await clientRef.current.getConversationByUniqueName(
      //     channelId
      //   );
      //   // console.log({ lastMessageDate: conversation.lastMessage?.dateCreated });
      //   const res = await conversation.getMessages(
      //     1,
      //     conversation.lastMessage?.index
      //   );
      //   // console.log({ channelId });
      //   // console.log({ res: res?.items });
      //   data.push({
      //     ...channelIds[i],
      //     lastMessage: {
      //       body: res?.items?.[0]?.body,
      //       date: conversation.lastMessage?.dateCreated,
      //     },
      //   });
      // }
      for (let i = 0; i < channelIds.length; i++) {
        const channelId = channelIds[i]?.uniqueName;
        const conversation = await clientRef.current.getConversationByUniqueName(
          channelId
        );
        // console.log({ lastMessageDate: conversation.lastMessage?.dateCreated });
        // const res = await conversation.getMessages(
        //   1,
        //   conversation.lastMessage?.index
        // );
        // console.log({ channelId });
        // console.log({ res: res?.items });
        data.push({
          ...channelIds[i],
          lastMessage: {
            // body: res?.items?.[0]?.body,
            date: conversation.lastMessage?.dateCreated,
          },
        });
      }
      data = data.sort((a, b) => {
        const dateA = a.lastMessage.date
          ? new Date(a.lastMessage.date)
          : new Date(0);
        const dateB = b.lastMessage.date
          ? new Date(b.lastMessage.date)
          : new Date(0);
        return dateB - dateA;
      });
      setLsConversations(data);
      setLastMessageLoading(false);
      // dispatchlsConvo({ type: success, data });
      console.timeEnd("setLastMessage");
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    if (!clientRef.current || !lsConvoData.res) {
      return;
    }
    setLastMessage(lsConvoData.res?.data);
  }, [clientRef.current, lsConvoData]);

  return (
    <Box sx={{ ...dFlex, gap: 2 }}>
      <Box
        id="message_list"
        sx={{
          height: "83vh",
          position: "relative",
          borderRadius: "23px",
          minWidth: "340px",
          backgroundColor: (theme) => theme.palette.background.secondary,
          boxSizing: "border-box",
        }}
      >
        {(apiData?.loading || lsConvoData?.loading || lastMessageLoading) && (
          <Box sx={{ ...flexCenter }}>
            <CircularProgress />
          </Box>
        )}
        <Box
          sx={{
            maxHeight: "100%",
            backgroundColor: "#DBE8E8",
            borderRadius: "23px",
            overflowY: "auto",
            overflowX: "hidden",
            direction: "rtl",
          }}
        >
          {batches?.map((e, i) => (
            <BatchCard
              data={e}
              key={i}
              // index={i}
              type="batch"
              joinChannel={joinChannel}
              chatDetails={chatDetails}
              setChatDetails={setChatDetails}
            />
          ))}
          {/* <Box
            sx={{
              ...itemsCenter,
              my: 2,
              px: 1.5,
              gap: 2,
              direction: "ltr",
              cursor: "pointer",
            }}
            onClick={() => {
              setState(true);
            }}
          >
            <Avatar
              sx={{
                width: "60px",
                height: "60px",
                backgroundColor: (theme) => theme.palette.primary.main,
              }}
            >
              A
            </Avatar>
            <Box sx={{ ...dFlex, flexDirection: "column", width: "100%" }}>
              <Typography
                variant="semibold"
                sx={{
                  color: (theme) => theme.palette.text.dark,
                }}
              >
                All Groups
              </Typography>
              <Typography
                variant="medium2"
                sx={{
                  color: (theme) => theme.palette.text.main,
                  ...dFlex,
                  maxWidth: "270px",
                  ...textEllipses,
                }}
              >
                Click to view all sub-groups {">"}
              </Typography>
            </Box>
          </Box> */}
          <Box
            sx={{
              borderRadius: "23px",
              height: "100%",
              backgroundColor: (theme) => theme.palette.background.secondary,
              direction: "ltr",
              // my: 4,
              pb: 4,
            }}
          >
            {/* {lsConvoData?.res?.data?.map((e, i) => ( */}
            {lsConversations?.map((e, i) => (
              <ChannelStudentCard
                chatDetails={chatDetails}
                data={e}
                key={i}
                index={i}
                joinChannel={joinChannel}
              />
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            maxWidth: state ? "400px" : "0px",
            translate: state ? "0%" : "-100%",
            top: 0,
            height: "100%",
            borderRadius: "23px",
            boxSizing: "border-box",
            backgroundColor: (theme) => theme.palette.background.secondary,
            overflowX: "hidden",
            overflowY: "scroll",
            transition: "all 0.75s ease-in-out",
            pb: 3,
          }}
        >
          <Box
            sx={{
              pt: 1,
              top: 0,
              position: "sticky",
              width: "100%",
              zIndex: 2,
              backgroundColor: (theme) => theme.palette.background.secondary,
            }}
          >
            <IconButton
              onClick={() => {
                setState(false);
              }}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
          </Box>
          {new Array(10).fill(1).map((e, i) => (
            <GroupCard
              index={i}
              chatDetails={chatDetails}
              setChatDetails={setChatDetails}
            />
          ))}
        </Box>
        <Box
          sx={{
            ...flexCenter,
            position: "absolute",
            bottom: 10,
            // pt: 10,
          }}
        >
          <NewMessage
            fetchAgain={fetchAgain}
            joinChannel={joinChannel}
            setChatDetails={setChatDetails}
          />
        </Box>
      </Box>
      {loading && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />
        </Box>
      )}

      {chatDetails && (
        <FacilitatorMessageBox
          chatUserFcmToken={chatUserFcmToken}
          setChatDetails={setChatDetails}
          chatDetails={chatDetails}
        />
      )}
    </Box>
  );
}

function BatchCard({
  data,
  joinChannel,
  setChatDetails,
  chatDetails,
  index,
  type,
}) {
  return (
    <Box
      sx={{
        ...itemsCenter,
        my: 2,
        px: 1.5,
        gap: 2,
        direction: "ltr",
        cursor: "pointer",
      }}
      onClick={() => {
        joinChannel(data?.batchName, type);
        // setChatDetails({
        //   name: `Batch ${index + 1}`,
        //   img: "",
        //   type: "channel",
        // });
      }}
    >
      <Avatar
        sx={{
          width: "60px",
          height: "60px",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        {data?.batchName?.[0]}
      </Avatar>
      <Box sx={{ ...dFlex, flexDirection: "column", width: "100%" }}>
        <Typography
          variant="semibold"
          sx={{
            color: (theme) =>
              chatDetails?.uniqueName == data?.batchName
                ? theme.palette.primary.main
                : theme.palette.text.dark,
          }}
        >
          {data?.batchName}
        </Typography>

        {/* <Typography
          variant="medium2"
          sx={{
            color: (theme) => theme.palette.text.main,
            ...dFlex,
            maxWidth: "270px",
            ...textEllipses,
          }}
        >
          Student Name 1:
          <Typography
            sx={{
              color: (theme) => theme.palette.text.light,
              fontSize: "14px",
              ...textEllipses,
            }}
          >
            Lorem ipsum dolor sit amet.askdbgib
        </Typography>
          </Typography> */}
      </Box>
    </Box>
  );
}
function GroupCard({ setChatDetails, chatDetails, index }) {
  return (
    <Box
      sx={{
        ...itemsCenter,
        my: 2,
        px: 1.5,
        gap: 2,
        direction: "ltr",
        cursor: "pointer",
      }}
      onClick={() => {
        setChatDetails({
          name: `Group ${index + 1}`,
          img: "",
          type: "channel",
        });
      }}
    >
      <Avatar
        sx={{
          width: "60px",
          height: "60px",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        {index + 1}
      </Avatar>
      <Box sx={{ ...dFlex, flexDirection: "column", width: "100%" }}>
        <Box sx={{ ...flexBetweenCenter, justifyContent: "space-between" }}>
          <Typography
            variant="semibold"
            sx={{
              color: (theme) =>
                chatDetails?.name == `Group ${index + 1}`
                  ? theme.palette.primary.main
                  : theme.palette.text.dark,
            }}
          >
            Group {index + 1}
          </Typography>

          <Typography
            variant="medium2"
            sx={{
              color: (theme) => theme.palette.text.light,
            }}
          >
            10:33am
          </Typography>
        </Box>

        <Typography
          variant="medium2"
          sx={{
            color: (theme) => theme.palette.text.main,
            ...dFlex,
            maxWidth: "270px",
            ...textEllipses,
          }}
        >
          Student Name 1:
          <Typography
            sx={{
              color: (theme) => theme.palette.text.light,
              fontSize: "14px",
              ...textEllipses,
            }}
          >
            Lorem ipsum dolor sit amet.askdbgib
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
}

function NewMessage({ fetchAgain, joinChannel, setChatDetails }) {
  const [batches, setBatches] = useState([]);
  const url =
    process.env.REACT_APP_SCHOOL_FACILITATOR +
    `/batchlist?filterCompleted=true`;
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [apiData] = useDataFetchHook(url);

  const [selectedBatch, setSelectedBatch] = useState(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    fetchAgain();
    setOpen(false);
  };

  useEffect(() => {
    if (!apiData?.res) {
      return;
    }
    setBatches([]);
    let batchValues = apiData?.res?.batch?.map((e) => {
      return { label: e.batchName, value: e.batchName };
    });
    setBatches(batchValues);
    return () => {
      setBatches([]);
    };
  }, [apiData?.res]);

  const [studentUrl, setStudentUrl] = useState(null);
  const [studentApiCall, fetchStudentAgain] = useDataFetchHook(studentUrl);

  useEffect(() => {
    if (!selectedBatch) {
      return;
    }
    const url = `${process.env.REACT_APP_SCHOOL_GET_STUDENT_BATCH}${selectedBatch.value}&offset=0&limit=50`;
    setStudentUrl(url);
  }, [selectedBatch]);

  const handleSearch = () => {
    // console.log("searhing");
    let arr = [...studentApiCall?.res?.Student];
    arr = arr?.filter((e) => {
      return e?.studentName?.toLowerCase()?.includes(searchText.toLowerCase());
    });
    setSearchData(arr);
  };

  useEffect(() => {
    if (!searchText) {
      setSearchData(studentApiCall?.res?.Student);
      return;
    }
    handleSearch();
  }, [searchText]);

  useEffect(() => {
    if (!studentApiCall?.res) {
      return;
    }
    setSearchData(studentApiCall?.res?.Student);
  }, [studentApiCall?.res]);

  return (
    <>
      <Button variant="primary" onClick={handleOpen} sx={{ width: "95%" }}>
        New Message
      </Button>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            borderRadius: "10px",
            minWidth: "65vw",
            height: "85vh",
          },
        }}
      >
        {/* <DialogTitle> */}
        <Box
          sx={{
            p: 3,
            pb: 2.5,
            ...flexBetweenCenter,
            gap: 3,
          }}
        >
          <Select
            theme={reactSelectTheme}
            value={selectedBatch}
            onChange={(e) => setSelectedBatch(e)}
            options={batches}
            maxMenuHeight={130}
            isSearchable={false}
            placeholder="Select Batch"
          />
          <Button sx={{ textTransform: "none" }} onClick={handleClose}>
            Close
          </Button>
        </Box>
        <FormTextField
          placeholder="Search"
          sx={{ px: 3, pb: 2 }}
          disabled={studentApiCall?.loading}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  fontSize="small"
                  sx={{
                    color: "#BFBFBF",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
        {/* </DialogTitle> */}
        <DialogContent dividers>
          {studentApiCall?.loading && (
            <Box sx={{ ...flexCenter }}>
              <CircularProgress />
            </Box>
          )}
          <Grid container spacing={1}>
            {searchData?.map((e, index) => (
              <React.Fragment key={index}>
                <Grid
                  item
                  xs={6}
                  style={{
                    borderRight: index % 2 === 0 && "1px solid #000000",
                    padding: 0,
                  }}
                >
                  <NewMessageStudentCard
                    key={e.id}
                    data={e}
                    joinChannel={joinChannel}
                    index={index}
                    fetchStudentAgain={fetchStudentAgain}
                    handleModalClose={handleClose}
                  />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export function NewMessageStudentCard({
  data,
  fetchAgain,
  handleModalClose,
  joinChannel,
  fetchStudentAgain,
}) {
  const { userDetails } = useContext(UserContext);

  return (
    <Box
      sx={{
        ...itemsCenter,
        py: 1,
        gap: 2,
        px: 4,
        cursor: "pointer",
      }}
    >
      <Avatar
        onClick={async () => {
          await joinChannel(`${data?.id}_${userDetails?.uid}`, "p2p");
          fetchAgain?.();
          handleModalClose?.();
        }}
        src={data?.image}
        sx={{
          width: "60px",
          height: "60px",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        {data?.studentName?.[0]}
      </Avatar>
      <Box
        sx={{ ...dFlex, flexDirection: "column", width: "100%" }}
        onClick={async () => {
          await joinChannel(`${data?.id}_${userDetails?.uid}`, "p2p");
          fetchAgain?.();
          handleModalClose?.();
        }}
      >
        <Box sx={{ ...flexBetweenCenter, justifyContent: "space-between" }}>
          <Typography
            variant="semibold"
            sx={{
              color: (theme) => theme.palette.text.dark,
            }}
          >
            {data?.studentName}
          </Typography>

          {/* <Typography
            variant="medium2"
            sx={{
              color: (theme) => theme.palette.text.light,
            }}
          >
            ID 1234
          </Typography> */}
        </Box>
      </Box>
      <SuspendStudentModal
        data={data}
        suspended={data?.chatSuspended}
        fetchStudentAgain={fetchStudentAgain}
      />
    </Box>
  );
}
