import { Box, CircularProgress } from "@mui/material";
import React from "react";
import ContentUploaderCard from "./ContentUploaderCard";
import AddContentUploaderModal from "./AddContentUploaderModal";
import useDataFetchHook from "../../../../Hooks/DataFetchHook";
import { flexCenter } from "../../../../common/commonStyles";

export default function ContentUploadersTab() {
  const url = process.env.REACT_APP_CONTENT_UPLAODER;
  const [uploaderApiCall, fetchUploaderAgain] = useDataFetchHook(url);

  return (
    <Box>
      <AddContentUploaderModal fetchUploaderAgain={fetchUploaderAgain} />

      {uploaderApiCall?.loading && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />
        </Box>
      )}

      <Box>
        {uploaderApiCall?.res?.data?.map((ele, idx) => {
          return (
            <ContentUploaderCard
              key={ele.id}
              data={ele}
              fetchUploaderAgain={fetchUploaderAgain}
            />
          );
        })}
      </Box>
    </Box>
  );
}
