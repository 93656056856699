import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import countryList from "react-select-country-list";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../Apis/axiosPayloads";
import { POST } from "../../Apis/constVariables";
import { BootstrapTooltip } from "../../Components/Parent/Dashboard/LeaderBoard";
import {
  dFlex,
  flexBetweenCenter,
  itemsCenter,
} from "../../common/commonStyles";
import { FormTextField } from "../../const/TextField";
import { reactSelectTheme } from "../../const/constFunctions";
import { InfoIcon } from "../../images/icons";
// import illustration from "../../images/register_illustration.png";
import OfflineRegistrationRequestModal from "../../Components/Registration/OfflineRegistrationRequestModal";
import { RegistrationIllustration } from "../../const/imageUrl";
import { LoadingBarContext } from "../../context/TopLodingBarContext";
import { startPayment } from "../../functions/paymentGateway";
import RegisterModal from "./RegisterModal";

const initData = {
  schoolName: null,
  email: null,
  contactPersonName: null,
  address: null,
  contactPersonNumber: null,
  password: null,
  country: null,
  city: null,
  zipCode: null,
  numberOfStudent: null,
  cnfPassword: null,
};

export default function Register() {
  const { ref } = useContext(LoadingBarContext);
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setError,
    setFocus,
    clearErrors,
  } = useForm();
  const location = useLocation();
  const [offlineRegistration, setOfflineRegistration] = useState(false);
  const [selectedValue, setSelectedValue] = useState("School");
  const [submit, setSubmit] = useState(false);
  const [country, setCountry] = useState(null);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const options = useMemo(() => countryList().getData(), []);
  const [emailError, setEmailError] = useState(false);
  const handleClickOpen = async (e) => {
    setSubmit(true);
    await handleCheckEmail({ target: { value: e.email } });
    if (emailError) {
      return;
    }
    setSubmit(false);
    setData(e);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeRadio = (event) => {
    reset(initData);
    const queryParam = new URLSearchParams(location?.search);
    queryParam.set("type", event.target.value);
    navigate({ search: queryParam.toString() });
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChangeRadio,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const navigate = useNavigate();
  const onSubmit = async (
    numberOfStudent,
    calculatedPrice,
    paymentMode,
    currency
  ) => {
    try {
      if (emailError) {
        toast.error("Email Validation Failed");
        handleClose();
        return;
      }
      if (!numberOfStudent || calculatedPrice <= 0) {
        toast.error("Please enter a number first");
        return;
      }
      localStorage.setItem("type", selectedValue);
      localStorage.setItem("country", JSON.stringify(country));
      localStorage.setItem("registrationData", JSON.stringify(data));
      let formattedData = {
        schoolName: data?.schoolName,
        email: data?.email,
        contactPersonName: data?.contactPersonName,
        address: data?.address,
        contactPersonNumber: `+${data?.contactPersonNumber}`,
        password: data?.password,
        country: country,
        city: data?.city,
        zipCode: data?.zipCode,
        type: selectedValue,
        numberOfStudent: numberOfStudent,
        confirmedPassword: data?.cnfPassword,
        paymentMode: paymentMode.value,
        amount: calculatedPrice,
        currency,
      };
      console.log({ formattedData });
      ref.current.continuousStart();
      const url = process.env.REACT_APP_REGISTER;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: { ...formattedData, paymentStatus: "pending" },
      });
      setSubmit(true);
      const apiRes = await axios.request(config);
      // toast(apiRes?.data?.message);
      if (paymentMode.value == "online") {
        await startPayment(
          formattedData.email,
          formattedData.contactPersonNumber ?? "",
          {
            email: formattedData.email,
            numberOfStudent: formattedData.numberOfStudent,
            paymentMode: paymentMode.value,
          },
          calculatedPrice,
          "/login",
          currency
        );
      } else {
        setOfflineRegistration(true);
      }
      ref.current.complete();
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
    setSubmit(false);
  };

  useEffect(() => {
    const queryParam = new URLSearchParams(location?.search);
    setSelectedValue(queryParam.get("type") ?? "School");
  }, [location?.search]);
  useEffect(() => {
    let type = localStorage.getItem("type");
    if (type) {
      const queryParam = new URLSearchParams(location?.search);
      queryParam.set("type", type);
      navigate({ search: queryParam.toString() });
      setSelectedValue(type);
      let setData = localStorage.getItem("registrationData");
      reset(JSON.parse(setData));
      let country = localStorage.getItem("country");
      setCountry(JSON.parse(country));
    }
  }, []);

  const handleCheckEmail = async (event) => {
    try {
      setEmailError(true);
      const url2 = process.env.REACT_APP_EMAIL_STATUS;
      if (!event.target.value) {
        return;
      }
      const config2 = createAxiosConfig({
        endPoint: url2,
        method: POST,
        data: {
          email: event.target.value,
        },
      });
      await axios
        .request(config2)
        .then((e) => {
          // localStorage.clear()
          setEmailError(false);
        })
        .catch((err) => {
          // console.log({ data: err.response.data });
          setError("email", { message: err.response.data.message });
          setFocus("email");
          event.target.focus();
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Grid
      container
      style={{
        height: "100vh",
        overflow: "auto",
        backgroundColor: (theme) => theme.palette.background.main,
      }}
    >
      <Grid item xs={12} sm={6} sx={{ display: { xs: "none", sm: "block" } }}>
        <Box
          sx={{
            height: "100%",
            textAlign: "center",
            boxSizing: "border-box",
            p: "40px 60px",
            background: (theme) => theme.palette.primary.light,
          }}
        >
          <Typography
            sx={{
              fontSize: "30px",
              color: "white",
            }}
          >
            Welcome to UNUH!
            <Typography
              sx={{
                fontSize: "18px",
                color: "white",
                mb: 12,
              }}
            >
              A fun, mobile learning programme designed to empower young people
              to master the inner game of life for greater happiness and
              success. Fill in the required information to complete your
              registration.{" "}
            </Typography>
          </Typography>
          <img
            // width="100%"
            height="426px"
            style={{
              objectFit: "contain",
            }}
            src={RegistrationIllustration}
            alt="register_illustration"
          />
        </Box>
      </Grid>
      {/* Right Section */}
      <Grid item xs={12} sm={6}>
        <Typography
          variant="semibold"
          onClick={() => {
            window.location.href =
              "https://unuh.limitlesstransformationja.com/";
          }}
          sx={{
            position: "absolute",
            top: 30,
            right: 40,
            color: (theme) => theme.palette.primary.main,
            mb: 3,
            textDecoration: "underline",
            cursor: "pointer",
            ...itemsCenter,
            gap: 0.1,
          }}
        >
          Go to Home Page <ArrowRightAltIcon />
        </Typography>
        <Box
          sx={{
            display: "flex",
            pt: "44px",
            backgroundColor: (theme) => theme.palette.background.main,
            // alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Box sx={{ width: "80%" }}>
            <Box sx={{ mb: 3 }}>
              <Typography
                variant="bold"
                sx={{
                  fontSize: "24px",
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                Register
              </Typography>
            </Box>
            <Stack direction="row" alignItems="center" sx={{ mb: 1.5 }}>
              <Radio
                sx={{ p: 0, pr: 1 }}
                id="school"
                name="search_type"
                {...controlProps("School")}
              />
              <label htmlFor="school">
                <Typography
                  variant="bold2"
                  sx={{
                    color: (theme) => theme.palette.text.main,
                    mr: 4,
                    cursor: "pointer",
                  }}
                >
                  School / Institution
                </Typography>
              </label>
              <Radio
                sx={{ p: 0, pr: 1 }}
                id="parent"
                name="search_type"
                {...controlProps("Parent")}
              />
              <label htmlFor="parent">
                <Typography
                  variant="bold2"
                  sx={{
                    color: (theme) => theme.palette.text.main,
                    cursor: "pointer",
                  }}
                >
                  Parent / Individual
                </Typography>
              </label>
            </Stack>
            <form onSubmit={(e) => e.preventDefault()}>
              <Box>
                {selectedValue === "School" && (
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.primary.main }}
                    onClick={() => {
                      setSelectedValue("Parent");
                    }}
                  >
                    Note:{" "}
                    <span
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      Register here
                    </span>{" "}
                    if you are a school admin as well as a Facilitator
                  </Typography>
                )}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    mt: selectedValue === "School" ? 3 : 7,
                  }}
                >
                  <Box sx={{ width: "50%" }}>
                    <Typography
                      variant="bold2"
                      sx={{ color: (theme) => theme.palette.text.main }}
                    >
                      {selectedValue === "School"
                        ? "School / Institute Name*"
                        : "Name*"}
                    </Typography>
                    <FormTextField
                      fullWidth
                      {...register("schoolName", {
                        required: "This field is required.",
                      })}
                      error={Boolean(errors.schoolName)}
                      // helperText={errors.schoolName?.message}
                    />
                    {Boolean(errors?.schoolName) && (
                      <FormHelperText error={true}>
                        <Typography variant="semibold2">
                          {errors?.schoolName?.message}
                        </Typography>
                      </FormHelperText>
                    )}
                  </Box>
                  <Box sx={{ width: "50%", marginLeft: "30px" }}>
                    <Typography
                      variant="bold2"
                      sx={{ color: (theme) => theme.palette.text.main }}
                    >
                      {selectedValue === "school" && <>Admin</>}
                      E-mail*
                    </Typography>
                    <FormTextField
                      type="email"
                      inputProps={{
                        style: {
                          textTransform: "lowercase",
                        },
                      }}
                      fullWidth
                      {...register("email", {
                        required: "Email is required.",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid Email Address",
                        },
                        onChange: (event) => {
                          clearErrors("email");
                        },
                        onBlur: (e) => {
                          handleCheckEmail(e);
                        },
                      })}
                      error={Boolean(errors.email)}
                      // helperText={errors.email?.message}
                    />
                    {Boolean(errors?.email) && (
                      <FormHelperText error={true}>
                        <Typography variant="semibold2">
                          {errors?.email?.message}
                        </Typography>
                      </FormHelperText>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    my: 3,
                  }}
                >
                  <Box sx={{ width: "50%" }}>
                    <Typography
                      variant="bold2"
                      sx={{ color: (theme) => theme.palette.text.main }}
                    >
                      Street Address Line 1*
                    </Typography>

                    <FormTextField
                      fullWidth
                      {...register("address", {
                        required: "This is required.",
                      })}
                      error={Boolean(errors.address)}
                      // helperText={errors.address?.message}
                    />
                    {Boolean(errors?.address) && (
                      <FormHelperText error={true}>
                        <Typography variant="semibold2">
                          {errors?.address?.message}
                        </Typography>
                      </FormHelperText>
                    )}
                  </Box>

                  <Box sx={{ width: "50%", marginLeft: "30px" }}>
                    <Typography
                      variant="bold2"
                      sx={{ color: (theme) => theme.palette.text.main }}
                    >
                      Street Address Line 2
                    </Typography>
                    <FormTextField
                      fullWidth
                      {...register("city")}
                      error={Boolean(errors.city)}
                      // helperText={errors.city?.message}
                    />
                    {Boolean(errors?.city) && (
                      <FormHelperText error={true}>
                        <Typography variant="semibold2">
                          {errors?.city?.message}
                        </Typography>
                      </FormHelperText>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    my: 3,
                  }}
                >
                  <Box sx={{ width: "50%" }}>
                    <Typography
                      variant="bold2"
                      sx={{ color: (theme) => theme.palette.text.main }}
                    >
                      Country*
                    </Typography>

                    <Box>
                      <FormControl fullWidth>
                        <Select
                          options={options}
                          theme={reactSelectTheme}
                          value={country}
                          onChange={(e) => setCountry(e)}
                        />{" "}
                      </FormControl>
                    </Box>
                  </Box>
                  <Box sx={{ width: "50%", marginLeft: "30px" }}>
                    <Typography
                      variant="bold2"
                      sx={{ color: (theme) => theme.palette.text.main }}
                    >
                      Postal / Zip Code
                    </Typography>
                    <FormTextField
                      id="postal-code"
                      autoComplete="zipcode"
                      placeholder="Postal / Zip Code"
                      fullWidth
                      {...register("zipCode", {
                        required: "Postal Code is required.",
                      })}
                      error={Boolean(errors.zipCode)}
                      // helperText={errors.zipCode?.message}
                    />
                    {Boolean(errors?.zipCode) && (
                      <FormHelperText error={true}>
                        <Typography variant="semibold2">
                          {errors?.zipCode?.message}
                        </Typography>
                      </FormHelperText>
                    )}
                  </Box>
                </Box>

                {selectedValue === "School" && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      my: 3,
                    }}
                  >
                    <Box sx={{ width: "50%" }}>
                      <Typography
                        variant="bold2"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        Contact Person Name*
                      </Typography>

                      <FormTextField
                        fullWidth
                        {...register("contactPersonName", {
                          required: "Person Name is required.",
                        })}
                        error={Boolean(errors.contactPersonName)}
                        // helperText={errors.contactPersonName?.message}
                      />
                      {Boolean(errors?.contactPersonName) && (
                        <FormHelperText error={true}>
                          <Typography variant="semibold2">
                            {errors?.contactPersonName?.message}
                          </Typography>
                        </FormHelperText>
                      )}
                    </Box>

                    <Box sx={{ width: "50%", marginLeft: "30px" }}>
                      <Typography
                        variant="bold2"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        Contact Person Phone number*
                      </Typography>
                      <FormTextField
                        fullWidth
                        type="number"
                        inputProps={{ inputMode: "decimal" }}
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                            display: "none",
                          },
                          "& input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <Typography
                              position="start"
                              variant="bold2"
                              sx={{ color: (theme) => theme.palette.text.main }}
                            >
                              +
                            </Typography>
                          ),
                        }}
                        {...register("contactPersonNumber", {
                          required: "Phone number is required.",
                          pattern: {
                            value: /^\d+$/,
                            message:
                              "Requires valid phone number with country code",
                          },
                        })}
                        error={Boolean(errors.contactPersonNumber)}
                        // helperText={errors.contactNumber?.message}
                      />
                      {Boolean(errors?.contactPersonNumber) && (
                        <FormHelperText error={true}>
                          <Typography variant="semibold2">
                            {errors?.contactPersonNumber?.message}
                          </Typography>
                        </FormHelperText>
                      )}
                    </Box>
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    my: 3,
                  }}
                >
                  <Box sx={{ width: "50%" }}>
                    <Typography
                      variant="bold2"
                      sx={{
                        color: (theme) => theme.palette.text.main,
                        ...flexBetweenCenter,
                      }}
                    >
                      Enter Password*
                      <BootstrapTooltip
                        title={
                          <Box
                            sx={{ mt: 0.2, ...dFlex, flexDirection: "column" }}
                          >
                            <Typography
                              variant="medium2"
                              sx={{
                                color: (theme) => theme.palette.text.light,
                              }}
                            >
                              At least 10 characters (20 maximum)
                            </Typography>
                            <Typography
                              variant="medium2"
                              sx={{
                                color: (theme) => theme.palette.text.light,
                              }}
                            >
                              At least one upper case letter
                            </Typography>
                            <Typography
                              variant="medium2"
                              sx={{
                                color: (theme) => theme.palette.text.light,
                              }}
                            >
                              At least one number
                            </Typography>
                            <Typography
                              variant="medium2"
                              sx={{
                                color: (theme) => theme.palette.text.light,
                              }}
                            >
                              At least one of the following symbols ! @ # $ % ^
                              * ( )
                            </Typography>
                          </Box>
                        }
                        arrow
                        PopperProps={{
                          sx: {
                            backgroundColor: "transparent",
                          },
                        }}
                        container
                        placement="right"
                      >
                        <IconButton
                          tabIndex="-1"
                          sx={{ p: 0, ml: 0.5, top: -5 }}
                        >
                          <InfoIcon />
                        </IconButton>
                      </BootstrapTooltip>
                    </Typography>

                    <FormTextField
                      type={showPassword ? "text" : "password"}
                      onDrop={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      autoComplete="current-password"
                      fullWidth
                      {...register("password", {
                        required: "This field is mandatory",
                        minLength: {
                          value: 10,
                          message: "Password should be at least 10 characters",
                        },
                        maxLength: {
                          value: 20,
                          message: "Password should be at most 20 characters",
                        },
                        validate: {
                          uppercase: (value) =>
                            /^(?=.*[A-Z])/.test(value) ||
                            "Password must contain at least one uppercase letter",
                          lowercase: (value) =>
                            /^(?=.*[a-z])/.test(value) ||
                            "Password must contain at least one lowercase letter",
                          number: (value) =>
                            /^(?=.*\d)/.test(value) ||
                            "Password must contain at least one number",
                          symbol: (value) =>
                            /^(?=.*[!@#$%^*()])/.test(value) ||
                            "Password must contain at least one of the following symbols: ! @ # $ % ^ * ( )",
                          space: (value) =>
                            !/\s/.test(value) ||
                            "Password cannot contain spaces",
                        },
                      })}
                      error={Boolean(errors.password)}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            tabIndex={-1}
                            sx={{ p: 0, ml: 1 }}
                            onMouseDown={() => {
                              setShowPassword(true);
                            }}
                            onMouseUp={() => {
                              setShowPassword(false);
                            }}
                          >
                            {showPassword ? (
                              <VisibilityOffIcon fontSize="small" />
                            ) : (
                              <VisibilityIcon fontSize="small" />
                            )}
                          </IconButton>
                        ),
                      }}
                      // helperText={errors.password?.message}
                    />
                    {Boolean(errors?.password) && (
                      <FormHelperText error={true}>
                        <Typography variant="semibold2">
                          {errors?.password?.message}
                        </Typography>
                      </FormHelperText>
                    )}
                  </Box>

                  <Box sx={{ width: "50%", marginLeft: "30px" }}>
                    <Typography
                      variant="bold2"
                      sx={{
                        color: (theme) => theme.palette.text.main,
                        ...flexBetweenCenter,
                      }}
                    >
                      Confirm Password*
                      <BootstrapTooltip
                        title={
                          <Box
                            sx={{ mt: 0.2, ...dFlex, flexDirection: "column" }}
                          >
                            <Typography
                              variant="medium2"
                              sx={{
                                color: (theme) => theme.palette.text.light,
                              }}
                            >
                              At least 10 characters (20 maximum)
                            </Typography>
                            <Typography
                              variant="medium2"
                              sx={{
                                color: (theme) => theme.palette.text.light,
                              }}
                            >
                              At least one upper case letter
                            </Typography>
                            <Typography
                              variant="medium2"
                              sx={{
                                color: (theme) => theme.palette.text.light,
                              }}
                            >
                              At least one number
                            </Typography>
                            <Typography
                              variant="medium2"
                              sx={{
                                color: (theme) => theme.palette.text.light,
                              }}
                            >
                              At least one of the following symbols ! @ # $ % ^
                              * ( )
                            </Typography>
                          </Box>
                        }
                        arrow
                        PopperProps={{
                          sx: {
                            backgroundColor: "transparent",
                          },
                        }}
                        container
                        placement="right"
                      >
                        <IconButton
                          tabIndex="-1"
                          sx={{ p: 0, ml: 0.5, top: -5 }}
                        >
                          <InfoIcon />
                        </IconButton>
                      </BootstrapTooltip>
                    </Typography>
                    <FormTextField
                      type="password"
                      autoComplete="off"
                      onDrop={(e) => {
                        toast.error("Pasting Not Allowed");
                        e.preventDefault();
                        return false;
                      }}
                      onPaste={(e) => {
                        toast.error("Pasting Not Allowed");
                        e.preventDefault();
                        return false;
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      fullWidth
                      {...register("cnfPassword", {
                        required: "This field is mandatory",
                        validate: (value) =>
                          value === watch("password") ||
                          "Passwords do not match",
                      })}
                      error={Boolean(errors.cnfPassword)}
                      // helperText={errors.cnfPassword?.message}
                    />
                    {Boolean(errors?.cnfPassword) && (
                      <FormHelperText error={true}>
                        <Typography variant="semibold2">
                          {errors?.cnfPassword?.message}
                        </Typography>
                      </FormHelperText>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box sx={{ ...itemsCenter, flexDirection: "column" }}>
                <Button
                  onClick={handleSubmit(handleClickOpen)}
                  disabled={emailError || submit}
                  variant="primary"
                >
                  Register
                </Button>
                <Typography
                  variant="semibold"
                  sx={{
                    textAlign: "center",
                    color: (theme) => theme.palette.primary.main,
                    my: 3,
                  }}
                >
                  Already Registered?{" "}
                  <Typography
                    onClick={() => {
                      navigate("/login", { replace: "true" });
                    }}
                    variant="bold2"
                    sx={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Login
                  </Typography>
                </Typography>{" "}
              </Box>
            </form>
            <RegisterModal
              type={selectedValue}
              open={open}
              formSubmit={submit}
              onSubmit={onSubmit}
              handleClose={handleClose}
            />
          </Box>
          <OfflineRegistrationRequestModal
            open={offlineRegistration}
            setOpen={setOfflineRegistration}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
