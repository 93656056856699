import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../Apis/axiosPayloads";
import { POST } from "../../Apis/constVariables";
import { flexCenter, itemsCenter } from "../../common/commonStyles";
import { FormTextField } from "../../const/TextField";
import { AccordionTransition } from "../../const/constFunctions";
import { AddIcon } from "../../images/icons";
import { ExpandMoreSvg } from "./icons";
import "./styles/createTopic.css";
const CreateGamePrePost = ({
  moduleId,
  prePostApiCall,
  quizData,
  text,
  type,
  fetchPrePostAgain,
  disabled,
}) => {
  const initData = {
    moduleAssociated: moduleId,
    fileTitle: "",
    type: type,
  };
  const [zipFile, setZipFile] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [showQuizForm, setShowQuizForm] = useState(false);

  const [editable, setEditable] = useState(false);

  const addGame = async (data) => {
    try {
      //{{url}}/api/content/cb/quiz
      const url = process.env.REACT_APP_GAME;
      console.log({ url });
      console.log({ data });
      console.log({ zipFile });
      const formData = new FormData();
      formData.append("zipFile", zipFile);
      formData.append("data", JSON.stringify(data));

      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: formData,
      });
      setLoading(true);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      setLoading(false);
      fetchPrePostAgain();
      toggleForm();
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
  };

  const toggleForm = (e) => {
    reset(initData);
    setShowQuizForm((p) => !p);
  };

  function edit(quiz) {
    setEditable(true);
    // setQuizId(quiz.id);
    reset(quiz);
    setShowQuizForm(true);
  }

  const updateGame = async (data) => {
    //   try {
    //     let nsn = data?.quizName.replaceAll(" ", "");
    //     if (!nsn) {
    //       toast.error("Please Enter Quiz Name");
    //       return;
    //     }
    //     let dat = /\//i.test(nsn);
    //     if (dat) {
    //       toast.error("You cannot use (/) characters");
    //       return;
    //     }
    //     //{{url}}/api/content/cb/quiz
    //     const url = process.env.REACT_APP_GAME + `/${data?.quizId}`;
    //     const config = createAxiosConfig({
    //       endPoint: url,
    //       method: PATCH,
    //       data: {
    //         quizName: data?.quizName?.trim(),
    //       },
    //     });
    //     setLoading(true);
    //     const apiRes = await axios.request(config);
    //     toast(apiRes?.data?.message);
    //     setLoading(false);
    //     fetchPrePostAgain();
    //     toggleForm();
    //   } catch (err) {
    //     setLoading(false);
    //     console.log(err);
    //     toast.error(err?.response?.data?.message);
    //   }
  };

  return (
    <>
      <section>
        <Button
          variant="primary"
          disabled={quizData || prePostApiCall?.loading || loading || disabled}
          sx={{ my: 2 }}
          onClick={() => {
            setEditable(false);
            toggleForm();
          }}
        >
          <AddIcon />
          Add {text}
        </Button>
        {prePostApiCall?.loading && (
          <Box sx={{ ...flexCenter }}>
            <CircularProgress />
          </Box>
        )}

        {showQuizForm && (
          <Box
            className="createQuiz-quizForm createQuiz-container"
            id={"quizForm"}
          >
            <Typography
              variant="bold"
              sx={{ color: (theme) => theme.palette.primary.main }}
            >
              {text}
            </Typography>
            {/* <Box className="createQuiz-row" sx={{ my: 2 }}>
              <label className="createQuiz-inputLabel">Quiz Name *</label>
              <FormTextField
                {...register("quizName", { required: "Enter Quiz Name" })}
                variant="outlined"
                error={!!errors.quizName}
                helperText={errors?.quizName?.message}
              />
            </Box> */}
            <Box className="createQuiz-row" sx={{ my: 2 }}>
              <label className="createQuiz-inputLabel">Game Title*</label>
              <FormTextField
                {...register("fileTitle", { required: "Enter Game Title" })}
                variant="outlined"
                error={!!errors.fileTitle}
                helperText={errors?.fileTitle?.message}
              />
            </Box>
            <Box
              sx={{
                ...itemsCenter,
                width: "100%",
                my: "20px",
              }}
            >
              <Typography className="course-builder-inputLable">
                Select Zip*
              </Typography>
              <Box sx={{ ...itemsCenter, gap: "10px" }}>
                <Box
                  component={"label"}
                  sx={{
                    ...flexCenter,
                    height: "35px",
                    width: "125px",
                    cursor: "pointer",
                    borderRadius: "4px",
                    color: (theme) => theme.palette.text.main,
                    backgroundColor: (theme) => theme.palette.text.lighter,
                  }}
                >
                  Choose a file
                  <input
                    type="file"
                    accept="application/zip"
                    onChange={(e) => setZipFile(e.target.files[0])}
                    style={{ display: "none" }}
                  />
                </Box>
                <Typography
                  variant="regular"
                  // title={zipFile ? zipFile?.zipFile?.name : "No File Chosen"}
                  title={zipFile ? zipFile?.name : "No File Chosen"}
                  sx={{
                    color: (theme) => theme.pal,
                  }}
                >
                  {/* {zipFile ? zipFile?.zipFile?.name : "No File Chosen"} */}
                  {zipFile ? zipFile?.name : "No File Chosen"}
                </Typography>
              </Box>
            </Box>
            <Box className="createQuiz-row">
              <Button
                sx={{
                  ml: "auto",
                  textTransform: "none",
                }}
                onClick={() => {
                  setEditable(false);
                  toggleForm();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary-small"
                disabled={loading}
                onClick={handleSubmit(!editable ? addGame : updateGame)}
              >
                Save
              </Button>
            </Box>
          </Box>
        )}

        {quizData && (
          <Accordion TransitionProps={AccordionTransition}>
            <AccordionSummary
              expandIcon={<ExpandMoreSvg />}
              sx={{
                backgroundColor: (theme) => theme.palette.content.quiz,
                color: "white",
                height: "40px",
                borderRadius: "7px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box className="quiz-accordion-heading">
                  <p>{quizData.fileTitle}</p>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="quiz-accordion-details-head">
                <p>{quizData.fileTitle}</p>
                <Box
                  sx={{
                    ...itemsCenter,
                    ml: "auto",
                    mr: 1,
                  }}
                  // onClick={() => del(item)}
                >
                  {/* <IconButton onClick={() => edit(quizData)}>
                    <EditIconColor />
                  </IconButton> */}
                  {/* <QuizDeleteModal
                    data={quizData}
                    fetchQuizAgain={fetchPrePostAgain}
                  /> */}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
      </section>
    </>
  );
};

export default CreateGamePrePost;
