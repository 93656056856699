import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { PATCH } from "../../../Apis/constVariables";
import AssignBatchModal from "../../../Components/School/Facilitator/AssignBatchModal";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import {
  dFlex,
  flexBetweenWithItemsCenter,
  flexCenter,
} from "../../../common/commonStyles";
import { StyledTableCell, StyledTableRow } from "../../../const/Table";
import { FormTextField } from "../../../const/TextField";
import { checkSize, convertToBase64File } from "../../../const/constFunctions";

export default function FacilitatorDetails() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [url, setUrl] = useState(null);
  const [apiData, fetchApiData] = useDataFetchHook(url);
  const navigate = useNavigate();

  const [edit, setEdit] = useState(false);
  const [selectedImage, setSelectedImage] = useState({
    file: null,
    base64: null,
  });
  const handleImageChange = async (e) => {
    try {
      if (e?.target?.files.length > 0) {
        let file = e.target.files[0];
        if (checkSize(file) > 10) {
          e.target.value = "";
          toast.error("Please upload an image less than 10MB");
          return;
        }
        let base64 = convertToBase64File(file);
        base64 = await base64;
        setSelectedImage({ file, base64: base64 });
      } else
        setSelectedImage({
          file: null,
          base64: null,
        });
    } catch (err) {
      console.log(err);
      e.target.value = "";
      toast.error("Something went wrong, Please try again");
    }
  };
  const location = useLocation();
  const [facilitatorDetails, setFacilitatorDetails] = useState({
    name: "",
  });

  useEffect(() => {
    const { facilitatorDetails } = location?.state ?? { name: "" };
    const formattedData = {
      email: facilitatorDetails?.email,
      phone: facilitatorDetails?.phone,
      instructorName: facilitatorDetails?.instructorName,
      id: facilitatorDetails?.id,
    };
    setSelectedImage({
      file: facilitatorDetails?.image,
      base64: null,
    });
    const url =
      process.env.REACT_APP_SCHOOL_FACILITATOR +
      `/batchlist?id=${facilitatorDetails?.id}`;
    setUrl(url);
    setFacilitatorDetails(facilitatorDetails);
    reset(formattedData);
  }, [location.state]);

  const onSubmit = async (data) => {
    try {
      console.log("called");
      if (!data?.instructorName.trim()) {
        toast.error("Name cannot be empty");
        return;
      }
      const url = process.env.REACT_APP_SCHOOL_FACILITATOR + `/${data?.id}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: {
          instructorName: data?.instructorName.trim(),
          image: selectedImage.base64
            ? selectedImage.base64
            : selectedImage.file,
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      setEdit(false);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <Box>
      <NavBreadCrumbs
        pageTags={["Facilitator", facilitatorDetails?.instructorName]}
      />
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.secondary,
          borderRadius: "20px",
          p: 3,
        }}
      >
        <Box sx={{ ...flexBetweenWithItemsCenter }}>
          <Typography
            variant="medium"
            sx={{ color: (theme) => theme.palette.text.main }}
          ></Typography>
          {edit ? (
            <Box>
              <Button
                sx={{ mr: 2, textTransform: "none" }}
                onClick={() => {
                  reset(facilitatorDetails);
                  setEdit((p) => !p);
                }}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                Save
              </Button>
            </Box>
          ) : (
            <Button variant="primary" onClick={() => setEdit((p) => !p)}>
              Edit
            </Button>
          )}
        </Box>
        <Box sx={{ ...flexCenter, flexDirection: "column" }}>
          <div style={{ position: "relative", display: "inline-block" }}>
            <Avatar
              src={
                selectedImage.base64 ? selectedImage.base64 : selectedImage.file
              }
              sx={{
                height: "171px",
                width: "171px",
              }}
            />

            <IconButton
              sx={{
                position: "absolute",
                bottom: "5px",
                right: "5px",
                borderRadius: "50%",
                border: "5px solid #F3F5F5",
                backgroundColor: (theme) => theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.primary.main,
                },
              }}
              component="label"
            >
              {selectedImage.file ? (
                <EditIcon
                  fontSize="medium"
                  sx={{
                    color: (theme) => theme.palette.background.secondary,
                  }}
                />
              ) : (
                <AddIcon
                  fontSize="medium"
                  sx={{
                    color: (theme) => theme.palette.background.secondary,
                  }}
                />
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </IconButton>
          </div>
          <Box sx={{ width: "70%", my: 3 }}>
            <Box sx={{ mb: 4, ...dFlex, flexDirection: "column" }}>
              <Typography
                variant="bold2"
                sx={{
                  color: (theme) => theme.palette.text.main,
                  mb: 1,
                }}
              >
                Facilitator Name
              </Typography>
              <FormTextField
                disabled={!edit}
                autoFocus={edit}
                fullWidth
                placeholder="Student Name"
                {...register("instructorName", {
                  required: "This field is mandatory",
                })}
                error={Boolean(errors?.instructorName)}
                helperText={errors?.instructorName?.message}
              />
            </Box>
            <Box sx={{ mb: 4, ...dFlex, flexDirection: "column" }}>
              <Typography
                variant="bold2"
                sx={{
                  color: (theme) => theme.palette.text.main,
                  mb: 1,
                }}
              >
                E-mail ID*
              </Typography>
              <FormTextField
                disabled
                // disabled={!edit}
                fullWidth
                placeholder="email@domain.com"
                inputProps={{
                  style: {
                    textTransform: "lowercase",
                  },
                }}
                {...register("email", {
                  required: "This field is mandatory",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid Email Address",
                  },
                })}
                error={Boolean(errors?.email)}
                helperText={errors?.email?.message}
              />
            </Box>
            <Box sx={{ mb: 1, ...dFlex, flexDirection: "column" }}>
              <Typography
                variant="bold2"
                sx={{
                  color: (theme) => theme.palette.text.main,
                  mb: 1,
                }}
              >
                Phone Number*
              </Typography>
              <FormTextField
                // disabled={!edit}
                disabled
                fullWidth
                placeholder="+12123456789"
                {...register("phone")}
                error={Boolean(errors?.phone)}
                helperText={errors?.phone?.message}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.secondary,
          borderRadius: "20px",
          minHeight: "50vh",
          my: 5,
          p: 3,
        }}
      >
        <Box sx={{ ...dFlex, justifyContent: "space-between" }}>
          <Typography
            variant="bold"
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          >
            Batches
          </Typography>
          <AssignBatchModal
            currentBatchCount={apiData?.res?.batch?.length ?? 0}
            fetchAgain={fetchApiData}
            facilitatorDetails={facilitatorDetails}
          />
        </Box>
        {apiData?.loading && (
          <Box sx={{ ...flexCenter }}>
            <CircularProgress />
          </Box>
        )}
        <Box sx={{ mt: 2, maxHeight: "50vh", overflow: "scroll" }}>
          <TableContainer sx={{ overflowX: "initial" }}>
            <Table stickyHeader>
              {/* Table header */}
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <Box sx={{ ...dFlex, gap: 1 }}>
                      <Typography
                        variant="semibold"
                        sx={{ color: (theme) => theme.palette.text.dark }}
                      >
                        Sl. No.
                      </Typography>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box sx={{ ...dFlex, gap: 1 }}>
                      <Typography
                        variant="semibold"
                        sx={{ color: (theme) => theme.palette.text.dark }}
                      >
                        Batch Name
                      </Typography>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box sx={{ ...dFlex, gap: 1 }}>
                      <Typography
                        variant="semibold"
                        sx={{ color: (theme) => theme.palette.text.dark }}
                      >
                        Student Count
                      </Typography>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box sx={{ ...dFlex, gap: 1 }}>
                      <Typography
                        variant="semibold"
                        sx={{ color: (theme) => theme.palette.text.dark }}
                      >
                        Action
                      </Typography>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {apiData?.res?.batch?.map((row, index) => (
                  <StyledTableRow
                    sx={{
                      cursor: "default!important",
                    }}
                  >
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {index + 1}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row?.batchName}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row?.studentNumber ?? 0}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        onClick={() => {
                          navigate(`/school/manage-batch/${row?.batchName}`, {
                            state: {
                              batchDetail: row,
                            },
                          });
                        }}
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                          cursor: "pointer",
                        }}
                      >
                        View
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
}
