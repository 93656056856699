import { Box } from "@mui/material";
import React from "react";
import AllStudentsTable from "../../../Components/Admin/Dashboard/AllStudentsTable";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";

// Function to render individual tab panels

export default function AllStudents() {
  return (
    <Box>
      {/* Navigation breadcrumbs */}
      <NavBreadCrumbs pageTags={["Dashboard", "All Students"]} />

      <AllStudentsTable />
    </Box>
  );
}
