import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ACEIndividualQuestion from "../../../Components/Admin/DemographicData/ACEIndividualQuestion";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { GET } from "../../../Apis/constVariables";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import axios from "axios";

export default function ACETestPage() {
  const [allData, setAllData] = useState([]);
  const getAllData = async () => {
    const url = process.env.REACT_APP_DEMOGRAPHIC_DATA + `/ACE`;
    const config = createAxiosConfig({
      endPoint: url,
      method: GET,
    });
    // console.log(config);
    const apiRes = await axios.request(config);
    setAllData(apiRes.data.data);
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Demographic Data", "ACE Test"]} />
      <Typography
        variant="bold"
        sx={{ color: (theme) => theme.palette.primary.main, mb: 3 }}
      >
        ACE Test
      </Typography>
      {/* <Box sx={{ ...dFlex, gap: 3, my: 2 }}>
        <Box
          sx={{
            ...dFlex,
            flexDirection: "column",
            backgroundColor: (theme) => theme.palette.background.secondary,
            // width: "200px",
            p: 2,
            borderRadius: "9px",
          }}
        >
          <Typography
            variant="semibold"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            {" "}
            Total Yes
          </Typography>

          <Typography
            variant="bold"
            sx={{
              fontSize: "30px",
              color: (theme) => theme.palette.text.light,
            }}
          >
            900000+
          </Typography>
        </Box>
        <Box
          sx={{
            ...dFlex,
            flexDirection: "column",
            backgroundColor: (theme) => theme.palette.background.secondary,
            // width: "200px",
            p: 2,
            borderRadius: "9px",
          }}
        >
          <Typography
            variant="semibold"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            {" "}
            Total No
          </Typography>

          <Typography
            variant="bold"
            sx={{
              fontSize: "30px",
              color: (theme) => theme.palette.text.light,
            }}
          >
            80000+
          </Typography>
        </Box>
      </Box> */}

      <Box>
        {allData.map((e, i) => (
          <ACEIndividualQuestion data={e} index={i} />
        ))}
      </Box>
    </Box>
  );
}
