import axios from "axios";
import { useContext, useEffect, useReducer, useState } from "react";
import { createAxiosConfig } from "../Apis/axiosPayloads";
import { error, request, success } from "../const/variables";
import { LoadingBarContext } from "../context/TopLodingBarContext";

const initState = {
  loading: false,
  error: false,
  errorMessage: "",
  res: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case request:
      return {
        ...state,
        loading: true,
      };

    case success:
      return {
        loading: false,
        error: false,
        errorMessage: "",
        res: action?.data,
      };
    case error:
      return {
        loading: false,
        error: true,
        errorMessage: action?.message,
        res: null,
      };

    default:
      return initState;
  }
};

export default function useDataFetchHook(url) {
  const [state, dispatch] = useReducer(reducer, initState);
  const [retry, setRetry] = useState(0);
  const { ref } = useContext(LoadingBarContext);

  const retryData = () => {
    setRetry((p) => ++p);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchData = async () => {
      if (!url) {
        return;
      }
      try {
        ref.current.continuousStart();
        dispatch({ type: request });
        const config = createAxiosConfig({
          endPoint: url,
          data: {
            type: "school",
          },
          other: {
            cancelToken: source.token,
          },
        });
        // console.log({ config });
        const response = await axios.request(config, {
          cancelToken: source.token,
        });
        const data = response?.data;
        dispatch({ type: success, data });
        ref.current.complete();
      } catch (err) {
        if (axios.isCancel(err)) {
          dispatch({ type: error, message: err?.message });
        } else {
          console.log(err);
          dispatch({ type: error, message: err?.response?.data?.message });
        }
      }
    };

    fetchData();

    return () => {
      // console.log("aborting request");
      source.cancel();
    };
  }, [url, retry]);

  return [state, retryData, dispatch];
}
