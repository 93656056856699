import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import useTablePagination from "../../../Hooks/TablePaginationHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { dFlex, flexSpaceBetween } from "../../../common/commonStyles";
import {
  StyledTableCell,
  StyledTableRow,
  TableRowSkeletonComponent,
} from "../../../const/Table";
import { FormTextField } from "../../../const/TextField";
import {
  ArrowDropDownIconSvg,
  ArrowDropUpIconSvg,
} from "../../../images/icons";
export default function SchoolFacilitatorInformation() {
  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();
  const navigate = useNavigate();
  const params = useParams();
  const [facilitatorListApi] = useDataFetchHook(
    process.env.REACT_APP_SA_DASHBOARD_LIST +
      `/subscriber/facilitators?&schoolId=${params?.schoolId}`
  );
  const [selectedSortUp, setSelectedSortUp] = useState("");
  const [selectedSortDown, setSelectedSortDown] = useState("");
  const [schoolData, setSchoolData] = useState({ name: "" });
  const location = useLocation();
  useEffect(() => {
    // Set school data from the location state
    const { school } = location?.state ?? { name: "" };
    setSchoolData(school);

    // Cleanup function to reset schoolData when component unmounts
    return () => {
      setSchoolData({ name: "" });
    };
  }, [location.state]);

  const [searchData, setSearchData] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const handleSearch = () => {
    try {
      // console.log("searhing");
      let arr = [...facilitatorListApi?.res?.data];
      arr = arr?.filter((e) => {
        return e?.instructorName
          ?.toLowerCase()
          ?.includes(searchText.toLowerCase());
      });
      handleChangePage(null, 0);
      setSearchData(arr);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!searchText) {
      setSearchData(facilitatorListApi?.res?.data);
      return;
    }
    handleSearch();
  }, [searchText]);

  useEffect(() => {
    if (!facilitatorListApi?.res) {
      return;
    }

    setSearchData(facilitatorListApi?.res?.data);
    return () => {};
  }, [facilitatorListApi?.res]);

  const handleClear = () => {
    setSelectedSortDown("");
    setSelectedSortUp("");
    setSearchText("");
    setSearchData(facilitatorListApi?.res?.data);
  };

  const sortArr = (para) => {
    setSelectedSortUp(para);
    setSelectedSortDown("");

    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "instructorName":
          fir = a.instructorName.toLowerCase();
          sec = b.instructorName.toLowerCase();
          break;
        case "batchCount":
          fir = parseInt(a.batchCount);
          sec = parseInt(b.batchCount);
          break;
        case "batchCount":
          fir = parseInt(a.batchCount);
          sec = parseInt(b.batchCount);
          break;
        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir < sec) {
        return -1;
      }
      if (fir > sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  const sortArrDec = (para) => {
    setSelectedSortUp("");
    setSelectedSortDown(para);

    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "instructorName":
          fir = a.instructorName.toLowerCase();
          sec = b.instructorName.toLowerCase();
          break;
        case "batchCount":
          fir = parseInt(a.batchCount);
          sec = parseInt(b.batchCount);
          break;
        case "batchCount":
          fir = parseInt(a.batchCount);
          sec = parseInt(b.batchCount);
          break;
        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir > sec) {
        return -1;
      }
      if (fir < sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  return (
    <Box>
      <NavBreadCrumbs
        pageTags={[
          "Dashboard",
          "School Subscribers",
          schoolData?.name,
          "Facilitator Information",
        ]}
      />
      <Box>
        <FormTextField
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
          disabled={facilitatorListApi?.loading}
          placeholder="search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  fontSize="small"
                  sx={{
                    color: "#BFBFBF",
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
        <Button
          onClick={handleClear}
          sx={{
            ml: 2,
            textTransform: "none",
            color: (theme) => theme.palette.text.main,
          }}
        >
          {" "}
          x Clear
        </Button>
      </Box>
      <Box sx={{ mt: 3, ...flexSpaceBetween }}>
        <Typography
          variant="bold"
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          Facilitator Information
        </Typography>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: "13px",
          my: 3,
          pt: 1,
          px: "20px",
          boxSizing: "border-box",
          maxHeight: facilitatorListApi?.loading ? "400px" : "1200px",
          transition: "all 1s ease",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Box sx={{ ...dFlex, gap: 1 }}>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Name
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArr("instructorName")}
                    >
                      <ArrowDropUpIconSvg
                        focus={selectedSortUp === "instructorName"}
                      />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArrDec("instructorName")}
                    >
                      <ArrowDropDownIconSvg
                        focus={selectedSortDown === "instructorName"}
                      />
                    </Box>
                  </Box>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  variant="medium"
                  sx={{ color: (theme) => theme.palette.text.dark }}
                >
                  Phone
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  variant="medium"
                  sx={{ color: (theme) => theme.palette.text.dark }}
                >
                  E-mail
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ ...dFlex, gap: 1 }}>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Batches
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArr("batchCount")}
                    >
                      <ArrowDropUpIconSvg
                        focus={selectedSortUp === "batchCount"}
                      />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArrDec("batchCount")}
                    >
                      <ArrowDropDownIconSvg
                        focus={selectedSortDown === "batchCount"}
                      />
                    </Box>
                  </Box>
                </Box>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {facilitatorListApi?.loading ? (
            <TableRowSkeletonComponent column={4} row={4} />
          ) : (
            <TableBody>
              {searchData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow
                    onClick={() => {
                      navigate(`${row?.id}`, {
                        state: {
                          schoolInfo: schoolData,
                          facilitatorInfo: row,
                        },
                      });
                    }}
                  >
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row?.instructorName ?? "NA"}{" "}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row?.phone ?? "NA"}{" "}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{ textTransform: "lowercase!important" }}
                    >
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row?.email ?? "NA"}{" "}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row.batchCount ?? 0}{" "}
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <TablePagination
          rowsPerPageOptions={[]}
          count={searchData?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
}
