import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from "@mui/material";
import { AddIcon, EditIconColor } from "../../../../images/icons";
import {
  flexCenter,
  itemsCenter,
  textEllipses,
} from "../../../../common/commonStyles";
import { FormTextField } from "../../../../const/TextField";
import { toast } from "react-toastify";
export default function UploadGameModal({ edit }) {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFile(null);
    setOpen(false);
  };

  const handleImageChange = (state, event) => {
    if (event?.target?.files.length > 0) state(event?.target?.files?.[0]);
    else state(null);
  };

  const handleAdd = () => {
    toast("You added a game");
    handleClose();
  };

  const handleUpdate = () => {
    toast("Game has been updated");
    handleClose();
  };

  return (
    <>
      {edit ? (
        <IconButton sx={{ py: 0 }} onClick={handleOpen}>
          <EditIconColor />
        </IconButton>
      ) : (
        <Button variant="primary" onClick={handleOpen}>
          <AddIcon /> Upload Game
        </Button>
      )}
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              backgroundColor: (theme) => theme.palette.background.main,
              borderRadius: "14px",
              pl: 3,
              pr: 1,
              pt: 3,
              pb: 2,
              maxWidth: "100%",
              width: "35%",
            },
          }}
        >
          <Typography
            variant="bold"
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {edit ? "Update" : "Upload"} Game
          </Typography>{" "}
          <DialogContent sx={{ p: 0 }}>
            <DialogContentText>
              <Box
                sx={{
                  ...itemsCenter,
                  width: "100%",
                  my: "20px",
                  gap: 2,
                }}
              >
                <Typography variant="medium" sx={{ width: "28%" }}>
                  Upload a Zip File
                </Typography>
                <Box sx={{ ...itemsCenter, gap: "10px" }}>
                  <Box
                    component={"label"}
                    sx={{
                      ...flexCenter,
                      height: "35px",
                      width: "125px",
                      cursor: "pointer",
                      borderRadius: "4px",
                      color: (theme) => theme.palette.text.main,
                      backgroundColor: (theme) => theme.palette.text.lighter,
                      // "&:hover": {
                      //   backgroundColor: "#D1D0D0",
                      // },
                    }}
                  >
                    Choose a file
                    <input
                      type="file"
                      accept="application/zip"
                      onChange={(e) => handleImageChange(setFile, e)}
                      style={{ display: "none" }}
                    />
                  </Box>
                  <Typography
                    variant="regular"
                    // contentEditable="true"
                    title={file ? file?.name : "No File Chosen"}
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      maxWidth: "220px",
                      ...textEllipses,
                    }}
                  >
                    {file ? file?.name : "No File Chosen"}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  ...itemsCenter,
                  width: "100%",
                  my: "20px",
                  gap: 2,
                }}
              >
                <Typography variant="medium" sx={{ width: "28%" }}>
                  Tokens
                </Typography>
                <FormTextField
                  sx={{ width: "32%" }}
                  type="number"
                  placeholder="First attempt"
                  autoComplete="off"
                />
                <FormTextField
                  sx={{ width: "32%" }}
                  type="number"
                  placeholder="Second attempt"
                  autoComplete="off"
                />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => (edit ? handleUpdate() : handleAdd())}
              // disabled={dialogName !== "delete"}
              variant="primary"
              sx={{
                "&:disabled": {
                  backgroundColor: "gray",
                  color: "#FFF",
                },
              }}
            >
              Save{" "}
            </Button>
          </DialogActions>
          {/* </form> */}
        </Dialog>
      )}
    </>
  );
}
