import {
  Box,
  Button,
  Dialog,
  DialogActions,
  FormHelperText,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { PATCH, POST } from "../../../Apis/constVariables";
import { itemsCenter, justifyCenter } from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import {
  AddIcon,
  AddIconBig,
  EditIconColor,
  RemoveIconBig,
} from "../../../images/icons";

// const initData = new Array(3).fill({ option: "", score: "" });
const initData = {
  question: "",
  option: ["", "", ""],
  marks: ["", "", ""],
};

export default function SDQQuestionAddModal({
  total,
  edit,
  type,
  data,
  fetchAgain,
}) {
  const [sdqData, setSdqData] = useState(initData);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleAdd = () => {
    setSdqData((prevData) => {
      const updatedOptions = [...prevData.option, ""];
      const updatedMarks = [...prevData.marks, ""];
      return {
        ...prevData,
        option: updatedOptions,
        marks: updatedMarks,
      };
    });
  };

  const handleRemove = (id) => {
    setSdqData((prevData) => {
      const updatedOptions = prevData.option.filter((e, i) => i !== id);
      const updatedMarks = prevData.marks.filter((e, i) => i !== id);
      return {
        ...prevData,
        option: updatedOptions,
        marks: updatedMarks,
      };
    });
  };

  const handleClickOpen = () => {
    setSdqData(data ?? initData);
    setOpen(true);
  };

  const handleClose = () => {
    setSdqData(initData);
    setOpen(false);
  };
  const handleSave = async () => {
    try {
      setLoading(true);
      const url = process.env.REACT_APP_SUPERADMIN_RISK_PROFILING_QUESTION;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...sdqData,
          seq: total + 1,
          type: type == 0 ? "SDQPre" : "SDQPost",
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchAgain();
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      const url =
        process.env.REACT_APP_SUPERADMIN_RISK_PROFILING_QUESTION +
        `/${sdqData?.id}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: {
          ...sdqData,
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchAgain();
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <>
      {edit ? (
        <IconButton
          sx={{ flex: "2%", height: "fit-content" }}
          onClick={handleClickOpen}
        >
          <EditIconColor />
        </IconButton>
      ) : (
        <Button variant="primary" onClick={handleClickOpen}>
          <AddIcon />
          Add Questionnaires
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "#F3F5F5",
            borderRadius: "14px",
            p: 5,
            maxWidth: "100%",
            width: "70%",
          },
        }}
      >
        <Typography
          variant="bold"
          sx={{
            color: (theme) => theme.palette.primary.main,
            mb: 3,
          }}
        >
          SDQ {type == 0 ? "Pre" : "Post"} Test
        </Typography>
        <Box>
          <FormTextField
            fullWidth
            autoFocus
            value={sdqData?.question}
            onChange={(e) => {
              if (e.target.value?.length > 500) {
                toast.error("Maximum 500 characters allowed");
                return;
              }
              setSdqData({
                ...sdqData,
                question: e.target.value,
              });
            }}
            InputProps={{
              sx: {
                height: "37px",
              },
            }}
            placeholder="Type Question"
            autoComplete="off"
            sx={{ height: "37px", mt: 1 }}
          />
          <FormHelperText
            sx={{ my: 0, width: "100%", textAlign: "right" }}
            error={sdqData?.question?.length > 500}
          >
            <Typography
              variant="semibold2"
              sx={{
                color: "#BFBFBF",
              }}
            >
              {sdqData?.question?.length} / 500
            </Typography>
          </FormHelperText>
        </Box>
        <Box
          sx={{
            ...justifyCenter,
            gap: 2,
            mt: 4,
            flexDirection: "column",
            transition: "height 0.5s ease",
            height: `${sdqData?.option?.length * 60}px`,
          }}
        >
          {sdqData?.option?.map((e, i) => (
            <Box
              sx={{
                ...itemsCenter,
                // width: "800px",
                gap: 5,
              }}
            >
              <Box sx={{ width: "7%" }}>
                {i > sdqData?.option.length - 2 && i < 6 && (
                  <IconButton sx={{ p: 0 }} onClick={handleAdd}>
                    <AddIconBig />
                  </IconButton>
                )}
              </Box>
              {/* <Box sx={{ ...itemsCenter, flex: "80%", gap: 2 }}> */}
              <Typography
                variant="medium"
                sx={{
                  width: "20%",
                  color: (theme) => theme.palette.text.light,
                }}
              >
                Option {i + 1}
              </Typography>
              <FormTextField
                autoFocus={i > 2}
                value={e}
                onChange={(event) => {
                  const { value } = event.target;
                  setSdqData((prevValue) => {
                    const updatedOption = [...prevValue.option];
                    updatedOption[i] = value;
                    return {
                      ...prevValue,
                      option: updatedOption,
                    };
                  });
                }}
                fullWidth
                InputProps={{
                  sx: {
                    height: "37px",
                  },
                }}
                placeholder="Type Answer"
                autoComplete="off"
                sx={{ height: "37px", my: 1 }}
              />
              {/* <Typography variant="medium">Score</Typography> */}
              <FormTextField
                fullWidth
                onChange={(event) => {
                  const { value } = event.target;
                  setSdqData((prevValue) => {
                    const updatedOption = [...prevValue.marks];
                    updatedOption[i] = value;
                    return {
                      ...prevValue,
                      marks: updatedOption,
                    };
                  });
                }}
                onBlur={(event) => {
                  const { value } = event.target;
                  setSdqData((prevValue) => {
                    const updatedOption = [...prevValue.marks];
                    updatedOption[i] = Math.abs(value);
                    return {
                      ...prevValue,
                      marks: updatedOption,
                    };
                  });
                }}
                value={sdqData?.marks?.[i]}
                type="number"
                InputProps={{
                  sx: {
                    height: "37px",
                  },
                }}
                placeholder="Score"
                autoComplete="off"
                sx={{ height: "37px", my: 1 }}
              />

              <Box sx={{ width: "5%" }}>
                {i > 2 && (
                  <IconButton sx={{ p: 0 }} onClick={() => handleRemove(i)}>
                    <RemoveIconBig />
                  </IconButton>
                )}
              </Box>
            </Box>
          ))}
        </Box>
        <DialogActions
          sx={{
            pt: 4,
          }}
        >
          <Button
            sx={{ height: "32px", textTransform: "none" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ height: "32px" }}
            variant="primary"
            disabled={loading}
            onClick={!edit ? handleSave : handleUpdate}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
