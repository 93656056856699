import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import { FormTextField } from "../../../const/TextField";
import { CancelIcon } from "../../../images/icons";

export default function CancelSubscriptionModal() {
  const [open, setOpen] = useState(false);
  const [refundAmount, setRefundAmount] = useState(null);
  const [loading, setLoading] = useState(false);
  const params = useParams();

  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = async () => {
    try {
      setLoading(true);
      const url = process.env.REACT_APP_SA_TRANSACTIONS + "/cancelSubscription";
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: { id: params?.schoolId, amount: refundAmount },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      handleClose();
      navigate(-1);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Button variant="primary" onClick={handleOpen}>
        <CancelIcon /> Cancel Subscription
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: (theme) => theme.palette.background.main,
            borderRadius: "10px",
            pb: 2,
            maxWidth: "100%",
            width: "30%",
          },
        }}
      >
        <Box sx={{ padding: "0px 5px" }}>
          {/* <form onSubmit={handleDeleteAdmission} autoComplete="off"> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to cancel subscription?
              <br />
              {/* Type <b>cancel</b> to confirm. */}
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              <FormTextField
                value={refundAmount}
                fullWidth
                onChange={(e) => {
                  setRefundAmount(e.target.value);
                }}
                sx={{ mt: "20px", color: "#BFBFBF" }}
                placeholder="Refund amount (Optional)"
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              disabled={loading}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={handleCancel}
              variant="primary-medium"
              sx={{
                "&:disabled": {
                  backgroundColor: "gray",
                  color: "#FFF",
                },
              }}
            >
              Cancel Subscription
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
