import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { flexCenter } from "../../common/commonStyles";
import { PaymentSuccessIcon } from "../../images/icons";

export default function OfflineRegistrationRequestModal({
  open,
  setOpen,
  navigateToLogin = true,
}) {
  const navigate = useNavigate();
  const handleClose = () => {
    // localStorage.clear();
    if (navigateToLogin) {
      navigate("/login", {
        replace: true,
      });
    }
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "13px",
            backgroundColor: (theme) => theme.palette.background.main,
          },
        }}
      >
        <Box sx={{ width: "400px", pb: "20px", textAlign: "center" }}>
          {/* <form onSubmit={handleDeleteAdmission} autoComplete="off"> */}
          <DialogContent sx={{ mb: 0 }}>
            <DialogContentText id="alert-dialog-description">
              <Box sx={{ ...flexCenter, flexDirection: "column", mb: 1 }}>
                <PaymentSuccessIcon />
                <Typography
                  variant="regular"
                  sx={{
                    color: "#2B8E2F",
                    fontWeight: "700",
                  }}
                >
                  Success
                </Typography>
              </Box>
              <Typography
                variant="regular"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Verification request has been sent to the admin
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Close
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
