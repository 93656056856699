import { Box, CircularProgress } from "@mui/material";
import React from "react";
import ModuleElement from "../../../Components/CourseOverview/ModuleElement";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { flexCenter } from "../../../common/commonStyles";

export default function ProgramContent() {
  const [apiCall] = useDataFetchHook(
    process.env.REACT_APP_PUBLISHED_MODULE + "1"
  );

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Program Content"]} />
      {apiCall?.loading && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />
        </Box>
      )}
      <Box>
        {apiCall?.res?.sequence?.map((e) => (
          <ModuleElement key={e.moduleId} data={e} />
        ))}
      </Box>
    </Box>
  );
}
