import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import FaciGroupComponent from "../../../Components/Facilitator/CommunityProject/FaciGroupComponent";
import FaciRequestComponent from "../../../Components/Facilitator/CommunityProject/FaciRequestComponent";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { flexCenter, flexSpaceBetween } from "../../../common/commonStyles";
import { reactSelectTheme } from "../../../const/constFunctions";
import { select_batch } from "../../../const/imageUrl";

export default function FacilitatorCommunityProject() {
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const url =
    process.env.REACT_APP_SCHOOL_FACILITATOR +
    `/batchlist?filterCompleted=true`;

  const [apiData] = useDataFetchHook(url);

  const handleChange = (newValue) => {
    setSelectedValue(newValue);
  };
  // state uplifted can be optimized but time constraint is there
  const [groupApiData, fetchGroupAgain] = useDataFetchHook(
    selectedValue?.value &&
      process.env.REACT_APP_FACILITATOR_GET_GROUP_APPROVED +
        selectedValue?.value
  );
  const [requestApiData, fetchRequestAgain] = useDataFetchHook(
    selectedValue?.value &&
      process.env.REACT_APP_FACILITATOR_GET_GROUP_REQUEST + selectedValue?.value
  );

  const fetchAllAgain = () => {
    fetchGroupAgain();
    fetchRequestAgain();
  };

  useEffect(() => {
    if (!apiData?.res) {
      return;
    }
    let formattedData = apiData?.res.batch?.map((e) => {
      return {
        label: e.batchName,
        value: e.batchName,
      };
    });
    setOptions(formattedData);
    return () => {
      setOptions([]);
    };
  }, [apiData?.res]);

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Community Project"]} />
      {apiData?.loading && (
        <Box sx={flexCenter}>
          <CircularProgress />
        </Box>
      )}
      <Box sx={{ maxWidth: "240px", mb: 5 }}>
        <Select
          theme={reactSelectTheme}
          isClearable
          options={options}
          //   styles={{
          //     control: (provided) => ({
          //       ...provided,
          //       width: "175px",
          //     }),
          //   }}
          placeholder="Select Batch"
          value={selectedValue}
          onChange={handleChange}
        />
      </Box>{" "}
      {/* {false && ( */}
      {!selectedValue && (
        <Box
          sx={{
            ...flexCenter,
            backgroundColor: (theme) => theme.palette.background.secondary,
            borderRadius: "13px",
            flexDirection: "column",
            py: 5,
            gap: 2,
          }}
        >
          <img src={select_batch} alt="no batch selected" />
          <Typography
            variant="bold"
            sx={{
              color: (theme) => theme.palette.text.light,
            }}
          >
            Please select a batch first
          </Typography>
        </Box>
      )}
      {/* {true && ( */}
      {selectedValue && (
        <Box sx={{ ...flexSpaceBetween, gap: 2 }}>
          <FaciRequestComponent
            fetchAgain={fetchAllAgain}
            apiData={requestApiData}
          />
          <FaciGroupComponent
            fetchAgain={fetchAllAgain}
            apiData={groupApiData}
          />
        </Box>
      )}
    </Box>
  );
}
