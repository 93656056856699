import {
  Box,
  Button,
  Dialog,
  DialogActions,
  FormControl,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import {
  currencyType,
  defaultVal,
  paymentOptions,
} from "../../../Pages/Auth/RegisterModal";
import { itemsCenter } from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { reactSelectTheme } from "../../../const/constFunctions";
import { UserContext } from "../../../context/UserContext";
import { startPayment } from "../../../functions/paymentGateway";
import OfflineRegistrationRequestModal from "../../Registration/OfflineRegistrationRequestModal";

export default function SubscribeModal({ text, type }) {
  const { userDetails } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [offlineRegistration, setOfflineRegistration] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const [paymentMode, setPaymentMode] = useState({
    label: "Online",
    value: "online",
  });
  const [currency, setCurrency] = useState({
    label: "JMD",
    value: "jmd",
  });
  const [numberOfStudent, setNumberOfStudent] = useState(null);
  const [calculatedPrice, setCalculatedPrice] = useState(defaultVal);
  const [calculated, setCalculated] = useState(false);
  const [submit, setSubmit] = useState(false);
  const handleClose = () => {
    setCalculatedPrice(defaultVal);
    setNumberOfStudent(null);
    setCalculated(false);
    setOpen(false);
  };
  const handleGetPrice = async (numberOfStudent) => {
    try {
      if (!numberOfStudent) {
        setCalculatedPrice(defaultVal);
        setCalculated(false);
        setNumberOfStudent(null);
        toast.error("Please enter a valid number");
        return;
      }
      // toast("Calculating price");
      const url = process.env.REACT_APP_GET_CALCULATED_PRICE;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          type: userDetails?.type,
          student: numberOfStudent,
        },
      });
      setSubmit(true);
      const apiRes = await axios.request(config);
      setCalculated(true);
      setCalculatedPrice(apiRes?.data?.Price);
      // toast("Price Calculated");
    } catch (err) {
      setNumberOfStudent(null);
      setCalculatedPrice(defaultVal);
      toast.error(err?.response?.data?.message);
    }
    setSubmit(false);
  };

  const subscribeMore = async () => {
    try {
      if (!calculated) {
        toast.error("Please enter a number");
        return;
      }
      setSubmit(true);
      if (paymentMode.value === "online") {
        await startPayment(
          userDetails.email,
          userDetails.contactPersonNumber ?? "",
          {
            email: userDetails.email,
            numberOfStudent: numberOfStudent,
            paymentMode: paymentMode.value,
          },
          calculatedPrice?.[currency.value],
          window.location.pathname,
          currency.label
        );
      } else {
        try {
          let formattedData = {
            amount: calculatedPrice[currency.value],
            numberOfStudent,
            email: userDetails.email,
            paymentMode: paymentMode.value,
            subscribeMore: true,
            currency: currency.value,
          };

          const url = process.env.REACT_APP_SUBSCRIBE_MORE + "Offline";
          const config = createAxiosConfig({
            endPoint: url,
            method: POST,
            data: formattedData,
          });
          const apiRes = await axios.request(config);
          toast(apiRes?.data?.message);
          setOfflineRegistration(true);
        } catch (err) {
          toast.error(err?.response?.data?.message);
        }
      }
    } catch (err) {}
    setSubmit(false);
    handleClose();
  };

  return (
    <>
      <Button variant="primary" onClick={handleClickOpen}>
        {text}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "#F3F5F5",
            borderRadius: "14px",
            p: "24px 12px 12px 24px",
            maxWidth: "100%",
            width: "30%",
          },
        }}
      >
        <Typography
          variant="bold2"
          sx={{
            color: (theme) => theme.palette.text.main,
            mb: 1,
          }}
        >
          Enter the number of students
        </Typography>
        <FormTextField
          type="number"
          sx={{ mb: 1 }}
          value={numberOfStudent}
          onBlur={(e) => {
            if (e.target.value < 0) {
              setNumberOfStudent(Math.abs(e.target.value));
            }
            handleGetPrice(Math.abs(e.target.value));
          }}
          onChange={(e) => setNumberOfStudent(e.target.value)}
          placeholder="Enter number of students you want to register"
        />
        <Box sx={{ ...itemsCenter, gap: 2 }}>
          <Typography
            variant="bold2"
            sx={{
              color: (theme) => theme.palette.text.main,
            }}
          >
            Currency
          </Typography>
          <FormControl sx={{ width: "50%" }}>
            <Select
              isSearchable={false}
              onChange={(e) => setCurrency(e)}
              value={currency}
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "120px",
                }),
              }}
              options={currencyType}
              theme={reactSelectTheme}
            />
          </FormControl>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography
            sx={{
              color: (theme) => theme.palette.text.main,
              mb: 1,
              ...itemsCenter,
              gap: 2,
            }}
          >
            Total{" "}
            <Typography variant="bold">
              ${calculatedPrice?.[currency.value]}
            </Typography>
          </Typography>
        </Box>

        <Box sx={{ ...itemsCenter, gap: 3 }}>
          <Typography
            variant="bold2"
            sx={{
              color: (theme) => theme.palette.text.main,
            }}
          >
            Payment Mode:
          </Typography>
          <FormControl sx={{ width: "50%" }}>
            <Select
              isSearchable={false}
              onChange={(e) => setPaymentMode(e)}
              value={paymentMode}
              options={paymentOptions}
              theme={reactSelectTheme}
            />
          </FormControl>
        </Box>
        <DialogActions sx={{ pt: 1 }}>
          <Button
            sx={{ height: "32px", textTransform: "none" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button onClick={subscribeMore} disabled={submit} variant="primary">
            {!calculated ? "Calculate " : "Subscribe"}{" "}
          </Button>
        </DialogActions>
      </Dialog>
      {offlineRegistration && (
        <OfflineRegistrationRequestModal
          open={offlineRegistration}
          setOpen={setOfflineRegistration}
          navigateToLogin={false}
        />
      )}
    </>
  );
}
