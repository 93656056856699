import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Select from "react-select";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import { dFlex, flexCenter } from "../../../common/commonStyles";
import {
  getLastTenWeeks,
  reactSelectTheme,
} from "../../../const/constFunctions";
import { analyticsEmpty } from "../../../const/imageUrl";

export default function AnalyticsGraph() {
  const [calculatedWeeks, setCalculatedWeeks] = useState([]);
  const [url, setUrl] = useState(null);
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(null);

  const [labels, setLabels] = useState([]);
  const [series, setSeries] = useState([]);
  const [analyticsDataApi] = useDataFetchHook(url);
  const handleChange = (selectedOption) => {
    setSelectedTimeFrame(selectedOption);
  };

  const option = {
    chart: {
      toolbar: {
        tools: {
          download: false,
        },
      },
      zoom: {
        enabled: true,
      },
    },
    // chart: {
    //   type: "bar",
    //   height: 350,
    // },
    plotOptions: {
      bar: {
        barWidth: "150px",
      },
    },
    title: {
      text: "Tokens",
      align: "left",
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: labels,
    },
    // fill: {
    //   type: "gradient",
    //   colors: ["#358B8A", "#06CFF1"],
    // },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#358B8A", "#06CFF1"],
        // gradientToColors: ["#06CFF1", "#358B8A"],
        shadeIntensity: 1,
        type: "vertical",
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    // tooltip: {
    //   y: {
    //     formatter: function (val) {
    //       return val + "%";
    //     },
    //   },
    // },
  };

  useEffect(() => {
    const res = getLastTenWeeks();
    res[0] = { label: "This Week", value: res[0].value };
    setCalculatedWeeks(res);
    setSelectedTimeFrame(res[0]);
  }, []);

  useEffect(() => {
    if (!selectedTimeFrame) {
      return;
    }
    let start = `${selectedTimeFrame?.value}`?.split(" - ")?.[0];
    let end = `${selectedTimeFrame?.value}`?.split(" - ")?.[1];
    const url =
      process.env.REACT_APP_SCHOOL_ANALYTICS + `?start=${start}&end=${end}`;
    setUrl(url);
    // console.log({ url });
    // console.log({ selectedTimeFrame });
  }, [selectedTimeFrame]);

  useEffect(() => {
    if (!analyticsDataApi?.res) {
      return;
    }

    let seriesData = analyticsDataApi?.res?.data?.map((e) => e.tokens);

    setLabels(analyticsDataApi?.res?.data?.map((e) => e.name));
    setSeries([
      {
        name: "",
        data: seriesData,
      },
    ]);
  }, [analyticsDataApi?.res]);

  return (
    <Box
      sx={{
        mt: 5,
        backgroundColor: (theme) => theme.palette.background.secondary,
        height: "601px",
        p: 5,
        boxSizing: "border-box",
        borderRadius: "12px",
      }}
    >
      <Typography
        variant="bold"
        sx={{ color: (theme) => theme.palette.primary.main }}
      >
        Analytics
      </Typography>
      {false && (
        <Box
          sx={{
            mt: 3,
            backgroundColor: (theme) => theme.palette.background.secondary,
            ...flexCenter,
            flexDirection: "column",
            py: 8,
            gap: 5,
            borderRadius: "12px",
          }}
        >
          <img
            src={analyticsEmpty}
            style={{ height: "310px", width: "354px" }}
          />
          <Typography
            variant="bold"
            sx={{ color: (theme) => theme.palette.text.light }}
          >
            Analytics of student progress will be shown here
          </Typography>
        </Box>
      )}

      {analyticsDataApi?.loading && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />
        </Box>
      )}

      <Box sx={{ mt: 5 }}>
        <Box sx={{ ...dFlex, justifyContent: "flex-end" }}>
          <Select
            options={calculatedWeeks}
            value={selectedTimeFrame}
            onChange={handleChange}
            theme={reactSelectTheme}
            maxMenuHeight={150}
            styles={{
              control: (provided) => ({
                ...provided,
                width: "200px",
              }),
            }}
            placeHolder="Select Week"
          />
        </Box>

        <Chart
          options={option}
          series={series}
          type="bar"
          width={"98%"}
          height={350}
        />
      </Box>
    </Box>
  );
}
