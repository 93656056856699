import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FormTextField } from "../../../const/TextField";
import { DeleteIcon } from "../../../images/icons";
import ConfirmDeleteAccountModal from "./ConfirmDeleteAccountModal ";

export default function DeleteAccountModal() {
  const [open, setOpen] = useState(false);
  const [refundAmount, setRefundAmount] = useState(null);
  const params = useParams();
  // console.log({ params });
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteAccount = () => {
    toast("Account is deleted");
    handleClose();
  };
  return (
    <>
      <Button variant="primary" onClick={handleOpen}>
        <DeleteIcon /> Delete Account
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: (theme) => theme.palette.background.main,
            borderRadius: "10px",
            pb: 2,
          },
        }}
      >
        <Box sx={{ padding: "0px 5px" }}>
          {/* <form onSubmit={handleDeleteAdmission} autoComplete="off"> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Enter the refund amount below if you wish to refund.{" "}
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              <FormTextField
                value={refundAmount}
                fullWidth
                onChange={(e) => {
                  setRefundAmount(e.target.value);
                }}
                sx={{ mt: "20px", color: "#BFBFBF" }}
                placeholder="Refund amount (Optional)"
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <ConfirmDeleteAccountModal refundAmount={refundAmount} />
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
