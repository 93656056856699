/** Flexbox styles */

export const menuItemsCSS = {
  display: "flex",
  gap: "20px",
  // pl: "40px",
  flexDirection: "column",
  // justifyContent: "space-between",
};
export const flexSpaceBetween = {
  display: "flex",
  justifyContent: "space-between",
};

export const flexBetweenWithItemsCenter = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export const flexBetweenCenter = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export const flexCenter = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const justifyCenter = { display: "flex", justifyContent: "center" };
export const itemsCenter = {
  display: "flex",
  alignItems: { xs: "flex-start", md: "center" },
};

export const dFlex = {
  display: "flex",
  flexDirection: "row",
};

export const fixedBottom = {
  position: "absolute",
  bottom: 100,
  width: "100%",
};

export const displayOnDesktop = { display: { xs: "none", md: "block" } };

/* Footer Common Style */

export const sideNavbarLink = {
  cursor: "pointer",
  // width: "100%",
  display: "flex",
  alignItems: "center",
  gap: 1,
  px: 1,
};

export const loginButton = {
  width: "140px",
  height: "50px",
  fontSize: "20px",
  fontWeight: 700,
};

export const dashboardCard = {
  display: "flex",
  cursor: "pointer",
  height: "15vh",
  maxHeight: "111px",
  width: "35%",
  maxWidth: "389px",
  minWidth: "200px",
  borderRadius: "8px",
  background: (theme) => theme.palette.background.secondary,
  // border: "1px solid #BFBFBF",
};
export const textEllipses = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
