import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import {
  dFlex,
  flexBetweenWithItemsCenter,
  flexCenter,
} from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { checkSize, convertToBase64File } from "../../../const/constFunctions";

export default function AddFacilitator({ edit }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState({
    file: null,
    base64: null,
  });
  const handleImageChange = async (e) => {
    try {
      if (e?.target?.files.length > 0) {
        let file = e.target.files[0];
        if (checkSize(file) > 10) {
          e.target.value = "";
          toast.error("Please upload an image less than 10MB");
          return;
        }
        let base64 = convertToBase64File(file);
        base64 = await base64;
        setSelectedImage({ file, base64: base64 });
      } else
        setSelectedImage({
          file: null,
          base64: null,
        });
    } catch (err) {
      console.log(err);
      e.target.value = "";
      toast.error("Something went wrong, Please try again");
    }
  };
  const location = useLocation();
  const [batchDetail, setBatchDetails] = useState({
    name: "",
  });

  useEffect(() => {
    const { batchDetail } = location?.state ?? { name: "" };
    setBatchDetails(batchDetail);
  }, [location.state]);

  const onSubmit = async (data) => {
    try {
      if (loading) return;
      if (
        !data?.instructorName?.trim() ||
        !data?.email?.trim() ||
        !data?.phone?.trim()
      ) {
        toast.error("Fields cannot be empty");
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_SCHOOL_FACILITATOR_REGISTER;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...data,
          phone: `+${data?.phone}`,
          image: selectedImage?.base64,
        },
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      setLoading(false);
      navigate(-1);
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <Box>
      <NavBreadCrumbs
        pageTags={[
          "Facilitator",
          edit ? "Edit Facilitator" : "Add Facilitator",
        ]}
      />
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.secondary,
          borderRadius: "20px",
          minHeight: "70vh",
          p: 3,
        }}
      >
        <Box sx={{ ...flexBetweenWithItemsCenter }}>
          <Typography
            variant="medium"
            sx={{ color: (theme) => theme.palette.text.main }}
          ></Typography>
          <Button variant="primary" onClick={handleSubmit(onSubmit)}>
            Save
          </Button>
        </Box>
        <Box sx={{ ...flexCenter, flexDirection: "column" }}>
          <div style={{ position: "relative", display: "inline-block" }}>
            <Avatar
              src={selectedImage?.base64}
              sx={{
                height: "171px",
                width: "171px",
              }}
            />

            <IconButton
              sx={{
                position: "absolute",
                bottom: "5px",
                right: "5px",
                borderRadius: "50%",
                border: "5px solid #F3F5F5",
                backgroundColor: (theme) => theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.primary.main,
                },
              }}
              component="label"
            >
              {selectedImage.file ? (
                <EditIcon
                  fontSize="medium"
                  sx={{
                    color: (theme) => theme.palette.background.secondary,
                  }}
                />
              ) : (
                <AddIcon
                  fontSize="medium"
                  sx={{
                    color: (theme) => theme.palette.background.secondary,
                  }}
                />
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </IconButton>
          </div>
          <Box sx={{ width: "70%", my: 3 }}>
            <Box sx={{ mb: 4, ...dFlex, flexDirection: "column" }}>
              <Typography
                variant="bold2"
                sx={{
                  color: (theme) => theme.palette.text.main,
                  mb: 1,
                }}
              >
                Facilitator Name
              </Typography>
              <FormTextField
                fullWidth
                placeholder="John Doe"
                {...register("instructorName", {
                  required: "This field is mandatory",
                })}
                error={Boolean(errors?.instructorName)}
                helperText={errors?.instructorName?.message}
              />
            </Box>
            <Box sx={{ mb: 4, ...dFlex, flexDirection: "column" }}>
              <Typography
                variant="bold2"
                sx={{
                  color: (theme) => theme.palette.text.main,
                  mb: 1,
                }}
              >
                E-mail ID*
              </Typography>
              <FormTextField
                fullWidth
                placeholder="email@domain.com"
                inputProps={{
                  style: {
                    textTransform: "lowercase",
                  },
                }}
                {...register("email", {
                  required: "This field is mandatory",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid Email Address",
                  },
                })}
                error={Boolean(errors?.email)}
                helperText={errors?.email?.message}
              />
            </Box>
            <Box sx={{ mb: 4, ...dFlex, flexDirection: "column" }}>
              <Typography
                variant="bold2"
                sx={{
                  color: (theme) => theme.palette.text.main,
                  mb: 1,
                }}
              >
                Phone Number*
              </Typography>
              <FormTextField
                fullWidth
                placeholder="12123456789"
                type="number"
                inputProps={{ inputMode: "decimal" }}
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <Typography
                      position="start"
                      variant="bold2"
                      sx={{ color: (theme) => theme.palette.text.main }}
                    >
                      +
                    </Typography>
                  ),
                }}
                {...register("phone", {
                  required: "This field is mandatory",
                  pattern: {
                    value: /^\d+$/,
                    message: "Phone number must start with a plus sign (+)",
                  },
                })}
                error={Boolean(errors?.phone)}
                helperText={errors?.phone?.message}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
