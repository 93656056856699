import RestoreIcon from "@mui/icons-material/Restore";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import AceQuestionAnswerTab from "../../../Components/Facilitator/ManageStudent/RiskProflingAnswer/AceQuestionAnswerTab";
import SDQQuestionAnswerTab from "../../../Components/Facilitator/StudentProgress/SDQQuestionAnswerTab";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import SuspenseLoading from "../../../common/SuspenseLoading";
import { itemsCenter } from "../../../common/commonStyles";
import { RegisterIcon } from "../../../images/icons";
import { TabPanel } from "../../Admin/Dashboard/SchoolSubscribers";

export default function AnswerRiskProfiling({ from }) {
  const [value, setValue] = useState(1);
  const aceURL =
    process.env.REACT_APP_SUPERADMIN_RISK_PROFILING_QUESTION + "?type=ACE";
  const sdqPreURL =
    process.env.REACT_APP_SUPERADMIN_RISK_PROFILING_QUESTION + "?type=SDQPre";
  const sdqPOSTURL =
    process.env.REACT_APP_SUPERADMIN_RISK_PROFILING_QUESTION + "?type=SDQPost";
  const [loading, setLoading] = useState(false);
  const [aceData, fetchAceData] = useDataFetchHook(aceURL);
  const [sdqPreData, fetchSDQPreData] = useDataFetchHook(sdqPreURL);
  const [sdqPostData, fetchSDQPostData] = useDataFetchHook(sdqPOSTURL);

  const [studentId, setStudentId] = useState(null);
  // Event handler for tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const [aceAnswer, setAceAnswer] = useState({});
  const [sdqPreAnswer, setSdqPreAnswer] = useState({});
  useEffect(() => {
    const param = new URLSearchParams(window.location.search);
    // console.log({ studentId: param.get("studentId") });
    if (!param.get("studentId")) {
      toast.error("Invalid Attempt");
      if (from == "parent") {
        navigate("/parent/students");
      } else {
        navigate("/facilitator/manage-student", {
          replace: true,
        });
      }
      return;
    }
    setStudentId(param.get("studentId"));
  }, [location.state]);

  const handleSave = async () => {
    try {
      if (!studentId) {
        toast.error("Invalid Attempt");
        return;
      }
      let aceAnswerLen = Object.keys(aceAnswer).length;
      let sdqPreAnswerLen = Object.keys(sdqPreAnswer).length;
      let aceDataLen = aceData?.res?.question?.length;
      let sdqPreDataLen = sdqPreData?.res?.question?.length;
      if (aceAnswerLen !== aceDataLen) {
        toast.error("Please answer all ACE questions");
        return;
      }
      // if (sdqPreAnswerLen !== sdqPreDataLen) {
      //   toast.error("Please answer all SDQ Pre questions");
      //   return;
      // }

      const url =
        process.env.REACT_APP_FACILITATOR_RISK_PROFILING_QUESTIONS +
        `/${studentId}`;
      const aceConfig = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          record: aceAnswer,
          type: "ACE",
        },
      });
      const sdqPreConfig = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          record: sdqPreAnswer,
          type: "SDQPre",
        },
      });

      let aceRes = axios.request(aceConfig);
      let sdqPreRes = axios.request(sdqPreConfig);
      aceRes = await aceRes;
      sdqPreRes = await sdqPreRes;
      toast(sdqPreRes?.data?.message);
      handleSkip();
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  const handleSkip = () => {
    if (from == "parent") {
      navigate("/parent/students", {
        replace: true,
      });
    } else {
      navigate("/facilitator/manage-student", {
        replace: true,
      });
    }
  };

  return (
    <Box>
      <NavBreadCrumbs
        pageTags={["Manage Students", "Add Student", "Risk Profiling"]}
      />

      <Box sx={{ ...itemsCenter, justifyContent: "flex-end", gap: 2 }}>
        <Button onClick={handleSkip} variant="primary">
          <RestoreIcon sx={{ color: "#FFF", width: "18px" }} />
          Skip now and do it later
        </Button>
        <Button disabled={loading} onClick={handleSave} variant="primary">
          <RegisterIcon />
          Save and register
        </Button>
      </Box>

      <Box>
        <Box
          sx={{
            width: "100%",
            mt: 3,
            // position: "sticky",
            // top: 65,
            // zIndex: 5,
            // backgroundColor: "#eff7f6",
            borderBottom: `1px solid #BFBFBF`,
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            allowScrollButtonsMobile
            scrollButtons="auto"
          >
            <Tab
              sx={{
                textTransform: "none",
              }}
              label={
                <Typography variant={value == 1 ? "bold2" : "medium"}>
                  ACE Questions
                </Typography>
              }
              value={1}
            />
            <Tab
              sx={{
                textTransform: "none",
              }}
              label={
                <Typography variant={value == 2 ? "bold2" : "medium"}>
                  SDQ Pre Test
                </Typography>
              }
              value={2}
              disabled
            />
            <Tab
              sx={{
                textTransform: "none",
              }}
              disabled
              label={
                <Typography variant={value == 3 ? "bold2" : "medium"}>
                  SDQ Post Test
                </Typography>
              }
              value={3}
            />
          </Tabs>
        </Box>

        {/* Tab panels */}
        <TabPanel value={value} index={1}>
          <AceQuestionAnswerTab
            data={aceData?.res?.question ?? []}
            answers={aceAnswer}
            setAnswers={setAceAnswer}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SDQQuestionAnswerTab
            data={sdqPreData?.res?.question ?? []}
            answers={sdqPreAnswer}
            setAnswers={setSdqPreAnswer}
            type={"Pre"}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          {/* <SDQQuestionAnswerTab
            data={sdqPostData?.res?.question ?? []}
            type={"Post"}
          /> */}
        </TabPanel>
      </Box>
      <Box>
        <Typography variant="semibold" sx={{ color: "#FE891D" }}>
          *Highly Recommended
        </Typography>
      </Box>
      {(aceData?.loading || sdqPreData?.loading || sdqPostData?.loading) && (
        <SuspenseLoading />
      )}
    </Box>
  );
}
