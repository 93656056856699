import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import { itemsCenter } from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";

export default function RejectOfflineRequestModal({ data, fetchAgain }) {
  const [open, setOpen] = useState();
  const [reason, setReason] = useState("Rejected");
  const [loading, setLoading] = useState(false);
  const handleOpen = () => {
    // localStorage.clear();
    setOpen(true);
  };
  const handleClose = () => {
    // localStorage.clear();
    setOpen(false);
  };

  const handleReject = async () => {
    try {
      const url = process.env.REACT_APP_REGISTER_STATUS + "/offlineReject";
      setLoading(true);
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: { email: data?.id, paymentStatus: "rejected", reason: reason },
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      fetchAgain();
      toast(apiRes?.data?.message);
      handleClose();
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        sx={{
          textTransform: "none",
          color: "red",
        }}
        onClick={handleOpen}
      >
        Reject
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: "425px",
            borderRadius: "13px",
            backgroundColor: (theme) => theme.palette.background.main,
          },
        }}
      >
        <Box sx={{ pb: "16px" }}>
          {/* <form onSubmit={handleDeleteAdmission} autoComplete="off"> */}
          <DialogContent sx={{ mb: 1 }}>
            <DialogContentText id="alert-dialog-description">
              <Typography
                variant="regular"
                sx={{
                  color: (theme) => theme.palette.text.main,
                  mb: 2,
                }}
              >
                Are you sure you want to reject the request sent by{" "}
                <b>{data?.schoolName}</b>{" "}
              </Typography>
              <Box sx={{ mt: 2, ...itemsCenter, gap: 2 }}>
                <Typography
                  variant="regular"
                  sx={{
                    color: (theme) => theme.palette.text.main,
                  }}
                >
                  Remarks{" "}
                </Typography>
                <FormTextField
                  multiline
                  fullWidth
                  minRows={2}
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Close
            </Button>
            <Button disabled={loading} onClick={handleReject} variant="primary">
              Reject
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
