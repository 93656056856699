import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function useKeyboardShortcut(handleToggle, menuItems) {
  const navigate = useNavigate();
  const handleKeyPress = (e) => {
    if (e.altKey && e.shiftKey) {
      if (e.code.includes("Digit") || e.code.includes("Numpad")) {
        let num = e.code.split("");
        num = num[num.length - 1];
        if (menuItems.length >= +num) {
          navigate(menuItems?.[+num - 1]?.to);
        }
      }
      if (e.code == "KeyS") {
        handleToggle();
        // console.log("clicked");
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
}
