import { Box, MenuItem, Select, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { dFlex, itemsCenter } from "../../../common/commonStyles";
import { CustomDateTextField, FormTextField } from "../../../const/TextField";
import "./CertificateDetails.css";

let fonts = [
  "Courier",
  "Courier-Bold",
  "Courier-Oblique",
  "Courier-BoldOblique",
  "Helvetica",
  "Helvetica-Bold",
  "Helvetica-Oblique",
  "Helvetica-BoldOblique",
  "Times-Roman",
  "Times-Bold",
  "Times-Italic",
  "Times-BoldItalic",
];

/* 
StandardFonts {
  Courier = 'Courier',
  CourierBold = 'Courier-Bold',
  CourierOblique = 'Courier-Oblique',
  CourierBoldOblique = 'Courier-BoldOblique',
  Helvetica = 'Helvetica',
  HelveticaBold = 'Helvetica-Bold',
  HelveticaOblique = 'Helvetica-Oblique',
  HelveticaBoldOblique = 'Helvetica-BoldOblique',
  TimesRoman = 'Times-Roman',
  TimesRomanBold = 'Times-Bold',
  TimesRomanItalic = 'Times-Italic',
  TimesRomanBoldItalic = 'Times-BoldItalic',
  Symbol = 'Symbol',
  ZapfDingbats = 'ZapfDingbats',
} */

/* const handleImage = (e) => {
   // //console.log("aaa", e);
    let reader = new FileReader();

    let filenameType = e.target.value.split(".");
    let filetypedata = filenameType[filenameType.length - 1].toLowerCase();
    if (filetypedata == "png" || filetypedata == "jpeg" || filetypedata == "jpg") {
    } else {
      alert("only jpeg  , gif  ,png  file accepted");
      e.target.value = null;

      return;
    }
    reader.onload = () => {
      if (reader.result.length > 1000000) {
        // alert if size > 1 MB
        alert("Image Too large, Update and try again.");
        return;
      }
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        let height = this.height;
        let width = this.width;
        // //console.log(Number(width / height));
        // //console.log(Number(width / height) != 1.5);
        if (Number(width / height) <= 1.40||Number(width / height) >= 1.60) {
          alert(`Aspect Ratio must be 3:2. Update and try again.`);
          return false;
        }
        // alert("Uploaded image has valid Height and Width.");

        setSignData({ base64: reader.result, url: URL.createObjectURL(e.target.files[0]) });
        return true;
      };
    };
    reader.readAsDataURL(e.target.files[0]);
  }; */

/* const {
      tempUrl, //1st time pdf upload URL
      tempUser, // object named as userNameCoordinate
      tempCourse, // object named as institutionCoordinate
      tempDate, // object named as dateCoordinate
      tempInstitution, // object named as institution (to make)
      tempSign, //object named as signCoordinate
      userName, // state named as defName
      courseName, // state named as defcourse
      completeDate, // state named as defDate
      institutionName, // state named as definstitutaion (to make)
      base64Image, // state named as base64image
    } = req.body; */

function GenerateCertificate() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [previewData, setPreviewData] = useState({
    userName: "",
    courseName: "",
    institutionName: "",
    completeDate: "",
  });
  const [file, setFile] = useState(null);

  const [url, setUrl] = useState("");
  const [OGUrl, setOGUrl] = useState("");
  const [base64Image, setBase64Image] = useState("");

  const [userNameCoordinate, setUserNameCoordinate] = useState({
    y: 275,
    x: 395,
    color: "#358B8A",
    font: "Helvetica",
    fontsize: 16,
    bold: false,
    opacity: 0.8,
  });

  const [institutionCoordinate, setInstitutionCoordinate] = useState({
    y: 525,
    x: 425,
    color: "#358B8A",
    font: "Helvetica",
    fontsize: 16,
    bold: false,
    opacity: 0.8,
  });

  const [dateCoordinate, setDateCoordinate] = useState({
    y: 230,
    x: 296,
    color: "#358B8A",
    font: "Helvetica",
    fontsize: 16,
    bold: false,
    opacity: 0.8,
  });

  const [instructorCoordinate, setInstructorCoordinate] = useState({
    y: 0,
    x: 0,
    color: "#358B8A",
    font: "Helvetica",
    fontsize: 0,
    bold: false,
    opacity: 0.8,
  });

  const [signCoordinate, setSignCoordinate] = useState({
    y: 0,
    x: 0,
    width: 0,
    aspectRatio: true,
  });

  const handlePreviewDataInput = (e) => {
    let { name, value } = e.target;
    setPreviewData({
      ...previewData,
      [name]: value,
    });
  };

  // const handleFileChange = async (e) => {
  //   if (e?.target?.files.length > 0) setFile(e?.target?.files?.[0]);
  //   else {
  //     setFile(null);
  //     return;
  //   }
  //   const base64 = await convertToBase64File(e?.target?.files?.[0]);
  //   // console.log({ base64 });
  //   setBase64Image(base64);
  //   // const reader = new FileReader();
  //   // reader.onloadend = () => {
  //   //   setBase64Image(reader.result);
  //   //   ////console.log("setBase64Image done", reader.result);
  //   // };
  //   // reader.readAsDataURL(files[0]);
  // };

  const handleInputChange = (e, setState) => {
    const { name, value } = e.target;
    let parsedValue = value;
    console.log({ name, value });
    if (["y", "x", "fontsize", "width"].includes(name)) {
      parsedValue = +value;
    } else if (name === "bold") {
      parsedValue = value;
    }

    setState((prevState) => ({
      ...prevState,
      [name]: parsedValue,
    }));
  };
  const getUserData = (e) => {
    handleInputChange(e, setUserNameCoordinate);
  };

  const getCourseData = (e) => {
    handleInputChange(e, setInstitutionCoordinate);
  };

  const getDateData = (e) => {
    handleInputChange(e, setDateCoordinate);
  };

  // const getInstructor = (e) => {
  //   handleInputChange(e, setInstructorCoordinate);
  // };

  // const getSignData = (e) => {
  //   handleInputChange(e, setSignCoordinate);
  // };

  const previewCertificate = async () => {
    try {
      setLoading(true);
      const url =
        process.env.REACT_APP_CERTIFICATE_TEMPLATE + `/previewCertificate`;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...previewData,
          tempUrl: OGUrl,
          tempUser: userNameCoordinate,
          tempCourse: instructorCoordinate,
          tempDate: dateCoordinate,
          tempInstitution: institutionCoordinate,
          tempSign: signCoordinate,
          base64Image,
        },
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      setUrl(apiRes?.data?.link);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  const saveCertificate = async () => {
    try {
      setLoading(true);
      const url =
        process.env.REACT_APP_CERTIFICATE_TEMPLATE + `/saveCertificate`;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          tempUrl: OGUrl,
          user: userNameCoordinate,
          course: instructorCoordinate,
          date: dateCoordinate,
          institution: institutionCoordinate,
          sign: signCoordinate,
          templateName: `${Date.now()}`,
        },
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      navigate(-1);
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    const { pdfFileLink } = location?.state ?? null;
    if (!pdfFileLink) {
      toast.error("PDF file not found");
    }
    console.log({ pdfFileLink });
    setUrl(pdfFileLink);
    setOGUrl(pdfFileLink);
  }, [location?.state]);

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Certification", "Edit"]} />
      <Box>
        <Box>
          <Box sx={{ ...dFlex, columnGap: 5, rowGap: 3, flexWrap: "wrap" }}>
            <Box sx={{ ...dFlex, flexDirection: "column", gap: 1 }}>
              <Typography
                variant="bold2"
                sx={{ color: (theme) => theme.palette.text.main }}
              >
                Name of the Student
              </Typography>
              <FormTextField
                sx={{ width: "280px" }}
                placeholder="Student Name"
                name="userName"
                value={previewData?.userName}
                onChange={handlePreviewDataInput}
              />
            </Box>
            <Box sx={{ ...dFlex, flexDirection: "column", gap: 1 }}>
              <Typography
                variant="bold2"
                sx={{ color: (theme) => theme.palette.text.main }}
              >
                Institution
              </Typography>
              <FormTextField
                sx={{ width: "280px" }}
                placeholder="Institution Name"
                name="institutionName"
                value={previewData?.institutionName}
                onChange={handlePreviewDataInput}
              />
            </Box>
            <Box sx={{ ...dFlex, flexDirection: "column", gap: 1 }}>
              <Typography
                variant="bold2"
                sx={{ color: (theme) => theme.palette.text.main }}
              >
                Date
              </Typography>
              <CustomDateTextField
                // type="dateCoordinate"
                sx={{ width: "280px" }}
                placeholder="Date"
                name="completeDate"
                value={previewData?.completeDate}
                onChange={handlePreviewDataInput}
              />
            </Box>
            {/* <Box sx={{ ...dFlex, flexDirection: "column", gap: 1 }}>
              <Typography
                variant="bold2"
                sx={{ color: (theme) => theme.palette.text.main }}
              >
                Name of the Program
              </Typography>
              <FormTextField
                sx={{ width: "280px" }}
                placeholder="Date"
                name="courseName"
                value={previewData?.courseName}
                onChange={handlePreviewDataInput}
              />
            </Box>
            <Box sx={{ ...dFlex, flexDirection: "column", gap: 1 }}>
              <Typography
                variant="bold2"
                sx={{ color: (theme) => theme.palette.text.main }}
              >
                Signature
              </Typography>
              <Box sx={{ ...itemsCenter, gap: "10px" }}>
                <Box
                  component={"label"}
                  sx={{
                    ...flexCenter,
                    height: "35px",
                    width: "125px",
                    cursor: "pointer",
                    borderRadius: "4px",
                    color: (theme) => theme.palette.text.main,
                    backgroundColor: (theme) => theme.palette.text.lighter,
                    // "&:hover": {
                    //   backgroundColor: "#D1D0D0",
                    // },
                  }}
                >
                  Choose a file
                  <input
                    type="file"
                    accept="image/jpeg"
                    onChange={(e) => {
                      handleFileChange(e);
                    }}
                    style={{ display: "none" }}
                  />
                </Box>
                <Typography
                  variant="regular"
                  // contentEditable="true"
                  title={file ? file?.name : "No File Chosen"}
                  sx={{
                    color: (theme) => theme.palette.text.main,
                  }}
                >
                  {file ? file?.name : "No File Chosen"}
                </Typography>
              </Box>{" "}
            </Box> */}
          </Box>
        </Box>

        {/* Settings Started */}
        <Box
          sx={{
            my: 5,
            p: 3,
            borderRadius: "10px",
            backgroundColor: (theme) => theme.palette.background.secondary,
          }}
        >
          <Typography
            variant="bold"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            Settings
          </Typography>
          <Box sx={{ my: 3 }}>
            <Typography
              variant="semibold"
              sx={{ color: (theme) => theme.palette.text.main }}
            >
              Name of the Student
            </Typography>{" "}
            <Box sx={{ borderRadius: "7px", backgroundColor: "#F3F3F5" }}>
              <Box sx={{ ...itemsCenter, justifyContent: "space-between" }}>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Y:
                  </Typography>
                  <FormTextField
                    type="Number"
                    name="y"
                    placeholder=""
                    min="0"
                    value={userNameCoordinate.y}
                    onChange={getUserData}
                  />
                </Box>

                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    X:
                  </Typography>
                  <FormTextField
                    type="Number"
                    name="x"
                    placeholder=""
                    min="0"
                    value={userNameCoordinate.x}
                    onChange={getUserData}
                  />
                </Box>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Color:
                  </Typography>
                  <FormTextField
                    type="text"
                    name="color"
                    placeholder=""
                    value={userNameCoordinate.color}
                    onChange={getUserData}
                  />
                </Box>
              </Box>
              <Box sx={{ ...itemsCenter, justifyContent: "space-between" }}>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Font:
                  </Typography>

                  <Select
                    name="font"
                    value={userNameCoordinate.font}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.background.secondary,
                      height: "38px",
                      width: "230px",
                    }}
                    MenuProps={{
                      sx: {
                        maxHeight: "250px",
                      },
                    }}
                    id="MenuItem"
                    onChange={getUserData}
                  >
                    {fonts.map((e) => (
                      <MenuItem value={e}>{e}</MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Font_Size:
                  </Typography>
                  <FormTextField
                    type="Number"
                    name="fontsize"
                    placeholder=""
                    min="0"
                    value={userNameCoordinate.fontsize}
                    onChange={getUserData}
                  />
                </Box>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Bold:
                  </Typography>
                  <Select
                    name="bold"
                    value={userNameCoordinate.bold}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.background.secondary,
                      height: "38px",
                      width: "230px",
                    }}
                    id="MenuItem"
                    onChange={getUserData}
                  >
                    <MenuItem value={false}>False</MenuItem>
                    <MenuItem value={true}>True</MenuItem>
                  </Select>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ my: 3 }}>
            <Typography
              variant="semibold"
              sx={{ color: (theme) => theme.palette.text.main }}
            >
              Institution
            </Typography>{" "}
            <Box sx={{ borderRadius: "7px", backgroundColor: "#F3F3F5" }}>
              <Box sx={{ ...itemsCenter, justifyContent: "space-between" }}>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Y:
                  </Typography>
                  <FormTextField
                    type="Number"
                    name="y"
                    placeholder=""
                    min="0"
                    value={institutionCoordinate.y}
                    onChange={getCourseData}
                  />
                </Box>

                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    X:
                  </Typography>
                  <FormTextField
                    type="Number"
                    name="x"
                    placeholder=""
                    min="0"
                    value={institutionCoordinate.x}
                    onChange={getCourseData}
                  />
                </Box>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Color:
                  </Typography>
                  <FormTextField
                    type="text"
                    name="color"
                    placeholder=""
                    value={institutionCoordinate.color}
                    onChange={getCourseData}
                  />
                </Box>
              </Box>
              <Box sx={{ ...itemsCenter, justifyContent: "space-between" }}>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Font:
                  </Typography>
                  <Select
                    name="font"
                    value={institutionCoordinate.font}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.background.secondary,
                      height: "38px",
                      width: "230px",
                    }}
                    MenuProps={{
                      sx: {
                        maxHeight: "250px",
                      },
                    }}
                    id="MenuItem"
                    onChange={getCourseData}
                  >
                    {fonts.map((e) => (
                      <MenuItem value={e}>{e}</MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Font_Size:
                  </Typography>
                  <FormTextField
                    type="Number"
                    name="fontsize"
                    placeholder=""
                    min="0"
                    value={institutionCoordinate.fontsize}
                    onChange={getCourseData}
                  />
                </Box>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Bold:
                  </Typography>
                  <Select
                    name="bold"
                    value={institutionCoordinate.bold}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.background.secondary,
                      height: "38px",
                      width: "230px",
                    }}
                    id="MenuItem"
                    onChange={getCourseData}
                  >
                    <MenuItem
                      //selected={institutionCoordinate.bold == false}
                      value={false}
                    >
                      False
                    </MenuItem>
                    <MenuItem
                      //selected={institutionCoordinate.bold == true}
                      value={true}
                    >
                      True
                    </MenuItem>
                  </Select>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ my: 3 }}>
            <Typography
              variant="semibold"
              sx={{ color: (theme) => theme.palette.text.main }}
            >
              Date
            </Typography>{" "}
            <Box sx={{ borderRadius: "7px", backgroundColor: "#F3F3F5" }}>
              <Box sx={{ ...itemsCenter, justifyContent: "space-between" }}>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Y:
                  </Typography>
                  <FormTextField
                    type="Number"
                    name="y"
                    placeholder=""
                    min="0"
                    value={dateCoordinate.y}
                    onChange={getDateData}
                  />
                </Box>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    X:
                  </Typography>
                  <FormTextField
                    type="Number"
                    name="x"
                    placeholder=""
                    min="0"
                    value={dateCoordinate.x}
                    onChange={getDateData}
                  />
                </Box>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Color:
                  </Typography>
                  <FormTextField
                    type="text"
                    name="color"
                    placeholder=""
                    value={dateCoordinate.color}
                    onChange={getDateData}
                  />
                </Box>
              </Box>
              <Box sx={{ ...itemsCenter, justifyContent: "space-between" }}>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Font:
                  </Typography>
                  <Select
                    name="font"
                    value={dateCoordinate.font}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.background.secondary,
                      height: "38px",
                      width: "230px",
                    }}
                    MenuProps={{
                      sx: {
                        maxHeight: "250px",
                      },
                    }}
                    id="MenuItem"
                    onChange={getDateData}
                  >
                    {fonts.map((e) => (
                      <MenuItem value={e}>{e}</MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Font_Size:
                  </Typography>
                  <FormTextField
                    type="Number"
                    name="fontsize"
                    placeholder=""
                    min="0"
                    value={dateCoordinate.fontsize}
                    onChange={getDateData}
                  />
                </Box>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Bold:
                  </Typography>
                  <Select
                    name="bold"
                    value={dateCoordinate.bold}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.background.secondary,
                      height: "38px",
                      width: "230px",
                    }}
                    id="MenuItem"
                    onChange={getDateData}
                  >
                    <MenuItem
                      //selected={dateCoordinate.bold == false}
                      value={false}
                    >
                      False
                    </MenuItem>
                    <MenuItem
                      //selected={dateCoordinate.bold == true}
                      value={true}
                    >
                      True
                    </MenuItem>
                  </Select>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* <Box sx={{ my: 3 }}>
            <Typography
              variant="semibold"
              sx={{ color: (theme) => theme.palette.text.main }}
            >
              Name of the Program
            </Typography>{" "}
            <Box sx={{ borderRadius: "7px", backgroundColor: "#F3F3F5" }}>
              <Box sx={{ ...itemsCenter, justifyContent: "space-between" }}>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Y:
                  </Typography>
                  <FormTextField
                    type="Number"
                    name="y"
                    placeholder=""
                    min="0"
                    value={instructorCoordinate.y}
                    onChange={getInstructor}
                  />
                </Box>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    X:
                  </Typography>
                  <FormTextField
                    type="Number"
                    name="x"
                    placeholder=""
                    min="0"
                    value={instructorCoordinate.x}
                    onChange={getInstructor}
                  />
                </Box>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Color:
                  </Typography>
                  <FormTextField
                    type="text"
                    name="color"
                    placeholder=""
                    value={instructorCoordinate.color}
                    onChange={getInstructor}
                  />
                </Box>
              </Box>
              <Box sx={{ ...itemsCenter, justifyContent: "space-between" }}>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Font:
                  </Typography>
                  <FormTextField
                    type="text"
                    name="font"
                    placeholder=""
                    value={instructorCoordinate.font}
                    onChange={getInstructor}
                  />
                </Box>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Font_Size:
                  </Typography>
                  <FormTextField
                    type="Number"
                    name="fontsize"
                    placeholder=""
                    min="0"
                    value={instructorCoordinate.fontsize}
                    onChange={getInstructor}
                  />
                </Box>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Bold:
                  </Typography>
                  <Select
                    name="bold"
                    value={dateCoordinate.bold}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.background.secondary,
                      height: "38px",
                      width: "230px",
                    }}
                    id="MenuItem"
                    onChange={getInstructor}
                  >
                    <MenuItem
                      //selected={dateCoordinate.bold == false}
                      value={false}
                    >
                      False
                    </MenuItem>
                    <MenuItem
                      //selected={dateCoordinate.bold == true}
                      value={true}
                    >
                      True
                    </MenuItem>
                  </Select>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ my: 3 }}>
            <Typography
              variant="semibold"
              sx={{ color: (theme) => theme.palette.text.main }}
            >
              Signature
            </Typography>{" "}
            <Box sx={{ borderRadius: "7px", backgroundColor: "#F3F3F5" }}>
              <Box sx={{ ...itemsCenter, justifyContent: "space-between" }}>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Y:
                  </Typography>
                  <FormTextField
                    type="Number"
                    name="y"
                    placeholder=""
                    min="0"
                    value={signCoordinate.y}
                    onChange={getSignData}
                  />
                </Box>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    X:
                  </Typography>
                  <FormTextField
                    type="Number"
                    name="x"
                    placeholder=""
                    min="0"
                    value={signCoordinate.x}
                    onChange={getSignData}
                  />
                </Box>
                <Box sx={{ margin: "10px 20px", ...itemsCenter, gap: 2 }}>
                  <Typography
                    variant="medium2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      width: "80px",
                    }}
                  >
                    Width:
                  </Typography>
                  <FormTextField
                    type="Number"
                    name="width"
                    placeholder=""
                    min="0"
                    value={signCoordinate.width}
                    onChange={getSignData}
                  />
                </Box>
              </Box>
            </Box>
          </Box> */}
          <Box sx={{ ...itemsCenter, justifyContent: "flex-end" }}>
            <Button
              disabled={loading}
              variant="primary"
              onClick={previewCertificate}
            >
              Preview
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            my: 5,
            p: 3,
            borderRadius: "10px",
            backgroundColor: (theme) => theme.palette.background.secondary,
          }}
        >
          <Typography
            variant="bold"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            Preview
          </Typography>{" "}
          <Box sx={{ my: 3 }}>
            <embed
              type=""
              // src="https://media.sproutsocial.com/uploads/2017/02/10x-featured-social-media-image-size.png"
              src={url}
              width="100%"
              height="562"
            />
          </Box>
          <Box sx={{ ...itemsCenter, justifyContent: "flex-end" }}>
            <Button
              disabled={loading}
              variant="primary"
              onClick={saveCertificate}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default GenerateCertificate;
