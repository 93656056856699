import styled from "@emotion/styled";
import { Skeleton, TableBody, TableCell, TableRow } from "@mui/material";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: "none",
  textTransform: "capitalize",
}));
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#f0eded",
  },
}));

export function TableRowSkeletonComponent({ column = 5, row = 3 }) {
  return (
    <TableBody>
      {new Array(row).fill(1).map((e) => (
        <TableRow sx={{ border: "none" }}>
          {new Array(column).fill(1).map((e) => (
            <TableCell sx={{ border: "none" }}>
              <Skeleton
                animation="wave"
                type="text"
                sx={{ fontSize: "24px", transform: "none" }}
              />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
}
