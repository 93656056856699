import { Box, Button, Dialog, DialogActions, Typography } from "@mui/material";
import React, { useState } from "react";
import { itemsCenter } from "../../../../../common/commonStyles";
import { SvgVector } from "../../../../../images/icons";

export default function SentenceModal({ data, index }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box onClick={handleClickOpen}>
        <Typography
          sx={{
            color: (theme) => theme.palette.text.main,
            px: 1,
            cursor: "pointer",
            transition: "all 0.2s ease",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.background.main,
            },
          }}
        >
          {data?.questionName}{" "}
        </Typography>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "#F3F5F5",
            borderRadius: "14px",
            p: "24px 12px 12px 24px",
            maxWidth: "100%",
            width: "60%",
          },
        }}
      >
        <Typography
          variant="medium"
          sx={{
            color: (theme) => theme.palette.text.main,
          }}
        >
          {index + 1}. {data?.question}
        </Typography>
        <Box>
          <Box
            sx={{
              mt: 2,
            }}
          >
            {data?.stdAnswer?.map((e, i) => (
              <Box
                sx={{
                  p: "5px 10px",
                  my: 2,
                  background: (theme) => theme.palette.primary.main,
                }}
              >
                <Box
                  sx={{
                    color: "#FFF",
                    gap: 1,
                    py: 0.5,
                    ...itemsCenter,
                    // boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.12)",
                  }}
                >
                  <SvgVector />
                  {e.text}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>

        <DialogActions sx={{ pt: 1 }}>
          <Button
            sx={{ height: "32px", textTransform: "none" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
