import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { DeleteIcon } from "../../../images/icons";

export default function CertificateDeleteModal({
  handleDeleteCertificateTemplate,
  selectedImage,
  e,
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    handleDeleteCertificateTemplate(e?.id);
    setOpen(false);
  };
  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          transition: "all 0.5s ease",
          backgroundColor: (theme) => theme.palette.primary.main,
          "&:hover": {
            backgroundColor: (theme) => theme.palette.primary.main,
          },
          "&:disabled": {
            backgroundColor: (theme) => theme.palette.primary.main,
            opacity: 0.5,
          },
        }}
        disabled={selectedImage == e?.id}
      >
        <DeleteIcon height={"17px"} />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <Box sx={{ width: "470px", padding: "12px 5px" }}>
          {/* <form onSubmit={handleDeleteAdmission} autoComplete="off"> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography
                variant="regular"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Are you sure you want to delete this certification template?{" "}
              </Typography>
              <br />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="primary-medium">
              Delete
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
