import styled from "@emotion/styled";
import { TextField } from "@mui/material";

export const FormTextField = styled(TextField)(({ theme }) => ({
  borderRadius: "7px",
  backgroundColor: theme.palette.background.secondary,
  input: {
    // textTransform: "capitalize",
    height: "5px",
    borderRadius: "7px",
    // margin: "2px 2px",
    "&::placeholder": {
      fontWeight: 500,
      color: "#959595",
    },
    color: "#5C5C5C",
  },
  //   "& .MuiOutlinedInput-root": {
  //     borderRadius: "7px",
  //     "&:hover fieldset": {
  //       borderColor: "#714AED",
  //     },
  //     "&.Mui-focused fieldset": {
  //       borderColor: "#714AED",
  //     },
  //   },
}));

export const CustomDateTextField = (props) => {
  return (
    <TextField
      InputProps={{
        sx: {
          background: "#FFF",
          color: "#959595",
          height: "38px",
          fontSize: "14px",
          borderRadius: "4px",
        },
      }}
      sx={{
        background: "#FFF",
        height: "38px",
        borderRadius: "4px",
      }}
      autoComplete="off"
      type="date"
      {...props}
    />
  );
};
export const SearchTextField = styled(TextField)(({ theme }) => ({
  borderRadius: "63px",
  backgroundColor: theme.palette.background.secondary,
  input: {
    height: "5px",
    // margin: "2px 2px",
    "&::placeholder": {
      color: "#BFBFBF",
    },
    color: "#959595",
  },
  ".MuiOutlinedInput-root": {
    borderRadius: "63px",
    // "&:hover fieldset": {
    //   borderColor: "#714AED",
    // },
    // "&.Mui-focused fieldset": {
    //   borderColor: "#714AED",
    // },
  },
}));
