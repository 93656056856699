import { Box, Typography } from "@mui/material";
import React from "react";
import { MedzGymIcon, ModuleIcon } from "../../../../images/icons";
import {
  flexBetweenWithItemsCenter,
  itemsCenter,
} from "../../../../common/commonStyles";
import AddCategoryModal from "./AddCategoryModal";
import CategoryDeleteModal from "./CategoryDeleteModal";
import { useNavigate } from "react-router-dom";
export default function CategoryCard({ data, index, fetchAgain }) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.12)",
        borderRadius: "12px",
        backgroundColor: (theme) => theme.palette.background.secondary,
        height: "89px",
        px: 5,
        my: 2,
        ...flexBetweenWithItemsCenter,
      }}
    >
      <Box
        sx={{
          ...itemsCenter,
          gap: 2,
          cursor: "pointer",
        }}
        onClick={() => {
          navigate(`${data?.id}`, {
            state: {
              data,
            },
          });
          // toast.error(JSON.stringify(data));
        }}
      >
        <MedzGymIcon />
        <Box sx={{ ...itemsCenter, gap: 0.5 }}>
          <Typography
            variant="semibold"
            sx={{
              color: (theme) => theme.palette.text.main,
            }}
          >
            {data.name}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ borderLeft: "1px solid #BFBFBF", pl: 2 }}>
        <AddCategoryModal edit={true} data={data} fetchAgain={fetchAgain} />
        <CategoryDeleteModal data={data} fetchAgain={fetchAgain} />
      </Box>
    </Box>
  );
}
