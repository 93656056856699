import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { dFlex, flexBetweenCenter } from "../../../../common/commonStyles";

export default function SDQQuestionAnswering({
  index,
  data,
  answers,
  handleAnswer,
}) {
  return (
    <Box sx={{ my: 4 }}>
      <Box
        sx={{ ...dFlex, justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography
          variant="medium"
          sx={{ color: (theme) => theme.palette.text.main, flex: "98%" }}
        >
          {index + 1}. {data?.question ?? "NA"}
        </Typography>
      </Box>
      <Box sx={{ ...flexBetweenCenter, px: 3, mt: 1 }}>
        <RadioGroup
          value={answers?.[data?.id]}
          onChange={(e) => {
            handleAnswer(data?.id, +e?.target?.value);
          }}
          row
          name="position"
          defaultValue="top"
        >
          {data?.option?.map((e, i) => (
            <FormControlLabel
              value={i}
              control={<Radio />}
              label={
                <Typography
                  variant="medium"
                  sx={{
                    color: (theme) => theme.palette.text.main,
                    textTransform: "capitalize",
                  }}
                >
                  {e}
                </Typography>
              }
              labelPlacement="bottom"
            />
          ))}
        </RadioGroup>
      </Box>
    </Box>
  );
}
