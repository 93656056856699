import { Box } from "@mui/material";
import React from "react";
import NewSubscriberTable from "../../../Components/Admin/Dashboard/NewSubscriberTable";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";

// Function to render individual tab panels

export default function NewSubscriber() {
  return (
    <Box>
      {/* Navigation breadcrumbs */}
      <NavBreadCrumbs pageTags={["Dashboard", "New Subscribers"]} />
      <NewSubscriberTable />
    </Box>
  );
}
