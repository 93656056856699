import { Box, Typography } from "@mui/material";
import React from "react";
import { dFlex } from "../../../../common/commonStyles";
import { FormTextField } from "../../../../const/TextField";

export default function ModuleCompleteSentance({ data, index }) {
  return (
    <>
      <Typography
        sx={{
          color: (theme) => theme.palette.text.main,
          mb: 1,
        }}
      >
        {index + 1}. {data?.question}
      </Typography>
      <Box
        sx={{
          px: "15px",
          flex: "80%",
          ...dFlex,
          // boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.12)",
        }}
      >
        <Box
          sx={{
            p: "15px",
            boxSizing: "border-box",
            height: "107px",
            // width: "80%",
            backgroundColor: "#E3EFEF",
            border: "1px solid #BFBFBF",
            borderRadius: "8px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: (theme) => theme.palette.text.main,
            }}
          >
            Type Answer
          </Typography>
          <FormTextField
            value={data?.data?.options[data?.stdAnswer]}
            disabled
            sx={{
              mt: 2,
            }}
          />
        </Box>
      </Box>
    </>
  );
}
