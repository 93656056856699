import { PG_URL } from "../Apis/constVariables";

/**
 *
 * @param {email} customer_email
 * @param {phone} customer_phone (optional)
 * @param {amount} amount
 */

const startPayment = async (
  customer_email,
  customer_phone,
  otherData,
  amount,
  redirect = "/login",
  payCurrency,
  customer_name
) => {
  try {
    console.log({
      customer_email,
      customer_phone,
      otherData,
      amount,
    });
    console.log("Starting transaction");
    let environment = true ? "sandbox" : "live";
    let accountNumber = true ? "1234567890" : `6181177506`;
    let country_code = "JM";
    // let currency = "JMD"; // JMD, TTD, USD
    let currency = payCurrency; // JMD, TTD, USD
    let avs = 1; // this field is for auto-filling details (email, ...etc)
    let data = otherData; //this is optional parameter that can / cannot be present in success response from WiPay
    let fee_structure = "merchant_absorb"; // customer_pay, merchant_absorb, split
    let method = "credit_card";
    let order_id = null; //this is the order id generated from our system
    let origin = "UNUH_WEB_PORTAL"; //need to see what this is
    let response_url = window.location.origin + redirect; // the final webpage where we handle response from WiPay
    let total = Number(amount).toFixed(2); // this is the total amount that we have to collect excluding the WiPay transaction charges, always 1.00 format
    let email = customer_email; // to get automated response from WiPay
    let phone = customer_phone; // customer phone number (optional)
    let name = customer_name ?? "";
    // console.log(PG_URL);

    var headers = new Headers();
    headers.append("Accept", "application/json");
    // headers.append("Accept", "*/*");
    var parameters = new URLSearchParams();
    parameters.append("account_number", accountNumber);
    parameters.append("avs", avs);
    parameters.append("country_code", country_code);
    parameters.append("currency", currency);
    parameters.append("data", JSON.stringify(data));
    parameters.append("environment", environment);
    parameters.append("fee_structure", fee_structure);
    parameters.append("method", method);
    parameters.append("order_id", order_id);
    parameters.append("origin", origin);
    parameters.append("email", email);
    parameters.append("phone", phone);
    parameters.append("response_url", response_url);
    parameters.append("total", total);
    parameters.append("name", name);
    var options = {
      method: "POST",
      headers: headers,
      body: parameters,
      redirect: "follow",
    };
    return fetch(PG_URL, options)
      .then((response) => {
        console.log({ response });
        // window.location.href = response.url;
        // if (response.status !== 200) {
        //   throw new Error("Something went wrong");
        // }
        return response.json();
      })
      .then((result) => {
        // result = JSON.parse(result);
        console.log({ result });
        // window.open(
        //   result.url,
        //   "",
        //   "toolbars=no,resizable=0,width=900,height=700,left=200,top=200"
        // );
        window.location.href = result.url;
      })
      .catch((err) => console.log(err));
  } catch (error) {
    console.log(error);
  }
};

const handlePaymentFailure = async (details) => {
  try {
    console.log(`Payment Failure`);
  } catch (error) {
    console.log(error);
  }
};

const handlePaymentSuccess = async (details) => {
  try {
    console.log(`Payment Success`);
  } catch (error) {
    console.log(error);
  }
};

export { handlePaymentFailure, handlePaymentSuccess, startPayment };
