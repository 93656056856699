import { Box } from "@mui/system";
import React from "react";
import AddFAQModal from "../../../Components/Admin/FAQ/AddFAQModal";
import FAQQuestionComponent from "../../../Components/Admin/FAQ/FAQQuestionComponent";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";

import { CircularProgress, Typography } from "@mui/material";
import { flexCenter } from "../../../common/commonStyles";
import { emptyDataSA } from "../../../const/imageUrl";

export default function FAQ() {
  const url = process.env.REACT_APP_FAQ;
  const [apiCall, fetchAgain] = useDataFetchHook(url);

  return (
    <Box>
      <NavBreadCrumbs pageTags={["FAQ"]} />
      <AddFAQModal fetchAgain={fetchAgain} />
      {apiCall?.loading && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />
        </Box>
      )}
      {/* {apiCall?.error && (
        <Box sx={{ ...flexCenter, flexDirection: "column", mt: 20 }}>
          {apiCall?.errorMessage ?? "Something went wrong"}
          <Button variant="primary-small" onClick={fetchAgain}>
            Retry
          </Button>
        </Box>
      )} */}
      {!apiCall.loading && apiCall?.res?.question.length < 0 && (
        <Box
          sx={{
            mt: 3,
            backgroundColor: (theme) => theme.palette.background.secondary,
            ...flexCenter,
            flexDirection: "column",
            py: 8,
            gap: 5,
            borderRadius: "12px",
          }}
        >
          <img
            alt="empty data"
            src={emptyDataSA}
            style={{ height: "254px", width: "345px" }}
          />
          <Typography
            variant="bold"
            sx={{ color: (theme) => theme.palette.text.light }}
          >
            You have not added any questions
          </Typography>
          <AddFAQModal fetchAgain={fetchAgain} />
        </Box>
      )}
      <Box>
        {apiCall?.res?.question?.map((e) => (
          <FAQQuestionComponent key={e?.id} data={e} fetchAgain={fetchAgain} />
        ))}
      </Box>
    </Box>
  );
}
