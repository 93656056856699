import { Box, Button, IconButton, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import {
  dFlex,
  flexCenter,
  itemsCenter,
  textEllipses,
} from "../../../common/commonStyles";
import { checkSize, convertToBase64File } from "../../../const/constFunctions";
import { AttachmentIcon, DeleteIcon } from "../../../images/icons";
import { validateBasicQuestionForm } from "../CreateQuestion";
import "../styles/createQuiz.css";

const DragAndDropCategory = ({
  questionFormData,
  fetchQuizQuestionsAgain,
  toggleQuestionForm,
}) => {
  const [item1, setItem1] = useState({
    file: null,
    base64: null,
  });
  const [item2, setItem2] = useState({
    file: null,
    base64: null,
  });
  const [item1Answers, setItem1Answers] = useState([]);
  const [item2Answers, setItem2Answers] = useState([]);
  const [loading, setLoading] = useState(false);
  function submit(e) {
    e.preventDefault();
  }

  const handleImageQuestionChange = async (e, state) => {
    try {
      if (e?.target?.files.length > 0) {
        let file = e.target.files[0];
        if (!file.type.includes("image")) {
          e.target.value = "";
          toast.error("Please upload an image file");
          return;
        }
        if (checkSize(file) > 10) {
          e.target.value = "";
          toast.error("Please upload an image less than 10MB");
          return;
        }
        const base64 = await convertToBase64File(file);
        state({
          file,
          base64,
        });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleImageAnswerChange = async (e, state, prev) => {
    try {
      if (!e?.target?.files?.length > 0 && !e.target.files) {
        e.target.value = null;
        state([]);
        return;
      }
      let files = [...e?.target?.files]
        ?.filter((e) => {
          if (!e?.type.includes("image")) {
            toast.error("Please select image file only");
          }
          return e?.type.includes("image");
        })
        ?.filter((e) => {
          let fileSize = checkSize(e);
          if (fileSize > 10) {
            toast.error(`${e.name} was too large`);
            toast.error(`Please Upload files less than 10mb`);
          }
          return fileSize < 10;
        });
      let arr = [...files].map(async (e) => {
        return {
          file: e,
          base64: await convertToBase64File(e),
        };
      });
      arr = await Promise.all(arr);
      arr = [...prev, ...arr];
      if (arr.length > 5) {
        toast.error("Maximum 5 files allowed");
      }
      state(arr.slice(0, 5));
      console.log({ arr });
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemove = (arr, id, state) => {
    let tempArr = [...arr];
    tempArr = tempArr.filter((_, i) => {
      return i !== id;
    });
    state(tempArr);
  };

  const addData = async () => {
    try {
      const formValidator = validateBasicQuestionForm(questionFormData);
      if (formValidator?.error) {
        toast.error(formValidator.message);
        return;
      }
      let nsn = questionFormData.questionName.replaceAll(" ", "");
      let dat = /\//i.test(nsn);
      if (!dat) {
      } else {
        toast.error("You can not use any (/) characters");
        return;
      }

      let err = false;
      console.log({ item1Answers });
      console.log({ item2Answers });
      item1Answers?.forEach((e) => {
        if (!e?.base64?.trim()) {
          err = true;
        }
      });
      item2Answers?.forEach((e) => {
        if (!e?.base64?.trim()) {
          err = true;
        }
      });
      if (
        err ||
        !item1?.base64 ||
        !item2?.base64 ||
        item1Answers?.length == 0 ||
        item2Answers?.length == 0
      ) {
        toast.error("Fields cannot be empty");
      }

      setLoading(true);
      const url = process.env.REACT_APP_QUESTION;
      const formData = new FormData();

      // Append the fields to the formData object
      formData.append("item1", item1?.base64);
      formData.append("item2", item2?.base64);
      if (item1Answers) {
        item1Answers.forEach((e) =>
          formData.append("item1Answers[]", e.base64)
        );
      }
      if (item2Answers) {
        item2Answers.forEach((e) =>
          formData.append("item2Answers[]", e.base64)
        );
      }

      // Add other fields from the questionFormData (if any)
      for (const key in questionFormData) {
        if (questionFormData.hasOwnProperty(key)) {
          formData.append(key, questionFormData[key]);
        }
      }

      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...questionFormData,
          data: {
            item1: item1?.base64,
            item2: item2?.base64,
            item1Answers: item1Answers?.map((e) => e.base64),
            item2Answers: item2Answers?.map((e) => e.base64),
          },
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchQuizQuestionsAgain();
      toggleQuestionForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <section className="quizForm-container">
      <form onSubmit={submit}>
        <Box className="quizForm-mainForm-infoRow">
          <Typography
            sx={{
              color: (theme) => theme.palette.text.dark,
              width: "5vw",
            }}
          >
            Item 1 :
          </Typography>
          <Box sx={{ ...itemsCenter, gap: "10px" }}>
            <Box
              component={"label"}
              sx={{
                ...flexCenter,
                height: "35px",
                width: "125px",
                cursor: "pointer",
                borderRadius: "4px",
                ml: 2,
                color: (theme) => theme.palette.text.main,
                backgroundColor: (theme) => theme.palette.text.lighter,
                // "&:hover": {
                //   backgroundColor: "#D1D0D0",
                // },
              }}
            >
              Choose a file
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageQuestionChange(e, setItem1)}
                style={{ display: "none" }}
              />
            </Box>
            <Typography
              variant="regular"
              // contentEditable="true"
              title={item1?.file ? item1?.file?.name : "No File Chosen"}
              sx={{
                color: (theme) => theme.palette.text.main,
                maxWidth: "280px",
                ...textEllipses,
              }}
            >
              {item1?.file ? item1?.file?.name : "No File Chosen"}
            </Typography>
          </Box>
          <Box sx={{ ml: "auto", mr: "30%" }}>
            <Box>
              <Button
                component="label"
                sx={{
                  ...itemsCenter,
                  gap: 1,
                  borderRadius: "15px",
                  textTransform: "none",
                  width: "max-content",
                  fontWeight: 600,
                  p: "5px 19px",
                  border: (theme) => `1px solid ${theme.palette.primary.main}`,
                }}
              >
                <AttachmentIcon fill={"#358B8A"} />
                Attach Answers
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={(e) =>
                    handleImageAnswerChange(e, setItem1Answers, item1Answers)
                  }
                  style={{ display: "none" }}
                />
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            ...dFlex,
            alignItems: "flex-end",
            mr: "14%",
            flexDirection: "column",
          }}
        >
          {item1Answers.map((e, i) => (
            <Box key={i} sx={{ ...itemsCenter, gap: 1, my: 1 }}>
              <AttachmentIcon />
              <Typography
                title={`${e?.file?.name} (${checkSize(e?.file)}mb)`}
                sx={{
                  width: "280px",
                  ...textEllipses,
                }}
              >
                {e?.file?.name} ({checkSize(e?.file)}mb){" "}
              </Typography>
              <IconButton
                onClick={() => {
                  handleRemove(item1Answers, i, setItem1Answers);
                }}
                sx={{ p: 1, border: "1px solid #358b8a" }}
              >
                <DeleteIcon height={"18px"} fill={"#358b8a"} />
              </IconButton>
            </Box>
          ))}
        </Box>
        <Box className="quizForm-mainForm-infoRow">
          <Typography
            sx={{
              color: (theme) => theme.palette.text.dark,
              width: "5vw",
            }}
          >
            Item 2 :
          </Typography>
          <Box sx={{ ...itemsCenter, gap: "10px" }}>
            <Box
              component={"label"}
              sx={{
                ...flexCenter,
                height: "35px",
                width: "125px",
                cursor: "pointer",
                borderRadius: "4px",
                ml: 2,
                color: (theme) => theme.palette.text.main,
                backgroundColor: (theme) => theme.palette.text.lighter,
                // "&:hover": {
                //   backgroundColor: "#D1D0D0",
                // },
              }}
            >
              Choose a file
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageQuestionChange(e, setItem2)}
                style={{ display: "none" }}
              />
            </Box>
            <Typography
              variant="regular"
              // contentEditable="true"
              title={item2?.file ? item2?.file?.name : "No File Chosen"}
              sx={{
                color: (theme) => theme.palette.text.main,
                maxWidth: "280px",
                ...textEllipses,
              }}
            >
              {item2?.file ? item2?.file?.name : "No File Chosen"}
            </Typography>
          </Box>
          <Box sx={{ ml: "auto", mr: "30%" }}>
            <Box>
              <Button
                component="label"
                sx={{
                  ...itemsCenter,
                  gap: 1,
                  borderRadius: "15px",
                  textTransform: "none",
                  width: "max-content",
                  fontWeight: 600,
                  p: "5px 19px",
                  border: (theme) => `1px solid ${theme.palette.primary.main}`,
                }}
              >
                <AttachmentIcon fill={"#358B8A"} />
                Attach Answers
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={(e) =>
                    handleImageAnswerChange(e, setItem2Answers, item2Answers)
                  }
                  style={{ display: "none" }}
                />
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            ...dFlex,
            alignItems: "flex-end",
            mr: "14%",
            flexDirection: "column",
          }}
        >
          {item2Answers.map((e, i) => (
            <Box key={i} sx={{ ...itemsCenter, gap: 1, my: 1 }}>
              <AttachmentIcon />
              <Typography
                title={`${e?.file?.name} (${checkSize(e?.file)}mb)`}
                sx={{
                  width: "280px",
                  ...textEllipses,
                }}
              >
                {e?.file?.name} ({checkSize(e?.file)}mb)
              </Typography>
              <IconButton
                onClick={() => {
                  handleRemove(item2Answers, i, setItem2Answers);
                }}
                sx={{ p: 1, border: "1px solid #358b8a" }}
              >
                <DeleteIcon height={"18px"} fill={"#358b8a"} />
              </IconButton>
            </Box>
          ))}
        </Box>
      </form>
      <Box className="quizForm-mainForm-infoRow">
        <Button
          variant="primary-small"
          style={{ marginLeft: "auto" }}
          disabled={loading}
          onClick={addData}
        >
          Save
        </Button>
      </Box>
    </section>
  );
};

export default DragAndDropCategory;
