import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import AddStudent from "../../../Components/Facilitator/CommunityProject/AddStudent";
import IssueCertificate from "../../../Components/Facilitator/CommunityProject/IssueCertificate";
import ChatOtherComponent from "../../../Components/Parent/CommunityChannel/ChatInfoPage/ChatOtherComponent";
import ChatVideoComponent from "../../../Components/Parent/CommunityChannel/ChatInfoPage/ChatVideoComponent";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import ProgressBar from "../../../common/ProgressBar";
import {
  dFlex,
  flexBetweenCenter,
  flexBetweenWithItemsCenter,
  flexCenter,
  itemsCenter,
  textEllipses,
} from "../../../common/commonStyles";
import { formatNumberWithOrdinal } from "../../../const/constFunctions";
import { projectNotSubmitted } from "../../../const/imageUrl";
import sendNotification from "../../../functions/PushNotification";

export default function FacilitatorProjectDetails() {
  const params = useParams();
  const [certificateIssued, setCertificateIssued] = useState(true);

  const [apiData, fetchAgain] = useDataFetchHook(
    process.env.REACT_APP_FACILITATOR_GROUP_INFO + `?groupId=${params.groupId}`
  );
  const [filesApiData] = useDataFetchHook(
    process.env.REACT_APP_FACILITATOR_GROUP_INFO +
      `/files?groupId=${params.groupId}`
  );
  // console.log({ res: filesApiData?.res });
  const sendNotificationToGroupUsers = (title, body) => {
    try {
      if (!title || !body) {
        console.log("No title and body specified");
        return;
      }
      console.log({ title, body });
      let membersArr = [
        ...apiData?.res?.data?.members,
        apiData?.res?.data?.admin,
      ];
      console.log(membersArr);
      membersArr.forEach((e) => {
        sendNotification({
          deviceToken: e?.fcmToken ?? null,
          title,
          body,
          type: "community-project",
          userId: e?.id,
        });
      });
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  useEffect(() => {
    if (!apiData.res?.data) return;
    setCertificateIssued(apiData?.res?.data?.certificateIssued ?? false);
  }, [apiData?.res?.data]);

  // console.log({ res: apiData.res });
  return (
    <Box>
      <NavBreadCrumbs pageTags={["Community Project", "Group Name"]} />

      <Box sx={{ ...flexBetweenWithItemsCenter, mb: 3 }}>
        <Typography
          variant="bold"
          sx={{
            color: (theme) => theme.palette.primary.main,
            alignSelf: "flex-end",
          }}
        >
          Participants
        </Typography>
        {certificateIssued ? (
          <Typography variant="bold2" sx={{ color: "#2DAC00", mr: 5 }}>
            {" "}
            Certificate has been Issued
          </Typography>
        ) : (
          <Box>
            <AddStudent
              groupData={apiData?.res?.data}
              fetchAgain={fetchAgain}
            />
            <IssueCertificate
              data={apiData?.res?.data}
              fetchAgain={fetchAgain}
              sendNotificationToGroupUsers={sendNotificationToGroupUsers}
            />
          </Box>
        )}
      </Box>
      {/* <CreateGroupTable /> */}
      <Box sx={{ mb: 3 }}>
        {apiData?.loading && (
          <Box sx={{ ...flexCenter }}>
            <CircularProgress />
          </Box>
        )}{" "}
        {apiData?.res?.data?.admin && (
          <StudentCard
            certificateIssued={certificateIssued}
            data={{ ...apiData?.res?.data?.admin, admin: true }}
            fetchAgain={fetchAgain}
          />
        )}
        {apiData?.res?.data?.members?.map((e, i) => (
          <StudentCard
            data={e}
            certificateIssued={certificateIssued}
            fetchAgain={fetchAgain}
            sendNotificationToGroupUsers={sendNotificationToGroupUsers}
          />
        ))}
      </Box>
      <Box>
        <Typography
          variant="bold"
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          Project Submitted
        </Typography>
      </Box>
      <Box
        sx={{
          ...dFlex,
          flexWrap: "wrap",
          rowGap: 2,
          columnGap: 5,
          mt: 3,
        }}
      >
        {filesApiData?.loading && (
          <Box sx={{ ...flexCenter }}>
            <CircularProgress />
          </Box>
        )}

        {filesApiData?.res?.total < 1 && (
          <Box
            sx={{
              mt: 3,
              ...flexCenter,
              flexDirection: "column",
              pt: 5,
              gap: 2,
            }}
          >
            <img src={projectNotSubmitted} alt="no request" />
            <Typography
              variant="bold"
              sx={{
                color: (theme) => theme.palette.text.light,
              }}
            >
              Project is not yet submitted
            </Typography>
          </Box>
        )}
        {filesApiData?.res?.files?.map((e, i) => {
          if (
            ["video/mp4", "video/mov", "video/avi", "video/mkv"].includes(
              e.fileType
            )
          ) {
            return <ChatVideoComponent key={e.id} data={e} />;
          } else {
            return <ChatOtherComponent key={e.id} data={e} />;
          }
          // <>
          //   <ChatVideoComponent />
          //   <ChatOtherComponent data={{ type: "docs" }} />
          //   <ChatOtherComponent data={{ type: "pdf" }} />
          //   <ChatOtherComponent data={{ type: "ppt" }} />
          //   <ChatOtherComponent data={{ type: "txt" }} />
          //   <ChatOtherComponent data={{ type: "xlxs" }} />
          //   <ChatOtherComponent data={{ type: "zip" }} />
          // </>;
        })}
      </Box>
    </Box>
  );
}

function StudentCard({
  data,
  fetchAgain,
  sendNotificationToGroupUsers,
  certificateIssued,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        my: 2,
        ...flexBetweenCenter,
        px: 5,
        borderRadius: "8px",
        height: "90px",
        backgroundColor: (theme) => theme.palette.background.secondary,
        position: "relative",
      }}
    >
      {data?.admin && (
        <Box
          sx={{
            position: "absolute",
            top: -10,
            right: 0,
            backgroundColor: (theme) => theme.palette.content.quizQuestion,
          }}
        >
          <Typography variant="medium2" sx={{ px: 1 }}>
            Admin
          </Typography>
        </Box>
      )}
      <Box sx={{ ...itemsCenter, gap: 2 }}>
        <Avatar
          src={data?.image}
          sx={{
            height: "62px",
            width: "62px",
          }}
        />
        <Box sx={{ ...dFlex, gap: 1, flexDirection: "column" }}>
          <Typography
            variant="medium"
            sx={{
              color: (theme) => theme.palette.text.dark,
              width: "160px",
              ...textEllipses,
            }}
          >
            {data?.studentName} {/* Student Name */}
          </Typography>
          <Typography
            variant="medium2"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            {data?.totalTokens ?? 0} Tokens Earned.
          </Typography>
        </Box>
      </Box>
      <Box sx={{ ...itemsCenter, flexDirection: "column" }}>
        <Typography
          variant="bold"
          sx={{ color: (theme) => theme.palette.text.dark }}
        >
          {formatNumberWithOrdinal(data?.rank ?? 0)}
        </Typography>
        <Typography sx={{ color: (theme) => theme.palette.text.dark }}>
          Rank
        </Typography>
      </Box>
      <Box sx={{ ...itemsCenter, gap: 3 }}>
        <Box
          sx={{
            ...dFlex,
            flexDirection: "column",
            width: "215px",
            gap: 1,
            mr: data?.admin || certificateIssued ? 6 : 0,
          }}
        >
          <Typography sx={{ color: (theme) => theme.palette.text.dark }}>
            {certificateIssued
              ? "All Modules Completed"
              : data?.moduleData?.moduleName ?? "No Module Attended"}{" "}
            {/* Module Name */}
          </Typography>
          <ProgressBar progress={data?.percentage ?? 0} fontSize={12} />{" "}
        </Box>
        {!data?.admin && !certificateIssued && (
          <>
            <IconButton sx={{ p: 0, mr: 0 }} onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Popover
              open={anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                top: 0,
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Box
                sx={{
                  p: 2,
                  width: "160px",
                  ...dFlex,
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <RemoveFromGroup
                  sendNotificationToGroupUsers={sendNotificationToGroupUsers}
                  data={data}
                  fetchAgain={fetchAgain}
                  handleElClose={handleClose}
                />
                <MakeAdmin
                  sendNotificationToGroupUsers={sendNotificationToGroupUsers}
                  data={data}
                  fetchAgain={fetchAgain}
                  handleElClose={handleClose}
                />
              </Box>
            </Popover>
          </>
        )}
      </Box>
    </Box>
  );
}

function RemoveFromGroup({
  fetchAgain,
  data,
  handleElClose,
  sendNotificationToGroupUsers,
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = async () => {
    try {
      const url = process.env.REACT_APP_FACILITATOR_REMOVE_USER;
      setLoading(true);
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: { groupId: params.groupId, id: data?.id },
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      fetchAgain();
      toast(apiRes?.data?.message);
      handleClose();
      handleElClose?.();
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Typography
        variant="medium2"
        onClick={handleOpen}
        sx={{ color: (theme) => theme.palette.text.light, cursor: "pointer" }}
      >
        Remove from Group{" "}
      </Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "13px",
            backgroundColor: (theme) => theme.palette.background.main,
          },
        }}
      >
        <Box sx={{ width: "515px", pb: "20px" }}>
          <DialogContent sx={{ mb: 2 }}>
            <DialogContentText id="alert-dialog-description">
              <Typography
                variant="regular"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Are you sure you want to remove <b>{data?.studentName}</b> from
                the group?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button disabled={loading} onClick={handleRemove} variant="primary">
              Remove
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}

function MakeAdmin({
  fetchAgain,
  data,
  handleElClose,
  sendNotificationToGroupUsers,
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    // console.log(data);
    try {
      const url = process.env.REACT_APP_FACILITATOR_CHANGE_ADMIN;
      setLoading(true);
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: { groupId: params.groupId, newAdmin: data?.id },
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      sendNotificationToGroupUsers(
        "Group Admin Updated",
        `${data?.studentName} is the new admin of the group`
      );
      fetchAgain();
      toast(apiRes?.data?.message);
      handleClose();
      handleElClose?.();
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Typography
        variant="medium2"
        onClick={handleOpen}
        sx={{ color: (theme) => theme.palette.text.light, cursor: "pointer" }}
      >
        Make Admin
      </Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "13px",
            backgroundColor: (theme) => theme.palette.background.main,
          },
        }}
      >
        <Box sx={{ width: "515px", pb: "20px" }}>
          <DialogContent sx={{ mb: 2 }}>
            <DialogContentText id="alert-dialog-description">
              <Typography
                variant="regular"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Are you sure you want to make <b>{data?.studentName}</b> an
                admin of this group?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button disabled={loading} onClick={handleSave} variant="primary">
              Confirm
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
