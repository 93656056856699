import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CreateGroupModal from "../../../Components/Facilitator/ManageStudent/CreateGroupModal";
import CreateGroupTable from "../../../Components/Facilitator/ManageStudent/CreateGroupTable";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { flexBetweenWithItemsCenter } from "../../../common/commonStyles";

export default function CreateGroup() {
  const [batchDetail, setBatchDetail] = useState({
    name: "",
  });
  const [checkedItems, setCheckedItems] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const { batchInfo } = location?.state ?? { name: "" };
    setBatchDetail(batchInfo);
  }, [location?.state]);

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Manage Student", "Create Group"]} />

      <Box
        sx={{
          width: "100%",
          my: 3,
          ...flexBetweenWithItemsCenter,
        }}
      >
        <Typography
          variant="bold"
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          {batchDetail?.name}
        </Typography>
        <CreateGroupModal studentData={checkedItems} />
      </Box>

      <CreateGroupTable
        checkedItems={checkedItems}
        setCheckedItems={setCheckedItems}
      />
    </Box>
  );
}
