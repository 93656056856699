import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { dFlex, flexBetweenCenter } from "../../../common/commonStyles";
import ACEQuestionAddModal from "./ACEQuestionAddModal";
import ACEQuestionDeleteModal from "./ACEQuestionDeleteModal";

export default function ACEquestion({ total, index, data, fetchAgain }) {
  return (
    <Box sx={{ my: 4 }}>
      <Box>
        <Typography
          variant="medium"
          sx={{ color: (theme) => theme.palette.text.main }}
        >
          {index + 1}. {data?.question}.
        </Typography>
      </Box>
      <Box sx={{ ...flexBetweenCenter, px: 3 }}>
        <Box sx={{ ...dFlex, flexDirection: "column", width: "70%", mt: 1 }}>
          <Typography
            variant="medium"
            sx={{ color: (theme) => theme.palette.text.main }}
          >
            a. {data?.option?.[0]}
          </Typography>
          <Typography
            variant="medium"
            sx={{
              color: (theme) => theme.palette.text.main,
              textAlign: "center",
            }}
          >
            or
          </Typography>
          <Typography
            variant="medium"
            sx={{ color: (theme) => theme.palette.text.main }}
          >
            b. {data?.option?.[1]}
          </Typography>
        </Box>
        <Box>
          <Button
            variant="disable"
            disableRipple
            sx={{
              cursor: "default",
              p: 0.5,
              m: 1,
              "&:hover": {
                backgroundColor: "#A8A6A6",
              },
            }}
          >
            Yes
          </Button>
          <Button
            variant="disable"
            disableRipple
            sx={{
              cursor: "default",
              p: 0.5,
              m: 1,
              "&:hover": {
                backgroundColor: "#A8A6A6",
              },
            }}
          >
            No
          </Button>
          <ACEQuestionAddModal
            fetchAgain={fetchAgain}
            edit={true}
            data={data}
          />
          <ACEQuestionDeleteModal data={data} fetchAgain={fetchAgain} />
        </Box>
      </Box>
    </Box>
  );
}
