import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../Apis/axiosPayloads";
import { PATCH, POST } from "../../Apis/constVariables";
import { flexCenter, itemsCenter } from "../../common/commonStyles";
import { FormTextField } from "../../const/TextField";
import { AccordionTransition } from "../../const/constFunctions";
import { AddIcon, EditIconColor } from "../../images/icons";
import CreateQuestion from "./CreateQuestion";
import QuizDeleteModal from "./DeleteModals/QuizDeleteModal";
import { ExpandMoreSvg } from "./icons";
import "./styles/createTopic.css";
const CreateQuizPrePost = ({
  moduleId,
  prePostApiCall,
  quizData,
  text,
  type,
  fetchPrePostAgain,
  disabled,
}) => {
  const initData = {
    moduleAssociated: moduleId,
    quizName: "",
    type: type,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [showQuizForm, setShowQuizForm] = useState(false);

  const [editable, setEditable] = useState(false);

  const addQuiz = async (data) => {
    try {
      let nsn = data?.quizName.replaceAll(" ", "");
      if (!nsn) {
        toast.error("Please Enter Quiz Name");
      }
      let dat = /\//i.test(nsn);
      if (dat) {
        toast.error("You cannot use (/) characters");
        return;
      }
      //{{url}}/api/content/cb/quiz
      const url = process.env.REACT_APP_QUIZ;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...data,
          quizName: data?.quizName?.trim(),
        },
      });
      setLoading(true);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      setLoading(false);
      fetchPrePostAgain();
      toggleForm();
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
  };

  const toggleForm = (e) => {
    reset(initData);
    setShowQuizForm((p) => !p);
  };

  function edit(quiz) {
    setEditable(true);
    // setQuizId(quiz.id);
    reset(quiz);
    setShowQuizForm(true);
  }

  const updateQuiz = async (data) => {
    try {
      let nsn = data?.quizName.replaceAll(" ", "");
      if (!nsn) {
        toast.error("Please Enter Quiz Name");
        return;
      }
      let dat = /\//i.test(nsn);
      if (dat) {
        toast.error("You cannot use (/) characters");
        return;
      }
      //{{url}}/api/content/cb/quiz
      const url = process.env.REACT_APP_QUIZ + `/${data?.quizId}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: {
          quizName: data?.quizName?.trim(),
        },
      });
      setLoading(true);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      setLoading(false);
      fetchPrePostAgain();
      toggleForm();
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <>
      <section>
        <Button
          variant="primary"
          disabled={quizData || prePostApiCall?.loading || loading || disabled}
          sx={{ my: 2 }}
          onClick={() => {
            setEditable(false);
            toggleForm();
          }}
        >
          <AddIcon />
          Add {text}
        </Button>
        {prePostApiCall?.loading && (
          <Box sx={{ ...flexCenter }}>
            <CircularProgress />
          </Box>
        )}

        {showQuizForm && (
          <Box
            className="createQuiz-quizForm createQuiz-container"
            id={"quizForm"}
          >
            <Typography
              variant="bold"
              sx={{ color: (theme) => theme.palette.primary.main }}
            >
              {text}
            </Typography>
            <Box className="createQuiz-row" sx={{ my: 2 }}>
              <label className="createQuiz-inputLabel">Quiz Name *</label>
              <FormTextField
                {...register("quizName", { required: "Enter Quiz Name" })}
                variant="outlined"
                error={!!errors.quizName}
                helperText={errors?.quizName?.message}
              />
            </Box>
            {/* <Box className="createQuiz-row">
              <Typography className="createQuiz-inputLabel">
                Time Limit (minutes)*
              </Typography>
              <FormTextField
                {...register("timeLimit", {
                  required: "Enter Time Limit",
                  min: { value: 0, message: "Enter a valid Time Limit" },
                })}
                type="number"
                variant="outlined"
                // className="createQuiz-inputBar"
                error={!!errors.timeLimit}
                helperText={errors?.timeLimit?.message}
              />
            </Box> */}

            <Box className="createQuiz-row">
              <Button
                sx={{
                  ml: "auto",
                  textTransform: "none",
                }}
                onClick={() => {
                  setEditable(false);
                  toggleForm();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary-small"
                disabled={loading}
                onClick={handleSubmit(!editable ? addQuiz : updateQuiz)}
              >
                Save
              </Button>
            </Box>
          </Box>
        )}

        {quizData && (
          <Accordion TransitionProps={AccordionTransition}>
            <AccordionSummary
              expandIcon={<ExpandMoreSvg />}
              sx={{
                backgroundColor: (theme) => theme.palette.content.quiz,
                color: "white",
                height: "40px",
                borderRadius: "7px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box className="quiz-accordion-heading">
                  <p>{quizData.quizName}</p>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="quiz-accordion-details-head">
                <p>{quizData.quizName}</p>
                <Box
                  sx={{
                    ...itemsCenter,
                    ml: "auto",
                    mr: 1,
                  }}
                  // onClick={() => del(item)}
                >
                  <IconButton onClick={() => edit(quizData)}>
                    <EditIconColor />
                  </IconButton>
                  <QuizDeleteModal
                    data={quizData}
                    fetchQuizAgain={fetchPrePostAgain}
                  />
                </Box>
              </Box>

              <CreateQuestion notNormalQuiz={true} quizId={quizData?.quizId} />
            </AccordionDetails>
          </Accordion>
        )}
      </section>
    </>
  );
};

export default CreateQuizPrePost;
