import { Box } from "@mui/material";
import React, { useMemo } from "react";
import AnalyticsGraph from "../../../Components/Parent/Dashboard/AnalyticsGraph";
import DashboardOverViewCard from "../../../Components/Parent/Dashboard/DashboardOverViewCard";
import LeaderBoard from "../../../Components/Parent/Dashboard/LeaderBoard";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";

export default function ParentDashboard() {
  const Leaderboard = useMemo(() => <LeaderBoard />, []);

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Dashboard"]} />
      <DashboardOverViewCard />
      <AnalyticsGraph />
      {Leaderboard}
    </Box>
  );
}
