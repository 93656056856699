import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import { flexCenter } from "../../../common/commonStyles";
import ModuleCompleteSentance from "./ModulePrePostCards/ModuleCompleteSentance";
import ModuleDragAndDropCard from "./ModulePrePostCards/ModuleDragAndDropCard";
import ModuleDropDownCard from "./ModulePrePostCards/ModuleDropDownCard";
import ModuleMCQCard from "./ModulePrePostCards/ModuleMCQCard";
import ModuleMatchPairsCard from "./ModulePrePostCards/ModuleMatchPairsCard";
import ModulePicMCQCard from "./ModulePrePostCards/ModulePicMCQCard";
import ModuleSCQCard from "./ModulePrePostCards/ModuleSCQCard";
import ModuleSeqCard from "./ModulePrePostCards/ModuleSeqCard";

export default function PrePostQuizTab({ stdId, moduleId, quizData }) {
  const [url, setUrl] = useState(null);

  const [apiCall] = useDataFetchHook(url);

  useEffect(() => {
    if (!stdId || !moduleId) {
      return;
    }
    const queryParam = new URLSearchParams();
    queryParam.set("stdId", stdId);
    queryParam.set("moduleId", moduleId);
    queryParam.set("quizId", quizData?.quizId);
    setUrl(
      process.env.REACT_APP_FACILITATOR_GET_STUDENT_QUIZ_ANSWER +
        "?" +
        queryParam.toString()
    );
    return () => {
      setUrl(null);
    };
  }, [quizData]);

  return (
    <Box
    // sx={{ height: "75vh", overflow: "auto" }}
    >
      {apiCall?.loading && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />
        </Box>
      )}

      {apiCall?.res?.questions?.map((e, i) => {
        if (e.questionType === "MCQ" && e?.data?.choiceType == "single")
          return <ModuleSCQCard data={e} index={i} />;
        if (e.questionType === "MCQ" && e?.data?.choiceType == "multiple")
          return <ModuleMCQCard data={e} index={i} />;
        if (e.questionType === "Ranking")
          return <ModuleSeqCard data={e} index={i} />;
        if (e.questionType === "DropDown")
          return <ModuleDropDownCard data={e} index={i} />;
        if (e.questionType === "CompleteSentence")
          return <ModuleCompleteSentance data={e} index={i} />;
        if (e.questionType === "PicMCQ")
          return <ModulePicMCQCard data={e} index={i} />;
        if (e.questionType === "DragDrop")
          return <ModuleDragAndDropCard data={e} index={i} />;
        if (e.questionType === "MatchPair")
          return <ModuleMatchPairsCard data={e} index={i} />;
      })}

      {/* <ModuleAnswerCard index={5} /> */}
    </Box>
  );
}
