import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TablePagination,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import useTablePagination from "../../../Hooks/TablePaginationHook";
import {
  dFlex,
  flexBetweenCenter,
  flexCenter,
} from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { BatchEmpty } from "../../../const/imageUrl";
import { RegisterIcon } from "../../../images/icons";
import ManageStudentCard from "./ManageStudentCard";

export default function ManageStudentTab({ data }) {
  const navigate = useNavigate();
  const url = `${process.env.REACT_APP_SCHOOL_GET_STUDENT_BATCH}${data.batchName}&offset=0&limit=50`;
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [studentApiCall, fetchAgain] = useDataFetchHook(url);

  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();

  const handleSearch = () => {
    // console.log("searhing");
    let arr = [...studentApiCall?.res?.Student];
    arr = arr?.filter((e) => {
      return e?.studentName?.toLowerCase()?.includes(searchText.toLowerCase());
    });
    handleChangePage(null, 0);
    setSearchData(arr);
  };

  useEffect(() => {
    if (!searchText) {
      setSearchData(studentApiCall?.res?.Student);
      return;
    }
    handleSearch();
  }, [searchText]);

  useEffect(() => {
    if (!studentApiCall?.res) {
      return;
    }
    setSearchData(studentApiCall?.res?.Student);
  }, [studentApiCall?.res]);

  return (
    <Box>
      {studentApiCall?.res?.Student?.length < 1 && (
        <Box
          sx={{
            mt: 3,
            backgroundColor: (theme) => theme.palette.background.secondary,
            ...flexCenter,
            flexDirection: "column",
            py: 8,
            gap: 5,
            borderRadius: "12px",
          }}
        >
          <img src={BatchEmpty} style={{ height: "220px", width: "600px" }} />
          <Typography
            variant="bold"
            sx={{ color: (theme) => theme.palette.text.light }}
          >
            You have not added any students yet
          </Typography>
          <Button
            onClick={() => {
              navigate("/facilitator/manage-student/add-student");
            }}
            variant="primary"
          >
            <RegisterIcon /> Add Student
          </Button>
        </Box>
      )}

      <Box sx={{ ...flexBetweenCenter, mb: 3 }}>
        <Box
          sx={{
            ...dFlex,
            gap: 2,
            alignItems: "center",
            boxSizing: "border-box",
          }}
        >
          <FormTextField
            placeholder="search"
            disabled={studentApiCall?.loading}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            value={searchText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    fontSize="small"
                    sx={{
                      color: "#BFBFBF",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      {studentApiCall?.loading && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />{" "}
        </Box>
      )}
      <Box
        sx={{
          ...dFlex,

          gap: 5,
          flexWrap: "wrap",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        {/* {studentApiCall?.res?.Student? */}
        {searchData
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          ?.map((e) => {
            return (
              <ManageStudentCard data={e} fetchAgain={fetchAgain} key={e?.id} />
            );
          })}
      </Box>
      {studentApiCall?.res?.Student?.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            rowsPerPageOptions={[]}
            count={searchData?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
}
