import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";

export default function ApproveOfflineRequestModal({ data, fetchAgain }) {
  const [open, setOpen] = useState();
  const [loading, setLoading] = useState(false);
  const handleOpen = () => {
    // localStorage.clear();
    setOpen(true);
  };
  const handleClose = () => {
    // localStorage.clear();
    setOpen(false);
  };

  const handleApprove = async () => {
    try {
      const url = process.env.REACT_APP_REGISTER_STATUS + "/success";
      setLoading(true);
      if (!data?.subscribeMore) {
        const config = createAxiosConfig({
          endPoint: url,
          method: POST,
          data: { email: data?.id, paymentStatus: "success" },
        });
        // console.log(config);
        const apiRes = await axios.request(config);
        toast(apiRes?.data?.message);
      } else {
        await handleOfflineApprove();
      }
      fetchAgain();
      handleClose();
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
    setLoading(false);
  };

  const handleOfflineApprove = async () => {
    try {
      let formattedData = {
        amount: data?.amount,
        student: data?.numberOfStudent,
        ...data,
      };
      const url =
        process.env.REACT_APP_SUBSCRIBE_MORE + `?userId=${data?.userId}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: formattedData,
      });
      const apiRes = await axios.request(config);
      return toast(apiRes?.data?.message);
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
      throw err;
    }
  };

  return (
    <>
      <Button variant="primary-small" onClick={handleOpen}>
        Approve
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "13px",
            backgroundColor: (theme) => theme.palette.background.main,
          },
        }}
      >
        <Box sx={{ width: "425px", pb: "16px" }}>
          {/* <form onSubmit={handleDeleteAdmission} autoComplete="off"> */}
          <DialogContent sx={{ mb: 2 }}>
            <DialogContentText id="alert-dialog-description">
              <Typography
                variant="regular"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Are you sure <b>{data?.schoolName}</b> has paid{" "}
                <b>${data?.amount}</b> offline?{" "}
              </Typography>
              <br />
              <Typography
                variant="medium2"
                sx={{
                  color: (theme) => theme.palette.content.quizQuestion,
                }}
              >
                Note: Once approved you won’t be able to reject.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Close
            </Button>
            <Button
              disabled={loading}
              onClick={handleApprove}
              variant="primary"
            >
              Approve
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
