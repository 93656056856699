import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import useDataFetchHook from "../../../../Hooks/DataFetchHook";
import { dFlex, flexCenter } from "../../../../common/commonStyles";
import { emptyDataSA } from "../../../../const/imageUrl";
import AddCategoryModal from "./AddCategoryModal";
import CategoryCard from "./CategoryCard";

export default function MedzGym() {
  const url = process.env.REACT_APP_GYM_CATEGORY;
  const [apiCall, fetchAgain] = useDataFetchHook(url);
  // console.log(apiCall);
  return (
    <Box>
      <Box
        sx={{
          ...dFlex,
          justifyContent: "flex-end",
          mb: 4,
        }}
      >
        <AddCategoryModal fetchAgain={fetchAgain} />
      </Box>
      {apiCall?.loading && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />
        </Box>
      )}
      {/* {apiCall?.error && (
        <Box sx={{ ...flexCenter, flexDirection: "column", mt: 20 }}>
          {apiCall?.errorMessage ?? "Something went wrong"}
          <Button variant="primary-small" onClick={fetchAgain}>
            Retry
          </Button>
        </Box>
      )} */}
      {!apiCall.loading && apiCall?.res?.data.length < 1 && (
        <Box
          sx={{
            mt: 3,
            backgroundColor: (theme) => theme.palette.background.secondary,
            ...flexCenter,
            flexDirection: "column",
            py: 8,
            gap: 5,
            borderRadius: "12px",
          }}
        >
          <img src={emptyDataSA} style={{ height: "254px", width: "345px" }} />
          <Typography
            variant="bold"
            sx={{ color: (theme) => theme.palette.text.light }}
          >
            You have not added any category
          </Typography>
          <AddCategoryModal fetchAgain={fetchAgain} />
        </Box>
      )}
      <Box>
        {apiCall?.res?.data?.map((e, i) => (
          <CategoryCard key={e.id} data={e} index={i} fetchAgain={fetchAgain} />
        ))}
      </Box>
    </Box>
  );
}
