import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../Apis/axiosPayloads";
import { PATCH } from "../../Apis/constVariables";
import useDataFetchHook from "../../Hooks/DataFetchHook";
import {
  flexBetweenWithItemsCenter,
  flexCenter,
  itemsCenter,
  textEllipses,
} from "../../common/commonStyles";
import { FormTextField } from "../../const/TextField";
import {
  AccordionTransition,
  checkSize,
  convertToBase64File,
} from "../../const/constFunctions";
import { success } from "../../const/variables";
import { EditIconColor } from "../../images/icons";
import SelfReflectionQuestion from "./BrawtaQuestions/SelfReflectionQuestion";
import BrawtaQuestionDeleteModal from "./DeleteModals/BrawtaQuestionDeleteModal";
import { ExpandMoreSvg, SvgVector } from "./icons";
import "./styles/createQuiz.css";

// const temp = process.env.REACT_APP_QUESTION;

export const validateBasicQuestionForm = (data) => {
  try {
    if (!data.questionName?.trim()) {
      return { error: true, message: "Question Name is required" };
    }
    if (!data.question?.trim()) {
      return { error: true, message: "Question is required" };
    }
    if (!data.questionInstruction?.trim()) {
      return { error: true, message: "Question Instruction is required" };
    }
    return { error: false };
  } catch (e) {
    console.log(e);
    return { error: true, message: "Something went wrong" };
  }
};

const CreateBrawtaQuestion = ({ brawtaId }) => {
  const initData = {
    brawtaId,
    questionName: "",
    question: "",
  };
  const [questionFormData, setQuestionFormData] = useState(initData);
  const [editAble, setEditAble] = useState(false);
  const [base64, setBase64] = useState(null);
  const [file, setFile] = useState(null);
  const url =
    brawtaId && process.env.REACT_APP_BRAWTA_QUESTION + `?brawtaId=${brawtaId}`;

  const [
    brawtaQuestionApiCall,
    fetchBrawtaQuestionsAgain,
    dispatchQuizQuestions,
  ] = useDataFetchHook(url);

  const [showQuizForm, setShowQuizForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setQuestionFormData((p) => {
      return {
        ...p,
        [name]: value,
      };
    });
  };

  const handleImageChange = async (state, e) => {
    try {
      if (e?.target?.files.length > 0) {
        let file = e.target.files[0];
        if (checkSize(file) > 10) {
          e.target.value = "";
          toast.error("Please upload an image less than 10MB");
          return;
        }
        const base64 = await convertToBase64File(file);
        console.log({ base64 });
        setBase64(base64);
        state(e?.target?.files?.[0]);
      } else state(null);
    } catch (err) {
      console.log(err);
      e.target.value = "";
      toast.error("Something went wrong, Please try again");
    }
  };

  const toggleQuestionForm = () => {
    setQuestionFormData({
      ...initData,
      questionName: brawtaQuestionApiCall?.res?.questions?.length + 1,
    });
    setEditAble(false);
    setShowQuizForm((p) => !p);
    setFile(null);
    setBase64(null);
  };
  const handleOnDragEnd = async (result) => {
    try {
      if (!result.destination) return;
      const items = Array.from(brawtaQuestionApiCall?.res?.questions);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      let url = process.env.REACT_APP_BRAWTA_QUESTION_ORDER;

      const formattedData = {
        brawtaId: brawtaId,
        questions: items.map((e) => e.questionId),
      };
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: formattedData,
      });
      setLoading(true);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      dispatchQuizQuestions({ type: success, data: { questions: items } });
      // fetchLessonAgain();
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  function edit(item) {
    // console.log({ item });

    const formattedData = {
      questionId: item?.questionId,
      question: item?.question,
      questionName: item?.questionName,
      brawtaId: item?.brawtaId,
    };

    setEditAble(true);
    setQuestionFormData(formattedData);
    setShowQuizForm(true);
  }

  return (
    <section className="quizForm-container">
      <Button
        variant="primary-small"
        disabled={brawtaQuestionApiCall?.res?.questions?.length >= 5}
        onClick={() => {
          toggleQuestionForm();
        }}
      >
        Add Question
      </Button>
      {showQuizForm && (
        <Box className="quizFrom-mainFrom">
          <form
            id={brawtaId + "question-form"}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Box className="quizForm-mainForm-infoRow">
              <p className="quizForm-mainForm-label">Question Name :</p>
              <FormTextField
                type="text"
                className="quizForm-mainForm-inputBar"
                name="questionName"
                value={questionFormData?.questionName}
                onChange={handleChange}
              />
            </Box>
            <Box className="quizForm-mainForm-infoRow">
              <p className="quizForm-mainForm-label">Question :</p>
              <FormTextField
                autoFocus
                type="text"
                className="quizForm-mainForm-inputBar"
                name="question"
                value={questionFormData?.question}
                onChange={handleChange}
              />
            </Box>
            {!editAble && (
              <Box
                className="quizForm-mainForm-infoRow"
                // id={topicId + moduleId + lessonId + "pdfLinkBlock"}
              >
                <Typography
                  sx={{
                    color: (theme) => theme.palette.text.dark,
                  }}
                  className="addItem-inputLabel"
                >
                  File
                </Typography>
                <Box sx={{ ...itemsCenter, gap: "10px" }}>
                  <Box
                    component={"label"}
                    sx={{
                      ...flexCenter,
                      height: "35px",
                      width: "125px",
                      cursor: "pointer",
                      borderRadius: "4px",
                      color: (theme) => theme.palette.text.main,
                      backgroundColor: (theme) => theme.palette.text.lighter,
                      // "&:hover": {
                      //   backgroundColor: "#D1D0D0",
                      // },
                    }}
                  >
                    Choose a file
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleImageChange(setFile, e)}
                      style={{ display: "none" }}
                    />
                  </Box>
                  <Typography
                    variant="regular"
                    title={file ? file?.name : "No File Chosen"}
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      // maxWidth: "220px",
                      // ...textEllipses,
                    }}
                  >
                    {file ? file?.name : "No File Chosen"}
                  </Typography>
                </Box>
              </Box>
            )}
            <SelfReflectionQuestion
              editAble={editAble}
              base64={base64}
              questionFormData={questionFormData}
              fetchBrawtaQuestionsAgain={fetchBrawtaQuestionsAgain}
              toggleQuestionForm={toggleQuestionForm}
            />
          </form>
        </Box>
      )}

      {(brawtaQuestionApiCall?.loading || loading) && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />
        </Box>
      )}

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <ul
              id="questionList"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                display: "block",
                overflow: "hidden",
                width: "100%",
                listStyleType: "none",
                margin: "0px",
                padding: "0px",
              }}
            >
              {brawtaQuestionApiCall?.res?.questions?.map((item, index) => (
                <Draggable
                  key={item.questionId}
                  draggableId={item.questionId}
                  index={index}
                >
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      key={item.id}
                      className="quiz-dragable"
                    >
                      <Accordion TransitionProps={AccordionTransition}>
                        <AccordionSummary
                          {...provided.dragHandleProps}
                          expandIcon={<ExpandMoreSvg />}
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.content.brawtaQuestion,
                            height: "40px",
                            color: "white",
                            borderRadius: "5px",
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <SvgVector />
                            <Typography
                              sx={{
                                maxWidth: "600px",
                                ...textEllipses,
                                whiteSpace: "pre-line",
                              }}
                            >
                              {item.questionName}
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box sx={{ ...flexBetweenWithItemsCenter }}>
                            <Typography
                              sx={{
                                maxWidth: "600px",
                                ...textEllipses,
                                whiteSpace: "pre-line",
                              }}
                            >
                              {item.question}
                            </Typography>
                            <Box>
                              <IconButton
                                onClick={() => edit(item)}
                                sx={{ py: 0 }}
                              >
                                <EditIconColor />
                              </IconButton>
                              <BrawtaQuestionDeleteModal
                                data={item}
                                fetchBrawtaQuestionsAgain={
                                  fetchBrawtaQuestionsAgain
                                }
                              />
                            </Box>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </section>
  );
};

export default CreateBrawtaQuestion;

{
  /* <MenuItem value="Assignment">
                  <Typography
                    variant="medium"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    Assignment
                  </Typography>
                </MenuItem> */
}
