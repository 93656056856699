import { Box, Button, Dialog, DialogActions, Typography } from "@mui/material";
import React, { useState } from "react";
import { itemsCenter } from "../../../../../common/commonStyles";
import VideoPlayer from "../../../../../const/VideoPlayer";
import { VideoIcon } from "../../../../../images/icons";

export default function VideoPlayerModal({ data }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ ...itemsCenter, gap: 1, my: 1 }} onClick={handleClickOpen}>
        <VideoIcon />
        <Typography sx={{ color: (theme) => theme.palette.text.main }}>
          (Video) {data?.itemName}
        </Typography>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "#F3F5F5",
            borderRadius: "14px",
            p: "24px 12px 12px 24px",
            maxWidth: "100%",
            width: "40%",
          },
        }}
      >
        <VideoPlayer
          data={{
            coverImage: data?.coverImage,
            title: data?.itemName,
            file: data?.URL,
          }}
        />
        <DialogActions sx={{ pt: 1 }}>
          <Button
            sx={{ height: "32px", textTransform: "none" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
