import { Box } from "@mui/material";
import React from "react";
import MedzGym from "../../Components/Admin/Content/MedzGym/MedzGym";
import NavBreadCrumbs from "../../common/NavBreadCrumbs";

export default function MedzGymPage() {
  return (
    <Box>
      <NavBreadCrumbs pageTags={["Medz Gym"]} />
      <MedzGym />
    </Box>
  );
}
