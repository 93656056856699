import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ModuleAnswerCard from "../../../Components/Admin/DemographicData/ModuleCards/ModuleAnswerCard";
import ModuleDragAndDropCard from "../../../Components/Admin/DemographicData/ModuleCards/ModuleDragAndDropCard";
import ModuleDropDownCard from "../../../Components/Admin/DemographicData/ModuleCards/ModuleDropDownCard";
import ModuleMCQCard from "../../../Components/Admin/DemographicData/ModuleCards/ModuleMCQCard";
import ModuleMatchPairsCard from "../../../Components/Admin/DemographicData/ModuleCards/ModuleMatchPairsCard";
import ModulePicMCQCard from "../../../Components/Admin/DemographicData/ModuleCards/ModulePicMCQCard";
import ModuleSCQCard from "../../../Components/Admin/DemographicData/ModuleCards/ModuleSCQCard";
import ModuleSeqCard from "../../../Components/Admin/DemographicData/ModuleCards/ModuleSeqCard";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { TabPanel } from "../Dashboard/SchoolSubscribers";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { GET } from "../../../Apis/constVariables";
import axios from "axios";

export default function ModulePage() {
  const location = useLocation();
  const [moduleInfo, setModuleInfo] = useState({ label: "" });

  const [value, setValue] = useState(1);
  // Event handler for tab change

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [preData, setPreData] = useState([]);
  const [postData, setPostData] = useState([]);
  const getPreData = async (moduleId) => {
    const url =
      process.env.REACT_APP_DEMOGRAPHIC_DATA +
      `/module?moduleId=${moduleId}&quizType=Prequiz`;
    const config = createAxiosConfig({
      endPoint: url,
      method: GET,
    });
    // console.log(config);
    const apiRes = await axios.request(config);
    setPreData(apiRes.data.data);
  };
  const getPostData = async (moduleId) => {
    const url =
      process.env.REACT_APP_DEMOGRAPHIC_DATA +
      `/module?moduleId=${moduleId}&quizType=Postquiz`;
    const config = createAxiosConfig({
      endPoint: url,
      method: GET,
    });
    // console.log(config);
    const apiRes = await axios.request(config);
    setPostData(apiRes.data.data);
    console.log(apiRes.data.data);
  };

  useEffect(() => {
    const { data } = location?.state ?? { label: "" };
    setModuleInfo(data);

    getPreData(data.data.moduleId);
    getPostData(data.data.moduleId);
  }, [location?.state]);

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Demographic Data", moduleInfo?.label]} />
      <Typography
        variant="bold"
        sx={{ color: (theme) => theme.palette.primary.main, mb: 3 }}
      >
        {moduleInfo?.label}
      </Typography>
      <Box
        sx={{
          // width: "100%",
          mt: 5,
          borderBottom: `1px solid #BFBFBF`,
        }}
      >
        <Tabs value={value} onChange={handleChange}>
          <Tab
            sx={{
              textTransform: "none",
            }}
            label={
              <Typography variant={value === 1 ? "bold2" : "medium"}>
                Pre Test
              </Typography>
            }
            value={1}
          />
          <Tab
            sx={{
              textTransform: "none",
            }}
            label={
              <Typography variant={value === 2 ? "bold2" : "medium"}>
                Post Test
              </Typography>
            }
            value={2}
          />
        </Tabs>
      </Box>
      {/* Pre Test */}
      <TabPanel value={value} index={1}>
        <Box
        // sx={{ height: "75vh", overflow: "auto" }}
        >
          {preData.map((_, idx) => {
            if (_.questionType === "MCQ" && _.data.choiceType === "single") {
              return <ModuleSCQCard value={_} index={idx} />;
            }
            if (_.questionType === "MCQ" && _.data.choiceType === "multiple") {
              return <ModuleMCQCard value={_} index={idx} />;
            }
            if (_.questionType === "MatchPair") {
              return <ModuleMatchPairsCard value={_} index={idx} />;
            }
            if (_.questionType === "PicMCQ") {
              return <ModulePicMCQCard value={_} index={idx} />;
            }
            if (_.questionType === "DropDown") {
              return <ModuleDropDownCard value={_} index={idx} />;
            }
            if (_.questionType === "Ranking") {
              return <ModuleSeqCard value={_} index={idx} />;
            }
            if (_.questionType === "DragDrop") {
              return <ModuleDragAndDropCard value={_} index={idx} />;
            }
            if (_.questionType === "CompleteSentence") {
              return <ModuleAnswerCard value={_} index={idx} />;
            }
          })}
          {/* 
          <ModuleSeqCard index={4} />
          <ModuleAnswerCard index={5} />
           */}
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box>
          {postData.map((_, idx) => {
            if (_.questionType === "MCQ" && _.data.choiceType === "single") {
              return <ModuleSCQCard value={_} index={idx} />;
            }
            if (_.questionType === "MCQ" && _.data.choiceType === "multiple") {
              return <ModuleMCQCard value={_} index={idx} />;
            }
            if (_.questionType === "MatchPair") {
              return <ModuleMatchPairsCard value={_} index={idx} />;
            }
            if (_.questionType === "PicMCQ") {
              return <ModulePicMCQCard value={_} index={idx} />;
            }
            if (_.questionType === "DropDown") {
              return <ModuleDropDownCard value={_} index={idx} />;
            }
            if (_.questionType === "Ranking") {
              return <ModuleSeqCard value={_} index={idx} />;
            }
            if (_.questionType === "DragDrop") {
              return <ModuleDragAndDropCard value={_} index={idx} />;
            }
            if (_.questionType === "CompleteSentence") {
              return <ModuleAnswerCard value={_} index={idx} />;
            }
          })}
        </Box>
        {/* <ModuleSCQCard index={0} />
        <ModuleMCQCard index={1} />
        <ModuleDropDownCard index={2} />
        <ModuleDragAndDropCard index={3} />
        <ModuleSeqCard index={4} />
        <ModuleAnswerCard index={5} />
        <ModulePicMCQCard index={6} />
        <ModuleMatchPairsCard index={7} /> */}
      </TabPanel>
    </Box>
  );
}
