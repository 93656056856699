import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  CircularProgress,
  InputAdornment,
  TablePagination,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import useTablePagination from "../../../Hooks/TablePaginationHook";
import {
  flexBetweenWithItemsCenter,
  flexCenter,
} from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { BatchEmpty } from "../../../const/imageUrl";
import BatchCard from "./BatchCard";
import CreateBatchModal from "./CreateBatchModal";

export default function BatchTab({ completed }) {
  const [url, setUrl] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [batchData, fetchBatchData, dispatchData] = useDataFetchHook(url);
  const [loading, setLoading] = useState(false);
  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();

  const filterData = () => {
    console.log("searhing");
    let arr = [...batchData?.res?.Batch];
    arr = arr?.filter((e) => {
      return e?.batchName?.toLowerCase()?.includes(searchText.toLowerCase());
    });
    handleChangePage(null, 0);
    setSearchData(arr);
    setLoading(false);
  };
  console.log({ page });
  useEffect(() => {
    if (!searchText) {
      setSearchData([]);
      setLoading(false);
      return;
    }
    const debounce = setTimeout(() => {
      filterData();
    }, 500);

    return () => clearTimeout(debounce);
  }, [searchText]);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("complete", completed ? true : false);
    const newUrl =
      process.env.REACT_APP_SCHOOL_BATCH_LIST + "?" + params.toString();
    setUrl(newUrl);
    return () => {};
  }, []);
  return (
    <Box>
      <Box sx={{ ...flexBetweenWithItemsCenter, height: "40px" }}>
        <Box>
          <FormTextField
            placeholder="search"
            disabled={batchData?.loading}
            onChange={(e) => {
              setLoading(true);
              setSearchText(e.target.value);
            }}
            value={searchText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    fontSize="small"
                    sx={{
                      color: "#BFBFBF",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {!completed && <CreateBatchModal fetchAgain={fetchBatchData} />}
      </Box>

      <Box>
        {batchData?.res?.Batch?.length <= 0 && (
          <Box
            sx={{
              mt: 3,
              backgroundColor: (theme) => theme.palette.background.secondary,
              ...flexCenter,
              flexDirection: "column",
              py: 8,
              gap: 5,
              borderRadius: "12px",
            }}
          >
            <img src={BatchEmpty} style={{ height: "220px", width: "600px" }} />
            <Typography
              variant="bold"
              sx={{ color: (theme) => theme.palette.text.light }}
            >
              {!completed
                ? "You have not created any batches yet"
                : "No batch is completed yet"}
            </Typography>
            {!completed && <CreateBatchModal fetchAgain={fetchBatchData} />}
          </Box>
        )}
        {(batchData?.loading || loading) && (
          <Box sx={{ ...flexCenter }}>
            <CircularProgress autoFocus={true} />
          </Box>
        )}
        {!searchText && (
          <Box sx={{ my: 3 }}>
            {batchData?.res?.Batch?.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )?.map((e, i) => (
              <BatchCard
                key={e?.id}
                data={e}
                fetchAgain={fetchBatchData}
                dispatchData={dispatchData}
                index={i}
                completed={completed}
                type={!completed ? "Current Batch" : "Completed Batch"}
              />
            ))}
          </Box>
        )}
        {searchText && (
          <Box sx={{ my: 3 }}>
            {searchData
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((e, i) => (
                <BatchCard
                  key={e?.id}
                  data={e}
                  fetchAgain={fetchBatchData}
                  dispatchData={dispatchData}
                  index={i}
                  completed={completed}
                  type={!completed ? "Current Batch" : "Completed Batch"}
                />
              ))}
          </Box>
        )}
        {batchData?.res?.Batch?.length >= 0 && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <TablePagination
              rowsPerPageOptions={[]}
              count={
                searchText ? searchData.length : batchData?.res?.totalCount
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
