import { Box, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  dFlex,
  flexSpaceBetween,
  itemsCenter,
} from "../../../../common/commonStyles";

export default function ModuleDropDownCard({ data, index }) {
  const [cases, setCases] = useState();
  useEffect(() => {
    if (!data?.data) {
      return;
    }
    setCases(data?.data?.options);
  }, [data?.data]);
  return (
    <Box
      sx={{
        my: 3,
        px: 3,
        borderRadius: "15px",
      }}
    >
      <Box sx={{ mb: 1, ...flexSpaceBetween, gap: 8 }}>
        <Typography
          variant="medium"
          sx={{
            flex: "80%",
            color: (theme) => theme.palette.text.main,
          }}
        >
          {index + 1}. {data?.question}
        </Typography>
      </Box>

      <Box sx={{ ...dFlex, flexDirection: "column", mt: 2 }}>
        {cases?.map((e, i) => (
          <Box
            sx={{
              ...itemsCenter,
              borderRadius: "6px",
              // backgroundColor: (theme) => theme.palette.background.secondary,
            }}
          >
            <Box
              sx={{
                p: "9px 20px",
                flex: "80%",
                color: (theme) => theme.palette.text.main,
                ...dFlex,
                flexDirection: "column",
                // boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.12)",
              }}
            >
              {String.fromCharCode(97 + i)}. {e.case}
              <Select
                sx={{
                  width: "70%",
                  mt: 0.2,
                  height: "30px",
                  backgroundColor: (theme) =>
                    theme.palette.background.secondary,
                }}
                value={e.answer}
              >
                {e?.options?.map((e, i) => (
                  <MenuItem value={i}>{e}</MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
