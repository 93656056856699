import { Avatar, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  dFlex,
  flexBetweenCenter,
  itemsCenter,
  textEllipses,
} from "../../../common/commonStyles";
import { formatNumberWithOrdinal } from "../../../const/constFunctions";

export default function CompletedStudentCard({
  data,
  batchDetails,
  show,
  fetchAgain,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  // console.log(data);

  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        my: 2,
        ...flexBetweenCenter,
        px: 2,
        borderRadius: "8px",
        height: "53px",
        backgroundColor: (theme) => theme.palette.background.secondary,
      }}
    >
      <Box
        sx={{ ...itemsCenter, gap: 2 }}
        // onClick={() => {
        //   navigate(`${data?.id}`, {
        //     state: {
        //       studentInfo: data,
        //     },
        //   });
        // }}
      >
        <Avatar
          src={data?.image}
          sx={{
            height: "38px",
            width: "38px",
          }}
        />
        <Box sx={{ ...dFlex, gap: 1, flexDirection: "column" }}>
          <Typography
            variant="medium"
            sx={{
              color: (theme) => theme.palette.text.main,
              width: "180px",
              ...textEllipses,
            }}
          >
            {data?.studentName}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ ...itemsCenter, flexDirection: "column" }}>
        <Typography
          variant="medium2"
          sx={{ color: (theme) => theme.palette.text.main }}
        >
          {data?.totalTokens ? data?.totalTokens : "0"} Tokens
        </Typography>
      </Box>
      <Box
        sx={{ ...itemsCenter, flexDirection: "row", width: "100px", gap: 1 }}
      >
        <Typography
          variant="medium2"
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          {formatNumberWithOrdinal(data?.rank ?? 0)}{" "}
        </Typography>
        <Typography
          variant="medium2"
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          Rank
        </Typography>
      </Box>
    </Box>
  );
}
