import { Box, Button, Dialog, DialogActions, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import { FormTextField } from "../../../const/TextField";
import { CreateBatchIcon } from "../../../images/icons";

export default function CreateBatchModal({ fetchAgain }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [batchName, setBatchName] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (loading) {
      toast.error("Please wait...");
      return;
    }
    setOpen(false);
  };

  // https://unuhdevapi.kdev.co.in/api/school/create-batch

  const handleAdd = async () => {
    try {
      if (!batchName.trim()) {
        toast.error("Name cannot be empty");
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_SCHOOL_CREATE_BATCH;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: { batchName: batchName.trim() },
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      setBatchName("");
      fetchAgain();
      handleClose();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <Box>
      <Button onClick={handleClickOpen} variant="primary">
        <CreateBatchIcon />
        Create Batch
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "#F3F5F5",
            borderRadius: "14px",
            p: "24px 12px 12px 24px",
            maxWidth: "100%",
            width: "539px",
          },
        }}
      >
        <Box>
          <Typography
            variant="medium"
            sx={{
              color: (theme) => theme.palette.text.dark,
              mb: 1,
            }}
          >
            Enter the batch name
          </Typography>
        </Box>
        <Typography
          variant="semibold2"
          sx={{
            color: (theme) => theme.palette.text.light,
            mb: 2,
          }}
        >
          Maximum 25 Characters
        </Typography>
        <FormTextField
          placeholder="batch blue"
          value={batchName}
          onChange={(e) => {
            if (e.target.value.length > 25) {
              toast.error("Maximum 25 characters allowed");
              setBatchName(e.target.value.slice(0, 25));
              return;
            }
            setBatchName(e.target.value);
          }}
        />

        <DialogActions sx={{ mt: 7 }}>
          <Button
            sx={{ height: "32px", textTransform: "none" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button disabled={loading} variant="primary" onClick={handleAdd}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
