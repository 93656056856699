import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  dFlex,
  flexSpaceBetween,
  itemsCenter,
} from "../../../common/commonStyles";

export default function ACEIndividualQuestion({ data, index }) {
  return (
    <Box
      sx={{
        my: 3,
        backgroundColor: (theme) => theme.palette.background.secondary,
        p: 3,
        borderRadius: "15px",
      }}
    >
      <Box sx={{ mb: 1, ...flexSpaceBetween, gap: 8 }}>
        <Typography
          variant="medium"
          sx={{
            flex: "80%",
            color: (theme) => theme.palette.text.dark,
          }}
        >
          {index + 1}. {data.question}
        </Typography>
        <Typography
          variant="semibold"
          sx={{
            flex: "10%",
            color: (theme) => theme.palette.text.dark,
          }}
        >
          Answers Count
        </Typography>
      </Box>
      <Box sx={{ ...dFlex, flexDirection: "column", maxWidth: "950px", pl: 3 }}>
        <Typography
          variant="medium"
          sx={{ color: (theme) => theme.palette.text.dark }}
        >
          a. {data.option[0]}
        </Typography>
        <Typography
          variant="medium"
          sx={{
            color: (theme) => theme.palette.text.dark,
            alignSelf: "center",
          }}
        >
          or
        </Typography>
        <Typography
          variant="medium"
          sx={{ color: (theme) => theme.palette.text.dark }}
        >
          b. {data.option[0]}.
        </Typography>
      </Box>
      <Box sx={{ ...dFlex, flexDirection: "column", mt: 2 }}>
        <Box sx={{ ...itemsCenter, gap: 2, my: 1 }}>
          <Box
            sx={{
              p: "9px 20px",
              flex: "90%",
              fontWeight: 500,
              borderRadius: "6px",
              color: (theme) => theme.palette.text.main,
              backgroundColor: (theme) => theme.palette.background.main,
              boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.12)",
            }}
          >
            Yes
          </Box>
          <Typography
            variant="medium"
            sx={{ flex: "10%", color: (theme) => theme.palette.text.dark }}
          >
            {data.response[1]}
          </Typography>
        </Box>
        <Box sx={{ ...itemsCenter, gap: 2, my: 1 }}>
          <Box
            sx={{
              p: "9px 20px",
              flex: "90%",
              borderRadius: "6px",
              fontWeight: 500,
              color: (theme) => theme.palette.text.main,
              backgroundColor: (theme) => theme.palette.background.main,
              boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.12)",
            }}
          >
            No
          </Box>
          <Typography
            variant="medium"
            sx={{ flex: "10%", color: (theme) => theme.palette.text.dark }}
          >
            {data.response[0]}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
