import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { itemsCenter } from "../../../common/commonStyles";
import ACEQuestionAddModal from "./ACEQuestionAddModal";
import ACEquestion from "./ACEquestion";

export default function ACEQuestionsTab({ data, fetchAgain }) {
  const [formatData, setFormatData] = useState([]);

  useEffect(() => {
    if (!data) {
      return;
    }
    let abc = data.sort((a, b) => (a?.seq > b?.seq ? 1 : -1));
    setFormatData(abc);
  }, [data]);

  return (
    <>
      <Box sx={{ ...itemsCenter, justifyContent: "flex-end", mb: 3 }}>
        <ACEQuestionAddModal
          total={data?.length ?? 0}
          fetchAgain={fetchAgain}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.secondary,
          maxHeight: "80vh",
          overflow: "scroll",
          borderRadius: "7px",
          px: 3,
        }}
      >
        {formatData?.map((item, i) => (
          <ACEquestion
            total={data?.length ?? 0}
            key={item?.id}
            data={item}
            index={i}
            fetchAgain={fetchAgain}
          />
        ))}
      </Box>
    </>
  );
}
