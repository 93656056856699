import { Box, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import { dFlex, flexCenter } from "../../../common/commonStyles";
import StudentEarnedCertificate from "./StudentEarnedCertificate";
import StudentEarnedModuleBadge from "./StudentEarnedModuleBadge";
import StudentEarnedToken from "./StudentEarnedToken";

export default function DashboardOverViewCard() {
  const [seeMore, setSeeMore] = useState(false);
  const [apiCall] = useDataFetchHook(
    process.env.REACT_APP_PUBLISHED_MODULE + "1"
  );

  // const scrollContainerRef = useRef(null);

  // const handleScrollLeft = () => {
  //   scrollContainerRef.current.scrollBy({
  //     left: -200, // Adjust the scroll amount as per your needs
  //     behavior: "smooth",
  //   });
  // };

  // const handleScrollRight = () => {
  //   scrollContainerRef.current.scrollBy({
  //     left: 200, // Adjust the scroll amount as per your needs
  //     behavior: "smooth",
  //   });
  // };

  return (
    <Box>
      <Box sx={{ ...dFlex, justifyContent: "flex-end" }}>
        {/* <Button
          sx={{ textTransform: "none", p: 0 }}
          onClick={() => {
            setSeeMore((prev) => !prev);
          }}
        >
          <Typography
            variant="semibold"
            sx={{
              color: (theme) => theme.palette.primary.main,
              ...itemsCenter,
              cursor: "pointer",
            }}
          >
            {seeMore ? <>See Less</> : <>See More</>}
            <KeyboardArrowUpIcon
              sx={{
                transition: "all 0.2s linear",
                transform: seeMore ? "" : "rotate(180deg)",
              }}
            />
          </Typography>
        </Button> */}
      </Box>
      <Box
        sx={{
          maxWidth: "100%",
          overflow: "hidden",
          flexWrap: "wrap",
          p: 1,
          ...dFlex,
          gap: 2,
          transition: "height 0.5s ease",
          // height: seeMore ? "280px" : "130px",
          height: "130px",
        }}
      >
        <StudentEarnedCertificate />
        <StudentEarnedToken />
        {apiCall?.loading && (
          <Box sx={{ ...flexCenter, width: "fit-content" }}>
            <CircularProgress />
          </Box>
        )}
        {apiCall?.res?.sequence?.map((e, i) => (
          <StudentEarnedModuleBadge key={e?.moduleId} data={e} index={i} />
        ))}
      </Box>
    </Box>
  );
}
