import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import useTablePagination from "../../../Hooks/TablePaginationHook";
import DownloadSelect from "../../../common/DownloadSelect";
import {
  dFlex,
  flexBetweenCenter,
  textEllipses,
} from "../../../common/commonStyles";
import {
  StyledTableCell,
  StyledTableRow,
  TableRowSkeletonComponent,
} from "../../../const/Table";
import { CustomDateTextField, FormTextField } from "../../../const/TextField";
import { getFormattedDate } from "../../../const/constFunctions";
import {
  ArrowDropDownIconSvg,
  ArrowDropUpIconSvg,
  FilterIcon,
} from "../../../images/icons";

export default function SchoolSubscriberTab({ type }) {
  const [schoolListApi] = useDataFetchHook(
    process.env.REACT_APP_SA_DASHBOARD_LIST +
      "/subscriber?type=school&list=" +
      type
  );

  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState("");
  const [selectOption, setSelectOption] = useState("default");
  const [selectedSortUp, setSelectedSortUp] = useState("");
  const [selectedSortDown, setSelectedSortDown] = useState("");

  const sortArr = (para) => {
    setSelectedSortUp(para);
    setSelectedSortDown("");

    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "name":
          fir = a.name.toLowerCase();
          sec = b.name.toLowerCase();
          break;
        case "studentsCount":
          fir = parseInt(a.studentsCount);
          sec = parseInt(b.studentsCount);
          break;
        case "registrationDate":
          fir = new Date(a.registrationDate);
          sec = new Date(b.registrationDate);
          break;
        case "insCount":
          fir = parseInt(a.insCount);
          sec = parseInt(b.insCount);
          break;
        case "batchCount":
          fir = parseInt(a.batchCount);
          sec = parseInt(b.batchCount);
          break;
        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir < sec) {
        return -1;
      }
      if (fir > sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  const sortArrDec = (para) => {
    setSelectedSortUp("");
    setSelectedSortDown(para);

    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "name":
          fir = a.name.toLowerCase();
          sec = b.name.toLowerCase();
          break;
        case "studentsCount":
          fir = parseInt(a.studentsCount);
          sec = parseInt(b.studentsCount);
          break;
        case "registrationDate":
          fir = new Date(a.registrationDate);
          sec = new Date(b.registrationDate);
          break;
        case "insCount":
          fir = parseInt(a.insCount);
          sec = parseInt(b.insCount);
          break;
        case "batchCount":
          fir = parseInt(a.batchCount);
          sec = parseInt(b.batchCount);
          break;
        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir > sec) {
        return -1;
      }
      if (fir < sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  const handleChange = (e) => {
    setSelectOption(e.target.value);
  };

  // Initialize table pagination
  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();
  const handleFilter = () => {
    let filteredData = schoolListApi?.res?.data ?? [];

    if (selectOption !== "default") {
      filteredData = filteredData.filter(
        (item) => item.amount === selectOption
      );
    }

    if (selectedDate) {
      const filterDate = new Date(selectedDate);
      filteredData = filteredData.filter(
        (item) =>
          new Date(item.registrationDate).toDateString() ===
          filterDate.toDateString()
      );
    }
    setSearchData(filteredData);
  };

  const clearSearch = () => {
    setSearchText("");
  };
  const handleClearFilter = () => {
    setSelectOption("default");
    setSelectedSortDown("");
    setSelectedSortUp("");
    setSelectedDate("");
    setSearchData(schoolListApi?.res?.data ?? []);
  };

  const [searchData, setSearchData] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const handleSearch = () => {
    try {
      // console.log("searhing");
      let arr = [...schoolListApi?.res?.data];
      arr = arr?.filter((e) => {
        return e?.name?.toLowerCase()?.includes(searchText.toLowerCase());
      });
      handleChangePage(null, 0);
      setSearchData(arr);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!searchText) {
      setSearchData(schoolListApi?.res?.data);
      return;
    }
    handleSearch();
  }, [searchText]);

  useEffect(() => {
    if (!schoolListApi?.res) {
      return;
    }

    setSearchData(schoolListApi?.res?.data);
    return () => {};
  }, [schoolListApi?.res]);

  const downloadCsv = () => {
    let data = searchData;
    data = data.map((e) => {
      let formattedData = {
        "Institute Name": e?.name,
        "Students Count": e?.studentsCount,
        "Facilitators Count": e?.insCount,
        "Batch Count": e?.batchCount,
        "Registration Date": getFormattedDate(e?.registrationDate),
      };
      return formattedData;
    });

    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `School_Data_${new Date().toString()}.csv`;
    link.click();
  };

  return (
    <>
      {/* Subscription Amount filters */}
      <Box>
        <Box sx={{ ...flexBetweenCenter, mb: 3 }}>
          <Box
            sx={{
              ...dFlex,
              gap: 2,
              boxSizing: "border-box",
            }}
          >
            <FormTextField
              placeholder="search"
              // sx={{ width: "400px" }}
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              disabled={schoolListApi?.loading}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      fontSize="small"
                      sx={{
                        color: "#BFBFBF",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            {/* <FormControl sx={{ width: 180 }}>
              <Select
                sx={{
                  background: "#FFF",
                  color: "#809fb8",
                  height: "38px",
                  borderRadius: "4px",
                }}
                name="selectOption"
                onChange={handleChange}
                value={selectOption}
              >
                <MenuItem value="default" disabled>
                  <Typography
                    variant="medium2"
                    sx={{
                      fontWeight: 600,
                      color: (theme) => theme.palette.text.light,
                    }}
                  >
                    Subscription Amount
                  </Typography>
                </MenuItem>
                <MenuItem value="$0-$50">
                  <Typography
                    variant="medium2"
                    sx={{
                      fontWeight: 600,
                      color: (theme) => theme.palette.text.light,
                    }}
                  >
                    $0-$50
                  </Typography>
                </MenuItem>
                <MenuItem value="$50-$100">
                  <Typography
                    variant="medium2"
                    sx={{
                      fontWeight: 600,
                      color: (theme) => theme.palette.text.light,
                    }}
                  >
                    $50-$100
                  </Typography>
                </MenuItem>
                <MenuItem value="$100-$500">
                  <Typography
                    variant="medium2"
                    sx={{
                      fontWeight: 600,
                      color: (theme) => theme.palette.text.light,
                    }}
                  >
                    $100-$500
                  </Typography>
                </MenuItem>
                <MenuItem value="$500-$1000">
                  <Typography
                    variant="medium2"
                    sx={{
                      fontWeight: 600,
                      color: (theme) => theme.palette.text.light,
                    }}
                  >
                    $500-$1000
                  </Typography>
                </MenuItem>
                <MenuItem value="$1000-$10000">
                  <Typography
                    variant="medium2"
                    sx={{
                      fontWeight: 600,
                      color: (theme) => theme.palette.text.light,
                    }}
                  >
                    $1000-$10000
                  </Typography>
                </MenuItem>
              </Select>
            </FormControl> */}
            <CustomDateTextField
              value={selectedDate}
              onChange={(e) => {
                setSearchText("");
                setSelectedDate(e.target.value);
              }}
            />
            <Box>
              <Button onClick={handleFilter} variant="primary-medium">
                {" "}
                <FilterIcon /> Filter
              </Button>
              <Button
                onClick={() => {
                  handleClearFilter();
                  clearSearch();
                }}
                sx={{
                  textTransform: "none",
                  color: (theme) => theme.palette.text.main,
                }}
              >
                {" "}
                x Clear
              </Button>
            </Box>
          </Box>
          <DownloadSelect downloadCsv={downloadCsv} />
        </Box>
      </Box>

      {/* Table */}
      <Box>
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "13px",
            pt: 1,
            px: "20px",
            boxSizing: "border-box",
            maxHeight: schoolListApi?.loading ? "400px" : "1200px",
            transition: "all 1s ease",
          }}
        >
          <Table sx={{ minWidth: 650 }}>
            {/* Table header */}
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Box sx={{ ...dFlex, gap: 1 }}>
                    <Typography
                      variant="medium"
                      sx={{ color: (theme) => theme.palette.text.dark }}
                    >
                      Institute Name
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArr("name")}
                      >
                        <ArrowDropUpIconSvg focus={selectedSortUp == "name"} />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArrDec("name")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown == "name"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ ...dFlex, gap: 1 }}>
                    {" "}
                    <Typography
                      variant="medium"
                      sx={{ color: (theme) => theme.palette.text.dark }}
                    >
                      Students Count
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArr("studentsCount")}
                      >
                        <ArrowDropUpIconSvg
                          focus={selectedSortUp == "studentsCount"}
                        />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArrDec("studentsCount")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown == "studentsCount"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledTableCell>
                {/* <StyledTableCell>
                  <Box sx={{ ...dFlex, gap: 1 }}>
                    <Typography
                      variant="medium"
                      sx={{ color: (theme) => theme.palette.text.dark }}
                    >
                      Subscription Amount
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArr("classCode")}
                      >
                        <ArrowDropUpIconSvg
                          focus={selectedSortUp == "classCode"}
                        />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArrDec("classCode")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown == "classCode"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledTableCell> */}
                <StyledTableCell>
                  <Box sx={{ ...dFlex, gap: 1 }}>
                    <Typography
                      variant="medium"
                      sx={{ color: (theme) => theme.palette.text.dark }}
                    >
                      Date
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArr("registrationDate")}
                      >
                        <ArrowDropUpIconSvg
                          focus={selectedSortUp == "registrationDate"}
                        />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArrDec("registrationDate")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown == "registrationDate"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  {" "}
                  <Box sx={{ ...dFlex, gap: 1 }}>
                    <Typography
                      variant="medium"
                      sx={{ color: (theme) => theme.palette.text.dark }}
                    >
                      Facilitators Count
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArr("insCount")}
                      >
                        <ArrowDropUpIconSvg
                          focus={selectedSortUp == "insCount"}
                        />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArrDec("insCount")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown == "insCount"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ ...dFlex, gap: 1 }}>
                    <Typography
                      variant="medium"
                      sx={{ color: (theme) => theme.palette.text.dark }}
                    >
                      Batches Count
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArr("batchCount")}
                      >
                        <ArrowDropUpIconSvg
                          focus={selectedSortUp == "batchCount"}
                        />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArrDec("batchCount")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown == "batchCount"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledTableCell>
              </TableRow>
            </TableHead>

            {/* Table body */}
            {schoolListApi?.loading ? (
              <TableRowSkeletonComponent column={6} row={4} />
            ) : (
              <TableBody>
                {searchData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow
                      onClick={() => {
                        navigate(`${row?.schoolId}`, {
                          state: {
                            data: row,
                          },
                        });
                      }}
                    >
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{
                            display: "block",
                            color: (theme) => theme.palette.text.main,
                            width: "200px",
                            ...textEllipses,
                          }}
                        >
                          {row?.userData?.name ? row?.userData.name : row.name}{" "}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row.studentsCount}
                        </Typography>
                      </StyledTableCell>
                      {/* <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row.amount ?? "NA"}
                        </Typography>
                      </StyledTableCell> */}
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {/* {row.carbs} */}
                          {getFormattedDate(row.registrationDate)}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row.insCount}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row.batchCount}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {/* Table pagination */}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            rowsPerPageOptions={[]}
            count={searchData?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </>
  );
}
