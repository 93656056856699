import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { DELETE, PATCH, POST } from "../../../Apis/constVariables";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { dFlex, flexCenter, itemsCenter } from "../../../common/commonStyles";
import { checkSize, convertToBase64File } from "../../../const/constFunctions";
import { UploadIcon } from "../../../images/icons";
import CertificateDeleteModal from "./CertificateDeleteModal";

const Certification = () => {
  const [seeMore, setSeeMore] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [certificateListApi, fetchAgain] = useDataFetchHook(
    process.env.REACT_APP_CERTIFICATE_TEMPLATE
  );

  const [certificateFile, setCertificateFile] = useState(null);
  const [dragOver, setDragOver] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleDeleteCertificateTemplate = async (id) => {
    try {
      setLoading(true);
      const url = process.env.REACT_APP_CERTIFICATE_TEMPLATE + `?tempuid=${id}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: DELETE,
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      toast(apiRes.data.message);
      fetchAgain();
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
    // setCertificateList(arr);
  };

  const handleImageChange = (event) => {
    // console.log(event.target);
    setShow(true);
    setSelectedImage(event.target.value);
  };

  // Function to handle file change
  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0];
      if (file.type !== "application/pdf") {
        toast.error("Please select a PDF file");
        return;
      }
      // Check file size
      if (checkSize(file) > 10) {
        e.target.value = "";
        toast.error("Please upload an image less than 10MB");
        return;
      }
      const base64 = await convertToBase64File(e?.target?.files?.[0]);
      console.log({ base64 });
      setCertificateFile(base64);
      // console.log(e.target.files[0]);
    }
  };

  // Function to handle drag over event
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  // Function to handle drag leave event
  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragOver(false);
  };

  // Function to handle drop event
  const handleDrop = async (e) => {
    try {
      e.preventDefault();
      setDragOver(false);
      const file = e.dataTransfer.files[0];
      if (file) {
        if (file.type !== "application/pdf") {
          toast.error("Please select a PDF file");
          return;
        }
        if (checkSize(file) > 10) {
          toast.error("Please upload an image less than 10MB");
          return;
        }
        console.log(file);
        const base64 = await convertToBase64File(file);
        console.log({ base64 });
        setCertificateFile(base64);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePdfUpload = async () => {
    if (!certificateFile) {
      toast.error("File is required");
      return;
    }
    try {
      setLoading(true);
      const url = process.env.REACT_APP_CERTIFICATE_TEMPLATE + `/pdfupload`;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          base64Image: certificateFile,
        },
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      navigate("edit", {
        state: {
          pdfFileLink: apiRes?.data?.link,
        },
      });
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  const handleChangeDefault = async () => {
    try {
      setLoading(true);
      const url =
        process.env.REACT_APP_CERTIFICATE_TEMPLATE +
        `?tempuid=${selectedImage}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchAgain();
      setShow(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (!certificateListApi?.res) {
      return;
    }
    setSelectedImage(certificateListApi?.res?.default);
  }, [certificateListApi?.res]);

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Certification"]} />
      <Box sx={{ ...itemsCenter, justifyContent: "flex-end" }}>
        <Button
          sx={{ transition: "all 300ms ease" }}
          disabled={!show}
          onClick={handleChangeDefault}
          variant="primary"
        >
          Save
        </Button>
      </Box>
      <Box sx={{ mb: 3 }}>
        <Typography
          variant="bold"
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
        >
          Previous Certificates
        </Typography>
      </Box>

      <Box style={{ padding: 0 }} sx={{ mb: 5 }}>
        {certificateListApi?.loading && (
          <Box sx={flexCenter}>
            <CircularProgress />
          </Box>
        )}
        {certificateListApi?.res?.total > 4 && (
          <Box sx={{ ...dFlex, justifyContent: "flex-end", mb: 1 }}>
            <Button
              sx={{ textTransform: "none", p: 0 }}
              onClick={() => {
                setSeeMore((prev) => !prev);
              }}
            >
              <Typography
                variant="semibold"
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  ...itemsCenter,
                  cursor: "pointer",
                }}
              >
                {seeMore ? <>See Less</> : <>See More</>}
                <KeyboardArrowUpIcon
                  sx={{
                    transition: "all 400ms linear",
                    transform: seeMore ? "" : "rotate(180deg)",
                  }}
                />
              </Typography>
            </Button>
          </Box>
        )}
        <Box
          sx={{
            flexWrap: "wrap",
            display: "flex",
            overflow: "hidden",
            transition: "all 0.3s ease",
            maxHeight: seeMore
              ? `${certificateListApi?.res?.total * 160}px`
              : "210px",
            gap: 1,
            // p: 1,
          }}
        >
          {certificateListApi?.res?.template?.map((e, index) => (
            <Box key={index} sx={{ position: "relative" }}>
              <RadioGroup
                name="selectedImage"
                value={selectedImage}
                onChange={handleImageChange}
                sx={{ flexDirection: "row", justifyContent: "center" }}
              >
                <FormControlLabel
                  // value={e}
                  control={
                    <Box
                      sx={{
                        ...itemsCenter,
                        flexDirection: "column",
                      }}
                    >
                      <Radio value={e?.id} />
                      <CertificateDeleteModal
                        handleDeleteCertificateTemplate={
                          handleDeleteCertificateTemplate
                        }
                        selectedImage={selectedImage}
                        e={e}
                      />
                    </Box>
                  }
                  sx={{
                    cursor: "default",
                    backgroundColor: "#FFF",
                    p: "16px 16px 16px 0px",
                    m: 0,
                    borderRadius: "10px",
                    alignItems: "flex-start",
                  }}
                  label={
                    <iframe
                      loading="lazy"
                      src={e?.templateUrl}
                      alt={`certi_${index}`}
                      style={{
                        cursor: "default",
                        width: "236px",
                        height: "165px",
                      }}
                    />
                  }
                />
              </RadioGroup>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          textAlign: "center",
          mb: 5,
        }}
      >
        <Typography
          variant="semibold"
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
        >
          Or
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.secondary,
          ...flexCenter,
          height: "80vh",
          borderRadius: "10px",
        }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {!certificateFile ? (
          <Box
            component="label"
            sx={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ mb: 10 }}>
              <UploadIcon />
            </Box>
            <Typography
              variant="semibold"
              sx={{
                color: "#BFBFBF",
                cursor: "pointer",
              }}
            >
              {dragOver
                ? "Drop the file here"
                : "Drag and Drop PDF or Select from device"}
            </Typography>
            <input
              onChange={onFileChange}
              hidden={true}
              accept="application/pdf"
              type="file"
              style={{ mt: "30px", width: "100%" }}
            />
          </Box>
        ) : (
          <Box sx={{ width: "100%", height: "100%", overflow: "auto" }}>
            <embed src={certificateFile} height="99%" width="100%" />
          </Box>
        )}
      </Box>
      {/* {certificateFile && ( */}
      <Box
        sx={{
          ...itemsCenter,
          mt: 4,
          justifyContent: "flex-end",
        }}
      >
        <Button sx={{ mr: 2 }} onClick={() => setCertificateFile(null)}>
          Cancel
        </Button>
        <Button variant="primary" disabled={loading} onClick={handlePdfUpload}>
          Next
        </Button>
      </Box>
      {/* )} */}
    </Box>
  );
};

export default Certification;
