export const RegistrationIllustration =
  "https://firebasestorage.googleapis.com/v0/b/dev-unuh-gcp.appspot.com/o/doNotTouch%2Fregister_illustration.png?alt=media&token=a1565977-be04-4a64-87db-bbd8713c119d";

export const BatchEmpty =
  "https://firebasestorage.googleapis.com/v0/b/dev-unuh-gcp.appspot.com/o/doNotTouch%2Fbatch_empty.png?alt=media&token=31f35635-6d62-4f2a-b8d6-91a689fbd3b8";

export const AlertIconUrl =
  "https://firebasestorage.googleapis.com/v0/b/dev-unuh-gcp.appspot.com/o/doNotTouch%2Falert_icon.png?alt=media&token=586ce634-15eb-4809-acce-1873093158ea";

export const meetingEmpty =
  "https://firebasestorage.googleapis.com/v0/b/dev-unuh-gcp.appspot.com/o/doNotTouch%2Fmeeting_empty.png?alt=media&token=e78a7b47-d75f-429c-8503-695336c98717";

export const analyticsEmpty =
  "https://firebasestorage.googleapis.com/v0/b/dev-unuh-gcp.appspot.com/o/doNotTouch%2Fanalytics_empty.png?alt=media&token=2d2d38a1-482b-49f7-89b3-0c2a8d9d53b8";

export const leaderBoardEmpty =
  "https://firebasestorage.googleapis.com/v0/b/dev-unuh-gcp.appspot.com/o/doNotTouch%2FleaderBoard_empty.png?alt=media&token=67c013a7-a5da-4a48-82b6-8701c5f12a3d";

export const subscriptionExpiry =
  "https://firebasestorage.googleapis.com/v0/b/dev-unuh-gcp.appspot.com/o/doNotTouch%2FsubscribeInactive.png?alt=media&token=d22c8579-7d93-4ead-ab93-ae6b9be84380";

export const jpgFileIcon =
  "https://firebasestorage.googleapis.com/v0/b/dev-unuh-gcp.appspot.com/o/doNotTouch%2Fjpg_file.png?alt=media&token=012fd12c-26c7-4c55-9783-32c55377c9bf";

export const docsFileIcon =
  "https://firebasestorage.googleapis.com/v0/b/dev-unuh-gcp.appspot.com/o/doNotTouch%2Fdocs_file.png?alt=media&token=892f2396-12ce-4960-9567-364a937579c7";

export const mp4FileIcon =
  "https://firebasestorage.googleapis.com/v0/b/dev-unuh-gcp.appspot.com/o/doNotTouch%2Fmp4_File.png?alt=media&token=6c4514a5-9d1e-4262-b7de-d8ac3f566d25";

export const pdfFileIcon =
  "https://firebasestorage.googleapis.com/v0/b/dev-unuh-gcp.appspot.com/o/doNotTouch%2Fpdf_file.png?alt=media&token=8c257ffb-f14f-4ca0-9152-03f50febf5b0";

export const pptFileIcon =
  "https://firebasestorage.googleapis.com/v0/b/dev-unuh-gcp.appspot.com/o/doNotTouch%2Fppt_file.png?alt=media&token=b3d0af09-243e-4f22-952c-b5ea20d5eab0";

export const txtFileIcon =
  "https://firebasestorage.googleapis.com/v0/b/dev-unuh-gcp.appspot.com/o/doNotTouch%2Ftxt_file.png?alt=media&token=df7b16af-f26b-458c-ad65-5c7ccea27fab";

export const xlxsFileIcon =
  "https://firebasestorage.googleapis.com/v0/b/dev-unuh-gcp.appspot.com/o/doNotTouch%2Fxlxs_file.png?alt=media&token=8e6403bf-ba1d-4373-9651-7b15b9e7c974";

export const zipFileIcon =
  "https://firebasestorage.googleapis.com/v0/b/dev-unuh-gcp.appspot.com/o/doNotTouch%2Fzip_file.png?alt=media&token=3a0433b5-b89b-4a41-a59e-0c209f6583e8";

export const emptyDataSA =
  "https://firebasestorage.googleapis.com/v0/b/dev-unuh-gcp.appspot.com/o/doNotTouch%2Fempty_data_SA.png?alt=media&token=321c4bea-9617-4e8f-b3a8-a5631f7f1a95";

export const noRequest =
  "https://firebasestorage.googleapis.com/v0/b/dev-unuh-gcp.appspot.com/o/doNotTouch%2Fno_request.png?alt=media&token=d6ea5e00-0022-4766-b31b-e01a890fd69b";

export const select_batch =
  "https://firebasestorage.googleapis.com/v0/b/dev-unuh-gcp.appspot.com/o/doNotTouch%2Fselect_batch.png?alt=media&token=7dbe5127-5d4e-4607-af34-c781c28cfdf9";

export const noGroups =
  "https://firebasestorage.googleapis.com/v0/b/dev-unuh-gcp.appspot.com/o/doNotTouch%2Fno_groups.png?alt=media&token=cab7386e-f67c-4099-8bde-d7a3342e1a5f";

export const projectNotSubmitted =
  "https://firebasestorage.googleapis.com/v0/b/dev-unuh-gcp.appspot.com/o/doNotTouch%2Fproject_not_submitted.png?alt=media&token=31c23c00-3e8a-45c6-b729-9d128b49575b";
