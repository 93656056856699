import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { IP, POST, ROLE_LIST } from "../../Apis/constVariables";
import PaymentFailure from "../../Components/Payment/PaymentFailure";
import PaymentSuccess from "../../Components/Payment/PaymentSuccess";
import { dFlex, flexCenter, itemsCenter } from "../../common/commonStyles";
import { FormTextField } from "../../const/TextField";
import { UserContext } from "../../context/UserContext";
import illustration from "../../images/login_illustration.png";
export default function Login() {
  const { setUserDetails } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [paymentRedirect, setPaymentRedirect] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      let abc = "";
      data.email = data.email.trim();
      data.password = data.password.trim();

      setLoading(true);
      const config = {
        method: POST,
        url: `${IP}/auth/login`,
        data: {
          ...data,
          type: 0,
        },
      };
      const apiRes = await axios(config);
      const resData = apiRes?.data;
      // localStorage.setItem("token", resData?.idToken);
      // Reset form values
      let userData = resData?.user;

      reset();
      localStorage.setItem("idToken", resData?.idToken);
      setUserDetails({ ...resData?.user, idToken: resData?.idToken });
      switch (resData?.user?.role) {
        case ROLE_LIST.SUPER_ADMIN:
          navigate("/admin/dashboard", { replace: true });
          break;
        case ROLE_LIST.CONTENT_UPLOADER:
          if (userData.isArchived) {
            setError("password", {
              message: "User access is disabled",
            });
            setUserDetails(null);
            localStorage.clear();
            break;
          }
          navigate("/uploader/dashboard", { replace: true });
          break;
        case ROLE_LIST.SCHOOL_ADMIN:
          if (userData.isArchived) {
            navigate("/school/settings", { replace: true });
            break;
          }
          navigate("/school/dashboard", { replace: true });
          break;
        case ROLE_LIST.FACILITATOR:
          if (userData.isArchived) {
            navigate("/facilitator/student-progress", { replace: true });
            break;
          }
          navigate("/facilitator/dashboard", { replace: true });
          break;
        case ROLE_LIST.PARENT_ADMIN:
          if (userData.isArchived) {
            navigate("/parent/settings", { replace: true });
            break;
          }
          navigate("/parent/dashboard", { replace: true });
          break;
        default:
          break;
      }
      // Navigate to the admin dashboard
    } catch (error) {
      console.log(error?.response?.data);
      setLoading(false);
      setError("password", {
        message: error?.response?.data?.message ?? "Something went wrong",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    let paymentDetails = {};
    searchParams.forEach((val, key) => {
      paymentDetails[key] = val;
    });
    setPaymentRedirect(paymentDetails);
    return () => {};
  }, []);
  // console.log({ paymentRedirect });
  return (
    <>
      <Grid
        container
        style={{
          height: "100vh",
          overflow: "hidden",
        }}
      >
        {/* Left Section */}
        <Grid item xs={12} sm={6} sx={{ display: { xs: "none", sm: "block" } }}>
          <Box
            sx={{
              textAlign: "center",
              height: "100%",
              p: "40px 60px",
              background: (theme) => theme.palette.primary.light,
            }}
          >
            <Typography
              sx={{
                fontSize: "30px",
                color: "white",
                // mb: 12,
              }}
            >
              Welcome!
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "white",
                  mb: 12,
                }}
              >
                Enter the required information to access the UNUH Portal{" "}
              </Typography>
            </Typography>
            <img
              height="436px"
              style={{
                objectFit: "contain",
              }}
              src={illustration}
              alt="illustration"
            />
          </Box>
        </Grid>
        {/* Right Section */}
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            background: (theme) => theme.palette.background.main,
          }}
        >
          <Typography
            variant="semibold"
            onClick={() => {
              window.location.href =
                "https://unuh.limitlesstransformationja.com/";
            }}
            sx={{
              position: "absolute",
              top: 30,
              right: 40,
              color: (theme) => theme.palette.primary.main,
              mb: 3,
              textDecoration: "underline",
              cursor: "pointer",
              ...itemsCenter,
              gap: 0.1,
            }}
          >
            Go to Home Page <ArrowRightAltIcon />
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Box sx={{ width: "80%" }}>
              <Box sx={{ mb: "44px" }}>
                <Typography
                  variant="bold"
                  sx={{
                    fontSize: "24px",
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  Login
                </Typography>
              </Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ mb: 5 }}>
                  <Box sx={{ mb: 4, ...dFlex, flexDirection: "column" }}>
                    <Typography
                      variant="bold2"
                      sx={{
                        color: (theme) => theme.palette.text.main,
                        mb: 1,
                      }}
                    >
                      E-mail ID*
                    </Typography>
                    <FormTextField
                      fullWidth
                      autoFocus
                      placeholder="email@domain.com"
                      inputProps={{
                        style: {
                          textTransform: "lowercase",
                        },
                      }}
                      {...register("email", {
                        required: "This field is mandatory",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid Email Address",
                        },
                      })}
                      error={Boolean(errors?.email)}
                    />
                    {Boolean(errors?.email) && (
                      <FormHelperText error={true}>
                        <Typography variant="semibold2">
                          {errors?.email?.message}
                        </Typography>
                      </FormHelperText>
                    )}
                  </Box>
                  <Box sx={{ ...dFlex, flexDirection: "column" }}>
                    <Typography
                      variant="bold2"
                      sx={{
                        mb: 1,
                        color: (theme) => theme.palette.text.main,
                      }}
                    >
                      Password*
                    </Typography>
                    <FormTextField
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      placeholder="*********"
                      {...register("password", {
                        required: "This field is mandatory",
                      })}
                      error={Boolean(errors?.password)}
                      InputProps={{
                        inputProps: {
                          style: {
                            textTransform: "none",
                          },
                        },
                        endAdornment: (
                          <IconButton
                            tabIndex="-1"
                            onClick={() => {
                              setShowPassword((p) => !p);
                            }}
                          >
                            {showPassword ? (
                              <VisibilityOffIcon fontSize="small" />
                            ) : (
                              <VisibilityIcon fontSize="small" />
                            )}
                          </IconButton>
                        ),
                      }}
                    />
                    {Boolean(errors?.password) && (
                      <FormHelperText error={true}>
                        <Typography variant="semibold2">
                          {errors?.password?.message}
                        </Typography>
                      </FormHelperText>
                    )}
                  </Box>
                  {errors?.apiError && (
                    <FormHelperText error={true}>
                      <Typography variant="semibold2">
                        {errors?.apiError?.message}
                      </Typography>
                    </FormHelperText>
                  )}
                  <Box
                    onClick={() => navigate("/forgot-password")}
                    sx={{
                      mt: 1,
                      textAlign: "end",
                      cursor: "pointer",
                      color: (theme) => theme.palette.primary.main,
                    }}
                  >
                    <Typography
                      variant="medium2"
                      sx={{
                        "&:hover": { textDecoration: "underline" },
                      }}
                    >
                      Forgot Password?
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ ...flexCenter }}>
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={loading}
                    sx={{
                      p: "9px 24px",
                    }}
                  >
                    {loading ? (
                      <>
                        <CircularProgress />
                      </>
                    ) : (
                      <>Login</>
                    )}
                  </Button>
                </Box>
              </form>
              <Box sx={{ mt: "22px", textAlign: "center" }}>
                <Typography
                  variant="semibold"
                  sx={{
                    textAlign: "center",
                    color: (theme) => theme.palette.primary.main,
                  }}
                >
                  Not Registered?{" "}
                  <Typography
                    onClick={() => {
                      navigate("/register");
                    }}
                    variant="bold2"
                    sx={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Register here{" "}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {paymentRedirect?.status == "success" && (
        <PaymentSuccess data={paymentRedirect} />
      )}
      {paymentRedirect?.status == "failed" && (
        <PaymentFailure data={paymentRedirect} />
      )}
    </>
  );
}
