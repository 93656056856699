import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../Apis/axiosPayloads";
import { PATCH, POST } from "../../Apis/constVariables";
import useDataFetchHook from "../../Hooks/DataFetchHook";
import {
  flexBetweenCenter,
  flexCenter,
  itemsCenter,
} from "../../common/commonStyles";
import { FormTextField } from "../../const/TextField";
import {
  AccordionTransition,
  getFormattedDate,
} from "../../const/constFunctions";
import { success } from "../../const/variables";
import { AddIcon, EditIconColor } from "../../images/icons";
import Course from "./Course";
import CreateGamePrePost from "./CreateGamePrePost";
import CreateLesson from "./CreateLesson";
import CreateQuizPrePost from "./CreateQuizPrePost";
import LessonDeleteModal from "./DeleteModals/LessonDeleteModal";
import { ExpandMoreSvg, SvgVector } from "./icons";
import "./styles/CreateCourse.css";

const initialInfo = {
  lessonName: "",
  lessonDescription: "",
};

const CreateCourse = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();
  const location = useLocation();
  const courseBasicInfo = location.state?.courseData;
  const view = location.state?.see;
  const [lessonLoading, setLessonLoading] = useState(false);
  const [editCourseInfo, setEditCourseInfo] = useState(null);
  const [courseLoading, setCourseLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [lessonUrl, setLessonUrl] = useState("");
  const [isCourseIdSet, setIsCourseIdSet] = useState("");
  const [formOpen, setFormOpen] = useState(false);
  const moduleQuiz =
    isCourseIdSet && process.env.REACT_APP_MODULE + `/${isCourseIdSet}`;
  const [prePostApiCall, fetchPrePostAgain] = useDataFetchHook(moduleQuiz);
  const [lessonApiCall, fetchLessonAgain, lessonDispatch] = useDataFetchHook(
    lessonUrl
  );
  useEffect(() => {
    if (!courseBasicInfo) return;
    setCourseLoading(true);
    const formattedData = {
      moduleId: courseBasicInfo?.moduleId,
      moduleName: courseBasicInfo?.moduleName,
      moduleDescription: courseBasicInfo?.moduleDescription,
      timeInHours: courseBasicInfo?.timeInHours,
      timeInMinutes: courseBasicInfo?.timeInMinutes,
      tokens: courseBasicInfo?.tokens,
      badge: courseBasicInfo?.badge ?? null,
      created: getFormattedDate(courseBasicInfo?.created),
      lastUpdate: getFormattedDate(courseBasicInfo?.lastUpdate),
    };
    const url =
      process.env.REACT_APP_ALL_LESSON +
      `?moduleId=${courseBasicInfo?.moduleId}`;
    setLessonUrl(url);
    setEditCourseInfo(formattedData);
    setCourseLoading(false);
  }, []);

  useEffect(() => {
    if (isCourseIdSet && !lessonUrl) {
      const url =
        process.env.REACT_APP_ALL_LESSON + `?moduleId=${isCourseIdSet}`;
      setLessonUrl(url);
    }
  }, [isCourseIdSet]);

  const addLesson = async (data) => {
    try {
      let nsn = data?.lessonName.replaceAll(" ", "");
      let dat = /\//i.test(nsn);
      if (dat) {
        toast.error("You cannot use (/) characters");
        return;
      }
      const url = process.env.REACT_APP_LESSON;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...data,
          lessonName: data?.lessonName?.trim(),
          lessonDescription: data?.lessonDescription?.trim(),
          moduleAssociated: isCourseIdSet,
        },
      });
      setLessonLoading(true);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      setLessonLoading(false);
      fetchLessonAgain();
      toggle();
    } catch (err) {
      setLessonLoading(false);
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
  };

  const updateLesson = async (data) => {
    try {
      let nsn = data?.lessonName.replaceAll(" ", "");
      if (!nsn) {
        toast.error("Lesson name cannot be empty");
        return;
      }
      let dat = /\//i.test(nsn);
      if (dat) {
        toast.error("You cannot use (/) characters");
        return;
      }
      const url = process.env.REACT_APP_LESSON + `/${data?.lessonId}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: {
          ...data,
          lessonName: data?.lessonName?.trim(),
          lessonDescription: data?.lessonDescription?.trim(),
        },
      });
      setLessonLoading(true);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      setLessonLoading(false);
      fetchLessonAgain();
      toggle();
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      setLessonLoading(false);
    }
  };

  const toggle = () => {
    reset(initialInfo);
    setFormOpen((prev) => !prev);
  };

  const handleOnDragEnd = async (result) => {
    try {
      if (!result.destination) return;
      const items = Array.from(lessonApiCall?.res?.lessons);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setLessonLoading(true);
      const url = process.env.REACT_APP_MODULE + `/addCourseLessonOrder`;
      const formattedData = {
        moduleId: isCourseIdSet,
        lessons: items.map((e) => e.lessonId),
      };
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: formattedData,
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      lessonDispatch({ type: success, data: { lessons: items } });
      setLessonLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      setLessonLoading(false);
    }
  };

  const edit = (lesson) => {
    toggle();
    let lessonInfo = {
      lessonId: lesson.lessonId,
      lessonName: lesson.lessonName,
      lessonDescription: lesson.lessonDescription,
    };
    reset(lessonInfo);
    setEditable(true);
  };

  return (
    <>
      <section>
        <Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Course
              editCourseInfo={editCourseInfo}
              setIsCourseIdSet={setIsCourseIdSet}
              view={view}
              courseLoading={courseLoading}
              setCourseLoading={setCourseLoading}
            />
            {isCourseIdSet && (
              <Box>
                <Button
                  variant="primary"
                  sx={{ my: 2 }}
                  onClick={() => {
                    setEditable(false);
                    reset(initialInfo);
                    toggle();
                  }}
                >
                  <AddIcon /> Add Lesson
                </Button>

                {formOpen && (
                  <Box
                    sx={{ borderRadius: "13px", p: 3 }}
                    id="lessonForm"
                    className="lessonForm-container"
                  >
                    <Typography
                      variant="bold"
                      sx={{ color: (theme) => theme.palette.primary.main }}
                      className="lessonForm-heading"
                    >
                      Lesson
                    </Typography>

                    <Box className="lessonForm-row">
                      <Typography className="lessonForm-inputLabel">
                        Lesson Name *
                      </Typography>
                      <FormTextField
                        {...register("lessonName", {
                          required: "Lesson Name is required",
                        })}
                        autoFocus
                        variant="outlined"
                        error={!!errors.lessonName}
                        helperText={errors?.lessonName?.message}
                      />
                    </Box>

                    <Box className="lessonForm-row">
                      <Typography className="lessonForm-inputLabel">
                        Short Description *
                      </Typography>
                      <FormTextField
                        {...register("lessonDescription", {
                          required: "Lesson Description is required",
                        })}
                        variant="outlined"
                        error={!!errors.lessonDescription}
                        helperText={errors?.lessonDescription?.message}
                      />
                    </Box>
                    <Box
                      sx={{
                        ...itemsCenter,
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        sx={{ textTransform: "none" }}
                        onClick={() => {
                          setEditable(false);
                          toggle();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary-small"
                        disabled={lessonLoading}
                        onClick={handleSubmit(
                          editable ? updateLesson : addLesson
                        )}
                      >
                        {editable ? "Update" : "Save"}
                      </Button>
                    </Box>
                  </Box>
                )}

                {(lessonLoading || lessonApiCall?.loading) && (
                  <Box className="lessonList-container">
                    <Box className="lessonForm-heading">Lesson</Box>
                    <Box sx={{ ...flexCenter }}>
                      <CircularProgress />
                    </Box>
                  </Box>
                )}

                <Box className="lessonList-container">
                  <Box className="lessonForm-heading">Lesson</Box>
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="lessonList">
                      {(provided) => (
                        <ul
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            display: "block",
                            overflow: "hidden",
                            width: "100%",
                            listStyleType: "none",
                            margin: "0px",
                            padding: "0px",
                          }}
                          id="lessonList"
                        >
                          {lessonApiCall?.res?.lessons?.map((item, index) => (
                            <Draggable
                              key={item.lessonId}
                              draggableId={item.lessonId}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  key={item.lessonId}
                                  className="lessonList-draggable"
                                >
                                  <Accordion
                                    TransitionProps={AccordionTransition}
                                  >
                                    <AccordionSummary
                                      {...provided.dragHandleProps}
                                      sx={{
                                        backgroundColor: (theme) =>
                                          theme.palette.content.lesson,
                                        color: "white",
                                        height: "40px",
                                        borderRadius: "7px",
                                      }}
                                      expandIcon={<ExpandMoreSvg />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Box
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <SvgVector />
                                        <Box className="lessonList-accordion-heading">
                                          {item.lessonName}
                                        </Box>
                                      </Box>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      sx={{ borderRadius: "2px" }}
                                      // isDragDisabled={true}
                                    >
                                      <Box sx={{ ...flexBetweenCenter, mb: 1 }}>
                                        <Typography
                                          variant="medium"
                                          sx={{
                                            color: (theme) =>
                                              theme.palette.text.main,
                                          }}
                                        >
                                          {item.lessonDescription}
                                        </Typography>
                                        <Box
                                          sx={{
                                            ...itemsCenter,
                                            mr: 1,
                                          }}
                                        >
                                          <IconButton
                                            sx={{ p: 0.5 }}
                                            onClick={() => edit(item)}
                                          >
                                            <EditIconColor />
                                            {""}
                                          </IconButton>
                                          <LessonDeleteModal
                                            fetchLessonAgain={fetchLessonAgain}
                                            data={item}
                                          />
                                        </Box>
                                      </Box>
                                      <CreateLesson
                                        quizData={item.quizData}
                                        itemsData={item.itemsData}
                                        topicsData={item.topicsData}
                                        moduleId={isCourseIdSet}
                                        lessonId={item.lessonId}
                                      />
                                    </AccordionDetails>
                                  </Accordion>
                                </li>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Box>
                <CreateQuizPrePost
                  text={"Pre Test"}
                  moduleId={isCourseIdSet}
                  prePostApiCall={prePostApiCall}
                  fetchPrePostAgain={fetchPrePostAgain}
                  type={"Prequiz"}
                  quizData={prePostApiCall?.res?.prequiz ?? null}
                />
                <CreateQuizPrePost
                  text={"Post Test"}
                  moduleId={isCourseIdSet}
                  prePostApiCall={prePostApiCall}
                  fetchPrePostAgain={fetchPrePostAgain}
                  type={"Postquiz"}
                  quizData={prePostApiCall?.res?.postquiz ?? null}
                />
                <CreateGamePrePost
                  text={"Pre Game"}
                  moduleId={isCourseIdSet}
                  type={"Pregame"}
                  prePostApiCall={prePostApiCall}
                  fetchPrePostAgain={fetchPrePostAgain}
                  quizData={prePostApiCall?.res?.pregame ?? null}
                  // disabled={true}
                />
                <CreateGamePrePost
                  text={"Post Game"}
                  moduleId={isCourseIdSet}
                  prePostApiCall={prePostApiCall}
                  fetchPrePostAgain={fetchPrePostAgain}
                  quizData={prePostApiCall?.res?.postgame ?? null}
                  type={"Postgame"}
                  // disabled={true}
                />
              </Box>
            )}
          </Box>
        </Box>
      </section>
    </>
  );
};

export default CreateCourse;
