import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import UserManualCard from "../../../Components/Facilitator/UserManual/UserManualCard";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import { flexCenter, itemsCenter } from "../../../common/commonStyles";

export default function UserManual({ type }) {
  const url =
    process.env.REACT_APP_PUBLISHED_INTRO_MESSAGE +
    `?type=${type}&published=${1}`;
  const [apiCall] = useDataFetchHook(url);
  const navigate = useNavigate();

  // console.log({ slides: apiCall?.res });

  return (
    <Box>
      {/* <NavBreadCrumbs pageTags={["User Manual"]} /> */}

      <Typography
        variant="medium"
        sx={{
          ...itemsCenter,
          cursor: "pointer",
          "&:hover": { textDecoration: "underline" },
          color: (theme) => theme.palette.text.main,
        }}
        onClick={() => {
          navigate(-1);
        }}
      >
        <NavigateBeforeIcon />
        Go back
      </Typography>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "20px",
          color: (theme) => theme.palette.text.dark,
          textAlign: "center",
          mb: 2,
        }}
      >
        User Manual
      </Typography>
      {apiCall?.loading && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />
        </Box>
      )}

      <Box>
        {apiCall?.res?.slides?.map((item, indx) => (
          <UserManualCard data={item} index={indx} />
        ))}
      </Box>
    </Box>
  );
}
