import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import React from "react";
import { AccordionTransition } from "../../../const/constFunctions";
import DeleteAccountModal from "./DeleteAccountModal ";

export default function ManageAccount() {
  return (
    <Box id="change-admin" sx={{ my: 2 }}>
      <Accordion
        TransitionProps={AccordionTransition}
        sx={{ boxShadow: "none" }}
      >
        <AccordionSummary
          sx={{
            backgroundColor: (theme) => theme.palette.background.secondary,
            boxShadow: "0px 2px 4px 0px #0000001F",
            borderRadius: "5px",
          }}
          expandIcon={
            <KeyboardArrowDownIcon
              sx={{
                color: (theme) => theme.palette.primary.main,
                fontSize: "2rem",
              }}
            />
          }
          id="panel1a-header"
        >
          <Typography
            variant="bold"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            Manage Account
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            borderRadius: "7px",
            // ...dFlex,
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ mt: 2, marginLeft: "8px" }}>
            <Typography
              variant="bold2"
              sx={{ color: (theme) => theme.palette.text.main }}
            >
              Delete Account
            </Typography>

            <Typography sx={{ color: (theme) => theme.palette.text.main }}>
              Caution! If you delete this account, a request will be sent to the
              admin to delete your account. Once the admin approves the request,
              you will permanently remove access to any of the data for
              registered students associated with your existing account.
            </Typography>
          </Box>
          <DeleteAccountModal />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
