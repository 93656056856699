import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../../Apis/axiosPayloads";
import { PATCH, POST } from "../../../../Apis/constVariables";
import { dFlex } from "../../../../common/commonStyles";
import { FormTextField } from "../../../../const/TextField";
import {
  checkSize,
  convertToBase64File,
} from "../../../../const/constFunctions";
import { AddProfileIcon, EditIconColor } from "../../../../images/icons";

const initData = {
  password: "",
  name: "",
  email: "",
  address2: "",
  phone: "",
  address1: "",
  imageUrl: null,
  joiningDate: "",
};
export default function AddContentUploaderModal({
  edit,
  data,
  fetchUploaderAgain,
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = async (e, i) => {
    try {
      if (e?.target?.files.length > 0) {
        let file = e.target.files[0];
        if (!file.type.includes("image")) {
          e.target.value = "";
          toast.error("Please upload an image file");
          return;
        }
        if (checkSize(file) > 10) {
          e.target.value = "";
          toast.error("Please upload an image less than 10MB");
          return;
        }
        const base64 = await convertToBase64File(file);
        setSelectedImage(base64);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleOpen = () => {
    reset(data ?? initData);
    setSelectedImage(data?.imageUrl ?? null);
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setSelectedImage(null);
    setOpen(false);
  };

  const handleAdd = async (data) => {
    try {
      setLoading(true);
      const url = "auth/content/register-uploader";
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: { ...data, phone: `+${data.phone}`, imageUrl: selectedImage },
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchUploaderAgain();
      handleClose();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };
  const handleEdit = async (data) => {
    try {
      if (!data?.id) {
        toast.error("Something went wrong");
        handleClose();
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_CONTENT_UPLAODER + `/${data?.id}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: { ...data, imageUrl: selectedImage },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchUploaderAgain();
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <>
      {edit ? (
        <IconButton onClick={handleOpen}>
          <EditIconColor />
        </IconButton>
      ) : (
        <Box sx={{ ...dFlex, justifyContent: "flex-end", mb: 3 }}>
          <Button variant="primary" onClick={handleOpen}>
            <AddProfileIcon />
            Add New Profile
          </Button>
        </Box>
      )}
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              backgroundColor: (theme) => theme.palette.background.main,
              borderRadius: "14px",
              px: 3,
              py: 1,
              maxWidth: "100%",
              width: "55%",
            },
          }}
        >
          {/* <form onSubmit={handleDeleteAdmission} autoComplete="off"> */}
          <DialogContent>
            <Box sx={{ ...dFlex, gap: 4 }}>
              <DialogContentText>
                <div style={{ position: "relative", display: "inline-block" }}>
                  <Avatar
                    src={selectedImage}
                    sx={{
                      height: "250px",
                      width: "250px",
                    }}
                  />

                  <IconButton
                    sx={{
                      position: "absolute",
                      bottom: "15px",
                      right: "15px",
                      borderRadius: "50%",
                      border: "5px solid #F3F5F5",
                      backgroundColor: (theme) => theme.palette.primary.main,
                      "&:hover": {
                        backgroundColor: (theme) => theme.palette.primary.main,
                      },
                    }}
                    component="label"
                  >
                    {selectedImage ? (
                      <EditIcon
                        fontSize="medium"
                        sx={{
                          color: (theme) => theme.palette.background.secondary,
                        }}
                      />
                    ) : (
                      <AddIcon
                        fontSize="medium"
                        sx={{
                          color: (theme) => theme.palette.background.secondary,
                        }}
                      />
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                  </IconButton>
                </div>
              </DialogContentText>
              <DialogContentText sx={{ width: "60%" }}>
                <Box sx={{ mb: 2, ...dFlex, flexDirection: "column" }}>
                  <Typography
                    variant="medium"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      mb: 1,
                    }}
                  >
                    Name*
                  </Typography>
                  <FormTextField
                    fullWidth
                    placeholder="John Doe"
                    {...register("name", {
                      required: "This field is mandatory",
                    })}
                    error={Boolean(errors?.name)}
                  />
                  {Boolean(errors?.name) && (
                    <FormHelperText error={true}>
                      <Typography variant="semibold2">
                        {errors?.name?.message}
                      </Typography>
                    </FormHelperText>
                  )}
                </Box>
                <Box sx={{ mb: 2, ...dFlex, flexDirection: "column" }}>
                  <Typography
                    variant="medium"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      mb: 1,
                    }}
                  >
                    E-mail*
                  </Typography>
                  <FormTextField
                    fullWidth
                    disabled={edit}
                    inputProps={{
                      style: {
                        textTransform: "lowercase",
                      },
                    }}
                    type="email"
                    placeholder="email@domain.com"
                    {...register("email", {
                      required: "This field is mandatory",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid Email Address",
                      },
                    })}
                    error={Boolean(errors?.email)}
                  />
                  {Boolean(errors?.email) && (
                    <FormHelperText error={true}>
                      <Typography variant="semibold2">
                        {errors?.email?.message}
                      </Typography>
                    </FormHelperText>
                  )}
                </Box>
                <Box sx={{ mb: 2, ...dFlex, flexDirection: "column" }}>
                  <Typography
                    variant="medium"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      mb: 1,
                    }}
                  >
                    Phone*
                  </Typography>
                  <FormTextField
                    fullWidth
                    type="number"
                    inputProps={{ inputMode: "decimal" }}
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                        display: "none",
                      },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <Typography
                          position="start"
                          variant="bold2"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          +
                        </Typography>
                      ),
                    }}
                    placeholder="+19999999999"
                    {...register("phone", {
                      required: "This field is mandatory",
                      pattern: {
                        value: /^\d+$/,
                        message: "Phone number must start with a plus sign (+)",
                      },
                    })}
                    error={Boolean(errors?.phone)}
                  />
                  {Boolean(errors?.phone) && (
                    <FormHelperText error={true}>
                      <Typography variant="semibold2">
                        {errors?.phone?.message}
                      </Typography>
                    </FormHelperText>
                  )}
                </Box>
                <Box sx={{ mb: 2, ...dFlex, flexDirection: "column" }}>
                  <Typography
                    variant="medium"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      mb: 1,
                    }}
                  >
                    Address Line 1*
                  </Typography>
                  <FormTextField
                    fullWidth
                    placeholder="Address Line 1"
                    {...register("address1", {
                      required: "This field is mandatory",
                    })}
                    error={Boolean(errors?.address1)}
                  />
                  {Boolean(errors?.address1) && (
                    <FormHelperText error={true}>
                      <Typography variant="semibold2">
                        {errors?.address1?.message}
                      </Typography>
                    </FormHelperText>
                  )}
                </Box>
                <Box sx={{ mb: 2, ...dFlex, flexDirection: "column" }}>
                  <Typography
                    variant="medium"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      mb: 1,
                    }}
                  >
                    Address Line 2
                  </Typography>
                  <FormTextField
                    fullWidth
                    placeholder="Address Line 2"
                    {...register("address2")}
                    error={Boolean(errors?.address2)}
                  />
                  {Boolean(errors?.address2) && (
                    <FormHelperText error={true}>
                      <Typography variant="semibold2">
                        {errors?.address2?.message}
                      </Typography>
                    </FormHelperText>
                  )}
                </Box>
                <Box sx={{ mb: 2, ...dFlex, flexDirection: "column" }}>
                  <Typography
                    variant="medium"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      mb: 1,
                    }}
                  >
                    Joining Date*
                  </Typography>
                  <TextField
                    fullWidth
                    InputProps={{
                      sx: {
                        background: "#FFF",
                        color: "#959595",
                        height: "38px",
                        fontSize: "14px",
                        borderRadius: "4px",
                      },
                    }}
                    sx={{
                      background: "#FFF",
                      height: "38px",
                      borderRadius: "4px",
                    }}
                    type="date"
                    {...register("joiningDate", {
                      required: "This field is mandatory",
                    })}
                    error={Boolean(errors?.joiningDate)}
                  />
                  {Boolean(errors?.joiningDate) && (
                    <FormHelperText error={true}>
                      <Typography variant="semibold2">
                        {errors?.joiningDate?.message}
                      </Typography>
                    </FormHelperText>
                  )}
                </Box>
              </DialogContentText>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(edit ? handleEdit : handleAdd)}
              disabled={loading}
              variant="primary"
            >
              Save{" "}
            </Button>
          </DialogActions>
          {/* </form> */}
        </Dialog>
      )}
    </>
  );
}
