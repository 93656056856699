import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { flexCenter } from "../../../common/commonStyles";
import { noRequest } from "../../../const/imageUrl";
import RequestCard, {
  RequestVerificationModal,
} from "../../Facilitator/CommunityProject/RequestCard";

const arr = new Array(10).fill(1);

export default function ParentRequestComponent({ apiData, fetchAll }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const handleClose = () => {
    setOpen(false);
    setData(null);
  };
  const handleOpen = (data) => {
    setData(data);
    setOpen(true);
  };

  return (
    <Box
      sx={{
        width: "50%",
      }}
    >
      <Typography
        variant="semibold"
        sx={{ color: (theme) => theme.palette.primary.main }}
      >
        Requests
      </Typography>
      <Box
        sx={{
          mt: 1,
          p: 2,
          borderRadius: "13px",
          height: "65vh",
          backgroundColor: (theme) => theme.palette.background.secondary,
          overflow: "auto",
        }}
      >
        {apiData.loading && (
          <Box sx={flexCenter}>
            <CircularProgress />
          </Box>
        )}

        {apiData?.res?.data?.length < 1 && (
          <Box
            sx={{
              mt: 3,
              ...flexCenter,
              flexDirection: "column",
              py: 10,
              gap: 2,
            }}
          >
            <img src={noRequest} alt="no request" />
            <Typography
              variant="bold"
              sx={{
                color: (theme) => theme.palette.text.light,
              }}
            >
              No Request
            </Typography>
          </Box>
        )}
        {apiData?.res?.data?.map((e, i) => (
          <RequestCard
            // data={{ studentName: "Student Name " + ++i }}
            data={e}
            handleOpen={handleOpen}
            key={i}
          />
        ))}
      </Box>
      <RequestVerificationModal
        fetchAgain={fetchAll}
        data={data}
        open={open}
        handleClose={handleClose}
      />
    </Box>
  );
}
