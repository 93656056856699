import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { flexBetweenWithItemsCenter } from "../../../common/commonStyles";

export default function BrawtaDetailCard({ data, index, studentData }) {
  const navigate = useNavigate();
  return (
    <Box
      onClick={() =>
        navigate(`${data?.id}`, {
          state: {
            brawtaData: data,
            studentData: studentData,
          },
        })
      }
      sx={{
        ...flexBetweenWithItemsCenter,
        gap: 2,
        background: (theme) => theme.palette.background.secondary,
        pl: 2,
        pr: 1,
        py: 1,
        width: "200px",
        borderRadius: "8px",
      }}
    >
      <Typography
        variant="bold2"
        sx={{ color: (theme) => theme.palette.text.main }}
      >
        {data?.brawtaName}
      </Typography>
      <IconButton sx={{ p: 0 }}>
        {" "}
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
}
