import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { PATCH, POST } from "../../../Apis/constVariables";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import {
  dFlex,
  flexBetweenWithItemsCenter,
  flexCenter,
} from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import {
  checkSize,
  convertToBase64File,
  reactSelectTheme,
} from "../../../const/constFunctions";
import sendNotification from "../../../functions/PushNotification";

export default function FacilitatorAddStudent({ edit }) {
  const navigate = useNavigate();
  const [batches, setBatches] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const url = edit
    ? null
    : process.env.REACT_APP_SCHOOL_FACILITATOR +
      `/batchlist?filterCompleted=true`;
  const [selectedBatch, setSelectedBatch] = useState(null);
  const url2 = process.env.REACT_APP_REGISTER_LIMIT;

  const [registerApiCall] = useDataFetchHook(url2);
  const [apiData] = useDataFetchHook(url);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageChange = async (e) => {
    try {
      if (e?.target?.files.length > 0) {
        let file = e.target.files[0];
        if (checkSize(file) > 10) {
          e.target.value = "";
          toast.error("Please upload an image less than 10MB");
          return;
        }
        let base64 = convertToBase64File(file);
        base64 = await base64;
        console.log({ trye: file.type });
        setSelectedImage({ file, base64: base64 });
      } else
        setSelectedImage({
          file: null,
          base64: null,
        });
    } catch (err) {
      console.log(err);
      e.target.value = "";
      toast.error("Something went wrong, Please try again");
    }
  };

  useEffect(() => {
    if (edit) {
      const { studentInfo } = location?.state;
      setSelectedImage({
        file: studentInfo.image,
        base64: null,
      });
      const formatedData = {
        phone: studentInfo?.phone,
        studentName: studentInfo?.studentName,
        email: studentInfo?.email,
        id: studentInfo?.id,
      };
      reset(formatedData);
    }
  }, [location.state]);

  const onSubmit = async (data) => {
    try {
      if (registerApiCall?.res?.studentCount >= registerApiCall?.res?.total) {
        toast.error("Student registration limit reached");
        return;
      }
      if (!data?.studentName.trim()) {
        toast.error("Student name cannot be blank");
        return;
      }
      if (!data?.email && !data?.phone) {
        toast.error("Email or Phone is required");
        return;
      }
      if (!selectedBatch) {
        toast.error("Batch is required");
        return;
      }
      // console.log(data);
      // console.log({ selectedBatch });
      setLoading(true);
      const url = process.env.REACT_APP_SCHOOL_CREATE_STUDENT;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...data,
          batchName: selectedBatch?.value?.trim(),
          image: selectedImage?.base64,
        },
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      setLoading(false);
      if (!edit) {
        navigate("risk-profiling?studentId=" + apiRes?.data?.id);
      } else {
        navigate(-1);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log({ error });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!apiData?.res) {
      return;
    }
    setBatches([]);
    let batchValues = apiData?.res?.batch?.map((e) => {
      return { label: e.batchName, value: e.batchName };
    });
    setBatches(batchValues);
    return () => {
      setBatches([]);
    };
  }, [apiData?.res]);

  const handleEdit = async (data) => {
    try {
      if (!data?.studentName.trim()) {
        toast.error("Student name cannot be blank");
        return;
      }
      if (!data?.email && !data?.phone) {
        toast.error("Email or Phone is required");
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_SCHOOL_STUDENT + `/${data?.id}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: {
          ...data,
          image: selectedImage?.base64
            ? selectedImage.base64
            : selectedImage.file,
        },
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      if (apiRes.data?.detailsUpdated) {
        sendNotification({
          deviceToken: apiRes.data?.fcmToken ?? null,
          title: "Profile Information Updated",
          body: "Your profile information has been updated please login again.",
          type: "profile_update",
          userId: data?.id,
        });
      }
      setLoading(false);
      navigate(-1);
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <Box>
      <NavBreadCrumbs
        pageTags={["Manage Student", edit ? "Edit Student" : "Add Student"]}
      />
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.secondary,
          borderRadius: "20px",
          minHeight: "70vh",
          p: 3,
        }}
      >
        <Box sx={{ ...flexBetweenWithItemsCenter }}>
          <Typography
            variant="medium"
            sx={{ color: (theme) => theme.palette.text.main }}
          >
            {!edit &&
              `Student ${registerApiCall?.res?.studentCount}/${registerApiCall?.res?.total}`}{" "}
          </Typography>
          <Button
            disabled={loading}
            variant="primary"
            onClick={handleSubmit(edit ? handleEdit : onSubmit)}
          >
            Save
          </Button>
        </Box>
        <Box sx={{ ...flexCenter, flexDirection: "column" }}>
          <div style={{ position: "relative", display: "inline-block" }}>
            <Avatar
              src={
                selectedImage?.base64
                  ? selectedImage?.base64
                  : selectedImage?.file
              }
              sx={{
                height: "171px",
                width: "171px",
              }}
            />

            <IconButton
              sx={{
                position: "absolute",
                bottom: "5px",
                right: "5px",
                borderRadius: "50%",
                border: "5px solid #F3F5F5",
                backgroundColor: (theme) => theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.primary.main,
                },
              }}
              component="label"
            >
              {selectedImage?.file ? (
                <EditIcon
                  fontSize="medium"
                  sx={{
                    color: (theme) => theme.palette.background.secondary,
                  }}
                />
              ) : (
                <AddIcon
                  fontSize="medium"
                  sx={{
                    color: (theme) => theme.palette.background.secondary,
                  }}
                />
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </IconButton>
          </div>
          <Box sx={{ width: "70%", my: 3 }}>
            <Box sx={{ mb: 4, ...dFlex, flexDirection: "column" }}>
              <Typography
                variant="bold2"
                sx={{
                  color: (theme) => theme.palette.text.main,
                  mb: 1,
                }}
              >
                Student Name*
              </Typography>
              <FormTextField
                fullWidth
                placeholder="Student Name"
                {...register("studentName", {
                  required: "This field is mandatory",
                })}
                error={Boolean(errors?.studentName)}
                helperText={errors?.studentName?.message}
              />
            </Box>
            <Box sx={{ mb: 4, ...dFlex, flexDirection: "column" }}>
              <Typography
                variant="bold2"
                sx={{
                  color: (theme) => theme.palette.text.main,
                  mb: 1,
                }}
              >
                E-mail ID
              </Typography>
              <FormTextField
                fullWidth
                // disabled={edit}
                placeholder="email@domain.com"
                inputProps={{
                  style: {
                    textTransform: "lowercase",
                  },
                }}
                {...register("email", {
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid Email Address",
                  },
                })}
                error={Boolean(errors?.email)}
                helperText={errors?.email?.message}
              />
            </Box>
            <Box sx={{ mb: 4, ...dFlex, flexDirection: "column" }}>
              <Typography
                variant="bold2"
                sx={{
                  color: (theme) => theme.palette.text.main,
                  mb: 1,
                }}
              >
                Phone Number
              </Typography>
              <FormTextField
                fullWidth
                // disabled={edit}
                placeholder="+12123456789"
                {...register("phone", {
                  pattern: {
                    value: /^\+\d+$/,
                    message: "Phone number must start with a plus sign (+)",
                  },
                })}
                error={Boolean(errors?.phone)}
                helperText={errors?.phone?.message}
              />
            </Box>
            {!edit && (
              <Box sx={{ mb: 4, ...dFlex, flexDirection: "column" }}>
                <Typography
                  variant="bold2"
                  sx={{
                    color: (theme) => theme.palette.text.main,
                    mb: 1,
                  }}
                >
                  Batch*
                </Typography>
                <Select
                  isClearable
                  value={selectedBatch}
                  onChange={(e) => setSelectedBatch(e)}
                  options={batches}
                  theme={reactSelectTheme}
                  maxMenuHeight={150}
                  placeholder="Select Batch"
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
