import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Popover,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ChatMessages } from "../../../Pages/Parent/CommunityChannel/ParentCommunityChannel";
import {
  dFlex,
  flexBetweenWithItemsCenter,
  itemsCenter,
  textEllipses,
} from "../../../common/commonStyles";
import { checkSize } from "../../../const/constFunctions";
import { UserContext } from "../../../context/UserContext";
import sendNotification from "../../../functions/PushNotification";
import {
  ChatDocumentIcon,
  ChatImageIcon,
  DeleteIcon,
} from "../../../images/icons";
export default function FacilitatorMessageBox({
  chatDetails,
  setChatDetails,
  chatUserFcmToken,
}) {
  const { userDetails } = useContext(UserContext);
  const [file, setFile] = useState(null);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [text, setText] = useState("");
  const navigate = useNavigate();
  const scrollRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "attachment-popover" : undefined;

  // useEffect(() => {
  //   setMessages([]);
  //   setLoading(true);
  //   setTimeout(() => {
  //     let arr = new Array(10).fill(1);
  //     setMessages(arr);
  //     setLoading(false);
  //   }, 500);
  // }, [chatDetails.name]);

  const joinChannel = async (channel) => {
    try {
      // console.log({ channel });

      if (channel.status === "notParticipating") {
        console.log("joining channel");
        await channel.join();
      }
      if (channel.status === "joined") {
        console.log("joining channel");
        const res = await channel.getMessages();
        // console.log({ res });
        setMessages(res?.items);
      }
      setLoading(false);
      console.log("channel joined successfully");
      setChatDetails(channel);
      channel.on("messageAdded", function (message) {
        handleMessageAdded(message);
      });
      channel.on("messageRemoved", function (message) {
        handleMessageRemoved(message);
      });
    } catch (error) {
      console.log("join error:");
      console.log({ error });
    }
  };
  const handleMessageAdded = (message) => {
    console.log({ message });
    setMessages((p) => [...p, message]);
  };
  const handleMessageRemoved = (message) => {
    // console.log({ message });
    setMessages((p) =>
      p.filter((e) => {
        return e.sid !== message.sid;
      })
    );
  };

  const sendMessage = async () => {
    try {
      setSendingMessage(true);

      let messageBuilder = chatDetails.prepareMessage();
      messageBuilder.setBody("");
      let obj = {
        name: userDetails?.instructorName ?? "No Username",
        image: userDetails?.image ?? null,
      };

      messageBuilder.setAttributes(obj);
      if (text) {
        if (!String(text).trim()) {
          toast.error("Message cannot be blank");
          return;
        }
        messageBuilder.setBody(String(text).trim());
        // await chatDetails.sendMessage(String(text).trim());
        // return;
      }
      if (file) {
        // console.log({ file });
        for (const key of file) {
          const formData = new FormData();
          // console.log({ key });
          formData.append(key.name, key);
          messageBuilder.addMedia(formData);
        }
        // await chatDetails.sendMessage(formData);
      }
      if (text || file) {
        await messageBuilder.build().send();
        if (chatUserFcmToken) {
          sendNotification({
            userId: chatDetails?.uniqueName?.split("_")[0],
            deviceToken: chatUserFcmToken,
            title: "New Message Received",
            body: text ?? "Media",
            name: userDetails.schoolName ?? userDetails?.instructorName,
            id: chatDetails?.uniqueName,
          });
        }
      }
      setFile(null);
      setText("");
      setSendingMessage(false);
    } catch (error) {
      setSendingMessage(false);
      console.log({ error });
    }
  };
  const cleanUp = () => {
    chatDetails?.leave?.();
  };
  useEffect(() => {
    const lastChildElement = scrollRef.current?.lastElementChild;
    // lastChildElement?.scrollIntoView({ behavior: "smooth" });
    lastChildElement?.scrollIntoView();
  }, [messages.length]);

  useEffect(() => {
    joinChannel(chatDetails);
    setMessages([]);
    setLoading(true);
    // setTimeout(() => {
    //   let arr = new Array(10).fill(1);
    //   setMessages(arr);
    //   setLoading(false);
    // }, 500);

    // return () => {
    //   cleanUp();
    // };
  }, [chatDetails.name]);
  // const handleKeyPress = (e) => {
  //   if (e.code === "Escape") {
  //     setChatDetails(null);
  //   }
  // };

  const handleFileRemove = (id) => {
    setFile((p) => p.filter((_, i) => i !== id));
  };

  // useEffect(() => {
  //   document.addEventListener("keydown", handleKeyPress);
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyPress);
  //   };
  // }, []);

  return (
    <Box
      id="message_window"
      sx={{
        minWidth: "690px",
        width: "100%",
        // height: "80vh",
        borderRadius: "23px",
        // backgroundColor: (theme) => theme.palette.background.secondary,
        position: "relative",
      }}
    >
      <Box
        id="chat-header"
        sx={{
          borderRadius: "12px",
          width: "100%",
          height: "72px",
          backgroundColor: (theme) => theme.palette.background.secondary,
          ...itemsCenter,
          pl: 3,
          boxSizing: "border-box",
          position: "absolute",
          top: 0,
          zIndex: 1,
        }}
      >
        <Box
          sx={{ ...itemsCenter, gap: 2, cursor: "pointer" }}
          // onClick={() => {
          //   navigate(`details`, {
          //     state: {
          //       chatDetails,
          //     },
          //   });
          // }}
        >
          <Avatar
            src={chatDetails?.attributes?.image}
            sx={{
              height: "60px",
              width: "60px",
              backgroundColor: (theme) => theme.palette.primary.main,
            }}
          >
            {chatDetails?.friendlyName?.[0]}{" "}
          </Avatar>
          <Typography
            variant="semibold"
            sx={{ color: (theme) => theme.palette.text.dark }}
          >
            {chatDetails?.friendlyName}{" "}
          </Typography>
        </Box>
      </Box>
      {loading ? (
        <Box
          sx={{
            position: "absolute",
            top: 72,
            bottom: 58,
            width: "100%",
            overflow: "auto",
          }}
        >
          <MessageSkeleton />
          <MessageSkeleton inComing={true} />
        </Box>
      ) : (
        <Box
          ref={scrollRef}
          sx={{
            // backgroundColor: (theme) => theme.palette.background.secondary,
            // backgroundColor: "red",
            position: "absolute",
            top: 72,
            bottom: 58,
            width: "100%",
            overflow: "auto",
            // height: "100%",
          }}
        >
          {messages.map((e, i) => (
            <ChatMessages
              data={e}
              inComing={e.author == userDetails?.uid}
              key={e.sid}
            />
          ))}
        </Box>
      )}
      <Box
        sx={{
          maxHeight: "150px",
          position: "absolute",
          left: 45,
          bottom: 75,
          px: 2,
          overflow: "auto",
          boxShadow: "2px 2px 8px 0px rgba(0, 0, 0, 0.25)",
          backgroundColor: (theme) => theme.palette.background.main,
        }}
      >
        {file?.map((e, i) => (
          <Box key={i} sx={{ ...itemsCenter, gap: 1, my: 1 }}>
            {/* <AttachmentIcon /> */}
            <Typography
              title={`${e?.name} (${checkSize(e)}mb)`}
              sx={{
                width: "280px",
                ...textEllipses,
              }}
            >
              {e?.name} ({checkSize(e)}mb){" "}
            </Typography>
            <IconButton
              onClick={() => {
                handleFileRemove(i);
              }}
              sx={{ p: 1, border: "1px solid #358b8a" }}
            >
              <DeleteIcon height={"18px"} fill={"#358b8a"} />
            </IconButton>
          </Box>
        ))}
      </Box>
      <Box
        id="chat-message-send"
        sx={{ position: "absolute", bottom: 0, ...dFlex, width: "100%" }}
      >
        <TextField
          placeholder="Type Message..."
          value={text}
          multiline
          maxRows={5}
          onChange={(e) => setText(e.target.value)}
          fullWidth
          sx={{
            backgroundColor: (theme) => theme.palette.background.secondary,
          }}
          InputProps={{
            startAdornment: (
              <IconButton onClick={handleClick}>
                <AttachFileIcon
                  sx={{ color: (theme) => theme.palette.primary.main }}
                />
              </IconButton>
            ),
          }}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          PaperProps={{
            sx: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Box
            sx={{
              ...dFlex,
              flexDirection: "column",
              backgroundColor: "transparent",
            }}
          >
            <IconButton
              component="label"
              sx={{
                mb: 1,
                backgroundColor: (theme) => theme.palette.background.secondary,
                p: 1.5,
              }}
            >
              <ChatImageIcon />
              <input
                type="file"
                multiple
                accept="image/*,video/*,audio/*"
                onChange={(e) => {
                  setFile([...e.target.files]);
                  handleClose();
                }}
                // onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </IconButton>
            <IconButton
              component="label"
              sx={{
                mb: 1,
                backgroundColor: (theme) => theme.palette.background.secondary,
                p: 1.5,
              }}
            >
              <ChatDocumentIcon />
              <input
                type="file"
                multiple
                accept="application/pdf"
                onChange={(e) => {
                  setFile([...e.target.files]);
                  handleClose();
                }}
                // onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </IconButton>
          </Box>
        </Popover>
        <Button
          onClick={sendMessage}
          disabled={sendingMessage}
          sx={{
            backgroundColor: (theme) => theme.palette.background.secondary,
            ml: 2,
            px: "19px",
            borderRadius: "11px",
            fontSize: "20px",
            textTransform: "none",
          }}
        >
          {sendingMessage ? <CircularProgress /> : "Send"}{" "}
        </Button>
      </Box>{" "}
    </Box>
  );
}

function MessageSkeleton({ inComing }) {
  return (
    <Box
      sx={{
        my: 3,
        // width: "70%",
        p: 1,
        boxSizing: "border-box",
        minHeight: "10px",
        ...dFlex,
        flexDirection: !inComing ? "row" : "row-reverse",
        gap: 2,
      }}
    >
      <Skeleton
        variant="circular"
        sx={{
          width: "70px",
          height: "70px",
          alignSelf: "flex-end",
        }}
      />
      <Box
        sx={{
          borderRadius: inComing ? "40px 40px 0px 40px" : "40px 40px 40px 0px",
          minHeight: "70px",
          width: "70%",
          py: 2,
          px: 3,
          alignSelf: "flex-end",
          backgroundColor: "#DBE8E8",
          boxSizing: "border-box",
          ...dFlex,
          flexDirection: "column",
        }}
      >
        <Box sx={{ ...flexBetweenWithItemsCenter }}>
          <Skeleton sx={{ width: "40%", height: "40px" }} />
          <Skeleton sx={{ width: "20%", height: "40px" }} />
        </Box>

        <Skeleton sx={{ height: "50px", p: 0, m: 0, transform: "none" }} />

        <Box
          sx={{
            backgroundColor: "#C4DDDD",
            borderRadius: "7px",
            p: "16px 12px",
            boxSizing: "border-box",
            width: "fit-content",
            gap: 1,
            mt: 2,
            width: "60%",
          }}
        >
          <Skeleton sx={{ width: "100%" }} />
        </Box>
      </Box>
    </Box>
  );
}
