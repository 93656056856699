import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import { dFlex, flexCenter } from "../../../common/commonStyles";

export default function StudentEarnedCertificate() {
  const url = process.env.REACT_APP_PARENT_CERTIFICATE_COUNT;

  const [certificateApiCall] = useDataFetchHook(url);
  // console.log({ res: certificateApiCall?.res });
  return (
    <Box
      sx={{
        width: "152px",
        minWidth: "152px",
        ...dFlex,
        flexDirection: "column",
        borderRadius: "10px",
        boxSizing: "border-box",
        p: "12px 16px",
        height: "128px",
        gap: 1,
        color: (theme) => theme.palette.primary.main,
        backgroundColor: (theme) => theme.palette.background.secondary,
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      {certificateApiCall?.loading && (
        <Box sx={flexCenter}>
          <CircularProgress />
        </Box>
      )}
      <Typography variant="bold" sx={{ fontSize: "30px" }}>
        {certificateApiCall?.res?.count}
      </Typography>
      <Typography variant="medium2">Students earned certification</Typography>
    </Box>
  );
}
