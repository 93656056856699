import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import DownloadSelect from "../../../common/DownloadSelect";
import { dFlex, flexBetweenCenter } from "../../../common/commonStyles";
import {
  StyledTableCell,
  StyledTableRow,
  TableRowSkeletonComponent,
} from "../../../const/Table";
import { CustomDateTextField, FormTextField } from "../../../const/TextField";
import { getFormattedDate } from "../../../const/constFunctions";
import {
  ArrowDropDownIconSvg,
  ArrowDropUpIconSvg,
  FilterIcon,
} from "../../../images/icons";

export default function TransactionsTable() {
  const [selectedDate, setSelectedDate] = useState("");
  const [selectOption, setSelectOption] = useState("default");
  const [selectedSortUp, setSelectedSortUp] = useState("");
  const [selectedSortDown, setSelectedSortDown] = useState("");

  const [apiData] = useDataFetchHook(
    process.env.REACT_APP_SA_TRANSACTIONS + "/list"
  );
  const handleChange = (e) => {
    setSelectOption(e.target.value);
  };

  const clearSearch = () => {
    setSearchText("");
  };

  const handleFilter = () => {
    let filteredData = apiData?.res?.data ?? [];

    if (selectOption !== "default") {
      filteredData = filteredData.filter((item) => item.type === selectOption);
    }

    if (selectedDate) {
      const filterDate = new Date(selectedDate);
      filteredData = filteredData.filter(
        (item) =>
          new Date(item.transactionDate).toDateString() ===
          filterDate.toDateString()
      );
    }
    setSearchData(filteredData);
  };

  const handleClearFilter = () => {
    setSelectOption("default");
    setSelectedDate("");
    setSelectedSortDown("");
    setSelectedSortUp("");
    setSearchData(apiData?.res?.data ?? []);
  };

  const [searchData, setSearchData] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const handleSearch = () => {
    try {
      // console.log("searhing");
      let arr = [...apiData?.res?.data];
      arr = arr?.filter((e) => {
        return e?.name?.toLowerCase()?.includes(searchText.toLowerCase());
      });
      setSearchData(arr);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!searchText) {
      setSearchData(apiData?.res?.data);
      return;
    }
    handleSearch();
  }, [searchText]);

  useEffect(() => {
    if (!apiData?.res) {
      return;
    }

    setSearchData(apiData?.res?.data);
    return () => {};
  }, [apiData?.res]);

  const sortArr = (para) => {
    setSelectedSortUp(para);
    setSelectedSortDown("");

    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "name":
          fir = a.name.toLowerCase();
          sec = b.name.toLowerCase();
          break;
        case "transactionType":
          fir = a.transactionType.toLowerCase();
          sec = b.transactionType.toLowerCase();
          break;
        case "transactionDate":
          fir = new Date(a.transactionDate);
          sec = new Date(b.transactionDate);
          break;
        case "amount":
          fir = parseInt(a?.amount?.replace("$", ""));
          sec = parseInt(b?.amount?.replace("$", ""));
          break;
        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir < sec) {
        return -1;
      }
      if (fir > sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  const sortArrDec = (para) => {
    setSelectedSortUp("");
    setSelectedSortDown(para);

    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "name":
          fir = a.name.toLowerCase();
          sec = b.name.toLowerCase();
          break;
        case "transactionType":
          fir = a.transactionType.toLowerCase();
          sec = b.transactionType.toLowerCase();
          break;
        case "transactionDate":
          fir = new Date(a.transactionDate);
          sec = new Date(b.transactionDate);
          break;
        case "amount":
          fir = parseInt(a?.amount?.replace("$", ""));
          sec = parseInt(b?.amount?.replace("$", ""));
          break;
        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir > sec) {
        return -1;
      }
      if (fir < sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  const downloadCsv = () => {
    let data = searchData;
    data = data.map((row) => {
      let formattedData = {
        "Subscriber Name": row.name,
        "Subscriber Type": row.type,
        "Transaction Date": getFormattedDate(row?.transactionDate),
        Amount: row.amount,
        "Invoice Id": row.invoiceId,
        "Transaction Type": row.transactionType?.toUpperCase(),
      };
      return formattedData;
    });

    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Transactions_${new Date().toString()}.csv`;
    link.click();
  };

  return (
    <Box
      sx={{
        // height: "60vh",
        maxHeight: apiData?.loading ? "400px" : "60vh",
        borderRadius: "7px",
        p: "20px",
        backgroundColor: (theme) => theme.palette.background.secondary,
        transition: "all 1s ease",
      }}
    >
      <Box sx={{ ...flexBetweenCenter, mb: 3 }}>
        <Box
          sx={{
            ...dFlex,
            gap: 2,
            boxSizing: "border-box",
          }}
        >
          <FormTextField
            placeholder="search"
            // sx={{ width: "400px" }}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            disabled={apiData?.loading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    fontSize="small"
                    sx={{
                      color: "#BFBFBF",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          {/* <FormTextField autoComplete="off" /> */}
          <FormControl sx={{ width: 180 }}>
            <Select
              sx={{
                background: "#FFF",
                color: "#809fb8",
                height: "38px",
                borderRadius: "4px",
              }}
              name="selectOption"
              onChange={handleChange}
              value={selectOption}
            >
              <MenuItem value="default" disabled>
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  Select Type
                </Typography>
              </MenuItem>
              {/* File type options */}
              <MenuItem value="Parent">
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  Parent
                </Typography>
              </MenuItem>
              <MenuItem value="School">
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  School / Institution
                </Typography>
              </MenuItem>
              <MenuItem value="Other">
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  Other
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
          <CustomDateTextField
            value={selectedDate}
            onChange={(e) => {
              setSearchText("");
              setSelectedDate(e.target.value);
            }}
            autoComplete="off"
            type="date"
          />
          <Box>
            <Button variant="primary-medium" onClick={handleFilter}>
              <FilterIcon /> Filter
            </Button>
            <Button
              onClick={() => {
                handleClearFilter();
                clearSearch();
              }}
              sx={{
                textTransform: "none",
                color: (theme) => theme.palette.text.main,
              }}
            >
              {" "}
              x Clear
            </Button>
          </Box>
        </Box>
        <DownloadSelect downloadCsv={downloadCsv} />
      </Box>
      <Box
        sx={{
          overflow: "auto",
          height: "45vh",
          transition: "all 1s ease",
        }}
      >
        <TableContainer sx={{ overflowX: "initial" }}>
          <Table stickyHeader>
            {/* Table header */}
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Box sx={{ ...dFlex, gap: 1 }}>
                    <Typography
                      variant="semibold"
                      sx={{ color: (theme) => theme.palette.text.dark }}
                    >
                      Subscriber
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArr("name")}
                      >
                        <ArrowDropUpIconSvg focus={selectedSortUp == "name"} />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArrDec("name")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown == "name"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant="semibold"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Subscriber Type
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ ...dFlex, gap: 1 }}>
                    <Typography
                      variant="semibold"
                      sx={{ color: (theme) => theme.palette.text.dark }}
                    >
                      Date
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArr("transactionDate")}
                      >
                        <ArrowDropUpIconSvg
                          focus={selectedSortUp == "transactionDate"}
                        />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArrDec("transactionDate")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown == "transactionDate"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ ...dFlex, gap: 1 }}>
                    <Typography
                      variant="semibold"
                      sx={{ color: (theme) => theme.palette.text.dark }}
                    >
                      Subscription Amount
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArr("amount")}
                      >
                        <ArrowDropUpIconSvg
                          focus={selectedSortUp == "amount"}
                        />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArrDec("amount")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown == "amount"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledTableCell>
                <StyledTableCell sx={{}}>
                  <Typography
                    variant="semibold"
                    sx={{
                      color: (theme) => theme.palette.text.dark,
                    }}
                  >
                    Invoice
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant="semibold"
                    sx={{
                      color: (theme) => theme.palette.text.dark,
                    }}
                  >
                    Payment Mode
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant="semibold"
                    sx={{
                      color: (theme) => theme.palette.text.dark,
                    }}
                  >
                    Payment Status
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ ...dFlex, gap: 1 }}>
                    <Typography
                      variant="semibold"
                      sx={{ color: (theme) => theme.palette.text.dark }}
                    >
                      Transaction Type
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArr("transactionType")}
                      >
                        <ArrowDropUpIconSvg
                          focus={selectedSortUp == "transactionType"}
                        />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArrDec("transactionType")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown == "transactionType"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledTableCell>
              </TableRow>
            </TableHead>

            {/* Table body */}
            {apiData?.loading ? (
              <TableRowSkeletonComponent column={8} row={4} />
            ) : (
              <TableBody>
                {searchData?.map((row, index) => (
                  <StyledTableRow
                    sx={{
                      cursor: "default!important",
                    }}
                    // onClick={() => {
                    //   navigate(`${row?.transactionId}`, {
                    //     state: {
                    //       transactionDetail: row,
                    //       type: "list",
                    //     },
                    //   });
                    // }}
                  >
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row.name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row.type}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {getFormattedDate(row?.transactionDate)}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        ${row.amount}{" "}
                        {row?.WiPayResponse?.currency ?? row?.currency ?? "JMD"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell
                      sx={{
                        width: "140px",
                      }}
                    >
                      <Typography
                        variant="regular"
                        sx={{
                          color: (theme) => theme.palette.text.main,
                          width: "90px",
                        }}
                      >
                        {row.invoiceId}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row?.paymentMode ?? "Online"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row?.paymentStatus ?? "Failes"}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row.transactionType}
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
