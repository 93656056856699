import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../../Apis/axiosPayloads";
import { POST } from "../../../../Apis/constVariables";
import {
  flexCenter,
  itemsCenter,
  textEllipses,
} from "../../../../common/commonStyles";
import { FormTextField } from "../../../../const/TextField";
import {
  checkSize,
  convertToBase64File,
  getFileDuration,
} from "../../../../const/constFunctions";
import { AddIcon, EditIconColor } from "../../../../images/icons";
const initData = {
  title: "",
  timeLimit: 0,
  firstToken: 0,
  secondToken: 0,
  coverImage: "",
  file: "",
  type: null,
};
export default function MedzGymUploadModal({ edit, categoryId, fetchAgain }) {
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState(initData);

  const [file, setFile] = useState(null);
  const [submit, setSubmit] = useState(false);
  const [coverImage, setCoverImage] = useState(null);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFile(null);
    setCoverImage(null);
    setOpen(false);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (value && name.toLowerCase().includes("token")) value = +value;
    setDetails({
      ...details,
      [name]: value,
    });
  };

  const handleCoverImageSelect = async (state, e) => {
    try {
      if (e?.target?.files.length > 0) {
        let file = e.target.files[0];
        if (checkSize(file) > 10) {
          e.target.value = "";
          toast.error("Please upload an image less than 10MB");
          return;
        }
        const base64 = await convertToBase64File(file);
        console.log({ base64 });
        setDetails({ ...details, coverImage: base64 });
        state(e?.target?.files?.[0]);
      } else state(null);
    } catch (err) {
      console.log(err);
      e.target.value = "";
      toast.error("Something went wrong, Please try again");
    }
  };

  const handleFileSelect = async (state, e) => {
    try {
      if (e?.target?.files.length > 0) {
        let file = e.target.files[0];
        if (checkSize(file) > 10) {
          e.target.value = "";
          toast.error("Please upload a file less than 10MB");
          return;
        }
        let base64 = convertToBase64File(file);
        let duration = getFileDuration(file);
        duration = await duration;
        base64 = await base64;
        console.log({ trye: file.type });
        setDetails({
          ...details,
          file: base64,
          timeLimit: duration == 0 ? 1 : duration,
          type: file?.type?.split("/")?.[0],
        });
        state(file);
      } else state(null);
    } catch (err) {
      console.log(err);
      e.target.value = "";
      toast.error("Something went wrong, Please try again");
    }
  };

  const handleSave = async () => {
    try {
      console.log({ details });
      if (!details?.title?.trim()) {
        toast.error("Name cannot be empty");
        return;
      }
      const url = process.env.REACT_APP_GYM_CATEGORY + `/${categoryId}`;
      // const config = {
      //   method: POST,
      //   url,
      //   data: details,
      // };
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: details,
      });
      setSubmit(true);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchAgain();
      handleClose();
      setDetails(initData);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
    setSubmit(false);
  };
  const handleEdit = async () => {
    toast("File saved");
    handleClose();
  };

  return (
    <>
      {edit ? (
        <IconButton onClick={handleOpen}>
          <EditIconColor />
        </IconButton>
      ) : (
        <Button variant="primary" onClick={handleOpen}>
          <AddIcon />
          Add File
        </Button>
      )}
      {open && (
        <Dialog
          open={open}
          onClose={() => {
            if (submit) {
              toast.error("Your file is being uploaded please do not close");
              return;
            }
            handleClose();
          }}
          PaperProps={{
            sx: {
              backgroundColor: (theme) => theme.palette.background.main,
              borderRadius: "14px",
              px: 3,
              py: 1,
              maxWidth: "100%",
              width: "35%",
            },
          }}
        >
          <DialogContent sx={{ p: 0 }}>
            <DialogContentText>
              <Box
                sx={{
                  ...itemsCenter,
                  width: "100%",
                  my: "20px",
                  gap: 2,
                }}
              >
                <Typography variant="medium" sx={{ width: "28%" }}>
                  Upload a File
                </Typography>
                <Box sx={{ ...itemsCenter, gap: "10px" }}>
                  <Box
                    component={"label"}
                    sx={{
                      ...flexCenter,
                      height: "35px",
                      width: "125px",
                      cursor: "pointer",
                      borderRadius: "4px",
                      color: (theme) => theme.palette.text.main,
                      backgroundColor: (theme) => theme.palette.text.lighter,
                    }}
                  >
                    Choose a file
                    <input
                      type="file"
                      accept="audio/*, video/*"
                      onChange={(e) => handleFileSelect(setFile, e)}
                      style={{ display: "none" }}
                    />
                  </Box>
                  <Typography
                    variant="regular"
                    title={file ? file?.name : "No File Chosen"}
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      maxWidth: "220px",
                      ...textEllipses,
                    }}
                  >
                    {file ? file?.name : "No File Chosen"}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  ...itemsCenter,
                  width: "100%",
                  my: "20px",
                  gap: 2,
                }}
              >
                <Typography variant="medium" sx={{ width: "28%" }}>
                  Upload Cover Photo
                </Typography>
                <Box sx={{ ...itemsCenter, gap: "10px" }}>
                  <Box
                    component={"label"}
                    sx={{
                      ...flexCenter,
                      height: "35px",
                      width: "125px",
                      cursor: "pointer",
                      borderRadius: "4px",
                      color: (theme) => theme.palette.text.main,
                      backgroundColor: (theme) => theme.palette.text.lighter,
                    }}
                  >
                    Choose a file
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleCoverImageSelect(setCoverImage, e)}
                      style={{ display: "none" }}
                    />
                  </Box>
                  <Typography
                    variant="regular"
                    title={coverImage ? coverImage?.name : "No File Chosen"}
                    sx={{
                      color: (theme) => theme.pal,
                      maxWidth: "220px",
                      ...textEllipses,
                    }}
                  >
                    {coverImage ? coverImage?.name : "No File Chosen"}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  ...itemsCenter,
                  width: "100%",
                  my: "20px",
                  gap: 2,
                }}
              >
                <Typography variant="medium" sx={{ width: "28%" }}>
                  Title
                </Typography>
                <FormTextField
                  name="title"
                  onChange={handleChange}
                  value={details?.title}
                  sx={{ width: "65%" }}
                  autoComplete="off"
                />
              </Box>
              <Box
                sx={{
                  ...itemsCenter,
                  width: "100%",
                  my: "20px",
                  gap: 2,
                }}
              >
                <Typography variant="medium" sx={{ width: "28%" }}>
                  Tokens
                </Typography>
                <FormTextField
                  sx={{ width: "32%" }}
                  type="number"
                  onBlur={(e) => {
                    if (e?.target?.value < 0) {
                      handleChange(0);
                      return;
                    }
                  }}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="firstToken"
                  value={details?.firstToken}
                  placeholder="First attempt"
                  autoComplete="off"
                />
                <FormTextField
                  sx={{ width: "32%" }}
                  type="number"
                  onBlur={(e) => {
                    if (e?.target?.value < 0) {
                      handleChange(0);
                      return;
                    }
                  }}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="secondToken"
                  value={details?.secondToken}
                  placeholder="Second attempt"
                  autoComplete="off"
                />
              </Box>
              {submit && (
                <Typography
                  variant="semibold2"
                  sx={{ color: (theme) => theme.palette.content.quizQuestion }}
                >
                  Your Video is getting Uploaded please do not leave / close
                  this tab
                </Typography>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              disabled={!details?.title || submit}
              variant="primary"
            >
              {submit ? <CircularProgress /> : "Save"}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
