export const request = "REQUEST";
export const success = "SUCCESS";
export const error = "ERROR";
export const quizOptions = {
  MCQ: "Multiple choice questions",
  MatchPair: "Match the pair",
  PicMCQ: "Picture MCQ",
  DropDown: "Drop down",
  Ranking: "Ranking",
  DragDrop: "Drag and drop categories",
  CompleteSentence: "Complete the sentence",
  Assignment: "Blog / Journal",
};
export const brawtaOptions = {
  selfReflection: "Answer a self reflection question",
  videoBased: "Upload a video based on the question provided",
  audioBased: "Upload an audio based on the question provided",
  quizAndBlog: "Complete the quiz and write a blog on the following topic",
  picturePrompt: "Respond to a picture prompt",
  shortStoryCase:
    "Respond to the following question regarding the short story/case",
  interviewSomeone: "Interview someone",
  thinkPairShare:
    "Think-Pair-Share ~ Find a partner and share or compare answers to a question related to the content and then share responses (video/audio) in the Community Channel",
};
