import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import { flexCenter } from "../../../common/commonStyles";
import { AlertIconUrl } from "../../../const/imageUrl";

export default function ConfirmDeleteAccountModal({ refundAmount }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteAccount = async () => {
    try {
      setLoading(true);
      const url = process.env.REACT_APP_SA_TRANSACTIONS + "/deleteAccount";
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: { id: params?.schoolId, amount: refundAmount },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      handleClose();
      navigate(-1);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
    setLoading(false);
  };
  return (
    <>
      <Button variant="primary" onClick={handleOpen}>
        Proceed
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: (theme) => theme.palette.background.main,
            borderRadius: "10px",
            pb: 2,
          },
        }}
      >
        <Box sx={{ padding: "0px 5px" }}>
          {/* <form onSubmit={handleDeleteAdmission} autoComplete="off"> */}
          <DialogContent>
            <Box sx={{ ...flexCenter, flexDirection: "column", gap: 2, mb: 3 }}>
              <img
                src={AlertIconUrl}
                style={{ height: "40px", width: "40px" }}
              />
              <Typography
                color="error"
                sx={{ fontWeight: 600, fontSize: "18px" }}
              >
                Alert
              </Typography>
            </Box>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete account?
              <br />
              <Typography
                variant="regular"
                sx={{
                  fontSize: "14px",
                  color: (theme) => theme.palette.content.quizQuestion,
                }}
              >
                Note: All your files and data related to this account will be
                deleted
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={loading}
              onClick={handleDeleteAccount}
              variant="primary-medium"
            >
              Delete Account
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
