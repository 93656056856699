import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Avatar, Box, IconButton, Popover, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { dFlex, itemsCenter } from "../../../common/commonStyles";
import ArchiveStudentModal from "../../School/ManageBatch/ArchiveStudentModal";
import DeleteStudentModal from "../../School/ManageBatch/DeleteStudentModal";

export default function ManageStudentCard({ data, fetchAgain }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        minWidth: "320px",
        maxWidth: "350px",
        height: "80px",
        ...itemsCenter,
        alignItems: { xs: "center" },
        borderRadius: "8px",
        px: "24px",
        boxSizing: "border-box",
        backgroundColor: (theme) => theme.palette.background.secondary,
      }}
    >
      <Avatar
        src={data?.image}
        sx={{ height: "54px", width: "54px", mr: 1.5 }}
      />
      <Box
        sx={{
          ...dFlex,
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ ...itemsCenter, gap: 1 }}>
          <Typography
            variant="semibold"
            sx={{ color: (theme) => theme.palette.text.dark }}
          >
            {data?.studentName}{" "}
          </Typography>
          <Box sx={{ border: "2px solid black", borderRadius: "100%" }}></Box>
          {/* <Typography
            variant="medium2"
            sx={{ color: (theme) => theme.palette.text.light }}
          >
            ID1234
          </Typography> */}
        </Box>
        {data?.certificateIssued && (
          <Typography variant="medium2" sx={{ color: "#358B48" }}>
            Completed Certification
          </Typography>
        )}
      </Box>
      <>
        <IconButton sx={{ p: 0, ml: "auto", mr: 0 }} onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Popover
          open={anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            top: 0,
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box
            sx={{
              p: 2,
              width: "100px",
              ...dFlex,
              flexDirection: "column",
              gap: 1,
              borderRadius: "8px",
            }}
          >
            <Typography
              variant="medium2"
              onClick={() => {
                navigate("edit-student", {
                  state: {
                    studentInfo: data,
                  },
                });
              }}
              sx={{
                color: (theme) => theme.palette.text.light,
                cursor: "pointer",
              }}
            >
              Edit
            </Typography>
            <ArchiveStudentModal data={data} fetchAgain={fetchAgain} />
            {data?.deleteAllowed && (
              <DeleteStudentModal fetchAgain={fetchAgain} data={data} />
            )}
          </Box>
        </Popover>
      </>
    </Box>
  );
}
