import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  InputAdornment,
  TablePagination,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArchivedStudentCard from "../../../Components/School/ManageBatch/ArchivedStudentCard";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import useTablePagination from "../../../Hooks/TablePaginationHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import {
  flexBetweenWithItemsCenter,
  flexCenter,
} from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { BatchEmpty } from "../../../const/imageUrl";
import { RegisterIcon } from "../../../images/icons";

export default function BatchArchivedStudentDetails() {
  const location = useLocation();
  // REACT_APP_STUDENT_ARCHIVE_LIST
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [studentUrl, setStudentUrl] = useState(null);
  const [studentApiCall, fetchStudentAgain] = useDataFetchHook(studentUrl);

  const navigate = useNavigate();
  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();
  const [batchDetails, setBatchDetails] = useState({
    batchName: "",
  });

  useEffect(() => {
    const { batchDetail } = location?.state ?? { batchName: "" };
    setStudentUrl(
      `${process.env.REACT_APP_STUDENT_ARCHIVE_LIST}${batchDetail.batchName}&offset=0&limit=50`
    );
    setBatchDetails(batchDetail);
  }, [location.state]);

  const filterData = () => {
    console.log("searhing");
    let arr = [...studentApiCall?.res?.Student];
    arr = arr?.filter((e) => {
      return e?.studentName?.toLowerCase()?.includes(searchText.toLowerCase());
    });
    handleChangePage(null, 0);
    setSearchData(arr);
    setLoading(false);
  };
  useEffect(() => {
    if (!searchText) {
      setSearchData([]);
      setLoading(false);
      return;
    }
    filterData();
  }, [searchText]);

  return (
    <Box>
      <NavBreadCrumbs
        pageTags={["Manage Batch", batchDetails?.batchName, "Archived"]}
      />

      <Box sx={{ ...flexBetweenWithItemsCenter, height: "40px" }}>
        <Box>
          <FormTextField
            placeholder="search"
            onChange={(e) => {
              setLoading(true);
              setSearchText(e.target.value);
            }}
            value={searchText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    fontSize="small"
                    sx={{
                      color: "#BFBFBF",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>

      <Box>
        {false && (
          <Box
            sx={{
              mt: 3,
              backgroundColor: (theme) => theme.palette.background.secondary,
              ...flexCenter,
              flexDirection: "column",
              py: 8,
              gap: 5,
              borderRadius: "12px",
            }}
          >
            <img src={BatchEmpty} style={{ height: "220px", width: "600px" }} />
            <Typography
              variant="bold"
              sx={{ color: (theme) => theme.palette.text.light }}
            >
              You have not registered student
            </Typography>
            <Button
              variant="primary"
              onClick={() =>
                navigate("add-student", {
                  state: {
                    batchDetail: batchDetails,
                  },
                })
              }
            >
              <RegisterIcon /> Add Student
            </Button>
          </Box>
        )}
      </Box>
      {!searchText && (
        <Box>
          {studentApiCall?.res?.Student?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          ).map((item, i) => (
            <ArchivedStudentCard data={item} fetchAgain={fetchStudentAgain} />
          ))}
        </Box>
      )}
      {searchText && (
        <Box>
          {searchData
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, i) => (
              <ArchivedStudentCard data={item} fetchAgain={fetchStudentAgain} />
            ))}
        </Box>
      )}
      {studentApiCall?.res?.Student?.length >= 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            rowsPerPageOptions={[]}
            count={
              searchText
                ? searchData.length
                : studentApiCall?.res?.Student?.length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
}
