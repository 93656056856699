import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
export default function NavBreadCrumbs({ pageTags }) {
  const navigate = useNavigate();
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      sx={{
        mb: 5,
        // position: "sticky",
        // top: 100,
      }}
    >
      {pageTags?.map((tag, index) => (
        <Typography
          variant="medium"
          sx={{
            color: (theme) => theme.palette.text.main,
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
            textTransform: "capitalize",
          }}
          key={index}
          onClick={() => {
            if (index == pageTags.length - 1) {
              return;
            }
            const number = pageTags.length - (index + 1);
            // console.log(number);
            navigate(-number, { replace: true });
          }}
        >
          {tag}
        </Typography>
      ))}
    </Breadcrumbs>
  );
}
