import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { DELETE } from "../../../Apis/constVariables";

export default function DeleteMeetingModal({ data, fetchAgain }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteAccount = async () => {
    try {
      if (!data?.id) {
        toast.error("Something went wrong! Please try again");
        handleClose();
        return;
      }
      const url = process.env.REACT_APP_SCHOOL_MEETING_LIST + `/${data?.id}`;
      // const config = {
      //   method: DELETE,
      //   url,
      // };
      const config = createAxiosConfig({
        endPoint: url,
        method: DELETE,
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchAgain();
      handleClose();
    } catch (err) {
      toast.error(err?.response?.data?.message);
      console.log(err);
    }
  };
  return (
    <>
      <Button
        onClick={handleOpen}
        sx={{ textTransform: "none", fontSize: "16px", fontWeight: 500 }}
      >
        Cancel Meet
      </Button>{" "}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: (theme) => theme.palette.background.main,
            borderRadius: "10px",
            pb: 2,
            maxWidth: "100%",
            width: "30%",
          },
        }}
      >
        <Box sx={{ padding: "0px 5px" }}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to cancel the meeting scheduled on{" "}
              {data?.date}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteAccount}
              variant="primary-medium"
              sx={{
                "&:disabled": {
                  backgroundColor: "gray",
                  color: "#FFF",
                },
              }}
            >
              Cancel Meet
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
