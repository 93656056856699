import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import SchoolSubscriberTab from "../../../Components/Admin/Dashboard/SchoolSubscriberTab";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";

// Function to render individual tab panels
export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box sx={{ my: 3 }}>{children}</Box>}
    </div>
  );
}

export default function SchoolSubscribers() {
  const [value, setValue] = useState(1);

  // Event handler for tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      {/* Navigation breadcrumbs */}
      <NavBreadCrumbs pageTags={["Dashboard", "School Subscribers"]} />

      {/*  <Box sx={{ ...flexSpaceBetween }}>
        <SearchTextField
          placeholder="search"
          sx={{ width: "400px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <DownloadSelect />
      </Box> */}

      <Box>
        {/* Tabs for current and previous subscribers */}
        <Box
          sx={{
            width: "100%",
            mt: 3,
            borderBottom: `1px solid #BFBFBF`,
          }}
        >
          <Tabs value={value} onChange={handleChange} variant="fullWidth">
            <Tab
              sx={{
                textTransform: "none",
              }}
              label={
                <Typography variant={value === 1 ? "bold2" : "medium"}>
                  Current Subscribers
                </Typography>
              }
              value={1}
            />
            <Tab
              sx={{
                textTransform: "none",
              }}
              label={
                <Typography variant={value === 2 ? "bold2" : "medium"}>
                  Previous Subscribers
                </Typography>
              }
              value={2}
            />
          </Tabs>
        </Box>

        {/* Tab panels */}
        <TabPanel value={value} index={1}>
          <SchoolSubscriberTab type={"current"} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SchoolSubscriberTab type={"prev"} />
        </TabPanel>
      </Box>
    </Box>
  );
}
