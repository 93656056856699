import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  CircularProgress,
  InputAdornment,
  TablePagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import useTablePagination from "../../../Hooks/TablePaginationHook";
import {
  dFlex,
  flexBetweenCenter,
  flexCenter,
} from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import BrawtaStudentCard from "../../Parent/BrawtaChallenge/BrawtaStudentCard";

export default function FacilitatorBrawtaChallengeTab({ data }) {
  const url = `${process.env.REACT_APP_SCHOOL_GET_STUDENT_BATCH}${data.batchName}&offset=0&limit=50`;
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [studentApiCall] = useDataFetchHook(url);

  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();

  const handleSearch = () => {
    // console.log("searhing");
    let arr = [...studentApiCall?.res?.Student];
    arr = arr?.filter((e) => {
      return e?.studentName?.toLowerCase()?.includes(searchText.toLowerCase());
    });
    handleChangePage(null, 0);
    setSearchData(arr);
  };

  useEffect(() => {
    if (!searchText) {
      setSearchData(studentApiCall?.res?.Student);
      return;
    }
    handleSearch();
  }, [searchText]);

  useEffect(() => {
    if (!studentApiCall?.res) {
      return;
    }
    setSearchData(studentApiCall?.res?.Student);
  }, [studentApiCall?.res]);

  return (
    <>
      <Box sx={{ ...flexBetweenCenter, mb: 3 }}>
        <Box
          sx={{
            ...dFlex,
            gap: 2,
            alignItems: "center",
            boxSizing: "border-box",
          }}
        >
          <FormTextField
            placeholder="search"
            disabled={studentApiCall?.loading}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            value={searchText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    fontSize="small"
                    sx={{
                      color: "#BFBFBF",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      {studentApiCall?.loading && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />{" "}
        </Box>
      )}
      <Box
        sx={{
          ...dFlex,
          gap: 2,
          flexWrap: "wrap",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        {/* {studentApiCall?.res?.Student? */}
        {searchData
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          ?.map((e) => {
            return <BrawtaStudentCard data={e} key={e?.id} />;
          })}
      </Box>
      {studentApiCall?.res?.Student?.length >= 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            rowsPerPageOptions={[]}
            count={searchData?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </>
  );
}
