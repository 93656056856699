import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TablePagination,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import useTablePagination from "../../../Hooks/TablePaginationHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import {
  dFlex,
  flexBetweenCenter,
  textEllipses,
} from "../../../common/commonStyles";
import {
  StyledTableCell,
  StyledTableRow,
  TableRowSkeletonComponent,
} from "../../../const/Table";
import { CustomDateTextField, FormTextField } from "../../../const/TextField";
import { getFormattedDate } from "../../../const/constFunctions";
import {
  ArrowDropDownIconSvg,
  ArrowDropUpIconSvg,
  FilterIcon,
} from "../../../images/icons";

export default function DeleteAccount() {
  const [searchData, setSearchData] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [selectOption, setSelectOption] = useState("default");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedSortUp, setSelectedSortUp] = useState("");
  const [selectedSortDown, setSelectedSortDown] = useState("");
  // const [loading] = useDummyLoading(2000);
  const [cancelListApi] = useDataFetchHook(
    process.env.REACT_APP_SA_DASHBOARD_LIST + "/cancelOrDeleteAll?type=delete"
  );
  const handleChange = (e) => {
    setSearchText("");
    setSelectOption(e.target.value);
  };
  const navigate = useNavigate();
  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();

  const handleFilter = () => {
    let filteredData = cancelListApi?.res?.data ?? [];
    if (selectOption !== "default") {
      filteredData = filteredData.filter((item) => item.type === selectOption);
    }

    if (selectedDate) {
      const filterDate = new Date(selectedDate);
      filteredData = filteredData.filter(
        (item) =>
          new Date(item.createdOn).toDateString() === filterDate.toDateString()
      );
    }
    setSearchData(filteredData);
  };

  const clearSearch = () => {
    setSearchText("");
  };

  const handleClearFilter = () => {
    setSelectOption("default");
    setSelectedDate("");
    setSelectedSortDown("");
    setSelectedSortUp("");
    setSearchData(cancelListApi?.res?.data ?? []);
  };

  const sortArr = (para) => {
    setSelectedSortUp(para);
    setSelectedSortDown("");

    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "name":
          fir = a.name.toLowerCase();
          sec = b.name.toLowerCase();
          break;
        case "createdOn":
          fir = new Date(a.createdOn);
          sec = new Date(b.createdOn);
          break;
        case "registrationDate":
          fir = new Date(a.registrationDate);
          sec = new Date(b.registrationDate);
          break;
        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir < sec) {
        return -1;
      }
      if (fir > sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  const sortArrDec = (para) => {
    setSelectedSortUp("");
    setSelectedSortDown(para);

    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "name":
          fir = a.name.toLowerCase();
          sec = b.name.toLowerCase();
          break;
        case "createdOn":
          fir = new Date(a.createdOn);
          sec = new Date(b.createdOn);
          break;
        case "registrationDate":
          fir = new Date(a.registrationDate);
          sec = new Date(b.registrationDate);
          break;
        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir > sec) {
        return -1;
      }
      if (fir < sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  const handleSearch = () => {
    try {
      // console.log("searhing");
      let arr = [...cancelListApi?.res?.data];
      arr = arr?.filter((e) => {
        return e?.name?.toLowerCase()?.includes(searchText.toLowerCase());
      });
      handleChangePage(null, 0);
      setSearchData(arr);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!searchText) {
      setSearchData(cancelListApi?.res?.data);
      return;
    }
    handleSearch();
  }, [searchText]);

  useEffect(() => {
    if (!cancelListApi?.res) {
      return;
    }

    setSearchData(cancelListApi?.res?.data);
    return () => {};
  }, [cancelListApi?.res]);

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Dashboard", "Delete Account List"]} />
      <Box sx={{ ...flexBetweenCenter, mb: 3 }}>
        <Box
          sx={{
            ...dFlex,
            gap: 2,
            boxSizing: "border-box",
          }}
        >
          <FormTextField
            placeholder="search by name"
            // sx={{ width: "400px" }}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            disabled={cancelListApi.loading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    fontSize="small"
                    sx={{
                      color: "#BFBFBF",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <FormControl sx={{ width: 180 }}>
            <Select
              sx={{
                background: "#FFF",
                color: "#809fb8",
                height: "38px",
                borderRadius: "4px",
              }}
              name="selectOption"
              onChange={handleChange}
              value={selectOption}
            >
              <MenuItem value="default" disabled>
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  Select Type
                </Typography>
              </MenuItem>
              <MenuItem value="Parent">
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  Parent
                </Typography>
              </MenuItem>
              <MenuItem value="School">
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  School / Institution
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
          <CustomDateTextField
            value={selectedDate}
            onChange={(e) => {
              setSearchText("");
              setSelectedDate(e.target.value);
            }}
            autoComplete="off"
            type="date"
          />
          <Box>
            <Button variant="primary-medium" onClick={handleFilter}>
              <FilterIcon /> Filter
            </Button>
            <Button
              onClick={() => {
                handleClearFilter();
                clearSearch();
              }}
              sx={{
                textTransform: "none",
                color: (theme) => theme.palette.text.main,
              }}
            >
              {" "}
              x Clear
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "13px",
            pt: 1,
            // px: "20px",
            boxSizing: "border-box",
            maxHeight: cancelListApi?.loading ? "400px" : "1200px",
            transition: "all 1s ease",
          }}
        >
          <Table>
            <TableRow>
              <StyledTableCell>
                <Box sx={{ ...dFlex, gap: 1 }}>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Subscriber Name
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArr("name")}
                    >
                      <ArrowDropUpIconSvg focus={selectedSortUp === "name"} />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArrDec("name")}
                    >
                      <ArrowDropDownIconSvg
                        focus={selectedSortDown === "name"}
                      />
                    </Box>
                  </Box>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  variant="medium"
                  sx={{ color: (theme) => theme.palette.text.dark }}
                >
                  Subscriber Type
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ ...dFlex, gap: 1 }}>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Request Date{" "}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArr("createdOn")}
                    >
                      <ArrowDropUpIconSvg
                        focus={selectedSortUp === "createdOn"}
                      />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArrDec("createdOn")}
                    >
                      <ArrowDropDownIconSvg
                        focus={selectedSortDown === "createdOn"}
                      />
                    </Box>
                  </Box>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ ...dFlex, gap: 1 }}>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Registration Date{" "}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArr("registrationDate")}
                    >
                      <ArrowDropUpIconSvg
                        focus={selectedSortUp === "registrationDate"}
                      />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArrDec("registrationDate")}
                    >
                      <ArrowDropDownIconSvg
                        focus={selectedSortDown === "registrationDate"}
                      />
                    </Box>
                  </Box>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  variant="medium"
                  sx={{ color: (theme) => theme.palette.text.dark }}
                >
                  Reason
                </Typography>
              </StyledTableCell>
            </TableRow>
            {cancelListApi?.loading ? (
              <TableRowSkeletonComponent column={5} row={4} />
            ) : (
              <TableBody>
                {searchData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow
                      key={row.id}
                      onClick={() => {
                        navigate(`${row?.id}`, {
                          state: {
                            transactionDetail: {
                              ...row,
                              redirectText: "Delete Account",
                            },
                            type: "delete",
                          },
                        });
                      }}
                    >
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row?.userData?.name ? row?.userData.name : row.name}{" "}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row.type}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {getFormattedDate(row?.createdOn)}{" "}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row?.registrationDate
                            ? getFormattedDate(row?.registrationDate)
                            : "NA"}{" "}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        title={row?.reason}
                        sx={{
                          maxWidth: "150px",
                          ...textEllipses,
                        }}
                      >
                        <Typography
                          variant="regular"
                          sx={{
                            color: (theme) => theme.palette.text.main,
                          }}
                        >
                          {row.reason ?? "NA"}{" "}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            rowsPerPageOptions={[]}
            count={searchData?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </Box>
  );
}
