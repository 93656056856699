import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TablePagination,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FacilitatorCard from "../../../Components/School/Facilitator/FacilitatorCard";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import useTablePagination from "../../../Hooks/TablePaginationHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import {
  flexBetweenWithItemsCenter,
  flexCenter,
} from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { BatchEmpty } from "../../../const/imageUrl";
import { RegisterIcon } from "../../../images/icons";

export default function Facilitator() {
  const [url, setUrl] = useState(null);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [apiData, fetchApiData, dispatchData] = useDataFetchHook(url);
  const [loading, setLoading] = useState(false);
  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();

  const filterData = () => {
    console.log("searhing");
    let arr = apiData?.res?.instructor;
    arr = arr?.filter((e) => {
      return e?.instructorName
        ?.toLowerCase()
        ?.includes(searchText.toLowerCase());
    });
    handleChangePage(null, 0);
    setSearchData(arr);
    setLoading(false);
  };

  useEffect(() => {
    if (!searchText) {
      setSearchData([]);
      setLoading(false);
      return;
    }
    const debounce = setTimeout(() => {
      filterData();
    }, 500);

    return () => clearTimeout(debounce);
  }, [searchText]);

  useEffect(() => {
    const params = new URLSearchParams();
    const newUrl =
      process.env.REACT_APP_SCHOOL_FACILITATOR_LIST + "?" + params.toString();
    setUrl(newUrl);
    return () => {};
  }, []);
  return (
    <Box>
      <NavBreadCrumbs pageTags={["Facilitator"]} />
      <Box>
        <Box sx={{ ...flexBetweenWithItemsCenter, height: "40px" }}>
          <Box>
            <FormTextField
              placeholder="search"
              disabled={apiData?.loading}
              onChange={(e) => {
                setLoading(true);
                setSearchText(e.target.value);
              }}
              value={searchText}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      fontSize="small"
                      sx={{
                        color: "#BFBFBF",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Button
            onClick={() => {
              navigate("add");
            }}
            variant="primary"
          >
            <RegisterIcon /> Add Facilitator{" "}
          </Button>
        </Box>

        <Box>
          {false && (
            <Box
              sx={{
                mt: 3,
                backgroundColor: (theme) => theme.palette.background.secondary,
                ...flexCenter,
                flexDirection: "column",
                py: 8,
                gap: 5,
                borderRadius: "12px",
              }}
            >
              <img
                alt="no batch"
                src={BatchEmpty}
                style={{ height: "220px", width: "600px" }}
              />
              <Typography
                variant="bold"
                sx={{ color: (theme) => theme.palette.text.light }}
              >
                You have not added any facilitators yet{" "}
              </Typography>
              <Button
                onClick={() => {
                  navigate("add");
                }}
                variant="primary"
              >
                <RegisterIcon /> Add Facilitator{" "}
              </Button>{" "}
            </Box>
          )}
        </Box>
        {(apiData?.loading || loading) && (
          <Box sx={{ ...flexCenter }}>
            <CircularProgress autoFocus={true} />
          </Box>
        )}
        {!searchText && (
          <Box sx={{ my: 3 }}>
            {apiData?.res?.instructor?.map((e, i) => (
              <FacilitatorCard
                key={e?.id}
                data={e}
                fetchAgain={fetchApiData}
                dispatchData={dispatchData}
                index={i}
              />
            ))}
          </Box>
        )}
        {searchText && (
          <Box sx={{ my: 3 }}>
            {searchData?.map((e, i) => (
              <FacilitatorCard
                key={e?.objectID}
                data={e}
                fetchAgain={fetchApiData}
                dispatchData={dispatchData}
                index={i}
              />
            ))}
          </Box>
        )}

        {apiData?.res?.instructor?.length >= 0 && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <TablePagination
              rowsPerPageOptions={[]}
              count={searchText ? searchData.length : apiData?.res?.totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
