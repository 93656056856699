import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { PATCH } from "../../../Apis/constVariables";
import {
  dFlex,
  flexBetweenWithItemsCenter,
  itemsCenter,
} from "../../../common/commonStyles";
import { request } from "../../../const/variables";

export default function BatchCard({
  data,
  index,
  completed,
  fetchAgain,
  dispatchData,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMarkAsCompleted = async () => {
    try {
      dispatchData({ type: request });
      const url = process.env.REACT_APP_SCHOOL_CREATE_BATCH;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: { batchName: data?.batchName.trim(), complete: true },
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      fetchAgain();
      toast(apiRes?.data?.message);
      handleClose();
    } catch (err) {
      fetchAgain();
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <Box
      sx={{
        borderRadius: "12px",
        backgroundColor: (theme) => theme.palette.background.secondary,
        height: "64px",
        ...flexBetweenWithItemsCenter,
        px: 5,
        my: 2,
      }}
    >
      <Box
        sx={{ ...itemsCenter, gap: 2, cursor: "pointer" }}
        onClick={() => {
          if (completed) {
            navigate(`completed/${data?.batchName}`, {
              state: {
                batchDetail: data,
              },
            });
            return;
          }
          navigate(`${data?.batchName}`, {
            state: {
              batchDetail: data,
            },
          });
        }}
      >
        <Typography
          variant="medium"
          sx={{
            color: (theme) => theme.palette.text.dark,
          }}
        >
          {data?.batchName}
        </Typography>
        <Box sx={{ borderRadius: "50%", border: "2px solid #5C5C5C" }}></Box>
        <Typography
          variant="medium"
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          {data?.studentNumber ?? 0} Students
        </Typography>
        {!completed && (
          <>
            <Box
              sx={{ borderRadius: "50%", border: "2px solid #5C5C5C" }}
            ></Box>

            <Typography
              variant="medium"
              sx={{ color: (theme) => theme.palette.primary.main }}
            >
              {data?.instructorNumber ?? 0} Facilitators
            </Typography>
          </>
        )}
      </Box>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          top: 0,
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            p: 2,
            ...dFlex,
            flexDirection: "column",
            gap: 1,
            borderRadius: "8px",
          }}
        >
          <Typography
            variant="medium2"
            sx={{
              color: (theme) => theme.palette.text.light,
              cursor: "pointer",
            }}
            onClick={() => {
              if (completed) {
                navigate(`completed/${data?.batchName}`, {
                  state: {
                    batchDetail: data,
                  },
                });
                return;
              }
              navigate(`${data?.batchName}`, {
                state: {
                  batchDetail: data,
                },
              });
            }}
          >
            View
          </Typography>
          {!completed && (
            <>
              <Typography
                variant="medium2"
                onClick={() => {
                  if (data?.studentNumber >= 50) {
                    toast.error(`Maximum of 50 student can be added`);
                    return;
                  }

                  navigate("add-student", {
                    state: {
                      batchDetail: data,
                    },
                  });
                }}
                sx={{
                  color: (theme) => theme.palette.text.light,
                  cursor: "pointer",
                }}
              >
                Add Student
              </Typography>
              <Typography
                variant="medium2"
                onClick={handleMarkAsCompleted}
                sx={{
                  color: (theme) => theme.palette.text.light,
                  cursor: "pointer",
                }}
              >
                Mark As Completed
              </Typography>
            </>
          )}
        </Box>
      </Popover>
    </Box>
  );
}
