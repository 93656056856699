import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { dFlex, itemsCenter } from "../../../../common/commonStyles";
import { PublishIcon, SvgVector } from "../../../../images/icons";
import DeleteGameModal from "./DeleteGameModal";
import UploadBadgeModal from "./UploadBadgeModal";
import UploadGameModal from "./UploadGameModal";

export default function Games() {
  const [sequence, setSequence] = useState([
    {
      id: "f89e460d-370a-4df6-b281-7cdf8ecdac2e",
      title: "Module 1",
      type: "module",
    },
    {
      id: "c332ad74-cc79-40ed-873f-6b3d58af5705",
      title: "Module 2",
      type: "module",
    },
    {
      id: "532356e6-e3c5-4570-af5c-fbff3e133fa5",
      title: "Module 3",
      type: "module",
    },
    {
      id: "5323566-e3c5-4570-af5c-fbff3e133fa5",
      title: "Game 1",
      type: "game",
    },
    {
      id: "5323e6-e3c5-4570-af5c-fbff3e133fa5",
      title: "Game 2",
      type: "game",
    },
    {
      id: "532356e6-e3c5-4570-af5c-ff3e133fa5",
      title: "Badge 1",
      type: "badge",
    },
  ]);

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const newItems = Array.from(sequence);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setSequence(newItems);
    // setShow(true);
  }

  const handlePublish = () => {
    toast("Contents are published");
  };

  return (
    <Box>
      <Box
        sx={{
          ...dFlex,
          justifyContent: "flex-end",
          mb: 4,
        }}
      >
        <Button onClick={handlePublish} variant="primary">
          <PublishIcon /> Publish
        </Button>
      </Box>

      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.secondary,
          p: 3,
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            ...dFlex,
            justifyContent: "flex-end",
            gap: 1,
            mb: 4,
          }}
        >
          <UploadBadgeModal />
          <UploadGameModal />
        </Box>
        <Box sx={{ mt: 2, px: 2, height: "50vh", overflow: "scroll" }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {sequence.map((item, id) => (
                    <Draggable key={item.id} draggableId={item.id} index={id}>
                      {(provided, snapshot) => (
                        <Box
                          ref={provided.innerRef}
                          snapshot={snapshot}
                          key={item.id}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            my: 2,
                            padding: "14px 24px",
                            backgroundColor:
                              item.type == "module" ? "#C4DDDD" : "#FFF",
                            border: "1px solid #BFBFBF",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.12)",
                            ...itemsCenter,
                            borderRadius: "5px",
                          }}
                        >
                          <IconButton sx={{ py: 0 }}>
                            <SvgVector fill={"#358B8A"} />
                          </IconButton>
                          <Typography
                            variant="bold2"
                            sx={{ color: (theme) => theme.palette.text.main }}
                          >
                            {item.title}
                          </Typography>
                          {item.type !== "module" && (
                            <Box sx={{ ml: "auto", mr: "1px" }}>
                              {item.type == "game" ? (
                                <UploadGameModal edit={true} />
                              ) : (
                                <UploadBadgeModal edit={true} />
                              )}
                              <DeleteGameModal />
                            </Box>
                          )}
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Box>
    </Box>
  );
}
