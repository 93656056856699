import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Avatar, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ChatOtherComponent from "../../../Components/Parent/CommunityChannel/ChatInfoPage/ChatOtherComponent";
import ChatVideoComponent from "../../../Components/Parent/CommunityChannel/ChatInfoPage/ChatVideoComponent";
import SuspendStudentModal from "../../../Components/Parent/CommunityChannel/SuspendStudentModal";
import {
  dFlex,
  flexBetweenCenter,
  itemsCenter,
} from "../../../common/commonStyles";
export default function ParentChatMediaInfo() {
  const [chatDetails, setChatDetails] = useState({
    type: null,
  });
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const { chatDetails } = location?.state ?? { type: null };
    setChatDetails(chatDetails);

    return () => {
      setChatDetails({
        type: null,
      });
    };
  }, [location?.useState]);

  return (
    <Box>
      <Box sx={{ mb: 2.5 }}>
        {/* Navigation back button */}
        <Typography
          variant="bold"
          sx={{
            ...itemsCenter,

            cursor: "pointer",
            color: (theme) => theme.palette.primary.main,
            "&:hover": { textDecoration: "underline" },
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <NavigateBeforeIcon />
          {chatDetails?.name ?? ""}
        </Typography>
      </Box>
      {chatDetails?.type === "channel" && (
        <Box sx={{ ml: 3, mb: 5 }}>
          <Typography
            variant="bold"
            sx={{
              // ...itemsCenter,

              color: (theme) => theme.palette.primary.main,
            }}
          >
            Student :{" "}
            <Typography
              variant="bold"
              sx={{ color: (theme) => theme.palette.text.main }}
            >
              {" "}
              50
            </Typography>
          </Typography>
        </Box>
      )}
      <Box sx={{ ml: 3, mb: 5 }}>
        <Typography
          variant="bold"
          sx={{
            // ...itemsCenter,
            color: (theme) => theme.palette.primary.main,
          }}
        >
          Media Shared
        </Typography>
        <Box
          sx={{
            ...dFlex,
            flexWrap: "wrap",
            rowGap: 2,
            columnGap: 5,
            mt: 3,
          }}
        >
          <ChatVideoComponent />
          <ChatOtherComponent data={{ type: "jpg" }} />
          <ChatOtherComponent data={{ type: "docs" }} />
          <ChatOtherComponent data={{ type: "pdf" }} />
          <ChatOtherComponent data={{ type: "ppt" }} />
          <ChatOtherComponent data={{ type: "txt" }} />
          <ChatOtherComponent data={{ type: "xlxs" }} />
          <ChatOtherComponent data={{ type: "zip" }} />
        </Box>
      </Box>
      {chatDetails?.type === "channel" && (
        <Box sx={{ ml: 3 }}>
          <Typography
            variant="bold"
            sx={{
              // ...itemsCenter,
              color: (theme) => theme.palette.primary.main,
            }}
          >
            Suspended Students
          </Typography>
          <Box
            sx={{
              ...dFlex,
              flexWrap: "wrap",
              flexDirection: "column",
              rowGap: 2,
              columnGap: 5,
              mt: 3,
              width: "32%",
            }}
          >
            <StudentCard />
            <StudentCard />
            <StudentCard />
          </Box>
        </Box>
      )}
    </Box>
  );
}

function StudentCard(details) {
  return (
    <Box
      sx={{
        ...itemsCenter,
        pt: 2,
        mt: 2,
        gap: 2,
        px: 1.5,
        cursor: "pointer",
      }}
    >
      <Avatar
        src="https://cdn.pixabay.com/photo/2023/05/18/18/54/ibex-8003063_1280.jpg"
        sx={{
          width: "60px",
          height: "60px",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        G
      </Avatar>
      <Box sx={{ ...dFlex, flexDirection: "column", width: "100%" }}>
        <Box sx={{ ...flexBetweenCenter, justifyContent: "space-between" }}>
          <Typography
            variant="semibold"
            sx={{
              color: (theme) => theme.palette.text.dark,
            }}
          >
            Student Name 1
          </Typography>

          <Typography
            variant="medium2"
            sx={{
              color: (theme) => theme.palette.text.light,
            }}
          >
            ID 1234
          </Typography>
        </Box>
      </Box>
      <SuspendStudentModal suspended={true} />
    </Box>
  );
}
