import { Box, Button, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../../Apis/axiosPayloads";
import useDataFetchHook from "../../../../Hooks/DataFetchHook";
import { dFlex, flexCenter } from "../../../../common/commonStyles";
import { emptyDataSA } from "../../../../const/imageUrl";
import { ModuleCreateIcon, PublishIcon } from "../../../../images/icons";
import ItemVideoError from "../ItemVideoError";
import ModuleCard from "./ModuleCard";

export default function Modules() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [itemError, setItemError] = useState(false);
  const url = process.env.REACT_APP_MODULE;
  const [apiCall, fetchAgain, dispatchApi] = useDataFetchHook(url);
  const handlePublish = async () => {
    try {
      if (loading) {
        toast.error("Another request is in progress");
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_MODULE_PUBLISH;
      const config = createAxiosConfig({
        endPoint: url,
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.type == "videoError") {
        setItemError(true);
      }
      toast.error(err?.response?.data?.message);
    }
    setLoading(false);
  };

  // const handleOnDragEnd = async (result) => {
  //   try {
  //     if (!result.destination || apiCall?.loading) {
  //       toast.error("Another request is in progress");
  //       return;
  //     }
  //     const items = Array.from(apiCall?.res?.module);
  //     const [reorderedItem] = items.splice(result.source.index, 1);
  //     items.splice(result.destination.index, 0, reorderedItem);
  //     dispatchApi({ type: request });
  //     const url = process.env.REACT_APP_MODULE_REORDER;
  //     const formattedData = {
  //       modules: items.map((e) => e.moduleId),
  //     };
  //     const config = createAxiosConfig({
  //       endPoint: url,
  //       method: PATCH,
  //       data: formattedData,
  //     });
  //     const apiRes = await axios.request(config);
  //     toast(apiRes?.data?.message);
  //     dispatchApi({ type: success, data: { module: items } });
  //     setLoading(false);
  //   } catch (err) {
  //     console.log(err);
  //     dispatchApi({ type: error, data: { message: "something Went wrong" } });
  //     toast.error(err?.response?.data?.message);
  //     setLoading(false);
  //   }
  // };

  return (
    <Box>
      <Box
        sx={{
          ...dFlex,
          justifyContent: "flex-end",
          mb: 4,
        }}
      >
        <Button
          variant="primary"
          onClick={() => {
            navigate("builder");
          }}
        >
          <ModuleCreateIcon fetchAgain={fetchAgain} /> Create Module
        </Button>
        <Button onClick={handlePublish} variant="primary">
          <PublishIcon /> Publish
        </Button>
      </Box>
      {(apiCall?.loading || loading) && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />
        </Box>
      )}
      <ItemVideoError open={itemError} setOpen={setItemError} />

      {/* {apiCall?.error && (
        <Box sx={{ ...flexCenter, flexDirection: "column", mt: 20 }}>
          {apiCall?.errorMessage ?? "Something went wrong"}
          <Button variant="primary-small" onClick={fetchAgain}>
            Retry
          </Button>
        </Box>
      )} */}
      {!apiCall.loading && apiCall?.res?.module?.length < 1 && (
        <Box
          sx={{
            mt: 3,
            backgroundColor: (theme) => theme.palette.background.secondary,
            ...flexCenter,
            flexDirection: "column",
            py: 8,
            gap: 5,
            borderRadius: "12px",
          }}
        >
          <img src={emptyDataSA} style={{ height: "254px", width: "345px" }} />
          <Typography
            variant="bold"
            sx={{ color: (theme) => theme.palette.text.light }}
          >
            You have not added any category
          </Typography>
          {/* <AddCategoryModal fetchAgain={fetchAgain} /> */}
        </Box>
      )}
      <Box>
        {apiCall?.res?.module?.map((e, i) => (
          <ModuleCard
            data={e}
            key={e.moduleId}
            index={i}
            fetchAgain={fetchAgain}
          />
        ))}

        {/* <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {apiCall?.res?.module?.map((e, i) => (
                  <Draggable
                    key={e.moduleId}
                    draggableId={e.moduleId}
                    index={i}
                  >
                    {(provided, snapshot) => (
                      <ModuleCard
                        provided={provided}
                        snapshot={snapshot}
                        data={e}
                        key={e.moduleId}
                        index={i}
                        fetchAgain={fetchAgain}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext> */}
      </Box>
    </Box>
  );
}
