import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import { itemsCenter } from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { IssueCertificateIcon } from "../../../images/icons";
export default function IssueCertificate({
  data,
  fetchAgain,
  sendNotificationToGroupUsers,
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tokens, setTokens] = useState("");
  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (e) => {
    if (e.target.value?.length > 50) {
      toast.error("Length cannot be greater than 50");
      return;
    }
    setTokens(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = async () => {
    try {
      if (!tokens?.trim()) {
        toast.error("Tokens cannot be empty");
        return;
      }
      if (tokens < 20) {
        toast.error("Tokens cannot be less than 20");
        return;
      }
      if (tokens > 150) {
        toast.error("Tokens cannot be greater than 150");
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_FACILITATOR_ISSUE_CERTIFICATE;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: { tokens: Math.abs(tokens), groupId: data?.groupId },
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      sendNotificationToGroupUsers(
        "Certificate Issued",
        "Certificate has been issued, click here to view the Certificate"
      );
      fetchAgain();
      handleClose();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <>
      <Button
        disabled={data?.certificateIssued || !data?.answerSubmitted}
        onClick={handleOpen}
        variant="primary"
      >
        <IssueCertificateIcon />
        Issue Certificate
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: (theme) => theme.palette.background.main,
            borderRadius: "14px",
            p: 3,
            maxWidth: "100%",
            width: "32%",
          },
        }}
      >
        <Typography
          variant="medium"
          sx={{
            color: (theme) => theme.palette.text.main,
          }}
        >
          Distribute Token score to the students before issuing the Certificate
        </Typography>
        <Typography
          variant="medium2"
          sx={{
            my: 1,
            color: (theme) => theme.palette.content.quizQuestion,
          }}
        >
          Tokens you entered below will be shared to each of the student.{" "}
        </Typography>
        <DialogContent sx={{ p: 0 }}>
          <DialogContentText>
            <Box
              sx={{
                ...itemsCenter,
                width: "100%",
                my: "20px",
                gap: 2,
              }}
            >
              <Typography variant="medium" sx={{ width: "28%" }}>
                Tokens
              </Typography>
              <FormTextField
                placeholder="150"
                InputProps={{
                  inputProps: {
                    min: 20,
                    max: 150,
                  },
                }}
                value={tokens}
                onChange={handleChange}
                sx={{ width: "65%" }}
                type="number"
                autoComplete="off"
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            disabled={loading}
            sx={{
              fontWeight: 500,
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleAdd} disabled={loading} variant="primary">
            Issue Certificate
          </Button>
        </DialogActions>
        {/* </form> */}
      </Dialog>
    </>
  );
}
