import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormHelperText,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { dFlex } from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";

export default function ChangeAdmin() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      let nsn = data.email.replaceAll(" ", "");

      setLoading(true);
      const url = process.env.REACT_APP_SA_CHANGE_SA;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...data,
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      setLoading(false);
      reset();
      localStorage.clear();
      navigate("/");
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Change Admin"]} />
      <Box>
        <Box
          sx={{
            ...dFlex,
            flexDirection: "column",
            gap: 1,
            width: "400px",
            mb: 2,
          }}
        >
          <Typography
            variant="medium"
            sx={{
              color: (theme) => theme.palette.text.main,
            }}
          >
            Enter the E-mail ID of the new admin
          </Typography>
          <FormTextField
            placeholder="admin@gmail.com"
            inputProps={{
              style: {
                textTransform: "lowercase",
              },
            }}
            {...register("email", {
              required: "This field is mandatory",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid Email Address",
              },
            })}
            error={Boolean(errors?.email)}
            sx={{
              maxWidth: "460px",
            }}
          />
          {Boolean(errors?.email) && (
            <FormHelperText sx={{ textAlign: "right", mt: 0 }} error={true}>
              <Typography variant="semibold2">
                {errors?.email?.message}
              </Typography>
            </FormHelperText>
          )}
        </Box>
        <ConformationDialog
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          loading={loading}
        />
      </Box>
    </Box>
  );
}

function ConformationDialog({ handleSubmit, onSubmit, loading }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        disabled={loading}
        variant="primary-medium"
        onClick={handleSubmit(handleOpen)}
      >
        Save
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "13px",
            backgroundColor: (theme) => theme.palette.background.main,
          },
        }}
      >
        <Box sx={{ width: "515px", pb: "20px" }}>
          {/* <form onSubmit={handleDeleteAdmission} autoComplete="off"> */}
          <DialogContent sx={{ mb: 2 }}>
            <DialogContentText id="alert-dialog-description">
              <Typography
                variant="regular"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Are you sure you want to change the admin?
              </Typography>
              <br />
              <Typography
                variant="regular"
                sx={{
                  color: (theme) => theme.palette.content.quizQuestion,
                }}
              >
                Note: You will loose the access immediately
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button disabled={loading} onClick={onSubmit} variant="primary">
              Save
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
