import { Box, IconButton, LinearProgress, Typography } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { flexCenter, itemsCenter } from "../common/commonStyles";
import { formatTime, normalize } from "./constFunctions";
import { Back30SecIcon, Forward30SecIcon } from "../images/icons";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import Error404Page from "../Pages/Error404Page";

function VideoPlayer({ data }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const playerRef = useRef(null);

  const handlePlayPause = () => {
    const player = playerRef.current;

    if (isPlaying) {
      player.pause();
    } else {
      player.play();
    }

    setIsPlaying(!isPlaying);
  };

  const handleFastForward = () => {
    const player = playerRef.current;
    player.currentTime += 30;
  };

  const handleBackward = () => {
    const player = playerRef.current;
    player.currentTime -= 30;
  };

  const handleTimeUpdate = () => {
    const player = playerRef.current;
    setCurrentTime(player.currentTime);
  };

  const handleInit = () => {
    try {
      const player = playerRef.current;
      player.currentTime = 0;
      player.play();
      setIsPlaying(true);
      setCurrentTime(0);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleInit();
  }, [data]);

  return (
    <Box>
      {/* {console.log({ data })} */}
      <Box
        sx={{
          // height: "240px",
          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12)",
          pt: 2,
          ...itemsCenter,
          flexDirection: "column",
        }}
      >
        <video
          ref={playerRef}
          style={{ width: "80%" }}
          onEnded={handlePlayPause}
          src={data?.file}
          onTimeUpdate={handleTimeUpdate}
        >
          <Error404Page />
        </video>
        <Typography variant="medium" sx={{ mt: 3 }}>
          {data?.title ?? "NA"}
        </Typography>
        <Box sx={{ width: "80%", mt: "16px" }}>
          <LinearProgress
            variant="determinate"
            value={
              currentTime > 0 &&
              normalize(currentTime, playerRef.current?.duration)
            }
            sx={{
              "& .MuiLinearProgress-bar": {
                borderRadius: "2px",
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "6px",
              color: (theme) => theme.palette.text.secondary,
              fontSize: "12px",
            }}
          >
            <Typography variant="caption">{formatTime(currentTime)}</Typography>
            <Typography variant="caption">
              {formatTime(playerRef.current?.duration)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          ...flexCenter,
          mt: 3,
          gap: 3,
        }}
      >
        <IconButton onClick={handleBackward} sx={{ p: 0 }}>
          {/* -30 */}
          <Back30SecIcon />
        </IconButton>
        <IconButton
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            borderRadius: "50%",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.primary.main,
            },
          }}
          onClick={handlePlayPause}
        >
          {isPlaying ? (
            <PauseIcon fontSize="large" sx={{ color: "white", p: 0 }} />
          ) : (
            <PlayArrowIcon fontSize="large" sx={{ color: "white", p: 0 }} />
          )}
        </IconButton>
        <IconButton onClick={handleFastForward} sx={{ p: 0 }}>
          <Forward30SecIcon />
        </IconButton>
      </Box>{" "}
    </Box>
  );
}

export default VideoPlayer;
