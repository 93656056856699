import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TablePagination,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import useTablePagination from "../../../Hooks/TablePaginationHook";
import {
  dFlex,
  flexBetweenCenter,
  flexCenter,
} from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { BatchEmpty } from "../../../const/imageUrl";
import { UserContext } from "../../../context/UserContext";
import { ArchiveIcon, RegisterIcon } from "../../../images/icons";
import StudentCard from "./StudentCard";
export default function StudentList() {
  const navigate = useNavigate();
  const [deletePermissionApi] = useDataFetchHook(
    process.env.REACT_APP_STUDENT_DELETE_PERMISSION
  );
  const { userDetails } = useContext(UserContext);
  const [url, setUrl] = useState(null);
  const [batchData, fetchBatchData, dispatchData] = useDataFetchHook(url);
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();

  useEffect(() => {
    const params = new URLSearchParams();
    const newUrl =
      process.env.REACT_APP_PARENT_STUDENT_LIST +
      "?filterCompleted=true" +
      params.toString();
    setUrl(newUrl);
    return () => {};
  }, []);

  const handleSearch = () => {
    // console.log("searhing");
    let arr = [...batchData?.res?.Student];
    arr = arr?.filter((e) => {
      return e?.studentName?.toLowerCase()?.includes(searchText.toLowerCase());
    });
    handleChangePage(null, 0);
    setSearchData(arr);
  };

  useEffect(() => {
    if (!searchText) {
      setSearchData(batchData?.res?.Student ?? []);
      return;
    }
    handleSearch();
  }, [searchText]);

  return (
    <Box>
      <Box sx={{ ...flexBetweenCenter, mb: 3 }}>
        <Box
          sx={{
            ...dFlex,
            gap: 2,
            alignItems: "center",
            boxSizing: "border-box",
          }}
        >
          <FormTextField
            placeholder="search"
            disabled={batchData?.loading}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            value={searchText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    fontSize="small"
                    sx={{
                      color: "#BFBFBF",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <Button
            variant="primary"
            onClick={() => {
              navigate("archived");
            }}
            sx={{ mr: 2 }}
          >
            <ArchiveIcon fill={"#FFF"} />
            Archived
          </Button>
          <Button
            variant="primary"
            disabled={
              userDetails?.isArchived || userDetails.subscriptionExpired
            }
            onClick={() => {
              navigate("add-student");
            }}
          >
            <RegisterIcon />
            Add Student
          </Button>
        </Box>
      </Box>
      {batchData?.res?.Student?.length < 1 && (
        <Box
          sx={{
            mt: 3,
            backgroundColor: (theme) => theme.palette.background.secondary,
            ...flexCenter,
            flexDirection: "column",
            py: 8,
            gap: 5,
            borderRadius: "12px",
          }}
        >
          <img src={BatchEmpty} style={{ height: "220px", width: "600px" }} />
          <Typography
            variant="bold"
            sx={{ color: (theme) => theme.palette.text.light }}
          >
            You have not added any students yet
          </Typography>
          <Button
            onClick={() => {
              navigate("add-student");
            }}
            variant="primary"
          >
            <RegisterIcon /> Add Student
          </Button>
        </Box>
      )}
      {batchData?.loading && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />
        </Box>
      )}
      {!searchText && (
        <Box sx={{ my: 3 }}>
          {batchData?.res?.Student?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )?.map((e, i) => (
            <StudentCard
              key={e?.id}
              data={e}
              fetchAgain={fetchBatchData}
              index={i}
              disabled={
                userDetails?.isArchived || userDetails.subscriptionExpired
              }
            />
          ))}
        </Box>
      )}
      {searchText && (
        <Box sx={{ my: 3 }}>
          {searchData
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((e, i) => (
              <StudentCard
                key={e?.id}
                data={e}
                fetchAgain={fetchBatchData}
                index={i}
              />
            ))}
        </Box>
      )}
      {batchData?.res?.Student?.length >= 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            rowsPerPageOptions={[]}
            count={searchText ? searchData?.length : batchData?.res?.totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
}
