import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { flexBetweenWithItemsCenter } from "../../../common/commonStyles";
import { BigRightArrow } from "../../../images/icons";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { GET } from "../../../Apis/constVariables";
import axios from "axios";

export default function DemographicData() {
  const navigate = useNavigate();
  const [cards, setCards] = useState([
    { label: "ACE", to: "ace-test" },
    { label: "SDQ Test", to: "sdq-test" },
    // { label: "Module 1  Test", to: "1" },
    // { label: "Module 2  Test", to: "2" },
    // { label: "Module 3  Test", to: "3" },
  ]);

  const [moduleData, setModuleData] = useState([]);
  const getModuleData = async () => {
    const url = process.env.REACT_APP_MODULE;
    const config = createAxiosConfig({
      endPoint: url,
      method: GET,
    });
    // console.log(config);
    const apiRes = await axios.request(config);
    apiRes.data["module"].map((_, idx) => {
      setCards([
        ...cards,
        { label: `Module ${idx + 1}`, data: _, to: `${idx + 1}` },
      ]);
    });
    setModuleData(apiRes.data.data);
  };
  useEffect(() => {
    getModuleData();
  }, []);

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Demographic Data"]} />

      {cards?.map((e) => (
        <Box
          sx={{
            ...flexBetweenWithItemsCenter,
            px: "24px",
            my: 2,
            width: "90%",
            minHeight: "12vh",
            borderRadius: "14px",
            backgroundColor: (theme) => theme.palette.background.secondary,
            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.12)",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(e.to, {
              state: {
                data: e,
              },
            });
          }}
        >
          <Typography
            variant="bold"
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {e.label}
          </Typography>
          <IconButton
            onClick={() => {
              navigate(e.to, {
                state: {
                  data: e,
                },
              });
            }}
          >
            <BigRightArrow />
          </IconButton>
        </Box>
      ))}
    </Box>
  );
}
