import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { PATCH, POST } from "../../../Apis/constVariables";
import { itemsCenter } from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { AddIconBig, RemoveIconBig } from "../../../images/icons";
import { validateBasicQuestionForm } from "../CreateQuestion";
import "../styles/createQuiz.css";
const CreateMcqQuestion = ({
  editAble,
  questionFormData,
  fetchQuizQuestionsAgain,
  toggleQuestionForm,
}) => {
  const [mcqQuestions, setMcqQuestions] = useState(["", "", ""]);
  const [mcqType, setMcqType] = useState("single");
  const [mcqAnswers, setMcqAnswers] = useState([]);
  const [scqAnswer, setScqAnswer] = useState(0);
  const [loading, setLoading] = useState(false);
  const handleAdd = () => {
    setMcqQuestions([...mcqQuestions, ""]);
  };
  const handleRemove = (id) => {
    let abc = [...mcqQuestions];
    abc = abc.filter((e, i) => {
      return i !== id;
    });
    setMcqQuestions(abc);
  };

  const handleMCQOptionChange = (event) => {
    const {
      target: { value },
    } = event;
    setMcqAnswers(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  function submit(e) {
    e.preventDefault();
  }

  const addData = async () => {
    try {
      const formValidator = validateBasicQuestionForm(questionFormData);
      if (formValidator?.error) {
        toast.error(formValidator.message);
        return;
      }
      let nsn = questionFormData.questionName.replaceAll(" ", "");
      let dat = /\//i.test(nsn);
      if (!dat) {
      } else {
        toast.error("You can not use any (/) characters");
        return;
      }
      let err = false;
      mcqQuestions?.forEach((e) => {
        if (!e?.trim()) {
          err = true;
        }
      });
      if (err) {
        toast.error("Fields cannot be empty");
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_QUESTION;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...questionFormData,
          data: {
            options: mcqQuestions,
            choiceType: mcqType,
            correctOptions: mcqType == "single" ? scqAnswer : mcqAnswers,
          },
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchQuizQuestionsAgain();
      toggleQuestionForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
  const updateData = async () => {
    try {
      const formValidator = validateBasicQuestionForm(questionFormData);
      if (formValidator?.error) {
        toast.error(formValidator.message);
        return;
      }
      let nsn = questionFormData.questionName.replaceAll(" ", "");
      let dat = /\//i.test(nsn);
      if (!dat) {
      } else {
        toast.error("You can not use any (/) characters");
        return;
      }
      let err = false;
      mcqQuestions?.forEach((e) => {
        if (!e?.trim()) {
          err = true;
        }
      });
      if (err) {
        toast.error("Fields cannot be empty");
        return;
      }
      setLoading(true);
      const url =
        process.env.REACT_APP_QUESTION + `/${questionFormData?.questionId}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: {
          ...questionFormData,
          data: {
            options: mcqQuestions,
            choiceType: mcqType,
            correctOptions: mcqType == "single" ? scqAnswer : mcqAnswers,
          },
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchQuizQuestionsAgain();
      toggleQuestionForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (!questionFormData?.data) {
      return;
    }
    setMcqQuestions(questionFormData?.data?.options);
    setMcqType(questionFormData?.data?.choiceType);
    if (questionFormData?.data?.choiceType == "single") {
      if (typeof questionFormData?.data?.correctOptions == "object") {
        setScqAnswer(questionFormData?.data?.correctOptions?.[0]);
      } else {
        setScqAnswer(questionFormData?.data?.correctOptions);
      }
    } else {
      setMcqAnswers(questionFormData?.data?.correctOptions);
    }

    return () => {};
  }, [questionFormData?.data]);

  return (
    <section className="quizForm-container">
      <form onSubmit={submit}>
        {mcqQuestions?.map((e, i) => (
          <Box className="quizForm-mainForm-infoRow" sx={{ ...itemsCenter }}>
            <>
              <label className="quizForm-mainForm-label">
                Option {i + 1} :
              </label>
              <FormTextField
                className="quizForm-mainForm-inputBar"
                name="text"
                autoFocus={i > 1}
                value={e}
                placeholder="Enter Option"
                onChange={(event) => {
                  const { value } = event.target;
                  setMcqQuestions((prevQuestions) =>
                    prevQuestions.map((question, index) =>
                      index === i ? value : question
                    )
                  );
                }}
              />
            </>
            {i > mcqQuestions.length - 2 && i < 9 && (
              <IconButton onClick={handleAdd}>
                <AddIconBig />
              </IconButton>
            )}
            {i > 1 && (
              <IconButton onClick={() => handleRemove(i)}>
                <RemoveIconBig />
              </IconButton>
            )}
          </Box>
        ))}
        <Box className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">Type of Choice</label>
          <Select
            value={mcqType}
            sx={{
              width: "34%",
              height: "36px",
            }}
            onChange={(event) => {
              setMcqType(event.target.value);
            }}
          >
            <MenuItem value="single">
              <Typography
                variant="medium"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Select any one
              </Typography>
            </MenuItem>
            <MenuItem value={"multiple"}>
              <Typography
                variant="medium"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Select all that applies
              </Typography>
            </MenuItem>
          </Select>
        </Box>
        {mcqType == "single" && (
          <Box className="quizForm-mainForm-infoRow">
            <label className="quizForm-mainForm-label">
              Choose the correct Option :
            </label>
            <Select
              value={scqAnswer}
              sx={{
                width: "34%",
                height: "36px",
              }}
              onChange={(event) => {
                setScqAnswer(event.target.value);
              }}
            >
              {mcqQuestions?.map((e, i) => (
                <MenuItem value={i}>
                  <Typography
                    variant="medium"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    Option {i + 1}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
        {mcqType == "multiple" && (
          <Box className="quizForm-mainForm-infoRow">
            <label className="quizForm-mainForm-label">
              Choose the correct Options :
            </label>
            <Select
              value={mcqAnswers}
              multiple
              sx={{
                width: "34%",
                height: "36px",
              }}
              renderValue={(selected) =>
                "Option " + selected.map((e) => e + 1).join(",Option ")
              }
              onChange={handleMCQOptionChange}
            >
              {mcqQuestions?.map((e, i) => (
                <MenuItem value={i}>
                  <Typography
                    variant="medium"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    Option {i + 1}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
      </form>
      <Box className="quizForm-mainForm-infoRow">
        <Button
          sx={{
            textTransform: "none",
          }}
          onClick={toggleQuestionForm}
          style={{ marginLeft: "auto" }}
        >
          Cancel
        </Button>
        <Button
          variant="primary-small"
          disabled={loading}
          onClick={editAble ? updateData : addData}
        >
          Save
        </Button>
      </Box>
    </section>
  );
};

export default CreateMcqQuestion;
