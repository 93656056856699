import { RemoveRedEye } from "@mui/icons-material";
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../../Apis/axiosPayloads";
import { PATCH, POST } from "../../../../Apis/constVariables";
import {
  dFlex,
  flexBetweenWithItemsCenter,
  flexCenter,
  itemsCenter,
} from "../../../../common/commonStyles";
import { FormTextField } from "../../../../const/TextField";
import {
  checkSize,
  convertToBase64File,
} from "../../../../const/constFunctions";
import { EditIconColor } from "../../../../images/icons";
import DeleteIntroSlideModal from "./DeleteIntroSildeModal";

export default function AddIntroSlide({
  setAdd,
  edit,
  slideData,
  slideLength,
  index,
  type,
  fetchAgain,
}) {
  const [editable, setEditable] = useState(edit);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    type: type,
    message: "",
  });
  const [file, setFile] = useState(null);
  const handleImageChange = async (e, i) => {
    try {
      const types = ["image", "video", "audio"];
      if (e?.target?.files.length > 0) {
        let file = e.target.files[0];
        // console.log({ file });
        let type = file?.type?.split("/")[0];
        if (!types.includes(type)) {
          setFile(null);
          e.target.value = "";
          toast.error("Unsupported type");
          return;
        }
        if (checkSize(file) > 10) {
          e.target.value = "";
          toast.error("Please upload an image less than 10MB");
          return;
        }
        const base64 = await convertToBase64File(file);
        setFile({
          file,
          base64: base64,
        });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  // const handleImageChange = (e) => {
  //   setFile(e.target?.files?.[0]);
  // };

  const handleSave = async () => {
    try {
      let nsn = data?.message.replaceAll(" ", "");
      let dat = /\//i.test(nsn);
      if (!file) {
        toast.error("File is required");
        return;
      }
      if (dat) {
        toast.error("you can not use any (/) characters");
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_INTRO_MESSAGE;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...data,
          fileType: file?.file?.type?.split("/")[0],
          file: file?.base64,
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchAgain();
      setAdd((p) => !p);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleEdit = async () => {
    try {
      let nsn = data?.message?.replaceAll(" ", "");
      if (!nsn) {
        toast.error("Please provide a message");
        return;
      }
      let dat = /\//i.test(nsn);
      if (dat) {
        toast.error("you can not use any (/) characters");
        return;
      }

      setLoading(true);
      const url = process.env.REACT_APP_INTRO_MESSAGE;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: {
          ...data,
          file: typeof file === "object" ? file?.base64 : file,
          fileType:
            typeof file === "object"
              ? file?.file?.type?.split("/")[0]
              : data?.fileType,
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchAgain();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
  const handleCancel = () => {
    setFile(null);
    if (edit) setEditable(true);
    else setAdd(false);
  };

  useEffect(() => {
    if (slideData) {
      // console.log({ slideData });
      setData({
        message: slideData?.message,
        id: slideData?.id,
        type: slideData?.type,
        file: slideData?.file,
        fileType: slideData?.fileType,
      });
      setFile(slideData?.file);
    }
  }, [slideData]);

  return (
    <Box
      sx={{
        minHeight: "45vh",
        backgroundColor: (theme) => theme.palette.background.secondary,
        boxSizing: "border-box",
        borderRadius: "6px",
        p: "24px 33px",
        my: 2,
      }}
    >
      <Box sx={{ ...flexBetweenWithItemsCenter }}>
        <Typography
          variant="bold"
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          {slideData ? `Slide ${index + 1}` : `Slide ${slideLength + 1}`}
        </Typography>
        {editable && (
          <Box>
            <IconButton onClick={() => setEditable(false)}>
              <EditIconColor />
            </IconButton>
            <DeleteIntroSlideModal data={data} fetchAgain={fetchAgain} />
          </Box>
        )}
      </Box>
      <Box sx={{ ...itemsCenter, gap: "20px", mt: "20px" }}>
        <Box
          component={"label"}
          sx={{
            ...flexCenter,
            height: "35px",
            width: "125px",
            cursor: "pointer",
            borderRadius: "4px",
            color: (theme) => theme.palette.text.main,
            backgroundColor: (theme) => theme.palette.text.lighter,
          }}
        >
          Choose a file
          <input
            disabled={editable}
            type="file"
            accept="image/*,audio/*,video/*"
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
        </Box>
        <Typography variant="semibold" sx={{ color: "#848484" }}>
          {file?.file ? file?.file?.name : "No File Chosen"}
        </Typography>
        {editable && (
          <IconButton
            onClick={() => {
              window.open(data?.file, "_blank");
            }}
            sx={{ p: 0, color: (theme) => theme.palette.primary.main }}
          >
            <RemoveRedEye />
          </IconButton>
        )}
      </Box>
      <Box sx={{ mt: "20px", ...dFlex }}>
        <Typography
          variant="semibold"
          sx={{ color: (theme) => theme.palette.text.main, mr: 3, mt: 2 }}
        >
          Message
        </Typography>
        <Box sx={{ width: "80%" }}>
          <FormHelperText
            sx={{ my: 0, width: "100%", textAlign: "right" }}
            error={132 > 500}
          >
            <Typography
              variant="semibold2"
              sx={{
                color: data?.message?.length >= 500 ? "red" : "#BFBFBF",
              }}
            >
              {data?.message?.length} / 500
            </Typography>
          </FormHelperText>
          <FormTextField
            disabled={editable}
            value={data?.message}
            onChange={(e) => {
              if (e?.target?.value?.length > 500) {
                setData({
                  ...data,
                  message: e.target.value.slice(0, 500),
                });
                toast.error("Max 500 characters allowed");
                return;
              }
              setData({
                ...data,
                message: e.target.value,
              });
            }}
            autoFocus
            sx={{ width: "100%" }}
            multiline
            rows={4}
          />
        </Box>
      </Box>

      {!editable && (
        <Box
          sx={{
            ...itemsCenter,
            gap: 2,
            justifyContent: "flex-end",
            mt: 3,
            mb: 1,
          }}
        >
          <Button
            onClick={handleCancel}
            sx={{ textTransform: "none", height: "28px" }}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            variant="primary-small"
            onClick={() => (edit ? handleEdit() : handleSave())}
          >
            Save
          </Button>
        </Box>
      )}
    </Box>
  );
}
