import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import {
  dFlex,
  flexBetweenCenter,
  textEllipses,
} from "../../../common/commonStyles";
import SDQQuestionAddModal from "./SDQQuestionAddModal";
import SDQQuestionDeleteModal from "./SDQQuestionDeleteModal";

export default function SDQQuestion({ index, data, fetchAgain }) {
  return (
    <Box sx={{ my: 4 }}>
      <Box
        sx={{ ...dFlex, justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography
          variant="medium"
          sx={{ color: (theme) => theme.palette.text.main, flex: "98%" }}
        >
          {index + 1}. {data?.question}.
        </Typography>
        <SDQQuestionAddModal edit={true} data={data} fetchAgain={fetchAgain} />
        <SDQQuestionDeleteModal data={data} fetchAgain={fetchAgain} />
      </Box>
      <Box sx={{ ...flexBetweenCenter, px: 3, mt: 1 }}>
        <RadioGroup row name="position" defaultValue="top">
          {data?.option?.map((e, i) => (
            <FormControlLabel
              value="bottom"
              disabled
              control={<Radio />}
              label={
                <Typography
                  variant="medium"
                  title={e}
                  sx={{
                    color: (theme) => theme.palette.text.main,
                    maxWidth: "240px",
                    ...textEllipses,
                  }}
                >
                  {e}
                </Typography>
              }
              labelPlacement="bottom"
            />
          ))}
        </RadioGroup>
      </Box>
    </Box>
  );
}
