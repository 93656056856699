import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import {
  flexBetweenCenter,
  flexCenter,
  itemsCenter,
} from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import sendNotification from "../../../functions/PushNotification";

export default function RequestCard({ data, handleOpen }) {
  return (
    <Box
      onClick={() => handleOpen(data)}
      sx={{
        ...itemsCenter,
        px: 2,
        py: 1,
        mb: 1.5,
        borderRadius: "6px",
        gap: 2,
        backgroundColor: (theme) => theme.palette.background.secondary,
        // boxShadow: "1px 1px 2px 0px rgba(0, 0, 0, 0.25)",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: (theme) => theme.palette.text.lighter,
        },
      }}
    >
      <Avatar
        sx={{
          height: "46px",
          width: "46px",
          textTransform: "capitalize",
        }}
      >
        {data?.admin?.studentName?.[0]}
      </Avatar>
      <Box sx={{ ...itemsCenter, gap: 0.5 }}>
        <Typography
          variant="semibold"
          sx={{ color: (theme) => theme.palette.text.dark }}
        >
          {data?.admin?.studentName ?? "NA"}
        </Typography>
        <Typography
          variant="semibold"
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          {" "}
          has requested for verification
        </Typography>
      </Box>
      <IconButton sx={{ p: 0, marginLeft: "auto" }}>
        {" "}
        <NavigateNextIcon fontSize="large" />
      </IconButton>{" "}
    </Box>
  );
}

export function RequestVerificationModal({
  data,
  open,
  handleClose,
  fetchAgain,
}) {
  const [url, setUrl] = useState(null);
  const [apiData, again, dispatch] = useDataFetchHook(url);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!data?.id) return;
    setUrl(process.env.REACT_APP_FACILITATOR_GET_GROUP_MEMBERS + data?.id);
  }, [data?.id]);

  const handleModalClose = () => {
    handleClose?.();
    setUrl(null);
    dispatch({ type: "init" });
  };

  const handleUpdate = async (status, reason = null) => {
    try {
      // console.log({ data });
      if (!data?.id) {
        toast.error("Group Id is required");
        return;
      }
      if (!status) {
        toast.error("Something went wrong!");
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_FACILITATOR_UPDATE_GROUP;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          groupId: data.id,
          status,
          reason,
        },
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      let membersArr = [...apiData?.res?.users, data?.admin];
      sendCommunityNotification(membersArr, status);
      fetchAgain();
      handleModalClose();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  const sendCommunityNotification = (arr = [], status) => {
    try {
      let title =
        status === "approved"
          ? "Community Group Approved"
          : "Community Group Rejected";
      let body =
        status === "approved"
          ? "Community Group request is approved"
          : "Community Group request is rejected";
      console.log({ arr });
      arr.forEach((e) => {
        sendNotification({
          deviceToken: e?.fcmToken ?? null,
          title,
          body,
          type: "community-project",
          userId: e?.id,
        });
      });
      // sendNotification({
      //   deviceToken: "",
      //   title,
      //   body,
      //   type: "community-project",
      //   userId: "",
      // });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleModalClose}
      PaperProps={{
        sx: {
          backgroundColor: (theme) => theme.palette.background?.main,
          borderRadius: "14px",
          p: 2,
          maxWidth: "100%",
          width: "50%",
        },
      }}
    >
      <Box sx={{ ...flexBetweenCenter, ml: 2 }}>
        <Typography
          variant="bold2"
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
        >
          Verification Request
        </Typography>
        <DialogActions>
          <Button
            disabled={loading || apiData.loading}
            onClick={() => handleUpdate("approved")}
            variant="primary-small"
          >
            <Typography variant="medium2">Approve</Typography>
          </Button>
          <RejectVerificationRequestModal
            handleUpdate={handleUpdate}
            disabled={loading || apiData.loading}
            data={data}
          />
        </DialogActions>
      </Box>
      <DialogContent sx={{ px: 1, py: 2 }}>
        <DialogContentText>
          <Box
            sx={{
              ...flexBetweenCenter,
              gap: 2,
              height: "70vh",
            }}
          >
            <Box
              sx={{
                flex: 1,
                backgroundColor: (theme) => theme.palette.background.secondary,
                height: "100%",
                borderRadius: "8px",
                px: 2,
              }}
            >
              <Box
                sx={{
                  py: 2,
                  borderBottom: "1px solid #BFBFBF",
                }}
              >
                <Typography
                  variant="bold2"
                  sx={{ color: (theme) => theme.palette.primary.main }}
                >
                  Admin
                </Typography>
                {data?.admin && <StudentCard data={data?.admin} />}
              </Box>
              <Box
                sx={{
                  my: 2,
                  maxHeight: "74%",
                  overflow: "scroll",
                  // position: "relative",
                }}
              >
                <Typography
                  variant="bold2"
                  sx={{
                    // display: "block",
                    // zIndex: 2,
                    backgroundColor: (theme) =>
                      theme.palette.background.secondary,
                    color: (theme) => theme.palette.primary.main,
                    // top: -15,
                    // position: "sticky",
                  }}
                >
                  Group Members
                </Typography>
                {apiData.loading && (
                  <Box sx={{ ...flexCenter, mt: 4 }}>
                    <CircularProgress />
                  </Box>
                )}
                {apiData?.res?.users?.map((e, i) => (
                  <StudentCard disabled={loading || apiData.loading} data={e} />
                ))}
              </Box>
            </Box>
            {/* divider line */}
            <Box
              sx={{
                border: "1px solid #BFBFBF",
                height: "100%",
              }}
            ></Box>
            {/* divider line end */}
            <Box
              sx={{
                flex: 1,
                backgroundColor: (theme) => theme.palette.background.secondary,
                height: "100%",
                borderRadius: "8px",
                px: 2,
              }}
            >
              <Box
                sx={{
                  py: 2,
                  borderBottom: "1px solid #BFBFBF",
                }}
              >
                <Typography
                  variant="bold2"
                  sx={{ color: (theme) => theme.palette.primary.main }}
                >
                  Group Name
                </Typography>
                <Box sx={{ ...itemsCenter, mt: 3.3, mb: 1.4 }}>
                  <Typography
                    variant="semibold"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    {data?.groupName ?? "NA"}
                  </Typography>
                </Box>{" "}
              </Box>
              <Box
                sx={{
                  my: 2,
                  maxHeight: "73%",
                  overflow: "auto",
                  // position: "relative",
                }}
              >
                <Typography
                  variant="bold2"
                  sx={{
                    // display: "block",
                    // zIndex: 2,
                    backgroundColor: (theme) =>
                      theme.palette.background.secondary,
                    color: (theme) => theme.palette.primary.main,
                    // top: -15,
                    // position: "sticky",
                  }}
                >
                  Idea
                </Typography>
                <Box
                  sx={{
                    border: "1px solid #BFBFBF",
                    p: 1,
                    mb: 1,
                    borderRadius: "8px",
                  }}
                >
                  {data?.idea}
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContentText>
      </DialogContent>
      <Button
        onClick={handleModalClose}
        sx={{
          mr: 2,
          alignSelf: "flex-end",
          textTransform: "none",
        }}
      >
        Cancel
      </Button>
      {/* </form> */}
    </Dialog>
  );
}

function StudentCard({ data }) {
  return (
    <Box sx={{ ...itemsCenter, gap: 2, mt: 2 }}>
      <Avatar
        src={data?.image}
        sx={{ height: "46px", width: "46px", textTransform: "capitalize" }}
      >
        {data?.studentName?.[0]}
      </Avatar>
      <Typography
        variant="semibold"
        sx={{ color: (theme) => theme.palette.text.dark }}
      >
        {data?.studentName}
      </Typography>
    </Box>
  );
}

function RejectVerificationRequestModal({ handleUpdate, disabled, data }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");
  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (e) => {
    setReason(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReject = () => {
    if (!reason.trim()) {
      toast.error("Reason cannot be empty");
    }
    handleUpdate("rejected", reason);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        disabled={disabled}
        variant="primary-small"
        sx={{
          //   backgroundColor: "red",
          backgroundImage:
            "radial-gradient(225.45% 125.44% at 41.91% 0%, #F2513F 0%, #D03725 99.89%);",
          "&:disabled": {
            backgroundImage:
              "radial-gradient(225.45% 125.44% at 41.91% 0%, #F2513F 0%, #D03725 99.89%);",
            opacity: "45%",
          },
        }}
      >
        <Typography variant="medium2">Reject</Typography>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: (theme) => theme.palette.background.main,
            borderRadius: "14px",
            px: 3,
            py: 2,
            maxWidth: "100%",
            width: "50%",
          },
        }}
      >
        <Typography
          variant="bold2"
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
        >
          Reason for rejection
        </Typography>
        <DialogContent sx={{ p: 0 }}>
          <DialogContentText>
            <Box
              sx={{
                my: "10px",
              }}
            >
              <FormTextField
                placeholder="Type a reason for rejection..."
                fullWidth
                multiline
                rows={12}
                value={reason}
                onChange={handleChange}
                autoComplete="none"
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              fontWeight: 500,
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            // onClick={edit ? handleEdit : handleAdd}
            onClick={handleReject}
            disabled={disabled}
            variant="primary-small"
          >
            Send
          </Button>
        </DialogActions>
        {/* </form> */}
      </Dialog>
    </>
  );
}
