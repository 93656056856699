import { Avatar, Box, IconButton, Typography } from "@mui/material";
import React from "react";
import {
  DeleteIcon,
  EditIconColor,
  MailIcon,
  PhoneIcon,
} from "../../../../images/icons";
import { flexBetweenWithItemsCenter } from "../../../../common/commonStyles";
import { itemsCenter } from "../../../../common/commonStyles";
import AddContentUploaderModal from "./AddContentUploaderModal";
import DeleteContentUploader from "./DeleteContentUploader";
export default function ContentUploaderCard({ data, fetchUploaderAgain }) {
  return (
    <Box
      sx={{
        minHeight: "10vh",
        height: "80px",
        maxHeight: "12vh",
        backgroundColor: (theme) => theme.palette.background.secondary,
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.12)",
        borderRadius: "8px",
        boxSizing: "border-box",
        my: 2,
        px: 3,
        ...flexBetweenWithItemsCenter,
      }}
    >
      <Box sx={{ ...flexBetweenWithItemsCenter, width: "50%" }}>
        <Box sx={{ ...itemsCenter, gap: "12px", minWidth: "75%" }}>
          <Avatar
            src={data?.imageUrl ?? ""}
            alt="profile-picture"
            sx={{ height: "59px", width: "59px", textTransform: "capitalize" }}
          >
            {data?.name?.[0]}
          </Avatar>
          <Typography
            variant="medium"
            sx={{ color: (theme) => theme.palette.text.main }}
          >
            {data?.name}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: (theme) => theme.palette.text.light,
              ...itemsCenter,
              gap: 1,
            }}
          >
            <MailIcon /> {data?.email}
          </Typography>
          <Typography
            sx={{
              color: (theme) => theme.palette.text.light,
              ...itemsCenter,
              gap: 1,
            }}
          >
            <PhoneIcon />
            {data?.phone}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "6%",
          minWidth: "fit-content",
          borderLeft: "1px solid #BFBFBF",
          pl: 2,
        }}
      >
        <AddContentUploaderModal
          data={data}
          fetchUploaderAgain={fetchUploaderAgain}
          edit={true}
        />
        <DeleteContentUploader
          data={data}
          fetchUploaderAgain={fetchUploaderAgain}
        />
      </Box>
    </Box>
  );
}
