import { Box } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import CreateCourse from "../../Components/Builder/CreateCourse";
import NavBreadCrumbs from "../../common/NavBreadCrumbs";

export default function UploaderCourseBuilder() {
  const location = useLocation();
  const editCourseId = location.state?.courseId;
  return (
    <Box>
      <NavBreadCrumbs
        pageTags={["Module", editCourseId ? "Edit Module" : "Create Module"]}
      />
      <CreateCourse />
    </Box>
  );
}
