import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import useTablePagination from "../../../Hooks/TablePaginationHook";
import DownloadSelect from "../../../common/DownloadSelect";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import ProgressBar from "../../../common/ProgressBar";
import {
  dFlex,
  flexBetweenCenter,
  flexSpaceBetween,
} from "../../../common/commonStyles";
import {
  StyledTableCell,
  StyledTableRow,
  TableRowSkeletonComponent,
} from "../../../const/Table";
import { FormTextField } from "../../../const/TextField";
import {
  ArrowDropDownIconSvg,
  ArrowDropUpIconSvg,
} from "../../../images/icons";
export default function SchoolStudentInformation() {
  const [moduleName, setModuleName] = React.useState([]);
  const [selectedSortUp, setSelectedSortUp] = useState("");
  const [selectedSortDown, setSelectedSortDown] = useState("");
  const params = useParams();
  const [studentListApi] = useDataFetchHook(
    process.env.REACT_APP_SA_DASHBOARD_LIST +
      `/subscriber/student?type=school&schoolId=${params?.schoolId}`
  );
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setModuleName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();
  const navigate = useNavigate();

  const [schoolData, setSchoolData] = useState({ name: "" });
  const location = useLocation();
  useEffect(() => {
    // Set school data from the location state
    const { school } = location?.state ?? { name: "" };
    setSchoolData(school);

    // Cleanup function to reset schoolData when component unmounts
    return () => {
      setSchoolData({ name: "" });
    };
  }, [location.state]);

  const [searchData, setSearchData] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const handleSearch = () => {
    try {
      // console.log("searhing");
      let arr = [...studentListApi?.res?.data];
      arr = arr?.filter((e) => {
        return e?.studentName
          ?.toLowerCase()
          ?.includes(searchText.toLowerCase());
      });
      handleChangePage(null, 0);
      setSearchData(arr);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClear = () => {
    setSelectedSortDown("");
    setSelectedSortUp("");
    setSearchText("");
    setSearchData(studentListApi?.res?.data);
  };

  const sortArr = (para) => {
    setSelectedSortUp(para);
    setSelectedSortDown("");

    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "studentName":
          fir = a.studentName.toLowerCase();
          sec = b.studentName.toLowerCase();
          break;
        case "totalTokens":
          fir = parseInt(a.totalTokens);
          sec = parseInt(b.totalTokens);
          break;
        case "rank":
          fir = parseInt(a.rank);
          sec = parseInt(b.rank);
          break;
        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir < sec) {
        return -1;
      }
      if (fir > sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  const sortArrDec = (para) => {
    setSelectedSortUp("");
    setSelectedSortDown(para);

    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "studentName":
          fir = a.studentName.toLowerCase();
          sec = b.studentName.toLowerCase();
          break;
        case "totalTokens":
          fir = parseInt(a.totalTokens);
          sec = parseInt(b.totalTokens);
          break;
        case "rank":
          fir = parseInt(a.rank);
          sec = parseInt(b.rank);
          break;
        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir > sec) {
        return -1;
      }
      if (fir < sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  useEffect(() => {
    if (!searchText) {
      setSearchData(studentListApi?.res?.data);
      return;
    }
    handleSearch();
  }, [searchText]);

  useEffect(() => {
    if (!studentListApi?.res) {
      return;
    }

    setSearchData(studentListApi?.res?.data);
    return () => {};
  }, [studentListApi?.res]);

  const downloadCsv = () => {
    let data = searchData;
    data = data.map((e) => {
      let formattedData = {
        "Student Name": e?.studentName,
        "Tokens Earned": e?.totalTokens,
        "School Rank": e?.rank,
        "Current Module Name": e?.moduleData?.moduleName ?? "NA",
        "Completion Percentage": `${e.percentage ?? 0}%`,
      };
      return formattedData;
    });

    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${
      schoolData?.name
    }_Student_Data_${new Date().toString()}.csv`;
    link.click();
  };

  return (
    <Box>
      <NavBreadCrumbs
        pageTags={[
          "Dashboard",
          "School Subscribers",
          schoolData?.name,
          "Student Information",
        ]}
      />
      <Box sx={{ ...flexBetweenCenter, mb: 3 }}>
        <Box
          sx={{
            ...dFlex,
            gap: 2,
            alignItems: "center",
            boxSizing: "border-box",
          }}
        >
          <FormTextField
            placeholder="search"
            // sx={{ width: "400px" }}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            disabled={studentListApi?.loading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    fontSize="small"
                    sx={{
                      color: "#BFBFBF",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          {/* <FormControl>
            <Select
              sx={{
                background: "#FFF",
                color: "#809fb8",
                height: "39px",
                minWidth: "155px",
                maxWidth: "220px",
                borderRadius: "7px",
                // border: "1px solid #358B8A",
              }}
              multiple
              value={moduleName}
              onChange={handleChange}
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem value="default" disabled>
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  Select Module
                </Typography>
              </MenuItem>
              <MenuItem value="Module1">
                <Checkbox checked={moduleName.indexOf("Module1") > -1} />
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  Module 1
                </Typography>
              </MenuItem>
              <MenuItem value="Module2">
                <Checkbox checked={moduleName.indexOf("Module2") > -1} />
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  Module 2
                </Typography>
              </MenuItem>
              <MenuItem value="Module3">
                <Checkbox checked={moduleName.indexOf("Module3") > -1} />
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  Module 3
                </Typography>
              </MenuItem>
            </Select>
          </FormControl> */}
          <Box>
            {/* <Button variant="primary-medium">
              <FilterIcon /> Filter
            </Button> */}
            <Button
              onClick={handleClear}
              sx={{
                textTransform: "none",
                color: (theme) => theme.palette.text.main,
              }}
            >
              {" "}
              x Clear
            </Button>
          </Box>
        </Box>
        <DownloadSelect downloadCsv={downloadCsv} />
      </Box>
      <Box sx={{ mt: 3, ...flexSpaceBetween }}>
        <Typography
          variant="bold"
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          Student Information
        </Typography>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: "13px",
          my: 3,
          pt: 1,
          px: "20px",
          boxSizing: "border-box",
          maxHeight: studentListApi?.loading ? "400px" : "1200px",
          transition: "all 1s ease",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Box sx={{ ...dFlex, gap: 1 }}>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Name
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArr("studentName")}
                    >
                      <ArrowDropUpIconSvg
                        focus={selectedSortUp === "studentName"}
                      />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArrDec("studentName")}
                    >
                      <ArrowDropDownIconSvg
                        focus={selectedSortDown === "studentName"}
                      />
                    </Box>
                  </Box>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ ...dFlex, gap: 1 }}>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Tokens
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArr("totalTokens")}
                    >
                      <ArrowDropUpIconSvg
                        focus={selectedSortUp === "totalTokens"}
                      />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArrDec("totalTokens")}
                    >
                      <ArrowDropDownIconSvg
                        focus={selectedSortDown === "totalTokens"}
                      />
                    </Box>
                  </Box>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ ...dFlex, gap: 1 }}>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Ranking
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArr("rank")}
                    >
                      <ArrowDropUpIconSvg focus={selectedSortUp === "rank"} />
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                        height: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "2px",
                      }}
                      onClick={() => sortArrDec("rank")}
                    >
                      <ArrowDropDownIconSvg
                        focus={selectedSortDown === "rank"}
                      />
                    </Box>
                  </Box>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  variant="medium"
                  sx={{ color: (theme) => theme.palette.text.dark }}
                >
                  Module
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  variant="medium"
                  sx={{ color: (theme) => theme.palette.text.dark }}
                >
                  Status
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {studentListApi?.loading ? (
            <TableRowSkeletonComponent column={5} row={4} />
          ) : (
            <TableBody>
              {searchData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow
                    onClick={() => {
                      navigate(`${row?.id}`, {
                        state: {
                          schoolInfo: schoolData,
                          studentInfo: row,
                        },
                      });
                    }}
                  >
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row.studentName}{" "}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row.totalTokens ?? 0}{" "}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row.rank ?? 0}{" "}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row?.certificateIssued
                          ? "All Modules Completed"
                          : row?.moduleData?.moduleName ?? "Yet to start"}{" "}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        <ProgressBar
                          height={"20px"}
                          progress={row?.percentage ?? 0}
                          fontSize={"12px"}
                        />
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <TablePagination
          rowsPerPageOptions={[]}
          count={searchData?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
}
