import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ContentUploadersTab from "../../../Components/Admin/Content/ContentUploader/ContentUploadersTab";
import IntroMessage from "../../../Components/Admin/Content/IntroMessage/IntroMessage";
import MedzGym from "../../../Components/Admin/Content/MedzGym/MedzGym";
import Modules from "../../../Components/Admin/Content/Module/Modules";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { TabPanel } from "../Dashboard/SchoolSubscribers";

export default function Content() {
  const navigate = useNavigate();
  const location = useLocation();
  const tabs = [
    {
      label: "Module",
      component: <Modules />,
    },
    // {
    //   label: "Game",
    //   component: <Games />,
    // },
    {
      label: "The Medz Gym",
      component: <MedzGym />,
    },
    {
      label: "Intro Messages",
      component: <IntroMessage />,
    },
    {
      label: "Content Uploaders",
      component: <ContentUploadersTab />,
    },
  ];

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    const queryParam = new URLSearchParams(location?.search);
    queryParam.set("tab", newValue);
    navigate({ search: queryParam.toString() });
    setValue(newValue);
  };

  useEffect(() => {
    const queryParam = new URLSearchParams(location?.search);
    setValue(Number(queryParam.get("tab") ?? 1));
  }, [location.search]);

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Content"]} />
      <Box>
        {/* Tabs for current and previous subscribers */}
        <Box
          sx={{
            // width: "100%",
            mt: 5,
            borderBottom: `1px solid #BFBFBF`,
          }}
        >
          <Tabs
            variant="scrollable"
            allowScrollButtonsMobile
            scrollButtons="auto"
            value={value}
            onChange={handleChange}
          >
            {tabs?.map((e, i) => (
              <Tab
                sx={{
                  textTransform: "none",
                }}
                label={
                  <Typography variant={value === i + 1 ? "bold2" : "medium"}>
                    {e.label}
                  </Typography>
                }
                value={i + 1}
              />
            ))}
          </Tabs>
        </Box>

        {/* Tab panels */}
        {tabs?.map((e, i) => (
          <TabPanel value={value} index={i + 1}>
            {e?.component}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
}
