import { Box } from "@mui/material";
import React, { useContext } from "react";
import ParentGroupComponent from "../../../Components/Parent/CommunityProject/ParentGroupComponent";
import ParentRequestComponent from "../../../Components/Parent/CommunityProject/ParentRequestComponent";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { flexSpaceBetween } from "../../../common/commonStyles";
import { UserContext } from "../../../context/UserContext";

export default function ParentCommunityProject() {
  const { userDetails } = useContext(UserContext);
  // state uplifted can be optimized but time constraint is there
  const [groupApiData, fetchGroupAgain] = useDataFetchHook(
    process.env.REACT_APP_FACILITATOR_GET_GROUP_APPROVED + userDetails?.schoolId
  );

  const [apiData, fetchAgain] = useDataFetchHook(
    process.env.REACT_APP_FACILITATOR_GET_GROUP_REQUEST + userDetails?.schoolId
  );

  const fetchAll = () => {
    fetchAgain();
    fetchGroupAgain();
  };
  return (
    <Box>
      <NavBreadCrumbs pageTags={["Community Project"]} />
      <Box sx={{ ...flexSpaceBetween, gap: 2 }}>
        <ParentRequestComponent apiData={apiData} fetchAll={fetchAll} />
        <ParentGroupComponent apiData={groupApiData} />
      </Box>
    </Box>
  );
}
