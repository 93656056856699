import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

export default function DeleteMessageModal({ data, handleMessageDelete }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuspend = () => {
    try {
      handleMessageDelete(data);
      handleClose();
    } catch (e) {
      console.log({ e });
    }
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        sx={{
          textTransform: "none",
          color: (theme) => theme.palette.text.main,
          justifyContent: "flex-start",
          ml: 1,
        }}
      >
        Delete Message
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "13px",
            backgroundColor: (theme) => theme.palette.background.main,
          },
        }}
      >
        <Box sx={{ width: "400px", pb: "10px" }}>
          {/* <form onSubmit={handleDeleteAdmission} autoComplete="off"> */}
          <DialogContent sx={{ mb: 1 }}>
            <DialogContentText id="alert-dialog-description">
              <Typography
                variant="regular"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Are you sure you want to delete this message?
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "#FEBC1D",
                }}
              >
                This message will be deleted for all users
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleSuspend} variant="primary">
              Delete
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
