import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../Apis/axiosPayloads";
import { PATCH } from "../../Apis/constVariables";
import ManageAccount from "../../Components/School/Settings/ManageAccount";
import ManageSubscription from "../../Components/School/Settings/ManageSubscription";
import SubscribeModal from "../../Components/School/Settings/SubscribeModal";
import NavBreadCrumbs from "../../common/NavBreadCrumbs";
import {
  flexCenter,
  itemsCenter,
  textEllipses,
} from "../../common/commonStyles";
import { FormTextField } from "../../const/TextField";
import { checkSize, convertToBase64File } from "../../const/constFunctions";
import { subscriptionExpiry } from "../../const/imageUrl";
import { UserContext } from "../../context/UserContext";

export default function ParentSettings() {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState({ image: null, base64: null });
  const { userDetails, setUserDetails } = useContext(UserContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleFileSelect = async (e) => {
    try {
      let file = e.target?.files?.[0];
      if (!file) {
        setFile({ base64: null, image: null });
        toast.error("No file selected");
        return;
      }
      if (file?.name?.includes("image")) {
        setFile({ base64: null, image: null });
        toast.error("Only image files allowed");
        return;
      }
      if (checkSize(file) > 10) {
        setFile({ base64: null, image: null });
        toast.error("File can't be larger than 10mb");
        return;
      }
      let base64 = await convertToBase64File(file);
      console.log({ base64: base64 });
      setFile({ base64: base64, image: file });
    } catch (error) {
      console.log({ error });
      toast.error("Something went wrong");
    }
  };

  const handleProfileUpdate = async (data) => {
    console.log(data);
    try {
      if (loading) return;
      setLoading(true);
      const url = process.env.REACT_APP_EDIT_SCHOOL_ADMIN + `/something`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: {
          image: file?.base64 ?? userDetails.image,
          address: data?.address,
          city: data?.city,
          contactPersonName: data?.contactPersonName,
          schoolName: data?.schoolName,
          contactPersonNumber: data?.contactPersonNumber,
        },
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      setUserDetails((p) => {
        return {
          ...p,
          ...data,
          image: file?.base64 ?? userDetails.image,
        };
      });
      toast(apiRes?.data?.message);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (!userDetails) {
      return;
    }
    const formattedData = {
      address: userDetails?.address,
      city: userDetails?.city,
      contactPersonName: userDetails?.contactPersonName,
      schoolName: userDetails?.schoolName,
      email: userDetails?.email,
      zipCode: userDetails?.zipCode,
    };

    reset(formattedData);

    return () => {};
  }, [userDetails]);

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Settings"]} />

      {(userDetails?.isArchived || userDetails?.subscriptionExpired) && (
        <Box
          sx={{
            mt: 3,
            backgroundColor: (theme) => theme.palette.background.secondary,
            ...flexCenter,
            flexDirection: "column",
            py: 8,
            gap: 5,
            borderRadius: "12px",
          }}
        >
          <Typography
            variant="bold"
            sx={{ color: (theme) => theme.palette.content.quizQuestion }}
          >
            You don’t have an active subscription. Click on subscribe button to
            retake the subscription.
          </Typography>
          <img
            src={subscriptionExpiry}
            style={{ height: "224px", width: "213px" }}
          />
          <SubscribeModal text={"Subscribe"} />
        </Box>
      )}

      {!(userDetails?.isArchived || userDetails?.subscriptionExpired) && (
        <>
          <Box sx={{ mb: 5 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                my: 3,
              }}
            >
              <Box sx={{ width: "35%" }}>
                <Typography
                  variant="bold2"
                  sx={{ color: (theme) => theme.palette.text.main }}
                >
                  Parent Name
                </Typography>
                <FormTextField
                  fullWidth
                  {...register(
                    "schoolName"
                    // {
                    //   required: "School Name is required.",
                    // }
                  )}
                  error={Boolean(errors.schoolName)}
                  helperText={errors.schoolName?.message}
                />
              </Box>
              <Box sx={{ width: "35%", marginLeft: "30px" }}>
                <Typography
                  variant="bold2"
                  sx={{ color: (theme) => theme.palette.text.main }}
                >
                  E-mail
                </Typography>
                <FormTextField
                  fullWidth
                  disabled
                  {...register(
                    "email"
                    // {
                    //   required: "Email is required.",
                    //   pattern: {
                    //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    //     message: "Invalid Email Address",
                    //   },
                    // }
                  )}
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                my: 3,
              }}
            >
              <Box sx={{ width: "35%" }}>
                <Typography
                  variant="bold2"
                  sx={{ color: (theme) => theme.palette.text.main }}
                >
                  Street Address Line 1*
                </Typography>
                <FormTextField
                  fullWidth
                  {...register(
                    "address"
                    // {
                    //   required: "Address  is required.",
                    // }
                  )}
                  error={Boolean(errors.address)}
                  helperText={errors.address?.message}
                />
              </Box>
              <Box sx={{ width: "35%", marginLeft: "30px" }}>
                <Typography
                  variant="bold2"
                  sx={{ color: (theme) => theme.palette.text.main }}
                >
                  Street Address Line 2*
                </Typography>

                <FormTextField
                  fullWidth
                  {...register(
                    "city"
                    // { required: "City is required." }
                  )}
                  error={Boolean(errors.city)}
                  helperText={errors.city?.message}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                my: 3,
              }}
            >
              <Box sx={{ width: "35%" }}>
                <Typography
                  variant="bold2"
                  sx={{ color: (theme) => theme.palette.text.main }}
                >
                  Country
                </Typography>

                <FormTextField
                  disabled
                  fullWidth
                  {...register(
                    "contactPersonName"
                    // {
                    //   required: "Person Name is required.",
                    // }
                  )}
                  error={Boolean(errors.contactPersonName)}
                  helperText={errors.contactPersonName?.message}
                />
              </Box>

              <Box sx={{ width: "35%", marginLeft: "30px" }}>
                <Typography
                  variant="bold2"
                  sx={{ color: (theme) => theme.palette.text.main }}
                >
                  Postal / Zip Code
                </Typography>
                <FormTextField
                  fullWidth
                  {...register(
                    "zipCode"
                    // {
                    //   required: "Number is required.",
                    // }
                  )}
                  error={Boolean(errors.zipCode)}
                  helperText={errors.zipCode?.message}
                />
              </Box>
              {/* <Box
            sx={{
              minWidth: "5%",
              alignSelf: "flex-end",
              ml: "auto",
              mr: "10px",
            }}
          >
            <Button
              onClick={handleSubmit(handleProfileUpdate)}
              variant="primary"
            >
              Save
            </Button>
          </Box> */}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                my: 3,
              }}
            >
              <Box sx={{ width: "35%" }}>
                <Typography
                  variant="bold2"
                  sx={{ color: (theme) => theme.palette.text.main }}
                >
                  Profile Photo
                </Typography>

                <Box sx={{ ...itemsCenter, gap: "10px", mt: 1 }}>
                  <Box
                    component={"label"}
                    sx={{
                      ...flexCenter,
                      height: "35px",
                      width: "125px",
                      cursor: "pointer",
                      borderRadius: "4px",
                      color: (theme) => theme.palette.text.main,
                      backgroundColor: (theme) => theme.palette.text.lighter,
                      // "&:hover": {
                      //   backgroundColor: "#D1D0D0",
                      // },
                    }}
                  >
                    Choose a file
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileSelect}
                      style={{ display: "none" }}
                    />
                  </Box>
                  <Typography
                    variant="regular"
                    // contentEditable="true"
                    title={file ? file?.name : "No File Chosen"}
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      maxWidth: "220px",
                      ...textEllipses,
                    }}
                  >
                    {file.base64 ? file?.image?.name : "No File Chosen"}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  minWidth: "5%",
                  alignSelf: "flex-end",
                  ml: "auto",
                  mr: "10px",
                }}
              >
                <Button
                  onClick={handleSubmit(handleProfileUpdate)}
                  variant="primary"
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>

          <ManageSubscription />
        </>
      )}
      <ManageAccount />
    </Box>
  );
}
