import { Box, IconButton } from "@mui/material";
import React, { useContext, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import useKeyboardShortcut from "../../Hooks/KeyboardShortcut";
import classes from "../../common/SideBarClasses.module.css";
import {
  flexBetweenWithItemsCenter,
  menuItemsCSS,
  sideNavbarLink,
  textEllipses,
} from "../../common/commonStyles";
import {
  BrawtaIcon,
  CommunityChannelIcon,
  CommunityProject,
  DashboardIcon,
  DownloadIconNoColor,
  MenuIcon,
  MyStudentsIcon,
  ProgramContentIcon,
  SettingsIcon,
} from "../../images/icons";
// import Logo from "../../images/unuh logo.svg";
import { UserContext } from "../../context/UserContext";
import Logo from "../../images/unuh-logo.png";
import { LogoutModal } from "../Admin/AdminSideBar";

const menuItems = [
  { to: "dashboard", icon: <DashboardIcon />, label: "Dashboard" },
  {
    to: "students",
    icon: <MyStudentsIcon />,
    label: "My Students",
  },
  {
    to: "brawta-challenge",
    icon: <BrawtaIcon />,
    label: "Brawta Challenge",
  },
  {
    to: "community-project",
    icon: <CommunityProject />,
    label: "Community Project",
  },
  {
    to: "community-channel",
    icon: <CommunityChannelIcon />,
    label: "Community Channel",
  },
  {
    to: "program-content",
    icon: <ProgramContentIcon />,
    label: "Program Content",
  },
  {
    to: "settings",
    icon: <SettingsIcon />,
    label: "Settings",
  },
  {
    to: "download-data",
    icon: <DownloadIconNoColor fill={""} />,
    label: "Download data",
  },
];

export default function ParentSideBar() {
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { userDetails } = useContext(UserContext);
  const handleToggleSidebar = () => {
    setIsSidebarOpen((p) => !p);
  };
  useKeyboardShortcut(handleToggleSidebar, menuItems);

  return (
    <Box
      // className="hidescrollbar"
      sx={{
        boxShadow: "4px 0px 13px rgba(0, 0, 0, 0.12)",
        backgroundColor: (theme) => theme.palette.background.secondary,
        height: "100vh",
        position: "sticky",
        zIndex: 999,
        top: "0px",
        left: "0px",
        pl: isSidebarOpen ? "30px" : "10px",
        width: isSidebarOpen ? "200px" : "65px",
        overflowY: "scroll",
        transition: "all 0.7s",
      }}
    >
      <Box
        sx={{
          mt: 2,
          mb: 4,
          height: "38px",
          width: isSidebarOpen ? "99px" : "40px",
          transition: "all 0.7s",
        }}
      >
        <img src={Logo} height="38px" />
      </Box>
      <Box sx={{ mb: 4 }}>
        <IconButton onClick={handleToggleSidebar}>
          <MenuIcon />
        </IconButton>
      </Box>

      <Box sx={{ ...menuItemsCSS }}>
        {menuItems.map((item, index) => {
          if (
            !userDetails.isArchived ||
            !userDetails.subscriptionExpired ||
            item.to == "settings" ||
            item.to == "students" ||
            item.to == "download-data"
          )
            return (
              <NavLink
                key={index}
                to={item.to}
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
              >
                <Box sx={{ ...flexBetweenWithItemsCenter, height: "40px" }}>
                  <Box
                    sx={{
                      ...sideNavbarLink,
                      ...textEllipses,
                    }}
                    title={item.label}
                  >
                    <IconButton sx={{ p: 0 }}>{item.icon} </IconButton>
                    <span
                      style={{
                        opacity: isSidebarOpen ? 1 : 0,
                        transition: "opacity 0.5s ease-in-out",
                      }}
                    >
                      {item.label}
                    </span>
                  </Box>
                  <Box
                    sx={{
                      // display: location.pathname.startsWith("/parent/" + item.to)
                      //   ? "block"
                      //   : "none",
                      height: location.pathname.startsWith("/parent/" + item.to)
                        ? "100%"
                        : "0%",
                      width: "4px",
                      // border: location.pathname.startsWith("/parent/" + item.to)
                      //   ? "3px solid"
                      //   : "0px solid",
                      backgroundColor: (theme) => theme.palette.primary.main,
                      borderColor: (theme) => theme.palette.primary.main,
                      borderRadius: "200px 0 0 200px",
                      transformOrigin: "bottom right",
                      transition: "height 0.3s ease-in-out",
                    }}
                  ></Box>
                </Box>
              </NavLink>
            );
        })}

        <LogoutModal isSidebarOpen={isSidebarOpen} />
      </Box>
    </Box>
  );
}
