import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  dFlex,
  flexBetweenWithItemsCenter,
  itemsCenter,
} from "../../../common/commonStyles";
import AssignBatchModal from "./AssignBatchModal";
import DeleteFacilitatorModal from "./DeleteFacilitatorModal";

export default function FacilitatorCard({ data, index, fetchAgain }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        borderRadius: "12px",
        backgroundColor: (theme) => theme.palette.background.secondary,
        height: "64px",
        ...flexBetweenWithItemsCenter,
        px: 5,
        my: 2,
      }}
    >
      <Box
        onClick={() => {
          navigate(`${data?.id}`, {
            state: {
              facilitatorDetails: data,
            },
          });
        }}
        sx={{ ...itemsCenter, gap: 2, cursor: "pointer" }}
      >
        <Typography
          variant="medium"
          sx={{ color: (theme) => theme.palette.text.dark }}
        >
          {data?.instructorName}
        </Typography>
        <Box sx={{ borderRadius: "50%", border: "2px solid #5C5C5C" }}></Box>
        <Typography
          variant="medium"
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          {data?.batchNumber} Batch
        </Typography>
      </Box>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          top: 0,
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            p: 2,
            ...dFlex,
            flexDirection: "column",
            gap: 1,
            borderRadius: "8px",
          }}
        >
          <Typography
            variant="medium2"
            sx={{
              color: (theme) => theme.palette.text.light,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(`${data?.id}`, {
                state: {
                  facilitatorDetails: data,
                },
              });
            }}
          >
            View
          </Typography>

          <>
            <AssignBatchModal
              currentBatchCount={data?.batchNumber}
              handleELClose={handleClose}
              normal={true}
              facilitatorDetails={data}
              fetchAgain={fetchAgain}
            />
            <DeleteFacilitatorModal
              handleELClose={handleClose}
              data={data}
              fetchAgain={fetchAgain}
            />
          </>
        </Box>
      </Popover>
    </Box>
  );
}
