import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MeetingCard from "../../../Components/School/Meeting/MeetingCard";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import {
  flexBetweenWithItemsCenter,
  flexCenter,
  itemsCenter,
} from "../../../common/commonStyles";
import { CustomDateTextField } from "../../../const/TextField";
import { meetingEmpty } from "../../../const/imageUrl";
import { CreateBatchIcon, ZoomMeetIcon } from "../../../images/icons";

export default function Meetings() {
  const navigate = useNavigate();
  const url = process.env.REACT_APP_SCHOOL_MEETING_LIST + "?offset=0&limit=100";
  const [apiCall, fetchAgain] = useDataFetchHook(url);
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const filterData = () => {
    if (!searchText) {
      return;
    }
    let arr = [...apiCall?.res?.meeting];
    arr = arr?.filter((e) => {
      return e?.date === searchText;
    });
    setSearchData(arr);
  };

  const handleClear = () => {
    setSearchData([]);
    setSearchText("");
  };
  useEffect(() => {
    if (!searchText) {
      setSearchData([]);
      return;
    }
    filterData();
  }, [searchText]);
  return (
    <Box>
      <NavBreadCrumbs pageTags={["Meetings"]} />

      <Box sx={{ ...flexBetweenWithItemsCenter }}>
        <Box sx={{ ...itemsCenter }}>
          <CustomDateTextField
            disabled={apiCall?.loading}
            type="date"
            sx={{ mr: 2 }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {/* <Button onClick={filterData} variant="primary" sx={{ mr: 0 }}>
            <FilterIcon />
            Filter
          </Button> */}
          <Button
            onClick={handleClear}
            sx={{
              textTransform: "none",
              px: 0,
              color: (theme) => theme.palette.text.main,
            }}
          >
            {" "}
            x Clear
          </Button>{" "}
        </Box>
        <Box>
          <Button
            variant="primary"
            onClick={() => {
              window.open("https://us05web.zoom.us/meeting/schedule", "_blank");
            }}
          >
            <ZoomMeetIcon /> Schedule Zoom meet
          </Button>
          <Button
            onClick={() => {
              navigate("add");
            }}
            variant="primary"
          >
            <CreateBatchIcon /> Schedule Offline meet
          </Button>
        </Box>
      </Box>
      {apiCall?.res?.meeting?.length < 1 && (
        <Box
          sx={{
            mt: 3,
            backgroundColor: (theme) => theme.palette.background.secondary,
            ...flexCenter,
            flexDirection: "column",
            py: 8,
            gap: 5,
            borderRadius: "12px",
          }}
        >
          <img
            alt="no meetings"
            src={meetingEmpty}
            style={{ height: "265px", width: "426px" }}
          />
          <Typography
            variant="bold"
            sx={{ color: (theme) => theme.palette.text.light }}
          >
            You don’t have any upcoming meetings scheduled
          </Typography>
          <Button
            onClick={() => {
              navigate("add");
            }}
            variant="primary"
          >
            <CreateBatchIcon /> Schedule Offline meet
          </Button>
        </Box>
      )}
      {apiCall?.loading && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />
        </Box>
      )}
      {!searchText && (
        <Box sx={{ my: 3 }}>
          {apiCall?.res?.meeting
            ?.sort((a, b) => (new Date(a.date) < new Date(b.date) ? -1 : 1))
            ?.map((data, i) => (
              <MeetingCard key={data?.id} data={data} fetchAgain={fetchAgain} />
            ))}
        </Box>
      )}
      {searchText && (
        <Box sx={{ my: 3 }}>
          {searchData
            ?.sort((a, b) => (new Date(a.date) < new Date(b.date) ? -1 : 1))
            ?.map((data, i) => (
              <MeetingCard key={data?.id} data={data} fetchAgain={fetchAgain} />
            ))}
        </Box>
      )}
    </Box>
  );
}
