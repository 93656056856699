import { Box } from "@mui/material";
import React from "react";
import StudentsEarnedCertificatesTable from "../../../Components/Admin/Dashboard/StudentsEarnedCertificatesTable";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";

export default function StudentsEarnedCertificates() {
  return (
    <Box>
      {/* Navigation breadcrumbs */}
      <NavBreadCrumbs
        pageTags={["Dashboard", "Students Earned Certificates"]}
      />

      {/* <Box sx={{ ...flexSpaceBetween }}>
        <SearchTextField
          placeholder="search"
          sx={{ width: "400px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <DownloadSelect />
      </Box>
         */}
      <StudentsEarnedCertificatesTable />
    </Box>
  );
}
