import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  dFlex,
  flexSpaceBetween,
  itemsCenter,
} from "../../../../common/commonStyles";

import img1 from "../../../../images/temp1.png";
import img2 from "../../../../images/temp2.png";

export default function ModuleDragAndDropCard({ value, index }) {
  return (
    <Box
      sx={{
        my: 3,
        px: 3,
        borderRadius: "15px",
      }}
    >
      <Box sx={{ mb: 1, ...flexSpaceBetween, gap: 8 }}>
        <Typography
          variant="medium"
          sx={{
            flex: "80%",
            color: (theme) => theme.palette.text.main,
          }}
        >
          {index + 1}. {value.question}
        </Typography>
      </Box>

      <Box sx={{ ...dFlex }}>
        <Box
          sx={{ flex: "80%", flexDirection: "column", mt: 2, p: "9px 20px" }}
        >
          <Box
            sx={{
              border: "1px dashed #BFBFBF",
              width: "389px",
              height: "141px",
              borderRadius: "8px",
              backgroundColor: (theme) => theme.palette.background.secondary,
            }}
          >
            {value.data.item1Answers.map((_, idx) => {
              return <img src={_} style={{ width: "50px", padding: "5px" }} />;
            })}
            {value.data.item2Answers.map((_, idx) => {
              return <img src={_} style={{ width: "50px", padding: "5px" }} />;
            })}
          </Box>
          <Box sx={{ ...dFlex, gap: 2, mt: 2 }}>
            <img
              src={value.data.item1}
              style={{ width: "200px", height: "200px" }}
            />
            <img
              src={value.data.item2}
              style={{ width: "200px", height: "200px" }}
            />
          </Box>{" "}
        </Box>
        <Box
          sx={{
            flex: "10%",
            alignSelf: "flex-end",
          }}
        >
          <Typography
            variant="medium"
            sx={{
              flex: "10%",
              pl: 1,
              color: (theme) => theme.palette.text.main,
            }}
          >
            {value?.studentAttempted ? value?.studentAttempted : "0"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
