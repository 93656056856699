import { Box, CircularProgress, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArchivedStudentCard from "../../../Components/School/ManageBatch/ArchivedStudentCard";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import useTablePagination from "../../../Hooks/TablePaginationHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { flexCenter } from "../../../common/commonStyles";

export default function ArchivedParentStudentDetails() {
  //REACT_APP_PARENT_STUDENT_ARCHIVE_LIST
  const [url, setUrl] = useState(null);
  const [batchData, fetchBatchData, dispatchData] = useDataFetchHook(url);

  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    const params = new URLSearchParams();
    const newUrl =
      process.env.REACT_APP_PARENT_STUDENT_ARCHIVE_LIST +
      "&" +
      params.toString();
    setUrl(newUrl);
    return () => {};
  }, []);
  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();

  return (
    <Box>
      <NavBreadCrumbs pageTags={["My Students", "Archived"]} />

      {batchData?.loading && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />
        </Box>
      )}
      {!searchText && (
        <Box sx={{ my: 3 }}>
          {batchData?.res?.Student?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )?.map((e, i) => (
            <ArchivedStudentCard
              key={e?.id}
              data={e}
              fetchAgain={fetchBatchData}
              index={i}
            />
          ))}
        </Box>
      )}
      {searchText && (
        <Box sx={{ my: 3 }}>
          {searchData
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map((e, i) => (
              <ArchivedStudentCard
                key={e?.id}
                data={e}
                fetchAgain={fetchBatchData}
                index={i}
              />
            ))}
        </Box>
      )}

      {batchData?.res?.Student?.length >= 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            rowsPerPageOptions={[]}
            count={searchText ? searchData.length : batchData?.res?.totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
}
