import {
  Box,
  Button,
  Dialog,
  DialogActions,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { dFlex, itemsCenter } from "../../../../../common/commonStyles";

export default function DropDownModal({ data, index }) {
  const [options, setOptions] = useState([
    { label: "Lorem ipsum ist" },
    { label: "Lorem ipsum ist" },
    { label: "Lorem ipsum ist" },
    { label: "Lorem ipsum ist" },
  ]);
  const [options2, setOptions2] = useState([
    { label: "Lorem ipsum ist" },
    { label: "Lorem ipsum ist" },
    { label: "Lorem ipsum ist" },
    { label: "Lorem ipsum ist" },
  ]);

  const [cases, setCases] = useState();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!data?.data) {
      return;
    }
    setCases(data?.data?.options);
  }, [data?.data]);

  return (
    <>
      <Box onClick={handleClickOpen}>
        <Typography
          sx={{
            color: (theme) => theme.palette.text.main,
            px: 1,
            cursor: "pointer",
            transition: "all 0.2s ease",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.background.main,
            },
          }}
        >
          {data?.questionName}
        </Typography>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "#F3F5F5",
            borderRadius: "14px",
            p: "24px 12px 12px 24px",
            maxWidth: "100%",
            width: "40%",
          },
        }}
      >
        <Typography
          variant="medium"
          sx={{
            color: (theme) => theme.palette.text.main,
          }}
        >
          {index + 1}. {data?.question}
        </Typography>

        <Box sx={{ ...dFlex, flexDirection: "column", mt: 1 }}>
          {cases?.map((e, i) => (
            <Box
              sx={{
                ...itemsCenter,
                borderRadius: "6px",
                // backgroundColor: (theme) => theme.palette.background.secondary,
              }}
            >
              <Box
                sx={{
                  p: "9px 20px",
                  flex: "80%",
                  color: (theme) => theme.palette.text.main,
                  ...dFlex,
                  flexDirection: "column",
                  // boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.12)",
                }}
              >
                {String.fromCharCode(97 + i)}. {e.case}
                <Select
                  sx={{
                    width: "70%",
                    mt: 0.2,
                    height: "30px",
                    backgroundColor: (theme) =>
                      theme.palette.background.secondary,
                  }}
                  value={e.answer}
                >
                  <MenuItem value={"default"}>{"Select any one"}</MenuItem>
                  {e?.options?.map((e, i) => (
                    <MenuItem value={i}>{e}</MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          ))}
        </Box>

        <DialogActions sx={{ pt: 1 }}>
          <Button
            sx={{ height: "32px", textTransform: "none" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
