import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import BrawtaDetailCard from "../../../Components/Parent/BrawtaChallenge/BrawtaDetailCard";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { dFlex, flexCenter } from "../../../common/commonStyles";
import { emptyDataSA } from "../../../const/imageUrl";

export default function StudentBrawtaDetails() {
  //REACT_APP_GET_STUDENT_BRAWTA_ATTEMPT
  const [studentData, setStudentData] = useState({ studentName: "" });
  const param = useParams();
  let url =
    process.env.REACT_APP_GET_STUDENT_BRAWTA_ATTEMPT + `?stdId=${param?.id}`;
  const [brawtaChallengeApi] = useDataFetchHook(url);
  const location = useLocation();

  useEffect(() => {
    const { studentData } = location?.state;

    setStudentData(studentData);
    return () => {
      setStudentData({
        studentName: "",
      });
    };
  }, [location?.state]);

  return (
    <Box>
      <NavBreadCrumbs
        pageTags={["Brawta Challenge", studentData?.studentName]}
      />

      {brawtaChallengeApi?.loading && (
        <Box sx={flexCenter}>
          <CircularProgress />
        </Box>
      )}

      {!brawtaChallengeApi.loading &&
        brawtaChallengeApi?.res?.challenges.length < 1 && (
          <Box
            sx={{
              mt: 3,
              backgroundColor: (theme) => theme.palette.background.secondary,
              ...flexCenter,
              flexDirection: "column",
              py: 8,
              gap: 5,
              borderRadius: "12px",
            }}
          >
            <img
              src={emptyDataSA}
              style={{ height: "254px", width: "345px" }}
            />
            <Typography
              variant="bold"
              sx={{ color: (theme) => theme.palette.text.light }}
            >
              This user has not yet completed any Brawta Challenge
            </Typography>
          </Box>
        )}

      <Box sx={{ ...dFlex, gap: 2, flexWrap: "wrap" }}>
        {brawtaChallengeApi?.res?.challenges?.map((e, i) => (
          <BrawtaDetailCard data={e} index={i} studentData={studentData} />
        ))}
      </Box>
    </Box>
  );
}
