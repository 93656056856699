import styled from "@emotion/styled";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Accordion,
  AccordionDetails,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import React from "react";
import useDataFetchHook from "../../../../Hooks/DataFetchHook";
import { flexCenter } from "../../../../common/commonStyles";
import { AccordionTransition } from "../../../../const/constFunctions";
import GameElement from "./GameElement";
import LessonElement from "./LessonElement";
import QuizElement from "./QuizElement";

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "22px", color: props.iconColor }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  borderRadius: "8px",
  height: "55px",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    marginLeft: theme.spacing(1),
  },
}));

export default function ModuleElement({ stdId, data }) {
  const [lessonApiCall] = useDataFetchHook(
    process.env.REACT_APP_ALL_PUBLISHED_LESSON + `${data?.moduleId}`
  );
  return (
    <Box sx={{ my: 2 }}>
      <Accordion TransitionProps={AccordionTransition}>
        <AccordionSummary
          //   {...props}
          iconColor={"#FFF"}
          sx={{
            color: "#FFF",
            backgroundColor: (theme) => theme.palette.primary.main,
          }}
        >
          <Typography variant="bold2">{data?.moduleName}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: "16px 40px" }}>
          {lessonApiCall?.loading && (
            <Box sx={{ ...flexCenter }}>
              <CircularProgress />
            </Box>
          )}
          {data?.pregame && <GameElement data={data?.pregame} />}
          {data?.prequiz && (
            <QuizElement
              stdId={stdId}
              moduleId={data?.moduleId}
              data={data?.prequiz}
            />
          )}
          {lessonApiCall?.res?.lessons?.map((e, i) => (
            <LessonElement
              stdId={stdId}
              data={e}
              key={e.lessonId}
              moduleId={data?.moduleId}
              index={i}
            />
          ))}
          {data?.postquiz && (
            <QuizElement
              stdId={stdId}
              moduleId={data?.moduleId}
              data={data?.postquiz}
            />
          )}
          {data?.postgame && <GameElement data={data?.postgame} />}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
