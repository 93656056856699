import { Box } from "@mui/material";
import StudentList from "../../../Components/Parent/StudentProgress/StudentList";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";

export default function MyStudent() {
  return (
    <Box>
      <NavBreadCrumbs pageTags={["My Students"]} />
      <Box>
        <StudentList />
      </Box>
    </Box>
  );
}
