import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import {
  dFlex,
  dashboardCard,
  flexCenter,
  itemsCenter,
  justifyCenter,
} from "../../../common/commonStyles";
import { RightArrow } from "../../../images/icons";

const cardTextCss = (loading) => ({
  color: "#fff",
  fontSize: loading ? "0px" : "30px",
  fontWeight: 600,
  transition: "all 0.5s ease",
});

export default function AdminDashboard() {
  const navigate = useNavigate();
  const baseCountURL = process.env.REACT_APP_SA_DASHBOARD_COUNT;

  const countApis = [
    {
      type: "cancel",
      title: "wants to Cancel Subscription",
      api: useDataFetchHook(`${baseCountURL}/cancelOrDelete?type=cancel`),
      onClick: () => navigate("cancel-subscription"),
    },
    {
      type: "delete",
      title: "wants to Delete Account",
      api: useDataFetchHook(`${baseCountURL}/cancelOrDelete?type=delete`),
      onClick: () => navigate("delete-account"),
    },
  ];
  const countCardApis = [
    {
      type: "school",
      title: "School Subscribers",
      api: useDataFetchHook(`${baseCountURL}/subscriber?type=school`),
      onClick: () => navigate("school-subscribers"),
    },
    {
      type: "parent",
      title: "Parent Subscribers",
      api: useDataFetchHook(`${baseCountURL}/subscriber?type=parent`),
      onClick: () => navigate("parent-subscribers"),
    },
    {
      type: "studentCertified",
      title: "Students Earned Certification This Month",
      api: useDataFetchHook(`${baseCountURL}/studentCertified`),
      onClick: () => navigate("students-earned-certificates"),
    },
    {
      type: "newSubscriber",
      title: "New Subscribers",
      api: useDataFetchHook(`${baseCountURL}/newSubscriber`),
      onClick: () => navigate("new-subscribers"),
    },
    {
      type: "allStudents",
      title: "All Students",
      api: useDataFetchHook(`${baseCountURL}/allStudents`),
      onClick: () => navigate("all-students"),
    },
    {
      type: "offlineRequest",
      title: "Offline requests",
      api: useDataFetchHook(`${baseCountURL}/offlineRequest`),
      onClick: () => navigate("offline-request"),
    },
  ];

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Dashboard"]} />
      <Box sx={{ ...justifyCenter, gap: "10px", flexDirection: "column" }}>
        {countApis.map(({ title, api, onClick }) => (
          <Typography
            key={title}
            variant="medium"
            sx={{
              cursor: "pointer",
              color: "#A8A6A6",
              maxWidth: "30%",
            }}
            onClick={onClick}
          >
            <Typography
              variant="semibold"
              sx={{
                color: (theme) => theme.palette.primary.main,
                fontSize: api[0]?.loading ? "0px" : "16px",
                transition: "all 0.55s ease",
              }}
            >
              {api[0]?.res?.count > 1
                ? `${api[0]?.res?.count} users`
                : `${api[0]?.res?.count} user`}
            </Typography>
            <CircularProgress
              size="16px"
              sx={{ display: api[0]?.loading ? "" : "none" }}
            />{" "}
            {title} <RightArrow />
          </Typography>
        ))}
        <Box
          sx={{
            ...dFlex,
            mt: 6,
            alignContent: "center",
            flexWrap: "wrap",
            gap: "40px 7%",
          }}
        >
          {countCardApis.map(({ title, api, onClick }) => (
            <DashboardCard countApi={api} onClick={onClick} title={title} />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
function CardCountLoading({ loading }) {
  return (
    <Box
      // animation="wave"
      sx={{
        ...flexCenter,
        width: "100%",
        display: loading ? "flex" : "none",
      }}
    >
      <CircularProgress
        sx={{
          color: (theme) => theme.palette.background.secondary,
        }}
      />
    </Box>
  );
}

const DashboardCard = ({ title, countApi, onClick }) => (
  <Box sx={dashboardCard} onClick={onClick}>
    <Typography
      sx={{
        ...itemsCenter,
        color: (theme) => theme.palette.text.main,
        flex: "55%",
        px: "5%",
        textTransform: "capitalize",
      }}
      variant="bold"
    >
      {title}
    </Typography>
    <Box
      sx={{
        ...flexCenter,
        flex: "20%",
        background: (theme) => theme.palette.primary.main,
        borderRadius: "0px 8px 8px 0px",
      }}
    >
      <Typography
        sx={{
          ...cardTextCss(countApi[0]?.loading),
        }}
      >
        {countApi[0]?.res?.count}
      </Typography>
      <CardCountLoading loading={countApi[0]?.loading} />
    </Box>
  </Box>
);
