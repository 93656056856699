import { Box, Button, Dialog, DialogActions, Typography } from "@mui/material";
import React, { useState } from "react";
import { dFlex } from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";

export default function CompleteSentenceModal({ data, index }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box onClick={handleClickOpen}>
        <Typography
          sx={{
            color: (theme) => theme.palette.text.main,
            px: 1,
            cursor: "pointer",
            transition: "all 0.2s ease",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.background.main,
            },
          }}
        >
          {data?.questionName}
        </Typography>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "#F3F5F5",
            borderRadius: "14px",
            minWidth: "440px",
            p: "24px 12px 12px 24px",
          },
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.text.main,
            mb: 1,
          }}
        >
          {index + 1}. {data?.question}
        </Typography>
        <Box
          sx={{
            px: "15px",
            flex: "80%",
            ...dFlex,
            // boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.12)",
          }}
        >
          <Box
            sx={{
              p: "15px",
              boxSizing: "border-box",
              height: "107px",
              width: "80%",
              backgroundColor: "#E3EFEF",
              border: "1px solid #BFBFBF",
              borderRadius: "8px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: (theme) => theme.palette.text.main,
              }}
            >
              Type Answer
            </Typography>
            <FormTextField
              disabled
              fullWidth
              InputProps={{
                sx: {
                  backgroundColor: (theme) => theme.palette.text.lighter,
                },
              }}
              sx={{
                mt: 2,
              }}
            />
          </Box>
        </Box>
        <DialogActions sx={{ pt: 1 }}>
          <Button
            sx={{ height: "32px", textTransform: "none" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
