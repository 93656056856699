import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  dFlex,
  flexSpaceBetween,
  itemsCenter,
} from "../../../../common/commonStyles";
import { FormTextField } from "../../../../const/TextField";

export default function ModuleAnswerCard({ value, index }) {
  const tempArr = [];
  value.data.options.map((_, idx) => {
    tempArr.push({
      label: _,
      count: value?.options[idx],
    });
  });

  const [options, setOptions] = useState(tempArr);

  return (
    <Box
      sx={{
        my: 3,
        px: 3,
        borderRadius: "15px",
      }}
    >
      <Box sx={{ mb: 1, ...flexSpaceBetween, gap: 8 }}>
        <Typography
          variant="medium"
          sx={{
            flex: "80%",
            color: (theme) => theme.palette.text.main,
          }}
        >
          {index + 1}. {value.questionName}
        </Typography>
      </Box>

      <Box sx={{ ...dFlex, flexDirection: "column", mt: 2 }}>
        {options.map((_, idx) => {
          return (
            <Box
              sx={{
                ...itemsCenter,
                gap: 2,
                my: 1,
                borderRadius: "6px",
              }}
            >
              <Box
                sx={{
                  px: "15px",
                  flex: "80%",
                  ...dFlex,
                  // boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.12)",
                }}
              >
                <Box
                  sx={{
                    p: "15px",
                    width: "389px",
                    boxSizing: "border-box",
                    height: "107px",
                    backgroundColor: "#E3EFEF",
                    border: "1px solid #BFBFBF",
                    borderRadius: "8px",
                  }}
                >
                  {/* <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    Type Answer
                  </Typography> */}
                  <FormTextField fullWidth sx={{ mt: 2 }} value={_.label} />
                </Box>
              </Box>
              <Typography
                variant="medium"
                sx={{
                  flex: "10%",
                  pl: 1,
                  alignSelf: "flex-end",
                  color: (theme) => theme.palette.text.main,
                }}
              >
                {_?.count ? _.count : "0"}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
