import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { dFlex, flexSpaceBetween } from "../../../../common/commonStyles";
import { SvgVector } from "../../../../images/icons";

export default function ModuleMatchPairsCard({ data, index }) {
  const [leftOptions, setLeftOptions] = useState([]);
  const [rightOptions, setRightOptions] = useState([]);

  useEffect(() => {
    if (!data?.data) {
      return;
    }
    let left = data?.data?.options;
    setLeftOptions(left);
    let right = data?.data?.correctOptions?.sort((a, b) => a.match - b.match);
    setRightOptions(right);
  }, [data?.data]);

  return (
    <Box
      sx={{
        my: 3,
        px: 3,
        borderRadius: "15px",
      }}
    >
      <Box sx={{ mb: 1, ...flexSpaceBetween, gap: 8 }}>
        <Typography
          variant="medium"
          sx={{
            flex: "80%",
            color: (theme) => theme.palette.text.main,
          }}
        >
          {index + 1}. {data?.question}
        </Typography>
      </Box>
      <Box sx={{ ...dFlex }}>
        <Box
          sx={{
            ...dFlex,
            flexDirection: "column",
            mt: 2,
            flex: "80%",
            p: "9px 20px",
          }}
        >
          {leftOptions?.map((e, i) => (
            <Box sx={{ ...dFlex }}>
              <Box sx={{ position: "relative", overflow: "hidden" }}>
                <Box
                  sx={{
                    width: { xs: "100px", md: "160px" },
                    height: "50px",
                    mb: 1,
                    pl: 1,
                    mr: 2,
                    background: (theme) => theme.palette.primary.main,
                    // borderRadius: "8px 0px 0px 8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <SvgVector />{" "}
                  <Typography sx={{ color: "#fff", ml: 1 }}>{e}</Typography>
                </Box>
                <Box
                  sx={{
                    width: { xs: "100px", md: "170px" },
                    clipPath: "ellipse(5% 12% at 100% 50%)",
                    height: "50px",
                    background: "#fff",
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                  }}
                ></Box>
              </Box>
              <Box sx={{ position: "relative", overflow: "hidden" }}>
                <Box
                  sx={{
                    width: { xs: "100px", md: "180px" },
                    height: "50px",
                    // ml: "10px",
                    px: 2,
                    background: (theme) => theme.palette.primary.main,
                    position: "absolute",
                    clipPath: "ellipse(5% 12% at 5% 50%)",
                    // top: "0px",
                    // left: "0px",
                  }}
                ></Box>
                <Box
                  sx={{
                    // width: { xs: "100px", md: "170px" },
                    clipPath: "inset(0 0 0 5%)",
                    height: "50px",
                    pr: 1,
                    background: (theme) => theme.palette.primary.main,
                    // borderRadius: "0px 8px 8px 0px",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "10px",
                  }}
                >
                  {/* <DragIcons /> */}
                  <Typography sx={{ color: "#fff", mr: 1, pr: 1 }}>
                    {rightOptions[i]?.text}
                  </Typography>
                  <SvgVector />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
