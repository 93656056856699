import { Box } from "@mui/material";
import React from "react";
import Games from "../../Components/Admin/Content/Games/Games";
import NavBreadCrumbs from "../../common/NavBreadCrumbs";

export default function GamesPage() {
  return (
    <Box>
      <NavBreadCrumbs pageTags={["Games"]} />
      <Games />
    </Box>
  );
}
