import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { flexSpaceBetween } from "../../../../common/commonStyles";

export default function ModuleMCQCard({ data, index }) {
  return (
    <Box
      sx={{
        my: 3,
        px: 3,
        borderRadius: "15px",
      }}
    >
      <Box sx={{ mb: 1, ...flexSpaceBetween, gap: 8 }}>
        <Typography
          variant="medium"
          sx={{
            flex: "80%",
            color: (theme) => theme.palette.text.main,
          }}
        >
          {index + 1}. {data?.question}
        </Typography>
      </Box>

      <FormGroup sx={{ px: 3 }}>
        {data?.data?.options?.map((e, i) => (
          <FormControlLabel
            key={i}
            sx={{
              my: 1,
              backgroundColor: (theme) => theme.palette.background.secondary,
              borderRadius: "6px",
            }}
            control={<Checkbox checked={data?.stdAnswer.includes(i)} />}
            label={
              <Typography
                variant="medium"
                sx={{ color: (theme) => theme.palette.text.main }}
              >
                {e}
              </Typography>
            }
          />
        ))}
      </FormGroup>
    </Box>
  );
}
