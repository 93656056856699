import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { itemsCenter } from "../../../common/commonStyles";

export default function BrawtaStudentCard({ data }) {
  const navigate = useNavigate();

  return (
    <Box
      onClick={() => {
        navigate(`${data?.id}`, {
          state: {
            studentData: data,
          },
        });
      }}
      sx={{
        ...itemsCenter,
        gap: 2,
        cursor: "pointer",
        background: (theme) => theme.palette.background.secondary,
        width: "240px",
        py: 1,
        px: 2,
        borderRadius: "8px",
      }}
    >
      <Avatar
        src={data?.image ?? ""}
        sx={{
          textTransform: "capitalize",
          height: "54px",
          width: "54px",
        }}
      >
        {data?.studentName?.[0]}
      </Avatar>
      <Typography
        variant="medium"
        sx={{
          color: (theme) => theme.palette.text.dark,
          textTransform: "capitalize",
        }}
      >
        {data?.studentName}
      </Typography>
    </Box>
  );
}
