import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { PATCH, POST } from "../../../Apis/constVariables";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import { dFlex } from "../../../common/commonStyles";
import { reactSelectTheme } from "../../../const/constFunctions";
import { RegisterIcon } from "../../../images/icons";

export default function AddFacilitatorModal({
  edit,
  data,
  insFacilitatorData,
  batchDetails,
  fetchAgain,
}) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [value, setValue] = useState(null);
  // console.log({ insFacilitatorData });
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(null);
  const [apiData, fetchApiData] = useDataFetchHook(url);

  const handleChange = (selectedOption) => {
    setValue(selectedOption);
  };

  const handleOpen = () => {
    setValue(null);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!edit) {
      const url = `${process.env.REACT_APP_SCHOOL_FACILITATOR_LIST}`;
      setUrl(url);
      return;
    }
    if (!data && !batchDetails) {
      return;
    }
    const url = `${process.env.REACT_APP_SCHOOL_GET_FACILITATOR_EXCEPT_ONE}${data?.id}&batch=${batchDetails?.batchName}`;
    setUrl(url);
  }, [data, batchDetails]);

  useEffect(() => {
    if (!apiData?.res?.instructor) {
      return;
    }
    let data = [...apiData.res.instructor];
    let temp = [];
    if (insFacilitatorData?.length > 0) {
      temp = insFacilitatorData;
      temp = temp.map((e) => e.id);
    }
    data = data
      ?.filter((e) => !temp.includes(e.id))
      .map((e) => {
        return {
          label: e?.instructorName,
          value: e?.id,
        };
      });
    setOptions(data);
  }, [apiData?.res?.instructor]);

  const handleAdd = async () => {
    try {
      if (!value) {
        toast.error("Please select a Facilitator");
        return;
      }
      if (!batchDetails?.id) {
        toast.error("Something is went wrong");
        handleClose();
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_SCHOOL_ADD_FACILITATOR_TO_BATCH;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          instructorId: value?.value,
          batchName: batchDetails?.id,
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchAgain();
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
  const handleEdit = async () => {
    try {
      if (!value) {
        toast.error("Please select a Facilitator");
        return;
      }
      if (!batchDetails?.id) {
        toast.error("Something is went wrong");
        handleClose();
        return;
      }
      setLoading(true);
      const url =
        process.env.REACT_APP_SCHOOL_REPLACE_FACILITATOR_IN_BATCH +
        `/${batchDetails?.id}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: {
          currentInstructor: value?.value,
          previousInstructor: data?.id,
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchAgain();
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      {edit ? (
        <Typography
          onClick={handleOpen}
          sx={{
            color: (theme) => theme.palette.primary.main,
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
          }}
        >
          Replace
        </Typography>
      ) : (
        <Button
          disabled={insFacilitatorData?.length >= 2}
          onClick={handleOpen}
          variant="primary"
          sx={{ mr: 3 }}
        >
          <RegisterIcon /> Assign Facilitator
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "13px",
            backgroundColor: (theme) => theme.palette.background.main,
          },
        }}
      >
        <Box sx={{ width: "515px", pb: "20px" }}>
          <DialogContent sx={{ mb: 2, height: "30vh" }}>
            <DialogContentText id="alert-dialog-description">
              <Box
                sx={{
                  ...dFlex,
                  flexDirection: "column",
                  mb: 2,
                }}
              >
                <Typography
                  variant="medium"
                  sx={{
                    color: (theme) => theme.palette.text.dark,
                  }}
                >
                  {edit ? "Replace" : "Add"} Facilitator
                </Typography>
                <Typography
                  variant="semibold2"
                  sx={{
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  <Typography
                    variant="small"
                    onClick={() => {
                      navigate("/school/facilitator/add");
                    }}
                    sx={{
                      color: "#1D7EC5",
                      cursor: "pointer",
                      "&:hover": { textDecoration: "underline" },
                    }}
                  >
                    Click here
                  </Typography>{" "}
                  to add new facilitator
                </Typography>
              </Box>
              <Box>
                <Select
                  theme={reactSelectTheme}
                  options={options}
                  value={value}
                  onChange={handleChange}
                  maxMenuHeight={130}
                  isSearchable={false}
                  placeholder="Select facilitator"
                ></Select>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={loading}
              onClick={edit ? handleEdit : handleAdd}
              variant="primary"
            >
              {edit ? "Save" : "Add"}
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
