import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import { AccordionTransition } from "../../../const/constFunctions";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import PaymentFailure from "./Payment/PaymentFailure";
import PaymentSuccess from "./Payment/PaymentSuccess";
import SubscribeModal from "./SubscribeModal";

export default function ManageSubscription() {
  const [profileDetails] = useDataFetchHook("school/setting/getProfile");

  const [paymentRedirect, setPaymentRedirect] = useState({});
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    let paymentDetails = {};
    searchParams.forEach((val, key) => {
      paymentDetails[key] = val;
    });
    setPaymentRedirect(paymentDetails);
    return () => {};
  }, []);

  return (
    <Box id="change-admin" sx={{ my: 2 }}>
      <Accordion
        TransitionProps={AccordionTransition}
        sx={{ boxShadow: "none" }}
      >
        <AccordionSummary
          sx={{
            backgroundColor: (theme) => theme.palette.background.secondary,
            boxShadow: "0px 2px 4px 0px #0000001F",
            borderRadius: "5px",
          }}
          expandIcon={
            <KeyboardArrowDownIcon
              sx={{
                color: (theme) => theme.palette.primary.main,
                fontSize: "2rem",
              }}
            />
          }
          id="panel1a-header"
        >
          <Typography
            variant="bold"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            Manage Subscription
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            borderRadius: "7px",
            // ...dFlex,
            // justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ mt: 2, marginLeft: "8px" }}>
            <Typography sx={{ color: (theme) => theme.palette.text.main }}>
              Your Subscription will end on{" "}
              {new Date(
                profileDetails?.res?.data?.endDate
              ).toLocaleDateString()}
              . Click on cancel subscription if you want to stop the
              subscription. Your money will be refunded to your original bank
              account.{" "}
            </Typography>
            <Typography
              sx={{ color: (theme) => theme.palette.content.quizQuestion }}
            >
              Note: Once your subscription is cancelled, you will have 30 days
              of time to download the data. After 30 days your access to any of
              the data for registered students associated with your existing
              account will be permanently deleted.
            </Typography>
          </Box>
          <CancelSubscriptionModal />
          <Box sx={{ mt: 5, marginLeft: "8px" }}>
            <Typography sx={{ color: (theme) => theme.palette.text.main }}>
              Click here if you have reached the limit on your current
              subscription and wish to add more students to your existing
              account.{" "}
            </Typography>
          </Box>
          <Box sx={{ ml: "8px", mt: 3, mb: 5 }}>
            <SubscribeModal text={"Subscribe more Students"} />
          </Box>
        </AccordionDetails>
      </Accordion>
      {paymentRedirect?.status == "success" && (
        <PaymentSuccess data={paymentRedirect} />
      )}
      {paymentRedirect?.status == "failed" && (
        <PaymentFailure data={paymentRedirect} />
      )}
    </Box>
  );
}
