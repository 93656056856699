import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import NormalFAQComponent from "../Components/NormalFAQComponent";
import useDataFetchHook from "../Hooks/DataFetchHook";
import { flexCenter, itemsCenter } from "../common/commonStyles";
import { emptyDataSA } from "../const/imageUrl";

export default function NormalFAQ() {
  const url = process.env.REACT_APP_FAQ;
  const [apiCall, fetchAgain] = useDataFetchHook(url);
  const navigate = useNavigate();
  return (
    <Box>
      <Typography
        variant="medium"
        sx={{
          ...itemsCenter,
          cursor: "pointer",
          "&:hover": { textDecoration: "underline" },
          color: (theme) => theme.palette.text.main,
        }}
        onClick={() => {
          navigate(-1);
        }}
      >
        <NavigateBeforeIcon />
        Go Back
      </Typography>{" "}
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "20px",
          color: (theme) => theme.palette.text.dark,
          textAlign: "center",
          mb: 2,
        }}
      >
        Frequently Asked Questions
      </Typography>
      {apiCall?.loading && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />
        </Box>
      )}
      {/* {apiCall?.error && (
        <Box sx={{ ...flexCenter, flexDirection: "column", mt: 20 }}>
          {apiCall?.errorMessage ?? "Something went wrong"}
          <Button variant="primary-small" onClick={fetchAgain}>
            Retry
          </Button>
        </Box>
      )} */}
      {!apiCall.loading && apiCall?.res?.question.length < 0 && (
        <Box
          sx={{
            mt: 3,
            backgroundColor: (theme) => theme.palette.background.secondary,
            ...flexCenter,
            flexDirection: "column",
            py: 8,
            gap: 5,
            borderRadius: "12px",
          }}
        >
          <img
            src={emptyDataSA}
            alt="empty_faq"
            style={{ height: "254px", width: "345px" }}
          />
          <Typography
            variant="bold"
            sx={{ color: (theme) => theme.palette.text.light }}
          >
            No FAQ is added yet,
          </Typography>
          <Typography
            variant="bold"
            sx={{ color: (theme) => theme.palette.text.light }}
          >
            Once admin adds a FAQ it will be visible here,
          </Typography>
        </Box>
      )}
      <Box>
        {apiCall?.res?.question?.map((e, i) => (
          <NormalFAQComponent key={e.id} index={i} data={e} />
        ))}
      </Box>
    </Box>
  );
}
