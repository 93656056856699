import { Box } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingBar from "react-top-loading-bar";
import { createAxiosConfig } from "./Apis/axiosPayloads";
import { ROLE_LIST } from "./Apis/constVariables";
import "./App.css";
import OfflinePage from "./Pages/OfflinePage";
import Router from "./Router/Router";
import { LoadingBarContext } from "./context/TopLodingBarContext";
import { UserContext } from "./context/UserContext";
axios.defaults.withCredentials = true;
// export const messaging = getMessaging(app);
let tag = "G-7LYZBX8QWG";
let location = window.location.origin;
// console.log({ location });
switch (location) {
  case "http://localhost:3000":
    break;
  case "https://unuhdevweb.kdev.co.in":
    tag = "G-7LYZBX8QWG";
    break;
  case "https://dev-unuh-gcp.web.app":
    tag = "G-DTSQN27PB6";
    break;
  case "https://lms.unuh.app/":
    tag = "G-VV62KJE5PR";
    break;
  default:
    break;
}

ReactGA.initialize(tag);
function App() {
  const { ref } = useContext(LoadingBarContext);

  const { setUserDetails, setFcmToken } = useContext(UserContext);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const navigate = useNavigate();

  // useEffect(() => {
  //   try {
  //     Notification.requestPermission()
  //       .then((permission) => {
  //         if (permission === "granted") {
  //           console.log("Notification permission granted.");
  //         }
  //       })
  //       .then(() => {
  //         getToken(messaging, {
  //           vapidKey:
  //             "BPee1QwgyJkpJf-vfASW0Nv4H4QUSOqvBA1y0-kiA4E9ZTyYRUe9rC-Y9GwMUKaor_Y1Ezlch7ngVnDCeK3ZcKc",
  //         })
  //           .then(async (currentToken) => {
  //             if (currentToken) {
  //               console.log(currentToken);
  //               setFcmToken(currentToken);
  //             } else {
  //               console.log(
  //                 "No registration token available. Request permission to generate one."
  //               );
  //             }
  //           })
  //           .catch((e) => {
  //             console.log("error in getting token");
  //             console.log(e);
  //           });
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //       });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, []);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    const handleKeyPress = (e) => {
      if (e.code === "KeyZ") {
        if (e.ctrlKey && e.shiftKey) {
          window.location.reload();
        }
      }
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("online", handleOnline);
      document.removeEventListener("keydown", handleKeyPress);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  const ignoredPages = [
    "/",
    "/register",
    "/forgot-password",
    "/tos",
    "/reset-password",
    "/sponsor",
    "/sponsor/response",
  ];
  useEffect(() => {
    const getData = async () => {
      const location = window.location.pathname;
      if (ignoredPages.includes(location)) {
        return;
      }
      try {
        const idToken = localStorage.getItem("idToken");
        // console.log({ location });
        if (!idToken) {
          if (location !== "/login") {
            navigate("/login", {
              replace: true,
            });
            return;
          }
          return;
        }
        // ref.current.continuousStart();
        const url = "student/getProfile";
        const config = createAxiosConfig({ endPoint: url });
        const apiRes = await axios.request(config);
        // localStorage.setItem("idToken", resData?.idToken);
        setUserDetails({ ...apiRes?.data?.data });
        // ref.current.complete();
        if (location !== "/login" || location == "/") {
          return;
        }
        switch (apiRes?.data?.data?.role) {
          case ROLE_LIST.SUPER_ADMIN:
            window.location.href = "/admin/dashboard";
            break;
          case ROLE_LIST.CONTENT_UPLOADER:
            window.location.href = "/uploader/dashboard";
            break;
          case ROLE_LIST.SCHOOL_ADMIN:
            window.location.href = "/school/dashboard";
            break;
          case ROLE_LIST.FACILITATOR:
            window.location.href = "/facilitator/dashboard";
            break;
          case ROLE_LIST.PARENT_ADMIN:
            window.location.href = "/parent/dashboard";
            break;
          default:
            break;
        }
      } catch (e) {
        console.log({ e });
        // if (
        //   location == "/" ||
        //   location == "/register" ||
        //   location == "/forgot-password" ||
        //   location == "/reset-password"
        // ) {
        //   return;
        // }
        localStorage.clear("idToken");
        window.location.href = "/login";
      }
    };
    ReactGA.pageview(window.location.pathname + window.location.search);
    getData();
  }, [window.location.pathname]);

  return (
    <Box
      sx={{
        //  maxWidth: "1550px",
        margin: "auto",
      }}
    >
      <LoadingBar color="#358B8A" style={{ height: "3px" }} ref={ref} />
      {isOnline ? <Router /> : <OfflinePage />}
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        // theme="light"
        toastStyle={{
          border: "1px solid #BFBFBF",
          filter: "drop-shadow(0px 4px 21px rgba(0, 0, 0, 0.25))",
          fontWeight: 700,
          color: "#358B8A",
          textAlign: "center",
          width: "max-content",
          minWidth: "400px",
          borderRadius: "9px",
        }}
      />{" "}
    </Box>
  );
}

export default App;
