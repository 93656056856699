import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useTablePagination from "../../../../Hooks/TablePaginationHook";
import DownloadSelect from "../../../../common/DownloadSelect";
import { dFlex, flexBetweenCenter } from "../../../../common/commonStyles";
import {
  StyledTableCell,
  StyledTableRow,
  TableRowSkeletonComponent,
} from "../../../../const/Table";
import { FormTextField } from "../../../../const/TextField";
import {
  ArrowDropDownIconSvg,
  ArrowDropUpIconSvg,
  FilterIcon,
} from "../../../../images/icons";

const dropDownType = {
  ace: "ACE",
  pre: "SDQ Pre-Test",
  post: "SDQ Post-Test",
};
export default function OverallStudentRiskProfile({ apiData, orgData }) {
  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();
  const [searchData, setSearchData] = useState([]);
  const [showText, setShowText] = useState(false);
  const [selectedSortUp, setSelectedSortUp] = useState("");
  const [selectedSortDown, setSelectedSortDown] = useState("");
  const [selectOption, setSelectOption] = useState("default");
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");

  const sortArr = (para) => {
    setSelectedSortUp(para);
    setSelectedSortDown("");

    // Assuming 'searchData' is your array of objects
    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "ace":
          fir = parseInt(a?.ace ?? 0);
          sec = parseInt(b?.ace ?? 0);
          break;
        case "sdqPre":
          fir = parseInt(a?.sdqPre ?? 0);
          sec = parseInt(b?.sdqPre ?? 0);
          break;
        case "sdqPost":
          fir = parseInt(a?.sdqPost ?? 0);
          sec = parseInt(b?.sdqPost ?? 0);
          break;
        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir < sec) {
        return -1;
      }
      if (fir > sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  const sortArrDec = (para) => {
    setSelectedSortUp("");
    setSelectedSortDown(para);

    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "ace":
          fir = parseInt(a?.ace) ?? 0;
          sec = parseInt(b?.ace) ?? 0;
          break;
        case "sdqPre":
          fir = parseInt(a?.sdqPre) ?? 0;
          sec = parseInt(b?.sdqPre) ?? 0;
          break;
        case "sdqPost":
          fir = parseInt(a?.sdqPost) ?? 0;
          sec = parseInt(b?.sdqPost) ?? 0;
          break;
        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir > sec) {
        return -1;
      }
      if (fir < sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  const downloadCsv = () => {
    let data = searchData;
    data = data.map((row) => {
      let formattedData = {
        "Student Id": row.userId,
        "ACE Score": row.ace,
        "SDQ Pre Score": row.sdqPre,
        "SDq Post Score": row?.sdqPost,
      };
      return formattedData;
    });

    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Risk_Profiling_Score_${
      orgData?.name
    }_${new Date().toString()}.csv`;
    link.click();
  };

  const handleFilter = () => {
    if (selectOption == "default") {
      toast.error("Please select a type.");
      return;
    }
    if (toValue == "" || fromValue == "") {
      toast.error("Please provide a range.");
      return;
    }
    if (
      toValue !== "" &&
      fromValue !== "" &&
      parseInt(toValue) < parseInt(fromValue)
    ) {
      toast.error("To value should not be less than From value.");
      return;
    }

    const filteredResults = apiData?.res?.data?.filter((item) => {
      if (selectOption === "ace") {
        return (
          item.ace !== "NA" &&
          item.ace >= parseInt(fromValue) &&
          item.ace <= parseInt(toValue)
        );
      } else if (selectOption === "pre") {
        return (
          item.sdqPre !== "NA" &&
          item.sdqPre >= parseInt(fromValue) &&
          item.sdqPre <= parseInt(toValue)
        );
      } else if (selectOption === "post") {
        return (
          item.sdqPost !== "NA" &&
          item.sdqPost >= parseInt(fromValue) &&
          item.sdqPost <= parseInt(toValue)
        );
      }
      return true;
    });
    setShowText(`Number of students in ${
      dropDownType[selectOption]
    } and range ${fromValue} - ${toValue} is:
          ${filteredResults?.length ?? 0}`);
    setSearchData(filteredResults);
  };

  const handleClearFilter = () => {
    setSelectOption("default");
    setFromValue("");
    setToValue("");
    setSelectedSortDown("");
    setShowText("");
    setSelectedSortUp("");
    setSearchData(apiData?.res?.data);
  };

  const handleChange = (e) => {
    setSelectOption(e.target.value);
  };

  useEffect(() => {
    if (!apiData.res) {
      return;
    }
    setSearchData(apiData?.res?.data);
  }, [apiData]);
  return (
    <>
      <Box sx={{ ...flexBetweenCenter, mb: 3 }}>
        <Box
          sx={{
            ...dFlex,
            gap: 2,
            boxSizing: "border-box",
          }}
        >
          <FormControl sx={{ width: 180 }}>
            <Select
              sx={{
                background: "#FFF",
                color: "#809fb8",
                height: "38px",
                borderRadius: "4px",
              }}
              name="selectOption"
              onChange={handleChange}
              value={selectOption}
            >
              <MenuItem value="default" disabled>
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  Select Type
                </Typography>
              </MenuItem>
              <MenuItem value="ace">
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  ACE
                </Typography>
              </MenuItem>
              <MenuItem value="pre">
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  SDQ Pre-Test
                </Typography>
              </MenuItem>
              <MenuItem value="post">
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  SDQ Post-Test
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
          <FormTextField
            sx={{ maxWidth: "15%" }}
            placeholder="From"
            type="number"
            autoComplete="none"
            value={fromValue}
            onChange={(e) => setFromValue(e.target.value)}
          />
          <FormTextField
            sx={{ maxWidth: "15%" }}
            placeholder="To"
            type="number"
            autoComplete="none"
            value={toValue}
            onChange={(e) => setToValue(e.target.value)}
          />
          <Box>
            <Button variant="primary-medium" onClick={handleFilter}>
              <FilterIcon /> Filter
            </Button>
            <Button
              onClick={handleClearFilter}
              sx={{
                textTransform: "none",
                color: (theme) => theme.palette.text.main,
              }}
            >
              {" "}
              x Clear
            </Button>
          </Box>
        </Box>
        <DownloadSelect downloadCsv={downloadCsv} />
      </Box>
      {showText && (
        <Typography
          variant="semibold"
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          {showText}
        </Typography>
      )}
      <Box
      // sx={{ pr: 3 }}
      >
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "13px",
            my: 3,
            pt: 1,
            px: "20px",
            boxSizing: "border-box",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ minWidth: "200px" }}>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Student Id
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ ...dFlex, gap: 1 }}>
                    <Typography
                      variant="medium"
                      sx={{ color: (theme) => theme.palette.text.dark }}
                    >
                      ACE Score
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArr("ace")}
                      >
                        <ArrowDropUpIconSvg focus={selectedSortUp === "ace"} />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArrDec("ace")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown === "ace"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ ...dFlex, gap: 1 }}>
                    <Typography
                      variant="medium"
                      sx={{ color: (theme) => theme.palette.text.dark }}
                    >
                      SDQ Pre test
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArr("sdqPre")}
                      >
                        <ArrowDropUpIconSvg
                          focus={selectedSortUp === "sdqPre"}
                        />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArrDec("sdqPre")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown === "sdqPre"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ ...dFlex, gap: 1 }}>
                    <Typography
                      variant="medium"
                      sx={{ color: (theme) => theme.palette.text.dark }}
                    >
                      SDQ Post test
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArr("sdqPost")}
                      >
                        <ArrowDropUpIconSvg
                          focus={selectedSortUp === "sdqPost"}
                        />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArrDec("sdqPost")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown === "sdqPost"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {apiData?.loading ? (
              <TableRowSkeletonComponent column={4} rows={4} />
            ) : (
              <TableBody>
                {searchData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <StyledTableRow
                      style={{
                        cursor: "default",
                      }}
                    >
                      <StyledTableCell sx={{ maxWidth: "200px" }}>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row.userId}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row.ace}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row.sdqPre}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row.sdqPost}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            rowsPerPageOptions={[]}
            count={searchData.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </>
  );
}
