import { Box, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import FacilitatorBrawtaChallengeTab from "../../../Components/Facilitator/BrawtaChallenge/FacilitatorBrawtaChallengeTab";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { flexCenter } from "../../../common/commonStyles";
import { BatchEmpty } from "../../../const/imageUrl";
import { TabPanel } from "../../Admin/Dashboard/SchoolSubscribers";

export default function FacilitatorBrawtaChallenge() {
  const [tabs, setTabs] = useState([]);
  const [value, setValue] = useState(0);
  const url =
    process.env.REACT_APP_SCHOOL_FACILITATOR +
    `/batchlist?filterCompleted=true`;

  const [apiData] = useDataFetchHook(url);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!apiData?.res) {
      return;
    }
    setTabs(apiData?.res?.batch);
    return () => {
      setTabs([]);
    };
  }, [apiData?.res]);

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Brawta Challenge"]} />

      {apiData?.loading && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />
        </Box>
      )}

      {apiData?.res?.batch?.length < 1 && (
        <Box
          sx={{
            mt: 3,
            backgroundColor: (theme) => theme.palette.background.secondary,
            ...flexCenter,
            flexDirection: "column",
            py: 8,
            gap: 5,
            borderRadius: "12px",
          }}
        >
          <img src={BatchEmpty} style={{ height: "220px", width: "600px" }} />
          <Typography
            variant="bold"
            sx={{ color: (theme) => theme.palette.text.light }}
          >
            No Batches to show
          </Typography>
        </Box>
      )}

      {tabs?.length > 0 && (
        <Box
          sx={{
            width: "100%",
            mt: 3,
            borderBottom: `1px solid #BFBFBF`,
          }}
        >
          <Tabs value={value} onChange={handleChange} variant="fullWidth">
            {tabs?.map((e, i) => (
              <Tab
                sx={{
                  textTransform: "none",
                }}
                label={
                  <Typography variant={value == i ? "bold2" : "medium"}>
                    {e.batchName}
                  </Typography>
                }
                value={i}
              />
            ))}
          </Tabs>
        </Box>
      )}
      {tabs?.map((e, i) => (
        <TabPanel value={value} key={e.batchName} index={i}>
          <FacilitatorBrawtaChallengeTab data={e} />
        </TabPanel>
      ))}
    </Box>
  );
}
