import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { dFlex, flexBetweenCenter } from "../../../../common/commonStyles";

export default function AceQuestionAnswering({
  data,
  index,
  answers,
  handleAnswer,
}) {
  return (
    <Box sx={{ my: 4 }}>
      <Box>
        <Typography
          variant="medium"
          sx={{ color: (theme) => theme.palette.text.main }}
        >
          {index + 1}. {data?.question}.
        </Typography>
      </Box>
      <Box sx={{ ...flexBetweenCenter, px: 3 }}>
        <Box sx={{ ...dFlex, flexDirection: "column" }}>
          <Typography
            variant="medium"
            sx={{
              color: (theme) => theme.palette.text.main,
            }}
          >
            a. {data?.option?.[0]}
          </Typography>
          <Typography
            variant="medium"
            sx={{
              color: (theme) => theme.palette.text.main,
              textAlign: "center",
            }}
          >
            or
          </Typography>
          <Typography
            variant="medium"
            sx={{ color: (theme) => theme.palette.text.main }}
          >
            b. {data?.option?.[1]}
          </Typography>
        </Box>
        <Box>
          <Button
            variant={answers?.[data?.id] === 1 ? "primary-small" : "outlined"}
            onClick={() => {
              handleAnswer(data?.id, 1);
            }}
            sx={{
              textTransform: "none",
              height: "28px",
              mr: "5px",
            }}
          >
            Yes
          </Button>
          <Button
            variant={answers?.[data?.id] === 0 ? "primary-small" : "outlined"}
            onClick={() => {
              handleAnswer(data?.id, 0);
            }}
            sx={{
              textTransform: "none",
              height: "28px",
              mr: "5px",
            }}
          >
            No
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
