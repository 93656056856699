import { GET, IP } from "./constVariables";

export const createAxiosConfig = ({
  endPoint,
  method = GET,
  data = {},
  other = {},
}) => {
  if (!endPoint) {
    throw new Error("EndPoint must be specified");
  }
  const idToken = localStorage.getItem("idToken");
  const url = `${IP}/${endPoint}`;

  const config = {
    url,
    method,
    data,
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
    ...other,
  };
  return config;
};
