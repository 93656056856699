import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import countryList from "react-select-country-list";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import useTablePagination from "../../../Hooks/TablePaginationHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import {
  dFlex,
  flexBetweenCenter,
  textEllipses,
} from "../../../common/commonStyles";
import {
  StyledTableCell,
  StyledTableRow,
  TableRowSkeletonComponent,
} from "../../../const/Table";
import { reactSelectTheme } from "../../../const/constFunctions";
import {
  ArrowDropDownIconSvg,
  ArrowDropUpIconSvg,
  FilterIcon,
  QuestionnairesIcon,
} from "../../../images/icons";

export default function RiskProfiling() {
  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();
  const [apiCall] = useDataFetchHook(
    process.env.REACT_APP_SA_RISK_PROFILING_SUBSCRIBERS
  );

  const navigate = useNavigate();
  const [selectOption, setSelectOption] = useState("default");
  const [selectedSortUp, setSelectedSortUp] = useState("");
  const [selectedSortDown, setSelectedSortDown] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [selectedType, setSelectedType] = useState("default");
  const [country, setCountry] = useState(null);
  const options = useMemo(() => countryList().getData(), []);

  const handleFilter = () => {
    let filteredData = apiCall?.res?.data ?? [];

    if (selectOption !== "default") {
      filteredData = filteredData.filter((item) => item.type === selectOption);
    }

    if (country) {
      filteredData = filteredData.filter((item) => {
        if (item.country?.label) {
          return item.country.label === country.label;
        }
        return item.country === country.label;
      });
    }
    setSearchData(filteredData);
  };

  const handleClearFilter = () => {
    setSelectOption("default");
    setSelectedSortDown("");
    setSelectedSortUp("");
    setCountry(null);
    setSearchData(apiCall?.res?.data ?? []);
  };

  const handleChange = (e) => {
    setSelectOption(e.target.value);
  };

  useEffect(() => {
    if (!apiCall?.res) {
      return;
    }
    setSearchData(apiCall?.res?.data);
  }, [apiCall?.res]);

  const sortArr = (para) => {
    setSelectedSortUp(para);
    setSelectedSortDown("");

    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "name":
          fir = a.name.toLowerCase();
          sec = b.name.toLowerCase();
          break;
        case "studentsCount":
          fir = parseInt(a.studentsCount);
          sec = parseInt(b.studentsCount);
          break;
        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir < sec) {
        return -1;
      }
      if (fir > sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  const sortArrDec = (para) => {
    setSelectedSortUp("");
    setSelectedSortDown(para);

    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "name":
          fir = a.name.toLowerCase();
          sec = b.name.toLowerCase();
          break;
        case "studentsCount":
          fir = parseInt(a.studentsCount);
          sec = parseInt(b.studentsCount);
          break;
        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir > sec) {
        return -1;
      }
      if (fir < sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Risk Profiling"]} />

      <Box sx={{ ...flexBetweenCenter, mb: 3 }}>
        <Box
          sx={{
            ...dFlex,
            flex: 1,
            gap: 2,
            boxSizing: "border-box",
          }}
        >
          <FormControl sx={{ width: "180px" }}>
            <Select
              sx={{
                background: "#FFF",
                color: "#809fb8",
                height: "38px",
                borderRadius: "4px",
              }}
              name="selectOption"
              onChange={handleChange}
              value={selectOption}
            >
              <MenuItem value="default" disabled>
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  Select Type
                </Typography>
              </MenuItem>
              {/* File type options */}
              <MenuItem value="Parent">
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  Parent
                </Typography>
              </MenuItem>
              <MenuItem value="School">
                <Typography
                  variant="medium2"
                  sx={{
                    fontWeight: 600,
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  School / Institution
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ width: "180px" }}>
            <ReactSelect
              options={options}
              theme={reactSelectTheme}
              value={country}
              onChange={(e) => setCountry(e)}
              placeholder="select country"
            />{" "}
          </FormControl>
          <Box>
            <Button onClick={handleFilter} variant="primary-medium">
              <FilterIcon /> Filter
            </Button>
            <Button
              onClick={handleClearFilter}
              sx={{
                textTransform: "none",
                color: (theme) => theme.palette.text.main,
              }}
            >
              {" "}
              x Clear
            </Button>
          </Box>
        </Box>
        <Button
          variant="primary"
          sx={{ gap: 1 }}
          onClick={() => {
            navigate("questionnaire");
          }}
        >
          <QuestionnairesIcon />
          Questionnaires
        </Button>
      </Box>
      <Box
      // sx={{ pr: 3 }}
      >
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "13px",
            my: 3,
            pt: 1,
            px: "20px",
            boxSizing: "border-box",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Box sx={{ ...dFlex, gap: 1 }}>
                    <Typography
                      variant="medium"
                      sx={{ color: (theme) => theme.palette.text.dark }}
                    >
                      Subscriber Name
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArr("name")}
                      >
                        <ArrowDropUpIconSvg focus={selectedSortUp === "name"} />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArrDec("name")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown === "name"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ ...dFlex, gap: 1 }}>
                    <Typography
                      variant="medium"
                      sx={{ color: (theme) => theme.palette.text.dark }}
                    >
                      Students Count
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArr("studentsCount")}
                      >
                        <ArrowDropUpIconSvg
                          focus={selectedSortUp === "studentsCount"}
                        />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArrDec("studentsCount")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown === "studentsCount"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Country
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Subscriber Type
                  </Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {apiCall?.loading ? (
              <TableRowSkeletonComponent column={4} rows={4} />
            ) : (
              <TableBody>
                {searchData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow
                      key={row?.id}
                      onClick={() => {
                        navigate(`${row?.id}`, {
                          state: {
                            data: row,
                          },
                        });
                      }}
                    >
                      <StyledTableCell
                        sx={{ ...textEllipses, maxWidth: "150px" }}
                      >
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row.name}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row.studentsCount ?? "NA"}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row.country?.label
                            ? row.country?.label
                            : row.country ?? "NA"}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="regular"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {row.type ?? ""}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            rowsPerPageOptions={[]}
            count={searchData.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </Box>
  );
}
