import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../Apis/axiosPayloads";
import { PATCH, POST } from "../../Apis/constVariables";
import { flexCenter, itemsCenter } from "../../common/commonStyles";
import { StyledTableCell } from "../../const/Table";
import { FormTextField } from "../../const/TextField";
import {
  checkSize,
  convertToBase64File,
  getFormattedDate,
} from "../../const/constFunctions";
import { EditIconColor, ModuleCreateIcon } from "../../images/icons";
import DeleteModuleModal from "../Admin/Content/Module/DeleteModuleModal";
import "./styles/Form.css";
import "./styles/GroupList.css";

const initDetails = {
  moduleName: "",
  moduleDescription: "",
  timeInHours: "",
  timeInMinutes: "",
  tokens: "",
  createdDate: "",
  lastVisited: "",
  badge: null,
};

const Course = ({ editCourseInfo, setIsCourseIdSet, courseLoading, view }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();
  const [courseDetails, setCourseDetails] = useState(initDetails);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [editdataflagForLoading, setEditdataflagForLoading] = useState(true);

  const handleSelectBadge = async (e) => {
    try {
      if (e?.target?.files.length > 0) {
        let file = e.target.files[0];

        if (!file.type.includes("image")) {
          e.target.value = "";
          toast.error("Please select a image file");
          return;
        }
        if (checkSize(file) > 10) {
          e.target.value = "";
          toast.error("Please upload an image less than 10MB");
          return;
        }
        const base64 = await convertToBase64File(file);
        setFile({ file: e?.target?.files?.[0], base64: base64 });
      } else setFile(null);
    } catch (err) {
      console.log(err);
      e.target.value = "";
      toast.error("Something went wrong, Please try again");
    }
  };

  const updateCourse = async (data) => {
    try {
      if (!data?.moduleDescription.trim() || !data?.moduleName.trim()) {
        toast.error("Fields Cannot be empty");
        return;
      }
      setEditable(false);
      setEditdataflagForLoading(true);
      // console.log({ data });
      setLoading(true);
      let nsn = data?.moduleName.replaceAll(" ", "");
      let dat = /\//i.test(nsn);

      if (dat) {
        toast.error("You cannot use (/) characters");
        return;
      }
      //https://unuhdevapi.kdev.co.in/api/content/cb/course/jas47z
      const url = process.env.REACT_APP_MODULE + `/${courseDetails?.moduleId}`;
      const dataBody = {
        moduleDescription: data?.moduleDescription.trim() ?? null,
        moduleName: data?.moduleName.trim() ?? null,
        tokens: Math.abs(+data?.tokens) ?? null,
        timeInMinutes: Math.abs(+data?.timeInMinutes) ?? null,
        timeInHours: Math.abs(+data?.timeInHours) ?? null,
        badge: file?.base64 ?? courseDetails?.badge,
      };
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: dataBody,
      });
      const apiRes = await axios.request(config);
      setLoading(false);
      toast(apiRes?.data?.message);
      setCourseDetails({
        ...courseDetails,
        ...data,
        badge: file?.base64 ?? courseDetails?.badge,
        lastUpdate: getFormattedDate(),
      });
      setIsCourseIdSet(courseDetails?.moduleId);
      const elements = document.getElementsByClassName("course-builder-top");
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.display = "none";
      }
      document.getElementById("addC").style.display = "none";
      document.getElementById("editC").style.display = "";
    } catch (err) {
      setEditdataflagForLoading(false);
      setLoading(false);
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
  };
  const addCourse = async (data) => {
    try {
      if (!data?.moduleDescription.trim() || !data?.moduleName.trim()) {
        toast.error("Fields Cannot be empty");
        return;
      }
      if (!file?.base64) {
        toast.error("Badge is required");
        return;
      }
      setEditable(false);
      setEditdataflagForLoading(true);
      // console.log({ data });
      setLoading(true);
      let nsn = data?.moduleName.replaceAll(" ", "");
      let dat = /\//i.test(nsn);

      if (dat) {
        toast.error("You cannot use (/) characters");
        return;
      }
      const url = process.env.REACT_APP_MODULE;
      const dataBody = {
        moduleDescription: data?.moduleDescription.trim() ?? null,
        moduleName: data?.moduleName.trim() ?? null,
        tokens: Math.abs(+data?.tokens) ?? null,
        timeInMinutes: Math.abs(+data?.timeInMinutes) ?? null,
        timeInHours: Math.abs(+data?.timeInHours) ?? null,
        badge: file?.base64 ?? null,
      };
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: dataBody,
      });
      const apiRes = await axios.request(config);
      setLoading(false);
      toast("Module " + data?.moduleName + " created");
      setIsCourseIdSet(apiRes?.data?.moduleId);
      setCourseDetails({
        ...data,
        moduleId: apiRes?.data?.moduleId,
        created: getFormattedDate(),
        lastUpdate: getFormattedDate(),
      });
      const elements = document.getElementsByClassName("course-builder-top");
      console.log(elements);
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.display = "none";
      }
      document.getElementById("addC").style.display = "none";
      document.getElementById("editC").style.display = "";
    } catch (err) {
      console.log(err);
      setEditdataflagForLoading(false);
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  function cancelEdit() {
    setEditable(false);
    document.getElementById("addC").style.display = "none";
    const elements = document.getElementsByClassName("course-builder-top");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = "none";
    }
  }
  function edit() {
    document.getElementById("addC").style.display = "";
    const elements = document.getElementsByClassName("course-builder-top");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = "";
    }
  }

  useEffect(() => {
    if (!editCourseInfo) return;
    setCourseDetails(editCourseInfo);
    setEditable(true);
    reset(editCourseInfo);
    setEditdataflagForLoading(false);
    if (view) {
      setIsCourseIdSet(editCourseInfo?.moduleId);
      const elements = document.getElementsByClassName("course-builder-top");
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.display = "none";
      }
      document.getElementById("addC").style.display = "none";
      document.getElementById("editC").style.display = "";
    }
  }, [editCourseInfo]);

  return (
    <>
      {!courseLoading ? (
        <Box className="course-builder-container">
          <Box
            id="editC"
            style={{ display: "none" }}
            className="course-builder-top-info"
          >
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "13px",
                pt: 1,
                px: "20px",
                boxSizing: "border-box",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="bold2"
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                        }}
                      >
                        Module Name
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="bold2"
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                        }}
                      >
                        Created Date
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="bold2"
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                        }}
                      >
                        Last Updated
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="bold2"
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                        }}
                      >
                        Action
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <StyledTableCell>
                      <Typography
                        variant="medium"
                        sx={{
                          color: (theme) => theme.palette.text.main,
                        }}
                      >
                        {courseDetails?.moduleName}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="medium"
                        sx={{
                          color: (theme) => theme.palette.text.main,
                        }}
                      >
                        {courseDetails?.created}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="medium"
                        sx={{
                          color: (theme) => theme.palette.text.main,
                        }}
                      >
                        {courseDetails?.lastUpdate}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box style={{ display: "flex" }}>
                        <IconButton
                          sx={{ p: 0, mr: 2 }}
                          onClick={() => {
                            setEditable(true);
                            edit();
                          }}
                        >
                          <EditIconColor />
                        </IconButton>
                        {/* <IconButton
                          sx={{ p: 0, ml: 2 }}
                          onClick={() => {
                            toast("Module Delete Clicked");
                            // setEditable(true);
                            // edit();
                            // alert("Delete Clicked");
                          }}
                        >
                          <DeleteIcon fill={"#358B8A"} />
                        </IconButton> */}
                        <DeleteModuleModal
                          data={courseDetails}
                          internal={true}
                        />
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box className="course-builder-top" sx={{ marginTop: "40px" }}>
            <Box className="course-builder-info">
              <Typography
                htmlFor="courseName"
                className="course-builder-inputLable"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Module Title*
              </Typography>
              <FormTextField
                {...register("moduleName", {
                  required: "Module Title is required",
                })}
                id="moduleName"
                autoComplete="off"
                autoFocus
                placeholder="UX Designer"
                sx={{ width: "40%" }}
                error={!!errors?.moduleName}
                helperText={errors?.moduleName?.message}
              />
            </Box>

            <Box className="course-builder-info">
              <Typography
                className="course-builder-inputLable"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Short description*
              </Typography>
              <FormTextField
                {...register("moduleDescription", {
                  required: "Module Description is required",
                })}
                autoComplete="off"
                placeholder="Lorem ipsum dolor sit amet, consectetur adip"
                sx={{ width: "40%" }}
                error={!!errors?.moduleDescription}
                helperText={errors?.moduleDescription?.message}
              />
            </Box>

            <Box className="course-builder-info">
              <Typography
                className="course-builder-inputLable"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Module Duration
              </Typography>
              <Box style={{ width: "40%", ...itemsCenter, gap: 4 }}>
                <Typography
                  sx={{
                    color: (theme) => theme.palette.text.main,
                  }}
                >
                  Hours
                </Typography>
                <FormTextField
                  {...register("timeInHours", {
                    min: { value: 0, message: "Minimum is 0" },
                  })}
                  defaultValue={0}
                  autoComplete="off"
                  type="number"
                  style={{ width: "22%" }}
                  error={!!errors.timeInHours}
                  helperText={errors?.timeInHours?.message}
                />
                <Typography
                  sx={{
                    color: (theme) => theme.palette.text.main,
                  }}
                >
                  Minute
                </Typography>
                <FormTextField
                  {...register("timeInMinutes", {
                    min: { value: 0, message: "Minimum is 0" },
                  })}
                  defaultValue={0}
                  autoComplete="off"
                  type="number"
                  style={{ width: "22%" }}
                  error={!!errors.timeInMinute}
                  helperText={errors?.timeInMinute?.message}
                />
              </Box>
            </Box>

            <Box className="course-builder-info">
              <Typography
                className="course-builder-inputLable"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Tokens
              </Typography>
              <FormTextField
                {...register("tokens", { min: 0 })}
                variant="outlined"
                type="number"
              />
            </Box>
            <Box
              sx={{
                ...itemsCenter,
                width: "100%",
                my: "20px",
              }}
            >
              <Typography
                className="course-builder-inputLable"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Upload Badge
              </Typography>
              <Box sx={{ ...itemsCenter, gap: "10px" }}>
                <Box
                  component={"label"}
                  sx={{
                    ...flexCenter,
                    height: "35px",
                    width: "125px",
                    cursor: "pointer",
                    borderRadius: "4px",
                    color: (theme) => theme.palette.text.main,
                    backgroundColor: (theme) => theme.palette.text.lighter,
                  }}
                >
                  Choose a file
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleSelectBadge(e)}
                    style={{ display: "none" }}
                  />
                </Box>
                <Typography
                  variant="regular"
                  title={file ? file?.file?.name : "No File Chosen"}
                  sx={{
                    color: (theme) => theme.pal,
                  }}
                >
                  {file ? file?.file?.name : "No File Chosen"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              ...itemsCenter,
              justifyContent: "flex-end",
            }}
          >
            {editable && editdataflagForLoading && (
              <Button
                sx={{ textTransform: "none", mr: 2 }}
                onClick={cancelEdit}
              >
                Cancel
              </Button>
            )}
            <Button
              variant="primary"
              id="addC"
              disabled={loading}
              sx={{ my: 2 }}
              onClick={handleSubmit(editable ? updateCourse : addCourse)}
            >
              {editable ? (
                <>
                  <ModuleCreateIcon /> Update Module
                </>
              ) : (
                <>
                  <ModuleCreateIcon /> Create Module
                </>
              )}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={flexCenter}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default Course;
