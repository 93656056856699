import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React from "react";
import { AccordionTransition } from "../const/constFunctions";
export default function NormalFAQComponent({ index, data }) {
  return (
    <Accordion TransitionProps={AccordionTransition} sx={{ my: 2 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={
          {
            // border: "1px solid red",
          }
        }
      >
        <Typography
          variant="medium"
          sx={{ color: (theme) => theme.palette.text.dark }}
        >
          {data?.question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          variant="medium"
          sx={{ color: (theme) => theme.palette.text.main }}
        >
          {data?.answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
