import { Box, Button, CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../Apis/axiosPayloads";
import ItemVideoError from "../../Components/Admin/Content/ItemVideoError";
import ModuleElement from "../../Components/CourseOverview/ModuleElement";
import useDataFetchHook from "../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../common/NavBreadCrumbs";
import { flexCenter, itemsCenter } from "../../common/commonStyles";
import { PublishIcon } from "../../images/icons";

export default function UploaderDashboard() {
  const url = process.env.REACT_APP_MODULE + "/uploaderDashboard";
  const [itemError, setItemError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiCall] = useDataFetchHook(url);

  const handlePublish = async () => {
    try {
      if (loading) {
        toast.error("Another request is in progress");
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_MODULE_PUBLISH;
      const config = createAxiosConfig({
        endPoint: url,
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.type == "videoError") {
        setItemError(true);
      }
      toast.error(err?.response?.data?.message);
    }
    setLoading(false);
  };
  return (
    <Box>
      <NavBreadCrumbs pageTags={["Dashboard"]} />

      <Box sx={{ ...itemsCenter, justifyContent: "flex-end" }}>
        <Button variant="primary" sx={{ mr: 0 }} onClick={handlePublish}>
          <PublishIcon />
          Publish
        </Button>
      </Box>
      {(apiCall?.loading || loading) && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />
        </Box>
      )}
      <Box sx={{ mt: 3 }}>
        {apiCall?.res?.sequence?.map((e, i) => (
          <ModuleElement key={i} data={e} unPublished={true} />
        ))}
      </Box>
      <ItemVideoError open={itemError} setOpen={setItemError} />
    </Box>
  );
}
