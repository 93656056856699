import { Box, Typography } from "@mui/material";
import React from "react";

const ProgressBar = ({ progress, height, fontSize }) => {
  const parentDiv = {
    height: height,
    width: "100%",
    backgroundColor: "#D9D9D9",
    borderRadius: "13px",
  };

  const childDiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: (theme) => theme.palette.primary.main,
    borderRadius: "13px",
    textAlign: "center",
  };

  const progressText = {
    color: "#F5F5F5",
    fontWeight: 600,
    fontSize: fontSize,
    pl: 1,
    whiteSpace: "nowrap",
  };

  return (
    <Box sx={parentDiv}>
      <Box sx={childDiv}>
        <Typography sx={progressText}>{`${progress}% Completed`}</Typography>
      </Box>
    </Box>
  );
};

export default ProgressBar;
