import {
  Box,
  CircularProgress,
  TablePagination,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CompletedStudentCard from "../../../Components/School/ManageBatch/CompletedStudentCard";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import useTablePagination from "../../../Hooks/TablePaginationHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { flexCenter } from "../../../common/commonStyles";
import { getFormattedDateForChat } from "../../../const/constFunctions";

export default function ParentLeaderBoardSeeAll() {
  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();

  // process.env.REACT_APP_PARENT_LEADERBOARD + `?limit=10&type=asc`
  const [url, setUrl] = useState();
  const [leaderBoardApi] = useDataFetchHook(url);

  const handlePageChange = (_, newPage) => {
    try {
      if (newPage > page) {
        const lastRank =
          leaderBoardApi?.res?.data?.[leaderBoardApi?.res?.data?.length - 1]
            ?.rank;
        let newUrl =
          process.env.REACT_APP_PARENT_LEADERBOARD +
          `?limit=${rowsPerPage}&type=asc&startAfter=${lastRank}`;
        console.log({ newUrl });
        setUrl(newUrl);
      } else if (newPage < page) {
        const lastRank = leaderBoardApi?.res?.data?.[0]?.rank;
        let newUrl =
          process.env.REACT_APP_PARENT_LEADERBOARD +
          `?limit=${rowsPerPage}&type=asc&endBefore=${lastRank}`;
        console.log({ newUrl });
        setUrl(newUrl);
      }
      handleChangePage(_, newPage);
    } catch (e) {
      console.log({ e });
    }
  };

  useEffect(() => {
    try {
      if (page == 0) {
        setUrl(
          process.env.REACT_APP_PARENT_LEADERBOARD +
            `?limit=${rowsPerPage}&type=asc`
        );
      }
    } catch (e) {
      console.log({ e });
    }
  }, [page]);

  useEffect(() => {
    try {
      setUrl(
        process.env.REACT_APP_PARENT_LEADERBOARD +
          `?limit=${rowsPerPage}&type=asc`
      );
      handleChangePage("", 0);
    } catch (e) {
      console.log({ e });
    }
  }, [rowsPerPage]);

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Dashboard", "LeaderBoard"]} />
      {leaderBoardApi?.res?.data?.length > 0 && (
        <Typography
          variant="medium2"
          sx={{ color: (theme) => theme.palette.text.main }}
        >
          Last Updated:{" "}
          {leaderBoardApi?.res?.lastUpdated ? (
            <b>
              {getFormattedDateForChat(
                new Date(leaderBoardApi?.res?.lastUpdated)
              )}
            </b>
          ) : (
            <b>NA</b>
          )}
          {/* {new Date(leaderBoardApi?.res?.lastUpdated).toLocaleString()} */}
        </Typography>
      )}{" "}
      <Box>
        {leaderBoardApi?.loading && (
          <Box sx={flexCenter}>
            <CircularProgress />
          </Box>
        )}
        {leaderBoardApi?.res?.data?.map((e) => (
          <CompletedStudentCard data={e} />
        ))}
      </Box>
      {leaderBoardApi?.res?.data?.length >= 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            rowsPerPageOptions={[]}
            count={leaderBoardApi?.res?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            // onPageChange={handleChangePage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
}
