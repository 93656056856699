import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../../../common/ProgressBar";
import { itemsCenter } from "../../../common/commonStyles";
export default function ModuleStatus({ index, status, show, userInfo, data }) {
  const navigate = useNavigate();

  return (
    <>
      {status == "completed" ? (
        <Box
          sx={{
            ...itemsCenter,
            background: "#FFF",
            minHeight: "12vh",
            mb: 2,
            px: 4,
            gap: 2,
            width: "90%",
            borderRadius: "16px",
          }}
        >
          <img src={data?.badge} height={"60px"} width={"45px"} alt="badge" />
          <Typography
            variant="bold"
            sx={{
              color: (theme) => theme.palette.text.main,
            }}
          >
            Successfully Completed {data?.moduleName}
          </Typography>
          {show && (
            <Typography
              variant="semibold"
              onClick={() => {
                navigate(`answers`, {
                  state: {
                    studentInfo: userInfo,
                    moduleData: data,
                  },
                });
              }}
              sx={{
                mr: 1,
                cursor: "pointer",
                ml: "auto",
                color: (theme) => theme.palette.primary.main,
                textDecoration: "underline",
              }}
            >
              Check test answers
            </Typography>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "12vh",
            mb: 2,
            px: 1,
            gap: 2,
            width: "90%",
            borderRadius: "16px",
          }}
        >
          <Typography
            variant="bold"
            sx={{
              color: (theme) => theme.palette.text.main,
            }}
          >
            Module {index} {`- ${data?.moduleName}` ?? ""}
          </Typography>
          <ProgressBar height={"25px"} progress={data?.percentage ?? 0} />
        </Box>
      )}
    </>
  );
}
