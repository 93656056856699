import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  dFlex,
  flexSpaceBetween,
  itemsCenter,
} from "../../../../common/commonStyles";

export default function ModuleMCQCard({ value, index }) {
  // const [options, setOptions] = useState([
  //   { label: "Option 1", count: "1000000" },
  //   { label: "Option 2", count: "526365" },
  //   { label: "Option 3", count: "798741" },
  //   { label: "Option 4", count: "7498741" },
  // ]);
  const tempArr = [];
  value.data.options.map((_, idx) => {
    tempArr.push({ label: _, count: value.options[idx] });
  });
  const [options, setOptions] = useState(tempArr);
  return (
    <Box
      sx={{
        my: 3,
        px: 3,
        borderRadius: "15px",
      }}
    >
      <Box sx={{ mb: 1, ...flexSpaceBetween, gap: 8 }}>
        <Typography
          variant="medium"
          sx={{
            flex: "80%",
            color: (theme) => theme.palette.text.main,
          }}
        >
          {index + 1}. {value.question}
        </Typography>
        <Typography
          variant="regular"
          sx={{
            flex: "10%",
            color: (theme) => theme.palette.text.main,
          }}
        >
          Answers Count
        </Typography>
      </Box>

      <Box sx={{ ...dFlex, flexDirection: "column", mt: 2 }}>
        {options?.map((e, i) => (
          <Box
            sx={{
              ...itemsCenter,
              gap: 2,
              my: 1,
              borderRadius: "6px",
              backgroundColor: (theme) => theme.palette.background.secondary,
            }}
          >
            <Box
              sx={{
                p: "9px 20px",
                flex: "80%",
                color: (theme) => theme.palette.text.main,
                ...dFlex,
                // boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.12)",
              }}
            >
              <Box
                sx={{
                  mr: 2,
                  width: "22px",
                  borderRadius: "4px",
                  border: (theme) => `1px solid ${theme.palette.primary.main}`,
                }}
              ></Box>{" "}
              {e.label}
            </Box>
            <Typography
              variant="medium"
              sx={{
                flex: "10%",
                pl: 1,
                color: (theme) => theme.palette.text.main,
              }}
            >
              {e.count ? e.count : "0"}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
