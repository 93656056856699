import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  dFlex,
  flexSpaceBetween,
  itemsCenter,
} from "../../../../common/commonStyles";
import { SvgVector } from "../../../Builder/icons";

export default function ModuleSeqCard({ value, index }) {
  // const [options, setOptions] = useState([
  //   { label: "Lorem ipsum ist" },
  //   { label: "Lorem ipsum ist" },
  //   { label: "Lorem ipsum ist" },
  //   { label: "Lorem ipsum ist" },
  // ]);
  const tempArr = [];
  value.data.options.map((_, idx) => {
    // if(_.position)
    tempArr.splice(_.position - 1, 0, { label: _.text });
    // tempArr.push({
    //   label: _.text,
    //   position: _.position,
    // });
  });
  const [options, setOptions] = useState(tempArr);
  return (
    <Box
      sx={{
        my: 3,
        px: 3,
        borderRadius: "15px",
      }}
    >
      <Box sx={{ mb: 1, ...flexSpaceBetween, gap: 8 }}>
        <Typography
          variant="medium"
          sx={{
            flex: "80%",
            color: (theme) => theme.palette.text.main,
          }}
        >
          {index + 1}. Lorem ipsum dolor sit amet consectetur adipisicing elit.
          In blanditiis fuga dolores voluptatum ullam provident.
        </Typography>
      </Box>
      <Box sx={{ ...dFlex }}>
        <Box
          sx={{
            ...dFlex,
            flexDirection: "column",
            mt: 2,
            flex: "80%",
            p: "9px 20px",
          }}
        >
          {options?.map((e, i) => (
            <Box
              sx={{
                ...itemsCenter,
                gap: 2,
                my: 1,
                width: "80%",
                background: (theme) => theme.palette.primary.main,
              }}
            >
              <Box
                sx={{
                  flex: "80%",
                  color: "#FFF",
                  ...itemsCenter,
                  // boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.12)",
                }}
              >
                <SvgVector />
                {e.label}
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            flex: "10%",
            alignSelf: "flex-end",
          }}
        >
          <Typography
            variant="medium"
            sx={{
              pl: 1,
              color: (theme) => theme.palette.text.main,
            }}
          >
            {value.studentAttempted ? value.studentAttempted : "0"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
