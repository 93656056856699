import { Box, Typography } from "@mui/material";
import React from "react";
import {
  dFlex,
  flexSpaceBetween,
  itemsCenter,
} from "../../../../common/commonStyles";
import { SvgVector } from "../../../../images/icons";

export default function ModuleSeqCard({ data, index }) {
  return (
    <Box
      sx={{
        my: 3,
        px: 3,
        borderRadius: "15px",
      }}
    >
      <Box sx={{ mb: 1, ...flexSpaceBetween, gap: 8 }}>
        <Typography
          variant="medium"
          sx={{
            flex: "80%",
            color: (theme) => theme.palette.text.main,
          }}
        >
          {index + 1}. {data?.question}
        </Typography>
      </Box>
      <Box sx={{ ...dFlex }}>
        <Box
          sx={{
            ...dFlex,
            flexDirection: "column",
            mt: 2,
            flex: "80%",
            p: "9px 20px",
          }}
        >
          {data?.stdAnswer?.map((e, i) => (
            <Box
              sx={{
                ...itemsCenter,
                gap: 2,
                my: 1,
                px: 1,
                py: 0.5,
                width: "80%",
                background: (theme) => theme.palette.primary.main,
              }}
            >
              <Box
                sx={{
                  flex: "80%",
                  color: "#FFF",
                  ...itemsCenter,
                  gap: 1,
                  // boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.12)",
                }}
              >
                <SvgVector />
                {e.text}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
