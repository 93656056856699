import { Box, Button, IconButton, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { PATCH, POST } from "../../../Apis/constVariables";
import { itemsCenter } from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { AddIconBig, RemoveIconBig } from "../../../images/icons";
import { validateBasicQuestionForm } from "../CreateQuestion";
import { scrambleArr } from "./Matchthepair";

const initData = [
  { text: "", position: 1 },
  { text: "", position: 2 },
  { text: "", position: 3 },
  { text: "", position: 4 },
];

const Choosethecorrectsequence = ({
  editAble,
  questionFormData,
  fetchQuizQuestionsAgain,
  toggleQuestionForm,
}) => {
  const [rearrangeOptions, setRearrangeOptions] = useState(initData);
  const [loading, setLoading] = useState(false);
  const [arrScrambled, setArrScrambled] = useState(false);
  const handleAdd = () => {
    setRearrangeOptions((prevOptions) => [
      ...prevOptions,
      { text: "", position: prevOptions.length + 1 },
    ]);
  };

  const handleRemove = (id) => {
    setRearrangeOptions((prevOptions) =>
      prevOptions.filter((option, index) => index !== id)
    );
  };

  const handleValueChange = (e, order) => {
    setRearrangeOptions((prevOptions) =>
      prevOptions.map((option, index) =>
        index === order ? { ...option, text: e.target.value } : option
      )
    );
  };

  const handleScramble = () => {
    setArrScrambled(true);
    setRearrangeOptions((prevOptions) => scrambleArr(prevOptions));
  };

  const addData = async () => {
    try {
      const formValidator = validateBasicQuestionForm(questionFormData);
      if (formValidator?.error) {
        toast.error(formValidator.message);
        return;
      }
      let nsn = questionFormData.questionName.replaceAll(" ", "");

      let dat = /\//i.test(nsn);
      if (!dat) {
      } else {
        toast.error("You can not use any (/) characters");
        return;
      }
      let err = false;
      rearrangeOptions?.forEach((e) => {
        if (!e?.text?.trim()) {
          err = true;
        }
      });
      if (err) {
        toast.error("Fields cannot be empty");
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_QUESTION;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...questionFormData,
          data: { options: rearrangeOptions },
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchQuizQuestionsAgain();
      toggleQuestionForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };
  const updateData = async () => {
    try {
      const formValidator = validateBasicQuestionForm(questionFormData);
      if (formValidator?.error) {
        toast.error(formValidator.message);
        return;
      }
      let nsn = questionFormData.questionName.replaceAll(" ", "");
      let dat = /\//i.test(nsn);
      if (!dat) {
      } else {
        toast.error("You can not use any (/) characters");
        return;
      }
      let err = false;
      rearrangeOptions?.forEach((e) => {
        if (!e?.text?.trim()) {
          err = true;
        }
      });
      if (err) {
        toast.error("Fields cannot be empty");
        return;
      }
      setLoading(true);
      const url =
        process.env.REACT_APP_QUESTION + `/${questionFormData?.questionId}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: {
          ...questionFormData,
          data: { options: rearrangeOptions },
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchQuizQuestionsAgain();
      toggleQuestionForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (!questionFormData?.data) {
      return;
    }
    let sort = questionFormData?.data?.options?.sort(
      (a, b) => a.position - b.position
    );
    setRearrangeOptions(sort ?? initData);

    return () => {
      setRearrangeOptions(initData);
    };
  }, [questionFormData?.data]);

  return (
    <section className="quizForm-container">
      <Box>
        {rearrangeOptions?.map((option, index) => (
          <Box key={index} sx={{ ...itemsCenter, mb: 2, ml: 2 }}>
            <Typography className="quizForm-mainForm-label">
              Sentence {index + 1}:
            </Typography>
            <FormTextField
              disabled={arrScrambled}
              sx={{ width: "33%" }}
              placeholder="Type sentence"
              value={option.text}
              onChange={(e) => handleValueChange(e, index)}
            />
            {!arrScrambled &&
              index > rearrangeOptions?.length - 2 &&
              index < 6 && (
                <IconButton onClick={handleAdd}>
                  <AddIconBig />
                </IconButton>
              )}
            {!arrScrambled && index > 2 && (
              <IconButton onClick={() => handleRemove(index)}>
                <RemoveIconBig />
              </IconButton>
            )}
            <br />
          </Box>
        ))}
      </Box>
      <Button
        variant="primary-small"
        style={{
          marginLeft: "1em",
        }}
        onClick={handleScramble}
      >
        Shuffle
      </Button>
      <Box sx={{ ...itemsCenter, justifyContent: "flex-end", my: 2 }}>
        <Button
          sx={{
            textTransform: "none",
          }}
          onClick={toggleQuestionForm}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          variant="primary-small"
          onClick={editAble ? updateData : addData}
        >
          Save
        </Button>
      </Box>
    </section>
  );
};

export default Choosethecorrectsequence;
