import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import FacilitatorHeader from "../../Components/Facilitator/FacilitatorHeader";
import FacilitatorSideBar from "../../Components/Facilitator/FacilitatorSideBar";

export default function FacilitatorSkeleton() {
  return (
    // Main container
    <Box sx={{ display: "flex" }}>
      {/* Sidebar */}
      <Box id="sidebar">
        <FacilitatorSideBar />
      </Box>

      {/* Main content */}
      <Box sx={{ flex: 1 }}>
        {/*  Header */}
        <FacilitatorHeader />

        {/* Content container */}
        <Box
          sx={{
            px: 5,
            py: 3,
            backgroundColor: (theme) => theme.palette.background.main,
            boxSizing: "border-box",
            overflow: "auto",
            height: "90vh",
          }}
        >
          {/* Render nested routes */}
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
