import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { flexSpaceBetween } from "../../../../common/commonStyles";

export default function ModuleSCQCard({ data, index }) {
  const [options, setOptions] = useState([
    { label: "Not True", count: "1000000" },
    { label: "Somewhat True", count: "526365" },
    { label: "Certainly True", count: "798741" },
  ]);
  console.log({ data });
  return (
    <Box
      sx={{
        my: 3,
        px: 3,
        borderRadius: "15px",
      }}
    >
      <Box sx={{ mb: 1, ...flexSpaceBetween, gap: 8 }}>
        <Typography
          variant="medium"
          sx={{
            flex: "80%",
            color: (theme) => theme.palette.text.main,
          }}
        >
          {index + 1}. {data?.question}
        </Typography>
      </Box>
      <Box id="radio-group-box" sx={{ flexDirection: "column", px: 3, mt: 1 }}>
        <RadioGroup value={data?.stdAnswer}>
          {data?.data?.options?.map((e, i) => (
            <FormControlLabel
              key={i}
              sx={{
                my: 1,
                backgroundColor: (theme) => theme.palette.background.secondary,
                borderRadius: "6px",
              }}
              // disabled
              control={<Radio />}
              value={i}
              label={
                <Typography
                  variant="medium"
                  sx={{ color: (theme) => theme.palette.text.main }}
                >
                  {e}
                </Typography>
              }
            />
          ))}
        </RadioGroup>
      </Box>
      {/* <Box sx={{ ...dFlex, flexDirection: "column", mt: 2 }}>
        {data?.data?.options?.map((e, i) => (
          <Box
            sx={{
              ...itemsCenter,
              gap: 2,
              my: 1,
              borderRadius: "6px",
              backgroundColor: (theme) => theme.palette.background.secondary,
            }}
          >
            <Box
              sx={{
                p: "9px 20px",
                flex: "80%",
                color: (theme) => theme.palette.text.main,
                ...dFlex,
                // boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.12)",
              }}
            >
              <Box
                sx={{
                  mr: 2,
                  height: "22px",
                  width: "22px",
                  borderRadius: "100%",
                  border: (theme) => `2px solid ${theme.palette.primary.main}`,
                }}
              ></Box>{" "}
              {e}
            </Box>
          </Box>
        ))}
      </Box> */}
    </Box>
  );
}
