import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import {
  dFlex,
  flexBetweenCenter,
  itemsCenter,
} from "../../../common/commonStyles";
import { getFormattedDateForChat } from "../../../const/constFunctions";
import SuspendStudentModal from "./SuspendStudentModal";

export default function ChannelStudentCard({
  chatDetails,
  data,
  joinChannel,
  showSuspend,
}) {
  return (
    <Box
      sx={{
        ...itemsCenter,
        pt: 2,
        mt: 2,
        gap: 2,
        px: 1.5,
        cursor: "pointer",
      }}
      onClick={async () => {
        if (showSuspend) {
          return;
        }
        await joinChannel(data?.uniqueName, "p2p");
        // setChatDetails({
        //   type: "student",
        //   name: `Student Name ${index + 1}`,
        //   img:
        //     "https://cdn.pixabay.com/photo/2023/05/18/18/54/ibex-8003063_1280.jpg",
        // });
      }}
    >
      <Avatar
        src={data?.attributes?.image}
        sx={{
          width: "60px",
          height: "60px",
          backgroundColor: (theme) => theme.palette.primary.main,
          textTransform: "capitalize",
        }}
      >
        {data?.friendlyName?.[0]}
      </Avatar>
      <Box sx={{ ...dFlex, flexDirection: "column", width: "100%" }}>
        <Box sx={{ ...flexBetweenCenter, justifyContent: "space-between" }}>
          <Typography
            variant="semibold"
            sx={{
              color: (theme) =>
                chatDetails?.friendlyName == data?.friendlyName
                  ? theme.palette.primary.main
                  : theme.palette.text.dark,
            }}
          >
            {data?.friendlyName}
          </Typography>
          {!showSuspend && (
            <Typography
              variant="medium2"
              sx={{
                color: (theme) => theme.palette.text.light,
              }}
            >
              {data?.lastMessage?.date
                ? getFormattedDateForChat(data?.lastMessage?.date)
                : ""}{" "}
            </Typography>
          )}
          {showSuspend && (
            <Typography
              variant="medium2"
              sx={{
                color: (theme) => theme.palette.text.light,
              }}
            >
              ID 1234
            </Typography>
          )}
        </Box>
        {!showSuspend && (
          <Typography
            sx={{
              color: (theme) => theme.palette.text.main,
              fontSize: "14px",
            }}
          >
            {data?.lastMessage?.body}
          </Typography>
        )}
      </Box>
      {showSuspend && <SuspendStudentModal />}
    </Box>
  );
}
