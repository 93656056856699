import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Avatar, Box, IconButton, Popover, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../../../common/ProgressBar";
import {
  dFlex,
  flexBetweenCenter,
  itemsCenter,
  textEllipses,
} from "../../../common/commonStyles";
import { formatNumberWithOrdinal } from "../../../const/constFunctions";
import ArchiveStudentModal from "./ArchiveStudentModal";

export default function ArchivedStudentCard({ data, fetchAgain }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        my: 2,
        ...flexBetweenCenter,
        px: 5,
        borderRadius: "8px",
        height: "90px",
        backgroundColor: (theme) => theme.palette.background.secondary,
      }}
    >
      <Box
        sx={{ ...itemsCenter, gap: 2 }}
        // onClick={() => {
        //   navigate(`${1}`, {
        //     state: {
        //       studentInfo: {
        //         name: "Student Name1",
        //         calories: "56",
        //         fat: "26",
        //         carbs: getFormattedDate(),
        //         protein: 5,
        //         proteins: 7,
        //       },
        //     },
        //   });
        // }}
      >
        <Avatar
          src={data?.image}
          sx={{
            height: "62px",
            width: "62px",
          }}
        />
        <Box
          sx={{
            ...dFlex,
            gap: 1,
            flexDirection: "column",
            width: "200px",
            ...textEllipses,
          }}
        >
          <Typography
            variant="medium"
            sx={{ color: (theme) => theme.palette.text.dark }}
          >
            {data?.studentName}
          </Typography>
          <Typography
            variant="medium2"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            {data?.totalTokens ?? 0} Tokens Earned.
          </Typography>
        </Box>
      </Box>
      <Box sx={{ ...itemsCenter, flexDirection: "column" }}>
        <Typography
          variant="bold"
          sx={{ color: (theme) => theme.palette.text.dark }}
        >
          {formatNumberWithOrdinal(data?.batchRank ?? 0)}
        </Typography>
        <Typography sx={{ color: (theme) => theme.palette.text.dark }}>
          Rank
        </Typography>
      </Box>
      <Box sx={{ ...dFlex, flexDirection: "column", width: "215px", gap: 1 }}>
        <Typography sx={{ color: (theme) => theme.palette.text.dark }}>
          {data?.moduleData?.moduleName}
        </Typography>
        <ProgressBar progress={data?.percentage ?? 0} fontSize={12} />
      </Box>
      {true && (
        <>
          <IconButton sx={{ p: 0 }} onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Popover
            open={anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              top: 0,
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box
              sx={{
                p: 2,
                ...dFlex,
                flexDirection: "column",
                gap: 1,
                borderRadius: "8px",
              }}
            >
              <ArchiveStudentModal
                data={data}
                fetchAgain={fetchAgain}
                unArchive={true}
              />
            </Box>
          </Popover>
        </>
      )}
    </Box>
  );
}
