import { Box, Typography } from "@mui/material";
import React from "react";
import { dFlex, itemsCenter } from "../../../../common/commonStyles";
import { GameConsoleIcon } from "../../../../images/icons";

export default function GameElement({ data }) {
  return (
    <Box
      sx={{
        my: 1,
        // height: "62px",
        gap: 1,
        p: "0 8px",
        // backgroundColor: "#47BBC3",
        borderRadius: "8px",
        ...itemsCenter,
      }}
    >
      <Box
        sx={{
          borderRadius: "50%",
          height: "30px",
          width: "48px",
          backgroundColor: "#FFF",
          ...itemsCenter,
          justifyContent: "center",
        }}
      >
        <GameConsoleIcon />
      </Box>
      <Box sx={{ ...dFlex, flexDirection: "column" }}>
        <Typography
          // sx={{ color: (theme) => theme.palette.background.secondary }}
          variant="bold2"
        >
          {data?.fileTitle}
        </Typography>
        {/* <Typography
          // sx={{ color: (theme) => theme.palette.background.secondary }}
          variant="semibold2"
        >
          {new Date().toUTCString()}
        </Typography> */}
      </Box>
    </Box>
  );
}
