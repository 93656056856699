import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import DownloadSelect from "../../../../common/DownloadSelect";
import { dFlex } from "../../../../common/commonStyles";
import {
  StyledTableCell,
  StyledTableRow,
  TableRowSkeletonComponent,
} from "../../../../const/Table";
import {
  ArrowDropDownIconSvg,
  ArrowDropUpIconSvg,
} from "../../../../images/icons";

export default function SDQScore({ apiData, orgData, type }) {
  const [searchData, setSearchData] = useState([]);
  const [selectedSortUp, setSelectedSortUp] = useState("");
  const [selectedSortDown, setSelectedSortDown] = useState("");

  const sortArr = (para) => {
    setSelectedSortUp(para);
    setSelectedSortDown("");

    // Assuming 'searchData' is your array of objects
    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "score":
          fir = parseInt(a?.count ?? 0);
          sec = parseInt(b?.count ?? 0);
          break;
        case "count":
          fir = parseInt(a?.count ?? 0);
          sec = parseInt(b?.count ?? 0);
          break;
        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir < sec) {
        return -1;
      }
      if (fir > sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  const sortArrDec = (para) => {
    setSelectedSortUp("");
    setSelectedSortDown(para);

    const sortedData = [...searchData];

    sortedData.sort((a, b) => {
      let fir, sec;

      switch (para) {
        case "score":
          fir = parseInt(a?.count ?? 0);
          sec = parseInt(b?.count ?? 0);
          break;
        case "count":
          fir = parseInt(a?.count ?? 0);
          sec = parseInt(b?.count ?? 0);
          break;
        default:
          fir = a[para];
          sec = b[para];
      }

      if (fir > sec) {
        return -1;
      }
      if (fir < sec) {
        return 1;
      }
      return 0;
    });

    setSearchData(sortedData);
  };

  const downloadCsv = () => {
    let data = searchData;
    data = data.map((row) => {
      let formattedData = {
        Range: `Range ${row.rangeStart} - ${row.rangeEnd}`,
        "Student Count": row.count,
      };
      return formattedData;
    });

    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `SDQ_${type == "pre" ? "Pre" : "Post"}_Score_${
      orgData?.name
    }_${new Date().toString()}.csv`;
    link.click();
  };

  useEffect(() => {
    if (!apiData?.res) {
      return;
    }

    function generateScoreRanges(data) {
      let scoreRanges = Array.from({ length: 8 }, (_, index) => ({
        rangeStart: index === 0 ? 0 : index * 5 + 1,
        rangeEnd: (index + 1) * 5,
        count: 0,
      }));

      let naCount = 0;

      data.forEach((item) => {
        let score = type == "pre" ? item.sdqPre : item.sdqPost;
        if (score === "NA") {
          naCount++;
        } else {
          for (let i = 0; i < 8; i++) {
            if (
              score >= scoreRanges[i].rangeStart &&
              score <= scoreRanges[i].rangeEnd
            ) {
              scoreRanges[i].count++;
              break;
            }
          }
        }
      });

      scoreRanges.push({ rangeStart: "NA", rangeEnd: "NA", count: naCount });

      return scoreRanges;
    }
    let finalData = generateScoreRanges(apiData?.res?.data);
    console.log({ finalData });
    setSearchData(finalData);
  }, [apiData]);

  return (
    <Box>
      <Box sx={{ ...dFlex, justifyContent: "flex-end" }}>
        <DownloadSelect downloadCsv={downloadCsv} />
      </Box>

      <Box
      // sx={{ pr: 3 }}
      >
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "13px",
            my: 3,
            pt: 1,
            px: "20px",
            boxSizing: "border-box",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={{ minWidth: "200px" }}>
                  <Typography
                    variant="medium"
                    sx={{ color: (theme) => theme.palette.text.dark }}
                  >
                    Range{" "}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ ...dFlex, gap: 1 }}>
                    <Typography
                      variant="medium"
                      sx={{ color: (theme) => theme.palette.text.dark }}
                    >
                      Student Count
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2px",
                      }}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArr("count")}
                      >
                        <ArrowDropUpIconSvg
                          focus={selectedSortUp === "count"}
                        />
                      </Box>
                      <Box
                        sx={{
                          cursor: "pointer",
                          height: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "2px",
                        }}
                        onClick={() => sortArrDec("count")}
                      >
                        <ArrowDropDownIconSvg
                          focus={selectedSortDown === "count"}
                        />
                      </Box>
                    </Box>
                  </Box>
                </StyledTableCell>
              </TableRow>
            </TableHead>

            {apiData?.loading ? (
              <TableRowSkeletonComponent column={2} rows={6} />
            ) : (
              <TableBody>
                {searchData.map((row) => (
                  <StyledTableRow
                    style={{
                      cursor: "default",
                    }}
                  >
                    <StyledTableCell sx={{ maxWidth: "200px" }}>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        Range {row.rangeStart} - {row.rangeEnd}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        variant="regular"
                        sx={{ color: (theme) => theme.palette.text.main }}
                      >
                        {row.count}
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
