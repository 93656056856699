import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { DELETE } from "../../../Apis/constVariables";
import { DeleteIcon } from "../../../images/icons";

export default function BrawtaDeleteModal({ data, fetchBrawtaAgain }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = async () => {
    try {
      if (!data?.brawtaId) {
        toast.error("Something went wrong");
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_BRAWTA + `/${data?.brawtaId}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: DELETE,
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      setLoading(false);
      fetchBrawtaAgain();
      handleClose();
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
  };
  return (
    <>
      <IconButton sx={{ py: 0 }} onClick={handleOpen}>
        <DeleteIcon fill={"#358b8a"} />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <Box sx={{ width: "470px", padding: "12px 5px" }}>
          {/* <form onSubmit={handleDeleteAdmission} autoComplete="off"> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography
                variant="regular"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Are you sure you want to delete this brawta challenge?
              </Typography>
              <br />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={loading}
              onClick={handleDelete}
              variant="primary-medium"
            >
              Delete
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
