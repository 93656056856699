import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { flexCenter, itemsCenter } from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { formatTime, normalize } from "../../../const/constFunctions";
import sendNotification from "../../../functions/PushNotification";
export default function BrawtaChallengeDetails() {
  const [studentData, setStudentData] = useState({ studentName: "" });
  const [tokens, setTokens] = useState(null);
  const [loading, setLoading] = useState(false);
  const [brawtaData, setBrawtaData] = useState({ brawtaName: "" });
  const [url, setUrl] = useState(null);
  const params = useParams();
  const [apiData, fetchAgain] = useDataFetchHook(url);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let { brawtaData, studentData } = location?.state ?? {};
    if (!studentData || !brawtaData) {
      navigate("/" + location?.pathname?.split("/")[1] + "/dashboard");
    }

    let url =
      process.env.REACT_APP_GET_STUDENT_BRAWTA_ATTEMPT +
      `/questions?stdId=${params.id}&brawtaId=${params?.brawtaId}&moduleId=${brawtaData?.moduleId}`;

    setUrl(url);

    setStudentData(studentData);
    setBrawtaData(brawtaData);
  }, [location?.state]);
  useEffect(() => {
    if (!apiData?.res) {
      return;
    }
    setTokens(apiData.res?.tokenAllocated);
  }, [apiData?.res]);

  const handleAllocate = async (status) => {
    try {
      console.log({ tokens });
      if (status == "approved" && !tokens) {
        toast.error("Tokens are required");
        return;
      } else if (tokens > apiData?.res?.maxTokens) {
        toast.error("Max tokens exceeded  " + apiData?.res?.maxTokens);
        return;
      }

      if (status == "rejected") {
        sendNotification({
          userId: params.id,
          deviceToken: studentData?.fcmToken,
          title: "Retry Brawta",
          type: "module",
          body:
            "Your brawta challenge answers was not accepted, Please try again",
        });
      } else {
        sendNotification({
          userId: params.id,
          deviceToken: studentData?.fcmToken,
          title: "Brawta Challenge accepted",
          type: "module",
          body: `You got ${tokens} tokens for completing your brawta challenge`,
        });
      }

      setLoading(true);
      const url = process.env.REACT_APP_GET_STUDENT_BRAWTA_ATTEMPT;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          tokens,
          brawtaId: params?.brawtaId,
          status,
          stdId: params.id,
        },
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      setLoading(false);
      if (status == "rejected") {
        navigate(-1);
      }
      fetchAgain();
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <Box>
      <NavBreadCrumbs
        pageTags={[
          "Brawta challenge",
          studentData?.studentName,
          brawtaData?.brawtaName,
        ]}
      />

      <Box
        sx={{
          borderRadius: "16px",
          // minHeight: "10vh",
          backgroundColor: (theme) => theme.palette.primary.lighter,
          p: 3,
        }}
      >
        {apiData?.loading && (
          <Box sx={flexCenter}>
            <CircularProgress />
          </Box>
        )}
        {apiData?.res?.data?.map((e, i) => {
          return (
            <>
              <Typography
                sx={{ color: (theme) => theme.palette.text.main, mb: 1 }}
              >
                {i + 1}. {e?.question}
              </Typography>

              {e?.complete ? (
                (e?.type == "audio" && <AudioPlayerBox data={e} key={i} />) ||
                (e?.type == "video" && <VideoPlayerBox data={e} key={i} />) ||
                (e?.type == "text" && <TextBox data={e} key={i} />)
              ) : (
                <Typography
                  sx={{ color: (theme) => theme.palette.text.main, mb: 1 }}
                >
                  Question not yet answered
                </Typography>
              )}
            </>
          );
        })}
      </Box>

      <Box sx={{ ...itemsCenter, gap: 2, mt: 2, mb: 1 }}>
        <Typography
          variant="semibold"
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          Tokens :
        </Typography>
        <FormTextField
          type="number"
          value={tokens}
          onChange={(e) => setTokens(e.target.value)}
          placeholder={`Max ${apiData?.res?.maxTokens ?? 0}`}
        />
        {!apiData?.res?.tokenAllocated && (
          <Box>
            <Button
              disabled={loading}
              variant="primary-small"
              onClick={() => handleAllocate("approved")}
            >
              Allocate
            </Button>
            <Button
              disabled={loading}
              sx={{ textTransform: "none", color: "red" }}
              onClick={() => handleAllocate("rejected")}
            >
              Reject
            </Button>
          </Box>
        )}
      </Box>
      <Typography
        variant="semibold"
        sx={{ color: (theme) => theme.palette.text.main, ml: 10 }}
      >
        Max Tokens:{" "}
        <Typography
          variant="semibold"
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          {apiData?.res?.maxTokens ?? 0}
        </Typography>
      </Typography>
    </Box>
  );
}

function VideoPlayerBox({ data }) {
  return (
    <Box
      sx={{
        ...flexCenter,
        height: "55vh",
        mb: 4,
        // width: "100%",
        borderRadius: "15px",
        background: (theme) => theme.palette.background.secondary,
      }}
    >
      <video
        style={{
          height: "90%",
          //   width: "90%",
          borderRadius: "10px",
        }}
        preload="metadata"
        controlsList="nodownload"
        controls
        // src={
        //   "https://player.vimeo.com/progressive_redirect/playback/795132261/rendition/540p/file.mp4?loc=external&signature=d75e3df4027950bd5576da161c3b2585297fb19f3168b2b012b2bd81c1cbef80"
        // }
        src={data?.url}
      />
    </Box>
  );
}

function TextBox({ data }) {
  return (
    <Box
      sx={{
        height: "25vh",
        // width: "100%",
        mb: 4,
        borderRadius: "15px",
        background: (theme) => theme.palette.background.secondary,
        overflow: "auto",
        py: 1,
        px: 4,
        boxSizing: "border-box",
        resize: "vertical",
      }}
    >
      {data?.answer}{" "}
    </Box>
  );
}

function AudioPlayerBox({ data }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const playerRef = useRef(null);

  const handlePlayPause = () => {
    const player = playerRef.current;

    if (isPlaying) {
      player.pause();
    } else {
      player.play();
    }

    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const player = playerRef.current;
    setCurrentTime(player.currentTime);
  };

  return (
    <Box
      sx={{
        // ...flexCenter,
        py: 3,
        px: 3,
        mb: 4,
        // height: "25vh",
        // width: "100%",
        borderRadius: "15px",
        background: (theme) => theme.palette.background.secondary,
      }}
    >
      <audio
        ref={playerRef}
        preload="metadata"
        // src={
        //   "https://player.vimeo.com/progressive_redirect/playback/795132261/rendition/540p/file.mp4?loc=external&signature=d75e3df4027950bd5576da161c3b2585297fb19f3168b2b012b2bd81c1cbef80"
        // }
        src={data?.url}
        onEnded={handlePlayPause}
        onTimeUpdate={handleTimeUpdate}
      />

      <Box sx={{}}>
        <LinearProgress
          variant="determinate"
          value={
            currentTime > 0 &&
            normalize(currentTime, playerRef.current?.duration)
          }
          sx={{
            "& .MuiLinearProgress-bar": {
              borderRadius: "2px",
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "6px",
            color: (theme) => theme.palette.text.secondary,
            fontSize: "12px",
          }}
        >
          <Typography variant="caption">{formatTime(currentTime)}</Typography>
          <Typography variant="caption">
            {formatTime(playerRef.current?.duration)}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          ...flexCenter,
          gap: 3,
        }}
      >
        <IconButton
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            borderRadius: "50%",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.primary.main,
            },
          }}
          onClick={handlePlayPause}
        >
          {isPlaying ? (
            <PauseIcon fontSize="large" sx={{ color: "white", p: 0 }} />
          ) : (
            <PlayArrowIcon fontSize="large" sx={{ color: "white", p: 0 }} />
          )}
        </IconButton>
      </Box>
    </Box>
  );
}
