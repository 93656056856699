import { Box } from "@mui/material";
import React from "react";
import AnalyticsGraph from "../../../Components/Facilitator/Dashboard/AnalyticsGraph";
import DashboardOverViewCard from "../../../Components/Facilitator/Dashboard/DashboardOverViewCard";
import LeaderBoard from "../../../Components/Facilitator/Dashboard/LeaderBoard";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";

export default function FacilitatorDashboard() {
  return (
    <Box>
      <NavBreadCrumbs pageTags={["Dashboard"]} />
      <DashboardOverViewCard />
      <AnalyticsGraph />
      <LeaderBoard />
    </Box>
  );
}
