import { Avatar, Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import { dFlex, flexCenter, itemsCenter } from "../../../common/commonStyles";
import { formatNumberWithOrdinal } from "../../../const/constFunctions";
import { leaderBoardEmpty } from "../../../const/imageUrl";
import { RightArrow } from "../../../images/icons";

export default function LeaderBoard() {
  const navigate = useNavigate();

  const url =
    process.env.REACT_APP_FACILITATOR_LEADERBOARD + `?limit=10&type=asc`;
  const [
    leaderBoardApi,
    fetchLeaderBoardAgain,
    dispatchLeaderBoard,
  ] = useDataFetchHook(url);
  const url2 =
    process.env.REACT_APP_FACILITATOR_LEADERBOARD + `?limit=10&type=desc`;
  const [leaderBoardApi2] = useDataFetchHook(url2);

  return (
    <Box sx={{ mt: 5 }}>
      <Typography
        variant="bold"
        sx={{ color: (theme) => theme.palette.primary.main }}
      >
        Leaderboard
      </Typography>
      {leaderBoardApi?.res?.data?.length < 1 && (
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.background.secondary,
            height: "376px",
            // p: 5,
            boxSizing: "border-box",
            borderRadius: "12px",
          }}
        >
          <Box
            sx={{
              mt: 2,
              backgroundColor: (theme) => theme.palette.background.secondary,
              ...flexCenter,
              flexDirection: "column",
              py: 6,
              gap: 3,
              borderRadius: "12px",
            }}
          >
            <img
              src={leaderBoardEmpty}
              style={{ height: "212px", width: "349px" }}
            />
            <Typography
              variant="bold"
              sx={{ color: (theme) => theme.palette.text.light }}
            >
              Leaderboard ranking will be shown here
            </Typography>
          </Box>
        </Box>
      )}
      {leaderBoardApi?.res?.data?.length > 1 && (
        <>
          <Box sx={{ ...dFlex, gap: 2 }}>
            <Box
              sx={{
                width: "70%",
                height: "376px",
                backgroundColor: (theme) => theme.palette.background.secondary,
                borderRadius: "12px",
                mt: 2,
                p: 3,
                boxSizing: "border-box",
                ...dFlex,
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              {leaderBoardApi?.res?.data?.length >= 3 && (
                <Box
                  sx={{
                    ...itemsCenter,
                    flexDirection: "column",
                    color: (theme) => theme.palette.primary.main,
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography variant="semibold">3rd Rank</Typography>
                  <Avatar
                    src={leaderBoardApi?.res?.data?.[2]?.image}
                    sx={{
                      width: "154px",
                      height: "154px",
                      my: 2,
                      boxShadow: "0px 4px 4px 0px #00000040",
                    }}
                  />
                  <Typography variant="semibold">
                    Token Earned:{leaderBoardApi?.res?.data?.[2]?.totalTokens}
                  </Typography>
                  <Typography variant="bold2">
                    {leaderBoardApi?.res?.data?.[2]?.studentName}
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  ...itemsCenter,
                  flexDirection: "column",
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                <Typography variant="semibold">1st Rank</Typography>
                <Avatar
                  src={leaderBoardApi?.res?.data?.[0]?.image}
                  sx={{
                    width: "154px",
                    height: "154px",
                    my: 2,
                    boxShadow: "0px 4px 4px 0px #00000040",
                  }}
                />
                <Typography variant="semibold">
                  Token Earned:
                  {leaderBoardApi?.res?.data?.[0]?.totalTokens ?? 0}
                </Typography>
                <Typography variant="bold2">
                  {leaderBoardApi?.res?.data?.[0]?.studentName}
                </Typography>
              </Box>
              {leaderBoardApi?.res?.data?.length >= 2 && (
                <Box
                  sx={{
                    ...itemsCenter,
                    flexDirection: "column",
                    color: (theme) => theme.palette.primary.main,
                    justifyContent: "flex-end",
                  }}
                >
                  <Typography variant="semibold">2nd Rank</Typography>
                  <Avatar
                    src={leaderBoardApi?.res?.data?.[1]?.image}
                    sx={{
                      width: "154px",
                      height: "154px",
                      mt: 3,
                      mb: 2,
                      boxShadow: "0px 4px 4px 0px #00000040",
                    }}
                  />
                  <Typography variant="semibold">
                    Tokens Earned: Tokens Earned:
                    {leaderBoardApi?.res?.data?.[1]?.totalTokens}
                  </Typography>
                  <Typography variant="bold2">
                    {leaderBoardApi?.res?.data?.[1]?.studentName}
                  </Typography>
                </Box>
              )}
            </Box>
            {leaderBoardApi?.res?.data?.slice(3)?.length > 0 && (
              <Box
                sx={{
                  width: "30%",
                  height: "376px",
                  backgroundColor: (theme) =>
                    theme.palette.background.secondary,
                  borderRadius: "12px",
                  mt: 2,
                  py: 3,
                  boxSizing: "border-box",
                  position: "relative",
                }}
              >
                <Box sx={{ maxHeight: "315px", overflow: "hidden" }}>
                  {leaderBoardApi?.res?.data?.slice(3)?.map((e, i) => (
                    <Box sx={{ ...itemsCenter, my: 2, px: 3 }}>
                      <Avatar
                        src={e?.image}
                        sx={{ width: "59px", height: "59px", mr: 1.5 }}
                      />
                      <Box sx={{ ...dFlex, flexDirection: "column" }}>
                        <Typography
                          variant="semibold"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {e?.studentName}{" "}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: (theme) => theme.palette.text.main,
                          }}
                        >
                          {e?.totalTokens} Tokens Earned{" "}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          ...itemsCenter,
                          ml: "auto",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="bold"
                          sx={{ color: (theme) => theme.palette.text.main }}
                        >
                          {formatNumberWithOrdinal(i + 4)}{" "}
                        </Typography>
                        <Typography
                          sx={{
                            color: (theme) => theme.palette.text.main,
                          }}
                        >
                          Rank
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
                {/* <Box sx={{ ...dFlex, justifyContent: "flex-end", px: 3 }}> */}
                <Box sx={{ position: "absolute", right: 20, bottom: 15 }}>
                  <Button
                    sx={{ textTransform: "none", p: 0 }}
                    onClick={() => navigate("leader-board")}
                  >
                    <Typography
                      variant="medium2"
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      See More <RightArrow />
                    </Typography>
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
          {/* <Box sx={{ mt: 5 }}>
            <Box>
              <Typography
                variant="bold"
                sx={{ color: (theme) => theme.palette.primary.main }}
              >
                Students with Good Progress
              </Typography>
              <BootstrapTooltip
                title={
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    Students who have scored 60% and above
                  </Typography>
                }
                arrow
                PopperProps={{
                  sx: {
                    backgroundColor: (theme) => theme.palette.background.main,
                  },
                }}
                container
                placement="right"
              >
                <IconButton sx={{ p: 0, ml: 0.5, top: -5 }}>
                  <InfoIcon />
                </IconButton>
              </BootstrapTooltip>
            </Box>
            <Box
              sx={{
                ...dFlex,
                flexWrap: "wrap",
                overflow: "hidden",
                height: "220px",
              }}
            >
              {new Array(15).fill(1).map((e) => (
                <Box
                  sx={{
                    ...itemsCenter,
                    mr: 4.5,
                    flexDirection: "column",
                    color: (theme) => theme.palette.text.main,
                    justifyContent: "flex-end",
                  }}
                >
                  <Avatar
                    src="https://i.imgur.com/WQ9VnMw.jpeg"
                    sx={{
                      width: "135px",
                      height: "135px",
                      my: 2,
                      boxShadow: "0px 4px 4px 0px #00000040",
                    }}
                  />
                  <Typography>Student Name1</Typography>
                  <Typography>Token Earned:457</Typography>
                </Box>
              ))}
            </Box>
            <Box sx={{ ...dFlex, justifyContent: "flex-end", mt: 2 }}>
              <Button
                sx={{ textTransform: "none", p: 0 }}
                onClick={() => navigate("good-progress")}
              >
                <Typography
                  variant="medium2"
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  See More <RightArrow />
                </Typography>
              </Button>
            </Box>
          </Box> */}
          <Box sx={{ mt: 5 }}>
            <Box>
              <Typography
                variant="bold"
                sx={{ color: (theme) => theme.palette.primary.main }}
              >
                Students with Slow / No Progress
              </Typography>
              {/* <BootstrapTooltip
                title={
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    Students who have scored 59% and below
                  </Typography>
                }
                arrow
                PopperProps={{
                  sx: {
                    backgroundColor: (theme) => theme.palette.background.main,
                  },
                }}
                container
                placement="right"
              >
                <IconButton sx={{ p: 0, ml: 0.5, top: -5 }}>
                  <InfoIcon />
                </IconButton>
              </BootstrapTooltip> */}
            </Box>
            <Box
              sx={{
                ...dFlex,
                flexWrap: "wrap",
                overflow: "hidden",
                height: "245px",
              }}
            >
              {leaderBoardApi2?.res?.data?.map((e) => (
                <Box
                  sx={{
                    ...itemsCenter,
                    mr: 4.5,
                    flexDirection: "column",
                    color: (theme) => theme.palette.text.main,
                    justifyContent: "flex-end",
                  }}
                >
                  <Avatar
                    src={e?.image}
                    sx={{
                      width: "135px",
                      height: "135px",
                      my: 2,
                      boxShadow: "0px 4px 4px 0px #00000040",
                    }}
                  />
                  <Typography>{e?.studentName}</Typography>
                  <Typography>Token Earned:{e?.totalTokens}</Typography>
                  <Typography>
                    Rank: {formatNumberWithOrdinal(e?.rank)}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box sx={{ ...dFlex, justifyContent: "flex-end", mt: 2 }}>
              <Button
                sx={{ textTransform: "none", p: 0 }}
                onClick={() => navigate("no-progress")}
              >
                <Typography
                  variant="medium2"
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  See More <RightArrow />
                </Typography>
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
