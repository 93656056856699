import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import AdminHeader from "../../Components/Admin/AdminHeader";
import AdminSideBar from "../../Components/Admin/AdminSideBar";

export default function AdminSkeleton() {
  return (
    // Main container
    <Box sx={{ display: "flex" }}>
      {/* Sidebar */}
      <Box id="sidebar">
        <AdminSideBar />
      </Box>

      {/* Main content */}
      <Box sx={{ flex: 1 }}>
        {/* Admin Header */}
        <AdminHeader />

        {/* Content container */}
        <Box
          sx={{
            px: 5,
            backgroundColor: (theme) => theme.palette.background.main,
            py: 3,
            boxSizing: "border-box",
            overflow: "auto",
            height: "90vh",
          }}
        >
          {/* Render nested routes */}
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
