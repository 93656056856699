import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { RegisterIcon } from "../../../images/icons";
import { dFlex } from "../../../common/commonStyles";
import Select from "react-select";
import { reactSelectTheme } from "../../../const/constFunctions";
import { FormTextField } from "../../../const/TextField";

export default function CreateGroupModal({ studentData }) {
  const [options, setOptions] = useState([
    { label: "Facilitator 1", value: "1" },
    { label: "Facilitator 2", value: "2" },
    { label: "Facilitator 3", value: "3" },
    { label: "Facilitator 4", value: "4" },
    { label: "Facilitator 5", value: "5" },
    { label: "Facilitator 6", value: "6" },
  ]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = () => {
    toast("Group ‘Black’ has been created");
    handleClose();
  };

  return (
    <>
      <Button onClick={handleOpen} variant="primary" sx={{ mr: 3 }}>
        <RegisterIcon /> Save
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "13px",
            backgroundColor: (theme) => theme.palette.background.main,
          },
        }}
      >
        <Box sx={{ width: "515px", pb: "20px" }}>
          <DialogContent sx={{ mb: 2, height: "38vh" }}>
            <DialogContentText id="alert-dialog-description">
              <Box
                sx={{
                  ...dFlex,
                  flexDirection: "column",
                  mb: 2,
                }}
              >
                <Typography
                  variant="medium"
                  sx={{
                    color: (theme) => theme.palette.text.dark,
                  }}
                >
                  Enter Group Name
                </Typography>
                <Typography
                  variant="semibold2"
                  sx={{
                    color: (theme) => theme.palette.text.light,
                  }}
                >
                  {studentData?.length} Students selected
                </Typography>
              </Box>
              <Box>
                <FormTextField fullWidth placeholder="Enter Group Name" />
              </Box>
              <Box sx={{ width: "40%", mt: 4, zIndex: 68484 }}>
                <Select
                  theme={reactSelectTheme}
                  options={options}
                  maxMenuHeight={130}
                  isSearchable={false}
                  placeholder="Select Admin"
                />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleAdd} variant="primary">
              Add
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
