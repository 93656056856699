import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { dFlex } from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { AdminInfoIcon, SendIcon } from "../../../images/icons";

export default function SchoolAdminInfo() {
  const navigate = useNavigate();
  const [schoolData, setSchoolData] = useState({ name: "" });
  const [editable, setEditable] = useState(false);
  const location = useLocation();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [apiData, fetchAgain] = useDataFetchHook(
    process.env.REACT_APP_SA_SCHOOL_ADMIN_INFO + `?schoolId=${params.schoolId}`
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleProfileUpdate = async (data) => {
    try {
      console.log(data);
      setLoading(true);
      const url = process.env.REACT_APP_SA_SCHOOL_ADMIN_INFO;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...data,
          schoolId: params?.schoolId,
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      setLoading(false);
      setEditable(false);
      fetchAgain();
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const { data } = location?.state ?? { name: "" };
    setSchoolData(data);

    return () => {
      setSchoolData({ name: "" });
    };
  }, [location.state]);
  useEffect(() => {
    if (!apiData.res) {
      return;
    }
    apiData?.res?.insData && setSchoolData(apiData?.res?.insData);
    let insData = apiData?.res?.insData;
    let adminData = apiData?.res?.adminData;
    reset({
      name: insData?.name,
      email: adminData?.email,
      contactPersonNumber: adminData?.contactPersonNumber,
    });
  }, [apiData?.res]);

  return (
    <Box>
      <NavBreadCrumbs
        pageTags={[
          "Dashboard",
          "School Subscribers",
          schoolData?.name,
          "Account Details",
        ]}
      />

      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.secondary,
          p: 3,
          borderRadius: "10px",
          ...dFlex,
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box sx={{ alignSelf: "flex-end" }}>
          {editable ? (
            <Button
              variant="primary"
              disabled={loading}
              onClick={handleSubmit(handleProfileUpdate)}
            >
              <SendIcon />
              Send Request
            </Button>
          ) : (
            <Button variant="primary" onClick={() => setEditable(true)}>
              <AdminInfoIcon />
              Change Admin
            </Button>
          )}
        </Box>

        <Box sx={{ width: "35%" }}>
          <Typography
            variant="bold2"
            sx={{ color: (theme) => theme.palette.text.main }}
          >
            School / Institution Name
          </Typography>

          <FormTextField
            fullWidth
            disabled
            inputProps={{
              style: {
                textTransform: "capitalize",
              },
            }}
            {...register(
              "name"
              // {
              //   required: "Person Name is required.",
              // }
            )}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
          />
        </Box>
        <Box sx={{ width: "35%" }}>
          <Typography
            variant="bold2"
            sx={{ color: (theme) => theme.palette.text.main }}
          >
            Admin Email
          </Typography>

          <FormTextField
            fullWidth
            disabled={!editable}
            inputProps={{
              style: {
                textTransform: "lowercase",
              },
            }}
            {...register("email", {
              required: "Email is required.",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid Email Address",
              },
            })}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
          />
        </Box>
        <Box sx={{ width: "35%" }}>
          <Typography
            variant="bold2"
            sx={{ color: (theme) => theme.palette.text.main }}
          >
            Contact Person Number
          </Typography>

          <FormTextField
            fullWidth
            disabled
            {...register("contactPersonNumber")}
            error={Boolean(errors.contactPersonNumber)}
            helperText={errors.contactPersonNumber?.message}
          />
        </Box>
      </Box>
    </Box>
  );
}
