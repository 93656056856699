import { createContext, useRef } from "react";

export const LoadingBarContext = createContext();

export const LoadingBarContextProvider = ({ children }) => {
  const ref = useRef();
  return (
    <LoadingBarContext.Provider
      value={{
        ref,
      }}
    >
      {children}
    </LoadingBarContext.Provider>
  );
};
