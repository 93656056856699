import {
  Box,
  Button,
  Dialog,
  DialogActions,
  FormHelperText,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import { FormTextField } from "../../../const/TextField";

export default function DeleteAccountModal() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleDeleteReq = async () => {
    try {
      if (!reason) {
        toast.error("Please provide a reason");
        return;
      }
      const url = process.env.REACT_APP_DELETE_SCHOOL;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          reason,
        },
      });
      setLoading(true);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      handleClose();
    } catch (err) {
      toast.error(err?.response?.data?.message);
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <>
      <Box sx={{ mt: 2, marginLeft: "8px" }}>
        <Button sx={{ textTransform: "none" }} onClick={handleOpen}>
          I want to delete account
        </Button>
        {/* <Button variant="primary" onClick={handleOpen}>
          Delete Account
        </Button> */}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "#F3F5F5",
            borderRadius: "14px",
            p: 3,
            maxWidth: "100%",
            width: "30%",
          },
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.text.main,
          }}
        >
          Are you sure you want to delete account?
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Typography
            sx={{
              color: (theme) => theme.palette.content.quizQuestion,
              mb: 1,
              fontSize: "14px",
            }}
          >
            Your money wont be refunded as you have used the subscription plan
            more than a month.
          </Typography>
        </Box>
        <FormHelperText
          sx={{ my: 0, width: "100%", textAlign: "right" }}
          error={132 > 500}
        >
          <Typography
            variant="semibold2"
            sx={{
              color: "#BFBFBF",
            }}
          >
            {reason?.length} / 500
          </Typography>
        </FormHelperText>
        <FormTextField
          multiline
          value={reason}
          onChange={(e) => {
            if (e.target.value?.length > 500) {
              return;
            }
            setReason(e.target.value);
          }}
          rows={5}
          InputProps={{ sx: { borderRadius: "10px" } }}
          placeholder="Reason"
        />

        <DialogActions sx={{ pt: 2, px: 0 }}>
          <Button
            sx={{ height: "32px", textTransform: "none" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            variant="primary"
            sx={{ mr: 0 }}
            onClick={handleDeleteReq}
          >
            Delete Account
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
