import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import ProgressBar from "../../../common/ProgressBar";
import {
  dFlex,
  flexBetweenCenter,
  itemsCenter,
  textEllipses,
} from "../../../common/commonStyles";

import { useNavigate } from "react-router-dom";
import { formatNumberWithOrdinal } from "../../../const/constFunctions";

export default function StudentCard({ data }) {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        my: 2,
        ...flexBetweenCenter,
        px: 5,
        borderRadius: "8px",
        height: "90px",
        backgroundColor: (theme) => theme.palette.background.secondary,
      }}
    >
      <Box
        sx={{
          ...itemsCenter,
          gap: 2,
          cursor: "pointer",
          width: "250px",
          ...textEllipses,
        }}
        onClick={() => {
          navigate(`${data?.id}`, {
            state: {
              studentInfo: data,
            },
          });
        }}
      >
        <Avatar
          src={data?.image}
          sx={{
            height: "62px",
            width: "62px",
          }}
        />
        <Box sx={{ ...dFlex, gap: 1, flexDirection: "column" }}>
          <Typography
            variant="medium"
            sx={{ color: (theme) => theme.palette.text.dark }}
          >
            {data?.studentName}{" "}
          </Typography>
          <Typography
            variant="medium2"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            {data?.totalTokens ?? 0} Tokens Earned.
          </Typography>
        </Box>
      </Box>
      <Box sx={{ ...itemsCenter, flexDirection: "column" }}>
        <Typography
          variant="bold"
          sx={{ color: (theme) => theme.palette.text.dark }}
        >
          {formatNumberWithOrdinal(data?.batchRank ?? 0)}
        </Typography>
        <Typography sx={{ color: (theme) => theme.palette.text.dark }}>
          Rank
        </Typography>
      </Box>
      <Box sx={{ ...dFlex, flexDirection: "column", width: "215px", gap: 1 }}>
        <Typography sx={{ color: (theme) => theme.palette.text.dark }}>
          {data?.certificateIssued
            ? "All Modules Completed"
            : data?.moduleData?.moduleName ?? "Yet to start"}{" "}
        </Typography>
        <ProgressBar progress={data?.percentage ?? 0} fontSize={12} />{" "}
      </Box>
    </Box>
  );
}
