import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Icon,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import useDataFetchHook from "../../Hooks/DataFetchHook";
import { flexCenter, itemsCenter } from "../../common/commonStyles";
import { BrawtaIcon } from "../../images/icons";

export default function BrawtaElement({ data, unPublished }) {
  const [open, setOpen] = useState(false);
  const url = unPublished
    ? process.env.REACT_APP_BRAWTA_QUESTION + `?brawtaId=${data?.brawtaId}`
    : process.env.REACT_APP_ALL_PUBLISHED_BRAWTA_QUESTION + `${data?.brawtaId}`;

  const [brawtaQuestionApiCall] = useDataFetchHook(url);

  const handleClickOpen = () => {
    // console.log({ data });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box sx={{ ...itemsCenter, gap: 1, my: 1 }} onClick={handleClickOpen}>
        <Icon
          sx={{
            p: 0,
            height: "fit-content",
            width: "fit-content",
            fill: "#358b8a",
          }}
        >
          <BrawtaIcon />
        </Icon>
        <Typography
          sx={{
            color: (theme) => theme.palette.text.main,
            cursor: "pointer",
            transition: "all 0.2s ease",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.background.main,
            },
          }}
        >
          (Challenge) {data?.brawtaName}
        </Typography>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "#F3F5F5",
            borderRadius: "14px",
            p: "24px 12px 12px 24px",
            maxWidth: "100%",
            width: "50%",
          },
        }}
      >
        {/* <Typography variant="bold2" sx={{ pb: 2 }}>
          Questions
        </Typography> */}
        {brawtaQuestionApiCall?.loading && (
          <Box sx={{ ...flexCenter }}>
            <CircularProgress />
          </Box>
        )}
        {brawtaQuestionApiCall?.res?.questions?.map((e, i) => (
          <Typography variant="medium" key={i}>
            {i + 1}. {e?.questionName}
            <Typography
              key={i}
              sx={{ px: 1, py: 2, color: (theme) => theme.palette.text.main }}
            >
              {e?.question}
            </Typography>
          </Typography>
        ))}
        <DialogActions sx={{ pt: 1 }}>
          <Button
            sx={{ height: "32px", textTransform: "none" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
