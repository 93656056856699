import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import { dFlex, flexCenter } from "../../../common/commonStyles";

export default function StudentEarnedModuleBadge({ index, data }) {
  const url =
    process.env.REACT_APP_DASHBOARD_MODULE_BADGE_COUNT +
    `?moduleId=${data?.moduleId}`;

  const [moduleBadeCountApi] = useDataFetchHook(url);
  return (
    <Box
      sx={{
        width: "152px",
        minWidth: "152px",
        ...dFlex,
        flexDirection: "column",
        borderRadius: "10px",
        boxSizing: "border-box",
        p: "12px 16px",
        height: "128px",
        gap: 1,
        color: (theme) => theme.palette.primary.main,
        backgroundColor: (theme) => theme.palette.background.secondary,
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      {moduleBadeCountApi?.loading && (
        <Box sx={flexCenter}>
          <CircularProgress />
        </Box>
      )}

      <Typography variant="bold" sx={{ fontSize: "30px" }}>
        {moduleBadeCountApi?.res?.count}
      </Typography>
      <Typography variant="medium2">
        Students earned Module {index + 1} badge
      </Typography>
    </Box>
  );
}
