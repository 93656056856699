import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import React from "react";
import { itemsCenter } from "../../../../common/commonStyles";
import { AccordionTransition } from "../../../../const/constFunctions";
import { ExpandMoreSvg, SvgVector } from "../../../../images/icons";
import AddIntroSlide from "./AddIntroSlide";

export default function IntroMessageCard({
  provided,
  snapshot,
  data,
  index,
  fetchAgain,
}) {
  return (
    <div
      ref={provided.innerRef}
      snapshot={snapshot}
      // key={data.id}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <Accordion TransitionProps={AccordionTransition} sx={{ my: 2 }}>
        <AccordionSummary
          {...provided.dragHandleProps}
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            color: "#FFF",
            borderRadius: "5px",
          }}
          expandIcon={<ExpandMoreSvg />}
          id="panel1a-header"
        >
          <Box
            sx={{
              ...itemsCenter,
              gap: 2,
            }}
          >
            <SvgVector />

            <Typography variant="bold">Slide {index + 1}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails style={{ borderRadius: "2px" }}>
          <AddIntroSlide
            slideData={data}
            index={index}
            edit={true}
            fetchAgain={fetchAgain}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
