import {
  Box,
  Button,
  Dialog,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { AddIcon } from "../../../images/icons";
import { FormTextField } from "../../../const/TextField";
import { dFlex } from "../../../common/commonStyles";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { toast } from "react-toastify";
import { PATCH, POST } from "../../../Apis/constVariables";
import axios from "axios";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";

const initDetails = {
  question: "",
  answer: "",
};
export default function AddFAQModal({ edit, fetchAgain, data }) {
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState(initDetails);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => {
    setDetails(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };

  const handleSave = async () => {
    try {
      if (!details?.question) {
        toast.error("question is required & cannot be empty");
        return;
      }
      if (!details?.answer) {
        toast.error("question answer is required & cannot be empty");
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_FAQ;
      // const config = {
      //   method: POST,
      //   url,
      //   data: details,
      // };
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: details,
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      setDetails(initDetails);
      fetchAgain();
      handleClose();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };
  const handleUpdate = async () => {
    try {
      if (!details?.question) {
        toast.error("question is required & cannot be empty");
        return;
      }
      if (!details?.answer) {
        toast.error("question answer is required & cannot be empty");
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_FAQ + `/${data?.id}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: details,
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchAgain();
      handleClose();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <>
      {edit ? (
        <IconButton sx={{ p: 0 }} onClick={handleOpen}>
          <NavigateNextIcon />
        </IconButton>
      ) : (
        <Box sx={{ ...dFlex, justifyContent: "flex-end" }}>
          <Button variant="primary" onClick={handleOpen}>
            <AddIcon /> Add FAQ
          </Button>
        </Box>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: (theme) => theme.palette.background.main,
            borderRadius: "14px",
            p: 3,
            maxWidth: "100%",
            width: "50%",
          },
        }}
      >
        <Typography
          variant="bold"
          sx={{
            color: (theme) => theme.palette.primary.main,
            mb: 3,
          }}
        >
          Add FAQ
        </Typography>
        <Box>
          <FormTextField
            fullWidth
            name="question"
            value={details?.question}
            onChange={handleChange}
            placeholder="Type Question"
            autoComplete="off"
            sx={{ mb: 3 }}
          />

          <FormTextField
            name="answer"
            value={details?.answer}
            multiline
            minRows={6}
            maxRows={9}
            fullWidth
            onChange={handleChange}
            placeholder="Case 2"
            autoComplete="off"
          />
        </Box>

        <DialogActions
          sx={{
            pt: 4,
          }}
        >
          <Button sx={{ height: "32px" }} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            sx={{ height: "32px" }}
            disabled={loading}
            variant="primary"
            onClick={() => (!edit ? handleSave() : handleUpdate())}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
