import { Box, Button, Dialog, DialogActions, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { dFlex } from "../../../common/commonStyles";
import { SvgVector } from "../../../images/icons";

export default function MatchThePairModal({ data, index }) {
  const [open, setOpen] = useState(false);
  const [leftOptions, setLeftOptions] = useState([]);
  const [rightOptions, setRightOptions] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!data?.data) {
      return;
    }
    let left = data?.data?.options;
    setLeftOptions(left);
    // let right = data?.data?.correctOptions?.sort((a, b) => a.match - b.match);
    setRightOptions(data?.data?.correctOptions);
  }, [data?.data]);
  return (
    <>
      <Box onClick={handleClickOpen}>
        <Typography
          sx={{
            color: (theme) => theme.palette.text.main,
            px: 1,
            cursor: "pointer",
            transition: "all 0.2s ease",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.background.main,
            },
          }}
        >
          {data?.questionName}
        </Typography>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "#F3F5F5",
            borderRadius: "14px",
            p: "24px 12px 12px 24px",
            minWidth: "500px",
          },
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.text.main,
            mb: 1,
          }}
        >
          {index + 1}. {data?.question}
        </Typography>
        <Box sx={{ ...dFlex }}>
          <Box
            sx={{
              ...dFlex,
              flexDirection: "column",
              mt: 2,
              width: "100%",
              p: "9px 20px",
            }}
          >
            {leftOptions?.map((e, i) => (
              <Box key={i} sx={{ ...dFlex, justifyContent: "space-between" }}>
                <Box sx={{ position: "relative", overflow: "hidden" }}>
                  <Box
                    sx={{
                      width: "220px",
                      height: "50px",
                      mb: 1,
                      pl: 1,
                      mr: 2,
                      background: (theme) => theme.palette.primary.main,
                      // borderRadius: "8px 0px 0px 8px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <SvgVector />{" "}
                    <Typography sx={{ color: "#fff", ml: 1 }}>{e}</Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "230px",
                      clipPath: "ellipse(5% 12% at 100% 50%)",
                      height: "50px",
                      background: "#fff",
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                    }}
                  ></Box>
                </Box>
                <Box
                  sx={{
                    position: "relative",
                    overflow: "hidden",
                    width: "220px",
                  }}
                >
                  <Box
                    sx={{
                      minWidth: { xs: "100px", md: "220px" },
                      height: "50px",
                      // ml: "10px",
                      px: 2,
                      background: (theme) => theme.palette.primary.main,
                      position: "absolute",
                      clipPath: "ellipse(5% 12% at 5% 50%)",
                      // justifyContent: "space-between",
                      // top: "0px",
                      // left: "0px",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      // width: { xs: "100px", md: "170px" },
                      clipPath: "inset(0 0 0 5%)",
                      height: "50px",
                      pr: 1,
                      background: (theme) => theme.palette.primary.main,
                      justifyContent: "space-between",
                      // borderRadius: "0px 8px 8px 0px",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "20px",
                    }}
                  >
                    {/* <DragIcons /> */}
                    <Typography sx={{ color: "#fff", mr: 1, pr: 1 }}>
                      {rightOptions[i]?.text}
                    </Typography>
                    <SvgVector />
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <DialogActions sx={{ pt: 1 }}>
          <Button
            sx={{ height: "32px", textTransform: "none" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
