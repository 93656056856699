import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function HomePage() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/login", { replace: true });
  }, []);

  return (
    <Box sx={{ px: 2 }}>
      <Typography>Home Page</Typography>
      <Button variant="primary" onClick={() => navigate("/login")}>
        Go to Login
      </Button>
      {/* <Button variant="primary" onClick={() => navigate("/reset-password")}>
        Go to Reset Password
      </Button>
      <Button variant="primary" onClick={() => navigate("/register")}>
        Go to Register
      </Button> */}
      <Button variant="primary" onClick={() => navigate("/tos")}>
        Terms and Conditions
      </Button>
    </Box>
  );
}
