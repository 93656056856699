import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../Apis/axiosPayloads";
import { PATCH, POST } from "../../Apis/constVariables";
import useDataFetchHook from "../../Hooks/DataFetchHook";
import { flexCenter, itemsCenter } from "../../common/commonStyles";
import { FormTextField } from "../../const/TextField";
import { AccordionTransition } from "../../const/constFunctions";
import { success } from "../../const/variables";
import { EditIconColor } from "../../images/icons";
import CreateQuestion from "./CreateQuestion";
import QuizDeleteModal from "./DeleteModals/QuizDeleteModal";
import { ExpandMoreSvg, SvgVector } from "./icons";
import "./styles/createTopic.css";

const initData = {
  quizName: "",
};
const CreateQuiz = ({
  text,
  topicId = null,
  lessonId = null,
  moduleId = null,
}) => {
  let url = null;
  if (lessonId) {
    url = process.env.REACT_APP_LESSON_QUIZ + `?lessonId=${lessonId}`;
  } else if (topicId) {
    url = process.env.REACT_APP_TOPIC_QUIZ + `?topicId=${topicId}`;
  }
  const [quizApiCall, fetchQuizAgain, quizDispatch] = useDataFetchHook(url);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [showQuizForm, setShowQuizForm] = useState(false);

  const [editable, setEditable] = useState(false);

  const addQuiz = async (data) => {
    try {
      let nsn = data.quizName.replaceAll(" ", "");
      if (!nsn) {
        toast.error("Fields Cannot be empty");
        return;
      }
      let dat = /\//i.test(nsn);

      if (!dat) {
      } else {
        toast.error("you can not use any (/) characters");
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_QUIZ;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          quizName: data?.quizName.trim(),
          lessonAssociated: lessonId ?? null,
          moduleAssociated: moduleId ?? null,
          topicAssociated: topicId ?? null,
          type: "quiz",
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchQuizAgain();
      toggleForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const updateQuiz = async (data) => {
    try {
      let nsn = data.quizName.replaceAll(" ", "");
      if (!nsn) {
        toast.error("Fields Cannot be empty");
        return;
      }

      let dat = /\//i.test(nsn);

      if (!dat) {
      } else {
        toast.error("you can not use any (/) characters");
        return;
      }
      setLoading(true);
      console.log({ data });
      const url = process.env.REACT_APP_QUIZ + `/${data?.quizId}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: {
          quizName: data?.quizName.trim(),
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchQuizAgain();
      toggleForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const toggleForm = () => {
    reset(initData);
    setShowQuizForm((p) => !p);
  };

  const edit = (quiz) => {
    setEditable(true);
    reset(quiz);
    setShowQuizForm(true);
  };

  const handleOnDragEnd = async (result) => {
    try {
      if (!result.destination) return;

      // console.log({ before: quizApiCall?.res?.quiz });
      const items = Array.from(quizApiCall?.res?.quiz);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      let url = null;
      let name = null,
        value = null;
      if (lessonId) {
        url = process.env.REACT_APP_LESSON + `/addLessonQuizOrder`;
        name = "lessonId";
        value = lessonId;
      } else if (topicId) {
        url = process.env.REACT_APP_TOPIC + `/addTopicQuizOrder`;
        name = "topicId";
        value = topicId;
      }

      const formattedData = {
        [name]: value,
        quiz: items.map((e) => e.quizId),
      };
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: formattedData,
      });
      setLoading(true);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      quizDispatch({ type: success, data: { quiz: items } });
      // fetchLessonAgain();
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <>
      <section>
        <Button
          variant="primary-small"
          sx={{ my: 2, mb: 1 }}
          onClick={() => {
            setEditable(false);
            toggleForm();
          }}
        >
          Add {text}
        </Button>
        {showQuizForm && (
          <Box
            className="createQuiz-quizForm createQuiz-container"
            id={"quizForm"}
          >
            <Typography
              variant="bold"
              sx={{ color: (theme) => theme.palette.primary.main }}
            >
              {text}
            </Typography>
            <Box className="createQuiz-row" sx={{ my: 2 }}>
              <label className="createQuiz-inputLabel">Quiz Name *</label>
              <FormTextField
                autoFocus
                {...register("quizName", { required: "Enter Quiz Name" })}
                variant="outlined"
                // className="createQuiz-inputBar"
                error={!!errors.quizName}
                helperText={errors?.quizName?.message}
              />
            </Box>
            {/* <Box className="createQuiz-row">
              <Typography className="createQuiz-inputLabel">
                Time Limit (minutes)*
              </Typography>
              <FormTextField
                {...register("timeLimit", {
                  required: "Enter Time Limit",
                  min: { value: 0, message: "Enter a valid Time Limit" },
                })}
                type="number"
                variant="outlined"
                // className="createQuiz-inputBar"
                error={!!errors.timeLimit}
                helperText={errors?.timeLimit?.message}
              />
            </Box> */}

            <Box className="createQuiz-row">
              <Button
                sx={{
                  ml: "auto",
                  textTransform: "none",
                }}
                onClick={() => {
                  setEditable(false);
                  toggleForm();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary-small"
                disabled={loading}
                onClick={handleSubmit(!editable ? addQuiz : updateQuiz)}
              >
                Save
              </Button>
            </Box>
          </Box>
        )}
        {(quizApiCall?.loading || loading) && (
          <Box sx={{ ...flexCenter }}>
            <CircularProgress />
          </Box>
        )}

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <ul
                id="quizList"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  listStyleType: "none",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {quizApiCall?.res?.quiz?.map((item, index) => (
                  <Draggable
                    key={item.quizId}
                    draggableId={item.quizId}
                    index={index}
                  >
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        key={item.quizId}
                        className="quiz-dragable"
                      >
                        <Accordion TransitionProps={AccordionTransition}>
                          <AccordionSummary
                            {...provided.dragHandleProps}
                            expandIcon={<ExpandMoreSvg />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.content.quiz,
                              color: "white",
                              height: "40px",
                              borderRadius: "7px",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <SvgVector />
                              <Box className="quiz-accordion-heading">
                                {item.quizName}
                              </Box>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box className="quiz-accordion-details-head">
                              <p>{item.quizName}</p>
                              <Box
                                sx={{
                                  ...itemsCenter,
                                  ml: "auto",
                                  mr: 1,
                                }}
                                // onClick={() => del(item)}
                              >
                                <IconButton onClick={() => edit(item)}>
                                  <EditIconColor />
                                </IconButton>
                                <QuizDeleteModal
                                  fetchQuizAgain={fetchQuizAgain}
                                  data={item}
                                />
                              </Box>
                            </Box>

                            <CreateQuestion quizId={item.quizId} />
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </section>
    </>
  );
};

export default CreateQuiz;
