import { Avatar, Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import useCheckRole from "../../Hooks/CheckRole";
import { itemsCenter } from "../../common/commonStyles";
import { UserContext } from "../../context/UserContext";

export default function ContentUploaderHeader() {
  const { userDetails } = useContext(UserContext);
  useCheckRole();
  // console.log({ userDetails });
  return (
    <Box
      id="nav-header"
      sx={{
        ...itemsCenter,
        justifyContent: "flex-end",
        height: "64px",
        gap: "20px",
        backgroundColor: (theme) => theme.palette.background.secondary,
        px: "40px",
        zIndex: 1,
        position: "sticky",
        top: 0,
      }}
    >
      <Box sx={{ ...itemsCenter, gap: "10px" }}>
        {/* Render user profile avatar */}
        <Avatar
          src={userDetails?.imageUrl ?? ""}
          sx={{ width: 43, height: 43 }}
          alt="profile-photo"
        >
          {userDetails?.name?.[0] ?? "NA"}
        </Avatar>
        {/* Render user name */}
        <Typography
          variant="medium"
          sx={{
            color: (theme) => theme.palette.text.main,
          }}
        >
          {userDetails?.name ?? "NA"}
        </Typography>
      </Box>
    </Box>
  );
}
