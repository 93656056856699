import { Box, Button, CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Select from "react-select";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../../Apis/axiosPayloads";
import { PATCH } from "../../../../Apis/constVariables";
import useDataFetchHook from "../../../../Hooks/DataFetchHook";
import { dFlex, flexCenter } from "../../../../common/commonStyles";
import { reactSelectTheme } from "../../../../const/constFunctions";
import { success } from "../../../../const/variables";
import { AddIcon, PublishIcon } from "../../../../images/icons";
import AddIntroSlide from "./AddIntroSlide";
import IntroMessageCard from "./IntroMessageCard";

export default function IntroMessage() {
  const [url, setUrl] = useState(null);
  const [add, setAdd] = useState(false);
  const [value, setValue] = useState(null);
  const [apiCall, fetchAgain, dispatchIntro] = useDataFetchHook(url);
  const [loading, setLoading] = useState(false);
  const [options] = useState([
    { label: "School Admin", value: "school" },
    { label: "Parent", value: "parent" },
    { label: "Facilitator", value: "facilitator" },
    { label: "Students (For Community Project)", value: "student" },
  ]);
  // const slides = ["Slide 1", "Slide 2"];

  const handleChange = (selectedOption) => {
    setValue(selectedOption);
  };
  const handlePublish = async () => {
    try {
      if (loading) {
        toast.error("Another request is in progress");
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_INTRO_MESSAGE + "/publish";
      const config = createAxiosConfig({
        endPoint: url,
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
    setLoading(false);
  };

  async function handleOnDragEnd(result) {
    try {
      if (!result.destination) return;
      const items = Array.from(apiCall?.res?.slides);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setLoading(true);
      const url = process.env.REACT_APP_INTRO_MESSAGE + `/slide`;
      const formattedData = {
        type: value?.value,
        slides: items.map((e) => e.id),
      };
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: formattedData,
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      dispatchIntro({ type: success, data: { slides: items } });
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!value) {
      return;
    }
    const url = process.env.REACT_APP_INTRO_MESSAGE + `?type=${value.value}`;
    setUrl(url);
  }, [value]);

  return (
    <>
      <Box sx={{ ...dFlex, gap: 2, justifyContent: "space-between", mb: 3 }}>
        <Box>
          <Select
            theme={reactSelectTheme}
            isClearable
            options={options}
            styles={{
              control: (provided) => ({
                ...provided,
                width: "175px",
              }),
            }}
            placeholder="Select user type"
            value={value}
            onChange={handleChange}
          />
        </Box>
        <Box>
          <Button
            variant="primary"
            disabled={!value?.value}
            onClick={() => {
              setAdd(true);
            }}
          >
            <AddIcon />
            Add Slide
          </Button>
          <Button disabled={loading} onClick={handlePublish} variant="primary">
            <PublishIcon />
            Publish
          </Button>
        </Box>
      </Box>
      {(apiCall?.loading || loading) && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />
        </Box>
      )}
      {!apiCall?.loading && (
        <Box>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {apiCall?.res?.slides?.map((item, id) => (
                    <Draggable key={item.id} draggableId={item.id} index={id}>
                      {(provided, snapshot) => (
                        <IntroMessageCard
                          provided={provided}
                          snapshot={snapshot}
                          index={id}
                          data={item}
                          fetchAgain={fetchAgain}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      )}
      <Box>
        {add && (
          <AddIntroSlide
            type={value.value}
            setAdd={setAdd}
            fetchAgain={fetchAgain}
            slideLength={apiCall?.res?.slides?.length}
          />
        )}
      </Box>
    </>
  );
}
