import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Select from "react-select";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import { dFlex, flexCenter } from "../../../common/commonStyles";
import { reactSelectTheme } from "../../../const/constFunctions";
import { analyticsEmpty } from "../../../const/imageUrl";

export default function AnalyticsGraph() {
  // REACT_APP_FACILITATOR_ANALYTICS
  const [url, setUrl] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [labels, setLabels] = useState([]);
  // const [series, setSeries] = useState([]);
  const [analyticsDataApi] = useDataFetchHook(url);
  const [batches, setBatches] = useState([]);
  const [series, setSeries] = useState([]);

  const handleChange = (selectedOption) => {
    setSelectedBatch(selectedOption);
  };

  console.log({ res: analyticsDataApi?.res });

  const batchUrl =
    process.env.REACT_APP_SCHOOL_FACILITATOR +
    `/batchlist?filterCompleted=true`;

  const [apiData] = useDataFetchHook(batchUrl);

  useEffect(() => {
    if (!apiData?.res) {
      return;
    }
    let formattedData = apiData?.res?.batch?.map((e) => ({
      label: e.batchName,
      value: e.batchName,
    }));
    setBatches(formattedData);
    return () => {
      setBatches([]);
    };
  }, [apiData?.res]);

  const option = {
    chart: {
      toolbar: {
        tools: {
          download: false,
        },
      },
      zoom: {
        enabled: false,
      },
    },

    plotOptions: {
      bar: {
        barWidth: "150px",
      },
    },
    title: {
      text: "Tokens",
      align: "left",
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: labels,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#358B8A"],
        shadeIntensity: 1,
        type: "horizontal",
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
  };

  useEffect(() => {
    if (!selectedBatch) {
      setUrl(null);
      setLabels([]);
      setSeries([]);
      return;
    }
    setLabels([]);
    setSeries([]);

    let url =
      process.env.REACT_APP_FACILITATOR_ANALYTICS +
      `?batchName=${selectedBatch.value}`;
    setUrl(url);
  }, [selectedBatch]);

  useEffect(() => {
    if (!analyticsDataApi?.res) {
      return;
    }
    let seriesData = analyticsDataApi?.res?.data?.map((e) => e.tokens);
    console.log({ seriesData });

    setLabels(analyticsDataApi?.res?.data?.map((e) => e.weekStart));
    setSeries([
      {
        name: "",
        data: seriesData,
      },
    ]);
    console.log(analyticsDataApi?.res);
  }, [analyticsDataApi?.res]);

  return (
    <Box
      sx={{
        mt: 5,
        backgroundColor: (theme) => theme.palette.background.secondary,
        height: "601px",
        p: 5,
        boxSizing: "border-box",
        borderRadius: "12px",
      }}
    >
      <Typography
        variant="bold"
        sx={{ color: (theme) => theme.palette.primary.main }}
      >
        Analytics
      </Typography>
      {false && (
        <Box
          sx={{
            mt: 3,
            backgroundColor: (theme) => theme.palette.background.secondary,
            ...flexCenter,
            flexDirection: "column",
            py: 8,
            gap: 5,
            borderRadius: "12px",
          }}
        >
          <img
            src={analyticsEmpty}
            style={{ height: "310px", width: "354px" }}
          />
          <Typography
            variant="bold"
            sx={{ color: (theme) => theme.palette.text.light }}
          >
            Analytics of student progress will be shown here
          </Typography>
        </Box>
      )}
      <Box sx={{ mt: 5 }}>
        <Box sx={{ ...dFlex, justifyContent: "flex-end" }}>
          <Select
            options={batches}
            theme={reactSelectTheme}
            isClearable={true}
            value={selectedBatch}
            onChange={handleChange}
            maxMenuHeight={150}
            styles={{
              control: (provided) => ({
                ...provided,
                width: "200px",
              }),
            }}
            placeHolder="Select Week"
          />
        </Box>
        {analyticsDataApi?.loading && (
          <Box sx={{ ...flexCenter }}>
            <CircularProgress />
          </Box>
        )}
        {selectedBatch ? (
          <Chart
            options={option}
            series={series}
            type="line"
            width={"98%"}
            height={350}
          />
        ) : (
          <Box
            sx={{
              mt: 3,
              backgroundColor: (theme) => theme.palette.background.secondary,
              ...flexCenter,
              flexDirection: "column",
              // py: 8,
              gap: 5,
              borderRadius: "12px",
            }}
          >
            <img
              src={analyticsEmpty}
              style={{ height: "310px", width: "354px" }}
            />
            <Typography
              variant="bold"
              sx={{ color: (theme) => theme.palette.text.light }}
            >
              Select batch to view analytics
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
