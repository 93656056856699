import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  InputAdornment,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import {
  dFlex,
  flexBetweenCenter,
  flexCenter,
  itemsCenter,
} from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { RegisterIcon } from "../../../images/icons";

export default function AddStudent({ groupData, fetchAgain }) {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(null);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const [apiData] = useDataFetchHook(url);

  const params = useParams();

  const handleOpen = () => {
    setUrl(
      process.env.REACT_APP_FACILITATOR_GROUP_INFO +
        `/add?groupId=${params.groupId}`
    );
    setOpen(true);
  };
  const handleClose = () => {
    setUrl(null);
    setOpen(false);
    setCheckedItems([]);
  };

  const handleCheckboxChange = (event, index) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setCheckedItems((p) => [...p, index]);
    } else {
      setCheckedItems((p) => p.filter((e, i) => e !== index));
    }
  };

  useEffect(() => {
    if (!apiData?.res) {
      return;
    }
    setData(apiData?.res?.users);
  }, [apiData?.res]);

  const handleSearch = () => {
    try {
      // console.log("searhing");
      let arr = [...apiData?.res?.users];
      arr = arr?.filter((e) => {
        return e?.studentName
          ?.toLowerCase()
          ?.includes(searchText.toLowerCase());
      });
      setData(arr);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!searchText) {
      setData(apiData?.res?.users);
      return;
    }
    handleSearch();
  }, [searchText]);

  const handleAdd = async () => {
    try {
      const url = process.env.REACT_APP_FACILITATOR_GROUP_INFO + "/add";
      setLoading(true);
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: { groupId: params.groupId, members: checkedItems },
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      fetchAgain();
      toast(apiRes?.data?.message);
      handleClose();
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        disabled={groupData?.answerSubmitted}
        onClick={handleOpen}
        variant="primary"
      >
        <RegisterIcon />
        Add Students{" "}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: (theme) => theme.palette.background.main,
            borderRadius: "14px",
            p: 3,
            maxWidth: "100%",
            width: "55%",
          },
        }}
      >
        <Typography
          variant="bold"
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
        >
          Add Students
        </Typography>
        <Box sx={{ ...flexBetweenCenter, my: 3 }}>
          <Box
            sx={{
              ...dFlex,
              gap: 2,
              alignItems: "center",
              boxSizing: "border-box",
            }}
          >
            <FormTextField
              placeholder="search"
              // sx={{ width: "400px" }}
              disabled={apiData?.loading}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      fontSize="small"
                      sx={{
                        color: "#BFBFBF",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            ...dFlex,
            px: 3,
            height: "60vh",
            gap: 3,
            flexWrap: "wrap",
            flexDirection: "row",
            overflow: "auto",
            justifyContent: "space-between",
            alignContent: "flex-start",
          }}
        >
          {apiData?.loading && (
            <Box sx={flexCenter}>
              <CircularProgress />
            </Box>
          )}

          {data?.map((e, index) => {
            return (
              <Box sx={{ ...itemsCenter, gap: 1, height: "fit-content" }}>
                <Checkbox
                  //   name={e?.id}
                  id={e?.id}
                  sx={{ p: 0 }}
                  checked={checkedItems?.includes(e?.id)}
                  onChange={(event) => handleCheckboxChange(event, e?.id)}
                />
                <label
                  htmlFor={e?.id}
                  style={{ cursor: "pointer", height: "fit-content" }}
                >
                  <StudentCard data={e} />
                </label>
              </Box>
            );
          })}
        </Box>
        <DialogActions sx={{ pb: 0 }}>
          <Button
            onClick={handleClose}
            // disabled={loading}
            sx={{
              fontWeight: 500,
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleAdd}
            disabled={loading}
            variant="primary"
            sx={{
              px: 4,
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function StudentCard({ data }) {
  return (
    <Box
      htmlFor="1234"
      sx={{
        minWidth: "320px",
        maxWidth: "350px",
        height: "80px",
        ...itemsCenter,
        alignItems: { xs: "center" },
        borderRadius: "8px",
        px: "24px",
        boxSizing: "border-box",
        backgroundColor: (theme) => theme.palette.background.secondary,
      }}
    >
      <Avatar
        src={data?.image}
        sx={{ height: "54px", width: "54px", mr: 1.5 }}
      />
      <Box
        sx={{
          ...dFlex,
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ ...itemsCenter, gap: 1 }}>
          <Typography
            variant="semibold"
            sx={{ color: (theme) => theme.palette.text.dark }}
          >
            {data?.studentName}{" "}
          </Typography>
          {/* <Box sx={{ border: "2px solid black", borderRadius: "100%" }}></Box> */}
        </Box>
        {data?.certificateIssued && (
          <Typography variant="medium2" sx={{ color: "#358B48" }}>
            Completed Certification
          </Typography>
        )}
      </Box>
    </Box>
  );
}
