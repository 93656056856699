import { Box } from "@mui/material";
import React from "react";
import Modules from "../../Components/Admin/Content/Module/Modules";
import NavBreadCrumbs from "../../common/NavBreadCrumbs";

export default function ModulesPage() {
  return (
    <Box>
      <NavBreadCrumbs pageTags={["Modules"]} />
      <Modules />
    </Box>
  );
}
