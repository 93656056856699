import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import AudioPlayer from "../../../../const/AudioPlayer";
import VideoPlayer from "../../../../const/VideoPlayer";

export default function FilePlayer({ fileSelected, setFileSelected }) {
  return (
    <Box
      sx={{
        position: "fixed",
        width: "400px",
        height: "400px",
        bottom: 30,
        right: 30,
        borderRadius: "15px",
        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.3)",
        backgroundColor: (theme) => theme.palette.background.secondary,
        //   zIndex: 50,
        //   transition: "height 10s",
        //   transition: "width 10s",
        transform: `scale(${fileSelected ? 1 : 0})`,
        transformOrigin: "bottom right",
        transition: "transform 0.4s ease-in-out",
        boxSizing: "border-box",
        p: 5,
      }}
    >
      <IconButton
        onClick={() => setFileSelected(null)}
        sx={{
          position: "absolute",
          top: 5,
          right: 10,
        }}
      >
        <CloseIcon
          fontSize="small"
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
        />
      </IconButton>
      {fileSelected?.type == "audio" && <AudioPlayer data={fileSelected} />}
      {fileSelected?.type == "video" && <VideoPlayer data={fileSelected} />}
      {/* <Box
        sx={{
          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12)",
          height: "252px",
        }}
      ></Box> */}
    </Box>
  );
}
