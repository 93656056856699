import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  FormControl,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../Apis/axiosPayloads";
import { POST } from "../../Apis/constVariables";
import { flexCenter, itemsCenter } from "../../common/commonStyles";
import { FormTextField } from "../../const/TextField";
import { reactSelectTheme } from "../../const/constFunctions";

export const defaultVal = "0";
export const paymentOptions = [
  {
    label: "Online",
    value: "online",
  },
  {
    label: "Offline",
    value: "offline",
  },
];
export const currencyType = [
  {
    label: "JMD",
    value: "jmd",
  },
  {
    label: "USD",
    value: "usd",
  },
];
export default function RegisterModal({
  type,
  open,
  onSubmit,
  handleClose,
  formSubmit,
}) {
  const [numberOfStudent, setNumberOfStudent] = useState(null);
  const [paymentMode, setPaymentMode] = useState({
    label: "Online",
    value: "online",
  });
  const [currency, setCurrency] = useState({
    label: "JMD",
    value: "jmd",
  });
  const [calculatedPrice, setCalculatedPrice] = useState(defaultVal);
  const [calculated, setCalculated] = useState(false);
  const [submit, setSubmit] = useState(false);

  const handleModalClose = () => {
    handleClose();
    setCalculatedPrice(defaultVal);
    setCalculated(false);
    setNumberOfStudent(null);
  };

  const handleGetPrice = async (numberOfStudent) => {
    try {
      if (!numberOfStudent) {
        setCalculatedPrice(defaultVal);
        setCalculated(false);
        setNumberOfStudent(null);
        toast.error("Please enter a number");
        return;
      }
      const url = process.env.REACT_APP_GET_CALCULATED_PRICE;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          type,
          student: numberOfStudent,
        },
      });
      setSubmit(true);
      const apiRes = await axios.request(config);
      setCalculated(true);
      setCalculatedPrice(apiRes?.data?.Price);
      toast("Price Calculated");
    } catch (err) {
      setNumberOfStudent(null);
      setCalculatedPrice(defaultVal);
      toast.error(err?.response?.data?.message);
    }
    setSubmit(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleModalClose}
        PaperProps={{
          sx: {
            backgroundColor: "#F3F5F5",
            borderRadius: "14px",
            p: "24px 12px 12px 24px",
            maxWidth: "100%",
            width: "30%",
          },
        }}
      >
        <Typography
          variant="bold2"
          sx={{
            color: (theme) => theme.palette.text.main,
            mb: 1,
          }}
        >
          Enter the number of students
        </Typography>
        <FormTextField
          type="number"
          sx={{ mb: 1 }}
          value={numberOfStudent}
          onBlur={(e) => {
            if (e.target.value < 0) {
              setNumberOfStudent(Math.abs(e.target.value));
            }
            handleGetPrice(Math.abs(e.target.value));
          }}
          onChange={(e) => setNumberOfStudent(e.target.value)}
          placeholder="Enter number of students you want to register"
        />
        <Box sx={{ ...itemsCenter, gap: 2 }}>
          <Typography
            variant="bold2"
            sx={{
              color: (theme) => theme.palette.text.main,
            }}
          >
            Currency
          </Typography>
          <FormControl sx={{ width: "50%" }}>
            <Select
              isSearchable={false}
              onChange={(e) => setCurrency(e)}
              value={currency}
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "120px",
                }),
              }}
              options={currencyType}
              theme={reactSelectTheme}
            />
          </FormControl>
        </Box>
        <Box sx={{ mt: 2 }}>
          {submit ? (
            <Box sx={{ ...flexCenter }}>
              <CircularProgress />
            </Box>
          ) : (
            <Typography
              sx={{
                color: (theme) => theme.palette.text.main,
                mb: 1,
                ...itemsCenter,
                gap: 2,
              }}
            >
              Total{" "}
              <Typography variant="bold">
                ${calculatedPrice?.[currency.value]}
              </Typography>
            </Typography>
          )}
        </Box>

        <Box sx={{ ...itemsCenter, gap: 3 }}>
          <Typography
            variant="bold2"
            sx={{
              color: (theme) => theme.palette.text.main,
            }}
          >
            Payment Mode:
          </Typography>
          <FormControl sx={{ width: "50%" }}>
            <Select
              isSearchable={false}
              onChange={(e) => setPaymentMode(e)}
              value={paymentMode}
              options={paymentOptions}
              theme={reactSelectTheme}
            />
          </FormControl>
        </Box>
        <DialogActions sx={{ pt: 5 }}>
          <Button
            sx={{ height: "32px", textTransform: "none" }}
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button
            disabled={submit || numberOfStudent <= 0 || formSubmit}
            sx={{
              width: paymentMode.value == "online" ? "100px" : "230px",
              // transform:
              //   paymentMode.value == "online"
              //     ? "rotateY(0deg)"
              //     : "rotateY(180deg)",
              whiteSpace: "nowrap",
              overflow: "hidden",
              transition: "all 600ms ease-out",
            }}
            variant="primary"
            onClick={() =>
              onSubmit(
                numberOfStudent,
                calculatedPrice?.[currency.value],
                paymentMode,
                currency.label
              )
            }
          >
            <span
            // style={{
            //   transform:
            //     paymentMode.value === "online"
            //       ? "rotateY(0deg)"
            //       : "rotateY(180deg)",
            //   transition: "all 0.6s ease-in-out",
            // }}
            >
              {!calculated
                ? "Calculate "
                : paymentMode.value == "online"
                ? "Subscribe"
                : "Send Verification Request"}
            </span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
