import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import BatchTab from "../../../Components/School/ManageBatch/BatchTab";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { TabPanel } from "../../Admin/Dashboard/SchoolSubscribers";

export default function ManageBatch() {
  const [value, setValue] = useState(1);

  // Event handler for tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Manage Batch"]} />
      <Box>
        {/* Tabs for current and previous subscribers */}
        <Box
          sx={{
            width: "100%",
            mt: 3,
            borderBottom: `1px solid #BFBFBF`,
          }}
        >
          <Tabs value={value} onChange={handleChange} variant="fullWidth">
            <Tab
              sx={{
                textTransform: "none",
              }}
              label={
                <Typography variant={value === 1 ? "bold2" : "medium"}>
                  Current batches
                </Typography>
              }
              value={1}
            />
            <Tab
              sx={{
                textTransform: "none",
              }}
              label={
                <Typography variant={value === 2 ? "bold2" : "medium"}>
                  Completed batches
                </Typography>
              }
              value={2}
            />
          </Tabs>
        </Box>

        {/* Tab panels */}
        <TabPanel value={value} index={1}>
          <BatchTab />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <BatchTab completed={true} />
        </TabPanel>
      </Box>
    </Box>
  );
}
