import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../Apis/axiosPayloads";
import { PATCH, POST } from "../../Apis/constVariables";
import useDataFetchHook from "../../Hooks/DataFetchHook";
import { flexCenter, itemsCenter } from "../../common/commonStyles";
import { FormTextField } from "../../const/TextField";
import { AccordionTransition } from "../../const/constFunctions";
import { success } from "../../const/variables";
import { EditIconColor } from "../../images/icons";
import CreateBrawtaQuestion from "./CreateBrawtaQuestion";
import BrawtaDeleteModal from "./DeleteModals/BrawtaDeleteModal";
import { ExpandMoreSvg, SvgVector } from "./icons";
import "./styles/createTopic.css";

const initData = {
  brawtaName: "",
  tokens: "",
  instructions: "",
};
const CreateBrawtaChallenge = ({ lessonId = null, moduleId = null }) => {
  let url = null;
  if (lessonId) {
    url = process.env.REACT_APP_LESSON_BRAWTA + `?lessonId=${lessonId}`;
  }
  // else if (topicId) {
  //   url = process.env.REACT_APP_TOPIC_QUIZ + `?topicId=${topicId}`;
  // }
  const [brawtaApiCall, fetchBrawtaAgain, quizDispatch] = useDataFetchHook(url);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [showQuizForm, setShowQuizForm] = useState(false);

  const [editable, setEditable] = useState(false);

  const addBrawta = async (data) => {
    console.log({ data });
    try {
      let nsn = data.brawtaName.replaceAll(" ", "");
      if (!nsn) {
        toast.error("Fields Cannot be empty");
        return;
      }
      let dat = /\//i.test(nsn);
      if (!dat) {
      } else {
        toast.error("you can not use any (/) characters");
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_BRAWTA;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...data,
          tokens: Math.abs(data?.tokens),
          brawtaName: data?.brawtaName?.trim(),
          lessonAssociated: lessonId ?? null,
          moduleAssociated: moduleId ?? null,
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchBrawtaAgain();
      toggleForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const updateBrawta = async (data) => {
    try {
      let nsn = data.brawtaName.replaceAll(" ", "");
      if (!nsn) {
        toast.error("Fields Cannot be empty");
        return;
      }
      let dat = /\//i.test(nsn);
      if (!dat) {
      } else {
        toast.error("you can not use any (/) characters");
        return;
      }
      setLoading(true);
      console.log({ data });
      const url = process.env.REACT_APP_BRAWTA + `/${data?.brawtaId}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: {
          ...data,
          brawtaName: data?.brawtaName.trim(),
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchBrawtaAgain();
      toggleForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const toggleForm = () => {
    reset(initData);
    setShowQuizForm((p) => !p);
  };

  const edit = (quiz) => {
    setEditable(true);
    reset(quiz);
    setShowQuizForm(true);
  };

  const handleOnDragEnd = async (result) => {
    try {
      if (!result.destination) return;
      const items = Array.from(brawtaApiCall?.res?.brawta);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      let url = process.env.REACT_APP_BRAWTA + `/updateBrawtaOrder`;
      let value = lessonId;

      const formattedData = {
        lessonId: value,
        brawta: items.map((e) => e.brawtaId),
      };
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: formattedData,
      });
      setLoading(true);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      quizDispatch({ type: success, data: { brawta: items } });
      // fetchLessonAgain();
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <>
      <section>
        <Button
          variant="primary-small"
          sx={{ my: 1 }}
          onClick={() => {
            setEditable(false);
            toggleForm();
          }}
        >
          Add Brawta Challenge
        </Button>

        {showQuizForm && (
          <Box
            className="createQuiz-quizForm createQuiz-container"
            id={"quizForm"}
          >
            <Typography
              variant="bold"
              sx={{ color: (theme) => theme.palette.primary.main }}
            >
              Brawta Challenge
            </Typography>
            <Box className="createQuiz-row" sx={{ my: 2 }}>
              <p className="quizForm-mainForm-label">Brawta Name *</p>
              <FormTextField
                autoFocus
                {...register("brawtaName", {
                  required: "Enter Quiz Name",
                  maxLength: {
                    value: 100,
                    message: "Cannot exceed 100 characters",
                  },
                })}
                variant="outlined"
                // className="createQuiz-inputBar"
                placeholder="Brawta Challenge"
                sx={{ width: "50%" }}
                error={!!errors.brawtaName}
                helperText={errors?.brawtaName?.message}
              />
            </Box>
            <Box className="createQuiz-row">
              <p className="quizForm-mainForm-label">Tokens :</p>
              <FormTextField
                type="number"
                placeholder="Tokens"
                sx={{ mr: 3, width: "50%" }}
                name="firstTokens"
                {...register("tokens", { required: "Tokens are required" })}
                error={!!errors.tokens}
                helperText={errors?.tokens?.message}
              />
            </Box>

            <Box className="createQuiz-row">
              <label className="quizForm-mainForm-label">Instruction : </label>
              <FormTextField
                multiline
                minRows={4}
                maxRows={6}
                sx={{ width: "50%" }}
                {...register("instructions", {
                  required: "Instructions are required",
                })}
                error={!!errors.instructions}
                helperText={errors?.instructions?.message}
              />
            </Box>

            <Box className="createQuiz-row">
              <Button
                sx={{
                  ml: "auto",
                  textTransform: "none",
                }}
                onClick={() => {
                  setEditable(false);
                  toggleForm();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary-small"
                disabled={loading}
                onClick={handleSubmit(!editable ? addBrawta : updateBrawta)}
              >
                Save
              </Button>
            </Box>
          </Box>
        )}
        {(brawtaApiCall?.loading || loading) && (
          <Box sx={{ ...flexCenter }}>
            <CircularProgress />
          </Box>
        )}

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <ul
                id="quizList"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  listStyleType: "none",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {brawtaApiCall?.res?.brawta?.map((item, index) => (
                  <Draggable
                    key={item.brawtaId}
                    draggableId={item.brawtaId}
                    index={index}
                  >
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        key={item.brawtaId}
                        className="quiz-dragable"
                      >
                        <Accordion TransitionProps={AccordionTransition}>
                          <AccordionSummary
                            {...provided.dragHandleProps}
                            expandIcon={<ExpandMoreSvg />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.content.brawtaChallenge,
                              color: "white",
                              height: "40px",
                              borderRadius: "7px",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <SvgVector />
                              <Box className="quiz-accordion-heading">
                                {item.brawtaName}
                              </Box>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box className="quiz-accordion-details-head">
                              <p>{item.brawtaName}</p>
                              <Box
                                sx={{
                                  ...itemsCenter,
                                  ml: "auto",
                                  mr: 1,
                                }}
                                // onClick={() => del(item)}
                              >
                                <IconButton onClick={() => edit(item)}>
                                  <EditIconColor />
                                </IconButton>
                                <BrawtaDeleteModal
                                  fetchBrawtaAgain={fetchBrawtaAgain}
                                  data={item}
                                />
                              </Box>
                            </Box>

                            <CreateBrawtaQuestion brawtaId={item.brawtaId} />
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </section>
    </>
  );
};

export default CreateBrawtaChallenge;
