import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../Apis/axiosPayloads";
import { POST } from "../../Apis/constVariables";
import { flexCenter } from "../../common/commonStyles";
import { PaymentErrorIcon } from "../../images/icons";

export default function PaymentFailure(data) {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/login", { replace: true });
    setOpen(false);
  };

  useEffect(() => {
    try {
      const url2 = process.env.REACT_APP_REGISTER_STATUS + "/fail";
      const config2 = createAxiosConfig({
        endPoint: url2,
        method: POST,
        data: data,
      });
      axios
        .request(config2)
        .then((e) => {
          // localStorage.clear()
          console.log("Registered Successfully");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong, Please Contact Administrator");
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "13px",
            backgroundColor: (theme) => theme.palette.background.main,
          },
        }}
      >
        <Box sx={{ width: "400px", pb: "20px", textAlign: "center" }}>
          {/* <form onSubmit={handleDeleteAdmission} autoComplete="off"> */}
          <DialogContent sx={{ mb: 0 }}>
            <DialogContentText id="alert-dialog-description">
              <Box sx={{ ...flexCenter, flexDirection: "column", mb: 1 }}>
                <PaymentErrorIcon />
                <Typography
                  variant="regular"
                  sx={{
                    color: "#D22E39",
                    fontWeight: "700",
                  }}
                >
                  Error{" "}
                </Typography>
              </Box>
              <Typography
                variant="regular"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Unfortunately there is an issue with your payment, try again or
                contact administrator
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button onClick={() => navigate("/register")} variant="primary">
              Retry
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
