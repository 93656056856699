import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { DownloadIcon } from "../images/icons";
import { flexCenter } from "./commonStyles";
export default function DownloadSelect({
  downloadCsv,
  downloadPdf,
  downloadExcel,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  // const [selectedRow, setSelectedRow] = useState(null);
  const handleFileSelect = (type) => {
    try {
      console.log(type);
      switch (type) {
        case "csv":
          downloadCsv?.();
          break;
        case "pdf":
          downloadPdf?.();
          break;
        case "xls":
          downloadExcel?.();
          break;

        default:
          break;
      }
      handleCloseMenu();
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    // setSelectedRow(row);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    // setSelectedRow(null);
  };

  return (
    <Box>
      <Button
        sx={{
          background: "#FFF",
          textTransform: "none",
          height: "40px",
          width: "200px",
          borderRadius: "7px",
          p: "16px",
          border: (theme) => `1px solid ${theme.palette.primary.main}`,
        }}
        onClick={(event) => handleOpenMenu(event)}
      >
        <Typography
          variant="bold2"
          sx={{
            ...flexCenter,
            gap: 1,
            color: (theme) => theme.palette.primary.main,
          }}
        >
          <DownloadIcon />
          Download Data
        </Typography>{" "}
        {Boolean(anchorEl) ? (
          // && selectedRow === row
          <ArrowDropUpIcon
            sx={{ color: (theme) => theme.palette.primary.main }}
          />
        ) : (
          <ArrowDropDownIcon
            sx={{ color: (theme) => theme.palette.primary.main }}
          />
        )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {downloadCsv && (
          <MenuItem
            onClick={() => handleFileSelect("csv")}
            sx={{ width: "200px" }}
          >
            CSV
          </MenuItem>
        )}
        {downloadPdf && (
          <MenuItem
            onClick={() => handleFileSelect("pdf")}
            sx={{ width: "200px" }}
          >
            PDF
          </MenuItem>
        )}
        {downloadExcel && (
          <MenuItem
            onClick={() => handleFileSelect("xls")}
            sx={{ width: "200px" }}
          >
            XLS
          </MenuItem>
        )}
      </Menu>
    </Box>
    // <FormControl>
    //   <Select
    //     sx={{
    //       background: "#FFF",
    //       color: "#809fb8",
    //       height: "40px",
    //       width: "max-content",
    //       borderRadius: "7px",
    //       // p: "16px 7px",
    //       border: (theme) => `1px solid ${theme.palette.primary.main}`,
    //     }}
    //     onChange={handleFileChange}
    //     value={fileType}
    //   >
    //     {/* Default option with download icon */}
    //     <MenuItem value="default">
    //       <Typography
    //         variant="bold2"
    //         sx={{
    //           ...flexCenter,
    //           gap: 1,
    //           color: (theme) => theme.palette.primary.main,
    //         }}
    //       >
    //         <DownloadIcon />
    //         Download Data
    //       </Typography>
    //     </MenuItem>

    //     {/* File type options */}
    //     <MenuItem value="CSV">
    //       <Typography variant="medium">CSV</Typography>
    //     </MenuItem>
    //     <MenuItem value="PDF">
    //       <Typography variant="medium">PDF</Typography>
    //     </MenuItem>
    //     <MenuItem value="XLS">
    //       <Typography variant="medium">XLS</Typography>
    //     </MenuItem>
    //   </Select>
    // </FormControl>
  );
}
