import { Box } from "@mui/material";
import React from "react";
import AceQuestionAnswering from "./AceQuestionAnswering";

export default function AceQuestionAnswerTab({
  data,
  print,
  answers,
  setAnswers,
}) {
  const handleAnswer = (id, ans) => {
    if (print) {
      return;
    }
    setAnswers((prev) => {
      return {
        ...prev,
        [id]: ans,
      };
    });
  };
  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.secondary,
        // maxHeight: "80vh",
        // overflow: "scroll",
        borderRadius: "7px",
        py: 1,
        px: 3,
      }}
    >
      {data?.map((item, i) => (
        <Box className={(i + 1) % 7 === 0 ? "pagebreak" : ""}>
          <AceQuestionAnswering
            index={i}
            data={item}
            answers={answers}
            handleAnswer={handleAnswer}
          />
        </Box>
      ))}
    </Box>
  );
}
