import { Box, Button, Dialog, DialogActions, Typography } from "@mui/material";
import React, { useState } from "react";
import { dFlex, itemsCenter } from "../../../common/commonStyles";

export default function PicMCQModal({ data, index }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    console.log({ data });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box onClick={handleClickOpen}>
        <Typography
          sx={{
            color: (theme) => theme.palette.text.main,
            px: 1,
            cursor: "pointer",
            transition: "all 0.2s ease",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.background.main,
            },
          }}
        >
          {data?.questionName}
        </Typography>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "#F3F5F5",
            borderRadius: "14px",
            p: "24px 12px 12px 24px",
            maxWidth: "100%",
            width: "70%",
          },
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.text.main,
            mb: 1,
          }}
        >
          {index + 1}. {data?.question}
        </Typography>
        <Box sx={{ ...dFlex, flexDirection: "column", mt: 2 }}>
          {data?.data?.options?.map((e, i) => (
            <Box
              key={i}
              sx={{
                ...itemsCenter,
                gap: 2,
                my: 1,
                borderRadius: "6px",
                backgroundColor: (theme) => theme.palette.background.secondary,
              }}
            >
              <Box
                sx={{
                  p: "9px 20px",
                  flex: "80%",
                  color: (theme) => theme.palette.text.main,
                  ...itemsCenter,
                  // boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.12)",
                }}
              >
                <Box
                  sx={{
                    mr: 2,
                    height: "22px",
                    width: "22px",
                    borderRadius: "50%",
                    border: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                  }}
                ></Box>
                <img
                  src={e?.pic}
                  style={{
                    borderRadius: "5px",
                    marginRight: "16px",
                    height: "70px",
                    width: "100px",
                    aspectRatio: "3/2",
                    objectFit: "contain",
                  }}
                />
                {e?.label}
              </Box>
            </Box>
          ))}
        </Box>
        <DialogActions sx={{ pt: 1 }}>
          <Button
            sx={{ height: "32px", textTransform: "none" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
