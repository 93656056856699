import { Box, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ACEQuestionsTab from "../../../Components/Admin/RiskProfiling/ACEQuestionsTab";
import SDQQuestionsTab from "../../../Components/Admin/RiskProfiling/SDQQuestionsTab";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { flexCenter } from "../../../common/commonStyles";
import { TabPanel } from "../Dashboard/SchoolSubscribers";

export default function Questionnaires() {
  const [value, setValue] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const aceURL =
    process.env.REACT_APP_SUPERADMIN_RISK_PROFILING_QUESTION + "?type=ACE";
  const sdqPreURL =
    process.env.REACT_APP_SUPERADMIN_RISK_PROFILING_QUESTION + "?type=SDQPre";
  const sdqPOSTURL =
    process.env.REACT_APP_SUPERADMIN_RISK_PROFILING_QUESTION + "?type=SDQPost";
  // const [loading, setLoading] = useState(false);
  const [aceData, fetchAceData] = useDataFetchHook(aceURL);
  const [sdqPreData, fetchSDQPreData] = useDataFetchHook(sdqPreURL);
  const [sdqPostData, fetchSDQPostData] = useDataFetchHook(sdqPOSTURL);

  const handleChange = (event, newValue) => {
    const queryParam = new URLSearchParams(location?.search);
    queryParam.set("tab", newValue);
    navigate({ search: queryParam.toString() });
    setValue(newValue);
  };

  useEffect(() => {
    const queryParam = new URLSearchParams(location?.search);
    setValue(Number(queryParam.get("tab") ?? 1));
  }, [location?.search]);

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Risk Profiling", "Questionnaires"]} />
      <Box>
        <Box
          sx={{
            width: "100%",
            mt: 3,
            // position: "sticky",
            // top: 65,
            // zIndex: 5,
            // backgroundColor: "#eff7f6",
            borderBottom: `1px solid #BFBFBF`,
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            allowScrollButtonsMobile
            scrollButtons="auto"
          >
            <Tab
              sx={{
                textTransform: "none",
              }}
              label={
                <Typography variant={value === 1 ? "bold2" : "medium"}>
                  ACE Questions
                </Typography>
              }
              value={1}
            />
            <Tab
              sx={{
                textTransform: "none",
              }}
              label={
                <Typography variant={value === 2 ? "bold2" : "medium"}>
                  SDQ Pre Test
                </Typography>
              }
              value={2}
            />
            <Tab
              sx={{
                textTransform: "none",
              }}
              label={
                <Typography variant={value === 3 ? "bold2" : "medium"}>
                  SDQ Post Test
                </Typography>
              }
              value={3}
            />
          </Tabs>
        </Box>

        {(aceData?.loading || sdqPreData?.loading || sdqPostData?.loading) && (
          <Box sx={{ ...flexCenter, mt: 2 }}>
            <CircularProgress />
          </Box>
        )}

        {/* Tab panels */}
        <TabPanel value={value} index={1}>
          <ACEQuestionsTab
            data={aceData?.res?.question ?? []}
            fetchAgain={fetchAceData}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SDQQuestionsTab
            data={sdqPreData?.res?.question ?? []}
            fetchAgain={fetchSDQPreData}
            type={0}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <SDQQuestionsTab
            data={sdqPostData?.res?.question ?? []}
            fetchAgain={fetchSDQPostData}
            type={1}
          />
        </TabPanel>
      </Box>
    </Box>
  );
}
