import { Box, Button, Dialog, DialogActions, Typography } from "@mui/material";
import React, { useState } from "react";
import { dFlex } from "../../../../../common/commonStyles";

export default function DragAndDropModal({ data, index }) {
  const [open, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);

  const handleClickOpen = () => {
    console.log({ data });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box onClick={handleClickOpen}>
        <Typography
          sx={{
            color: (theme) => theme.palette.text.main,
            px: 1,
            cursor: "pointer",
            transition: "all 0.2s ease",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.background.main,
            },
          }}
        >
          {data?.questionName}
        </Typography>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "#F3F5F5",
            borderRadius: "14px",
            p: "24px 12px 12px 24px",
          },
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.text.main,
            mb: 1,
          }}
        >
          {index + 1}. {data?.question}
        </Typography>
        <Box sx={{ ...dFlex }}>
          <Box
            sx={{ flex: "80%", flexDirection: "column", mt: 2, p: "9px 20px" }}
          >
            <Box
              sx={{
                border: "1px dashed #BFBFBF",
                width: "389px",
                height: "141px",
                borderRadius: "8px",
                backgroundColor: (theme) => theme.palette.background.secondary,
                ...dFlex,
                boxSizing: "border-box",

                p: 2,
                gap: 2,
                flexWrap: "wrap",
              }}
            ></Box>
            <Box sx={{ ...dFlex, gap: 2, mt: 2 }}>
              <Box
                sx={{
                  border: "1px dashed #BFBFBF",
                  // height: "240px",
                  width: "180px",
                  backgroundColor: (theme) =>
                    theme.palette.background.secondary,
                  ...dFlex,
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  py: 0.5,
                  px: 0.5,
                }}
              >
                <Box>
                  {data?.stdAnswer?.item1Answers?.map((e) => (
                    <img
                      src={e}
                      style={{ marginRight: "10px" }}
                      height={"44px"}
                      width={"44px"}
                    />
                  ))}
                </Box>
                <img src={data?.data?.item1} height={"140px"} width={"120px"} />
              </Box>
              <Box
                sx={{
                  border: "1px dashed #BFBFBF",
                  // height: "240px",
                  width: "180px",
                  backgroundColor: (theme) =>
                    theme.palette.background.secondary,
                  ...dFlex,
                  alignItems: "flex-end",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  py: 0.5,
                }}
              >
                <Box>
                  {data?.stdAnswer?.item2Answers?.map((e) => (
                    <img
                      src={e}
                      style={{ marginRight: "10px" }}
                      height={"44px"}
                      width={"44px"}
                    />
                  ))}
                </Box>
                <img src={data?.data?.item2} height={"140px"} width={"120px"} />
              </Box>
            </Box>{" "}
          </Box>
        </Box>

        <DialogActions sx={{ pt: 1 }}>
          <Button
            sx={{ height: "32px", textTransform: "none" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
