import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TablePagination,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BrawtaStudentCard from "../../../Components/Parent/BrawtaChallenge/BrawtaStudentCard";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import useTablePagination from "../../../Hooks/TablePaginationHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { dFlex, flexCenter } from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { BatchEmpty } from "../../../const/imageUrl";
import { RegisterIcon } from "../../../images/icons";

export default function ParentBrawtaChallenge() {
  const navigate = useNavigate();
  const [url, setUrl] = useState(null);
  const [batchData, fetchBatchData, dispatchData] = useDataFetchHook(url);
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();

  useEffect(() => {
    const params = new URLSearchParams();
    const newUrl =
      process.env.REACT_APP_PARENT_STUDENT_LIST +
      "?filterCompleted=true" +
      params.toString();
    setUrl(newUrl);
    return () => {};
  }, []);

  const handleSearch = () => {
    // console.log("searhing");
    let arr = [...batchData?.res?.Student];
    arr = arr?.filter((e) => {
      return e?.studentName?.toLowerCase()?.includes(searchText.toLowerCase());
    });
    handleChangePage(null, 0);
    setSearchData(arr);
  };

  useEffect(() => {
    if (!searchText) {
      setSearchData(batchData?.res?.Student ?? []);
      return;
    }
    handleSearch();
  }, [searchText]);

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Brawta Challenge"]} />

      <Box>
        <Box
          sx={{
            mb: 3,
            alignItems: "center",
          }}
        >
          <FormTextField
            placeholder="search"
            disabled={batchData?.loading}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            value={searchText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    fontSize="small"
                    sx={{
                      color: "#BFBFBF",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {batchData?.loading && (
          <Box sx={{ ...flexCenter, pb: 2 }}>
            <CircularProgress />
          </Box>
        )}
        {batchData?.res?.Student?.length < 1 && (
          <Box
            sx={{
              mt: 3,
              backgroundColor: (theme) => theme.palette.background.secondary,
              ...flexCenter,
              flexDirection: "column",
              py: 8,
              gap: 5,
              borderRadius: "12px",
            }}
          >
            <img src={BatchEmpty} style={{ height: "220px", width: "600px" }} />
            <Typography
              variant="bold"
              sx={{ color: (theme) => theme.palette.text.light }}
            >
              You have not added any students yet
            </Typography>
            <Button
              onClick={() => {
                navigate("/parent/students/add-student");
              }}
              variant="primary"
            >
              <RegisterIcon /> Add Student
            </Button>
          </Box>
        )}
        {!searchText && (
          <Box sx={{ ...dFlex, flexWrap: "wrap", gap: 2 }}>
            {batchData?.res?.Student?.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )?.map((e) => (
              <BrawtaStudentCard data={e} key={e.id} />
            ))}
          </Box>
        )}

        {searchText && (
          <Box sx={{ ...dFlex, flexWrap: "wrap", gap: 2 }}>
            {searchData
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((e, i) => (
                <BrawtaStudentCard key={e?.id} data={e} index={i} />
              ))}
          </Box>
        )}
      </Box>

      {batchData?.res?.Student?.length >= 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            rowsPerPageOptions={[]}
            count={searchText ? searchData?.length : batchData?.res?.totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
}
