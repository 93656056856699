import styled from "@emotion/styled";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { AccordionDetails, Box, Typography } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import React from "react";
import { AccordionTransition } from "../../const/constFunctions";
export const Accordion = styled((props) => <MuiAccordion {...props} />)(
  ({ theme }) => ({
    boxShadow: "none",
    border: "1px solid #BFBFBF",
    "& .Mui-expanded": {
      backgroundColor: "#E0F1F1",
    },
  })
);
export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "22px", color: props.iconColor }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  borderRadius: "8px",
  height: "39px",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    marginLeft: theme.spacing(1),
  },
}));
export default function ModuleTest({ data }) {
  return (
    <Accordion TransitionProps={AccordionTransition} sx={{ my: 1 }}>
      <AccordionSummary
        sx={
          {
            // border: "none",
            // backgroundColor: (theme) => theme.palette.primary.main,
          }
        }
      >
        <Typography
          variant="bold2"
          sx={{ color: (theme) => theme.palette.text.main }}
        >
          {data.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: "16px 40px" }}>
        <Box>Lorem ipsum dolor sit.</Box>
      </AccordionDetails>
    </Accordion>
  );
}
