import { Box, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import StudentProgressTab from "../../../Components/Facilitator/StudentProgress/StudentProgressTab";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { flexCenter } from "../../../common/commonStyles";
import { BatchEmpty } from "../../../const/imageUrl";
import { TabPanel } from "../../Admin/Dashboard/SchoolSubscribers";

export default function StudentProgress() {
  const [tabs, setTabs] = useState([]);
  const [value, setValue] = useState(0);
  const [moduleDetails, setModuleDetails] = useState([]);
  const [moduleDataApi] = useDataFetchHook(
    process.env.REACT_APP_PUBLISHED_MODULE + "1"
  );
  const url =
    process.env.REACT_APP_SCHOOL_FACILITATOR +
    `/batchlist?filterCompleted=true`;

  const [apiData] = useDataFetchHook(url);
  // Event handler for tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!apiData?.res) {
      return;
    }
    setTabs(apiData?.res?.batch);
    return () => {
      setTabs([]);
    };
  }, [apiData?.res]);

  useEffect(() => {
    if (!moduleDataApi?.res) {
      return;
    }
    // console.log(moduleDataApi?.res?.sequence);

    setModuleDetails(
      moduleDataApi?.res?.sequence?.map((e) => {
        return {
          label: e.moduleName,
          value: e.moduleId,
        };
      })
    );

    return () => {
      setModuleDetails([]);
    };
  }, [moduleDataApi]);
  // console.log({ moduleDetails });
  return (
    <Box>
      <NavBreadCrumbs pageTags={["Student Progress"]} />
      <Box>
        {/* Tabs for current and previous subscribers */}

        {(apiData?.loading || moduleDataApi?.loading) && (
          <Box sx={{ ...flexCenter }}>
            <CircularProgress />
          </Box>
        )}

        {apiData?.res?.batch?.length < 1 && (
          <Box
            sx={{
              mt: 3,
              backgroundColor: (theme) => theme.palette.background.secondary,
              ...flexCenter,
              flexDirection: "column",
              py: 8,
              gap: 5,
              borderRadius: "12px",
            }}
          >
            <img src={BatchEmpty} style={{ height: "220px", width: "600px" }} />
            <Typography
              variant="bold"
              sx={{ color: (theme) => theme.palette.text.light }}
            >
              No Batches to show
            </Typography>
          </Box>
        )}

        {tabs?.length > 0 && (
          <Box
            sx={{
              width: "100%",
              mt: 3,
              borderBottom: `1px solid #BFBFBF`,
            }}
          >
            <Tabs value={value} onChange={handleChange} variant="fullWidth">
              {tabs?.map((e, i) => (
                <Tab
                  sx={{
                    textTransform: "none",
                  }}
                  label={
                    <Typography variant={value == i ? "bold2" : "medium"}>
                      {e?.batchName}
                    </Typography>
                  }
                  value={i}
                />
              ))}
            </Tabs>
          </Box>
        )}
        {/* Tab panels */}
        {tabs?.map((e, i) => (
          <TabPanel value={value} index={i}>
            <StudentProgressTab moduleDetails={moduleDetails} data={e} />
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
}
