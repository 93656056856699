import { Box, Button, IconButton, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { PATCH, POST } from "../../../Apis/constVariables";
import {
  flexCenter,
  itemsCenter,
  textEllipses,
} from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { checkSize, convertToBase64File } from "../../../const/constFunctions";
import { AddIconBig, RemoveIconBig } from "../../../images/icons";
import { validateBasicQuestionForm } from "../CreateQuestion";
import "../styles/createQuiz.css";

const initData = [
  {
    label: "",
    pic: null,
    base64: null,
  },
  {
    label: "",
    pic: null,
    base64: null,
  },
];
const initAnswer = 0;

const PictureMCQ = ({
  editAble,
  questionFormData,
  fetchQuizQuestionsAgain,
  toggleQuestionForm,
}) => {
  // console.log({ questionFormData });
  const [picMCQ, setPicMCQ] = useState(initData);
  const [answer, setAnswer] = useState(initAnswer);
  const [loading, setLoading] = useState(false);
  const handleOptionChange = (e) => {
    const { name, value } = e.target;
    setPicMCQ((prev) =>
      prev.map((prevValue, index) =>
        index === Number(name)
          ? { ...prevValue, label: value }
          : { ...prevValue }
      )
    );
  };

  const handlePicChange = async (e, i) => {
    try {
      if (e?.target?.files.length > 0) {
        let file = e.target.files[0];
        if (!file.type.includes("image")) {
          e.target.value = "";
          toast.error("Please upload an image file");
          return;
        }
        if (checkSize(file) > 10) {
          e.target.value = "";
          toast.error("Please upload an image less than 10MB");
          return;
        }
        const base64 = await convertToBase64File(file);
        setPicMCQ((prev) =>
          prev.map((prevValue, index) =>
            index === Number(i)
              ? { ...prevValue, pic: file, base64: base64 }
              : { ...prevValue }
          )
        );
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleAdd = () => {
    setPicMCQ((prevOption) => [
      ...prevOption,
      {
        label: "",
        pic: "",
      },
    ]);
  };

  const handleRemove = (id) => {
    setPicMCQ((prevOptions) => prevOptions.filter((_, index) => index !== id));
  };

  function submit(e) {
    e.preventDefault();
  }

  const addData = async () => {
    try {
      const formValidator = validateBasicQuestionForm(questionFormData);
      if (formValidator?.error) {
        toast.error(formValidator.message);
        return;
      }
      let nsn = questionFormData.questionName.replaceAll(" ", "");

      let dat = /\//i.test(nsn);
      if (!dat) {
      } else {
        toast.error("You can not use any (/) characters");
        return;
      }
      let err = false;
      picMCQ?.forEach((e) => {
        if (!e?.label?.trim() || !e?.base64) {
          err = true;
        }
      });
      if (err) {
        toast.error("Fields cannot be empty");
        return;
      }

      setLoading(true);
      const url = process.env.REACT_APP_QUESTION;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...questionFormData,
          data: { options: picMCQ, correctOption: answer },
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchQuizQuestionsAgain();
      toggleQuestionForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const updateData = async () => {
    try {
      const formValidator = validateBasicQuestionForm(questionFormData);
      if (formValidator?.error) {
        toast.error(formValidator.message);
        return;
      }
      let nsn = questionFormData.questionName.replaceAll(" ", "");

      let dat = /\//i.test(nsn);
      if (!dat) {
      } else {
        toast.error("You can not use any (/) characters");
        return;
      }
      let err = false;
      picMCQ?.forEach((e) => {
        if (!e?.label?.trim() || !e?.base64) {
          err = true;
        }
      });
      if (err) {
        toast.error("Fields cannot be empty");
        return;
      }
      setLoading(true);
      const url =
        process.env.REACT_APP_QUESTION + `/${questionFormData?.questionId}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: {
          ...questionFormData,
          data: { options: picMCQ, correctOption: answer },
        },
      });
      console.log({ config });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchQuizQuestionsAgain();
      toggleQuestionForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (!questionFormData?.data) {
      return;
    }

    let picMCQ = questionFormData?.data?.options;
    setPicMCQ(picMCQ);
    setAnswer(questionFormData?.data?.correctOption);
    return () => {
      setPicMCQ(initData);
      setAnswer(initAnswer);
    };
  }, [questionFormData?.data]);

  return (
    <section className="quizForm-container">
      <form onSubmit={submit}>
        {picMCQ?.map((ele, index) => (
          <Box className="quizForm-mainForm-infoRow" key={index + 1}>
            <label className="quizForm-mainForm-label">
              Option {index + 1} :
            </label>
            <FormTextField
              name={index}
              value={ele?.label}
              placeholder={"Option " + (index + 1)}
              onChange={handleOptionChange}
            />
            <Box
              sx={{
                ...itemsCenter,
                gap: "10px",
                ml: 2,
                borderRadius: "7px",
                pr: "10px",
                border: "1px solid #BFBFBF",
                maxWidth: "40%",
              }}
            >
              <Box
                component={"label"}
                sx={{
                  ...flexCenter,
                  height: "35px",
                  width: "125px",
                  cursor: "pointer",
                  borderRadius: "4px",
                  color: (theme) => theme.palette.text.main,
                  backgroundColor: (theme) => theme.palette.text.lighter,
                }}
              >
                Choose a file
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handlePicChange(e, index)}
                  style={{ display: "none" }}
                />
              </Box>
              <Typography
                variant="regular"
                // title={
                //   picImage?.[`option${index}`]
                //     ? picImage?.[`option${index}`]?.name
                //     : "No File Chosen"
                // }
                sx={{
                  color: (theme) => theme.palette.text.main,
                  ...textEllipses,
                }}
              >
                {ele?.pic ? ele?.pic?.name : "No Image Chosen"}
                {/* {picImage?.[`option${index}`]
                  ? picImage?.[`option${index}`]?.name
                  : "No File Chosen"} */}
              </Typography>
            </Box>
            {index > picMCQ.length - 2 && index < 4 && (
              <IconButton onClick={handleAdd}>
                <AddIconBig />
              </IconButton>
            )}
            {index > 1 && (
              <IconButton onClick={() => handleRemove(index)}>
                <RemoveIconBig />
              </IconButton>
            )}
          </Box>
        ))}
        <Box className="quizForm-mainForm-infoRow">
          <label className="quizForm-mainForm-label">
            Choose the correct Option :
          </label>
          <select
            className="quizForm-mainForm-select"
            value={answer}
            onChange={(e) => {
              setAnswer(e.target.value);
            }}
          >
            {picMCQ?.map((_, index) => (
              <option key={index} value={index}>
                Option {index + 1}
              </option>
            ))}
          </select>
        </Box>
      </form>
      <Box className="quizForm-mainForm-infoRow">
        <Button
          sx={{
            textTransform: "none",
            ml: "auto",
          }}
          onClick={toggleQuestionForm}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          variant="primary-small"
          onClick={editAble ? updateData : addData}
        >
          Save
        </Button>
      </Box>
    </section>
  );
};

export default PictureMCQ;
