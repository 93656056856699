import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { TabPanel } from "../../Admin/Dashboard/SchoolSubscribers";
import ModuleElement from "./CourseOverview/ModuleElement";
import PrePostQuizTab from "./PrePostQuizTab";

export default function ParentModuleAnswers() {
  const [value, setValue] = useState(1);

  // Event handler for tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [userInfo, setUserInfo] = useState({ studentName: "" });
  const [moduleInfo, setModuleInfo] = useState({ moduleName: "" });
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    const state = location?.state ?? {};
    setUserInfo(state?.studentInfo ?? { studentName: "" });
    setModuleInfo(state?.moduleData ?? { moduleName: "" });
  }, [location.state]);

  return (
    <Box>
      <NavBreadCrumbs
        pageTags={[
          "My Student",
          userInfo?.studentName,
          moduleInfo?.moduleName + " - Check Test Answer",
        ]}
      />
      <Typography
        variant="bold"
        sx={{ color: (theme) => theme.palette.primary.main }}
      >
        {moduleInfo?.name}
      </Typography>
      <Box
        sx={{
          width: "100%",
          mt: 2,
          borderBottom: `1px solid #BFBFBF`,
        }}
      >
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          <Tab
            sx={{
              textTransform: "none",
            }}
            label={
              <Typography variant={value == 1 ? "bold2" : "medium"}>
                Pre Test
              </Typography>
            }
            value={1}
          />
          <Tab
            sx={{
              textTransform: "none",
            }}
            label={
              <Typography variant={value == 2 ? "bold2" : "medium"}>
                In Module
              </Typography>
            }
            value={2}
          />
          <Tab
            sx={{
              textTransform: "none",
            }}
            label={
              <Typography variant={value == 3 ? "bold2" : "medium"}>
                Post Test
              </Typography>
            }
            value={3}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={1}>
        <PrePostQuizTab
          stdId={userInfo?.id}
          moduleId={moduleInfo?.moduleId}
          quizData={moduleInfo?.prequiz}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box>
          <ModuleElement stdId={userInfo?.id} data={moduleInfo} />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PrePostQuizTab
          stdId={userInfo?.id}
          moduleId={moduleInfo?.moduleId}
          quizData={moduleInfo?.postquiz}
        />{" "}
      </TabPanel>
    </Box>
  );
}
