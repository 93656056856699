import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TablePagination,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import useTablePagination from "../../../Hooks/TablePaginationHook";
// import DownloadSelect from "../../../common/DownloadSelect";
import {
  dFlex,
  flexBetweenCenter,
  flexCenter,
} from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { reactSelectTheme } from "../../../const/constFunctions";
import { BatchEmpty } from "../../../const/imageUrl";
import { FilterIcon, RegisterIcon } from "../../../images/icons";
import StudentCard from "./StudentCard";
export default function StudentProgressTab({ data, moduleDetails }) {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const url = `${process.env.REACT_APP_SCHOOL_GET_STUDENT_BATCH}${data.batchName}&offset=0&limit=50`;

  const [studentApiCall] = useDataFetchHook(url);

  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();

  // const data1 = new Array(25).fill(1);

  const handleSearch = () => {
    try {
      // console.log("searhing");
      let arr = [...studentApiCall?.res?.Student];
      arr = arr?.filter((e) => {
        return e?.studentName
          ?.toLowerCase()
          ?.includes(searchText.toLowerCase());
      });
      handleChangePage(null, 0);
      setSearchData(arr);
    } catch (error) {
      console.log(error);
    }
  };
  const handleFilter = () => {
    try {
      console.log("filtering");
      let arr = [...studentApiCall?.res?.Student];
      arr = arr?.filter((e) => {
        return e?.currentModuleId === selectedModule.value;
      });
      console.log(arr);
      handleChangePage(null, 0);
      setSearchData(arr);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClearFilter = () => {
    setSearchText("");
    setSelectedModule(null);
    setSearchData(studentApiCall?.res?.Student ?? []);
  };

  useEffect(() => {
    if (!searchText) {
      setSearchData(studentApiCall?.res?.Student);
      return;
    }
    handleSearch();
  }, [searchText]);
  useEffect(() => {
    if (!studentApiCall?.res) {
      return;
    }
    setSearchData(studentApiCall?.res?.Student);
  }, [studentApiCall?.res]);

  return (
    <Box>
      <Box sx={{ ...flexBetweenCenter, mb: 3 }}>
        <Box
          sx={{
            ...dFlex,
            gap: 2,
            alignItems: "center",
            boxSizing: "border-box",
          }}
        >
          <FormTextField
            placeholder="search"
            // sx={{ width: "400px" }}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            value={searchText}
            disabled={studentApiCall?.loading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    fontSize="small"
                    sx={{
                      color: "#BFBFBF",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Select
            options={moduleDetails}
            theme={reactSelectTheme}
            isDisabled={studentApiCall?.loading}
            isClearable={true}
            value={selectedModule}
            onChange={(e) => {
              setSelectedModule(e);
            }}
            maxMenuHeight={150}
            // styles={{
            //   control: (provided) => ({
            //     ...provided,
            //     minWidth: "200px",
            //   }),
            // }}
            placeholder="Select Module"
          />
          <Box>
            <Button onClick={handleFilter} variant="primary-medium">
              <FilterIcon /> Filter
            </Button>
            <Button
              onClick={() => {
                setSearchText("");
                setSelectedModule(null);
                setSearchData(studentApiCall?.res?.Student);
              }}
              sx={{
                textTransform: "none",
                color: (theme) => theme.palette.text.main,
              }}
            >
              {" "}
              x Clear
            </Button>
          </Box>
        </Box>
        {/* <DownloadSelect /> */}
      </Box>
      {studentApiCall?.res?.Student?.length < 1 && (
        <Box
          sx={{
            mt: 3,
            backgroundColor: (theme) => theme.palette.background.secondary,
            ...flexCenter,
            flexDirection: "column",
            py: 8,
            gap: 5,
            borderRadius: "12px",
          }}
        >
          <img src={BatchEmpty} style={{ height: "220px", width: "600px" }} />
          <Typography
            variant="bold"
            sx={{ color: (theme) => theme.palette.text.light }}
          >
            You have not added any students yet
          </Typography>
          <Button
            onClick={() => {
              navigate("/facilitator/manage-student/add-student");
            }}
            variant="primary"
          >
            <RegisterIcon /> Add Student
          </Button>
        </Box>
      )}

      {studentApiCall?.loading && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />{" "}
        </Box>
      )}

      {/* {!searchText && (
        <Box>
          {studentApiCall?.res?.Student?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          ).map((e) => (
            <StudentCard data={e} key={e?.id} />
          ))}
        </Box>
      )} */}
      {/* {searchText && ( */}
      <Box>
        {searchData
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((e) => (
            <StudentCard data={e} key={e?.id} />
          ))}
      </Box>
      {/* )} */}
      {studentApiCall?.res?.Student?.length >= 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            rowsPerPageOptions={[]}
            count={
              searchText
                ? searchData.length
                : studentApiCall?.res?.Student?.length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
}
