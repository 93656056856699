import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { PATCH, POST } from "../../../Apis/constVariables";
import { itemsCenter } from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { AddIconBig, DeleteIcon, RemoveIconBig } from "../../../images/icons";
import { validateBasicQuestionForm } from "../CreateQuestion";
import "../styles/createQuiz.css";

const DropDown = ({
  editAble,
  questionFormData,
  fetchQuizQuestionsAgain,
  toggleQuestionForm,
}) => {
  const initCases = [
    {
      case: "",
      options: ["", ""],
      answer: "",
    },
  ];
  const [cases, setCases] = useState(initCases);
  const [loading, setLoading] = useState(false);
  const handleAddCase = () => {
    setCases([
      ...cases,
      {
        case: "",
        options: ["", ""],
        answer: "",
      },
    ]);
  };
  const handleRemoveCase = (id) => {
    let abc = [...cases];
    abc = abc.filter((e, i) => {
      return i !== id;
    });
    setCases(abc);
  };
  const handleAddOptionInCase = (arr, id, setState) => {
    let temp = [...arr];
    let caseObj = temp[id];
    caseObj?.options?.push("");
    temp[id] = caseObj;
    setState(temp);
  };
  const handleRemoveOptionInCase = (arr, id, toRemove, setState) => {
    let temp = [...arr];
    let caseObj = temp[id];
    caseObj.options = caseObj?.options?.filter((_, i) => i !== toRemove);
    temp[id] = caseObj;
    setState(temp);
  };

  const handleCaseInput = (e, i) => {
    const { value } = e.target;
    setCases((prev) =>
      prev.map((prevValue, index) =>
        index === i ? { ...prevValue, case: value } : { ...prevValue }
      )
    );
  };
  const handleCaseAnswerInput = (e, i) => {
    const { value } = e.target;
    setCases((prev) =>
      prev.map((prevValue, index) =>
        index === i ? { ...prevValue, answer: value } : { ...prevValue }
      )
    );
  };

  const handleCaseOptionInput = (
    arr,
    arrIndex,
    toAddIndex,
    event,
    setState
  ) => {
    setState((prevState) =>
      prevState?.map((prevValue, index) => {
        if (index !== arrIndex) {
          return prevValue;
        } else {
          prevValue.options[toAddIndex] = event.target.value;
          return prevValue;
        }
      })
    );
  };
  function submit(e) {
    e.preventDefault();
  }

  const addData = async () => {
    try {
      const formValidator = validateBasicQuestionForm(questionFormData);
      if (formValidator?.error) {
        toast.error(formValidator.message);
        return;
      }
      let nsn = questionFormData.questionName.replaceAll(" ", "");

      let dat = /\//i.test(nsn);
      if (!dat) {
      } else {
        toast.error("You can not use any (/) characters");
        return;
      }

      let err = false;
      cases?.forEach((e) => {
        if (!e?.case?.trim() || !`${e?.answer}`.trim()) {
          err = true;
        } else {
          e?.options?.forEach((e) => {
            if (!e.trim()) {
              err = true;
            }
          });
        }
      });

      if (err) {
        toast.error("Fields cannot be empty");
        return;
      }

      setLoading(true);
      const url = process.env.REACT_APP_QUESTION;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...questionFormData,
          data: { options: cases },
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      setCases(initCases);
      fetchQuizQuestionsAgain();
      toggleQuestionForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const updateData = async () => {
    try {
      const formValidator = validateBasicQuestionForm(questionFormData);
      if (formValidator?.error) {
        toast.error(formValidator.message);
        return;
      }
      let nsn = questionFormData.questionName.replaceAll(" ", "");

      let dat = /\//i.test(nsn);
      if (!dat) {
      } else {
        toast.error("You can not use any (/) characters");
        return;
      }
      let err = false;
      cases?.forEach((e) => {
        if (!e?.case?.trim() || !`${e?.answer}`.trim()) {
          err = true;
        } else {
          e?.options?.forEach((e) => {
            if (!e.trim()) {
              err = true;
            }
          });
        }
      });

      if (err) {
        toast.error("Fields cannot be empty");
        return;
      }
      setLoading(true);
      const url =
        process.env.REACT_APP_QUESTION + `/${questionFormData?.questionId}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: {
          ...questionFormData,
          data: { options: cases },
        },
      });
      console.log({ config });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchQuizQuestionsAgain();
      toggleQuestionForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (!questionFormData?.data) {
      return;
    }
    setCases(questionFormData?.data?.options);
    return () => {
      setCases(initCases);
    };
  }, [questionFormData?.data]);

  return (
    <section className="quizForm-container">
      <form onSubmit={submit}>
        {cases?.map((e, i) => (
          <>
            <Box className="quizForm-mainForm-infoRow">
              <label className="quizForm-mainForm-label">Case {i + 1}:</label>
              <FormTextField
                className="quizForm-mainForm-inputBar"
                value={e?.case}
                onChange={(e) => handleCaseInput(e, i)}
              />
              {i > 0 && (
                <IconButton onClick={() => handleRemoveCase(i)}>
                  <DeleteIcon fill={"#358b8a"} />
                </IconButton>
              )}
              {i > cases.length - 2 && i < 3 && (
                <Button
                  onClick={handleAddCase}
                  variant="outlined"
                  sx={{
                    ml: 1,
                    p: "1px 5px",
                    textTransform: "none",
                  }}
                >
                  Add Case
                </Button>
              )}
            </Box>
            {e?.options?.map((optionVal, indx) => (
              <Box
                className="quizForm-mainForm-infoRow"
                sx={{ ...itemsCenter }}
              >
                <>
                  <label className="quizForm-mainForm-label">
                    Option {indx + 1} :
                  </label>
                  <FormTextField
                    className="quizForm-mainForm-inputBar"
                    name="text"
                    value={optionVal}
                    onChange={(e) =>
                      handleCaseOptionInput(cases, i, indx, e, setCases)
                    }
                  />
                  {indx > e?.options?.length - 2 && indx < 6 && (
                    <IconButton
                      onClick={() => {
                        handleAddOptionInCase(cases, i, setCases);
                      }}
                    >
                      <AddIconBig />
                    </IconButton>
                  )}
                  {indx > 1 && (
                    <IconButton
                      onClick={() =>
                        handleRemoveOptionInCase(cases, i, indx, setCases)
                      }
                    >
                      <RemoveIconBig />
                    </IconButton>
                  )}
                </>
              </Box>
            ))}
            <Box className="quizForm-mainForm-infoRow">
              <label className="quizForm-mainForm-label">
                Choose the correct Option :
              </label>
              <Select
                value={e?.answer}
                sx={{
                  width: "34%",
                  height: "36px",
                }}
                onChange={(e) => handleCaseAnswerInput(e, i)}
              >
                {e?.options?.map((e, i) => (
                  <MenuItem value={i}>
                    <Typography
                      variant="medium"
                      sx={{
                        color: (theme) => theme.palette.text.main,
                      }}
                    >
                      Option {i + 1}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </>
        ))}
      </form>
      <Box className="quizForm-mainForm-infoRow">
        <Button
          sx={{
            textTransform: "none",
            ml: "auto",
          }}
          onClick={toggleQuestionForm}
        >
          Cancel
        </Button>
        <Button
          variant="primary-small"
          disabled={loading}
          onClick={editAble ? updateData : addData}
        >
          Save
        </Button>
      </Box>
    </section>
  );
};

export default DropDown;
