import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { dFlex, flexSpaceBetween } from "../../../../common/commonStyles";

export default function ModulePicMCQCard({ data, index }) {
  return (
    <Box
      sx={{
        my: 3,
        px: 3,
        borderRadius: "15px",
      }}
    >
      <Box sx={{ mb: 1, ...flexSpaceBetween, gap: 8 }}>
        <Typography
          variant="medium"
          sx={{
            flex: "80%",
            color: (theme) => theme.palette.text.main,
          }}
        >
          {index + 1}. {data?.question}
        </Typography>
      </Box>

      <RadioGroup value={data?.stdAnswer}>
        {data?.data?.options?.map((e, i) => (
          <FormControlLabel
            key={i}
            sx={{
              my: 1,
              backgroundColor: (theme) => theme.palette.background.secondary,
              borderRadius: "6px",
            }}
            // disabled
            control={<Radio />}
            value={i}
            label={
              <Typography
                variant="medium"
                sx={{
                  color: (theme) => theme.palette.text.main,
                  ...dFlex,
                  alignItems: "center",
                  py: 1,
                }}
              >
                <img
                  src={e?.pic}
                  style={{
                    borderRadius: "5px",
                    marginRight: "16px",
                    height: "70px",
                    width: "100px",
                  }}
                />
                {e.label}
              </Typography>
            }
          />
        ))}
      </RadioGroup>
    </Box>
  );
}
