import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import SuspenseLoading from "../common/SuspenseLoading";

import CategoryFilesPage from "../Components/Admin/Content/MedzGym/CategoryFilesPage";
import ParentStudentInfoTable from "../Components/Admin/Dashboard/ParentStudentInfoTable";
import AdminSkeleton from "../Pages/Admin/AdminSkeleton";
import Certification from "../Pages/Admin/Certification/Certification";
import GenerateCertificate from "../Pages/Admin/Certification/GenerateCertificate";
import ChangeAdmin from "../Pages/Admin/ChangeAdmin/ChangeAdmin";
import Content from "../Pages/Admin/Content/Content";
import CourseBuilder from "../Pages/Admin/Content/CourseBuilder";
import AdminDashboard from "../Pages/Admin/Dashboard/AdminDashboard";
import AllStudents from "../Pages/Admin/Dashboard/AllStudents";
import CancelSubscription from "../Pages/Admin/Dashboard/CancelSubscription";
import DeleteAccount from "../Pages/Admin/Dashboard/DeleteAccount";
import NewSubscriber from "../Pages/Admin/Dashboard/NewSubscriber";
import OfflineRegistrationRequest from "../Pages/Admin/Dashboard/OfflineRegistrationRequest";
import ParentInfoData from "../Pages/Admin/Dashboard/ParentInfoData";
import ParentSubscribers from "../Pages/Admin/Dashboard/ParentSubscribers";
import SchoolAdminInfo from "../Pages/Admin/Dashboard/SchoolAdminInfo";
import SchoolFacilitatorInfoData from "../Pages/Admin/Dashboard/SchoolFacilitatorInfoData";
import SchoolFacilitatorInformation from "../Pages/Admin/Dashboard/SchoolFacilitatorInformation";
import SchoolStudentInformation from "../Pages/Admin/Dashboard/SchoolStudentInformation";
import SchoolSubscriberData from "../Pages/Admin/Dashboard/SchoolSubscriberData";
import SchoolSubscribers from "../Pages/Admin/Dashboard/SchoolSubscribers";
import StudentInfoDetails from "../Pages/Admin/Dashboard/StudentInfoDetails";
import StudentsEarnedCertificates from "../Pages/Admin/Dashboard/StudentsEarnedCertificates";
import ACETestPage from "../Pages/Admin/DemographicData/ACETestPage";
import DemographicData from "../Pages/Admin/DemographicData/DemographicData";
import ModulePage from "../Pages/Admin/DemographicData/ModulePage";
import SDQTestPage from "../Pages/Admin/DemographicData/SDQTestPage";
import FAQ from "../Pages/Admin/FAQ/FAQ";
import Questionnaires from "../Pages/Admin/RiskProfiling/Questionnaires";
import RiskProfiling from "../Pages/Admin/RiskProfiling/RiskProfiling";
import RiskProfilingIndividualOrg from "../Pages/Admin/RiskProfiling/RiskProfilingIndividualOrg";
import AccountDetails from "../Pages/Admin/Transactions/AccountDetails";
import AllTransactions from "../Pages/Admin/Transactions/AllTransactions";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import Login from "../Pages/Auth/Login";
import Register from "../Pages/Auth/Register";
import ResetPassword from "../Pages/Auth/ResetPassword";
import Sponsor from "../Pages/Auth/Sponsor";
import ContentUploaderSkeleton from "../Pages/ContentUplaoder/ContentUploaderSkeleton";
import GamesPage from "../Pages/ContentUplaoder/GamesPage";
import IntroMessagePage from "../Pages/ContentUplaoder/IntroMessagePage";
import MedzGymPage from "../Pages/ContentUplaoder/MedzGymPage";
import ModulesPage from "../Pages/ContentUplaoder/ModulesPage";
import UploaderCourseBuilder from "../Pages/ContentUplaoder/UploaderCourseBuilder";
import UploaderDashboard from "../Pages/ContentUplaoder/UploaderDashboard";
import Error404Page from "../Pages/Error404Page";
import FacilitatorBrawtaChallenge from "../Pages/Facilitator/BrawtaChallenge/FacilitatorBrawtaChallenge";
import FacilitatorCommunityProject from "../Pages/Facilitator/Community Project/FacilitatorCommunityProject";
import FacilitatorProjectDetails from "../Pages/Facilitator/Community Project/FacilitatorProjectDetails";
import FacilitatorCommunityChannel from "../Pages/Facilitator/CommunityChannel/FacilitatorCommunityChannel";
import FacilitatorDashboard from "../Pages/Facilitator/Dashboard/FacilitatorDashboard";
import FacilitatorLeaderBoardSeeAll from "../Pages/Facilitator/Dashboard/LeaderBoardSeeAll";
import FacilitatorNoProgressList from "../Pages/Facilitator/Dashboard/NoProgressList";
import FacilitatorSkeleton from "../Pages/Facilitator/FacilitatorSkeleton";
import AnswerRiskProfiling from "../Pages/Facilitator/ManageStudent/AnswerRiskProfiling";
import ArchivedStudentDetails from "../Pages/Facilitator/ManageStudent/ArchivedStudentDetails";
import CreateGroup from "../Pages/Facilitator/ManageStudent/CreateGroup";
import FacilitatorAddStudent from "../Pages/Facilitator/ManageStudent/FacilitatorAddStudent";
import ManageStudent from "../Pages/Facilitator/ManageStudent/ManageStudent";
import ModuleAnswers from "../Pages/Facilitator/StudentProgress/ModuleAnswers";
import FacilitatorRiskProfiling from "../Pages/Facilitator/StudentProgress/RiskProfiling";
import StudentDetails from "../Pages/Facilitator/StudentProgress/StudentDetails";
import StudentProgress from "../Pages/Facilitator/StudentProgress/StudentProgress";
import UserManual from "../Pages/Facilitator/UserManual/UserManual";
import HomePage from "../Pages/HomePage/HomePage";
import NormalFAQ from "../Pages/NormalFAQ";
import BrawtaChallengeDetails from "../Pages/Parent/BrawtaChallenge/BrawtaChallengeDetails";
import ParentBrawtaChallenge from "../Pages/Parent/BrawtaChallenge/ParentBrawtaChallenge";
import StudentBrawtaDetails from "../Pages/Parent/BrawtaChallenge/StudentBrawtaDetails";
import ParentChatMediaInfo from "../Pages/Parent/CommunityChannel/ParentChatMediaInfo";
import ParentCommunityChannel from "../Pages/Parent/CommunityChannel/ParentCommunityChannel";
import ParentCommunityProject from "../Pages/Parent/CommunityProject/ParentCommunityProject";
import ParentLeaderBoardSeeAll from "../Pages/Parent/Dashboard/LeaderBoardSeeAll";
import ParentDashboard from "../Pages/Parent/Dashboard/ParentDashboard";
import ParentDownloadData from "../Pages/Parent/DownloadData/ParentDownloadData";
import ArchivedParentStudentDetails from "../Pages/Parent/ManageStudent/ArchivedParentStudentDetails";
import ParentAddStudent from "../Pages/Parent/ManageStudent/ParentAddStudent";
import MyStudent from "../Pages/Parent/MyStudents/MyStudent";
import ParentModuleAnswers from "../Pages/Parent/MyStudents/ParentModuleAnswers";
import ParentStudentDetails from "../Pages/Parent/MyStudents/ParentStudentDetails";
import ParentRiskProfiling from "../Pages/Parent/MyStudents/RiskProfiling";
import ParentSettings from "../Pages/Parent/ParentSettings";
import ParentSkeleton from "../Pages/Parent/ParentSkeleton";
import PrintRiskProfling from "../Pages/PrintRiskProfling";
import AddStudent from "../Pages/School/Batch/AddStudent";
import BatchArchivedStudentDetails from "../Pages/School/Batch/BatchArchivedStudentDetails";
import BatchDetails from "../Pages/School/Batch/BatchDetails";
import CompletedBatchDetails from "../Pages/School/Batch/CompletedBatchDetails";
import ManageBatch from "../Pages/School/Batch/ManageBatch";
import LeaderBoardSeeAll from "../Pages/School/Dashboard/LeaderBoardSeeAll";
import NoProgressList from "../Pages/School/Dashboard/NoProgressList";
import SchoolDashboard from "../Pages/School/Dashboard/SchoolDashboard";
import DownloadData from "../Pages/School/DownloadData/DownloadData";
import AddFacilitator from "../Pages/School/Facilitator/AddFacilitator";
import Facilitator from "../Pages/School/Facilitator/Facilitator";
import FacilitatorDetails from "../Pages/School/Facilitator/FacilitatorDetails";
import AddMeeting from "../Pages/School/Meeting/AddMeeting";
import Meetings from "../Pages/School/Meeting/Meetings";
import UpdateMeeting from "../Pages/School/Meeting/UpdateMeeting";
import ProgramContent from "../Pages/School/ProgramContent/ProgramContent";
import SchoolSkeleton from "../Pages/School/SchoolSkeleton";
import SchoolSettings from "../Pages/School/Settings/SchoolSettings";
import TOS from "../Pages/TOS";
import Temp from "../Pages/Temp/Temp";

function Router() {
  return (
    <Suspense fallback={<SuspenseLoading />}>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<HomePage />} loader={<SuspenseLoading />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/sponsor" element={<Sponsor />} />

        {/* Admin routes */}
        <Route path="/admin" element={<AdminSkeleton />}>
          {/* Dashboard Routes */}
          <Route path="dashboard">
            <Route path="" element={<AdminDashboard />} />
            <Route path="cancel-subscription">
              <Route path="" element={<CancelSubscription />} />
              <Route
                path=":schoolId"
                element={<AccountDetails from={"cancel"} />}
              />
            </Route>
            <Route path="delete-account">
              <Route path="" element={<DeleteAccount />} />
              <Route
                path=":schoolId"
                element={<AccountDetails from={"delete"} />}
              />
            </Route>
            <Route path="school-subscribers">
              <Route path="" element={<SchoolSubscribers />} />
              <Route path=":schoolId">
                <Route path="" element={<SchoolSubscriberData />} />
                <Route path="admin" element={<SchoolAdminInfo />} />
                <Route
                  path="student-information"
                  element={<SchoolStudentInformation />}
                />
                <Route
                  path="student-information/:studentId"
                  element={<StudentInfoDetails />}
                />
                <Route
                  path="facilitator-information"
                  element={<SchoolFacilitatorInformation />}
                />
                <Route
                  path="facilitator-information/:facilitatorId"
                  element={<SchoolFacilitatorInfoData />}
                />
              </Route>
            </Route>

            <Route path="parent-subscribers">
              <Route path="" element={<ParentSubscribers />} />
              <Route path=":parentId" element={<ParentInfoData />} />
              <Route
                path=":parentId/student-information"
                element={<ParentStudentInfoTable route={true} />}
              />
              <Route
                path=":parentId/student-information/:studentId"
                element={<StudentInfoDetails />}
              />
            </Route>
            <Route path="students-earned-certificates">
              <Route path="" element={<StudentsEarnedCertificates />} />
              <Route path=":studentId" element={<StudentInfoDetails />} />
            </Route>
            <Route path="new-subscribers" element={<NewSubscriber />} />
            <Route path="all-students">
              <Route path="" element={<AllStudents />} />
              <Route path=":studentId" element={<StudentInfoDetails />} />
            </Route>
            <Route path="offline-request">
              <Route path="" element={<OfflineRegistrationRequest />} />
              {/* <Route path=":studentId" element={<StudentInfoDetails />} /> */}
            </Route>
            {/* Add more dashboard routes here */}
          </Route>

          <Route path="transactions">
            <Route path="" element={<AllTransactions />} />
            {/* <Route
              path=":transactionId"
              element={<IndividualTransactionDetails />}
            /> */}
          </Route>
          <Route path="risk-profiling">
            <Route path="" element={<RiskProfiling />} />
            <Route path=":orgId" element={<RiskProfilingIndividualOrg />} />
            <Route path="questionnaire" element={<Questionnaires />} />
          </Route>
          <Route path="certificates" element={<Certification />} />
          <Route path="certificates/edit" element={<GenerateCertificate />} />
          <Route path="content">
            <Route path="" element={<Content />} />
            <Route path=":categoryId" element={<CategoryFilesPage />} />
            <Route path="builder" element={<CourseBuilder />} />
          </Route>
          <Route path="demographic-data">
            <Route path="" element={<DemographicData />} />
            <Route path="ace-test" element={<ACETestPage />} />
            <Route path="sdq-test" element={<SDQTestPage />} />
            <Route path=":moduleId" element={<ModulePage />} />
          </Route>
          <Route path="change-admin" element={<ChangeAdmin />} />
          <Route path="faq" element={<FAQ />} />
        </Route>

        <Route path="/uploader" element={<ContentUploaderSkeleton />}>
          <Route path="dashboard" element={<UploaderDashboard />} />
          <Route path="modules" element={<ModulesPage />} />
          <Route path="modules/builder" element={<UploaderCourseBuilder />} />
          <Route path="games" element={<GamesPage />} />
          <Route path="medz-gym">
            <Route path="" element={<MedzGymPage />} />
            <Route path=":categoryId" element={<CategoryFilesPage />} />
          </Route>
          <Route path="intro-messages" element={<IntroMessagePage />} />
        </Route>

        <Route path="/school" element={<SchoolSkeleton />}>
          <Route path="dashboard">
            <Route path="" element={<SchoolDashboard />} />
            <Route path="leader-board" element={<LeaderBoardSeeAll />} />
            {/* <Route path="good-progress" element={<GoodProgressList />} /> */}
            <Route path="no-progress" element={<NoProgressList />} />
          </Route>
          <Route path="manage-batch">
            <Route path="" element={<ManageBatch />} />
            <Route path=":batchId" element={<BatchDetails />} />
            <Route
              path=":batchId/archived"
              element={<BatchArchivedStudentDetails />}
            />
            <Route
              path=":batchId/:studentId"
              element={<StudentInfoDetails />}
            />
            <Route path=":batchId/add-student" element={<AddStudent />} />
            <Route
              path=":batchId/edit-student"
              element={<AddStudent edit={true} />}
            />
            <Route
              path="completed/:batchId"
              element={<CompletedBatchDetails />}
            />
            <Route path="add-student" element={<AddStudent />} />
          </Route>
          <Route path="facilitator">
            <Route path="" element={<Facilitator />} />
            <Route path=":facilitatorId" element={<FacilitatorDetails />} />
            <Route path="add" element={<AddFacilitator />} />
          </Route>
          <Route path="meetings">
            <Route path="" element={<Meetings />} />
            <Route path="add" element={<AddMeeting />} />
            <Route path="edit" element={<UpdateMeeting />} />
          </Route>
          <Route
            path="user-manual"
            element={<UserManual type={"school"} />}
            errorElement={<SuspenseLoading />}
          />
          <Route path="program-content" element={<ProgramContent />} />
          <Route path="settings" element={<SchoolSettings />} />
          <Route path="download-data" element={<DownloadData />} />
          <Route path="faq" element={<NormalFAQ />} />
        </Route>

        <Route path="/facilitator" element={<FacilitatorSkeleton />}>
          <Route path="dashboard">
            <Route path="" element={<FacilitatorDashboard />} />
            <Route
              path="leader-board"
              element={<FacilitatorLeaderBoardSeeAll />}
            />
            {/* <Route path="good-progress" element={<GoodProgressList />} /> */}
            <Route path="no-progress" element={<FacilitatorNoProgressList />} />
          </Route>{" "}
          <Route
            path="user-manual"
            element={<UserManual type={"facilitator"} />}
            errorElement={<SuspenseLoading />}
          />
          <Route path="student-progress">
            <Route path="" element={<StudentProgress />} />
            <Route path=":studentId" element={<StudentDetails />} />
            <Route
              path=":studentId/risk-profiling"
              element={<FacilitatorRiskProfiling />}
            />
            <Route path=":studentId/answers" element={<ModuleAnswers />} />
          </Route>
          <Route path="manage-student">
            <Route path="" element={<ManageStudent />} />
            <Route path="add-student" element={<FacilitatorAddStudent />} />
            <Route
              path="add-student/risk-profiling"
              element={<AnswerRiskProfiling from={"facilitator"} />}
            />
            <Route
              path="edit-student"
              element={<FacilitatorAddStudent edit={true} />}
            />
            <Route path="archived" element={<ArchivedStudentDetails />} />
            <Route path="create-group" element={<CreateGroup />} />
          </Route>
          <Route path="brawta-challenge">
            <Route path="" element={<FacilitatorBrawtaChallenge />} />
            <Route path=":id" element={<StudentBrawtaDetails />} />
            <Route path=":id/:brawtaId" element={<BrawtaChallengeDetails />} />
          </Route>
          <Route path="community-project">
            <Route path="" element={<FacilitatorCommunityProject />} />
            <Route path=":groupId" element={<FacilitatorProjectDetails />} />
          </Route>
          <Route path="program-content" element={<ProgramContent />} />
          <Route path="faq" element={<NormalFAQ />} />
          <Route path="community-channel">
            <Route path="" element={<FacilitatorCommunityChannel />} />
            <Route path="details" element={<ParentChatMediaInfo />} />
          </Route>
        </Route>

        <Route path="/parent" element={<ParentSkeleton />}>
          <Route path="dashboard">
            <Route path="" element={<ParentDashboard />} />
            <Route path="leader-board" element={<ParentLeaderBoardSeeAll />} />
            {/* <Route path="good-progress" element={<GoodProgressList />} />
            <Route path="no-progress" element={<NoProgressList />} /> */}
          </Route>{" "}
          <Route path="user-manual" element={<UserManual type={"parent"} />} />
          <Route path="brawta-challenge">
            <Route path="" element={<ParentBrawtaChallenge />} />
            <Route path=":id" element={<StudentBrawtaDetails />} />
            <Route path=":id/:brawtaId" element={<BrawtaChallengeDetails />} />
          </Route>
          <Route path="faq" element={<NormalFAQ />} />
          <Route path="program-content" element={<ProgramContent />} />
          <Route path="settings" element={<ParentSettings />} />
          <Route path="community-project">
            <Route path="" element={<ParentCommunityProject />} />
            <Route path=":groupId" element={<FacilitatorProjectDetails />} />
          </Route>
          <Route path="students">
            <Route path="" element={<MyStudent />} />
            <Route path=":studentId" element={<ParentStudentDetails />} />
            <Route
              path=":studentId/risk-profiling"
              element={<ParentRiskProfiling />}
            />
            <Route
              path=":studentId/answers"
              element={<ParentModuleAnswers />}
            />
            <Route path="add-student" element={<ParentAddStudent />} />
            <Route
              path="add-student/risk-profiling"
              element={<AnswerRiskProfiling from={"parent"} />}
            />
            <Route
              path="edit-student"
              element={<ParentAddStudent edit={true} />}
            />
            <Route path="archived" element={<ArchivedParentStudentDetails />} />
          </Route>
          <Route path="community-channel">
            <Route path="" element={<ParentCommunityChannel />} />
            <Route path="details" element={<ParentChatMediaInfo />} />
          </Route>
          <Route path="download-data" element={<ParentDownloadData />} />
        </Route>

        {/* Temp route */}
        <Route path="/temp" element={<Temp />} />
        <Route
          path="/risk-profiling/:studentId"
          element={<PrintRiskProfling />}
        />
        <Route path="/tos" element={<TOS />} />

        {/* 404 Error page */}
        <Route path="*" element={<Error404Page />} />
      </Routes>
    </Suspense>
  );
}

export default Router;
