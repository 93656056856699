import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
// import DownloadSelect from "../../../common/DownloadSelect";
import ACEScore from "../../../Components/Admin/RiskProfiling/IndividualOrgPages/ACEScore";
import OverallStudentRiskProfile from "../../../Components/Admin/RiskProfiling/IndividualOrgPages/Overall";
import SDQScore from "../../../Components/Admin/RiskProfiling/IndividualOrgPages/SDQScore";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { TabPanel } from "../Dashboard/SchoolSubscribers";

export default function RiskProfilingIndividualOrg() {
  const params = useParams();

  const [apiData] = useDataFetchHook(
    process.env.REACT_APP_SA_RISK_PROFILING_SUBSCRIBERS +
      `/students?schoolId=${params.orgId}`
  );

  const [orgData, setOrgData] = useState({ name: "" });

  const location = useLocation();

  useEffect(() => {
    const state = location?.state;
    setOrgData(state?.data ?? { name: "" });
  }, [location.state]);

  const tabs = [
    {
      label: "Overall",
      component: (
        <OverallStudentRiskProfile apiData={apiData} orgData={orgData} />
      ),
    },
    {
      label: "ACE Score",
      component: <ACEScore apiData={apiData} orgData={orgData} />,
    },
    {
      label: "SDQ Pre-Test",
      component: <SDQScore apiData={apiData} orgData={orgData} type={"pre"} />,
    },
    {
      label: "SDQ Post-Test",
      component: <SDQScore apiData={apiData} orgData={orgData} type={"post"} />,
    },
  ];

  const [value, setValue] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Risk Profiling", orgData?.name]} />

      <Box>
        {/* Tabs for current and previous subscribers */}
        <Box
          sx={{
            // width: "100%",
            mt: 5,
            borderBottom: `1px solid #BFBFBF`,
          }}
        >
          <Tabs
            variant="scrollable"
            allowScrollButtonsMobile
            scrollButtons="auto"
            value={value}
            onChange={handleChange}
          >
            {tabs?.map((e, i) => (
              <Tab
                sx={{
                  textTransform: "none",
                }}
                label={
                  <Typography variant={value === i + 1 ? "bold2" : "medium"}>
                    {e.label}
                  </Typography>
                }
                value={i + 1}
              />
            ))}
          </Tabs>
        </Box>

        {/* Tab panels */}
        {tabs?.map((e, i) => (
          <TabPanel value={value} index={i + 1}>
            {e?.component}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
}
