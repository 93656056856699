import { Box, IconButton } from "@mui/material";
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import useKeyboardShortcut from "../../Hooks/KeyboardShortcut";
import classes from "../../common/SideBarClasses.module.css";
import {
  flexBetweenWithItemsCenter,
  menuItemsCSS,
  sideNavbarLink,
  textEllipses,
} from "../../common/commonStyles";
import {
  DashboardIcon,
  MenuIcon,
  UploaderIntroIcon,
  UploaderMedzGymIcon,
  UploaderModuleIcon,
} from "../../images/icons";
// import Logo from "../../images/unuh logo.svg";
import Logo from "../../images/unuh-logo.png";
import { LogoutModal } from "../Admin/AdminSideBar";

const menuItems = [
  { to: "dashboard", icon: <DashboardIcon />, label: "Dashboard" },
  {
    to: "modules",
    icon: <UploaderModuleIcon />,
    label: "Modules",
  },
  // {
  //   to: "games",
  //   icon: <UploaderGamesIcon />,
  //   label: "Games",
  // },
  { to: "medz-gym", icon: <UploaderMedzGymIcon />, label: "The Medz Gym" },
  {
    to: "intro-messages",
    icon: <UploaderIntroIcon />,
    label: "Intro Messages",
  },
];

export default function ContentUploaderSideBar() {
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleToggleSidebar = () => {
    setIsSidebarOpen((p) => !p);
  };
  useKeyboardShortcut(handleToggleSidebar, menuItems);

  return (
    <Box
      className="hidescrollbar"
      sx={{
        boxShadow: "4px 0px 13px rgba(0, 0, 0, 0.12)",
        backgroundColor: (theme) => theme.palette.background.secondary,
        height: "100vh",
        position: "sticky",
        zIndex: 999,
        top: "0px",
        left: "0px",
        pl: isSidebarOpen ? "30px" : "10px",
        width: isSidebarOpen ? "200px" : "65px",
        overflowY: "scroll",
        transition: "all 0.7s",
      }}
    >
      <Box
        sx={{
          mt: 2,
          mb: 4,
          height: "38px",
          width: isSidebarOpen ? "99px" : "40px",
          transition: "all 0.7s",
        }}
      >
        <img src={Logo} height="38px" />
      </Box>
      <Box sx={{ mb: 4 }}>
        <IconButton onClick={handleToggleSidebar}>
          <MenuIcon />
        </IconButton>
      </Box>

      <Box sx={{ ...menuItemsCSS }}>
        {menuItems.map((item, index) => (
          <NavLink
            key={index}
            to={item.to}
            className={({ isActive }) =>
              isActive ? classes.active : classes.inactive
            }
          >
            <Box sx={{ ...flexBetweenWithItemsCenter, height: "40px" }}>
              <Box
                sx={{
                  ...sideNavbarLink,
                  ...textEllipses,
                }}
                title={item.label}
              >
                <IconButton sx={{ p: 0 }}>{item.icon} </IconButton>
                <span
                  style={{
                    opacity: isSidebarOpen ? 1 : 0,
                    transition: "opacity 0.5s ease-in-out",
                  }}
                >
                  {item.label}
                </span>
              </Box>
              <Box
                sx={{
                  display: location.pathname.startsWith("/uploader/" + item.to)
                    ? "block"
                    : "none",
                  justifySelf: "flex-end",
                  height: "100%",
                  border: "3px solid",
                  borderColor: (theme) => theme.palette.primary.main,
                  borderRadius: "50px 0 0 50px",
                }}
              ></Box>
            </Box>
          </NavLink>
        ))}

        <LogoutModal isSidebarOpen={isSidebarOpen} />
      </Box>
    </Box>
  );
}
