import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { PATCH } from "../../../Apis/constVariables";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import { dFlex, flexCenter, itemsCenter } from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";

function compareRanges(a, b) {
  const getNumericValue = (str) => parseInt(str.match(/\d+/)[0]);

  const numericA = getNumericValue(a);
  const numericB = getNumericValue(b);

  return numericA - numericB;
}

export default function PaymentSettingsTable() {
  const [loading, setLoading] = useState(false);

  const [apiData, fetchAgain] = useDataFetchHook(
    process.env.REACT_APP_PAYMENT_SETTINGS
  );

  const [individual, setIndividual] = useState(null);
  const [plan, setPlan] = useState({});
  // console.log({ res: apiData?.res });

  useEffect(() => {
    if (!apiData?.res) {
      return;
    }
    setIndividual(apiData?.res?.plan?.individual?.price);
    let { individual, ...rest } = apiData?.res?.plan;
    setPlan(rest);
  }, [apiData?.res]);

  const handleChange = (e, range, currency) => {
    const { value } = e.target;

    const updatedPlan = { ...plan };
    const pricing = {
      ...updatedPlan[range].price,
      [currency]: value,
    };
    updatedPlan[range].price = pricing;

    setPlan(updatedPlan);
  };

  const handleSave = async (type, value) => {
    try {
      console.log({ value });
      if (!value.jmd || !value.usd || value?.jmd <= 0 || value?.usd <= 0) {
        toast.error("Both Price should be greater than 0");
        return;
      }

      setLoading(true);
      const url = process.env.REACT_APP_PAYMENT_SETTINGS;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: { type, price: value },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <Box>
      {apiData?.loading && (
        <Box sx={flexCenter}>
          <CircularProgress />
        </Box>
      )}

      <Box
        sx={{
          mb: 4,
          // maxHeight: "14vh",
          background: (theme) => theme.palette.background.secondary,
          borderRadius: "10px",
          p: 3,
          // ...justifyCenter,
          // flexDirection: "column",
          // ...flexBetweenWithItemsCenter,
        }}
      >
        <Typography
          variant="medium"
          sx={{
            color: (theme) => theme.palette.text.dark,
          }}
        >
          Individual Price
        </Typography>
        <Box sx={{ ...itemsCenter, gap: 2, mt: 1 }}>
          <FormTextField
            placeholder="Enter subscription amount"
            type="number"
            onChange={(e) =>
              setIndividual((p) => {
                return {
                  ...p,
                  jmd: e.target.value,
                };
              })
            }
            value={individual?.jmd}
            sx={{
              height: "32px",
            }}
            InputProps={{
              inputProps: {
                min: 1,
              },
              sx: {
                height: "32px",
              },
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    height: "fit-content",
                    pr: "10px",
                    borderRight: "1px solid #BFBFBF",
                  }}
                >
                  <Typography
                    variant="medium"
                    sx={{
                      cursor: "default",
                      color: (theme) => theme.palette.text.light,
                    }}
                  >
                    $
                  </Typography>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    height: "fit-content",
                    pl: "10px",
                    borderLeft: "1px solid #BFBFBF",
                  }}
                >
                  <Typography
                    variant="medium"
                    sx={{
                      cursor: "default",
                      color: (theme) => theme.palette.text.light,
                    }}
                  >
                    JMD
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
          <FormTextField
            placeholder="Enter subscription amount"
            type="number"
            onChange={(e) =>
              setIndividual((p) => {
                return {
                  ...p,
                  usd: e.target.value,
                };
              })
            }
            value={individual?.usd}
            sx={{
              height: "32px",
            }}
            InputProps={{
              inputProps: {
                min: 1,
              },
              sx: {
                height: "32px",
              },
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    height: "fit-content",
                    pr: "10px",
                    borderRight: "1px solid #BFBFBF",
                  }}
                >
                  <Typography
                    variant="medium"
                    sx={{
                      cursor: "default",
                      color: (theme) => theme.palette.text.light,
                    }}
                  >
                    $
                  </Typography>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    height: "fit-content",
                    pl: "10px",
                    borderLeft: "1px solid #BFBFBF",
                  }}
                >
                  <Typography
                    variant="medium"
                    sx={{
                      cursor: "default",
                      color: (theme) => theme.palette.text.light,
                    }}
                  >
                    USD
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
          <Button
            disabled={apiData?.loading || loading}
            onClick={() => handleSave("individual", individual)}
            variant="primary-small"
          >
            Update
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          mb: 4,
          minHeight: "50vh",
          background: "#FFF",
          borderRadius: "10px",
          p: 3,
        }}
      >
        <Box>
          <Box sx={{ mb: 3 }}>
            <Typography
              variant="medium"
              sx={{
                color: (theme) => theme.palette.text.dark,
              }}
            >
              School / Institution Subscription
            </Typography>
          </Box>
          <Box sx={{ ...dFlex, mb: 3 }}>
            <Typography
              variant="medium"
              sx={{
                minWidth: "20%",
                color: (theme) => theme.palette.text.main,
              }}
            >
              Number of Students
            </Typography>
            <Typography
              variant="medium"
              sx={{
                color: (theme) => theme.palette.text.main,
              }}
            >
              Fees per student
            </Typography>
          </Box>
          {Object.keys(plan)
            ?.sort(compareRanges)
            .map((e, i) => {
              return (
                <Box sx={{ ...itemsCenter, mb: 2 }}>
                  <Typography
                    variant="medium"
                    sx={{
                      minWidth: "20%",
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    {/* 0 - 50 Students */}
                    {e} Students
                  </Typography>
                  <FormTextField
                    placeholder="Enter subscription amount"
                    onChange={(event) => handleChange(event, e, "jmd")}
                    value={plan?.[e]?.price?.jmd}
                    type="number"
                    sx={{
                      height: "32px",
                      mr: 3,
                    }}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                      sx: {
                        height: "32px",
                      },
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            height: "fit-content",
                            pr: "10px",
                            borderRight: "1px solid #BFBFBF",
                          }}
                        >
                          <Typography
                            variant="medium"
                            sx={{
                              cursor: "default",
                              color: (theme) => theme.palette.text.light,
                            }}
                          >
                            $
                          </Typography>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            height: "fit-content",
                            pl: "10px",
                            borderLeft: "1px solid #BFBFBF",
                          }}
                        >
                          <Typography
                            variant="medium"
                            sx={{
                              cursor: "default",
                              color: (theme) => theme.palette.text.light,
                            }}
                          >
                            JMD
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormTextField
                    placeholder="Enter subscription amount"
                    onChange={(event) => handleChange(event, e, "usd")}
                    value={plan?.[e]?.price?.usd}
                    type="number"
                    sx={{
                      height: "32px",
                      mr: 3,
                    }}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                      sx: {
                        height: "32px",
                      },
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            height: "fit-content",
                            pr: "10px",
                            borderRight: "1px solid #BFBFBF",
                          }}
                        >
                          <Typography
                            variant="medium"
                            sx={{
                              cursor: "default",
                              color: (theme) => theme.palette.text.light,
                            }}
                          >
                            $
                          </Typography>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            height: "fit-content",
                            pl: "10px",
                            borderLeft: "1px solid #BFBFBF",
                          }}
                        >
                          <Typography
                            variant="medium"
                            sx={{
                              cursor: "default",
                              color: (theme) => theme.palette.text.light,
                            }}
                          >
                            USD
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    disabled={apiData?.loading || loading}
                    onClick={() => handleSave(e, plan?.[e]?.price)}
                    variant="primary-small"
                  >
                    Update
                  </Button>
                </Box>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
}
