import { Box, Button, Dialog, DialogActions, Typography } from "@mui/material";
import React, { useState } from "react";
import { itemsCenter, textEllipses } from "../../../../common/commonStyles";
import VideoPlayer from "../../../../const/VideoPlayer";
import { mp4FileIcon } from "../../../../const/imageUrl";

export default function ChatVideoComponent({ data }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          ...itemsCenter,
          flexDirection: "column",
          gap: 1,
          my: 1,
          maxWidth: "100px",
          cursor: "pointer",
        }}
        onClick={handleClickOpen}
      >
        <img
          src={mp4FileIcon}
          alt="mp4_file"
          style={{
            maxHeight: "120px",
            maxWidth: "90px",
          }}
        />
        <Typography
          variant="medium2"
          sx={{
            maxWidth: "120px",
            color: (theme) => theme.palette.text.main,
            ...textEllipses,
          }}
        >
          {data?.fileName}
        </Typography>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "#F3F5F5",
            borderRadius: "14px",
            p: "24px 12px 12px 24px",
            maxWidth: "100%",
            width: "40%",
          },
        }}
      >
        <VideoPlayer data={data} />
        <DialogActions sx={{ pt: 1 }}>
          <Button
            sx={{ height: "32px", textTransform: "none" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
