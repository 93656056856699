import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import { flexCenter } from "../../../common/commonStyles";

export default function ItemVideoError({ open, setOpen }) {
  const [apiData] = useDataFetchHook(open && process.env.REACT_APP_ERROR_ITEM);
  const handleClose = () => {
    setOpen(false);
  };
  // console.log({ apiData });
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "13px",
            backgroundColor: (theme) => theme.palette.background.main,
          },
        }}
      >
        <Box sx={{ maxWidth: "800px", pb: "20px" }}>
          <DialogTitle sx={{ mb: 1 }}>
            <Typography
              variant="semibold"
              sx={{ color: (theme) => theme.palette.text.dark }}
            >
              List of Video Items with error
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ mb: 2 }}>
            {apiData?.loading && (
              <>
                <Box sx={flexCenter}>
                  <CircularProgress />
                </Box>
              </>
            )}
            <DialogContentText id="alert-dialog-description">
              {apiData?.res?.data?.map((e, i) => {
                return (
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      sx={{ color: (theme) => theme.palette.text.main }}
                    >
                      {i + 1}. <b>Item Name</b> - {e?.itemName}
                    </Typography>
                    <Typography
                      sx={{ color: (theme) => theme.palette.text.main, ml: 2 }}
                    >
                      {e?.lesson && (
                        <>
                          <b>Item Path:</b> {e.moduleName} {"->"} {e.lessonName}{" "}
                          {"->"} {e.itemName}
                        </>
                      )}
                      {e?.topic && (
                        <>
                          <b>Item Path:</b> {e.moduleName} {"->"} {e.lessonName}{" "}
                          {"->"}
                          {e?.topicName} {"->"} {e.itemName}
                        </>
                      )}
                    </Typography>
                  </Box>
                );
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}
