import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import { React, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../Apis/axiosPayloads";
import { PATCH, POST } from "../../Apis/constVariables";
import useDataFetchHook from "../../Hooks/DataFetchHook";
import {
  flexBetweenCenter,
  flexCenter,
  itemsCenter,
} from "../../common/commonStyles";
import { FormTextField } from "../../const/TextField";
import {
  AccordionTransition,
  checkSize,
  convertToBase64File,
} from "../../const/constFunctions";
import { success } from "../../const/variables";
import { EditIconColor } from "../../images/icons";
import ItemDeleteModal from "./DeleteModals/ItemDeleteModal";
import { ExpandMoreSvg, SvgVector } from "./icons";
import "./styles/item.css";

const Item = ({ topicId = null, moduleId = null, lessonId = null }) => {
  const initData = {
    lessonAssociated: lessonId,
    topicAssociated: topicId,
    URL: null,
    itemName: "",
    itemType: "",
    FirstTokens: "",
    SecondTokens: "",
  };
  let url = null;
  if (lessonId) {
    url = process.env.REACT_APP_ALL_ITEM + `?lessonId=${lessonId}`;
  } else if (topicId) {
    url = process.env.REACT_APP_ALL_ITEM_TOPIC + `?topicId=${topicId}`;
  }
  const [itemsApiCall, fetchItemsAgain, itemDispatch] = useDataFetchHook(url);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [file, setFile] = useState(null);
  const [coverFile, setCoverFile] = useState(null);
  const [showItemForm, setShowItemForm] = useState(false);
  const [base64, setBase64] = useState(null);
  const [audioBase64, setAudioBase64] = useState(null);
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);

  const [itemType, setItemType] = useState(null);

  const handleImageChange = async (state, e) => {
    try {
      if (e?.target?.files.length > 0) {
        let file = e.target.files[0];
        if (checkSize(file) > 10) {
          e.target.value = "";
          toast.error("Please upload an image less than 10MB");
          return;
        }
        const base64 = await convertToBase64File(file);
        setBase64(base64);
        state(e?.target?.files?.[0]);
      } else state(null);
    } catch (err) {
      console.log(err);
      e.target.value = "";
      toast.error("Something went wrong, Please try again");
    }
  };

  const handleAudioChange = async (state, e) => {
    try {
      if (e?.target?.files.length > 0) {
        let file = e.target.files[0];
        // if (checkSize(file) > 10) {
        //   e.target.value = "";
        //   toast.error("Please upload an image less than 10MB");
        //   return;
        // }
        const base64 = await convertToBase64File(file);
        setAudioBase64(base64);
        state(e?.target?.files?.[0]);
      } else state(null);
    } catch (err) {
      console.log(err);
      e.target.value = "";
      toast.error("Something went wrong, Please try again");
    }
  };

  useEffect(() => {
    if (itemType) {
      handleImageChange(setFile, null);
      handleImageChange(setCoverFile, null);
      setAudioBase64(null);
      setBase64(null);
    }
  }, [itemType]);

  const edit = (item) => {
    setShowItemForm((p) => !p);
    setItemType(item.itemType);
    const formatData = {
      itemId: item?.itemId,
      itemName: item?.itemName,
      SecondTokens: item?.SecondTokens,
      FirstTokens: item?.FirstTokens,
    };
    reset(formatData);
    setEditable(true);
  };

  const toggleForm = () => {
    reset(initData);
    setBase64(null);
    setAudioBase64(null);
    setItemType(null);
    setShowItemForm((p) => !p);
  };

  const addItem = async (data) => {
    try {
      let nsn = data?.itemName.replaceAll(" ", "");
      if (!nsn) {
        toast.error("Fields Cannot be empty");
        return;
      }
      let dat = /\//i.test(nsn);
      if (dat) {
        toast.error("you can not use any (/) characters");
        return;
      }
      if (itemType == "pdf" && !base64) {
        toast.error("Please select file first");
        return;
      }
      if (itemType == "audio" && !audioBase64) {
        toast.error("Please select file first");
        return;
      }

      let sendUrl = null,
        coverImage = null,
        videoPath = null;
      /* itemType === "pdf" ? base64 : data.URL
        itemType !== "pdf" ? base64 : null */
      setLoading(true);

      if (itemType == "pdf") {
        sendUrl = base64;
        coverImage = null;
      } else if (itemType == "audio") {
        sendUrl = audioBase64;
        coverImage = base64;
      } else if (itemType == "video") {
        videoPath = await uploadVideoToVimeo(data?.itemName.trim());
        console.log({ videoPath });
        sendUrl = null;
        coverImage = base64;
      } else {
        sendUrl = data.URL;
        coverImage = base64;
      }

      if (itemType !== "video" && !sendUrl) {
        throw new Error("Something went wrong while uploading");
      }
      const url = process.env.REACT_APP_ITEM;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...data,
          videoPath,
          itemName: data?.itemName.trim(),
          itemType,
          URL: sendUrl,
          coverImage: coverImage,
          FirstTokens: Math.abs(data?.FirstTokens),
          SecondTokens: Math.abs(data?.SecondTokens),
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchItemsAgain();
      toggleForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const uploadVideoToVimeo = async (videoName) => {
    try {
      // creating vimeo video

      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer 1946de4522237abe8384875d19028134"
      );

      let raw = JSON.stringify({
        upload: {
          approach: "post",
          size: file.size,
        },
        name: videoName,
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const createVideo = await fetch(
        "https://api.vimeo.com/me/videos",
        requestOptions
      );
      const response = await createVideo.json();
      const videoPath = response.uri;

      const uploadLink = response.upload.upload_link;
      const formData = new FormData();
      formData.append("file_data", file, file.name);

      var uploadOptions = {
        method: "POST",
        body: formData,
        redirect: "manual",
      };
      // console.log({ uploadLink });
      const res = await fetch(uploadLink, uploadOptions).catch((e) =>
        console.log(e)
      );
      const uploadRes = await res?.json?.();
      // console.log({ res });
      // console.log({ uploadRes });

      return `${videoPath}`;
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const updateItem = async (data) => {
    try {
      let nsn = data?.itemName.replaceAll(" ", "");
      if (!nsn) {
        toast.error("Fields Cannot be empty");
        return;
      }
      let dat = /\//i.test(nsn);
      if (dat) {
        toast.error("you can not use any (/) characters");
        return;
      }

      setLoading(true);
      const url = process.env.REACT_APP_ITEM + `/${data?.itemId}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: {
          ...data,
          itemName: data?.itemName.trim(),
          FirstTokens: Math.abs(data?.FirstTokens),
          SecondTokens: Math.abs(data?.SecondTokens),
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchItemsAgain();
      toggleForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleOnDragEnd = async (result) => {
    try {
      if (!result.destination) return;
      const items = Array.from(itemsApiCall?.res?.items);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setLoading(true);
      let url = null;
      let formattedData = null;
      if (lessonId) {
        url = process.env.REACT_APP_LESSON + `/addLessonItemOrder`;
        formattedData = {
          lessonId: lessonId,
          items: items.map((e) => e.itemId),
        };
      } else if (topicId) {
        url = process.env.REACT_APP_TOPIC + `/addTopicItemOrder`;
        formattedData = {
          topicId: topicId,
          items: items.map((e) => e.itemId),
        };
      }

      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: formattedData,
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      itemDispatch({ type: success, data: { items: items } });
      // fetchLessonAgain();
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <>
      <section className="section" style={{ margin: "0px", width: "100%" }}>
        <Button
          variant="primary-small"
          onClick={() => {
            toggleForm();
            setEditable(false);
          }}
        >
          {/* <AddIcon /> */}
          Add Item
        </Button>
        {showItemForm && (
          <Box
            className="addItem-container"
            // id={topicId + moduleId + lessonId + "itemForm"}
          >
            <Typography
              variant="bold"
              sx={{ color: (theme) => theme.palette.primary.main }}
            >
              Item
            </Typography>
            <Box className="addItem-row" sx={{ my: 2 }}>
              <Typography
                className="addItem-inputLabel"
                sx={{
                  color: (theme) => theme.palette.text.dark,
                }}
              >
                Item Name*
              </Typography>
              <FormTextField
                {...register("itemName", {
                  required: "Item Name is required",
                })}
                // id={topicId + moduleId + lessonId + "itemName"}
                autoFocus
                autoComplete="off"
                sx={{ width: "30%" }}
                error={!!errors.itemName}
                helperText={errors?.itemName?.message}
              />
            </Box>
            {!editable && (
              <Box className="addItem-row">
                <Typography
                  className="addItem-inputLabel"
                  sx={{
                    color: (theme) => theme.palette.text.dark,
                  }}
                >
                  Type *
                </Typography>
                <Select
                  // id={topicId + moduleId + lessonId + "itemType"}
                  sx={{
                    background: "#FFF",
                    color: "#809fb8",
                    height: "38px",
                    borderRadius: "4px",
                    width: "30%",
                  }}
                  error={Boolean(errors?.itemType)}
                  onChange={(e) => {
                    setItemType(e.target.value);
                  }}
                  value={itemType}
                  name="itemType"
                  // {...register("itemType", {
                  //   required: "This is required",
                  // })}
                >
                  <MenuItem value="pdf">
                    <Typography
                      variant="medium"
                      sx={{
                        fontWeight: 600,
                        color: (theme) => theme.palette.text.main,
                      }}
                    >
                      PDF
                    </Typography>
                  </MenuItem>
                  <MenuItem value="video">
                    <Typography
                      variant="medium"
                      sx={{
                        fontWeight: 600,
                        color: (theme) => theme.palette.text.main,
                      }}
                    >
                      Video
                    </Typography>
                  </MenuItem>
                  <MenuItem value="html">
                    <Typography
                      variant="medium"
                      sx={{
                        fontWeight: 600,
                        color: (theme) => theme.palette.text.main,
                      }}
                    >
                      HTML
                    </Typography>
                  </MenuItem>
                  <MenuItem value="audio">
                    <Typography
                      variant="medium"
                      sx={{
                        fontWeight: 600,
                        color: (theme) => theme.palette.text.main,
                      }}
                    >
                      Audio
                    </Typography>
                  </MenuItem>
                  <MenuItem value="scrom">
                    <Typography
                      variant="medium"
                      sx={{
                        fontWeight: 600,
                        color: (theme) => theme.palette.text.main,
                      }}
                    >
                      SCORM
                    </Typography>
                  </MenuItem>
                </Select>
              </Box>
            )}
            {itemType == "pdf" && !editable && (
              <Box
                className="addItem-row"
                // id={topicId + moduleId + lessonId + "pdfLinkBlock"}
              >
                <Typography
                  sx={{
                    color: (theme) => theme.palette.text.dark,
                  }}
                  className="addItem-inputLabel"
                >
                  PDF File *
                </Typography>
                <Box sx={{ ...itemsCenter, gap: "10px" }}>
                  <Box
                    component={"label"}
                    sx={{
                      ...flexCenter,
                      height: "35px",
                      width: "125px",
                      cursor: "pointer",
                      borderRadius: "4px",
                      color: (theme) => theme.palette.text.main,
                      backgroundColor: (theme) => theme.palette.text.lighter,
                      // "&:hover": {
                      //   backgroundColor: "#D1D0D0",
                      // },
                    }}
                  >
                    Choose a file
                    <input
                      type="file"
                      accept="application/pdf"
                      onChange={(e) => handleImageChange(setFile, e)}
                      style={{ display: "none" }}
                    />
                  </Box>
                  <Typography
                    variant="regular"
                    title={file ? file?.name : "No File Chosen"}
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      // maxWidth: "220px",
                      // ...textEllipses,
                    }}
                  >
                    {file ? file?.name : "No File Chosen"}
                  </Typography>
                </Box>
              </Box>
            )}
            {itemType == "audio" && !editable && (
              <Box
                className="addItem-row"
                // id={topicId + moduleId + lessonId + "pdfLinkBlock"}
              >
                <Typography
                  sx={{
                    color: (theme) => theme.palette.text.dark,
                  }}
                  className="addItem-inputLabel"
                >
                  Audio File *
                </Typography>
                <Box sx={{ ...itemsCenter, gap: "10px" }}>
                  <Box
                    component={"label"}
                    sx={{
                      ...flexCenter,
                      height: "35px",
                      width: "125px",
                      cursor: "pointer",
                      borderRadius: "4px",
                      color: (theme) => theme.palette.text.main,
                      backgroundColor: (theme) => theme.palette.text.lighter,
                      // "&:hover": {
                      //   backgroundColor: "#D1D0D0",
                      // },
                    }}
                  >
                    Choose a file
                    <input
                      type="file"
                      accept="audio/*"
                      onChange={(e) => handleAudioChange(setFile, e)}
                      style={{ display: "none" }}
                    />
                  </Box>
                  <Typography
                    variant="regular"
                    title={file ? file?.name : "No File Chosen"}
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      // maxWidth: "220px",
                      // ...textEllipses,
                    }}
                  >
                    {file ? file?.name : "No File Chosen"}
                  </Typography>
                </Box>
              </Box>
            )}
            {itemType == "video" && !editable && (
              <Box
                className="addItem-row"
                // id={topicId + moduleId + lessonId + "pdfLinkBlock"}
              >
                <Typography
                  sx={{
                    color: (theme) => theme.palette.text.dark,
                  }}
                  className="addItem-inputLabel"
                >
                  Video File *
                </Typography>
                <Box sx={{ ...itemsCenter, gap: "10px" }}>
                  <Box
                    component={"label"}
                    sx={{
                      ...flexCenter,
                      height: "35px",
                      width: "125px",
                      cursor: "pointer",
                      borderRadius: "4px",
                      color: (theme) => theme.palette.text.main,
                      backgroundColor: (theme) => theme.palette.text.lighter,
                      // "&:hover": {
                      //   backgroundColor: "#D1D0D0",
                      // },
                    }}
                  >
                    Choose a file
                    <input
                      type="file"
                      accept="video/*"
                      onChange={(e) => handleAudioChange(setFile, e)}
                      style={{ display: "none" }}
                    />
                  </Box>
                  <Typography
                    variant="regular"
                    title={file ? file?.name : "No File Chosen"}
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      // maxWidth: "220px",
                      // ...textEllipses,
                    }}
                  >
                    {file ? file?.name : "No File Chosen"}
                  </Typography>
                </Box>
              </Box>
            )}
            {itemType == "scrom" && !editable && (
              <Box
                className="addItem-row"
                // id={topicId + moduleId + lessonId + "pdfLinkBlock"}
              >
                <Typography
                  sx={{
                    color: (theme) => theme.palette.text.dark,
                  }}
                  className="addItem-inputLabel"
                >
                  SCORM Type *
                </Typography>
                <Select
                  sx={{
                    background: "#FFF",
                    color: "#809fb8",
                    height: "38px",
                    borderRadius: "4px",
                    width: "30%",
                  }}
                  error={Boolean(errors?.scromType)}
                  name="itemType"
                  {...register("scromType", {
                    required: "This is required",
                  })}
                >
                  <MenuItem value="Articulate">
                    <Typography
                      variant="medium"
                      sx={{
                        fontWeight: 600,
                        color: (theme) => theme.palette.text.main,
                      }}
                    >
                      Articulate
                    </Typography>
                  </MenuItem>
                  <MenuItem value="genially">
                    <Typography
                      variant="medium"
                      sx={{
                        fontWeight: 600,
                        color: (theme) => theme.palette.text.main,
                      }}
                    >
                      Genially
                    </Typography>
                  </MenuItem>
                  <MenuItem value="iSpring">
                    <Typography
                      variant="medium"
                      sx={{
                        fontWeight: 600,
                        color: (theme) => theme.palette.text.main,
                      }}
                    >
                      iSpring
                    </Typography>
                  </MenuItem>
                </Select>
              </Box>
            )}
            {(itemType === "scrom" || itemType === "html") && !editable && (
              <Box
                className="addItem-row"
                // id={topicId + moduleId + lessonId + "videoLinkBlock"}
              >
                <Typography
                  className="addItem-inputLabel"
                  sx={{
                    color: (theme) => theme.palette.text.dark,
                  }}
                >
                  {itemType == "scrom" && "SCORM"}
                  {itemType == "html" && "HTML"} Link *
                </Typography>
                <FormTextField
                  {...register("URL", {
                    required: "URL is required",
                    pattern: {
                      value: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi,
                      message: "Invalid URL",
                    },
                  })}
                  // id={topicId + moduleId + lessonId + "videoLink"}
                  autoComplete="off"
                  sx={{ width: "30%" }}
                  error={!!errors.URL}
                  helperText={errors?.URL?.message}
                />
              </Box>
            )}
            {(itemType === "video" || itemType === "audio") && !editable && (
              <Box
                className="addItem-row"
                // id={topicId + moduleId + lessonId + "videoLinkBlock"}
              >
                <Typography
                  className="addItem-inputLabel"
                  sx={{
                    color: (theme) => theme.palette.text.dark,
                  }}
                >
                  Cover Image*
                </Typography>
                <Box sx={{ ...itemsCenter, gap: "10px" }}>
                  <Box
                    component={"label"}
                    sx={{
                      ...flexCenter,
                      height: "35px",
                      width: "125px",
                      cursor: "pointer",
                      borderRadius: "4px",
                      color: (theme) => theme.palette.text.main,
                      backgroundColor: (theme) => theme.palette.text.lighter,
                      // "&:hover": {
                      //   backgroundColor: "#D1D0D0",
                      // },
                    }}
                  >
                    Choose a file
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleImageChange(setCoverFile, e)}
                      style={{ display: "none" }}
                    />
                  </Box>
                  <Typography
                    variant="regular"
                    title={coverFile ? coverFile?.name : "No File Chosen"}
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      // maxWidth: "220px",
                      // ...textEllipses,
                    }}
                  >
                    {coverFile ? coverFile?.name : "No File Chosen"}
                  </Typography>
                </Box>
              </Box>
            )}
            <Box className="addItem-row" sx={{ my: 2 }}>
              <Typography
                className="addItem-inputLabel"
                sx={{
                  color: (theme) => theme.palette.text.dark,
                }}
              >
                Tokens*
              </Typography>
              <FormTextField
                sx={{ width: "14%", mr: 2.5 }}
                type="number"
                placeholder="First attempt"
                autoComplete="off"
                error={Boolean(errors?.FirstTokens)}
                helperText={errors?.firstToken?.message}
                {...register("FirstTokens", {
                  required: "This is required",
                })}
              />
              <FormTextField
                sx={{ width: "14%" }}
                type="number"
                placeholder="Second attempt"
                autoComplete="off"
                error={Boolean(errors?.SecondTokens)}
                helperText={errors?.secondToken?.message}
                {...register("SecondTokens", {
                  required: "This is required",
                })}
              />
            </Box>
            <Box sx={{ ...itemsCenter, justifyContent: "flex-end" }}>
              <Button
                sx={{ textTransform: "none" }}
                onClick={() => toggleForm()}
              >
                Cancel
              </Button>
              <Button
                variant="primary-small"
                disabled={loading}
                onClick={
                  editable ? handleSubmit(updateItem) : handleSubmit(addItem)
                }
              >
                Save
              </Button>
            </Box>
          </Box>
        )}
        {(itemsApiCall?.loading || loading) && (
          <Box sx={{ ...flexCenter }}>
            <CircularProgress />
          </Box>
        )}

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <ul
                id="itemList"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  listStyleType: "none",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {itemsApiCall?.res?.items?.map((item, index) => (
                  <Draggable
                    key={item.itemId}
                    draggableId={item.itemId}
                    index={index}
                  >
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        key={item.itemId}
                        className="draggableItem-li"
                      >
                        <Accordion TransitionProps={AccordionTransition}>
                          <AccordionSummary
                            {...provided.dragHandleProps}
                            expandIcon={<ExpandMoreSvg />}
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.content.item,
                              color: "white",
                              height: "40px",
                              borderRadius: "7px",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <SvgVector />
                              <Box className="lessonList-accordion-heading">
                                {item.itemName}
                              </Box>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box sx={{ ...flexBetweenCenter, mb: 1 }}>
                              {topicId ? (
                                <Typography
                                  variant="semibold"
                                  sx={{
                                    color: (theme) => theme.palette.text.main,
                                  }}
                                >
                                  Topic Associated - {topicId}
                                </Typography>
                              ) : (
                                <></>
                              )}
                              {lessonId ? (
                                <Typography
                                  variant="semibold"
                                  sx={{
                                    color: (theme) => theme.palette.text.main,
                                  }}
                                >
                                  Lesson Associated- {lessonId}
                                </Typography>
                              ) : (
                                <></>
                              )}
                              <Box
                                sx={{
                                  ...itemsCenter,
                                  mr: 1,
                                }}
                              >
                                <IconButton onClick={() => edit(item)}>
                                  <EditIconColor />
                                </IconButton>
                                <ItemDeleteModal
                                  data={item}
                                  fetchItemsAgain={fetchItemsAgain}
                                />
                              </Box>
                            </Box>
                            <Box>
                              {moduleId ? (
                                <Typography variant="semibold">
                                  Module Associated- {moduleId}
                                </Typography>
                              ) : (
                                <></>
                              )}
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </section>
    </>
  );
};

export default Item;
