import { createContext, useState } from "react";

export const UserContext = createContext();

export const ContextProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState({
    name: "",
    profilePic: "",
    role: "",
    uid: "",
  });
  const [fcmToken, setFcmToken] = useState(null);
  return (
    <UserContext.Provider
      value={{
        userDetails,
        setUserDetails,
        fcmToken,
        setFcmToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
