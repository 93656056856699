import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../Apis/axiosPayloads";
import { PATCH } from "../../Apis/constVariables";
import useDataFetchHook from "../../Hooks/DataFetchHook";
import {
  flexBetweenWithItemsCenter,
  flexCenter,
} from "../../common/commonStyles";
import { FormTextField } from "../../const/TextField";
import { AccordionTransition } from "../../const/constFunctions";
import { quizOptions, success } from "../../const/variables";
import { EditIconColor } from "../../images/icons";
import QuizQuestionDeleteModal from "./DeleteModals/QuizQuestionDeleteModal";
import Assignment from "./Questions/Assignment";
import Choosethecorrectsequence from "./Questions/Choosethecorrectsequence";
import CompleteSentence from "./Questions/CompleteSentence";
import DragAndDropCategory from "./Questions/DragAndDropCategory";
import DropDown from "./Questions/DropDown";
import Matchthepair from "./Questions/Matchthepair";
import PictureMCQ from "./Questions/PictureMCQ";
import CreateMcqQuestion from "./Questions/mcqQuestion";
import { ExpandMoreSvg, SvgVector } from "./icons";
import "./styles/createQuiz.css";

// const temp = process.env.REACT_APP_QUESTION;

export const validateBasicQuestionForm = (data) => {
  try {
    if (!data.questionName?.trim()) {
      return { error: true, message: "Question Name is required" };
    }
    if (!data.question?.trim()) {
      return { error: true, message: "Question is required" };
    }
    // if (!data.questionInstruction?.trim()) {
    //   return { error: true, message: "Question Instruction is required" };
    // }
    return { error: false };
  } catch (e) {
    console.log(e);
    return { error: true, message: "Something went wrong" };
  }
};

const CreateQuestion = ({ quizId, notNormalQuiz }) => {
  const initData = {
    quizId,
    questionType: "default",
    questionName: "",
    firstTokens: 0,
    secondTokens: 0,
    question: "",
    questionInstruction: "",
  };
  const [questionFormData, setQuestionFormData] = useState(initData);
  const [editAble, setEditAble] = useState(false);
  const url =
    quizId && process.env.REACT_APP_QUESTION + `/questionset?quizId=${quizId}`;

  const [
    quizQuestionApiCall,
    fetchQuizQuestionsAgain,
    dispatchQuizQuestions,
  ] = useDataFetchHook(url);

  const [showQuizForm, setShowQuizForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name == "firstTokens" || name == "secondTokens") {
      value = Math.abs(value);
    }
    setQuestionFormData((p) => {
      return {
        ...p,
        [name]: value,
      };
    });
  };

  const toggleQuestionForm = () => {
    setQuestionFormData(initData);
    setEditAble(false);
    setShowQuizForm((p) => !p);
  };
  const handleOnDragEnd = async (result) => {
    try {
      if (!result.destination) return;
      const items = Array.from(quizQuestionApiCall?.res?.questions);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      let url = process.env.REACT_APP_QUESTION + `/addQuestionOrder`;

      const formattedData = {
        quizId: quizId,
        questions: items.map((e) => e.questionId),
      };
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: formattedData,
      });
      setLoading(true);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      dispatchQuizQuestions({ type: success, data: { questions: items } });
      // fetchLessonAgain();
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  function edit(item) {
    // console.log({ item });

    const formattedData = {
      questionInstruction: item?.questionInstruction,
      questionId: item?.questionId,
      question: item?.question,
      questionName: item?.questionName,
      questionType: item?.questionType,
      firstTokens: item?.firstTokens,
      secondTokens: item?.secondTokens,
      data: item?.data,
    };

    setEditAble(true);
    setQuestionFormData(formattedData);
    setShowQuizForm(true);
  }

  return (
    <section className="quizForm-container">
      <Button
        variant="primary-small"
        onClick={() => {
          toggleQuestionForm();
        }}
      >
        Add Question
      </Button>
      {showQuizForm && (
        <Box className="quizFrom-mainFrom">
          <form
            id={quizId + "question-form"}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Box className="quizForm-mainForm-infoRow">
              <Typography
                className="quizForm-mainForm-label"
                sx={{
                  color: (theme) => theme.palette.text.dark,
                }}
              >
                Type of Question:
              </Typography>
              <Select
                autoFocus
                sx={{
                  height: "36px",
                  width: "50%",
                }}
                name="questionType"
                value={questionFormData?.questionType}
                onChange={handleChange}
              >
                <MenuItem value="default" disabled>
                  <Typography
                    variant="medium"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    Select Type
                  </Typography>
                </MenuItem>
                <MenuItem value="MCQ">
                  <Typography
                    variant="medium"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    {quizOptions?.MCQ}
                  </Typography>
                </MenuItem>
                <MenuItem value="MatchPair">
                  <Typography
                    variant="medium"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    {quizOptions?.MatchPair}
                  </Typography>
                </MenuItem>
                <MenuItem value="PicMCQ">
                  <Typography
                    variant="medium"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    {quizOptions?.PicMCQ}
                  </Typography>
                </MenuItem>

                <MenuItem value="DropDown">
                  <Typography
                    variant="medium"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    {quizOptions?.DropDown}
                  </Typography>
                </MenuItem>
                <MenuItem value="Ranking">
                  <Typography
                    variant="medium"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    {quizOptions?.Ranking}
                  </Typography>
                </MenuItem>
                <MenuItem value="DragDrop">
                  <Typography
                    variant="medium"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    {quizOptions?.DragDrop}
                  </Typography>
                </MenuItem>
                <MenuItem value="Assignment">
                  <Typography
                    variant="medium"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    {quizOptions?.Assignment}
                  </Typography>
                </MenuItem>
                {notNormalQuiz && (
                  <MenuItem value="CompleteSentence">
                    <Typography
                      variant="medium"
                      sx={{
                        color: (theme) => theme.palette.text.main,
                      }}
                    >
                      {quizOptions?.CompleteSentence}
                    </Typography>
                  </MenuItem>
                )}
              </Select>
            </Box>
            <Box className="quizForm-mainForm-infoRow">
              <p className="quizForm-mainForm-label">Question Name :</p>
              <FormTextField
                type="text"
                className="quizForm-mainForm-inputBar"
                name="questionName"
                value={questionFormData?.questionName}
                onChange={handleChange}
              />
            </Box>
            {!notNormalQuiz && (
              <Box className="quizForm-mainForm-infoRow">
                <p className="quizForm-mainForm-label">Tokens :</p>
                <FormTextField
                  type="number"
                  placeholder="First Attempt"
                  sx={{ mr: 3, width: "24%" }}
                  name="firstTokens"
                  value={questionFormData?.firstTokens}
                  onChange={handleChange}
                />
                <FormTextField
                  type="number"
                  placeholder="Second Attempt"
                  sx={{ width: "24%" }}
                  name="secondTokens"
                  value={questionFormData?.secondTokens}
                  onChange={handleChange}
                />
              </Box>
            )}

            <Box className="quizForm-mainForm-infoRow">
              <label className="quizForm-mainForm-label">Question : </label>
              <FormTextField
                multiline
                minRows={4}
                maxRows={6}
                sx={{ width: "50%" }}
                name="question"
                value={questionFormData?.question}
                onChange={handleChange}
              />
            </Box>
            <Box className="quizForm-mainForm-infoRow">
              <label className="quizForm-mainForm-label">Instruction : </label>
              <FormTextField
                multiline
                minRows={4}
                maxRows={6}
                sx={{ width: "50%" }}
                name="questionInstruction"
                value={questionFormData?.questionInstruction}
                onChange={handleChange}
              />
            </Box>

            {questionFormData?.questionType == "MCQ" && (
              <CreateMcqQuestion
                editAble={editAble}
                questionFormData={questionFormData}
                fetchQuizQuestionsAgain={fetchQuizQuestionsAgain}
                toggleQuestionForm={toggleQuestionForm}
              />
            )}
            {questionFormData?.questionType == "MatchPair" && (
              <Matchthepair
                editAble={editAble}
                questionFormData={questionFormData}
                fetchQuizQuestionsAgain={fetchQuizQuestionsAgain}
                toggleQuestionForm={toggleQuestionForm}
              />
            )}
            {questionFormData?.questionType == "PicMCQ" && (
              <PictureMCQ
                editAble={editAble}
                questionFormData={questionFormData}
                fetchQuizQuestionsAgain={fetchQuizQuestionsAgain}
                toggleQuestionForm={toggleQuestionForm}
              />
            )}
            {questionFormData?.questionType == "Ranking" && (
              <Choosethecorrectsequence
                editAble={editAble}
                questionFormData={questionFormData}
                fetchQuizQuestionsAgain={fetchQuizQuestionsAgain}
                toggleQuestionForm={toggleQuestionForm}
              />
            )}
            {questionFormData?.questionType == "DragDrop" && (
              <DragAndDropCategory
                editAble={editAble}
                questionFormData={questionFormData}
                fetchQuizQuestionsAgain={fetchQuizQuestionsAgain}
                toggleQuestionForm={toggleQuestionForm}
              />
            )}
            {questionFormData?.questionType == "Assignment" && (
              <Assignment
                editAble={editAble}
                questionFormData={questionFormData}
                fetchQuizQuestionsAgain={fetchQuizQuestionsAgain}
                toggleQuestionForm={toggleQuestionForm}
              />
            )}
            {questionFormData?.questionType == "DropDown" && (
              <DropDown
                editAble={editAble}
                questionFormData={questionFormData}
                fetchQuizQuestionsAgain={fetchQuizQuestionsAgain}
                toggleQuestionForm={toggleQuestionForm}
              />
            )}
            {questionFormData?.questionType == "CompleteSentence" && (
              <CompleteSentence
                editAble={editAble}
                questionFormData={questionFormData}
                fetchQuizQuestionsAgain={fetchQuizQuestionsAgain}
                toggleQuestionForm={toggleQuestionForm}
              />
            )}
          </form>
        </Box>
      )}

      {(quizQuestionApiCall?.loading || loading) && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />
        </Box>
      )}

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <ul
              id="questionList"
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                display: "block",
                overflow: "hidden",
                width: "100%",
                listStyleType: "none",
                margin: "0px",
                padding: "0px",
              }}
            >
              {quizQuestionApiCall?.res?.questions?.map((item, index) => (
                <Draggable
                  key={item.questionId}
                  draggableId={item.questionId}
                  index={index}
                >
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      key={item.id}
                      className="quiz-dragable"
                    >
                      <Accordion TransitionProps={AccordionTransition}>
                        <AccordionSummary
                          {...provided.dragHandleProps}
                          expandIcon={<ExpandMoreSvg />}
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.content.quizQuestion,
                            height: "40px",
                            color: "white",
                            borderRadius: "5px",
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <SvgVector />
                            <Typography>{item.questionName}</Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box sx={{ ...flexBetweenWithItemsCenter }}>
                            <p>{quizOptions?.[item.questionType]}</p>
                            <Box>
                              {item?.questionType !== "DragDrop" && (
                                <IconButton
                                  onClick={() => edit(item)}
                                  sx={{ py: 0 }}
                                >
                                  <EditIconColor />
                                </IconButton>
                              )}
                              <QuizQuestionDeleteModal
                                data={item}
                                fetchQuizQuestionsAgain={
                                  fetchQuizQuestionsAgain
                                }
                              />
                            </Box>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </section>
  );
};

export default CreateQuestion;

{
  /* <MenuItem value="Assignment">
                  <Typography
                    variant="medium"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    Assignment
                  </Typography>
                </MenuItem> */
}
