import { Box, Button, FormHelperText, Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../Apis/axiosPayloads";
import { POST } from "../../Apis/constVariables";
import { dFlex, flexCenter } from "../../common/commonStyles";
import { FormTextField } from "../../const/TextField";
import illustration from "../../images/login_illustration.png";

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    // setError,
    reset,
  } = useForm();
  const onSubmit = async (e) => {
    try {
      //REACT_APP_FORGOT_PASSWORD
      setLoading(true);
      const url = process.env.REACT_APP_FORGOT_PASSWORD;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          email: e.email,
        },
      });
      const apiRes = await axios.request(config);
      // console.log(e);
      // console.log({ apiRes });
      console.log(apiRes.data.url);
      toast(apiRes?.data?.message);
      // setError("email", { message: "error message" });
      navigate("/login");
      reset();
    } catch (error) {
      // setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <Grid container style={{ height: "100vh", overflow: "hidden" }}>
      {" "}
      {/* Left Section */}
      <Grid item xs={12} sm={6} sx={{ display: { xs: "none", sm: "block" } }}>
        <Box
          sx={{
            textAlign: "center",
            height: "100%",
            p: "40px 60px",
            background: (theme) => theme.palette.primary.light,
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              color: "white",
              mb: 12,
            }}
          >
            In order to reset your password, enter your email address and submit{" "}
          </Typography>
          <img
            // width="100%"
            height="436px"
            style={{
              objectFit: "contain",
            }}
            src={illustration}
            alt="illustration"
          />
        </Box>
      </Grid>
      {/* Right Section */}
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Box sx={{ width: "80%" }}>
            <Box sx={{ mb: "44px" }}>
              <Typography
                variant="bold"
                sx={{
                  fontSize: "24px",
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                Forgot Password
              </Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ mb: 5 }}>
                <Box sx={{ ...dFlex, flexDirection: "column" }}>
                  <Typography
                    variant="bold2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      mb: 1,
                    }}
                  >
                    E-mail ID*
                  </Typography>
                  <FormTextField
                    fullWidth
                    placeholder="email@domain.com"
                    inputProps={{
                      style: {
                        textTransform: "lowercase",
                      },
                    }}
                    {...register("email", {
                      required: "This field is mandatory",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid Email Address",
                      },
                    })}
                    error={Boolean(errors?.email)}
                  />
                  {Boolean(errors?.email) && (
                    <FormHelperText error={true}>
                      <Typography variant="semibold2">
                        {errors?.email?.message}
                      </Typography>
                    </FormHelperText>
                  )}
                </Box>
                {errors?.apiError && (
                  <FormHelperText sx={{ fontSize: "16px" }} error={true}>
                    {errors?.apiError?.message}
                  </FormHelperText>
                )}
              </Box>
              <Box sx={{ ...flexCenter }}>
                <Button disabled={loading} type="submit" variant="primary">
                  Send E-mail
                </Button>
              </Box>
            </form>
            <Box sx={{ mt: "22px", textAlign: "center" }}>
              <Typography
                variant="semibold"
                sx={{
                  textAlign: "center",
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                <Typography
                  onClick={() => {
                    navigate("/login");
                  }}
                  variant="bold2"
                  sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Back to Login
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
