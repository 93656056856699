import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";

let theme = createTheme({
  typography: {
    fontFamily: "Nunito",
    bold: {
      fontSize: "20px",
      fontWeight: 700,
    },
    bold2: {
      fontSize: "16px",
      fontWeight: 700,
    },
    semibold: {
      fontSize: "16px",
      fontWeight: 600,
    },
    semibold2: {
      fontSize: "12px",
      fontWeight: 600,
    },
    medium: {
      fontSize: "16px",
      fontWeight: 500,
    },
    medium2: {
      fontSize: "14px",
      fontWeight: 500,
    },
    regular: {
      fontSize: "16px",
      fontWeight: 400,
    },
    // light: {
    //   lineHeight: "25px",
    //   fontSize: "18px",
    // },
    // formLabel: {
    //   lineHeight: "25px",
    //   fontSize: "16px",
    //   fontWeight: 600,
    // },
    // linkLabel: {
    //   lineHeight: "25px",
    //   fontSize: "16px",
    //   fontWeight: 600,
    // },
  },
  palette: {
    primary: {
      main: "#358B8A",
      light: "#47BBC3",
      lighter: "#C4DDDD",
    },
    content: {
      lesson: "#5F5DCD",
      item: "#44B2D4",
      topic: "#E92994",
      quiz: "#FF65BB",
      quizQuestion: "#FEBC1D",
      brawtaChallenge: "#6FBB40",
      brawtaQuestion: "#A6E178",
    },
    text: {
      main: "#5C5C5C",
      dark: "#1E1E1E",
      light: "#959595",
      lighter: "#DEDEDE",
    },
    background: {
      main: "#F3F5F5",
      secondary: "#FFFFFF",
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "primary" },
          style: {
            fontSize: "16px",
            fontWeight: 700,
            gap: "8px",
            height: "40px",
            marginRight: "8px",
            borderRadius: "6px",
            color: "white",
            textTransform: "none",
            backgroundImage:
              "radial-gradient(159.52% 159.52% at 36.92% -59.52%, #06CFF1 0%, #358B8A 100%)",
            "&:disabled": {
              color: "white",
              backgroundImage:
                "radial-gradient(159.52% 159.52% at 36.92% -59.52%, rgba(6, 207, 241, 0.5) 0%, rgba(53, 139, 138, 0.5) 100%)",
            },
          },
        },
        {
          props: { variant: "primary-medium" },
          style: {
            fontSize: "16px",
            fontWeight: 600,
            gap: "8px",
            height: "35px",
            marginRight: "8px",
            borderRadius: "6px",
            color: "white",
            textTransform: "none",
            backgroundImage:
              "radial-gradient(159.52% 159.52% at 36.92% -59.52%, #06CFF1 0%, #358B8A 100%)",
            "&:disabled": {
              color: "white",
              backgroundImage:
                "radial-gradient(159.52% 159.52% at 36.92% -59.52%, rgba(6, 207, 241, 0.5) 0%, rgba(53, 139, 138, 0.5) 100%)",
            },
          },
        },
        {
          props: { variant: "primary-small" },
          style: {
            fontSize: "16px",
            fontWeight: 600,
            gap: "8px",
            height: "28px",
            marginRight: "8px",
            borderRadius: "6px",
            color: "white",
            textTransform: "none",
            backgroundImage:
              "radial-gradient(159.52% 159.52% at 36.92% -59.52%, #06CFF1 0%, #358B8A 100%)",
            "&:disabled": {
              color: "white",
              backgroundImage:
                "radial-gradient(159.52% 159.52% at 36.92% -59.52%, rgba(6, 207, 241, 0.5) 0%, rgba(53, 139, 138, 0.5) 100%)",
            },
          },
        },
        {
          props: { variant: "primary-disabled" },
          style: {
            fontSize: "16px",
            fontWeight: 600,
            gap: "8px",
            height: "40px",
            marginRight: "8px",
            borderRadius: "6px",
            color: "white",
            textTransform: "none",
            backgroundImage:
              "radial-gradient(159.52% 159.52% at 36.92% -59.52%, rgba(6, 207, 241, 0.5) 0%, rgba(53, 139, 138, 0.5) 100%)",
          },
        },
        {
          props: { variant: "disable" },
          style: {
            // filter: "drop-shadow(0px 25px 33px rgba(0, 0, 0, 0.25))",
            fontSize: "16px",
            fontWeight: 500,
            height: "30px",
            borderRadius: "7px",
            marginRight: "8px",
            color: "#FFF",
            textTransform: "none",
            backgroundColor: "#959595",
          },
        },
      ],
      defaultProps: {
        margin: 2,
      },
    },
    // MuiTab: {
    //   defaultProps: {
    //     disableRipple: true,
    //   },
    // },
  },
});
theme = responsiveFontSizes(theme);
const AppThemeProvider = (props) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default AppThemeProvider;
