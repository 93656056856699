import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  dFlex,
  flexBetweenWithItemsCenter,
  itemsCenter,
} from "../../../../common/commonStyles";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FileCard from "./FileCard";

export default function FileList({ mins, setFileSelected, data, fetchAgain }) {
  const [seeMore, setSeeMore] = useState(false);

  return (
    <Box sx={{ mb: 5 }}>
      <Box sx={{ ...flexBetweenWithItemsCenter }}>
        <Typography
          variant="semibold"
          sx={{ color: (theme) => theme.palette.text.dark }}
        >
          {mins} Minutes
        </Typography>
        {data?.length > 5 && (
          <Button
            sx={{ textTransform: "none", p: 0 }}
            onClick={() => {
              setSeeMore((prev) => !prev);
            }}
          >
            <Typography
              variant="semibold"
              sx={{
                color: (theme) => theme.palette.primary.main,
                ...itemsCenter,
                cursor: "pointer",
              }}
            >
              {seeMore ? <>See Less</> : <>See More</>}
              <KeyboardArrowUpIcon
                sx={{
                  transition: "all 0.2s linear",
                  transform: seeMore ? "" : "rotate(180deg)",
                }}
              />
            </Typography>
          </Button>
        )}
        {/* <Typography
          variant="semibold"
          sx={{
            color: (theme) => theme.palette.primary.main,
            cursor: "pointer",
          }}
          onClick={() => {
            setSeeMore((p) => !p);
          }}
        >
          See All
        </Typography> */}
      </Box>
      <Box
        sx={{
          overflow: "hidden",
          flexWrap: "wrap",
          transition: "max-height 0.75s ease",
          maxHeight: seeMore ? "1200px" : "220px",
          mt: 2,
          ...dFlex,
          gap: 3,
        }}
      >
        {data?.map((e, i) => {
          return (
            <FileCard
              details={e}
              index={i}
              setFileSelected={setFileSelected}
              fetchAgain={fetchAgain}
            />
          );
        })}
      </Box>
    </Box>
  );
}
