import {
  Avatar,
  Box,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CancelSubscriptionModal from "../../../Components/Admin/Transactions/CancelSubscriptionModal";
import DeleteAccountModal from "../../../Components/Admin/Transactions/DeleteAccountModal ";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { dFlex, itemsCenter } from "../../../common/commonStyles";
import {
  StyledTableCell,
  StyledTableRow,
  TableRowSkeletonComponent,
} from "../../../const/Table";
import { getFormattedDate } from "../../../const/constFunctions";

export default function AccountDetails({ from }) {
  const [transactionDetail, setTransactionDetail] = useState({
    name: "",
    redirectText: null,
  });

  const [url, setUrl] = useState(null);
  // process.env.REACT_APP_SA_TRANSACTIONS + "/list";
  const [apiData] = useDataFetchHook(url);
  const [redirectType, setRedirectType] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const state = location?.state ?? {};
    setTransactionDetail(state?.transactionDetail ?? { name: "" });
    setUrl(
      process.env.REACT_APP_SA_TRANSACTIONS +
        `/list/${state?.transactionDetail?.schoolId}`
    );
    setRedirectType(state.type ?? "list");
  }, [location.state]);

  return (
    <Box>
      <NavBreadCrumbs
        pageTags={[
          "Dashboard",
          from == "delete" ? "Delete Account List" : "Cancel Subscription List",
          `${transactionDetail?.name}`,
        ]}
      />

      <Box sx={{ ...dFlex, gap: 2, justifyContent: "space-between" }}>
        <Box
          sx={{
            ...itemsCenter,
            gap: "4%",
            mb: 5,
            flex: 1,
          }}
        >
          <Avatar
            src={transactionDetail?.userData?.image}
            alt="profile image"
            sx={{ width: "106px", height: "106px" }}
          >
            {transactionDetail?.userData?.name?.[0]}
          </Avatar>

          <Box sx={{ flex: 1 }}>
            <Stack spacing={2}>
              <Box sx={{ ...itemsCenter, gap: "20px" }}>
                <Typography
                  variant="medium"
                  sx={{
                    fontSize: "16px",
                    width: "70px",
                    color: (theme) => theme.palette.text.dark,
                  }}
                >
                  Name:
                </Typography>
                <Typography
                  variant="regular"
                  sx={{
                    width: "60%",
                    color: (theme) => theme.palette.text.main,
                  }}
                >
                  {transactionDetail?.userData?.name}
                </Typography>
              </Box>
              <Box sx={{ ...itemsCenter, gap: "20px" }}>
                <Typography
                  variant="medium"
                  sx={{
                    fontSize: "16px",
                    width: "70px",
                    color: (theme) => theme.palette.text.dark,
                  }}
                >
                  Email:
                </Typography>
                <Typography
                  variant="regular"
                  sx={{
                    width: "60%",
                    color: (theme) => theme.palette.text.main,
                  }}
                >
                  {transactionDetail?.userData?.email ?? "NA"}
                </Typography>
              </Box>
              <Box sx={{ ...itemsCenter, gap: "20px" }}>
                <Typography
                  variant="medium"
                  sx={{
                    fontSize: "16px",
                    width: "70px",
                    color: (theme) => theme.palette.text.dark,
                  }}
                >
                  Phone:
                </Typography>
                <Typography
                  variant="regular"
                  sx={{
                    width: "60%",
                    color: (theme) => theme.palette.text.main,
                  }}
                >
                  {transactionDetail?.userData?.phone ?? "NA"}
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Box>
        {transactionDetail?.requestStatus !== "completed" ? (
          <Box>
            {redirectType == "delete" ? (
              <DeleteAccountModal />
            ) : (
              <CancelSubscriptionModal />
            )}
            {/* <RefundModal /> */}
          </Box>
        ) : (
          <Box>
            <Typography
              variant="medium"
              sx={{ color: (theme) => theme.palette.primary.main }}
            >
              This request is completed
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.secondary,
          borderRadius: "10px",
          maxHeight: "45vh",
          p: 3,
          mb: 5,
          boxSizing: "border-box",
          overflow: "auto",
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography
            variant="medium"
            sx={{
              color: (theme) => theme.palette.text.dark,
            }}
          >
            Reason:
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="medium"
            sx={{
              color: (theme) => theme.palette.text.main,
            }}
          >
            {transactionDetail?.reason ?? "NA"}
          </Typography>
        </Box>
      </Box>

      <Typography
        variant="bold"
        sx={{ color: (theme) => theme.palette.primary.main, mb: 2 }}
      >
        {" "}
        Transaction Details
      </Typography>

      <TableContainer
        sx={{
          overflowX: "initial",
          backgroundColor: (theme) => theme.palette.background.secondary,
          pt: 1,
          borderRadius: "10px",
          mt: 2,
        }}
      >
        <Table stickyHeader>
          {/* Table header */}
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Typography
                  variant="semibold"
                  sx={{ color: (theme) => theme.palette.text.dark }}
                >
                  Date
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  variant="semibold"
                  sx={{ color: (theme) => theme.palette.text.dark }}
                >
                  Amount
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  variant="semibold"
                  sx={{ color: (theme) => theme.palette.text.dark }}
                >
                  Student Count
                </Typography>
              </StyledTableCell>

              <StyledTableCell>
                <Typography
                  variant="semibold"
                  sx={{ color: (theme) => theme.palette.text.dark }}
                >
                  Invoice
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography
                  variant="semibold"
                  sx={{ color: (theme) => theme.palette.text.dark }}
                >
                  Transaction Type
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>

          {/* Table body */}
          {apiData?.loading ? (
            <TableRowSkeletonComponent column={5} row={4} />
          ) : (
            <TableBody>
              {apiData?.res?.data?.map((row, index) => (
                <StyledTableRow
                  sx={{
                    cursor: "default!important",
                  }}
                >
                  <StyledTableCell>
                    <Typography
                      variant="regular"
                      sx={{ color: (theme) => theme.palette.text.main }}
                    >
                      {getFormattedDate(row?.transactionDate)}{" "}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      variant="regular"
                      sx={{ color: (theme) => theme.palette.text.main }}
                    >
                      {row.amount}{" "}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      variant="regular"
                      sx={{ color: (theme) => theme.palette.text.main }}
                    >
                      {row?.student ?? 0}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      variant="regular"
                      sx={{ color: (theme) => theme.palette.text.main }}
                    >
                      {row.invoiceId}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography
                      variant="regular"
                      sx={{ color: (theme) => theme.palette.text.main }}
                    >
                      {row.transactionType}
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}
