import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import sendNotification from "../../../functions/PushNotification";

export default function SuspendStudentModal({
  data,
  fetchStudentAgain,
  normalButton,
  suspended,
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => {
    //info the data
    // console.log("data", data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuspend = async () => {
    try {
      const url = process.env.REACT_APP_SUSPEND_STUDENT;
      setLoading(true);
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          id: data?.id,
          status: !suspended,
        },
      });

      // console.log(config);
      const apiRes = await axios.request(config);
      sendNotification({
        deviceToken: data?.fcmToken,
        title: suspended ? "Chat Activated" : "Chat Suspended",
        body: suspended
          ? "Your are allowed to chat again"
          : "You have been restricted to chat",
        type: "chatSuspended",
        userId: data.id,
      });
      toast(apiRes?.data?.message);
      fetchStudentAgain?.();
      handleClose();
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <>
      {normalButton ? (
        <Button
          onClick={handleOpen}
          sx={{
            textTransform: "none",
            color: (theme) => theme.palette.text.main,
            justifyContent: "flex-start",
            ml: 1,
          }}
        >
          {" "}
          {suspended ? "Activate" : "Suspend"} Student
        </Button>
      ) : suspended ? (
        <Button
          onClick={handleOpen}
          sx={{
            textTransform: "none",
            color: (theme) => theme.palette.content.quizQuestion,
          }}
        >
          {" "}
          Activate
        </Button>
      ) : (
        <Button onClick={handleOpen} sx={{ textTransform: "none" }}>
          {" "}
          Suspend
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "13px",
            backgroundColor: (theme) => theme.palette.background.main,
          },
        }}
      >
        <Box sx={{ width: "458px", pb: "20px" }}>
          {/* <form onSubmit={handleDeleteAdmission} autoComplete="off"> */}
          <DialogContent sx={{ mb: 1 }}>
            <DialogContentText id="alert-dialog-description">
              <Typography
                variant="regular"
                sx={{
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Are you sure you want to {suspended ? "Activate" : "Suspend"}{" "}
                {data?.studentName} from the batch?
              </Typography>
              {!suspended && (
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: "#FEBC1D",
                  }}
                >
                  They can still read the messages but cannot post anything in
                  the chats
                </Typography>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={loading}
              onClick={handleSuspend}
              variant="primary"
            >
              {suspended ? "Activate" : "Suspend"}
            </Button>
          </DialogActions>
          {/* </form> */}
        </Box>
      </Dialog>
    </>
  );
}
