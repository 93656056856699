import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import AceQuestionAnswerTab from "../Components/Facilitator/ManageStudent/RiskProflingAnswer/AceQuestionAnswerTab";
import SDQQuestionAnswerTab from "../Components/Facilitator/StudentProgress/SDQQuestionAnswerTab";
import useDataFetchHook from "../Hooks/DataFetchHook";
import { flexCenter } from "../common/commonStyles";

export default function PrintRiskProfling() {
  const ref = useRef();
  const params = useParams();
  const aceURL =
    process.env.REACT_APP_SUPERADMIN_RISK_PROFILING_QUESTION + "?type=ACE";
  const sdqPreURL =
    process.env.REACT_APP_SUPERADMIN_RISK_PROFILING_QUESTION + "?type=SDQPre";
  const sdqPOSTURL =
    process.env.REACT_APP_SUPERADMIN_RISK_PROFILING_QUESTION + "?type=SDQPost";
  const getRiskProfilingAnswer =
    process.env.REACT_APP_FACILITATOR_RISK_PROFILING_QUESTIONS + "/getrecord?";
  const [aceData, fetchAceData] = useDataFetchHook(aceURL);
  const [sdqPreData, fetchSDQPreData] = useDataFetchHook(sdqPreURL);
  const [sdqPostData, fetchSDQPostData] = useDataFetchHook(sdqPOSTURL);
  const [aceDataAnswer] = useDataFetchHook(
    getRiskProfilingAnswer + `type=ACE&id=${params?.studentId}`
  );
  const [sdqPreDataAnswer] = useDataFetchHook(
    getRiskProfilingAnswer + `type=SDQPre&id=${params?.studentId}`
  );
  const [sdqPostDataAnswer] = useDataFetchHook(
    getRiskProfilingAnswer + `type=SDQPost&id=${params?.studentId}`
  );

  const [aceAnswer, setAceAnswer] = useState({});
  const [sdqPreAnswer, setSdqPreAnswer] = useState({});
  const [sdqPostAnswer, setSdqPostAnswer] = useState({});

  useEffect(() => {
    setAceAnswer(aceDataAnswer?.res?.record);
    setSdqPreAnswer(sdqPreDataAnswer?.res?.record);
    setSdqPostAnswer(sdqPostDataAnswer?.res?.record);
  }, [aceDataAnswer, sdqPreDataAnswer, sdqPostDataAnswer]);

  return (
    <Box sx={{ m: 3 }}>
      <ReactToPrint
        trigger={() => (
          <Button
            disabled={
              aceData.loading ||
              sdqPreData.loading ||
              sdqPostData.loading ||
              aceDataAnswer.loading ||
              sdqPreDataAnswer.loading ||
              sdqPostDataAnswer.loading
            }
            variant="primary"
          >
            Print
          </Button>
        )}
        content={() => ref.current}
        removeAfterPrint={true}
        onAfterPrint={() => {
          console.log("sdfs");
        }}
      />
      <Box ref={ref} sx={{ m: 3 }}>
        {(aceData.loading ||
          sdqPreData.loading ||
          sdqPostData.loading ||
          aceDataAnswer.loading ||
          sdqPreDataAnswer.loading ||
          sdqPostDataAnswer.loading) && (
          <Box sx={{ ...flexCenter }}>
            <CircularProgress />
          </Box>
        )}
        <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
          ACE Answers
        </Typography>
        <AceQuestionAnswerTab
          data={aceData?.res?.question ?? []}
          answers={aceAnswer}
          print={true}
          setAnswers={setAceAnswer}
        />
        <hr className="pagebreak" />
        <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
          SDQ Pre Answers
        </Typography>
        <SDQQuestionAnswerTab
          data={sdqPreData?.res?.question ?? []}
          answers={sdqPreAnswer}
          print={true}
          setAnswers={setSdqPreAnswer}
          type={"Pre"}
        />
        <hr className="pagebreak" />
        <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
          SDQ Post Answers
        </Typography>
        <SDQQuestionAnswerTab
          data={sdqPostData?.res?.question ?? []}
          answers={sdqPostAnswer}
          print={true}
          setAnswers={setSdqPostAnswer}
          type={"Post"}
        />
      </Box>
    </Box>
  );
}
