import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../Apis/axiosPayloads";
import { POST } from "../../Apis/constVariables";
import { dFlex, flexCenter } from "../../common/commonStyles";
import { FormTextField } from "../../const/TextField";
import illustration from "../../images/login_illustration.png";
export default function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showCnfPassword, setCnfShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm();

  const onSubmit = async (e) => {
    try {
      setLoading(true);
      const currUrl = new URL(window.location.href);
      // console.log({ currUrl });
      // console.log(currUrl.searchParams.toString());
      // console.log(e);
      // Reset form values
      // reset();
      const url = process.env.REACT_APP_RESET_PASSWORD;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...e,
          oobCode: currUrl.searchParams.get("oobCode"),
          uid: currUrl.searchParams.get("uid"),
        },
      });
      const apiRes = await axios.request(config);
      // console.log(e);
      // console.log({ apiRes });
      toast(apiRes?.data?.message);

      // Navigate to a different page
      navigate("/login", { replace: true });
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <Grid container style={{ height: "100vh", overflow: "hidden" }}>
      {/* Left Section */}
      <Grid item xs={12} sm={6} sx={{ display: { xs: "none", sm: "block" } }}>
        <Box
          sx={{
            textAlign: "center",
            height: "100%",
            p: "40px 60px",
            background: (theme) => theme.palette.primary.light,
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              color: "white",
              mb: 16,
            }}
          >
            Please enter your new password.{" "}
          </Typography>
          <img
            // width="100%"
            height="436px"
            style={{
              objectFit: "contain",
            }}
            src={illustration}
            alt="illustration"
          />
        </Box>
      </Grid>

      {/* Right Section */}
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Box sx={{ width: "80%" }}>
            <Box sx={{ mb: "44px" }}>
              <Typography
                variant="bold"
                sx={{
                  fontSize: "24px",
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                Reset Password
              </Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ mb: 8 }}>
                <Box sx={{ mb: 4, ...dFlex, flexDirection: "column" }}>
                  <Typography
                    variant="bold2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      mb: 1,
                    }}
                  >
                    Password*
                  </Typography>
                  <FormTextField
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => {
                            setShowPassword((p) => !p);
                          }}
                        >
                          {showPassword ? (
                            <VisibilityOffIcon fontSize="small" />
                          ) : (
                            <VisibilityIcon fontSize="small" />
                          )}
                        </IconButton>
                      ),
                    }}
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    placeholder="password"
                    {...register("password", {
                      required: "This field is mandatory",
                      minLength: {
                        value: 10,
                        message: "Password should be at least 10 characters",
                      },
                      maxLength: {
                        value: 20,
                        message: "Password should be at most 20 characters",
                      },
                      validate: {
                        uppercase: (value) =>
                          /^(?=.*[A-Z])/.test(value) ||
                          "Password must contain at least one uppercase letter",
                        lowercase: (value) =>
                          /^(?=.*[a-z])/.test(value) ||
                          "Password must contain at least one lowercase letter",
                        number: (value) =>
                          /^(?=.*\d)/.test(value) ||
                          "Password must contain at least one number",
                        symbol: (value) =>
                          /^(?=.*[!@#$%^*()])/.test(value) ||
                          "Password must contain at least one of the following symbols: ! @ # $ % ^ * ( )",
                        space: (value) =>
                          !/\s/.test(value) || "Password cannot contain spaces",
                      },
                    })}
                    error={Boolean(errors?.password)}
                  />
                  {Boolean(errors?.password) && (
                    <FormHelperText error={true}>
                      <Typography variant="semibold2">
                        {errors?.password?.message}
                      </Typography>
                    </FormHelperText>
                  )}
                </Box>
                <Box sx={{ ...dFlex, flexDirection: "column" }}>
                  <Typography
                    variant="bold2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                      mb: 1,
                    }}
                  >
                    Confirm Password*
                  </Typography>
                  <FormTextField
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => {
                            setCnfShowPassword((p) => !p);
                          }}
                        >
                          {showCnfPassword ? (
                            <VisibilityOffIcon fontSize="small" />
                          ) : (
                            <VisibilityIcon fontSize="small" />
                          )}
                        </IconButton>
                      ),
                    }}
                    fullWidth
                    type={showCnfPassword ? "text" : "password"}
                    placeholder="confirm password"
                    {...register("cnfPassword", {
                      required: "This field is mandatory",
                      validate: (value) =>
                        value === watch("password") || "Passwords do not match",
                    })}
                    error={Boolean(errors?.cnfPassword)}
                  />
                  {Boolean(errors?.cnfPassword) && (
                    <FormHelperText error={true}>
                      <Typography variant="semibold2">
                        {errors?.cnfPassword?.message}
                      </Typography>
                    </FormHelperText>
                  )}
                </Box>
                {errors?.apiError && (
                  <FormHelperText sx={{ fontSize: "16px" }} error={true}>
                    {errors?.apiError?.message}
                  </FormHelperText>
                )}
                <Box sx={{ mt: 1, ...dFlex, flexDirection: "column" }}>
                  <Typography
                    variant="semibold2"
                    sx={{
                      color: (theme) => theme.palette.text.light,
                    }}
                  >
                    At least 10 characters (20 maximum)
                  </Typography>
                  <Typography
                    variant="semibold2"
                    sx={{
                      color: (theme) => theme.palette.text.light,
                    }}
                  >
                    At least one upper case letter
                  </Typography>
                  <Typography
                    variant="semibold2"
                    sx={{
                      color: (theme) => theme.palette.text.light,
                    }}
                  >
                    At least one number
                  </Typography>
                  <Typography
                    variant="semibold2"
                    sx={{
                      color: (theme) => theme.palette.text.light,
                    }}
                  >
                    At least one of the following symbols ! @ # $ % ^ * ( )
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ ...flexCenter }}>
                <Button disabled={loading} type="submit" variant="primary">
                  Save
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
