import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from "@mui/material";
import { DeleteIcon } from "../../../../images/icons";
import { DELETE } from "../../../../Apis/constVariables";
import axios from "axios";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../../Apis/axiosPayloads";

export default function FileDeleteModal({ fetchAgain, details }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      if (!details?.id) {
        toast.error("Something went wrong! Please try again");
        handleClose();
        return;
      }
      const url =
        process.env.REACT_APP_GYM_CATEGORY +
        `/${details?.categoryId}/${details?.id}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: DELETE,
      });
      setLoading(true);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchAgain();
      handleClose();
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          position: "absolute",
          right: 5,
          bottom: 5,
          p: "10px 12px",
          backgroundColor: (theme) => theme.palette.background.secondary,
          "&:hover": {
            backgroundColor: (theme) => theme.palette.background.secondary,
          },
        }}
      >
        <DeleteIcon fill={"#358B8A"} />
      </IconButton>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              backgroundColor: (theme) => theme.palette.background.main,
              borderRadius: "8px",
              px: 1,
              pb: 1,
              width: "25%",
            },
          }}
        >
          {/* <form onSubmit={handleDeleteAdmission} autoComplete="off"> */}
          <DialogContent>
            <DialogContentText>
              <Typography
                variant="medium"
                sx={{
                  textAlign: "center",
                  color: (theme) => theme.palette.text.main,
                }}
              >
                Are you sure you want to delete file <b>{details?.title}</b>?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleDelete} disabled={loading} variant="primary">
              {loading ? <CircularProgress /> : "Delete"}
            </Button>
          </DialogActions>
          {/* </form> */}
        </Dialog>
      )}
    </>
  );
}
