import { Box, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import useTablePagination from "../../../Hooks/TablePaginationHook";
import ArchivedStudentCard from "./ArchivedStudentCard";

export default function ArchivedStudentTab({ data }) {
  const [studentUrl, setStudentUrl] = useState(null);
  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();
  const [studentApiCall, fetchStudentAgain] = useDataFetchHook(studentUrl);
  useEffect(() => {
    setStudentUrl(
      `${process.env.REACT_APP_STUDENT_ARCHIVE_LIST}${data.batchName}&offset=0&limit=50`
    );
  }, [data]);

  return (
    <Box>
      {studentApiCall?.res?.Student?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ).map((e) => (
        <ArchivedStudentCard
          data={e}
          key={e?.id}
          fetchAgain={fetchStudentAgain}
        />
      ))}
      {studentApiCall?.res?.Student?.length >= 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            rowsPerPageOptions={[]}
            //   count={
            //     searchText
            //       ? searchData.length
            //       : studentApiCall?.res?.Student?.length
            //   }
            count={studentApiCall?.res?.Student?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
}
