import {
  Box,
  Button,
  Dialog,
  DialogActions,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { dFlex } from "../../../../../common/commonStyles";

export default function PicMCQModal({ data, index }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    console.log({ data });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box onClick={handleClickOpen}>
        <Typography
          sx={{
            color: (theme) => theme.palette.text.main,
            px: 1,
            cursor: "pointer",
            transition: "all 0.2s ease",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.background.main,
            },
          }}
        >
          {data?.questionName}
        </Typography>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "#F3F5F5",
            borderRadius: "14px",
            p: "24px 12px 12px 24px",
            maxWidth: "100%",
            width: "70%",
          },
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.text.main,
            mb: 1,
          }}
        >
          {index + 1}. {data?.question}
        </Typography>
        <RadioGroup value={data?.stdAnswer}>
          {data?.data?.options?.map((e, i) => (
            <FormControlLabel
              key={i}
              sx={{
                my: 1,
                backgroundColor: (theme) => theme.palette.background.secondary,
                borderRadius: "6px",
              }}
              // disabled
              control={<Radio />}
              value={i}
              label={
                <Typography
                  variant="medium"
                  sx={{
                    color: (theme) => theme.palette.text.main,
                    ...dFlex,
                    alignItems: "center",
                    py: 1,
                  }}
                >
                  <img
                    src={e?.pic}
                    style={{
                      borderRadius: "5px",
                      marginRight: "16px",
                      height: "70px",
                      width: "100px",
                    }}
                  />
                  {e.label}
                </Typography>
              }
            />
          ))}
        </RadioGroup>

        <DialogActions sx={{ pt: 1 }}>
          <Button
            sx={{ height: "32px", textTransform: "none" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
