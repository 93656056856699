import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../Apis/axiosPayloads";
import { PATCH, POST } from "../../Apis/constVariables";
import useDataFetchHook from "../../Hooks/DataFetchHook";
import { flexCenter, itemsCenter } from "../../common/commonStyles";
import { FormTextField } from "../../const/TextField";
import { AccordionTransition } from "../../const/constFunctions";
import { success } from "../../const/variables";
import { EditIconColor } from "../../images/icons";
import CreateBrawtaChallenge from "./CreateBrawtaChallenge";
import CreateQuiz from "./CreateQuiz";
import TopicDeleteModal from "./DeleteModals/TopicDeleteModal ";
import Item from "./Item";
import { ExpandMoreSvg, SvgVector } from "./icons";
import "./styles/item.css";

const initData = {
  topicName: "",
  topicDescription: "",
};

const CreateLesson = ({ moduleId, lessonId }) => {
  const [topicFormShow, setTopicFormShow] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const url = process.env.REACT_APP_ALL_TOPIC + `?lessonId=${lessonId}`;
  const [topicApiCall, fetchTopicAgain, topicDispatch] = useDataFetchHook(url);

  const addTopic = async (data) => {
    try {
      let nsn = data?.topicName.replaceAll(" ", "");
      if (!nsn) {
        toast.error("Fields Cannot be empty");
        return;
      }
      let dat = /\//i.test(nsn);
      if (!dat) {
      } else {
        toast.error("you can not use any (/) characters");
        return;
      }

      setLoading(true);
      const url = process.env.REACT_APP_TOPIC;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...data,
          topicName: data?.topicName.trim(),
          topicDescription: data?.topicDescription.trim(),
          lessonAssociated: lessonId,
          moduleAssociated: moduleId,
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchTopicAgain();
      toggle();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const updateTopic = async (data) => {
    try {
      let nsn = data?.topicName.replaceAll(" ", "");
      if (!nsn) {
        toast.error("Fields Cannot be empty");
        return;
      }
      let dat = /\//i.test(nsn);
      if (!dat) {
      } else {
        toast.error("you can not use any (/) characters");
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_TOPIC + `/${data?.topicId}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: {
          topicName: data?.topicName.trim(),
          topicDescription: data?.topicDescription.trim(),
          lessonAssociated: lessonId,
          moduleAssociated: moduleId,
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchTopicAgain();
      toggle();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const toggle = () => {
    reset(initData);
    setTopicFormShow((p) => !p);
  };

  const edit = (topic) => {
    setEditable(true);
    setTopicFormShow((p) => !p);
    reset(topic);
  };

  const handleOnDragEnd = async (result) => {
    try {
      if (!result.destination) return;
      const items = Array.from(topicApiCall?.res?.topics);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setLoading(true);
      const url = process.env.REACT_APP_LESSON + `/addLessonTopicOrder`;
      const formattedData = {
        lessonId: lessonId,
        topics: items.map((e) => e.topicId),
      };
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: formattedData,
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      topicDispatch({ type: success, data: { topics: items } });
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <>
      <br></br>
      <Item lessonId={lessonId} />
      <br></br>
      <section
        className="section"
        style={{ marginBlockStart: "0px", width: "100%" }}
      >
        <Button
          variant="primary-small"
          onClick={() => {
            setEditable(false);
            toggle();
          }}
        >
          {/* <AddIcon /> */}
          Add Topic
        </Button>
        {topicFormShow && (
          <Box className="addItem-container" id={lessonId + "topicForm"}>
            <Typography
              variant="bold"
              sx={{ color: (theme) => theme.palette.primary.main }}
            >
              Topic
            </Typography>
            <Box className="addItem-row" sx={{ my: 2 }}>
              <Typography
                className="addItem-inputLabel"
                sx={{
                  color: (theme) => theme.palette.text.dark,
                }}
              >
                Topic Name*
              </Typography>
              <FormTextField
                {...register("topicName", {
                  required: "Topic name is required",
                })}
                autoFocus
                autoComplete="off"
                sx={{ width: "30%" }}
                error={!!errors.topicName}
                helperText={errors?.topicName?.message}
              />
            </Box>
            <Box className="addItem-row">
              <Typography
                className="addItem-inputLabel"
                sx={{
                  color: (theme) => theme.palette.text.dark,
                }}
              >
                Short Description*
              </Typography>
              <FormTextField
                {...register("topicDescription", {
                  required: "Topic description is required",
                })}
                autoComplete="off"
                sx={{ width: "30%" }}
                error={!!errors.topicDescription}
                helperText={errors?.topicDescription?.message}
              />
            </Box>
            <Box className="addItem-row">
              <Button
                // className="addItem-btn"
                style={{ marginLeft: "auto" }}
                sx={{
                  textTransform: "none",
                }}
                onClick={toggle}
              >
                Cancel
              </Button>
              <Button
                variant="primary-small"
                disabled={loading}
                onClick={
                  !editable ? handleSubmit(addTopic) : handleSubmit(updateTopic)
                }
              >
                {editable ? "Update" : "Save"}
              </Button>
            </Box>
          </Box>
        )}

        {(topicApiCall?.loading || loading) && (
          <Box sx={{ ...flexCenter }}>
            <CircularProgress />
          </Box>
        )}

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <ul
                id="topicList"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  listStyleType: "none",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                {topicApiCall?.res?.topics?.map((item, index) => (
                  <Draggable
                    key={item.topicId}
                    draggableId={item.topicId}
                    index={index}
                  >
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        key={item.topicId}
                        className="draggableTopic-li"
                      >
                        <Accordion TransitionProps={AccordionTransition}>
                          <AccordionSummary
                            {...provided.dragHandleProps}
                            expandIcon={<ExpandMoreSvg />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.content.topic,
                              color: "white",
                              height: "40px",
                              borderRadius: "7px",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <SvgVector />
                              <Box className="lessonList-accordion-heading">
                                {item.topicName}
                              </Box>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box className="lessonList-draggable-summary">
                              <Typography
                                sx={{
                                  color: (theme) => theme.palette.text.main,
                                }}
                              >
                                {item.topicDescription}
                              </Typography>
                              <Box
                                sx={{
                                  marginLeft: "auto",
                                  ...itemsCenter,
                                  mr: 1,
                                }}
                              >
                                <IconButton onClick={() => edit(item)}>
                                  <EditIconColor />
                                </IconButton>
                              </Box>
                              <TopicDeleteModal
                                fetchTopicAgain={fetchTopicAgain}
                                data={item}
                              />
                            </Box>
                            <Typography
                              sx={{
                                color: (theme) => theme.palette.text.main,
                              }}
                            >
                              Lesson Associated - {lessonId}
                            </Typography>
                            <br></br>
                            <Item topicId={item.topicId} />
                            <br />
                            <CreateQuiz text={"Quiz"} topicId={item.topicId} />
                            {/* <CreateBrawtaChallenge topicId={item.topicId} /> */}
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </section>

      <CreateQuiz text={"Quiz"} lessonId={lessonId} />
      <CreateBrawtaChallenge lessonId={lessonId} />
    </>
  );
};

export default CreateLesson;
