import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  InputAdornment,
  TablePagination,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddFacilitatorModal from "../../../Components/School/ManageBatch/AddFacilitatorModal";
import AlertStudentModal from "../../../Components/School/ManageBatch/AlertStudentModal";
import StudentCard from "../../../Components/School/ManageBatch/StudentCard";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import useTablePagination from "../../../Hooks/TablePaginationHook";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import {
  dFlex,
  flexBetweenWithItemsCenter,
  flexCenter,
  itemsCenter,
} from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { BatchEmpty } from "../../../const/imageUrl";
import { ArchiveIcon, RegisterIcon } from "../../../images/icons";

export default function BatchDetails() {
  const location = useLocation();
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [limitError, setLimitError] = useState(false);
  const [studentUrl, setStudentUrl] = useState(null);
  const [deletePermissionApi] = useDataFetchHook(
    process.env.REACT_APP_STUDENT_DELETE_PERMISSION
  );
  const [studentApiCall, fetchStudentAgain] = useDataFetchHook(studentUrl);
  const [insUrl, setInsUrl] = useState(null);
  const [insApiCall, fetchInsAgain] = useDataFetchHook(insUrl);
  const navigate = useNavigate();
  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();
  const [batchDetails, setBatchDetails] = useState({
    batchName: "",
  });

  useEffect(() => {
    const { batchDetail } = location?.state ?? { batchName: "" };
    setStudentUrl(
      `${process.env.REACT_APP_SCHOOL_GET_STUDENT_BATCH}${batchDetail.batchName}&offset=0&limit=50`
    );
    setInsUrl(
      `${process.env.REACT_APP_SCHOOL_GET_FACILITATOR_IN_BATCH}${batchDetail.batchName}&offset=0&limit=50`
    );
    setBatchDetails(batchDetail);
  }, [location.state]);

  const memoizedAddFacilitatorModal = useMemo(
    () => (
      <AddFacilitatorModal
        fetchAgain={fetchInsAgain}
        insFacilitatorData={insApiCall?.res?.instructor}
        batchDetails={batchDetails}
      />
    ),
    [insApiCall]
  );
  const filterData = () => {
    console.log("searhing");
    let arr = [...studentApiCall?.res?.Student];
    arr = arr?.filter((e) => {
      return e?.studentName?.toLowerCase()?.includes(searchText.toLowerCase());
    });
    handleChangePage(null, 0);
    setSearchData(arr);
    setLoading(false);
  };
  useEffect(() => {
    if (!searchText) {
      setSearchData([]);
      setLoading(false);
      return;
    }
    filterData();
  }, [searchText]);

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Manage Batch", batchDetails?.batchName]} />

      <Box sx={{ ...flexBetweenWithItemsCenter, height: "40px" }}>
        <Box>
          <FormTextField
            placeholder="search"
            onChange={(e) => {
              setLoading(true);
              setSearchText(e.target.value);
            }}
            value={searchText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    fontSize="small"
                    sx={{
                      color: "#BFBFBF",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          {memoizedAddFacilitatorModal}
          <Button
            variant="primary"
            disabled={studentApiCall?.res?.Student?.length >= 50}
            onClick={() =>
              navigate("add-student", {
                state: {
                  batchDetail: batchDetails,
                },
              })
            }
          >
            <RegisterIcon /> Add Student
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Box>
          <Typography
            sx={{ color: (theme) => theme.palette.text.main, ...dFlex, gap: 2 }}
          >
            Facilitator Name : -{" "}
            <Box>
              {insApiCall?.res?.instructor?.map((e, i) => (
                <Typography
                  sx={{
                    color: (theme) => theme.palette.text.main,
                    ...itemsCenter,
                    gap: 1,
                  }}
                >
                  {e?.instructorName}{" "}
                  <AddFacilitatorModal
                    fetchAgain={fetchInsAgain}
                    data={e}
                    edit={true}
                    batchDetails={batchDetails}
                  />
                </Typography>
              ))}{" "}
            </Box>
          </Typography>
        </Box>
        <Box sx={{ ...flexBetweenWithItemsCenter }}>
          <Typography sx={{ color: (theme) => theme.palette.text.main }}>
            Number of Students : {studentApiCall?.res?.Student?.length}
          </Typography>
          <Button
            variant="outlined"
            onClick={() => {
              navigate("archived", {
                state: {
                  batchDetail: batchDetails,
                },
              });
            }}
            sx={{ textTransform: "none", gap: 1, borderRadius: "7px" }}
          >
            <ArchiveIcon />
            Archived
          </Button>
        </Box>
      </Box>
      <Box>
        {false && (
          <Box
            sx={{
              mt: 3,
              backgroundColor: (theme) => theme.palette.background.secondary,
              ...flexCenter,
              flexDirection: "column",
              py: 8,
              gap: 5,
              borderRadius: "12px",
            }}
          >
            <img
              alt="no batch"
              src={BatchEmpty}
              style={{ height: "220px", width: "600px" }}
            />
            <Typography
              variant="bold"
              sx={{ color: (theme) => theme.palette.text.light }}
            >
              You have not registered student
            </Typography>
            <Button
              variant="primary"
              onClick={() =>
                navigate("add-student", {
                  state: {
                    batchDetail: batchDetails,
                  },
                })
              }
            >
              <RegisterIcon /> Add Student
            </Button>
          </Box>
        )}
      </Box>
      {!searchText && (
        <Box>
          {studentApiCall?.res?.Student?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          ).map((e) => (
            <StudentCard
              data={e}
              batchDetails={batchDetails}
              fetchAgain={fetchStudentAgain}
            />
          ))}
        </Box>
      )}
      {searchText && (
        <Box>
          {searchData
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((e) => (
              <StudentCard
                data={e}
                batchDetails={batchDetails}
                fetchAgain={fetchStudentAgain}
              />
            ))}
        </Box>
      )}
      {studentApiCall?.res?.Student?.length >= 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TablePagination
            rowsPerPageOptions={[]}
            count={
              searchText
                ? searchData.length
                : studentApiCall?.res?.Student?.length
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
      {limitError && <AlertStudentModal setLimitError={setLimitError} />}
    </Box>
  );
}
