import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  dFlex,
  flexSpaceBetween,
  itemsCenter,
} from "../../../common/commonStyles";

export default function SDQIndividualQuestion({ data, index }) {
  // const [options, setOptions] = useState([
  //   { label: "Not True", count: data.response[0] },
  //   { label: "Somewhat True", count: data.response[1] },
  //   { label: "Certainly True", count: data.response[2] },
  // ]);
  const tempArr = [];
  data?.option?.map((_, idx) => {
    tempArr.push({ label: _, count: data?.response[idx] });
  });
  const [options, setOptions] = useState(tempArr);
  return (
    <Box
      sx={{
        my: 3,
        // backgroundColor: (theme) => theme.palette.background.secondary,
        p: 3,
        borderRadius: "15px",
      }}
    >
      <Box sx={{ mb: 1, ...flexSpaceBetween, gap: 8 }}>
        <Typography
          variant="medium"
          sx={{
            flex: "80%",
            color: (theme) => theme.palette.text.dark,
          }}
        >
          {index + 1}. {data.question}
        </Typography>
        <Typography
          variant="bold2"
          sx={{
            flex: "10%",
            color: (theme) => theme.palette.text.main,
          }}
        >
          Answers Count
        </Typography>
      </Box>

      <Box sx={{ ...dFlex, flexDirection: "column", mt: 2 }}>
        {options?.map((e, i) => (
          <Box sx={{ ...itemsCenter, gap: 2, my: 1 }}>
            <Box
              sx={{
                p: "9px 20px",
                flex: "80%",
                borderRadius: "6px",
                color: (theme) => theme.palette.text.dark,
                backgroundColor: (theme) => theme.palette.background.secondary,
                // boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.12)",
                ...itemsCenter,
                gap: 1,
              }}
            >
              <Box
                sx={{
                  width: "22px",
                  height: "22px",
                  border: "2px solid #BFBFBF",
                  borderRadius: "50%",
                }}
              ></Box>{" "}
              {e.label}
            </Box>
            <Typography
              variant="medium"
              sx={{
                flex: "10%",
                pl: 1,
                color: (theme) => theme.palette.text.main,
              }}
            >
              {e.count}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
