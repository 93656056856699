import { Avatar, Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { dFlex, flexCenter, itemsCenter } from "../../../common/commonStyles";
import { convertToAMPMFormat } from "../../../const/constFunctions";
import calenderBack from "../../../images/calender_background.png";
import DeleteMeetingModal from "./DeleteMeetingModal";

export default function MeetingCard({ data, fetchAgain }) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.secondary,
        height: "170px",
        borderRadius: "10px",
        my: 5,
        p: "24px 40px",
        ...dFlex,
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ ...dFlex, gap: 3 }}>
        <Box sx={{ height: "fit-content" }}>
          <Box
            sx={{
              minHeight: "52px",
              minWidth: "52px",
              backgroundImage: `url(${calenderBack})`,
              pt: 0.5,
              boxSizing: "border-box",
              color: "#FEBC1D",
              fontSize: "20px",
              fontWeight: 600,
              ...flexCenter,
            }}
          >
            {data?.date?.split("-")?.[2]}
          </Box>
        </Box>
        <Box>
          <Typography
            variant="regular"
            sx={{
              color: (theme) => theme.palette.text.main,
              textTransform: "capitalize",
            }}
          >
            {" "}
            {data?.subject}
          </Typography>
          <Box sx={{ ...dFlex, gap: 2, mt: 0.5 }}>
            <Typography sx={{ color: (theme) => theme.palette.text.dark }}>
              {" "}
              {data?.date}
            </Typography>
            <Typography sx={{ color: (theme) => theme.palette.text.dark }}>
              {" "}
              {convertToAMPMFormat(data?.startTime)} -{" "}
              {convertToAMPMFormat(data?.endTime)}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          border: "1px solid #BFBFBF",
          maxHeight: "175px",
          width: "218px",
          borderRadius: "8px",
          overflow: "auto",
          boxSizing: "border-box",
          p: 1,
        }}
      >
        {data?.facilitators?.map((e) => (
          <Box sx={{ ...itemsCenter, gap: 2, mb: 1 }}>
            <Avatar src={e.image} sx={{ height: "30px", width: "30px" }} />
            <Typography sx={{ color: (theme) => theme.palette.text.main }}>
              {e.name}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box sx={{ ...dFlex, flexDirection: "column" }}>
        <DeleteMeetingModal data={data} fetchAgain={fetchAgain} />
        <Button
          sx={{ textTransform: "none", fontSize: "16px", fontWeight: 500 }}
          onClick={() => {
            navigate("edit", {
              state: {
                data: data,
              },
            });
          }}
        >
          Update Meet
        </Button>
      </Box>
    </Box>
  );
}
