import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Avatar, Box, IconButton, Popover, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../../../common/ProgressBar";
import {
  dFlex,
  flexBetweenCenter,
  itemsCenter,
  textEllipses,
} from "../../../common/commonStyles";
import { formatNumberWithOrdinal } from "../../../const/constFunctions";
import ArchiveStudentModal from "./ArchiveStudentModal";
import DeleteStudentModal from "./DeleteStudentModal";

export default function StudentCard({ data, batchDetails, show, fetchAgain }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        my: 2,
        ...flexBetweenCenter,
        px: 5,
        borderRadius: "8px",
        height: "90px",
        backgroundColor: (theme) => theme.palette.background.secondary,
      }}
    >
      <Box
        sx={{
          ...itemsCenter,
          gap: 2,
          cursor: "pointer",
          width: "275px",
          ...textEllipses,
        }}
        onClick={() => {
          navigate(`${data?.id}`, {
            state: {
              studentInfo: data,
            },
          });
        }}
      >
        <Avatar
          src={data?.image}
          sx={{
            height: "62px",
            width: "62px",
          }}
        />
        <Box sx={{ ...dFlex, gap: 1, flexDirection: "column" }}>
          <Typography
            variant="medium"
            sx={{ color: (theme) => theme.palette.text.dark }}
          >
            {data?.studentName}
          </Typography>
          <Typography
            variant="medium2"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            {data?.totalTokens ?? 0} Tokens Earned.
          </Typography>
        </Box>
      </Box>
      <Box sx={{ ...itemsCenter, flexDirection: "column" }}>
        <Typography
          variant="bold"
          sx={{ color: (theme) => theme.palette.text.dark }}
        >
          {formatNumberWithOrdinal(data?.batchRank ?? 0)}
        </Typography>
        <Typography sx={{ color: (theme) => theme.palette.text.dark }}>
          Rank
        </Typography>
      </Box>
      <Box sx={{ ...dFlex, flexDirection: "column", width: "215px", gap: 1 }}>
        <Typography sx={{ color: (theme) => theme.palette.text.dark }}>
          {data?.certificateIssued
            ? "All Modules Completed"
            : data?.moduleData?.moduleName ?? "Yet to start"}
        </Typography>
        <ProgressBar progress={data?.percentage ?? 0} fontSize={12} />{" "}
      </Box>
      {!show && (
        <>
          <IconButton sx={{ p: 0 }} onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Popover
            open={anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              top: 0,
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box
              sx={{
                p: 2,
                ...dFlex,
                flexDirection: "column",
                gap: 1,
                borderRadius: "8px",
              }}
            >
              <Typography
                variant="medium2"
                onClick={() => {
                  navigate("edit-student", {
                    state: {
                      batchDetail: batchDetails,
                      studentInfo: data,
                    },
                  });
                }}
                sx={{
                  color: (theme) => theme.palette.text.light,
                  cursor: "pointer",
                }}
              >
                Edit
              </Typography>
              <ArchiveStudentModal fetchAgain={fetchAgain} data={data} />
              {data?.deleteAllowed && (
                <DeleteStudentModal fetchAgain={fetchAgain} data={data} />
              )}
            </Box>
          </Popover>
        </>
      )}
    </Box>
  );
}
