import { Avatar, Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import useCheckRole from "../../Hooks/CheckRole";
import classes from "../../common/SideBarClasses.module.css";
import { itemsCenter, sideNavbarLink } from "../../common/commonStyles";
import { UserContext } from "../../context/UserContext";
import { FaqIcon, UserManualIcon } from "../../images/icons";

export default function SchoolHeader() {
  const { userDetails } = useContext(UserContext);
  useCheckRole();
  return (
    <Box
      id="nav-header"
      sx={{
        ...itemsCenter,
        justifyContent: "flex-end",
        height: "64px",
        gap: "20px",
        backgroundColor: (theme) => theme.palette.background.secondary,
        px: "40px",
        zIndex: 1,
        position: "sticky",
        top: 0,
      }}
    >
      {/* Render FAQ icon as a NavLink */}
      <NavLink
        to={"/school/user-manual"}
        sx={{}}
        className={({ isActive }) =>
          isActive ? classes.faqActive : classes.inactive
        }
      >
        <Box sx={sideNavbarLink}>
          <UserManualIcon />
        </Box>
      </NavLink>
      <NavLink
        to={"/school/faq"}
        sx={{}}
        className={({ isActive }) =>
          isActive ? classes.faqActive : classes.inactive
        }
      >
        <Box sx={sideNavbarLink}>
          <FaqIcon />
        </Box>
      </NavLink>

      <Box sx={{ ...itemsCenter, gap: "10px" }}>
        {/* Render user profile avatar */}
        <Avatar
          src={userDetails?.image ?? ""}
          sx={{ width: 43, height: 43, textTransform: "capitalize" }}
          alt="profile-photo"
        >
          {userDetails?.schoolName?.toString()[0] ?? "NA"}
        </Avatar>
        {/* Render user name */}
        <Typography
          variant="medium"
          sx={{
            textTransform: "capitalize",
            color: (theme) => theme.palette.text.main,
          }}
        >
          {userDetails?.schoolName ?? "NA"}
        </Typography>
      </Box>
    </Box>
  );
}
