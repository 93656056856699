import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROLE_LIST } from "../Apis/constVariables";
import { UserContext } from "../context/UserContext";

export default function useCheckRole() {
  const { userDetails } = useContext(UserContext);
  const location = window.location;
  const navigate = useNavigate();
  useEffect(() => {
    // console.log({ userDetails });
    switch (userDetails.role) {
      case ROLE_LIST.SUPER_ADMIN:
        if (!location.pathname.includes("admin")) navigate("/admin/dashboard");
        break;
      case ROLE_LIST.CONTENT_UPLOADER:
        if (!location.pathname.includes("uploader"))
          navigate("/uploader/dashboard");
        break;
      case ROLE_LIST.SCHOOL_ADMIN:
        if (userDetails.isArchived) {
          navigate("/school/settings", { replace: true });
          break;
        }
        if (!location.pathname.includes("school"))
          navigate("/school/dashboard");
        break;
      case ROLE_LIST.FACILITATOR:
        if (userDetails.isArchived) {
          navigate("/facilitator/student-progress", { replace: true });
          break;
        }
        if (!location.pathname.includes("facilitator"))
          navigate("/facilitator/dashboard");
        break;
      case ROLE_LIST.PARENT_ADMIN:
        if (userDetails.isArchived) {
          navigate("/parent/settings", { replace: true });
          break;
        }
        if (!location.pathname.includes("parent"))
          navigate("/parent/dashboard");
        break;
      default:
        break;
    }
  }, [userDetails.role]);
}
