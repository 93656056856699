import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Checkbox,
  InputAdornment,
  TablePagination,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useTablePagination from "../../../Hooks/TablePaginationHook";
import {
  dFlex,
  flexBetweenCenter,
  itemsCenter,
} from "../../../common/commonStyles";
import { FormTextField } from "../../../const/TextField";
import { RegisterIcon } from "../../../images/icons";
import ManageStudentCard from "./ManageStudentCard";

export default function CreateGroupTable() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [checkedItems, setCheckedItems] = useState([]);

  const handleCheckboxChange = (event, index) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setCheckedItems((p) => [...p, index]);
    } else {
      setCheckedItems((p) => p.filter((e, i) => e !== index));
    }
  };

  const navigate = useNavigate();
  const [
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  ] = useTablePagination();
  const data = new Array(25).fill(1);

  return (
    <Box>
      <Button
        // disabled={data?.certificateIssued || !data?.answerSubmitted}
        onClick={handleOpen}
        variant="primary"
      >
        <RegisterIcon />
        Add Students{" "}
      </Button>

      <Box sx={{ ...flexBetweenCenter, mb: 3 }}>
        <Box
          sx={{
            ...dFlex,
            gap: 2,
            alignItems: "center",
            boxSizing: "border-box",
          }}
        >
          <FormTextField
            placeholder="search"
            // sx={{ width: "400px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    fontSize="small"
                    sx={{
                      color: "#BFBFBF",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          ...dFlex,

          gap: 5,
          flexWrap: "wrap",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        {data
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          ?.map((e, index) => {
            return (
              <Box sx={{ ...itemsCenter, gap: 1 }}>
                <Checkbox
                  checked={checkedItems?.includes(index)}
                  onChange={(event) => handleCheckboxChange(event, index)}
                />
                <ManageStudentCard />
              </Box>
            );
          })}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <TablePagination
          rowsPerPageOptions={[]}
          count={data?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
}
