import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { flexCenter } from "../common/commonStyles";
import { useNavigate } from "react-router-dom";
import errorImage from "../images/error404.png";
export default function Error404Page() {
  //   const [number, setNumber] = useState(Math.floor(Math.random() * 100));
  const navigate = useNavigate();
  return (
    <Box sx={{ ...flexCenter, height: "90vh" }}>
      <Box>
        <Typography
          style={{
            fontSize: "80px",
            textAlign: "center",
            color: "#6A707E",
            fontWeight: 600,
          }}
        >
          404
        </Typography>
        <Typography
          sx={{
            fontSize: "24px",
            textAlign: "center",
            color: (theme) => theme.palette.primary.main,
          }}
        >
          Oop, this page cannot be found!
        </Typography>
        <img src={errorImage} alt="img" />
        <Box sx={{ ...flexCenter }}>
          <Button onClick={() => navigate(-1)}>Go Back</Button>
          {/* <Button variant="contained" onClick={() => navigate("/")}>
            Go to Home Page
          </Button> */}
        </Box>
        {/* <Box>
          <Typography>{number}</Typography>
        </Box> */}
      </Box>
    </Box>
  );
}
