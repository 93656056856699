import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SDQIndividualQuestion from "../../../Components/Admin/DemographicData/SDQIndividualQuestion";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";
import { TabPanel } from "../Dashboard/SchoolSubscribers";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { GET } from "../../../Apis/constVariables";
import axios from "axios";

export default function SDQTestPage() {
  const [value, setValue] = useState(1);
  // Event handler for tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [preData, setPreData] = useState([]);
  const [postData, setPostData] = useState([]);
  const getPreData = async () => {
    const url = process.env.REACT_APP_DEMOGRAPHIC_DATA + `/SDQPre`;
    const config = createAxiosConfig({
      endPoint: url,
      method: GET,
    });
    // console.log(config);
    const apiRes = await axios.request(config);
    setPreData(apiRes.data.data);
  };
  const getPostData = async () => {
    const url = process.env.REACT_APP_DEMOGRAPHIC_DATA + `/SDQPost`;
    const config = createAxiosConfig({
      endPoint: url,
      method: GET,
    });
    // console.log(config);
    const apiRes = await axios.request(config);
    setPostData(apiRes.data.data);
  };
  useEffect(() => {
    getPreData();
    getPostData();
  }, []);

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Demographic Data", "SDQ Test"]} />
      <Typography
        variant="bold"
        sx={{ color: (theme) => theme.palette.primary.main, mb: 3 }}
      >
        SDQ Test
      </Typography>
      <Box
        sx={{
          // width: "100%",
          mt: 5,
          borderBottom: `1px solid #BFBFBF`,
        }}
      >
        <Tabs value={value} onChange={handleChange}>
          <Tab
            sx={{
              textTransform: "none",
            }}
            label={
              <Typography variant={value === 1 ? "bold2" : "medium"}>
                Pre Test
              </Typography>
            }
            value={1}
          />
          <Tab
            sx={{
              textTransform: "none",
            }}
            label={
              <Typography variant={value === 2 ? "bold2" : "medium"}>
                Post Test
              </Typography>
            }
            value={2}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={1}>
        <Box
        // sx={{ height: "75vh", overflow: "auto" }}
        >
          {preData.map((e, i) => (
            <SDQIndividualQuestion data={e} index={i} />
          ))}
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box
        // sx={{ height: "75vh", overflow: "auto" }}
        >
          {postData.map((e, i) => (
            <SDQIndividualQuestion data={e} index={i} />
          ))}
        </Box>
      </TabPanel>
    </Box>
  );
}
