import {
  Box,
  Button,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useDataFetchHook from "../../../../Hooks/DataFetchHook";
import NavBreadCrumbs from "../../../../common/NavBreadCrumbs";
import { flexCenter, itemsCenter } from "../../../../common/commonStyles";
import { emptyDataSA } from "../../../../const/imageUrl";
import { PublishIcon } from "../../../../images/icons";
import FileList from "./FileList";
import FilePlayer from "./FilePlayer";
import MedzGymUploadModal from "./MedzGymUploadModal";

export default function CategoryFilesPage() {
  const tData = ["audio", "video"];
  const param = useParams();
  const [url, setUrl] = useState(null);
  const [apiCall, tryAgain] = useDataFetchHook(url);
  const [fileSelected, setFileSelected] = useState(null);
  const [value, setValue] = useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const createFileUrl = () => {
    setUrl(
      process.env.REACT_APP_GYM_CATEGORY +
        `/${param?.categoryId}?type=${tData[value - 1]}`
    );
  };
  const [categoryData, setCategoryData] = useState({
    name: "",
  });
  const location = useLocation();
  // console.log(apiCall);
  useEffect(() => {
    let { data } = location?.state ?? { name: "" };
    setCategoryData(data);
  }, [location?.state]);
  useEffect(() => {
    createFileUrl();
  }, [value]);

  const handlePublish = () => {
    toast("Published");
  };

  return (
    <Box>
      <NavBreadCrumbs pageTags={["Content", "Medz Gym"]} />
      <Box sx={{ ...itemsCenter, justifyContent: "space-between", gap: 1 }}>
        <Typography
          variant="bold"
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          {categoryData?.name}
        </Typography>
        <Box>
          <MedzGymUploadModal
            categoryId={param?.categoryId}
            fetchAgain={tryAgain}
          />
          <Button onClick={handlePublish} variant="primary">
            <PublishIcon />
            Publish
          </Button>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            // width: "100%",
            mt: 2,
            borderBottom: `1px solid #BFBFBF`,
          }}
        >
          <Tabs
            variant="scrollable"
            allowScrollButtonsMobile
            scrollButtons="auto"
            value={value}
            onChange={handleChange}
          >
            <Tab
              sx={{
                textTransform: "none",
              }}
              label={
                <Typography variant={value === 1 ? "bold2" : "medium"}>
                  Audio
                </Typography>
              }
              value={1}
            />
            <Tab
              sx={{
                textTransform: "none",
              }}
              label={
                <Typography variant={value === 2 ? "bold2" : "medium"}>
                  Video{" "}
                </Typography>
              }
              value={2}
            />
          </Tabs>
        </Box>
        {apiCall?.loading && (
          <Box sx={{ ...flexCenter, mt: 5 }}>
            <CircularProgress />
          </Box>
        )}
        {!apiCall.loading && Object.keys(apiCall?.res?.data ?? {})?.length < 1 && (
          <Box
            sx={{
              mt: 3,
              backgroundColor: (theme) => theme.palette.background.secondary,
              ...flexCenter,
              flexDirection: "column",
              py: 8,
              gap: 5,
              borderRadius: "12px",
            }}
          >
            <img
              alt="empty data"
              src={emptyDataSA}
              style={{ height: "254px", width: "345px" }}
            />
            <Typography
              variant="bold"
              sx={{ color: (theme) => theme.palette.text.light }}
            >
              You have not added any files
            </Typography>
            <MedzGymUploadModal
              categoryId={param?.categoryId}
              fetchAgain={tryAgain}
            />
          </Box>
        )}
        <Box sx={{ my: 2 }}>
          {/* {JSON.stringify(apiCall?.res?.data)} */}
          {Object.keys(apiCall?.res?.data ?? {}).map((e) => {
            // console.log("aiuysf", apiCall?.res?.data[e]);
            return (
              <FileList
                mins={e}
                data={apiCall?.res?.data[e]}
                fetchAgain={tryAgain}
                setFileSelected={setFileSelected}
                // type={tData[value - 1]}
              />
            );
          })}
        </Box>
      </Box>

      <FilePlayer
        fileSelected={fileSelected}
        setFileSelected={setFileSelected}
      />
    </Box>
  );
}
