import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { LoadingBarContextProvider } from "./context/TopLodingBarContext";
import { ContextProvider } from "./context/UserContext";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import AppThemeProvider from "./theme/theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <AppThemeProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ContextProvider>
        <LoadingBarContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LoadingBarContextProvider>
      </ContextProvider>
    </LocalizationProvider>
  </AppThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
