import { AccordionDetails, Typography } from "@mui/material";
import React from "react";
import useDataFetchHook from "../../Hooks/DataFetchHook";
import { AccordionTransition } from "../../const/constFunctions";
import { Accordion, AccordionSummary } from "./ModuleTest";
import AssignmentModal from "./QuizModals/AssignmentModal";
import CompleteSentenceModal from "./QuizModals/CompleteSentenceModal";
import DragAndDropModal from "./QuizModals/DragAndDropModal";
import DropDownModal from "./QuizModals/DropDownModal";
import MCQModal from "./QuizModals/MCQModal";
import MatchThePairModal from "./QuizModals/MatchThePairModal";
import PicMCQModal from "./QuizModals/PicMCQModal";
import SCQModal from "./QuizModals/SCQModal";
import SentenceModal from "./QuizModals/SentenceModal";

export default function QuizElement({ data, unPublished }) {
  const url = unPublished
    ? process.env.REACT_APP_QUESTION + `/questionset?quizId=`
    : process.env.REACT_APP_ALL_PUBLISHED_QUIZ_QUESTION;
  const [apiCall] = useDataFetchHook(url + `${data?.quizId}`);

  return (
    <Accordion
      TransitionProps={AccordionTransition}
      sx={{ p: 0, mb: 1, my: 2 }}
    >
      <AccordionSummary
        sx={{
          maxHeight: "50px",
          // border: "none",
          // backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        <Typography
          variant="bold2"
          sx={{ color: (theme) => theme.palette.text.main }}
        >
          {data?.quizName}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: "#FFF",
        }}
      >
        {apiCall.res?.questions?.length == 0 && (
          <Typography
            variant="bold2"
            sx={{ color: (theme) => theme.palette.text.main }}
          >
            No questions in this quiz
          </Typography>
        )}
        {/* {console.log({ apiCall })} */}
        {apiCall.res?.questions?.map((e, i) => {
          {
            if (e.questionType === "MCQ" && e?.data?.choiceType == "single")
              return <SCQModal key={i} data={e} index={i} />;
            if (e.questionType === "MCQ" && e?.data?.choiceType == "multiple")
              return <MCQModal key={i} data={e} index={i} />;
            if (e.questionType === "Ranking")
              return <SentenceModal key={i} data={e} index={i} />;
            if (e.questionType === "DropDown")
              return <DropDownModal key={i} data={e} index={i} />;
            if (e.questionType === "CompleteSentence")
              return <CompleteSentenceModal key={i} data={e} index={i} />;
            if (e.questionType === "PicMCQ")
              return <PicMCQModal key={i} data={e} index={i} />;
            if (e.questionType === "DragDrop")
              return <DragAndDropModal key={i} data={e} index={i} />;
            if (e.questionType === "MatchPair")
              return <MatchThePairModal key={i} data={e} index={i} />;
            if (e.questionType === "Assignment")
              return <AssignmentModal key={i} data={e} index={i} />;
          }
        })}
        {/* e.questionType == "ranking" && <AssignmentModal />; */}
      </AccordionDetails>
    </Accordion>
  );
}
