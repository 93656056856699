import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { itemsCenter, textEllipses } from "../../../../common/commonStyles";
import {
  docsFileIcon,
  jpgFileIcon,
  mp4FileIcon,
  pdfFileIcon,
  pptFileIcon,
  txtFileIcon,
  xlxsFileIcon,
  zipFileIcon,
} from "../../../../const/imageUrl";

export default function ChatOtherComponent({ data }) {
  const [iconName, setIconName] = useState(null);
  const [loading, setLoading] = useState(false);

  function downloadFile(url, customFileName) {
    // Fetch the URL
    setLoading(true);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Create an <a> element and set its attributes
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = customFileName; // Set the custom file name

        // Trigger a click event to initiate the download
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();

        // Cleanup
        document.body.removeChild(a);
        URL.revokeObjectURL(a.href);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  }

  useEffect(() => {
    let type = data?.fileType;
    switch (type) {
      case "image/jpeg":
      case "image/png":
        setIconName(jpgFileIcon);
        break;
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "application/msword":
        setIconName(docsFileIcon);
        break;
      case "video/mp4":
      case "video/mov":
      case "video/avi":
      case "video/mkv":
        setIconName(mp4FileIcon);
        break;
      case "application/pdf":
        setIconName(pdfFileIcon);
        break;
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        setIconName(pptFileIcon);
        break;
      case "text/plain":
        setIconName(txtFileIcon);
        break;
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        setIconName(xlxsFileIcon);
        break;
      case "zip":
        setIconName(zipFileIcon);
        break;

      default:
        setIconName("Invalid Format");
        break;
    }
  }, [data]);

  return (
    <Box
      sx={{
        ...itemsCenter,
        flexDirection: "column",
        gap: 1,
        my: 1,
        maxWidth: "100px",
        cursor: "pointer",
        position: "relative",
      }}
      onClick={() => {
        if (loading) {
          return;
        }
        downloadFile(data?.file, data?.fileName);
        // window.open(data?.file, "_blank");
      }}
    >
      {loading && (
        <Box sx={{ position: "absolute", top: 50, zIndex: "999" }}>
          <CircularProgress />
        </Box>
      )}

      <img
        src={iconName}
        alt={data?.fileName}
        style={{
          opacity: loading ? 0.5 : 1,
          maxHeight: "120px",
          maxWidth: "90px",
        }}
      />
      <Typography
        variant="medium2"
        sx={{
          maxWidth: "120px",
          color: (theme) => theme.palette.text.main,
          ...textEllipses,
        }}
      >
        {data?.fileName}
      </Typography>
    </Box>
  );
}
