import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router";
import {
  dFlex,
  flexBetweenWithItemsCenter,
} from "../../../common/commonStyles";

export default function GroupCard({ data }) {
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => navigate(`${data?.id}`)}
      sx={{
        ...flexBetweenWithItemsCenter,
        px: 2,
        py: 1,
        mb: 3,
        borderRadius: "6px",
        gap: 2,
        backgroundColor: (theme) => theme.palette.background.secondary,
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.25)",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: (theme) => theme.palette.text.lighter,
        },
      }}
    >
      <Box sx={{ ...dFlex, gap: 0.5, flexDirection: "column" }}>
        <Typography
          variant="semibold"
          sx={{ color: (theme) => theme.palette.text.dark }}
        >
          {data?.groupName ?? "NA"}
        </Typography>
        <Typography
          variant="semibold"
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          {" "}
          {data?.members?.length} students
        </Typography>
        {data?.certificateIssued && (
          <Typography variant="semibold" sx={{ color: "#2DAC00" }}>
            {" "}
            Certificate Issued
          </Typography>
        )}
      </Box>
      <IconButton sx={{ p: 0, marginLeft: "auto" }}>
        {" "}
        <NavigateNextIcon fontSize="large" />
      </IconButton>{" "}
    </Box>
  );
}
