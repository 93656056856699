import { Box, Typography } from "@mui/material";
import React from "react";
import PaymentSettingsTable from "../../../Components/Admin/Transactions/PaymentSettingsTable";
import TransactionsTable from "../../../Components/Admin/Transactions/TransactionsTable";
import NavBreadCrumbs from "../../../common/NavBreadCrumbs";

export default function AllTransactions() {
  return (
    <Box>
      <NavBreadCrumbs pageTags={["Transaction Details"]} />

      <Box
        sx={{
          mb: 4,
        }}
      >
        <TransactionsTable />
      </Box>
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="bold"
          sx={{
            color: (theme) => theme.palette.primary.main,
          }}
        >
          Payment Settings
        </Typography>
      </Box>
      <PaymentSettingsTable />
    </Box>
  );
}
