import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../../Apis/axiosPayloads";
import { PATCH, POST } from "../../../../Apis/constVariables";
import { itemsCenter } from "../../../../common/commonStyles";
import { FormTextField } from "../../../../const/TextField";
import { AddIcon, EditIconColor } from "../../../../images/icons";
export default function AddCategoryModal({ edit, data, fetchAgain }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const handleOpen = () => {
    setName(data?.name);
    setOpen(true);
  };

  const handleChange = (e) => {
    if (e.target.value?.length > 50) {
      toast.error("Length cannot be greater than 50");
      return;
    }
    setName(e.target.value);
  };

  const handleClose = () => {
    setName(data?.name);
    setOpen(false);
  };

  const handleAdd = async () => {
    try {
      if (!name?.trim()) {
        toast.error("Name cannot be empty");
        return;
      }
      setLoading(true);
      const url = process.env.REACT_APP_GYM_CATEGORY;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: { name },
      });
      // console.log(config);
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchAgain();
      handleClose();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };
  const handleEdit = async () => {
    try {
      console.log("called");
      if (!name?.trim()) {
        toast.error("Name cannot be empty");
        return;
      }
      const url = process.env.REACT_APP_GYM_CATEGORY + `/${data?.id}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: { name },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchAgain();
      handleClose();
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <>
      {edit ? (
        <IconButton onClick={handleOpen}>
          <EditIconColor />
        </IconButton>
      ) : (
        <Button variant="primary" onClick={handleOpen}>
          <AddIcon /> Add Category
        </Button>
      )}
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              backgroundColor: (theme) => theme.palette.background.main,
              borderRadius: "14px",
              pl: 3,
              pr: 1,
              pt: 3,
              pb: 2,
              maxWidth: "100%",
              width: "32%",
            },
          }}
        >
          <Typography
            variant="bold"
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          >
            {edit ? "Edit" : "Add"} Category
          </Typography>
          <DialogContent sx={{ p: 0 }}>
            <DialogContentText>
              <Box
                sx={{
                  ...itemsCenter,
                  width: "100%",
                  my: "20px",
                  gap: 2,
                }}
              >
                <Typography variant="medium" sx={{ width: "28%" }}>
                  Category Name
                </Typography>
                <FormTextField
                  placeholder="Ex: Breathing"
                  value={name}
                  onChange={handleChange}
                  sx={{ width: "65%" }}
                  autoComplete="categoryName"
                />
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                fontWeight: 500,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={edit ? handleEdit : handleAdd}
              disabled={loading}
              variant="primary"
              sx={{
                "&:disabled": {
                  backgroundColor: "gray",
                  color: "#FFF",
                },
              }}
            >
              {edit ? "Save" : "Add"}
            </Button>
          </DialogActions>
          {/* </form> */}
        </Dialog>
      )}
    </>
  );
}
