import axios from "axios";
import { createAxiosConfig } from "../Apis/axiosPayloads";
import { POST } from "../Apis/constVariables";

export default function sendNotification({
  deviceToken,
  title,
  body,
  name,
  type = "chat",
  id,
  userId = null,
}) {
  // console.log("notificaiton Function Started")
  const {
    REACT_APP_NOTIFICATION_URL,
    REACT_APP_DEV_SERVER_KEY,
    REACT_APP_PROD_SERVER_KEY,
  } = process.env;
  const key = REACT_APP_PROD_SERVER_KEY;
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `key = ${key}`);
  myHeaders.append("Content-Type", "application/json");
  // console.log({ deviceToken });
  // console.log({ title });
  // console.log({ body });
  var requestBody = JSON.stringify({
    to: deviceToken,
    notification: {
      title: title,
      body: body,
    },
    data: {
      type,
      name,
      id,
    },
  });
  const axiosPayload = createAxiosConfig({
    endPoint: process.env.REACT_APP_CREATE_NOTIFICATION,
    method: POST,
    data: {
      userId,
      title: title,
      body: body,
      data: {
        type,
        name,
        id,
      },
    },
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: requestBody,
    redirect: "follow",
  };
  //   console.log({ requestOptions });
  axios
    .request(axiosPayload)
    .then((res) => console.log(res.data))
    .catch((err) => console.log({ err }));
  fetch(REACT_APP_NOTIFICATION_URL, requestOptions)
    .then((response) => response.json())
    .then(() => console.log("Notification Sent Successfully"))
    .catch((error) => console.log("error", error));
}
