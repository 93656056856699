import {
  Box,
  Button,
  Dialog,
  DialogActions,
  FormHelperText,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { PATCH, POST } from "../../../Apis/constVariables";
import { FormTextField } from "../../../const/TextField";
import { AddIcon, EditIconColor } from "../../../images/icons";

const initData = {
  question: "",
  option: ["", ""],
};

export default function ACEQuestionAddModal({ total, edit, fetchAgain, data }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [aceData, setAceData] = useState(initData);
  const handleClickOpen = () => {
    setAceData(data ?? initData);
    setOpen(true);
  };

  const handleClose = () => {
    setAceData(initData);
    setOpen(false);
  };

  const handleAdd = async () => {
    try {
      setLoading(true);
      const url = process.env.REACT_APP_SUPERADMIN_RISK_PROFILING_QUESTION;
      const config = createAxiosConfig({
        endPoint: url,
        method: POST,
        data: {
          ...aceData,
          seq: total + 1,
          type: "ACE",
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchAgain();
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      const url =
        process.env.REACT_APP_SUPERADMIN_RISK_PROFILING_QUESTION +
        `/${aceData?.id}`;
      const config = createAxiosConfig({
        endPoint: url,
        method: PATCH,
        data: {
          ...aceData,
        },
      });
      const apiRes = await axios.request(config);
      toast(apiRes?.data?.message);
      fetchAgain();
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      {edit ? (
        <IconButton onClick={handleClickOpen}>
          <EditIconColor />
        </IconButton>
      ) : (
        <Button variant="primary" onClick={handleClickOpen}>
          <AddIcon />
          Add Questionnaires
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "#F3F5F5",
            borderRadius: "14px",
            p: 5,
            maxWidth: "100%",
            width: "70%",
          },
        }}
      >
        <Typography
          variant="bold"
          sx={{
            color: (theme) => theme.palette.primary.main,
            mb: 3,
          }}
        >
          ACE
        </Typography>
        <Box>
          <Box>
            <FormTextField
              fullWidth
              value={aceData?.question}
              onChange={(e) => {
                if (e.target.value?.length > 500) {
                  toast.error("Maximum 500 characters allowed");
                  return;
                }
                setAceData({ ...aceData, question: e.target.value });
              }}
              InputProps={{
                sx: {
                  height: "32px",
                },
              }}
              placeholder="Type Question"
              autoComplete="ace-question"
              sx={{ height: "32px", mt: 1 }}
            />
            <FormHelperText
              sx={{ my: 0, width: "100%", textAlign: "right" }}
              error={aceData?.question?.length > 500}
            >
              <Typography
                variant="semibold2"
                sx={{
                  color: "#BFBFBF",
                }}
              >
                {aceData?.question?.length} / 500
              </Typography>
            </FormHelperText>
          </Box>
          {aceData?.option?.map((e, i) => (
            <FormTextField
              fullWidth
              InputProps={{
                sx: {
                  height: "32px",
                },
              }}
              onChange={(event) => {
                const { value } = event.target;
                setAceData((prevValue) => {
                  const updatedOption = [...prevValue.option];
                  updatedOption[i] = value;
                  return {
                    ...prevValue,
                    option: updatedOption,
                  };
                });
              }}
              value={e}
              placeholder={"Case " + (i + 1)}
              autoComplete="off"
              sx={{ height: "32px", my: 1 }}
            />
          ))}
        </Box>

        <DialogActions
          sx={{
            pt: 4,
          }}
        >
          <Button
            sx={{ height: "32px", textTransform: "none" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ height: "32px" }}
            variant="primary"
            disabled={loading}
            onClick={edit ? handleUpdate : handleAdd}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
