import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import { Client } from "@twilio/conversations";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { createAxiosConfig } from "../../../Apis/axiosPayloads";
import { POST } from "../../../Apis/constVariables";
import ChannelStudentCard from "../../../Components/Parent/CommunityChannel/ChannelStudentCard";
import DeleteMessageModal from "../../../Components/Parent/CommunityChannel/DeleteMessageModal";
import ParentMessageBox from "../../../Components/Parent/CommunityChannel/ParentMessageBox";
import SuspendStudentModal from "../../../Components/Parent/CommunityChannel/SuspendStudentModal";
import useDataFetchHook from "../../../Hooks/DataFetchHook";
import {
  dFlex,
  flexBetweenWithItemsCenter,
  flexCenter,
  itemsCenter,
  textEllipses,
} from "../../../common/commonStyles";
import {
  checkSize,
  getFormattedDateForChat,
} from "../../../const/constFunctions";
import { UserContext } from "../../../context/UserContext";
import {
  DownloadFileIcon,
  PdfItemIcon,
  VideoIcon,
} from "../../../images/icons";
import { NewMessageStudentCard } from "../../Facilitator/CommunityChannel/FacilitatorCommunityChannel";
const url = "student/getAccessToken";
export const getToken = async () => {
  try {
    const config = createAxiosConfig({
      endPoint: url,
    });
    // console.log({ config });
    const response = await axios.request(config);
    const data = response?.data;
    return data;
  } catch (error) {
    return error;
  }
};
export default function ParentCommunityChannel() {
  // const { userDetails, fcmToken } = useContext(UserContext);
  const [chatDetails, setChatDetails] = useState(null);
  const [chatUserFcmToken, setChatUserFcmToken] = useState(null);
  const clientRef = useRef(null);
  // const [accessTokenApi, fetchAccessTokenAgain] = useDataFetchHook(url);
  const [loading, setLoading] = useState(true);

  const users =
    process.env.REACT_APP_PARENT_STUDENT_LIST + "?filterCompleted=true";

  const [usersApiCall, fetchUsersAgain] = useDataFetchHook(users);

  const lsConvo =
    process.env.REACT_APP_LIST_CONVERSATION + `?filterCompleted=true`;

  const [lsConvoData, fetchAgain] = useDataFetchHook(lsConvo);
  const [lsConversations, setLsConversations] = useState([]);
  const [lastMessageLoading, setLastMessageLoading] = useState(false);
  // console.log({ res: lsConvoData?.res });
  // console.log({ fcmToken });
  const joinChannel = async (channelId, type) => {
    // let channelId = "uniqueName";
    try {
      if (chatDetails?.uniqueName == channelId) {
        return;
      }
      setChatUserFcmToken(null);
      setChatDetails(null);
      setLoading(true);
      const conversation = await clientRef.current.getConversationByUniqueName(
        channelId
      );
      console.log("conversation already found");
      let att = await conversation.getAttributes();
      if (type == "p2p") {
        const url = process.env.REACT_APP_GET_FCM_TOKEN + `?id=${channelId}`;
        const config = createAxiosConfig({
          endPoint: url,
        });
        // console.log({ config });
        const response = await axios.request(config);
        const data = response?.data;
        console.log({ data });
        setChatUserFcmToken(data?.token);
      }
      // console.log({ type });
      // console.log({ channelId });
      // console.log({ att });
      // console.log(conversation.status);
      // await conversation.delete();
      setChatDetails(conversation);
    } catch (error) {
      if (error.body.code == 50432) {
        const url = `${process.env.REACT_APP_JOIN_CONVERSATION}?id=${channelId}`;
        try {
          const config = createAxiosConfig({
            endPoint: url,
          });
          const response = await axios.request(config);
          console.log({ response });
          await joinChannel(channelId);
        } catch (error) {
          console.log({ apiError: error });
          toast.error("Error While Connecting");
          toast.error("Please refresh the page");
        }
      } else if (error.body.code == 50350) {
        const url = process.env.REACT_APP_CREATE_CONVERSATION;
        try {
          const config = createAxiosConfig({
            endPoint: url,
            method: POST,
            data: {
              name: "My Channel",
              id: channelId,
              type: type,
            },
          });
          const response = await axios.request(config);
          console.log({ response });
          await joinChannel(channelId);
        } catch (error) {
          console.log({ apiError: error });
          toast.error("Error While Connecting");
          toast.error("Please refresh the page");
        }
      } else console.log({ error });
    }
    setLoading(false);
  };

  const initFunction = async () => {
    // console.log("calling access token");
    const tokenRes = await getToken();

    // console.log({ res: tokenRes });
    const token = tokenRes?.accessToken;
    try {
      const client = new Client(token);
      clientRef.current = client;
      // client.setPushRegistrationId("fcm", fcmToken ?? "abcd");
      client.on("tokenAboutToExpire", async () => {
        const tokenRes = await getToken();
        const token = tokenRes?.accessToken;
        client.updateToken(token);
      });
      client.on("tokenExpired", async () => {
        const tokenRes = await getToken();
        const token = tokenRes?.accessToken;
        client.updateToken(token);
      });
      // // console.log({ client });
      // const conversation = await client.getConversationByUniqueName(
      //   "uniqueName"
      // );
      // let att = await conversation.getAttributes()
      // console.log("conversation already found");
      // // console.log(conversation.status);
      // // await conversation.delete();
      // setChatDetails(conversation);
      setLoading(false);
      // const client = await Channel.length;
      // client.on("tokenAboutToExpire", async () => {});
    } catch (error) {
      toast.error("Error While Connecting");
      toast.error("Please refresh the page");
      console.log({ error });
    }
  };

  // useEffect(() => {
  //   onMessage(messaging, (payload) => {
  //     console.log("Message received. ", payload);
  //     clientRef.current.handlePushNotification(payload);
  //   });

  //   return () => {};
  // }, []);

  useEffect(() => {
    initFunction();
    // return () => res();

    let intervalId = setInterval(() => {
      fetchAgain();
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);
  const setLastMessage = async (channelIds) => {
    let data = [];
    console.time("setLastMessage");
    try {
      // dispatchlsConvo({ type: request });
      setLastMessageLoading(true);
      // for (let i = 0; i < channelIds.length; i++) {
      //   const channelId = channelIds[i]?.uniqueName;
      //   const conversation = await clientRef.current.getConversationByUniqueName(
      //     channelId
      //   );
      //   // console.log({ lastMessageDate: conversation.lastMessage?.dateCreated });
      //   const res = await conversation.getMessages(
      //     1,
      //     conversation.lastMessage?.index
      //   );
      //   // console.log({ channelId });
      //   // console.log({ res: res?.items });
      //   data.push({
      //     ...channelIds[i],
      //     lastMessage: {
      //       body: res?.items?.[0]?.body,
      //       date: conversation.lastMessage?.dateCreated,
      //     },
      //   });
      // }
      for (let i = 0; i < channelIds.length; i++) {
        const channelId = channelIds[i]?.uniqueName;
        const conversation = await clientRef.current.getConversationByUniqueName(
          channelId
        );
        // console.log({ lastMessageDate: conversation.lastMessage?.dateCreated });
        // const res = await conversation.getMessages(
        //   1,
        //   conversation.lastMessage?.index
        // );
        // console.log({ channelId });
        // console.log({ res: res?.items });
        data.push({
          ...channelIds[i],
          lastMessage: {
            // body: res?.items?.[0]?.body,
            date: conversation.lastMessage?.dateCreated,
          },
        });
      }
      data = data.sort((a, b) => {
        const dateA = a.lastMessage.date
          ? new Date(a.lastMessage.date)
          : new Date(0);
        const dateB = b.lastMessage.date
          ? new Date(b.lastMessage.date)
          : new Date(0);
        return dateB - dateA;
      });
      setLsConversations(data);
      setLastMessageLoading(false);
      // dispatchlsConvo({ type: success, data });
      console.timeEnd("setLastMessage");
    } catch (error) {
      console.log({ error });
    }
  };
  useEffect(() => {
    if (!clientRef.current || !lsConvoData.res) {
      return;
    }
    setLastMessage(lsConvoData.res?.data);
  }, [clientRef.current, lsConvoData]);

  return (
    <Box sx={{ ...dFlex, gap: 2 }}>
      <Box
        id="message_list"
        sx={{
          minHeight: "85vh",
          position: "relative",
          borderRadius: "23px",
          backgroundColor: (theme) => theme.palette.background.secondary,
        }}
      >
        <Box
          sx={{
            maxHeight: "85vh",
            backgroundColor: "#DBE8E8",
            borderRadius: "23px",
            overflowY: "auto",
            overflowX: "hidden",
            direction: "rtl",
          }}
        >
          {new Array(1).fill(1).map((e, i) => (
            <BatchCard
              key={i}
              joinChannel={joinChannel}
              chatDetails={chatDetails}
              // setChatDetails={setChatDetails}
            />
          ))}
          <Box
            sx={{
              height: "100%",
              borderRadius: "23px",
              backgroundColor: (theme) => theme.palette.background.secondary,
              direction: "ltr",
            }}
          >
            {(lsConvoData?.loading || lastMessageLoading) && (
              <Box sx={{ ...flexCenter, py: "5%" }}>
                <CircularProgress />
              </Box>
            )}
            {/* {console.log({ lsConversations })} */}

            {lsConversations?.map((e, i) => (
              <ChannelStudentCard
                chatDetails={chatDetails}
                data={e}
                key={i}
                index={i}
                joinChannel={joinChannel}
                // setChatDetails={setChatDetails}
              />
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            ...flexCenter,
            position: "absolute",
            bottom: 60,
          }}
        >
          <NewMessage
            usersApiCall={usersApiCall}
            fetchUsersAgain={fetchUsersAgain}
            joinChannel={joinChannel}
            fetchAgain={fetchAgain}
          />
        </Box>
      </Box>
      {loading && (
        <Box sx={{ ...flexCenter }}>
          <CircularProgress />
        </Box>
      )}
      {chatDetails && (
        <>
          <ParentMessageBox
            chatUserFcmToken={chatUserFcmToken}
            setChatDetails={setChatDetails}
            chatDetails={chatDetails}
          />
        </>
      )}
    </Box>
  );
}

function BatchCard({ joinChannel, setChatDetails, chatDetails }) {
  const { userDetails } = useContext(UserContext);
  // console.log(chatDetails?.uniqueName);
  // console.log(userDetails?.schoolId);
  return (
    <Box
      sx={{
        ...itemsCenter,
        my: 2,
        px: 1.5,
        gap: 2,
        direction: "ltr",
        minWidth: "22vw",
        cursor: "pointer",
      }}
      onClick={() => {
        joinChannel(userDetails?.schoolId);
        // setChatDetails({
        //   name: "My Channel",
        //   img: "",
        //   type: "channel",
        // });
      }}
    >
      <Avatar
        sx={{
          width: "60px",
          height: "60px",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        M
      </Avatar>
      <Box sx={{ ...dFlex, flexDirection: "column", width: "100%" }}>
        <Typography
          variant="semibold"
          sx={{
            color: (theme) =>
              chatDetails?.uniqueName == userDetails?.schoolId
                ? theme.palette.primary.main
                : theme.palette.text.dark,
          }}
        >
          My Channel
        </Typography>

        {/* <Typography
          variant="medium2"
          sx={{
            color: (theme) => theme.palette.text.main,
            ...dFlex,
            maxWidth: "270px",
            ...textEllipses,
          }}
        >
          Student Name 1:
          <Typography
            sx={{
              color: (theme) => theme.palette.text.light,
              fontSize: "14px",
              ...textEllipses,
            }}
          >
            Lorem ipsum dolor sit amet.askdbgib
          </Typography>
        </Typography> */}
      </Box>
    </Box>
  );
}

function NewMessage({
  usersApiCall,
  joinChannel,
  fetchAgain,
  fetchUsersAgain,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  // console.log({ usersApiCall });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button variant="primary" onClick={handleClick} sx={{ width: "95%" }}>
        New Message
      </Button>
      <Popover
        open={anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            ...dFlex,
            flexDirection: "column",
            gap: 1,
            borderRadius: "8px",
            maxHeight: "40vh",
          }}
        >
          {usersApiCall?.loading && (
            <Box sx={{ ...flexCenter }}>
              {" "}
              <CircularProgress />{" "}
            </Box>
          )}

          {usersApiCall?.res?.Student?.map((e, i) => (
            <NewMessageStudentCard
              handleModalClose={handleClose}
              data={e}
              fetchAgain={fetchAgain}
              key={e.id}
              fetchStudentAgain={fetchUsersAgain}
              showSuspend={true}
              joinChannel={joinChannel}
            />
          ))}
        </Box>
      </Popover>
    </>
  );
}

export function ChatMessages({ setChatDetails, data, inComing }) {
  const { userDetails } = useContext(UserContext);
  const [showMore, setShowMore] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [chatUserDetails, setChatUserDetails] = useState({
    friendlyName: "",
  });
  // inComing && console.log({ media: data?.attachedMedia });
  const [fileUrls, setFileUrls] = useState([]);
  // console.log({ data });
  // console.log({ chatUserDetails });
  const getAuthorDetails = async () => {
    try {
      const authData = await data?.getParticipant?.();
      // console.log({ authData });
      const userData = await authData?.getUser?.();
      // console.log({ userData });
      setChatUserDetails(userData);
    } catch (e) {
      // console.log({ e });
    }
  };

  useEffect(() => {
    getAuthorDetails();
  }, [data]);

  // const generateFileUrls = async () => {
  //   setFileUrls([]);
  //   let files = data?.attachedMedia;
  //   for (let i = 0; i < files.length; i++) {
  //     const file = files[i];
  //     const url = await file?.getContentTemporaryUrl?.();
  //     // console.log({ url });
  //     setFileUrls((p) => [...p, url]);
  //   }
  // };
  // // console.log({ fileUrls });
  // useEffect(() => {
  //   generateFileUrls();
  // }, [data]);

  const handleFileClick = async (i) => {
    const url = await data?.attachedMedia?.[i]?.getContentTemporaryUrl?.();

    window.open(url, "_blank");
  };
  const handleMessageDelete = async (data) => {
    try {
      const url = await data?.remove();
    } catch (e) {
      console.log({ e });
    }
  };
  // console.log({ chatUserDetails });
  // console.log({ data });
  return (
    <Box
      onMouseOver={() => {
        setShowMore(true);
      }}
      onMouseOut={() => {
        if (anchorEl) {
          return;
        }
        setShowMore(false);
      }}
      sx={{
        my: 3,
        // width: "70%",
        p: 1,
        boxSizing: "border-box",
        minHeight: "10px",
        ...dFlex,
        flexDirection: !inComing ? "row" : "row-reverse",
        gap: 2,
      }}
    >
      <Avatar
        // src="https://cdn.pixabay.com/photo/2023/05/18/18/54/ibex-8003063_1280.jpg"
        // src={data?.attributes?.image}
        src={chatUserDetails?.attributes?.image}
        sx={{
          width: "70px",
          height: "70px",
          alignSelf: "flex-end",
        }}
      >
        {data?.attributes?.name?.[0]}
      </Avatar>
      <Box
        sx={{
          borderRadius: inComing ? "40px 40px 0px 40px" : "40px 40px 40px 0px",
          minHeight: "70px",
          width: "70%",
          py: 2,
          px: 3,
          alignSelf: "flex-end",
          backgroundColor: "#DBE8E8",
          boxSizing: "border-box",
          ...dFlex,
          flexDirection: "column",
        }}
      >
        <Typography
          variant="semibold"
          sx={{
            color: (theme) => theme.palette.text.dark,
            mb: 1,
            ...flexBetweenWithItemsCenter,
          }}
        >
          {data?.attributes?.name}
          <Typography
            variant="medium2"
            sx={{
              color: (theme) => theme.palette.text.light,
            }}
          >
            {getFormattedDateForChat(data?.dateCreated)}
          </Typography>
        </Typography>
        <Typography
          variant="regular"
          sx={{ color: (theme) => theme.palette.text.main }}
        >
          {data?.body}
        </Typography>

        {data?.attachedMedia?.map((e, i) => {
          if (e?.contentType?.includes("image")) {
            return (
              <Box
                key={e.sid}
                sx={{
                  backgroundColor: "#C4DDDD",
                  borderRadius: "7px",
                  p: "16px 12px",
                  boxSizing: "border-box",
                  width: "fit-content",
                  ...itemsCenter,
                  gap: 1,
                  mt: 2,
                }}
              >
                <VideoIcon />
                <Box
                  sx={{
                    ...dFlex,
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: (theme) => theme.palette.text.dark,
                      maxWidth: "180px",
                      ...textEllipses,
                    }}
                  >
                    {" "}
                    {/* Lorem ipsum dolor sit amet. */}
                    {e?.filename}
                  </Typography>
                  <Typography
                    variant="semibold2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    {checkSize(e)}mb
                  </Typography>
                </Box>
                <IconButton onClick={() => handleFileClick(i)}>
                  <DownloadFileIcon />
                </IconButton>
              </Box>
            );
          }
          if (e?.contentType?.includes("video")) {
            return (
              <Box
                key={e.sid}
                sx={{
                  backgroundColor: "#C4DDDD",
                  borderRadius: "7px",
                  p: "16px 12px",
                  boxSizing: "border-box",
                  width: "fit-content",
                  ...itemsCenter,
                  gap: 1,
                  mt: 2,
                }}
              >
                <VideoIcon />
                <Box
                  sx={{
                    ...dFlex,
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: (theme) => theme.palette.text.dark,
                      maxWidth: "180px",
                      ...textEllipses,
                    }}
                  >
                    {" "}
                    {/* Lorem ipsum dolor sit amet. */}
                    {e?.filename}
                  </Typography>
                  <Typography
                    variant="semibold2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    {checkSize(e)}mb
                  </Typography>
                </Box>
                <IconButton onClick={() => handleFileClick(i)}>
                  <DownloadFileIcon />
                </IconButton>
              </Box>
            );
          }
          if (e?.contentType == "application/pdf") {
            return (
              <Box
                key={e.sid}
                sx={{
                  backgroundColor: "#C4DDDD",
                  borderRadius: "7px",
                  p: "16px 12px",
                  boxSizing: "border-box",
                  width: "fit-content",
                  ...itemsCenter,
                  gap: 1,
                  mt: 2,
                }}
              >
                <PdfItemIcon />
                <Box
                  sx={{
                    ...dFlex,
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: (theme) => theme.palette.text.dark,
                      maxWidth: "180px",
                      ...textEllipses,
                    }}
                  >
                    {e?.filename}
                  </Typography>
                  <Typography
                    variant="semibold2"
                    sx={{
                      color: (theme) => theme.palette.text.main,
                    }}
                  >
                    {checkSize(e)}mb
                  </Typography>
                </Box>
                <IconButton onClick={() => handleFileClick(i)}>
                  {" "}
                  <DownloadFileIcon />
                </IconButton>
              </Box>
            );
          }
        })}

        {/* <Box
          sx={{
            backgroundColor: "#C4DDDD",
            borderRadius: "7px",
            p: "16px 12px",
            boxSizing: "border-box",
            width: "fit-content",
            ...itemsCenter,
            gap: 1,
            mt: 2,
          }}
        >
          <AudioItemIcon />
          <Box
            sx={{
              ...dFlex,
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: (theme) => theme.palette.text.dark,
                maxWidth: "180px",
                ...textEllipses,
              }}
            >
              {" "}
              Lorem ipsum dolor sit amet.
            </Typography>
            <Typography
              variant="semibold2"
              sx={{
                color: (theme) => theme.palette.text.main,
              }}
            >
              255kB
            </Typography>
          </Box>
          <IconButton>
            <DownloadFileIcon />
          </IconButton>
        </Box>*/}
      </Box>
      {showMore && (
        <ChatMessageOptions
          chatUserDetails={chatUserDetails}
          data={data}
          setChatDetails={setChatDetails}
          handleMessageDelete={handleMessageDelete}
          inComing={inComing}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )}
    </Box>
  );
}

export function ChatMessageOptions({
  chatUserDetails,
  data,
  handleMessageDelete,
  inComing,
  anchorEl,
  setAnchorEl,
  setChatDetails,
}) {
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSuspendClose = () => {
    setChatDetails(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{
          color: (theme) => theme.palette.text.main,
          alignSelf: "center",
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        open={anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            ...dFlex,
            flexDirection: "column",
            pr: 4,
            gap: 1,
            borderRadius: "8px",
          }}
        >
          <DeleteMessageModal
            data={data}
            handleMessageDelete={handleMessageDelete}
          />
          <Button
            sx={{
              textTransform: "none",
              color: (theme) => theme.palette.text.main,
              justifyContent: "flex-start",
              ml: 1,
            }}
          >
            Copy Message
          </Button>
          {!inComing && (
            <SuspendStudentModal
              fetchStudentAgain={handleSuspendClose}
              suspended={chatUserDetails?.attributes?.chatSuspended ?? false}
              data={{
                id: chatUserDetails.identity,
                studentName: chatUserDetails?.friendlyName,
                fcmToken: chatUserDetails?.state?.attributes?.fcmToken,
                // ...chatUserDetails,
              }}
              normalButton={true}
            />
          )}
        </Box>
      </Popover>
    </>
  );
}
