import {
  Box,
  Button,
  Dialog,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import {
  flexBetweenWithItemsCenter,
  itemsCenter,
  justifyCenter,
} from "../../../../../common/commonStyles";
import {
  NextIcon,
  PageZoomIcon,
  PageZoomOutIcon,
  PdfItemIcon,
  PdfPageIcon,
  PrevPage,
} from "../../../../../images/icons";

export default function PdfRenderModal({ data }) {
  const [open, setOpen] = useState(false);

  const [audioEnable, setAudioEnable] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoomFactor, setZoomFactor] = useState(1.0);

  // useEffect(() => {
  //   getItemProgress({ courseId: courseId, itemId: itemId }).then((result) => {
  //     if (result.data.error === "not present") {
  //       setCompleted(false);
  //     } else if (result.data.completed === true) {
  //       setCompleted(true);
  //     }
  //     //console.log(result);
  //   });
  //   return () => {};
  // }, []);

  // useEffect(() => {
  //   setCanNavigate(completed || !isLinear);
  //   if (completed) {
  //     setPDFProgress({
  //       courseId: courseId,
  //       itemId: itemId,
  //       lastVisited: new Date().toString(),
  //     });
  //   }
  // }, [completed]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const changePage = (goToPage) => {
    if (goToPage > numPages || goToPage <= 0) return;
    setPageNumber(goToPage);
  };
  const zoom = (action) => {
    if (zoomFactor <= 0.25 && action == "out") return;
    if (zoomFactor >= 1.5 && action == "in") return;
    if (action === "in") {
      setZoomFactor((prev) => prev + 0.25);
      //console.log(zoomFactor);
    } else if (action === "out") {
      setZoomFactor((prev) => prev - 0.25);
      //console.log(zoomFactor);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ ...itemsCenter, gap: 1, my: 1 }} onClick={handleClickOpen}>
        <PdfItemIcon />
        <Typography sx={{ color: (theme) => theme.palette.text.main }}>
          (PDF) {data?.itemName}
        </Typography>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "#F3F5F5",
            borderRadius: "14px",
            p: "24px 12px 12px 24px",
            maxWidth: "100%",
            width: "40%",
          },
        }}
      >
        <Box sx={{ ...justifyCenter }}>
          <Box sx={{ ...itemsCenter, gap: 2, mb: 2 }}>
            <IconButton onClick={() => changePage(pageNumber - 1)}>
              <PrevPage height={"1em"} />
            </IconButton>
            <Box sx={{ ...itemsCenter, gap: 1 }}>
              <PdfPageIcon />
              <Typography className="courseItem-pdfTopbarText">
                {pageNumber}/{numPages}
              </Typography>
            </Box>
            <IconButton onClick={() => changePage(pageNumber + 1)}>
              <NextIcon height={"1em"} />
            </IconButton>
          </Box>
        </Box>
        <Box
          style={{
            boxSizing: "border-box",
            overflowY: "auto",
            height: "80vh",
            ...justifyCenter,
          }}
        >
          <Document
            // file={pdfURL}
            file={data?.URL}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page scale={zoomFactor} pageNumber={pageNumber} />
          </Document>
        </Box>
        <Box sx={{ mt: 2, ...flexBetweenWithItemsCenter }}>
          <Box>
            <IconButton onClick={() => zoom("out")}>
              <PageZoomOutIcon />
            </IconButton>
            <IconButton onClick={() => zoom("in")}>
              <PageZoomIcon />
            </IconButton>
          </Box>
          {/* <IconButton onClick={() => setAudioEnable((prev) => !prev)}>
            {audioEnable ? <AudioIcon fill={"#358B8A"} /> : <AudioIcon />}
          </IconButton> */}
        </Box>

        <DialogActions sx={{ pt: 4 }}>
          <Button
            sx={{ height: "32px", textTransform: "none" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
